// ERR MSG FOR AUTH,CREATE,UPDATE, MODULE
export const AUTH_MSG = {
  VALID_PASSWORD:
    'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
  CANNOT_EMPTY: 'This field cannot be empty.',
  VALID_NAME: 'Enter valid name',
  MAX_NAME: 'Must be 20 characters or less',
  VALID_EMAIL: 'Enter valid email',
  VALID_PHONE: 'Enter valid phone number',
  VALID_GUEST: 'Enter valid value in guest field',
  TOO_SHORT: 'Too short',
  TOO_LONG: 'Too long',
  VALID_DESCRIPTION: 'Enter valid property description',
  SAME_PASSWORD: 'Password must be same',
  VALID_UNIT: 'Please enter valid unit',
  CHECK_PRIVACY_POLICY: 'You need to accept privacy policy',
  CHECH_DATE_FUTURE: 'Date of birth cannot be in the future',
  FIFTEEN_YEARS_AGO: 'Date of birth must be at least 15 years ago',
  DOB: 'Date of birth is required',
  FIRST_NAME: 'First name is required.',
  LAST_NAME: 'Last name is required.',
  FIRST_NAME: 'First name is required.',
  PHONE_NUMBER: 'Phone number is required.',
  CONFIRM_PASWORD: 'Confirm password is required',
  PASWORD: 'Password is required',
  EMAIL: 'Email is required',
  HEADING: 'Heading is required',
  INFO: 'Info is required',
  SUB_HEADING: 'Description is required',
  MAX_INFO_SIZE: 'Must be 60 characters or less',
  SOCIAL_LINK: 'Link is required',
  ROLE: 'Role is required',
};
