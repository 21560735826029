// ----------------------------------------------------------------------

export default function Paper(theme) {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          '&.MuiPopover-paper': {
            boxShadow: theme.customShadows.dropdown,
            padding: '4px',
            borderRadius: Number(theme.shape.borderRadius) * 2.5,
            '& .MuiList-root': {
              padding: '0',
            },
          },
          '& .MuiMenuItem-root': {
            borderRadius: Number(theme.shape.borderRadius) * 1.5,
            whiteSpace: 'normal',
            fontSize: '0.875rem',
            minHeight: 'auto',
            '& .Mui-selected': {
              fontWeight: '600',
            },
          },
          '& .MuiListItemButton-root': {
            borderRadius: Number(theme.shape.borderRadius) * 1.5,
            whiteSpace: 'normal',
            fontSize: '0.875rem',
            minHeight: 'auto',
            '& .Mui-selected': {
              fontWeight: '600',
            },
          },
          '& .MuiListItemIcon-root': {
            minWidth: 'auto !important',
            marginRight: 10,
          },
        },
      },
    },
  };
}
