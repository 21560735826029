import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Input,
  Form,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Card,
  CardBody,
} from 'reactstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAllEmailTemplatesList } from '../../../services/apiServices';
import { LogoSection } from '../../../components/logo';
import EditEmailTemplate from './EditEmailTemplate';
import ResponseModal from '../../Components/Common/SuccessModal';
import ShowSpinner from '../../Components/Common/spinner';

//import Images
import logo_dark from '../../assets/images/logo-dark.png';

const PropertyRentalInquiry = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [allTemplates, setAllTemplates] = useState([]);
  const [spinFlag, setSpinFlag] = useState(false);
  const [modal, setModal] = useState(false);
  const [renderFlag, setRenderFlag] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const [response, setResponse] = useState({
    message: '',
    open: false,
    statusCode: null,
  });
  const fetchEmailTemplatesContent = async () => {
    try {
      setSpinFlag(true);
      // let payload = {
      //   currentPage: currentPage,
      // };
      let { data } = await getAllEmailTemplatesList();
      if (data.statusCode === 200) {
        setSpinFlag(false);
        // setNumberOfPages(data.noOfPages);

        setAllTemplates(data.templatesData);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  useEffect(() => {
    fetchEmailTemplatesContent();
  }, [renderFlag]);

  const handleClose = () => {
    setIsEdit(null);
    setModal(false);
  };

  return (
    <React.Fragment>
      <ResponseModal response={response} setResponse={setResponse} />
      {allTemplates.map((item, index) => {
        return (
          <Row key={index} className="mb-3">
            {/* <Col lg={12}>
              <div className="justify-content-between d-flex align-items-center mt-3 mb-4">
                <h5 className="mb-0 pb-1 text-decoration-underline">
                  Property Rental Inquiry
                </h5>
              </div>
            </Col> */}
            <Col className="col-12">
              <div
                style={{
                  maxWidth: '600px',
                  margin: '0 auto',
                  backgroundColor: '#fff',
                  padding: '20px',
                  border: '1px solid rgba(145,158,171,0.8)',
                  borderRadius: '14px',
                  fontWeight: '400',
                  color: '#323232',
                  fontSize: '15px',
                }}
              >
                <div className="mb-3">
                  <Row>
                    <Col xs={6}>
                      <div className="tam-logo">
                        <LogoSection />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          className="btn btn-icon btn-topbar rounded-circle"
                          onClick={() => {
                            setIsEdit(item);
                            setModal(true);
                          }}
                        >
                          <i className="ri-pencil-line fs-16"></i>
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="mb-3">
                  <Label htmlFor="api-key-name" className="form-label">
                    Subject:
                  </Label>
                  <span> {item.subject}</span>
                </div>
                <div className="mb-3">
                  <p>Dear -----------,</p>
                </div>
                <div className="mb-3">
                  <div
                    dangerouslySetInnerHTML={{ __html: item.headerContent }}
                  ></div>
                </div>
                {item.key === 'propertyRequest' && (
                  <div
                    className="py-2 px-1"
                    style={{
                      border: '1px dashed rgba(145,158,171,0.08)',
                      background: 'rgba(145,158,171,0.04)',
                      borderRadius: '14px',
                      marginBottom: '20px',
                    }}
                  >
                    <h5 className="mb-2 px-2">Property Details</h5>
                    <div className="d-flex pb-1 px-2">
                      <div
                        style={{
                          width: '80px',
                          height: '80px',
                          borderRadius: '10px',
                          background: '#eee',
                        }}
                      ></div>
                      <div
                        style={{
                          width: 'calc(100% - 100px)',
                          paddingLeft: '20px',
                        }}
                      >
                        <h5 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-lg col-12"></span>
                        </h5>
                        <p className="card-text placeholder-glow">
                          <span className="placeholder placeholder-xs col-12"></span>
                          <span className="placeholder placeholder-xs col-3"></span>
                        </p>
                      </div>
                    </div>
                    <hr />
                    <h5 className="mb-2 px-2">Booking Details</h5>
                    <div className="pb-1 px-2">
                      <div className="row">
                        <div className="col-5">
                          <p className="card-text placeholder-glow">
                            <span className="placeholder placeholder-xs col-12"></span>
                          </p>
                        </div>
                        <div className="col-7">
                          <p className="card-text placeholder-glow">
                            <span className="placeholder placeholder-xs col-12"></span>
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <p className="card-text placeholder-glow">
                            <span className="placeholder placeholder-xs col-12"></span>
                          </p>
                        </div>
                        <div className="col-7">
                          <p className="card-text placeholder-glow">
                            <span className="placeholder placeholder-xs col-12"></span>
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <p className="card-text placeholder-glow">
                            <span className="placeholder placeholder-xs col-12"></span>
                          </p>
                        </div>
                        <div className="col-7">
                          <p className="card-text placeholder-glow">
                            <span className="placeholder placeholder-xs col-12"></span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {item.key === 'welcomeUser' && null}

                {item.key === 'verifyEmail' && (
                  <div className="mb-3">
                    <Button color="success">Verify Email</Button>
                  </div>
                )}
                {item.key === 'contactUs' && (
                  <div
                    className="py-2 px-1"
                    style={{
                      border: '1px dashed rgba(145,158,171,0.08)',
                      background: 'rgba(145,158,171,0.04)',
                      borderRadius: '14px',
                      marginBottom: '20px',
                    }}
                  >
                    <h5 className="mb-2 px-2">User Details</h5>
                    <div className="d-flex pb-1 px-2">
                      <div
                        style={{
                          width: '80px',
                          height: '80px',
                          borderRadius: '10px',
                          background: '#eee',
                        }}
                      ></div>
                      <div
                        style={{
                          width: 'calc(100% - 100px)',
                          paddingLeft: '20px',
                        }}
                      >
                        <h5 className="card-title placeholder-glow">
                          <span className="placeholder placeholder-lg col-12"></span>
                        </h5>
                        <p className="card-text placeholder-glow">
                          <span className="placeholder placeholder-xs col-12"></span>
                          <span className="placeholder placeholder-xs col-3"></span>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {item.key === 'forgotPassword' && (
                  <Button color="success">Forgot password link</Button>
                )}
                <div className="mb-3">
                  <div
                    dangerouslySetInnerHTML={{ __html: item.footerContent }}
                  ></div>
                </div>
                <p>
                  Best regards, <br />{' '}
                  <p className="card-text placeholder-glow">
                    <span className="placeholder placeholder-xs col-3"></span>
                  </p>
                </p>
              </div>
            </Col>
          </Row>
        );
      })}
      {allTemplates.length === 0 && (
        <Card>
          <CardBody className="text-center">Data not found</CardBody>
        </Card>
      )}

      <Modal
        id="showModal"
        isOpen={modal}
        handleClose={handleClose}
        centered
        size="lg"
        toggle={handleClose}
      >
        <ModalHeader modal={modal} toggle={handleClose}>
          Edit email templates
        </ModalHeader>

        <ModalBody>
          <EditEmailTemplate
            handleClose={handleClose}
            isEdit={isEdit}
            renderFlag={renderFlag}
            setRenderFlag={setRenderFlag}
            setSpinFlag={setSpinFlag}
            setResponse={setResponse}
          />
        </ModalBody>
      </Modal>
      <ShowSpinner spinFlag={spinFlag} />
    </React.Fragment>
  );
};

export default PropertyRentalInquiry;
