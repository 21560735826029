import { Backdrop, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import React from 'react';
import { Alert, Modal, ModalBody } from 'reactstrap';
import trash from '../../assets/lordicon-icons/wired-outline-185-trash-bin.gif';
import ResponseModal from './SuccessModal';
import ShowSpinner from './spinner';

const DeleteModal = (props) => {
  let { show, onDeleteClick, onCloseClick, deleteConfirmationMsg } = props;

  return (
    <>
      <Modal fade={true} isOpen={show} toggle={onCloseClick} centered={true}>
        <ModalBody className="py-3 px-3">
          <div
            className="mb-3"
            style={{ textAlign: 'right' }}
            onClick={onCloseClick}
          >
            <Icon icon="ic:round-close" style={{ cursor: 'pointer' }} />
          </div>
          <div className="mt-2 text-center">
            <img width={'100px'} src={trash} alt="Delete" />
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                {deleteConfirmationMsg
                  ? deleteConfirmationMsg
                  : 'Are you sure you want to delete this user ?'}
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-"
              data-bs-dismiss="modal"
              onClick={onCloseClick}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={onDeleteClick}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
  deleteState: PropTypes.any,
};

export default DeleteModal;
