import React, { useState } from 'react';
import {
  DialogTitle,
  DialogActions,
  Dialog,
  Typography,
  Button,
  DialogContent,
  IconButton,
  Divider,
  Box,
  Slider,
  SliderThumb,
  Grid,
  TextField,
  InputAdornment,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Creditcardmockup from '../../assets/images/Creditcardmockup.png';
import Creditcardmockup1 from '../../assets/images/Creditcardmockup-1.png';
import Creditcardmockup2 from '../../assets/images/Creditcardmockup-2.png';
import OwlCarousel from 'react-owl-carousel3';

const CreditcardLists = [
  {
    cardPath: Creditcardmockup,
  },
  {
    cardPath: Creditcardmockup1,
  },
  {
    cardPath: Creditcardmockup2,
  },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '100%',
    maxWidth: '1133px',
  },
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogContent-root': {
    padding: 0,
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(3),
  },
}));
const options = {
  responsiveClass: true,
  loop: false,
  responsive: {
    0: {
      items: 1,
      dots: true,
    },
    400: {
      items: 1,
      dots: true,
    },
    600: {
      items: 2,
      dots: false,
    },
    700: {
      items: 3,
      dots: false,
    },
    1000: {
      items: 3,
      dots: false,
    },
  },
};
export const PropertyCheckout = (props) => {
  const { handleClose, open, ...other } = props;
  const [value, setValue] = useState([20, 37]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ textAlign: 'center' }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Checkout
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ p: 4, bgcolor: '#EDEDED' }}>
            <Box
              sx={{
                '& .owl-dots .owl-dot': {
                  '& span': {
                    background: '#EFEFEF !important',
                  },
                  '&.active': {
                    '& span': {
                      background: '#FEAE01 !important',
                    },
                  },
                },
              }}
            >
              <Typography variant="h3" sx={{ mb: 2 }}>
                Choose Payment Methods
              </Typography>
              <OwlCarousel
                className="owl-theme"
                loop
                margin={0}
                nav={false}
                {...options}
              >
                {CreditcardLists.map((val, index) => (
                  <Box key={index} sx={{ px: 2, py: 1 }}>
                    <img
                      style={{ width: '100%' }}
                      src={val.cardPath}
                      alt={val.cardPath}
                    />
                  </Box>
                ))}
              </OwlCarousel>
            </Box>
          </Box>
          <Box sx={{ p: 4 }}>scsc</Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            fullWidth
            sx={{ py: 1.5, px: 2 }}
            onClick={handleClose}
          >
            Confirm
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};
