import React, { useState } from 'react';
import * as Yup from 'yup';
import {
  TextField,
  IconButton,
  InputAdornment,
  Button,
  Stack,
  MenuItem,
  Grid,
  Box,
  Typography,
  ListItem,
  ListItemButton,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  alpha,
} from '@mui/material';
import { useFormik, Form, FormikProvider, Field } from 'formik';
import { Icon } from '@iconify/react';
import SvgColor from '../../../components/svg-color';
import Landlord from '../../../assets/icons/Landlord.svg';
import Renter from '../../../assets/icons/Renter.svg';

const RadioGroupMain = styled(RadioGroup)(({ theme }) => ({
  flexDirection: 'row',
  gap: 56,
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    gap: 18,
  },
}));
const RadioLabel = styled(FormControlLabel)(({ theme }) => ({
  width: '144px',
  height: '139px',
  margin: 0,
  position: 'relative',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  color: `${alpha(theme.palette.text.secondary, 1)}`,
  '&:hover': {
    '& .MuiButtonBase-root': {
      background: `${alpha(theme.palette.primary.main, 1)}`,
    },
    '& .MuiFormControlLabel-label': {
      color: '#fff',
      '& .MuiTypography-root': {
        color: '#fff',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    width: 'calc(50% - 15px)',
    maxWidth: '144px',
    height: 'auto',
    aspectRatio: '1',
  },
  '& .MuiButtonBase-root': {
    position: 'absolute',
    left: '0',
    top: '0',
    padding: '0',
    border: '1px solid #D1D5DB',
    borderRadius: '14px',
    width: '100%',
    height: '100%',
    '& svg': {
      display: 'none',
    },
    '&.Mui-checked': {
      background: `${alpha(theme.palette.primary.main, 1)}`,
    },
    '&.Mui-checked + .MuiFormControlLabel-label': {
      color: '#fff',
      '& .MuiTypography-root': {
        color: '#fff',
      },
    },
  },
}));

export const RegisterRole = (props) => {
  return (
    <>
      <Box sx={{ mb: 6, position: 'relative' }}>
        <Typography
          variant="subtitle1"
          align="center"
          sx={{
            color: 'secondary.main',
            textTransform: 'uppercase',
            letterSpacing: { sm: '0.1em' },
          }}
        >
          Sign up as
        </Typography>
      </Box>
      <Stack spacing={3}>
        <RadioGroupMain
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
        >
          <RadioLabel
            onChange={props.signUpLandlord}
            value="Landlord"
            control={<Radio />}
            label={
              <Box
                sx={{
                  position: 'relative',
                  zIndex: '1',
                }}
              >
                <SvgColor
                  src={Landlord}
                  alt={Landlord}
                  sx={{ width: '48px', height: '48px' }}
                />
                <Typography variant="body1" sx={{ fontWeight: '600' }}>
                  Landlord
                </Typography>
              </Box>
            }
          />
          <RadioLabel
            onChange={props.signUpRenter}
            value="Renter"
            control={<Radio />}
            label={
              <Box
                sx={{
                  position: 'relative',
                  zIndex: '1',
                }}
              >
                <SvgColor
                  src={Renter}
                  alt={Renter}
                  sx={{ width: '48px', height: '48px' }}
                />
                <Typography variant="body1" sx={{ fontWeight: '600' }}>
                  Renter
                </Typography>
              </Box>
            }
          />
        </RadioGroupMain>
      </Stack>
    </>
  );
};
