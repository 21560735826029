import React, { useState } from 'react';
import {
  Rating,
  Grid,
  Box,
  Container,
  Typography,
  Stack,
  Divider,
  Avatar,
  List,
  ListItem,
  Button,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  TextField,
  Radio,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import avatar_11 from '../../assets/images/avatars/avatar_11.jpg';
import { IMG_URL } from '../../utils/constant';
import { DialogPopup } from '../../components/dialog-popup/DialogPopup';
import { addReview } from '../../services/apiServices';
import { useAuthStore } from '../../redux/features/authSlice';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconEmpty': {
    color: '#D3D3DC',
  },
  '& .MuiRating-iconFilled': {
    color: '#FFBD3E',
  },
  '& .MuiRating-iconHover': {
    color: '#FFBD3E',
  },
});
const CheckboxRound = styled(Checkbox)(({ theme }) => ({
  height: 42,
  width: 42,
  border: 'none',
  borderRadius: '50%',
  // background: '#D3D3DC',
  color: '#D3D3DC',
  fontSize: 42,
  [theme.breakpoints.down('md')]: {
    height: 24,
    width: 24,
  },
  '&.Mui-checked': {
    color: '#FEAE01',
    border: 'none',
    '& .MuiSvgIcon-root': {
      display: 'block',
    },
  },
  '& svg': {
    height: '100%',
    width: '100%',
  },
}));
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: 140,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#D9D9D9',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#323232',
  },
}));
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const expectationsList = [
  {
    label: 'Much Better than i expected',
  },
  {
    label: 'A bit better than i expected',
  },
  {
    label: 'About the same as i expected',
  },
  {
    label: 'A bit worse than i expected',
  },
  {
    label: 'Much worse than i expected',
  },
];
export const PropertyReviews = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let { loginInfo, isLoggedin } = useAuthStore();

  let {
    propertyData,
    propertyOwner,
    propertyReview,
    reviewData,
    setRenderFlag,
    renderFlag,
    setSpinFlag,
    propertyUnits,
  } = props;
  let { profile, firstname, lastname, role } = loginInfo;
  let {
    _id,
    rating,
    accuracyRating,
    cleanlinessRating,
    communicationRating,
    locationRating,
    valueRating,
    requestData,
    requestDataForReview,
  } = propertyData;

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [popupOpen, setPopupOpen] = useState(false);
  const [statusCode, setStatusCode] = useState();
  const handlePopupOpen = (popupOpen) => {
    setPopupOpen(popupOpen);
  };
  const handlePopupClose = (popupOpen) => {
    setPopupOpen(false);
  };

  const postReview = async (values) => {
    try {
      setSpinFlag(true);
      values.property_id = _id;
      values.landlord_id = propertyOwner._id;
      // values.review_id = reviewData ? reviewData._id : null;
      const { data } = await addReview(values);
      if (data.statusCode === 200) {
        handlePopupClose();
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        setRenderFlag(!renderFlag);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      rating: 1,
      cleanlinessRating: 0,
      accuracyRating: 0,
      valueRating: 0,
      communicationRating: 0,
      locationRating: 0,
      expectationComment: '',
      comment: '',
    },

    onSubmit: async (values) => {
      postReview(values);
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    handleChange,
    handleBlur,
  } = formik;

  const showReviews = () => handlePopupOpen('showReviews');
  const sendReviews = () => handlePopupOpen('sendReviews');

  // const editReviews = () => {
  //   setRatingState({
  //     rating: reviewData.rating,
  //     cleanlinessRating: reviewData.cleanlinessRating,
  //     accuracyRating: reviewData.accuracyRating,
  //     valueRating: reviewData.valueRating,
  //     communicationRating: reviewData.communicationRating,
  //     locationRating: reviewData.locationRating,
  //     expectationComment: reviewData.expectationComment,
  //     comment: reviewData.comment,
  //   });
  //   sendReviews();
  // };
  console.log('requestDataForReview', requestDataForReview);
  console.log('review data', reviewData);
  console.log('isLoggedin', !isLoggedin);
  console.log(
    'check condition',
    reviewData || !isLoggedin || !requestDataForReview
      ? 'a'
      : requestDataForReview.status === 'paid'
      ? 'b'
      : 'c'
  );

  return (
    <>
      <DialogPopup
        open={open}
        setOpen={setOpen}
        message={message}
        size="xs"
        statusCode={statusCode}
      />
      <Box>
        <Container fixed={true} sx={{ maxWidth: { xl: '1326px' } }}>
          <Divider />
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                mb: 3,
              }}
            >
              {/* <Button
                  size="small"
                 variant="soft"
                  sx={{ ml: 2 }}
                  onClick={editReviews}
               >
                  <Icon
                   style={{ marginRight: 8 }}
                  icon="fluent:edit-20-filled"
                  />
                  Edit your review
               </Button> */}
              {/* <RatingPreview rating={rating ? rating : 0} readOnly /> */}
              <StyledRating
                defaultValue={rating ? rating : 0}
                value={rating ? rating : 0}
                // defaultValue={1.3}
                readOnly
                icon={<Icon icon="ph:star-fill" />}
                emptyIcon={<Icon icon="ph:star-fill" />}
                sx={{
                  fontSize: {
                    xs: '1rem',
                    md: '1rem',
                    lg: '1.125rem',
                    xl: '1.25rem',
                  },
                }}
              />

              <Typography variant="h6">
                {propertyReview.length} reviews
              </Typography>
              {reviewData ||
              !isLoggedin ||
              !requestDataForReview ? null : requestDataForReview.status ===
                'paid' ? (
                <Button
                  size="small"
                  variant="soft"
                  sx={{ ml: 2 }}
                  onClick={sendReviews}
                >
                  <Icon
                    style={{ marginRight: 8 }}
                    icon="fluent:edit-20-filled"
                  />
                  Write your review
                </Button>
              ) : null}
            </Box>
            <Grid container>
              <Grid item lg={10} xs={12}>
                {rating ? (
                  <List
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: { sm: 'auto auto', xs: 'auto' },
                      columnGap: { md: 15, xs: 2 },
                      rowGap: 1,
                      p: 0,
                    }}
                  >
                    <ListItem sx={{ px: 0, justifyContent: 'space-between' }}>
                      <Typography variant="body1">Cleanliness</Typography>
                      <Stack flexDirection="row" alignItems="center">
                        <BorderLinearProgress
                          variant="determinate"
                          value={cleanlinessRating * 20}
                        />
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          {cleanlinessRating}
                        </Typography>
                      </Stack>
                    </ListItem>
                    <ListItem sx={{ px: 0, justifyContent: 'space-between' }}>
                      <Typography variant="body1">Accuracy</Typography>
                      <Stack flexDirection="row" alignItems="center">
                        <BorderLinearProgress
                          variant="determinate"
                          value={accuracyRating * 20}
                        />
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          {accuracyRating}
                        </Typography>
                      </Stack>
                    </ListItem>
                    <ListItem sx={{ px: 0, justifyContent: 'space-between' }}>
                      <Typography variant="body1">Communication</Typography>
                      <Stack flexDirection="row" alignItems="center">
                        <BorderLinearProgress
                          variant="determinate"
                          value={communicationRating * 20}
                        />
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          {communicationRating}
                        </Typography>
                      </Stack>
                    </ListItem>
                    <ListItem sx={{ px: 0, justifyContent: 'space-between' }}>
                      <Typography variant="body1">Location</Typography>
                      <Stack flexDirection="row" alignItems="center">
                        <BorderLinearProgress
                          variant="determinate"
                          value={locationRating * 20}
                        />
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          {locationRating}
                        </Typography>
                      </Stack>
                    </ListItem>
                    {/* <ListItem sx={{ px: 0, justifyContent: 'space-between' }}>
                      <Typography variant="body1">Check-in</Typography>
                      <Stack flexDirection="row" alignItems="center">
                        <BorderLinearProgress
                          variant="determinate"
                          value={100}
                        />
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          5.0
                        </Typography>
                      </Stack>
                    </ListItem> */}
                    <ListItem sx={{ px: 0, justifyContent: 'space-between' }}>
                      <Typography variant="body1">Value</Typography>
                      <Stack flexDirection="row" alignItems="center">
                        <BorderLinearProgress
                          variant="determinate"
                          value={valueRating * 20}
                        />
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          {valueRating}
                        </Typography>
                      </Stack>
                    </ListItem>
                  </List>
                ) : null}

                <Stack
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { sm: 'auto auto', xs: 'auto' },
                    columnGap: { md: 15, xs: 2 },
                    rowGap: 4,
                    pt: 4,
                  }}
                >
                  {propertyReview.length !== 0
                    ? propertyReview.slice(0, 6).map((item, index) => {
                        return (
                          <Box key={index}>
                            <Stack flexDirection="row" sx={{ mb: 2 }}>
                              <Avatar
                                src={`${IMG_URL}${item.reviewerData[0].profile}`}
                                sx={{ width: '48px', height: '48px' }}
                                alt="photoURL"
                              />
                              <Box sx={{ ml: 2 }}>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ color: 'text.primary' }}
                                >
                                  {item.reviewerData[0].firstname}{' '}
                                  {item.reviewerData[0].lastname}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{ color: 'text.secondary' }}
                                >
                                  {new Date(item.createdAt).toLocaleDateString(
                                    'en-US',
                                    {
                                      month: 'long',
                                      year: 'numeric',
                                    }
                                  )}
                                  {/* November 2022 */}
                                </Typography>
                              </Box>
                            </Stack>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                              {item.comment}
                            </Typography>
                            <Typography
                              variant="button"
                              sx={{ mb: 2, textTransform: 'none' }}
                            >
                              {/* <Link to="/" style={{ color: '#323232' }}>
                                Show more
                              </Link> */}
                            </Typography>
                          </Box>
                        );
                      })
                    : 'No reviews yet'}
                </Stack>
                <Box sx={{ mt: 4 }}>
                  {propertyReview.length > 6 ? (
                    <Button
                      variant="outlined"
                      size="large"
                      sx={{ minWidth: '290px' }}
                      onClick={showReviews}
                    >
                      Show all reviews
                    </Button>
                  ) : null}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      <Dialog
        open={popupOpen}
        onClose={handlePopupClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
        sx={{
          '& .MuiDialog-paper': {
            flexDirection: {
              md: popupOpen === 'showReviews' ? 'row' : 'column',
              width: '100%',
            },
          },
        }}
      >
        {popupOpen === 'showReviews' ? (
          <>
            <DialogTitle id="scroll-dialog-title">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  mb: 3,
                }}
              >
                <StyledRating
                  defaultValue={rating ? rating : 0}
                  value={rating ? rating : 0}
                  readOnly
                  icon={<Icon icon="ph:star-fill" />}
                  emptyIcon={<Icon icon="ph:star-fill" />}
                  sx={{
                    fontSize: {
                      xs: '1rem',
                      md: '1rem',
                      lg: '1.125rem',
                      xl: '1.25rem',
                    },
                  }}
                />
                <Typography variant="h6">
                  {propertyReview.length} reviews
                </Typography>
              </Box>
              <List
                sx={{
                  p: 0,
                  display: { md: 'block', xs: 'none' },
                }}
              >
                <ListItem sx={{ px: 0, justifyContent: 'space-between' }}>
                  <Typography variant="body1">Cleanliness</Typography>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    sx={{ maxWidth: '50%' }}
                  >
                    <BorderLinearProgress
                      variant="determinate"
                      value={cleanlinessRating * 20}
                    />
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      {cleanlinessRating}
                    </Typography>
                  </Stack>
                </ListItem>
                <ListItem sx={{ px: 0, justifyContent: 'space-between' }}>
                  <Typography variant="body1">Accuracy</Typography>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    sx={{ maxWidth: '50%' }}
                  >
                    <BorderLinearProgress
                      variant="determinate"
                      value={accuracyRating * 20}
                    />
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      {accuracyRating}
                    </Typography>
                  </Stack>
                </ListItem>
                <ListItem sx={{ px: 0, justifyContent: 'space-between' }}>
                  <Typography variant="body1">Communication</Typography>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    sx={{ maxWidth: '50%' }}
                  >
                    <BorderLinearProgress
                      variant="determinate"
                      value={communicationRating * 20}
                    />
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      {communicationRating}
                    </Typography>
                  </Stack>
                </ListItem>
                <ListItem sx={{ px: 0, justifyContent: 'space-between' }}>
                  <Typography variant="body1">Location</Typography>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    sx={{ maxWidth: '50%' }}
                  >
                    <BorderLinearProgress
                      variant="determinate"
                      value={locationRating * 20}
                    />
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      {locationRating}
                    </Typography>
                  </Stack>
                </ListItem>
                {/* <ListItem sx={{ px: 0, justifyContent: 'space-between' }}>
                  <Typography variant="body1">Check-in</Typography>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    sx={{ maxWidth: '50%' }}
                  >
                    <BorderLinearProgress variant="determinate" value={100} />
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      5.0
                    </Typography>
                  </Stack>
                </ListItem> */}
                <ListItem sx={{ px: 0, justifyContent: 'space-between' }}>
                  <Typography variant="body1">Value</Typography>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    sx={{ maxWidth: '50%' }}
                  >
                    <BorderLinearProgress
                      variant="determinate"
                      value={valueRating * 20}
                    />
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      {valueRating}
                    </Typography>
                  </Stack>
                </ListItem>
              </List>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handlePopupClose}
                aria-label="close"
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Icon icon="iconamoon:close-light" />
              </IconButton>
            </DialogTitle>
            <DialogContent id="scroll-dialog-description">
              <Box sx={{ pt: { md: 8 } }}>
                <List
                  sx={{
                    p: 0,
                    display: { md: 'none' },
                  }}
                >
                  <ListItem sx={{ px: 0, justifyContent: 'space-between' }}>
                    <Typography variant="body1">Cleanliness</Typography>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      sx={{ maxWidth: '50%' }}
                    >
                      <BorderLinearProgress
                        variant="determinate"
                        value={cleanlinessRating * 20}
                      />
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        {cleanlinessRating}
                      </Typography>
                    </Stack>
                  </ListItem>
                  <ListItem sx={{ px: 0, justifyContent: 'space-between' }}>
                    <Typography variant="body1">Accuracy</Typography>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      sx={{ maxWidth: '50%' }}
                    >
                      <BorderLinearProgress
                        variant="determinate"
                        value={accuracyRating * 20}
                      />
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        {accuracyRating}
                      </Typography>
                    </Stack>
                  </ListItem>
                  <ListItem sx={{ px: 0, justifyContent: 'space-between' }}>
                    <Typography variant="body1">Communication</Typography>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      sx={{ maxWidth: '50%' }}
                    >
                      <BorderLinearProgress
                        variant="determinate"
                        value={communicationRating * 20}
                      />
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        {communicationRating}
                      </Typography>
                    </Stack>
                  </ListItem>
                  <ListItem sx={{ px: 0, justifyContent: 'space-between' }}>
                    <Typography variant="body1">Location</Typography>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      sx={{ maxWidth: '50%' }}
                    >
                      <BorderLinearProgress
                        variant="determinate"
                        value={locationRating * 20}
                      />
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        {locationRating}
                      </Typography>
                    </Stack>
                  </ListItem>
                  {/* <ListItem sx={{ px: 0, justifyContent: 'space-between' }}>
                    <Typography variant="body1">Check-in</Typography>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      sx={{ maxWidth: '50%' }}
                    >
                      <BorderLinearProgress variant="determinate" value={100} />
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        5.0
                      </Typography>
                    </Stack>
                  </ListItem> */}
                  <ListItem sx={{ px: 0, justifyContent: 'space-between' }}>
                    <Typography variant="body1">Value</Typography>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      sx={{ maxWidth: '50%' }}
                    >
                      <BorderLinearProgress
                        variant="determinate"
                        value={valueRating * 20}
                      />
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        {valueRating}
                      </Typography>
                    </Stack>
                  </ListItem>
                </List>
                <Stack>
                  {propertyReview.map((item, index) => {
                    return (
                      <Box key={index} sx={{ mb: 3 }}>
                        <Stack flexDirection="row" sx={{ mb: 2 }}>
                          <Avatar
                            src={`${IMG_URL}${item.reviewerData[0].profile}`}
                            // src={
                            //   item.reviewerData[0].profile
                            //     ? `${IMG_URL}${item.reviewerData[0].profile}`
                            //     : null
                            // }
                            sx={{ width: '48px', height: '48px' }}
                            alt="photoURL"
                          />
                          <Box sx={{ ml: 2 }}>
                            <Typography
                              variant="subtitle1"
                              sx={{ color: 'text.primary' }}
                            >
                              {item.reviewerData[0].firstname}{' '}
                              {item.reviewerData[0].lastname}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ color: 'text.secondary' }}
                            >
                              {new Date(item.createdAt).toLocaleDateString(
                                'en-US',
                                {
                                  month: 'long',
                                  year: 'numeric',
                                }
                              )}
                            </Typography>
                          </Box>
                        </Stack>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                          {item.comment}
                        </Typography>
                      </Box>
                    );
                  })}
                </Stack>
              </Box>
            </DialogContent>
          </>
        ) : popupOpen === 'sendReviews' ? (
          <>
            <DialogTitle
              id="scroll-dialog-title"
              variant="h3"
              sx={{ textAlign: 'center' }}
            >
              Review
              <IconButton
                aria-label="close"
                onClick={handlePopupClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Icon icon="iconamoon:close-light" />
              </IconButton>
            </DialogTitle>
            <DialogContent
              id="scroll-dialog-description"
              sx={{ py: 5 }}
              dividers
            >
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Box sx={{ maxWidth: 720, m: '0 auto' }}>
                    <Box sx={{ textAlign: 'center', py: { md: 4, xs: 1 } }}>
                      <Avatar
                        sx={{
                          width: { lg: 173, md: 120, xs: 82 },
                          height: { lg: 173, md: 120, xs: 82 },
                          m: '0 auto 32px',
                        }}
                        src={`${IMG_URL}${profile}`}
                        alt={firstname}
                      />
                      <Typography variant="h2">
                        {firstname + ' ' + lastname}
                      </Typography>
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: 'normal',
                          textTransform: 'capitalize',
                        }}
                      >
                        {role}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box sx={{ textAlign: 'center', py: 4 }}>
                      <Typography variant="h4" sx={{ mb: 3 }}>
                        How would you rate your experiance with Minimalist Room?
                      </Typography>
                      <StyledRating
                        name="rating"
                        defaultValue={4}
                        value={values.rating}
                        onChange={handleChange}
                        icon={<Icon icon="ph:star-fill" />}
                        emptyIcon={<Icon icon="ph:star-fill" />}
                        sx={{
                          fontSize: {
                            xs: '1.875rem',
                            md: '1.875rem',
                            lg: '2.0625rem',
                            xl: '3rem',
                          },
                        }}
                      />
                    </Box>
                    <Divider />
                    <Box>
                      <List
                        sx={{
                          p: 0,
                        }}
                      >
                        <ListItem
                          sx={{
                            px: 0,
                            py: 2,
                            justifyContent: 'space-between',
                            flexDirection: { sm: 'row', xs: 'column' },
                            gap: { sm: 0, xs: 2 },
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                          }}
                        >
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: 'normal',
                              fontSize: { md: 24, xs: 16 },
                            }}
                          >
                            Cleanliness
                          </Typography>
                          <StyledRating
                            name="cleanlinessRating"
                            defaultValue={4}
                            value={values.cleanlinessRating}
                            onChange={(event, newValue) => {
                              setFieldValue(
                                'cleanlinessRating',
                                newValue === null ? 0 : newValue
                              );
                            }}
                            icon={<Icon icon="ph:star-fill" />}
                            emptyIcon={<Icon icon="ph:star-fill" />}
                            sx={{
                              fontSize: '1.5rem',
                            }}
                          />
                        </ListItem>
                        <ListItem
                          sx={{
                            px: 0,
                            py: 2,
                            justifyContent: 'space-between',
                            flexDirection: { sm: 'row', xs: 'column' },
                            gap: { sm: 0, xs: 2 },
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                          }}
                        >
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: 'normal',
                              fontSize: { md: 24, xs: 16 },
                            }}
                          >
                            Accuracy
                          </Typography>
                          <StyledRating
                            name="accuracyRating"
                            defaultValue={4}
                            value={values.accuracyRating}
                            // onChange={handleChange}
                            onChange={(event, newValue) => {
                              setFieldValue(
                                'accuracyRating',
                                newValue === null ? 0 : newValue
                              );
                            }}
                            icon={<Icon icon="ph:star-fill" />}
                            emptyIcon={<Icon icon="ph:star-fill" />}
                            sx={{
                              fontSize: '1.5rem',
                            }}
                          />
                        </ListItem>
                        <ListItem
                          sx={{
                            px: 0,
                            py: 2,
                            justifyContent: 'space-between',
                            flexDirection: { sm: 'row', xs: 'column' },
                            gap: { sm: 0, xs: 2 },
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                          }}
                        >
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: 'normal',
                              fontSize: { md: 24, xs: 16 },
                            }}
                          >
                            Value
                          </Typography>
                          <StyledRating
                            name="valueRating"
                            defaultValue={4}
                            value={values.valueRating}
                            onChange={(event, newValue) => {
                              setFieldValue(
                                'valueRating',
                                newValue === null ? 0 : newValue
                              );
                            }}
                            icon={<Icon icon="ph:star-fill" />}
                            emptyIcon={<Icon icon="ph:star-fill" />}
                            sx={{
                              fontSize: '1.5rem',
                            }}
                          />
                        </ListItem>
                        <ListItem
                          sx={{
                            px: 0,
                            py: 2,
                            justifyContent: 'space-between',
                            flexDirection: { sm: 'row', xs: 'column' },
                            gap: { sm: 0, xs: 2 },
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                          }}
                        >
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: 'normal',
                              fontSize: { md: 24, xs: 16 },
                            }}
                          >
                            Communication
                          </Typography>
                          <StyledRating
                            name="communicationRating"
                            defaultValue={4}
                            value={values.communicationRating}
                            onChange={(event, newValue) => {
                              setFieldValue(
                                'communicationRating',
                                newValue === null ? 0 : newValue
                              );
                            }}
                            icon={<Icon icon="ph:star-fill" />}
                            emptyIcon={<Icon icon="ph:star-fill" />}
                            sx={{
                              fontSize: '1.5rem',
                            }}
                          />
                        </ListItem>
                        <ListItem
                          sx={{
                            px: 0,
                            py: 2,
                            justifyContent: 'space-between',
                            flexDirection: { sm: 'row', xs: 'column' },
                            gap: { sm: 0, xs: 2 },
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                          }}
                        >
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: 'normal',
                              fontSize: { md: 24, xs: 16 },
                            }}
                          >
                            Location
                          </Typography>
                          <StyledRating
                            name="locationRating"
                            defaultValue={4}
                            value={values.locationRating}
                            onChange={(event, newValue) => {
                              setFieldValue(
                                'locationRating',
                                newValue === null ? 0 : newValue
                              );
                            }}
                            icon={<Icon icon="ph:star-fill" />}
                            emptyIcon={<Icon icon="ph:star-fill" />}
                            sx={{
                              fontSize: '1.5rem',
                            }}
                          />
                        </ListItem>
                      </List>
                    </Box>
                    <Box sx={{ textAlign: 'center', py: 4 }}>
                      <Typography variant="h4">
                        How did you stay at Minimalist compare to your
                        expectations?
                      </Typography>
                    </Box>
                    <Box>
                      <List
                        sx={{
                          p: 0,
                        }}
                      >
                        {expectationsList.map((val, index) => (
                          <ListItem
                            key={index}
                            sx={{
                              px: 0,
                              py: 2,
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography
                              variant="h4"
                              sx={{
                                fontWeight: 'normal',
                                fontSize: { md: 24, xs: 16 },
                              }}
                            >
                              {val.label}
                            </Typography>
                            <Radio
                              checked={values.expectationComment === val.label}
                              onChange={handleChange}
                              value={val.label}
                              name="expectationComment"
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                    <Divider />
                    <Box sx={{ textAlign: 'center', py: 4 }}>
                      <TextField
                        name="comment"
                        multiline
                        onChange={handleChange}
                        value={values.comment}
                        rows={5}
                        label="Additional Comments (optional)"
                        fullWidth
                      />
                    </Box>
                    <Divider />
                    <Box sx={{ py: 4 }}>
                      <Stack
                        justifyContent="end"
                        gap="24px"
                        sx={{
                          flexDirection: { sm: 'row' },
                        }}
                      >
                        <Button
                          variant="outlined"
                          size="large"
                          sx={{ minWidth: 200 }}
                          onClick={handlePopupClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          size="large"
                          type="submit"
                          sx={{ minWidth: 200 }}
                        >
                          Send
                        </Button>
                      </Stack>
                    </Box>
                  </Box>
                </Form>
              </FormikProvider>
            </DialogContent>
          </>
        ) : (
          ''
        )}
      </Dialog>
    </>
  );
};
