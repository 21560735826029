import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Typography,
  Stack,
  CardContent,
  Checkbox,
  Rating,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import SvgColor from '../svg-color';
import Bed from '../../assets/icons/Bed.svg';
import Like from '../../assets/icons/Like.svg';
import nophoto from '../../assets/images/nophoto.jpg';
import Bathroom from '../../assets/icons/Bathroom.svg';
import ArrowIconLeft from '../../assets/icons/chevron-left.svg';
import ArrowIconRight from '../../assets/icons/chevron-right.svg';
import { IMG_URL, nairaSymbol } from '../../utils/constant';
import { addToFavorite } from '../../services/apiServices';
import { DialogPopup } from '../dialog-popup/DialogPopup';
import { currencySeprator } from '../../utils/common-function';
import { useAuthStore } from '../../redux/features/authSlice';

const StyledProductImg = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const StyledRating = styled(Rating)({
  '& .MuiRating-iconEmpty': {
    color: '#D3D3DC',
  },
  '& .MuiRating-iconFilled': {
    color: '#FFBD3E',
  },
  '& .MuiRating-iconHover': {
    color: '#FFBD3E',
  },
});

const FavoriteIconButton = styled(Box)(({ theme }) => ({
  zIndex: 9,
  top: 16,
  right: 16,
  position: 'absolute',
  '& .MuiCheckbox-root': {
    border: 'none !important',
    width: 'auto',
    height: 'auto',
    padding: 8,
    borderRadius: '50%',
    color: '#fff',
    '& > *': {
      fontSize: '24px',
    },
    '&.Mui-checked': {
      color: '#FF6645',
    },
  },
}));
const CardIcon = styled(Box)(({ theme }) => ({
  background: '#E9EAF0',
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '8px',
}));

const CarouselDiv = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  '& .slick-slider': {
    height: '100%',
    width: '100%',
    '& .slick-list': {
      height: '100%',
      width: '100%',
      '& .slick-track': {
        height: '100%',
        '& .slick-slide': {
          '&>div': {
            height: '100%',
            width: '100%',
          },
        },
      },
    },
    '& .slick-arrow': {
      fontSize: '0',
      width: '28px !important',
      height: '28px !important',
      zIndex: '1',
      transition: '0.45s ease-in-out',
      borderRadius: '50%',
      opacity: '0',
      margin: '0 !important',
      bottom: 'auto',
      top: 'calc(50% - 10px)',
      '&:before': {
        fontSize: '0',
      },
      '&.slick-prev': {
        background: `rgba(255,255,255,1) url(${ArrowIconLeft}) no-repeat center center / 70% !important`,
        left: '5px !important',
        right: 'auto !important',
      },
      '&.slick-next': {
        background: `rgba(255,255,255,1) url(${ArrowIconRight}) no-repeat center center / 70% !important`,
        right: '5px !important',
        left: 'auto !important',
      },
      '&.slick-disabled': {
        display: 'none !important',
      },
    },
    '&:hover': {
      '& .slick-arrow': {
        opacity: '1',
      },
    },
    '& .slick-dots': {
      bottom: '10px',
      maxWidth: '55%',
      left: '50%',
      transform: 'translate(-50%, 0)',
      display: 'flex !important',
      alignItems: 'center',
      justifyContent: 'center',
      '& li': {
        margin: '0',
        width: '8px',
        height: '8px',
        '& button': {
          width: '5px',
          height: '5px',
        },
        '& button:before': {
          fontSize: '22px',
          color: '#fff',
        },
      },
    },
  },
}));

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: false,
};

export const PropertyCard = (props) => {
  let { propertyValues, sx, renderFlag, setRenderFlag, bedroomFilter } = props;
  let { isLoggedin } = useAuthStore();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  let {
    title,
    address,
    propertyUnits,
    bathrooms,
    bedrooms,
    propertyImages,
    _id,
    isFavorite,
    rating,
  } = propertyValues;

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [statusCode, setStatusCode] = useState();

  const handleFav = async (event) => {
    try {
      if (isLoggedin) {
        let payload = {
          propertyId: _id,
          flag: event.target.checked,
        };

        let { data } = await addToFavorite(payload);
        if (data.statusCode === 200) {
          setRenderFlag(!renderFlag);
          setStatusCode(data.statusCode);
        } else {
          setOpen(true);
          setMessage(data.message);
          setStatusCode(data.statusCode);
        }
      } else {
        navigate('/login');
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  return (
    <Card
      sx={{
        width: '409px',
        maxWidth: '100%',
        margin: '0 auto',
        boxShadow: 'none',
        ...sx,
      }}
    >
      <DialogPopup
        open={open}
        setOpen={setOpen}
        message={message}
        size="xs"
        statusCode={statusCode}
      />
      <CardContent sx={{ p: { xs: 1.5, md: 2, lg: 2.5 } }}>
        <Box
          className="sliderItemImg"
          sx={{
            position: 'relative',
            marginBottom: '24px',
            aspectRatio: '1.10',
            borderRadius: '14px',
            overflow: 'hidden',
            border: '1px solid #EBEBEB',
          }}
        >
          <FavoriteIconButton>
            <Checkbox
              checked={
                isFavorite ? (isFavorite.length !== 0 ? true : false) : false
              }
              onChange={handleFav}
              icon={<Icon icon="ion:heart-outline" />}
              checkedIcon={<Icon icon="ion:heart-sharp" />}
            />
          </FavoriteIconButton>

          {propertyImages ? (
            <CarouselDiv>
              <Slider {...settings}>
                {propertyImages.map((img, index) => (
                  <Box sx={{ height: '100%' }} key={index}>
                    <Link
                      color="inherit"
                      underline="hover"
                      // navigate(`/available-spaces?lat=${latLng.lat}&&lng=${latLng.lng}`);
                      to={`/property-details/${_id}?bedroom=${bedroomFilter}`}
                      style={{
                        textDecoration: 'none',
                        height: '100%',
                        width: '100%',
                        display: 'block',
                      }}
                    >
                      <StyledProductImg
                        key={index}
                        alt={title}
                        src={`${IMG_URL}${img.propertyImage}`}
                      />
                    </Link>
                  </Box>
                ))}
              </Slider>
            </CarouselDiv>
          ) : (
            <Box sx={{ height: '100%' }}>
              <Link
                color="inherit"
                underline="hover"
                // to={'/property-details/' + _id}
                to={`/property-details/${_id}?bedroom=${bedroomFilter}`}
                style={{
                  textDecoration: 'none',
                  height: '100%',
                  width: '100%',
                  display: 'block',
                }}
              >
                <StyledProductImg alt={title} src={nophoto} />
              </Link>
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', textTransform: 'capitalize' }}>
          <Box
            sx={{
              flexGrow: '1',
              width: 'calc(100% - 100px)',
            }}
          >
            <Link
              color="inherit"
              underline="hover"
              style={{ textDecoration: 'none' }}
              to={`/property-details/${_id}?bedroom=${bedroomFilter}`}
            >
              <Typography
                variant="h5"
                noWrap
                sx={{
                  color: 'text.primary',
                  textTransform: 'capitalize',
                  fontSize: {
                    xs: '1rem',
                    md: '1rem',
                    lg: '1.125rem',
                    xl: '1.25rem',
                  },
                }}
              >
                {title}
              </Typography>
              <Typography
                variant="body2"
                noWrap
                sx={{
                  color: 'text.secondary',
                  fontSize: {
                    xs: '0.75rem',
                    md: '0.75rem',
                    lg: '0.813rem',
                    xl: '0.875rem',
                  },
                }}
              >
                {address}
              </Typography>
            </Link>
          </Box>
          <StyledRating
            defaultValue={rating ? rating : 0}
            value={rating ? rating : 0}
            readOnly
            icon={<Icon icon="ph:star-fill" />}
            emptyIcon={<Icon icon="ph:star-fill" />}
            sx={{
              fontSize: {
                xs: '1rem',
                md: '1rem',
                lg: '1.125rem',
                xl: '1.25rem',
              },
            }}
          />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          marginTop="24px"
          textTransform="capitalize"
        >
          <Typography
            variant="body2"
            style={{
              color: '#323232',
              textTransform: 'capitalize',
              margin: '0',
            }}
          >
            {propertyUnits ? (
              propertyUnits.length > 1 ? (
                <span>
                  <b>
                    {nairaSymbol}
                    {currencySeprator(
                      Math.min(...propertyUnits.map((item) => item.price))
                    )}
                  </b>{' '}
                  -
                  <b>
                    {nairaSymbol}
                    {currencySeprator(
                      Math.max(...propertyUnits.map((item) => item.price))
                    )}
                  </b>
                </span>
              ) : (
                <span>
                  <b>
                    {nairaSymbol}
                    {currencySeprator(
                      Math.min(...propertyUnits.map((item) => item.price))
                    )}
                  </b>
                </span>
              )
            ) : null}
          </Typography>

          <Stack direction="row">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'text.secondary',
                ml: 2,
              }}
            >
              <CardIcon>
                <SvgColor
                  sx={{
                    width: '10px',
                    height: '10px',
                  }}
                  src={Bed}
                />
              </CardIcon>
              <Typography variant="caption">
                {Math.min(...propertyUnits.map((item) => item.bedrooms))} Bed
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'text.secondary',
                ml: 2,
              }}
            >
              <CardIcon>
                <SvgColor
                  sx={{
                    width: '10px',
                    height: '10px',
                  }}
                  src={Bathroom}
                />
              </CardIcon>
              <Typography variant="caption">
                {Math.min(...propertyUnits.map((item) => item.bathrooms))}{' '}
                Bathroom
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
