import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Container,
  Typography,
  Button,
  Stack,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { PropertyGrid } from './PropertyGrid';
import PropertyMap from './PropertyMap';
import { PropertyFilter } from './PropertyFilter';
import Filter from '../../assets/icons/Filter.svg';
import MapPin from '../../assets/icons/map-pin.svg';
import GridIcon from '../../assets/icons/GridIcon.svg';
import SvgColor from '../../components/svg-color';
import { DialogPopup } from '../../components/dialog-popup/DialogPopup';
import { fetchAllCities, getAllProperty } from '../../services/apiServices';
import { useAuthStore } from '../../redux/features/authSlice';

const StylePopular = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  padding: '50px 0',
  [theme.breakpoints.down('lg')]: {
    padding: '56px 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: '40px 0',
  },
}));

export const ListSpacesSection = (props) => {
  let {
    resultRef,
    availRenderFlag,
    setAvailRenderFlag,
    bedroomFilter,
    handleClickOpen,
    visibleProperties,
    setVisibleProperties,
    propertiesData,
    totalProperty,
  } = props;
  // const navigate = useNavigate();
  // const { pathname } = useLocation();
  // let { loginInfo } = useAuthStore();

  const query = new URLSearchParams(useLocation().search);
  const lat = query.get('lat');
  const lng = query.get('lng');
  const [show, setShow] = useState(false);
  const [list, setList] = useState();

  const handleList = (list) => {
    setList(list);
    setShow(true);
  };

  const mapSec = () => handleList('mapSec');
  const gridSec = () => handleList('gridSec');
  const loadMore = () => {
    setVisibleProperties(visibleProperties + 3);
  };

  return (
    <>
      <StylePopular ref={resultRef}>
        <Container fixed={true} sx={{ maxWidth: { xl: '1326px' } }}>
          <Grid container spacing={3} sx={{ mb: 5, alignItems: 'center' }}>
            <Grid item md={6} xs={12}>
              <Box>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 'normal', color: 'text.secondary' }}
                >
                  {lat && lng ? (
                    <>
                      Search Result {propertiesData.length} of {totalProperty}{' '}
                      Spaces
                    </>
                  ) : (
                    <>Total {totalProperty} spaces</>
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Stack
                flexDirection="row"
                gap="24px"
                sx={{ justifyContent: { md: 'end', xs: 'start' } }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ height: '44px', borderRadius: '8px', p: 1.5 }}
                  onClick={handleClickOpen}
                >
                  <SvgColor
                    sx={{ mr: 1.5, width: '18px', height: '18px' }}
                    src={Filter}
                  />
                  Filter spaces
                </Button>
                <Button
                  variant="outlined"
                  color={
                    list === 'mapSec'
                      ? 'secondary'
                      : list === 'gridSec'
                      ? 'primary'
                      : 'primary'
                  }
                  sx={{
                    width: '44px',
                    height: '44px',
                    minWidth: 'auto',
                    borderRadius: '8px',
                    p: 1.5,
                  }}
                  onClick={mapSec}
                >
                  <SvgColor
                    sx={{ width: '18px', height: '18px' }}
                    src={MapPin}
                  />
                </Button>
                <Button
                  variant="outlined"
                  color={list === 'mapSec' ? 'primary' : 'secondary'}
                  sx={{
                    width: '44px',
                    height: '44px',
                    minWidth: 'auto',
                    borderRadius: '8px',
                    p: 1.5,
                  }}
                  onClick={gridSec}
                >
                  <SvgColor
                    sx={{ width: '18px', height: '18px' }}
                    src={GridIcon}
                  />
                </Button>
              </Stack>
            </Grid>
          </Grid>
          {/* <PropertyFilter
            setRenderFlag={setAvailRenderFlag}
            renderFlag={availRenderFlag}
            setPriceRangeFilter={setPriceRangeFilter}
            setPriceDuration={setPriceDuration}
            setCities={setCities}
            setBedroomFilter={setBedroomFilter}
            setPropertyTypeFilter={setPropertyTypeFilter}
            setAmenitiesFilter={setAmenitiesFilter}
            handleClose={handleClose}
            setDateFilter={setDateFilter}
            open={openFilterPop}
            city={cityCheckbox}
            setMoveInDate={setMoveInDate}
            moveInDate={moveInDate}

            setLeaseDuration={setLeaseDuration}
            leaseDuration={leaseDuration}
          /> */}
          {list === 'mapSec' ? (
            <PropertyMap
              lat={lat}
              lng={lng}
              propertiesData={propertiesData}
              totalProperty={totalProperty}
            />
          ) : (
            <PropertyGrid
              loadMore={loadMore}
              totalProperty={totalProperty}
              visibleProperties={visibleProperties}
              bedroomFilter={bedroomFilter}
              // searchCount={lat && lng ? propertiesData.length : totalProperty}
              propertiesData={propertiesData}
              renderFlag={availRenderFlag}
              setRenderFlag={setAvailRenderFlag}
            />
          )}
        </Container>
      </StylePopular>
    </>
  );
};
