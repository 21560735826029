import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  DialogContent,
  DialogContentText,
  Button,
  Typography,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

export const DialogPopup = (props) => {
  let { open, message, setOpen, url, size, statusCode } = props;
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={size ? size : 'md'}
        sx={{
          '& .MuiDialog-paper': {
            width: '100%',
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon icon="ic:round-close" />
        </IconButton>
        <DialogContent sx={{ px: 3, pb: 1.5, pt: 4, textAlign: 'center' }}>
          {statusCode === 200 ? (
            <div className="svg-box">
              <svg className="circular green-stroke">
                <circle
                  className="path"
                  cx="75"
                  cy="75"
                  r="50"
                  fill="none"
                  stroke-width="5"
                  stroke-miterlimit="10"
                />
              </svg>
              <svg className="checkmark green-stroke">
                <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                  <path
                    className="checkmark__check"
                    fill="none"
                    d="M616.306,283.025L634.087,300.805L673.361,261.53"
                  />
                </g>
              </svg>
            </div>
          ) : (
            <>
              {/* <div className="svg-box">
                <svg className="circular red-stroke">
                  <circle
                    className="path"
                    cx="75"
                    cy="75"
                    r="50"
                    fill="none"
                    stroke-width="5"
                    stroke-miterlimit="10"
                  />
                </svg>
                <svg className="cross red-stroke">
                  <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-502.652,-204.518)">
                    <path
                      className="first-line"
                      d="M634.087,300.805L673.361,261.53"
                      fill="none"
                    />
                  </g>
                  <g transform="matrix(-1.28587e-16,-0.79961,0.79961,-1.28587e-16,-204.752,543.031)">
                    <path
                      className="second-line"
                      d="M634.087,300.805L673.361,261.53"
                    />
                  </g>
                </svg>
              </div> */}
              <div className="svg-box">
                <svg className="circular yellow-stroke">
                  <circle
                    className="path"
                    cx="75"
                    cy="75"
                    r="50"
                    fill="none"
                    stroke-width="5"
                    stroke-miterlimit="10"
                  />
                </svg>
                <svg className="alert-sign yellow-stroke">
                  <g transform="matrix(1,0,0,1,-615.516,-257.346)">
                    <g transform="matrix(0.56541,-0.56541,0.56541,0.56541,93.7153,495.69)">
                      <path
                        className="line"
                        d="M634.087,300.805L673.361,261.53"
                        fill="none"
                      />
                    </g>
                    <g transform="matrix(2.27612,-2.46519e-32,0,2.27612,-792.339,-404.147)">
                      <circle
                        className="dot"
                        cx="621.52"
                        cy="316.126"
                        r="1.318"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </>
          )}

          <Typography
            variant="h6"
            sx={{ fontWeight: 'normal', color: 'text.primary' }}
          >
            {message}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', px: 3, pt: 1.5, pb: 4 }}>
          {/* <Button onClick={props.handleClose}>Disagree</Button> */}
          {url ? (
            <Button
              component={Link}
              to={url}
              autoFocus
              variant="outlined"
              fullWidth
              sx={{ borderRadius: 2, p: 1.2, maxWidth: '120px' }}
            >
              Okay
            </Button>
          ) : (
            <Button
              onClick={handleClose}
              autoFocus
              variant="outlined"
              fullWidth
              sx={{ borderRadius: 2, p: 1.2, maxWidth: '120px' }}
            >
              Okay
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
