import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button, Form, Input, Label } from 'reactstrap';
import { editEmailTemplates } from '../../../services/apiServices';

import 'quill/dist/quill.snow.css';

const EditEmailTemplate = (props) => {
  let {
    isEdit,
    handleClose,
    setResponse,
    setSpinFlag,
    setRenderFlag,
    renderFlag,
  } = props;

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [templateId, setTemplateId] = useState(null);
  const [subject, setSubject] = useState('');
  const [headerContent, setHeaderContent] = useState('');
  const [footerContent, setFooterContent] = useState('');
  const [errors, setErrors] = useState({});

  const handleSubjectChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: '' });
    setSubject(value);
  };

  const handleHeaderContentChange = (e, editor) => {
    const data = editor.getData();
    setErrors({ ...errors, headerContent: '' });
    setHeaderContent(data);
  };

  const handleFooterContentChange = (e, editor) => {
    const data = editor.getData();
    setErrors({ ...errors, footerContent: '' });
    setFooterContent(data);
  };

  const toEditEmailTemplate = async () => {
    try {
      setSpinFlag(true);
      let payload = {
        subject,
        headerContent,
        footerContent,
        templateId,
      };
      let { data } = await editEmailTemplates(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
        setRenderFlag(!renderFlag);
        handleClose();
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
        handleClose();
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation logic
    const newErrors = {};

    if (!subject.trim()) {
      newErrors.subject = 'Subject is required';
    }

    if (!headerContent.trim()) {
      newErrors.headerContent = 'Header content is required';
    }

    if (!footerContent.trim()) {
      newErrors.footerContent = 'Footer content is required';
    }

    // Check for errors
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      console.log('no validataion');
      toEditEmailTemplate();
      //   toAddFaqs();
    }
  };

  useEffect(() => {
    if (isEdit) {
      setSubject(isEdit.subject);
      setHeaderContent(isEdit.headerContent);
      setFooterContent(isEdit.footerContent);
      setTemplateId(isEdit._id);
    }
  }, []);

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <div>
          <div className="mb-3">
            <Label htmlFor="api-key-name" className="form-label">
              Subject:
            </Label>
            <Input
              type="text"
              className="form-control"
              id="api-key-name"
              placeholder="Enter Subject"
              onChange={handleSubjectChange}
              value={subject}
              name="subject"
            />
            {errors.subject && (
              <div className="text-danger">{errors.subject}</div>
            )}
          </div>
          <div className="mb-3">
            <p>Dear Landlord,</p>
          </div>
          <div className="mb-3">
            <Label htmlFor="api-key-name" className="form-label">
              Header content:
            </Label>
            <CKEditor
              editor={ClassicEditor}
              data={headerContent}
              onChange={(e, editor) => {
                handleHeaderContentChange(e, editor);
              }}
            />
            {errors.headerContent && (
              <div className="text-danger">{errors.headerContent}</div>
            )}
          </div>
          <div className="mb-3">
            <Label htmlFor="api-key-name" className="form-label">
              Footer content:
            </Label>
            <CKEditor
              editor={ClassicEditor}
              data={footerContent}
              onChange={(e, editor) => {
                handleFooterContentChange(e, editor);
              }}
            />
            {errors.footerContent && (
              <div className="text-danger">{errors.footerContent}</div>
            )}
          </div>
          <div className="mb-3">
            <div class="hstack gap-2 justify-content-end">
              <Button
                type="button"
                className="btn-light"
                onClick={() => handleClose}
              >
                Cancel
              </Button>
              <Button type="submit" className="btn-success">
                Save
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default EditEmailTemplate;
