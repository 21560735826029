import { Grid, Box, Container, Typography, Stack, Button } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Intersect2 from '../../assets/images/Intersect2.png';
import Rectangle5201 from '../../assets/images/Rectangle5201.png';
import bannerImg from '../../assets/images/bannerImg.png';
import { IMG_URL } from '../../utils/constant';
import { useAuthStore } from '../../redux/features/authSlice';

const BannerMain = styled(Box)(({ theme }) => ({
  padding: '50px 0',
  position: 'relative',
  '&:before': {
    content: '""',
    background: `url(${Intersect2}) no-repeat top center / 100% 80%, url(${Rectangle5201}) no-repeat top center / 100% auto`,
    position: 'absolute',
    top: '0',
    left: '0',
    height: '390px',
    width: '100%',
  },
  [theme.breakpoints.down('lg')]: {
    padding: '56px 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: '40px 0',
  },
}));
const BannerTop = styled(Box)(({ theme }) => ({
  maxWidth: '860px',
  margin: '0 auto',
  padding: '40px 0 20px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: '100%',
  textAlign: 'center',
  [theme.breakpoints.down('lg')]: {
    padding: '0 0 20px',
  },
}));
const BannerLeftText = styled(Box)(({ theme }) => ({
  paddingBottom: '24px',
  position: 'relative',
  [theme.breakpoints.down('lg')]: {},
  '& .MuiTypography-h2': {
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
      marginBottom: '20px',
    },
  },
  '& .MuiTypography-h6': {
    [theme.breakpoints.down('xl')]: {
      fontSize: '1.0625rem',
    },
  },
}));
const BannerRight = styled(Box)(({ theme }) => ({
  '& img': {
    display: 'block',
    width: '100%',
    aspectRatio: '1/0.95',
  },
}));

export const BannerSection = (props) => {
  let { loginInfo } = useAuthStore();
  let { becomeHostContents, bannerSection } = props;
  return (
    <>
      <BannerMain>
        <Container fixed={true} sx={{ maxWidth: { xl: '1326px' } }}>
          <BannerTop>
            <BannerLeftText>
              {bannerSection && bannerSection.length > 0 && (
                <>
                  <Typography variant="h2" sx={{ mb: 4 }}>
                    {bannerSection[0]?.title}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 'normal', color: 'text.secondary' }}
                  >
                    {bannerSection[0]?.description}
                  </Typography>
                </>
              )}
            </BannerLeftText>
            {loginInfo ? null : (
              <Stack
                direction="row"
                sx={{
                  justifyContent: 'center',
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  sx={{ minWidth: '195px' }}
                  component={Link}
                  to="/sign-up"
                >
                  Sign Up
                </Button>
              </Stack>
            )}
          </BannerTop>
          <Box
            sx={{
              position: 'relative',
              borderRadius: '32px',
              overflow: 'hidden',
              aspectRatio: '1.8',
              maxWidth: 890,
              m: '0 auto',
            }}
          >
            {bannerSection && bannerSection.length > 0 && (
              <>
                {bannerSection[0]?.bannerImage ? (
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                    src={`${IMG_URL}${bannerSection[0]?.bannerImage}`}
                  />
                ) : null}
              </>
            )}
          </Box>
        </Container>
      </BannerMain>
    </>
  );
};
