import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  Stack,
  Backdrop,
  CircularProgress,
  Alert,
  FormHelperText,
} from '@mui/material';
import { RightImg, LeftSection, BrandListBox, BannerLeftMain } from './styled';
import { useNavigate, useLocation } from 'react-router-dom';
import { BrandList } from '../../../components/brand-list';
import Rectangle5196 from '../../../assets/images/Rectangle5196.png';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import { resetPassword } from '../../../services/apiServices';
import { resetPasswordSchema } from '../../../utils/validation/Auth';
import authBack6 from '../../../assets/images/auth_back6.svg';
import authBack7 from '../../../assets/images/auth_back7.svg';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const ResetPassword = () => {
  let query = useQuery();
  let token = query.get('token');
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const [state, setState] = useState({
    showPassword: false,
    showPasswordco: false,
    message: '',
    spinFlag: false,
    showAlert: false,
    showAlertError: false,
  });

  const initialValues = {
    password: '',
    confirmpassword: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: async (values) => {
      let payload = {
        password: values.password,
        token: token,
      };
      try {
        setState({ ...state, spinFlag: true });
        const { data } = await resetPassword(payload);
        if (data.statusCode == 200) {
          setState({
            ...state,
            message: data.message,
            spinFlag: false,
            showAlert: true,
            showAlertError: false,
          });
          resetForm();
          setTimeout(() => {
            navigate('/login');
          }, 2000);
        } else {
          setState({
            ...state,
            message: data.message,
            spinFlag: false,
            showAlert: false,
            showAlertError: true,
          });
          resetForm();
        }
      } catch (error) {
        navigate(`/error?msg=${err}&&path=${pathname}`);
      }
    },
  });

  const handleShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleShowPasswordCo = () => {
    setState({ ...state, showPasswordco: !state.showPasswordco });
  };

  useEffect(() => {
    if (token === null) {
      navigate('/forgot-password');
    }
  }, []);

  const { errors, touched, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: { md: 'end', xs: 'center' },
            background: `url(${authBack6}) no-repeat top right, url(${authBack7}) no-repeat bottom left`,
            order: { md: '1', xs: '2' },
          }}
        >
          <BannerLeftMain>
            <LeftSection>
              <>
                {state.showAlert ? (
                  <Alert sx={{ mb: 2 }} severity="success">
                    {state.message}
                  </Alert>
                ) : (
                  ''
                )}
                {state.showAlertError ? (
                  <Alert sx={{ mb: 2 }} severity="error">
                    {state.message}
                  </Alert>
                ) : (
                  ''
                )}

                <Box sx={{ mb: 6 }}>
                  <Typography
                    component="h6"
                    align="center"
                    sx={{
                      color: '#FEAE01',
                      fontSize: '16px',
                      textTransform: 'uppercase',
                      fontWeight: '600',
                      letterSpacing: { sm: '0.1em' },
                    }}
                  >
                    Reset password
                  </Typography>
                </Box>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            type={state.showPassword ? 'text' : 'password'}
                            label="Password"
                            {...getFieldProps('password')}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleShowPassword}
                                    edge="end"
                                  >
                                    <Icon
                                      icon={
                                        state.showPassword
                                          ? 'eva:eye-fill'
                                          : 'eva:eye-off-fill'
                                      }
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            // error={Boolean(touched.password && errors.password)}
                            // helperText={touched.password && errors.password}
                          />
                          {touched.password && errors.password && (
                            <FormHelperText error>
                              {errors.password}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            type={state.showPasswordco ? 'text' : 'password'}
                            label="Confirm Password"
                            {...getFieldProps('confirmpassword')}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleShowPasswordCo}
                                    edge="end"
                                  >
                                    <Icon
                                      icon={
                                        state.showPasswordco
                                          ? 'eva:eye-fill'
                                          : 'eva:eye-off-fill'
                                      }
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            // error={Boolean(
                            //   touched.confirmpassword && errors.confirmpassword
                            // )}
                            // helperText={
                            //   touched.confirmpassword && errors.confirmpassword
                            // }
                          />
                          {touched.confirmpassword &&
                            errors.confirmpassword && (
                              <FormHelperText error>
                                {errors.confirmpassword}
                              </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Form>
                </FormikProvider>
              </>
            </LeftSection>
            <BrandListBox>
              <BrandList />
            </BrandListBox>
          </BannerLeftMain>
        </Grid>
        <Grid item md={6} xs={12} sx={{ order: { md: '2', xs: '1' } }}>
          <RightImg>
            <img src={Rectangle5196} alt="Rectangle5196" />
          </RightImg>
        </Grid>
      </Grid>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={state.spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
