import React, { useState, useRef } from 'react';
import {
  Grid,
  Box,
  Container,
  Typography,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogActions,
  DialogContentText,
  DialogContent,
  Slide,
} from '@mui/material';
import Slider from 'react-slick';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import ArrowIconLeft from '../../assets/icons/ArrowIconLeft.svg';
import ArrowIconRight from '../../assets/icons/ArrowIconRight.svg';
import menu from '../../assets/icons/menu.svg';
import SvgColor from '../../components/svg-color';
import { IMG_URL } from '../../utils/constant';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const DialogFull = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-rounded': {
    borderRadius: '0',
  },
}));
const CarouselDiv = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  '& .slick-slider': {
    height: '100%',
    width: '100%',
    '& .slick-list': {
      height: '100%',
      width: '100%',
      '& .slick-track': {
        height: '100%',
        '& .slick-slide': {
          '&>div': {
            height: '100%',
            width: '100%',
          },
        },
      },
    },
    '& .slick-dots': {
      bottom: '0',
      '& li': {
        margin: '0',
        '& button:before': {
          fontSize: '22px',
          color: '#fff',
        },
      },
    },
  },
}));
const SliderArrows = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 15,
  [theme.breakpoints.down('md')]: {
    // display: 'none',
  },
  '& .slick-arrow': {
    fontSize: '0',
    width: '40px',
    height: '40px',
    transform: 'inherit',
    position: 'static',
    display: 'block',
    '&:before': {
      fontSize: '0',
    },
    '&.slick-prev': {
      background: `url(${ArrowIconLeft}) no-repeat center center / cover`,
    },
    '&.slick-next': {
      background: `url(${ArrowIconRight}) no-repeat center center / cover`,
    },
    '&.slick-disabled': {
      opacity: '0.5',
    },
  },
}));
const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: false,
  arrows: false,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 414,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const ImagesGallery = (props) => {
  let { propertyImages } = props;
  const [open, setOpen] = useState(false);
  const slider = useRef(null);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Container fixed={true} sx={{ maxWidth: { xl: '1326px' } }}>
        <Box sx={{ py: { md: 5, xs: 3 } }}>
          <Grid container spacing={3} sx={{ mb: 3, alignItems: 'center' }}>
            <Grid item md={6} xs={12}>
              <Box>
                <Typography variant="h6">
                  Gallery{' '}
                  <Typography variant="body1" component="span">
                    ({propertyImages.length} images)
                  </Typography>
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Stack
                flexDirection="row"
                sx={{ justifyContent: { md: 'end' } }}
                gap="24px"
              >
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ height: '44px', borderRadius: '8px', p: 1.5 }}
                  onClick={handleClickOpen}
                >
                  <SvgColor
                    src={menu}
                    sx={{ mr: 1.5, width: '18px', height: '18px' }}
                    alt=""
                  />
                  Show all images
                </Button>
                {propertyImages.length > 3 && (
                  <SliderArrows>
                    <button
                      className="slick-arrow slick-prev"
                      style={{ display: 'block' }}
                      onClick={() => slider?.current?.slickPrev()}
                    >
                      Prev
                    </button>
                    <button
                      className="slick-arrow slick-next"
                      style={{ display: 'block' }}
                      onClick={() => slider?.current?.slickNext()}
                    >
                      Next
                    </button>
                  </SliderArrows>
                )}
              </Stack>
            </Grid>
          </Grid>
          <CarouselDiv>
            {propertyImages.length === 0 ? (
              ''
            ) : (
              <Slider {...settings} ref={slider}>
                {propertyImages.map((file, index) => (
                  <Box key={index} sx={{ px: { md: 2, xs: 1 }, py: 1 }}>
                    <img
                      src={`${IMG_URL}${file?.propertyImage}`}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: '14px',
                        aspectRatio: '1.2',
                        border: '1px solid #eee',
                      }}
                    />
                  </Box>
                ))}
              </Slider>
            )}
          </CarouselDiv>
          <DialogFull
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{ '& .MuiDialog-paper': { m: 0 } }}
          >
            <DialogTitle
              id="scroll-dialog-title"
              sx={{
                boxShadow: (theme) => theme.customShadows.dialog,
                textAlign: 'right',
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <Icon icon="iconamoon:close-light" />
              </IconButton>
            </DialogTitle>
            <DialogContent sx={{ p: 0 }}>
              <DialogContentText id="scroll-dialog-description">
                <Container sx={{ px: 0 }}>
                  <Grid container>
                    {propertyImages.map((file, index) => (
                      <Grid item md={index === 0 ? 12 : 6} xs={12} key={index}>
                        <Box sx={{ px: 1, py: 1 }}>
                          <img
                            src={`${IMG_URL}${file?.propertyImage}`}
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              aspectRatio: '2',
                              border: '1px solid #eee',
                            }}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Container>
              </DialogContentText>
            </DialogContent>
            {/* <DialogActions></DialogActions> */}
          </DialogFull>
        </Box>
      </Container>
    </>
  );
};
