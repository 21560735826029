import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Card, CardBody, CardHeader, UncontrolledCollapse } from 'reactstrap';
import { getAllUsersLogged } from '../../../services/apiServices';
import userNoImage from '../../assets/images/users/user-dummy-img.jpg';
import { IMG_URL } from '../../../utils/constant';
import { getDateAndTime } from '../../../utils/common-function';

const RecentActivity = (props) => {
  let { user_id, userData } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState('1');
  const [activityTab, setActivityTab] = useState('1');
  const [userLoggedDataa, setUserLoggedDataa] = useState([]);

  const fetchUserLogged = async () => {
    try {
      // setSpinFlag(true);
      let payload = { user_id: user_id };
      let { data } = await getAllUsersLogged(payload);
      if (data?.statusCode == 200) {
        let datta = data?.userloggsss;
        setUserLoggedDataa(datta);
      } else {
        console.log('errrr in fetch user logged api');
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  useEffect(() => {
    fetchUserLogged();
  }, []);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggleActivityTab = (tab) => {
    if (activityTab !== tab) {
      setActivityTab(tab);
    }
  };
  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0  me-2">Recent Activity</h4>
          <div className="flex-shrink-0 ms-auto"></div>
        </CardHeader>
        <CardBody>
          <div className="profile-timeline">
            <div className="accordion accordion-flush" id="todayExample">
              {userLoggedDataa && userLoggedDataa?.length > 0 ? (
                <>
                  {' '}
                  {userLoggedDataa?.map((item) => {
                    return (
                      <div className="accordion-item border-0">
                        <div className="accordion-header">
                          <button
                            className="accordion-button p-2 shadow-none"
                            type="button"
                            id="headingOne"
                          >
                            <div className="d-flex">
                              <div className="flex-shrink-0">
                                <img
                                  src={
                                    userData?.profile
                                      ? `${IMG_URL}${userData?.profile}`
                                      : userNoImage
                                  }
                                  alt="err"
                                  className="avatar-xs rounded-circle"
                                />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h6 className="fs-14 mb-1">
                                  {userData?.firstname} {userData?.lastname}
                                </h6>
                                <small className="text-muted">
                                  {getDateAndTime(item?.time)}
                                </small>
                              </div>
                            </div>
                          </button>
                        </div>
                        <UncontrolledCollapse
                          className="accordion-collapse"
                          toggler="#headingOne"
                          defaultOpen
                        >
                          <div className="accordion-body ms-2 ps-5">
                            {item?.description}
                          </div>
                        </UncontrolledCollapse>
                      </div>
                    );
                  })}
                </>
              ) : (
                'No data found'
              )}

              {/* <div className="accordion-item border-0">
                <div className="accordion-header" id="headingTwo">
                  <Link
                    to="#"
                    className="accordion-button p-2 shadow-none"
                    id="collapseTwo"
                  >
                    <div className="d-flex">
                      <div className="flex-shrink-0 avatar-xs">
                        <div className="avatar-title bg-light text-success rounded-circle">
                          M
                        </div>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h6 className="fs-14 mb-1">Megan Elmore</h6>
                        <small className="text-muted">
                          Adding a new event with attachments - 04:45PM
                        </small>
                      </div>
                    </div>
                  </Link>
                </div>
                <UncontrolledCollapse toggler="collapseTwo" defaultOpen>
                  <div className="accordion-body ms-2 ps-5">
                    <Row className="g-2">
                      <div className="col-auto">
                        <div className="d-flex border border-dashed p-2 rounded position-relative">
                          <div className="flex-shrink-0">
                            <i className="ri-image-2-line fs-17 text-danger"></i>
                          </div>
                          <div className="flex-grow-1 ms-2">
                            <h6>
                              <Link to="#" className="stretched-link">
                                Business Template - UI/UX design
                              </Link>
                            </h6>
                            <small>685 KB</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className="d-flex border border-dashed p-2 rounded position-relative">
                          <div className="flex-shrink-0">
                            <i className="ri-file-zip-line fs-17 text-info"></i>
                          </div>
                          <div className="flex-grow-1 ms-2">
                            <h6>
                              <Link to="#" className="stretched-link">
                                Bank Management System - PSD
                              </Link>
                            </h6>
                            <small>8.78 MB</small>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                </UncontrolledCollapse>
              </div>
              <div className="accordion-item border-0">
                <div className="accordion-header" id="headingThree">
                  <Link to="#" className="accordion-button p-2 shadow-none">
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <img
                          src={avatar5}
                          alt=""
                          className="avatar-xs rounded-circle"
                        />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h6 className="fs-14 mb-1">New ticket received</h6>
                        <small className="text-muted mb-2">
                          User
                          <span className="text-secondary">Erica245</span>
                          submitted a ticket - 02:33PM
                        </small>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="accordion-item border-0">
                <div className="accordion-header" id="headingFour">
                  <Link
                    to="#"
                    className="accordion-button p-2 shadow-none"
                    id="collapseFour"
                  >
                    <div className="d-flex">
                      <div className="flex-shrink-0 avatar-xs">
                        <div className="avatar-title bg-light text-muted rounded-circle">
                          <i className="ri-user-3-fill"></i>
                        </div>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h6 className="fs-14 mb-1">Nancy Martino</h6>
                        <small className="text-muted">
                          Commented on 12:57PM
                        </small>
                      </div>
                    </div>
                  </Link>
                </div>
                <UncontrolledCollapse toggler="collapseFour" defaultOpen>
                  <div className="accordion-body ms-2 ps-5">
                    " A wonderful serenity has taken possession of my entire
                    soul, like these sweet mornings of spring which I enjoy with
                    my whole heart. Each design is a new, unique piece of art
                    birthed into this world, and while you have the opportunity
                    to be creative and make your own style choices. "
                  </div>
                </UncontrolledCollapse>
              </div>
              <div className="accordion-item border-0">
                <div className="accordion-header" id="headingFive">
                  <Link
                    to="#"
                    className="accordion-button p-2 shadow-none"
                    id="collapseFive"
                  >
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <img
                          src={avatar7}
                          alt=""
                          className="avatar-xs rounded-circle"
                        />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h6 className="fs-14 mb-1">Lewis Arnold</h6>
                        <small className="text-muted">
                          Create new project buildng product - 10:05AM
                        </small>
                      </div>
                    </div>
                  </Link>
                </div>
                <UncontrolledCollapse toggler="collapseFive" defaultOpen>
                  <div className="accordion-body ms-2 ps-5">
                    <p className="text-muted mb-2">
                      Every team project can have a velzon. Use the velzon to
                      share information with your team to understand and
                      contribute to your project.
                    </p>
                    <div className="avatar-group">
                      <Link
                        to="#"
                        className="avatar-group-item"
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover"
                        data-bs-placement="top"
                        title=""
                        data-bs-original-title="Christi"
                      >
                        <img
                          src={avatar4}
                          alt=""
                          className="rounded-circle avatar-xs"
                        />
                      </Link>
                      <Link
                        to="#"
                        className="avatar-group-item"
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover"
                        data-bs-placement="top"
                        title=""
                        data-bs-original-title="Frank Hook"
                      >
                        <img
                          src={avatar3}
                          alt=""
                          className="rounded-circle avatar-xs"
                        />
                      </Link>
                      <Link
                        to="#"
                        className="avatar-group-item"
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover"
                        data-bs-placement="top"
                        title=""
                        data-bs-original-title=" Ruby"
                      >
                        <div className="avatar-xs">
                          <div className="avatar-title rounded-circle bg-light text-primary">
                            R
                          </div>
                        </div>
                      </Link>
                      <Link
                        to="#"
                        className="avatar-group-item"
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover"
                        data-bs-placement="top"
                        title=""
                        data-bs-original-title="more"
                      >
                        <div className="avatar-xs">
                          <div className="avatar-title rounded-circle">2+</div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </UncontrolledCollapse>
              </div> */}
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default RecentActivity;
