import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Vector from './VectorMap';
//SimpleBar
import SimpleBar from 'simplebar-react';

const PropertyMapLocations = (props) => {
  let { allCountryCountPercentage } = props;
  return (
    <React.Fragment>
      <Card className="card-height-100">
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            Properties Map Location
          </h4>
          <div className="flex-shrink-0"></div>
        </CardHeader>

        <CardBody>
          <Row>
            <Col md={4}>
              <div className="px-2 py-2 mt-1">
                <SimpleBar style={{ maxHeight: '432px' }} className="pe-2">
                  {allCountryCountPercentage &&
                  allCountryCountPercentage.length > 0
                    ? allCountryCountPercentage.map((item, index) => {
                        return (
                          <div key={index}>
                            <p className="mb-1">
                              {item?._id}{' '}
                              <span className="float-end">{item?.count}</span>
                            </p>
                            <div
                              className="progress mt-2"
                              style={{ height: '6px' }}
                            >
                              <div
                                className="progress-bar progress-bar-striped bg-primary"
                                role="progressbar"
                                style={{ width: `${item?.percenatge}%` }}
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="75"
                              ></div>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </SimpleBar>
              </div>
            </Col>
            <Col md={8}>
              <Vector
                value="world_mill"
                data-colors='["--vz-light", "--vz-success", "--vz-primary"]'
              />
            </Col>
          </Row>
          {/* </Map> */}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default PropertyMapLocations;
{
  /* export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_KEY,
})(PropertyMapLocations); */
}
