import React, { useEffect, useState } from 'react';
import { alpha, styled } from '@mui/material/styles';
import Scrollbar from '../../../components/scrollbar';
import {
  Card,
  Button,
  Box,
  CardHeader,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemButton,
  Badge,
  ListItemText,
  ListItemAvatar,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { Icon } from '@iconify/react';
import { BASEURL, IMG_URL } from '../../../utils/constant';
import { useAuthStore } from '../../../redux/features/authSlice';
import moment from 'moment';
import { createRoom } from '../../../services/apiServices';
import { DataNotAvailable } from '../../../components/data-not-found';
const StyledIcon = styled(Box)(({ theme }) => ({}));
const BadgeNum = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#FEAE01',
    color: '#fff',
    transform: 'inherit',
  },
}));
const StyledBadge = styled(Badge)(({ theme }) => ({
  // '& .MuiBadge-badge': {
  //   backgroundColor: '#D1D5DB',
  //   color: '#D1D5DB',
  //   boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  // },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));
function createData(id, name, price) {
  return { id, name, price };
}

export const AppMessages = ({ title }) => {
  const [value, setValue] = useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Card>
        <CardHeader
          title={title}
          action={
            <Button size="small" color="primary" component={Link} to="/message">
              See all
            </Button>
          }
          sx={{ pb: 2 }}
        />
        <Box>
          <Scrollbar style={{ height: '295px' }}>
            <MessagesList />
          </Scrollbar>
        </Box>
      </Card>
    </>
  );
};

function MessagesList({ news }) {
  const [membersList, setMemeberList] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  let { loginInfo } = useAuthStore();
  const navigate = useNavigate();

  const handleMessageOpen = async (user) => {
    const payload = {
      userId: user.participants[0]._id,
    };
    const { data } = await createRoom(payload);
    // navigation
    if (data.id) {
      let messageData = {
        userId: user.participants[0]._id,
        firstname: user.participants[0].firstname,
        lastname: user.participants[0].lastname,
        profile: user.participants[0].profile,
      };

      navigate(`/message?roomId=${data.id}`, { state: messageData });
      // navigate(0);
    }
  };

  // const chatSocket = io(`${BASEURL}/dashboard`);
  useEffect(() => {
    const chatSocket = io(`${BASEURL}`);

    chatSocket.on('online', (userId) => {
      setOnlineUsers(userId);
    });

    let memberListObj = {
      searchByName: '',
      user_id: loginInfo._id,
      pageNumber: 0,
    };
    chatSocket.emit('member-list', memberListObj);

    chatSocket.on('fetch-member-list', (result) => {
      setMemeberList(result.memberList);
    });

    chatSocket.on('receiver-member-list', (result) => {
      setMemeberList(result.memberList);
    });

    return () => {
      chatSocket.disconnect();
    };
  }, []);

  return (
    <>
      <List sx={{ p: 0 }}>
        {membersList?.map((val, index) => {
          return val.participants?.map((user, index) => (
            <ListItem
              sx={{
                p: 0,
                borderTop: '1px solid #F1F1F1',
              }}
              key={index}
            >
              <ListItemButton
                onClick={() => handleMessageOpen(val)}
                sx={{ p: { md: 2, xs: 1.5 } }}
              >
                <ListItemAvatar>
                  <StyledBadge
                    color={
                      onlineUsers.includes(val?.participants[0]?._id)
                        ? 'success'
                        : 'secondary'
                    }
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    variant="dot"
                  >
                    <Avatar
                      alt={user.firstname?.toUpperCase()}
                      src={user.profile && `${IMG_URL}${user.profile}`}
                    />
                  </StyledBadge>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      component="h6"
                      noWrap={true}
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {user?.firstname} {user?.lastname}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="caption"
                      component="p"
                      noWrap={true}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      {val?.lastMessage?.metaData ? (
                        <>
                          {val?.lastMessage?.metaData.type === 'image' ? (
                            <Icon
                              width="13px"
                              icon="vscode-icons:file-type-image"
                            />
                          ) : val?.lastMessage?.metaData.type === 'video' ? (
                            <Icon
                              width="13px"
                              icon="vscode-icons:file-type-video"
                            />
                          ) : val?.lastMessage?.metaData.type === 'doc' ? (
                            <>
                              {val?.lastMessage?.metaData.name
                                .split('.')
                                .pop()
                                .toLowerCase() === 'pdf' ? (
                                <Icon
                                  width="13px"
                                  icon="vscode-icons:file-type-pdf2"
                                />
                              ) : val?.lastMessage?.metaData.name
                                  .split('.')
                                  .pop()
                                  .toLowerCase() === 'docx' ||
                                val?.lastMessage?.metaData.name
                                  .split('.')
                                  .pop()
                                  .toLowerCase() === 'doc' ? (
                                <Icon
                                  width="13px"
                                  icon="fxemoji:documenttextpicture"
                                />
                              ) : val?.lastMessage?.metaData.name
                                  .split('.')
                                  .pop()
                                  .toLowerCase() === 'txt' ? (
                                <Icon
                                  width="13px"
                                  icon="fxemoji:documentwithtext"
                                />
                              ) : val?.lastMessage?.metaData.name
                                  .split('.')
                                  .pop()
                                  .toLowerCase() === 'zip' ? (
                                <Icon
                                  width="13px"
                                  icon="vscode-icons:file-type-zip"
                                />
                              ) : (
                                <Icon
                                  width="13px"
                                  icon="simple-line-icons:doc"
                                />
                              )}
                            </>
                          ) : null}
                          <Typography
                            variant="caption"
                            component="p"
                            noWrap={true}
                            sx={{
                              width: 'calc(100% - 13px)',
                              pl: 0.5,
                            }}
                          >
                            {val?.lastMessage?.metaData.name}
                          </Typography>
                        </>
                      ) : (
                        val?.lastMessage?.content
                      )}
                    </Typography>
                  }
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    ml: 2,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ color: 'text.secondary' }}
                    noWrap={true}
                  >
                    {val?.lastMessage?.updatedAt &&
                    moment(val?.lastMessage?.updatedAt).isSame(
                      moment().startOf('day'),
                      'd'
                    )
                      ? val?.lastMessage?.updatedAt &&
                        moment(val?.lastMessage?.updatedAt).format('hh:mm a')
                      : val?.lastMessage?.updatedAt &&
                        moment(val?.lastMessage?.updatedAt).format('MM/DD/YY')}
                  </Typography>
                  {val?.unread_count === 0 ? (
                    ''
                  ) : (
                    <>
                      <Box
                        sx={{
                          minWidth: '20px',
                          height: '20px',
                          borderRadius: '20px',
                          bgcolor: '#FEAE01',
                          display: `${
                            val?.lastMessage?.senderId !== loginInfo._id &&
                            val?.lastMessage?.senderId
                              ? 'flex'
                              : 'none'
                          }`,
                          flexWrap: 'wrap',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '11px',
                          color: '#fff',
                          p: '0 2px',
                        }}
                      >
                        {val?.unread_count > 100 ? (
                          '99+'
                        ) : (
                          <>{val?.unread_count}</>
                        )}
                      </Box>
                    </>
                  )}
                </Box>
              </ListItemButton>
            </ListItem>
          ));
        })}
      </List>
      {membersList?.length === 0 ? (
        <Box sx={{ px: 2, pb: 2, height: '295px' }}>
          <DataNotAvailable
            sx={{ p: 2, minHeight: 'auto' }}
            title="You don't have Chat List"
          />
        </Box>
      ) : null}
    </>
  );
}
