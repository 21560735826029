import React from 'react';
import { InputAdornment, OutlinedInput, Grid } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { Icon } from '@iconify/react';

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: 14,
  backgroundColor: '#fff',
  width: '38%',
  [theme.breakpoints.down('lg')]: {
    width: '45%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  '& .MuiInputBase-input': {
    padding: '12.5px 14px',
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

export const PropertiesListSearch = ({ filterValues, onFilterValues }) => {
  return (
    <>
      <StyledSearch
        value={filterValues}
        onChange={onFilterValues}
        placeholder="Search listing"
        endAdornment={
          <InputAdornment position="start">
            <Icon
              icon="eva:search-fill"
              sx={{ color: 'text.disabled', width: 20, height: 20 }}
            />
          </InputAdornment>
        }
      />
    </>
  );
};
