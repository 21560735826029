import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const PRIMARY = {
  lighter: '#F4F4F4',
  light: '#C1C1C1',
  main: '#323232',
  dark: '#24191B',
  darker: '#18090E',
};

const SECONDARY = {
  lighter: '#FEF5CC',
  light: '#FED866',
  main: '#FEAE01',
  dark: '#B67100',
  darker: '#794300',
};

const SUCCESS = {
  lighter: '#D1FAD9',
  light: '#74E5A0',
  main: '#1FAB71',
  dark: '#0F7B63',
  darker: '#055250',
};

const INFO = {
  lighter: '#C9E9FB',
  light: '#5DA9E8',
  main: '#0052B4',
  dark: '#002F81',
  darker: '#001756',
};

const WARNING = {
  lighter: '#FFEBD9',
  light: '#FFB48F',
  main: '#FF6645',
  dark: '#B72622',
  darker: '#7A0D1B',
};

const ERROR = {
  lighter: '#FDD7CB',
  light: '#F86D63',
  main: '#EA001B',
  dark: '#A80032',
  darker: '#700036',
};

const palette = {
  primary: PRIMARY,
  secondary: SECONDARY,
  success: SUCCESS,
  info: INFO,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  // divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  // background: {
  //   paper: '#fff',
  //   default: GREY[100],
  //   neutral: GREY[200],
  // },
  // action: {
  //   active: GREY[600],
  //   hover: alpha(GREY[500], 0.08),
  //   selected: alpha(GREY[500], 0.16),
  //   disabled: alpha(GREY[500], 0.8),
  //   disabledBackground: alpha(GREY[500], 0.24),
  //   focus: alpha(GREY[500], 0.24),
  //   hoverOpacity: 0.08,
  //   disabledOpacity: 0.48,
  // },
};

export default palette;
