import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Input } from 'reactstrap';
import DatatablesCom from '../../Components/Tables/DataTables/DatatablesCom';
import userNoImage from '../../assets/images/users/user-dummy-img.jpg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { IMG_URL } from '../../../utils/constant';
import { getAllUnitHistory } from '../../../services/apiServices';

const customStyles = {
  rows: {
    style: {
      // minHeight: '72px',
    },
  },
  headCells: {
    style: {
      padding: '8px',
      background: '#f3f6f9',
      fontWeight: 'bold',
      color: '#878a99',
    },
  },
  cells: {
    style: {
      padding: '8px',
    },
  },
};

const RenterDetails = (props) => {
  let { userData, setSpinFlag, setResponse } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [fetchRender, setFetchRender] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [allUnitHistory, setAllUnitHistory] = useState([]);
  const [optionsFlag, setOptionsFlag] = useState(false);
  const [paginationTotalRows, setPaginationTotalRows] = useState(1);
  const [search, setSearch] = useState();
  const [searchByDate, setSearchByDate] = useState();
  const [rowPerPage, setRowsPerPage] = useState(10);
  const [placeholder, setPlaceholder] = useState(
    'Search by firstname,lastname and title'
  );

  useEffect(() => {
    if (userData?._id) fetchRenterHistory();
  }, [fetchRender, currentPage, userData?._id, rowPerPage]);

  const fetchRenterHistory = async () => {
    try {
      setSpinFlag(true);
      let payload = { currentPage, user_id: userData?._id, rowPerPage };
      if (search) payload['search'] = search;
      if (searchByDate) payload['searchByDate'] = searchByDate;
      const { data } = await getAllUnitHistory(payload);
      if (data.statusCode == 200) {
        setPaginationTotalRows(data?.totalUnitCount);
        setAllUnitHistory(data?.unitHistoryList);
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Properties</span>,
      sortable: true,
      width: '350px',
      cell: (row) => {
        return (
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 me-3">
              <div className="avatar-sm bg-light rounded p-1">
                {row?.propertyImages[0]?.propertyImage ? (
                  <img
                    className="img-fluid d-block"
                    src={`${IMG_URL}${row?.propertyImages[0]?.propertyImage}`}
                    alt="Header Avatar"
                  />
                ) : (
                  <img
                    className="img-fluid d-block"
                    src={userNoImage}
                    alt="Header Avatar"
                  />
                )}
              </div>
            </div>
            <div className="flex-grow-1">
              <Link
                to={`/admin/property-details/${row?.propertyData[0]?._id}`}
                className="text-dark"
              >
                <h5 className="fs-14 mb-1">{row?.propertyData[0]?.title}</h5>
              </Link>
              <p className="text-muted mb-0">
                <span className="fw-medium">
                  {' '}
                  {row?.propertyData[0]?.address}
                </span>
              </p>
            </div>
          </div>
        );
      },
    },

    {
      name: <span className="font-weight-bold fs-13">Landlord</span>,
      sortable: true,
      width: '230px',
      cell: (row) => {
        return (
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 me-3">
              <div className="avatar-sm bg-light rounded p-1">
                {row?.ownerData[0]?.profile ? (
                  <img
                    className="img-fluid d-block"
                    src={`${IMG_URL}${row?.ownerData[0]?.profile}`}
                    alt="Header Avatar"
                  />
                ) : (
                  <img
                    className="img-fluid d-block"
                    src={userNoImage}
                    alt="Header Avatar"
                  />
                )}
              </div>
            </div>
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                <Link
                  to={`/admin/user-details?id=${row?.ownerData[0]?._id}`}
                  className="text-dark"
                >
                  {row?.ownerData[0]?.firstname} {row?.ownerData[0]?.lastname}
                </Link>
              </h5>
              <p className="text-muted mb-0">
                <span className="fw-medium">
                  {' '}
                  {row?.ownerData[0]?.countryCode} {row?.ownerData[0]?.mobile}
                </span>
              </p>
            </div>
          </div>
        );
      },
    },

    {
      name: <span className="font-weight-bold fs-13">Move-in Date</span>,
      sortable: true,
      cell: (row) => {
        return <span>{row?.fromDate.substring(0, 10)}</span>;
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Move-out Date</span>,
      sortable: true,
      cell: (row) => {
        return <span>{row?.toDate.substring(0, 10)}</span>;
      },
    },
    // {
    //   name: <span className="font-weight-bold fs-13">Status</span>,
    //   selector: (row) => {
    //     return row?.propertyUnit[0]?.isActive === 1 ||
    //       row?.propertyUnit[0]?.isActive === undefined
    //       ? 'Active'
    //       : 'Inactive';
    //   },
    //   sortable: true,
    // },
    {
      name: <span className="font-weight-bold fs-13">Units name</span>,
      selector: (row) => row?.propertyUnit[0]?.unitName,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Created date</span>,
      selector: (row) => row?.createdAt.substring(0, 10),
      sortable: true,
    },
  ];
  return (
    <>
      <Col lg={12}>
        <Card id="propertiesList">
          <CardHeader className="border-0">
            <Row>
              <Col>
                <div>
                  <h5 className="card-title mb-0">Unit List</h5>
                </div>
              </Col>
              <Col></Col>
            </Row>
          </CardHeader>
          <CardBody className="p-0">
            <div>
              {allUnitHistory && allUnitHistory.length ? (
                <DatatablesCom
                  columns={columns}
                  data={allUnitHistory}
                  customStyles={customStyles}
                  setCurrentPage={setCurrentPage}
                  paginationTotalRows={paginationTotalRows}
                  setSearch={setSearch}
                  search={search}
                  fetchRender={fetchRender}
                  setFetchRender={setFetchRender}
                  optionsFlag={optionsFlag}
                  searchByDate={searchByDate}
                  setSearchByDate={setSearchByDate}
                  placeholder={placeholder}
                  setRowsPerPage={setRowsPerPage}
                  rowPerPage={rowPerPage}
                />
              ) : (
                <>
                  <DatatablesCom
                    setCurrentPage={setCurrentPage}
                    customStyles={customStyles}
                    setSearch={setSearch}
                    search={search}
                    fetchRender={fetchRender}
                    setFetchRender={setFetchRender}
                    optionsFlag={optionsFlag}
                    setSearchByDate={setSearchByDate}
                    placeholder={placeholder}
                    setRowsPerPage={setRowsPerPage}
                    rowPerPage={rowPerPage}
                  />
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default RenterDetails;
