import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { MultipleRadialbar } from '../../Components/AllCharts';

const UsersChart = (props) => {
  const {
    allUsersCount,
    allAdminUserPercentage,
    allLandlordUserPercentage,
    allRenterUserPercentage,
  } = props;

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Users</h4>
        </CardHeader>

        <CardBody className="p-0 pb-2">
          <div className="w-100">
            {allUsersCount > 0 ? (
              <MultipleRadialbar
                dataColors='["--vz-success", "--vz-primary", "--vz-warning", "--vz-danger"]'
                allUsersCount={allUsersCount}
                allAdminUserPercentage={allAdminUserPercentage}
                allLandlordUserPercentage={allLandlordUserPercentage}
                allRenterUserPercentage={allRenterUserPercentage}
              />
            ) : (
              <div style={{ height: '312px' }} className="py-2 text-center">
                There are no records to display
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default UsersChart;
