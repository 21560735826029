import { useRoutes } from 'react-router-dom';
import { AdminLayout, AdminDashboardLayout } from '../src/layouts/admin-layout';
import { SignupLayout } from '../src/layouts/signup-layout';
import { MainLayout } from '../src/layouts/main-layout';
import { DashboardLayout } from '../src/layouts/dashboard-layout';
import { SignUp } from '../src/pages/auth/sign-up';
import { Login } from '../src/pages/auth/login';
import { Page404 } from '../src/pages/not-found/Page404';
import { ForgotPassword } from '../src/pages/auth/forgot-password';
import { ResetPassword } from '../src/pages/auth/reset-password';
import { Home } from '../src/pages/home';
import { AvailableSpaces } from '../src/pages/available-spaces';
import { BecomeAHost } from '../src/pages/become-host';
import { AboutUs } from '../src/pages/about-us';
import { Faq } from '../src/pages/faq';
import { Error } from '../src/pages/error-page';
import { PropertyDetails } from '../src/pages/property-details';
import { Dashboard } from '../src/pages/dashboard-pages/dashboard';
import { Message } from '../src/pages/dashboard-pages/message';
import { AccountDetails } from '../src/pages/dashboard-pages/account-details';
import { Settings } from '../src/pages/dashboard-pages/settings';
import { Payment } from '../src/pages/dashboard-pages/payment';
import { Notification } from '../src/pages/dashboard-pages/notification';
import { FavouritePlacePage } from '../src/pages/dashboard-pages/favourite-place';
import { UnitHistoryPage } from '../src/pages/dashboard-pages/unit-history';
import { AddPaymentMethod } from '../src/pages/dashboard-pages/add-payment-method';
import { AddBankAccounts } from './pages/dashboard-pages/add-bank-accounts';
import AddProperty from '../src/pages/dashboard-pages/add-property';
import EditProperty from '../src/pages/dashboard-pages/edit-property';
import {
  PrivateRoute,
  PublicRoute,
  ForRenterRoute,
  ForLandlordRoute,
  PrivateRouteAdmin,
  PublicRouteAdmin,
} from './utils/PrivateRoute';
import { useAuthStore } from './redux/features/authSlice';
import { io } from 'socket.io-client';
import { BASEURL } from './utils/constant';
import { useEffect } from 'react';
import { PaymentMmethod } from './pages/dashboard-pages/payment-method';
import AdminLogin from './admin/pages/Authentication/Login';
import AdminForgetPasswordPage from './admin/pages/Authentication/ForgetPassword';
import AdminResetPasswordPage from './admin/pages/Authentication/ResetPasswordPage';
import AdminDashboard from './admin/pages/AdminDashboard';
import UserListPage from './admin/pages/Users';
import UserDetails from './admin/pages/Users/UserDetails';
import PropertyDetailsAdmin from './admin/pages/Properties/property-details';
import PropertyListPage from './admin/pages/Properties';
import PaymentPage from './admin/pages/Payments';
import AdminProfile from './admin/pages/Profile';
import ErrorAdmin from './admin/pages/Error';
import SettingsPage from './admin/pages/Settings/WebsiteInfo';
import FaqsPage from './admin/pages/Settings/Faqs';
import AmenitiesPage from './admin/pages/Settings/Amenities';
import RulesPage from './admin/pages/Settings/Rules';
import ExploreRentalsPage from './admin/pages/Settings/ExploreRentals';
import FeaturedCitiesPage from './admin/pages/Settings/FeaturedCities';
import ReviewsPage from './admin/pages/Settings/Reviews';
import Email from './admin/pages/Email';
import HomePage from './admin/pages/MasterCrud/Home';
import ListPage from './admin/pages/MasterCrud/List';
import HowItWorks from './admin/pages/MasterCrud/List/HowItWorks';
import RentPage from './admin/pages/MasterCrud/Rent';
import AboutUsPage from './admin/pages/MasterCrud/AboutUs';
import AboutUsList from './admin/pages/MasterCrud/AboutUs/AboutUsList';
import Chat from './admin/pages/Chat';
import AdminNotification from './admin/pages/Notification';
import { PermissionModules } from './utils/PrivateRoutesPermission';

function Router() {
  let { loginInfo, loginAdminInfo } = useAuthStore();

  // if its on header not in router
  const notificationSocket = io(`${BASEURL}`);
  useEffect(() => {
    let id = loginInfo
      ? loginInfo._id
      : loginAdminInfo
      ? loginAdminInfo._id
      : null;

    notificationSocket.on('connect', () => {
      notificationSocket.emit('join', id);
    });
    return () => {
      notificationSocket.disconnect();
    };
  }, [notificationSocket]);

  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          element: (
            <ForLandlordRoute>
              <Home />
            </ForLandlordRoute>
          ),
          index: true,
        },
        {
          path: 'available-spaces',
          element: (
            <ForLandlordRoute>
              <AvailableSpaces />
            </ForLandlordRoute>
          ),
        },
        {
          path: 'become-a-host',
          element: (
            <ForLandlordRoute>
              <BecomeAHost />
            </ForLandlordRoute>
          ),
        },
        { path: 'about-us', element: <AboutUs /> },
        { path: 'faq', element: <Faq /> },
        {
          path: 'property-details/:id',
          element: <PropertyDetails />,
        },
      ],
    },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '/',
      element: (
        <PublicRoute>
          <SignupLayout />
        </PublicRoute>
      ),
      children: [
        { path: 'sign-up', element: <SignUp /> },
        { path: 'login', element: <Login /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        {
          path: 'reset-password',
          element: <ResetPassword />,
        },
      ],
    },
    { path: 'error', element: <Error /> },
    {
      path: '/',
      element: (
        <PrivateRoute>
          <DashboardLayout />
        </PrivateRoute>
      ),
      children: [
        {
          path: 'dashboard',
          children: [
            { element: <Dashboard />, index: true },
            {
              path: 'add-property',
              element: (
                <ForRenterRoute>
                  <AddProperty />
                </ForRenterRoute>
              ),
            },
            {
              path: 'edit-listing',
              element: (
                <ForRenterRoute>
                  <EditProperty />
                </ForRenterRoute>
              ),
            },
            { path: 'favourite-place', element: <FavouritePlacePage /> },
            { path: 'unit-history', element: <UnitHistoryPage /> },
          ],
        },
        { path: 'message', element: <Message /> },
        {
          path: 'account-details',
          element: <AccountDetails />,
        },
        { path: 'settings', element: <Settings /> },
        {
          path: 'payment',
          children: [
            { element: <Payment loginInfo={loginInfo} />, index: true },
            {
              path: 'add-payment-method', // not run
              element: (
                <ForLandlordRoute>
                  <AddPaymentMethod />
                </ForLandlordRoute>
              ),
            },
            {
              path: 'payment-method',
              element: (
                <ForLandlordRoute>
                  <PaymentMmethod />
                </ForLandlordRoute>
              ),
            },
            {
              path: 'add-bank-accounts',
              element: (
                <ForRenterRoute>
                  <AddBankAccounts />
                </ForRenterRoute>
              ),
            },
          ],
        },
        { path: 'notification', element: <Notification /> },
      ],
    },

    {
      path: '/admin',
      element: <AdminLayout />,
      children: [
        {
          path: '',
          element: (
            <PublicRouteAdmin>
              <AdminLogin />
            </PublicRouteAdmin>
          ),
          index: true,
        },
        {
          path: 'forgot-password',
          element: <AdminForgetPasswordPage />,
        },
        {
          path: 'reset-password',
          element: <AdminResetPasswordPage />,
        },
        { path: 'error', element: <ErrorAdmin /> },
        {
          path: '',
          element: (
            <PrivateRouteAdmin>
              <AdminDashboardLayout />
            </PrivateRouteAdmin>
          ),
          children: [
            { path: 'dashboard', element: <AdminDashboard />, index: true },
            {
              path: 'user-list',
              element: (
                <PermissionModules permissionType={'Users'}>
                  <UserListPage />
                </PermissionModules>
              ),
            },
            { path: 'user-details', element: <UserDetails /> },
            {
              path: 'property-list',
              element: (
                <PermissionModules permissionType={'Properties'}>
                  <PropertyListPage />
                </PermissionModules>
              ),
            },
            {
              path: 'property-details/:id',
              element: <PropertyDetailsAdmin />,
            },
            { path: 'profile', element: <AdminProfile /> },
            {
              path: 'payment',
              element: (
                <PermissionModules permissionType={'Payment'}>
                  <PaymentPage />
                </PermissionModules>
              ),
            },
            {
              path: 'email-templates',
              element: (
                <PermissionModules permissionType={'Email templates'}>
                  <Email />
                </PermissionModules>
              ),
            },
            // Chat
            {
              path: 'chat',
              element: (
                <PermissionModules permissionType={'Chat'}>
                  <Chat />
                </PermissionModules>
              ),
            },
            {
              path: 'admin-notification',
              element: (
                <PermissionModules permissionType={'notification'}>
                  <AdminNotification />
                </PermissionModules>
              ),
            },
            // Setting pages
            {
              path: 'settings',
              element: (
                <PermissionModules permissionType={'Website info'}>
                  <SettingsPage />
                </PermissionModules>
              ),
            },
            {
              path: 'faqs',
              element: (
                <PermissionModules permissionType={'Faqs'}>
                  <FaqsPage />
                </PermissionModules>
              ),
            },
            {
              path: 'amenities',
              element: (
                <PermissionModules permissionType={'Amenities'}>
                  <AmenitiesPage />
                </PermissionModules>
              ),
            },
            {
              path: 'rules',
              element: (
                <PermissionModules permissionType={'Rules'}>
                  <RulesPage />
                </PermissionModules>
              ),
            },
            {
              path: 'explore-rentals',
              element: (
                <PermissionModules permissionType={'Explore rentals'}>
                  <ExploreRentalsPage />
                </PermissionModules>
              ),
            },
            {
              path: 'featured-cities',
              element: (
                <PermissionModules permissionType={'Featured cities'}>
                  <FeaturedCitiesPage />
                </PermissionModules>
              ),
            },
            {
              path: 'reviews',
              element: (
                <PermissionModules permissionType={'Reviews'}>
                  <ReviewsPage />
                </PermissionModules>
              ),
            },
            // Master Crud
            {
              path: 'home',
              element: (
                <PermissionModules permissionType={'Home page'}>
                  <HomePage />
                </PermissionModules>
              ),
            },
            {
              path: 'list',
              element: (
                <PermissionModules permissionType={'List page'}>
                  <HomePage />
                </PermissionModules>
              ),
            },
            {
              path: 'rent',
              element: (
                <PermissionModules permissionType={'Rent page'}>
                  <HomePage />
                </PermissionModules>
              ),
            },
            {
              path: 'about-us',
              element: (
                <PermissionModules permissionType={'Aboutus page'}>
                  <HomePage />
                </PermissionModules>
              ),
            },
            { path: 'how-it-works', element: <HowItWorks /> },
            { path: 'about-us-list', element: <AboutUsList /> },
          ],
        },
      ],
    },
  ]);
}

export default Router;
