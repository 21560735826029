import React, { useEffect, useMemo } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Breadcrumbs,
} from '@mui/material';
// utils

import { AccountPopover } from './AccountPopover';
import { NotificationsPopover } from './NotificationsPopover';
import { Searchbar } from './Searchbar';
import MenuLine from '../../../assets/icons/menu-line.svg';
import SvgColor from '../../../components/svg-color';
import navConfig from '../sidebar/config';
import { Link, useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;
const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 120;
const StyledRoot = styled(AppBar)(({ theme }) => ({
  color: theme.palette.text.primary,
  // background: theme.palette.background.paper,
  position: 'absolute',
  background: 'none',
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    // width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    width: `50%`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  padding: theme.spacing(1, 2),
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(6, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export const Header = ({ onOpenNav }) => {
  const location = useLocation();
  const paths = location.pathname.split('/').filter((path) => path !== '');

  const useScrollDirection = () => {
    const [scrollDirection, setScrollDirection] = React.useState(null);

    useEffect(() => {
      let lastScrollY = window.pageYOffset;

      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        const direction = scrollY > lastScrollY ? 'down' : 'up';
        if (
          direction !== scrollDirection &&
          (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
        ) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener('scroll', updateScrollDirection); // add event listener
      return () => {
        window.removeEventListener('scroll', updateScrollDirection); // clean up
      };
    }, [scrollDirection]);

    return scrollDirection;
  };
  const scrollDirection = useScrollDirection();

  const Aisolutioncomponent = useMemo(() => {
    return <NotificationsPopover />;
  }, []);

  return (
    <StyledRoot
      className={`sticky ${
        scrollDirection === 'down' ? '-top-24' : 'top-0'
      } h-24 bg-blue-200 transition-all duration-500`}
    >
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
            width: '30px',
            height: '30px',
            p: 0.5,
          }}
        >
          <SvgColor src={MenuLine} sx={{ width: 1, height: 1 }} />
        </IconButton>
        <Box>
          <Typography
            component="h3"
            variant="h3"
            sx={{
              color: 'text.primary',
              display: { xs: 'none', lg: 'none' },
            }}
          >
            Dashboard
          </Typography>
        </Box>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          {/* <NotificationsPopover /> */}
          {Aisolutioncomponent}
          {/* <Searchbar /> */}
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
};
