import isString from 'lodash/isString';
// @mui
import { Box, Typography, Link } from '@mui/material';
//
import { Breadcrumbs } from './Breadcrumbs';

// ----------------------------------------------------------------------

export const HeaderBreadcrumbs = ({
  links,
  action,
  heading,
  moreLink = '' || [],
  sx,
  ...other
}) => {
  return (
    <Box
      sx={{
        position: { lg: 'absolute' },
        left: { lg: '41px' },
        top: { lg: '60px' },
        mb: { xs: 4, lg: 0 },
        ...sx,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            {heading}
          </Typography>
          {links && <Breadcrumbs links={links} {...other} />}
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>

      {isString(moreLink) ? (
        <Box sx={{ mt: 2 }}>
          <Link href={moreLink} target="_blank" rel="noopener" variant="body2">
            {moreLink}
          </Link>
        </Box>
      ) : (
        moreLink.map((href) => (
          <Box sx={{ mt: 2 }}>
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          </Box>
        ))
      )}
    </Box>
  );
};
