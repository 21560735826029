import * as Yup from 'yup';
import { PROPERTY_MSG, AUTH_MSG } from '../messages/error';
import { REGEX } from '../regex';
export const addPropertySchema = Yup.object().shape({
  properties: Yup.array().of(
    Yup.object().shape(
      {
        title: Yup.string()
          .trim()
          .matches(REGEX.TITLE, PROPERTY_MSG.VALID_TITLE)
          .max(200, PROPERTY_MSG.TITLE_TOO_LONG)
          .min(2, PROPERTY_MSG.TITLE_TOO_SHORT)
          .required(PROPERTY_MSG.TITLE_EMPTY),
        propertyType: Yup.string().required(PROPERTY_MSG.PROPERTY_TYPE_EMPTY),
        description: Yup.string()
          .trim()
          .matches(REGEX.DESCRIPTION, PROPERTY_MSG.VALID_DESCRIPTION)
          .max(5000, PROPERTY_MSG.DESCRIPTION_LONG)
          .min(15, PROPERTY_MSG.DESCRIPTION_SHORT)
          .required(PROPERTY_MSG.DESCRIPTION_EMPTY),
        address: Yup.string().trim().required(PROPERTY_MSG.ADDRESS_EMPTY),
        city: Yup.string().trim().required(PROPERTY_MSG.CITY_EMPTY),
        country: Yup.string().trim().required(PROPERTY_MSG.COUNTRY_EMPTY),
        zip: Yup.string()
          .trim()
          .matches(REGEX.ZIP, PROPERTY_MSG.VALID_ZIP)
          .required(PROPERTY_MSG.ZIP_EMPTY),

        amenities: Yup.array()
          .of(Yup.string())
          .min(1, PROPERTY_MSG.AMENITIES_EMPTY),
        unitArray: Yup.array().of(
          Yup.object().shape({
            unitName: Yup.string()
              .trim()
              .matches(REGEX.TITLE, PROPERTY_MSG.VALID_TITLE)
              .max(100, PROPERTY_MSG.TITLE_TOO_LONG)
              .min(2, PROPERTY_MSG.TITLE_TOO_SHORT)
              .required(PROPERTY_MSG.UNIT_NAME_EMPTY),
            price: Yup.string()
              .trim()
              .matches(REGEX.PRICE, PROPERTY_MSG.VALID_PRICE)
              .required(PROPERTY_MSG.PRICE_EMPTY),
            durationName: Yup.string().required(
              PROPERTY_MSG.DURATION_NAME_EMPTY
            ),
            rules: Yup.array()
              .of(Yup.string())
              .min(1, PROPERTY_MSG.RULES_EMPTY),
            amenities: Yup.array()
              .of(Yup.string())
              .min(1, PROPERTY_MSG.AMENITIES_EMPTY),
            bedrooms: Yup.string()
              .trim()
              .matches(
                REGEX.ALLOWED_NUM_EXCEPT_ZERO,
                PROPERTY_MSG.VALID_BEDROOM
              )
              .required(PROPERTY_MSG.BEDROOM_EMPTY),
            bathrooms: Yup.string()
              .trim()
              .matches(
                REGEX.ALLOWED_NUM_EXCEPT_ZERO,
                PROPERTY_MSG.VALID_BATHROOM
              )
              .required(PROPERTY_MSG.BEDROOM_EMPTY),
            availableFrom: Yup.string()
              .trim()
              .required(PROPERTY_MSG.AVAILABLE_FROM_EMPTY),
          })
        ),

        // [
        //   ['dayPrice', 'monthPrice'],
        //   ['dayPrice', 'yearPrice'],
        //   ['monthPrice', 'yearPrice'],
        // ]
        // yearPrice: Yup.string().when(['monthPrice', 'dayPrice'], {
        //   is: (a, b) => a !== '' || b !== '',
        //   then: Yup.string().required(
        //     'Please fill out Field1, Field2 or Field3'
        //   ),
        //   otherwise: Yup.string().optional(),
        // }),
        // monthPrice: Yup.string().when(['yearPrice', 'dayPrice'], {
        //   is: (a, b) => a !== '' || b !== '',
        //   then: Yup.string().required(
        //     'Please fill out Field1, Field2 or Field3'
        //   ),
        //   otherwise: Yup.string().optional(),
        // }),
        // dayPrice: Yup.string().when(['yearPrice', 'monthPrice'], {
        //   is: (a, b) => a !== '' || b !== '',
        //   then: Yup.string().required(
        //     'Please fill out Field1, Field2 or Field3'
        //   ),
        //   otherwise: Yup.string().optional(),
        // }),
        // yearPrice: Yup.string().when(['monthPrice', 'dayPrice'], {
        //   is: (a, b) => a !== '' || b !== '',
        //   then: Yup.string().required(
        //     'Please fill out yearly price, monthly price or daily price'
        //   ),
        //   otherwise: Yup.string().optional(),
        // }),
        // monthPrice: Yup.string().when(['yearPrice', 'dayPrice'], {
        //   is: (a, b) => a !== '' || b !== '',
        //   then: Yup.string().required(
        //     'Please fill out yearly price, monthly price or daily price'
        //   ),
        //   otherwise: Yup.string().optional(),
        // }),
        // dayPrice: Yup.string().when(['yearPrice', 'monthPrice'], {
        //   is: (a, b) => a !== '' || b !== '',
        //   then: Yup.string().required(
        //     'Please fill out yearly price, monthly price or daily price'
        //   ),
        //   otherwise: Yup.string().optional(),
        // }),

        rules: Yup.array().of(Yup.string()).min(1, PROPERTY_MSG.RULES_EMPTY),
        propertyImg: Yup.array().min(1, PROPERTY_MSG.VALID_PROPERTY_IMG),

        // priceCategory: Yup.string().required(PROPERTY_MSG.CANNOT_EMPTY),
      }
      // [
      //   ['dayPrice', 'monthPrice'],
      //   ['dayPrice', 'yearPrice'],
      //   ['monthPrice', 'yearPrice'],
      // ]
    )
  ),
});

export const editPropertySchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .matches(REGEX.TITLE, PROPERTY_MSG.VALID_TITLE)
    .max(200, PROPERTY_MSG.TITLE_TOO_LONG)
    .min(2, PROPERTY_MSG.TITLE_TOO_SHORT)
    .required(PROPERTY_MSG.TITLE_EMPTY),
  propertyType: Yup.string().required(PROPERTY_MSG.PROPERTY_TYPE_EMPTY),
  description: Yup.string()
    .trim()
    .matches(REGEX.DESCRIPTION, PROPERTY_MSG.VALID_DESCRIPTION)
    .max(5000, PROPERTY_MSG.DESCRIPTION_LONG)
    .min(15, PROPERTY_MSG.DESCRIPTION_SHORT)
    .required(PROPERTY_MSG.DESCRIPTION_EMPTY),
  address: Yup.string().trim().required(PROPERTY_MSG.ADDRESS_EMPTY),
  city: Yup.string().trim().required(PROPERTY_MSG.CITY_EMPTY),
  country: Yup.string().trim().required(PROPERTY_MSG.COUNTRY_EMPTY),
  zip: Yup.string()
    .trim()
    .matches(REGEX.ZIP, PROPERTY_MSG.VALID_ZIP)
    .required(PROPERTY_MSG.ZIP_EMPTY),
  amenities: Yup.array().of(Yup.string()).min(1, PROPERTY_MSG.AMENITIES_EMPTY),
  unitArray: Yup.array().of(
    Yup.object().shape({
      price: Yup.string()
        .trim()
        .matches(REGEX.PRICE, PROPERTY_MSG.VALID_PRICE)
        .required(PROPERTY_MSG.PRICE_EMPTY),
      durationName: Yup.string().required(PROPERTY_MSG.DURATION_NAME_EMPTY),
      unitName: Yup.string()
        .trim()
        .matches(REGEX.TITLE, PROPERTY_MSG.VALID_TITLE)
        .max(100, PROPERTY_MSG.TITLE_TOO_LONG)
        .min(2, PROPERTY_MSG.TITLE_TOO_SHORT)
        .required(PROPERTY_MSG.UNIT_NAME_EMPTY),
      rules: Yup.array().of(Yup.string()).min(1, PROPERTY_MSG.RULES_EMPTY),
      amenities: Yup.array()
        .of(Yup.string())
        .min(1, PROPERTY_MSG.AMENITIES_EMPTY),
      availableFrom: Yup.string().required(PROPERTY_MSG.AMENITIES_EMPTY),
      bedrooms: Yup.string()
        .trim()
        .matches(REGEX.ALLOWED_NUM_EXCEPT_ZERO, PROPERTY_MSG.VALID_BEDROOM)
        .required(PROPERTY_MSG.BEDROOM_EMPTY),
      bathrooms: Yup.string()
        .trim()
        .matches(REGEX.ALLOWED_NUM_EXCEPT_ZERO, PROPERTY_MSG.VALID_BATHROOM)
        .required(PROPERTY_MSG.BATHROOM_EMPTY),
    })
  ),

  // price: Yup.string()
  //   .matches(REGEX.PRICE, PROPERTY_MSG.VALID_PRICE)
  //   .required(PROPERTY_MSG.CANNOT_EMPTY),
  // bedrooms: Yup.string()
  //   .matches(REGEX.ALLOWED_NUMBER, PROPERTY_MSG.VALID_BEDROOM)
  //   .required(PROPERTY_MSG.CANNOT_EMPTY),
  // bathrooms: Yup.string()
  //   .matches(REGEX.ALLOWED_NUMBER, PROPERTY_MSG.VALID_BATHROOM)
  //   .required(PROPERTY_MSG.CANNOT_EMPTY),
  rules: Yup.array().of(Yup.string()).min(1, PROPERTY_MSG.RULES_EMPTY),
  // availableFrom: Yup.string().required(PROPERTY_MSG.CANNOT_EMPTY),
  // priceCategory: Yup.string().required(PROPERTY_MSG.CANNOT_EMPTY),
  propertyImg: Yup.array().min(1, 'Select at least 1 file'),
});

export const reserveSchema = Yup.object().shape({
  fromDate: Yup.string().required(PROPERTY_MSG.FROM_DATE_EMPTY),
  toDate: Yup.string().required(PROPERTY_MSG.TO_DATE_EMPTY),
  guest: Yup.string()
    .trim()
    .matches(REGEX.GUEST, PROPERTY_MSG.VALID_GUEST)
    .required(PROPERTY_MSG.GUEST_EMPTY),
  unit: Yup.string().required(PROPERTY_MSG.UNIT_EMPTY),
});

export const applyListingSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .matches(REGEX.FULL_NAME, AUTH_MSG.VALID_NAME)
    .max(35, PROPERTY_MSG.MAX_NAME)
    .required(PROPERTY_MSG.NAME_EMPTY),
  jobTitle: Yup.string()
    .trim()
    .matches(REGEX.TITLE, PROPERTY_MSG.VALID_TITLE)
    .max(40, PROPERTY_MSG.TOO_LONG)
    .min(2, PROPERTY_MSG.TOO_SHORT)
    .required(PROPERTY_MSG.JOBTITLE_EMPTY),
  companyName: Yup.string()
    .trim()
    .matches(REGEX.TITLE, PROPERTY_MSG.VALID_TITLE)
    .max(60, PROPERTY_MSG.TOO_LONG)
    .min(2, PROPERTY_MSG.TOO_SHORT)
    .required(PROPERTY_MSG.COMPANY_NAME_EMPTY),
  yearlyIncome: Yup.string()
    .trim()
    // .matches(REGEX.PRICE, PROPERTY_MSG.VALID_PRICE)
    .required(PROPERTY_MSG.YEARLY_INCOME_EMPTY),
  rentCalculation: Yup.string()
    .trim()
    // .matches(REGEX.PRICE, PROPERTY_MSG.VALID_PRICE)
    .required(PROPERTY_MSG.RENT_CALCULATION_EMPTY),
  rentablityScore: Yup.string()
    .trim()
    .matches(REGEX.PRICE, PROPERTY_MSG.VALID_PRICE)
    .required(PROPERTY_MSG.RENTABILITY_SCORE_EMPTY),
  additionalDetails: Yup.string()
    .trim()
    .matches(REGEX.DESCRIPTION, PROPERTY_MSG.VALID_DESCRIPTION)
    .max(1000, PROPERTY_MSG.DESCRIPTION_LONG)
    .min(15, PROPERTY_MSG.DESCRIPTION_SHORT),
});

export const requestTourSchema = Yup.object().shape({
  visitDate: Yup.string().required(PROPERTY_MSG.VISIT_DATE_EMPTY),
  message: Yup.string()
    .trim()
    .matches(REGEX.DESCRIPTION, PROPERTY_MSG.VALID_MESSAGE)
    .max(1000, PROPERTY_MSG.MESSAGE_LONG)
    .min(15, PROPERTY_MSG.MESSAGE_SHORT)
    .required(PROPERTY_MSG.MESSAGE_EMPTY),
});
