import { useState, useRef, useEffect } from 'react';
// @mui
import {
  Table,
  CardContent,
  Popover,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  Box,
  List,
  ListItemIcon,
  ListItemButton,
  Backdrop,
  CircularProgress,
  Pagination,
} from '@mui/material';
import { Icon } from '@iconify/react';
// import AddPayment from '../../../assets/icons/AddPayment.svg';
// import Filter from '../../../assets/icons/Filter.svg';
// import SvgColor from '../../../components/svg-color';
import Scrollbar from '../../../components/scrollbar';
import {
  getBankAccounts,
  deleteBankAccount,
  setBankPrimary,
} from '../../../services/apiServices';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';
import { DataNotPayment } from '../../../components/data-not-found';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const ActionField = (props) => {
  let { data, toDeleteBank, setBankAsPrimary } = props;
  const navigate = useNavigate();
  const anchorRef1 = useRef(null);
  const [openIn, setOpenIn] = useState(false);
  const handleEditPage = (id) => {
    navigate(`/payment/add-payment-method`, {
      state: {
        id,
      },
    });
  };
  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        <IconButton
          onClick={() => {
            setOpenIn(true);
          }}
          ref={anchorRef1}
        >
          <Icon icon="carbon:overflow-menu-vertical" />
        </IconButton>
        <Popover
          open={openIn}
          onClose={() => {
            setOpenIn(false);
          }}
          anchorEl={anchorRef1.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'center', horizontal: 'right' }}
          PaperProps={{
            sx: {
              width: '140px',
            },
          }}
        >
          <List>
            {data.isPrimary !== 1 ? (
              <ListItemButton
                onClick={() => {
                  setOpenIn(false), setBankAsPrimary(data._id);
                }}
                sx={{
                  borderRadius: 1.5,
                }}
              >
                <ListItemIcon>
                  <Icon
                    style={{ height: 20, width: 20 }}
                    icon="ph:seal-check-duotone"
                  />
                </ListItemIcon>
                Set as Primary
              </ListItemButton>
            ) : null}
            <ListItemButton
              onClick={() => {
                setOpenIn(false), toDeleteBank(data._id);
              }}
              sx={{ borderRadius: 1.5, color: 'error.main' }}
            >
              <ListItemIcon>
                <Icon
                  style={{ height: 20, width: 20 }}
                  icon="solar:trash-bin-minimalistic-bold"
                />
              </ListItemIcon>
              Delete
            </ListItemButton>
          </List>
        </Popover>
      </Box>
    </>
  );
};

export const BankListTable = (props) => {
  let {
    title,
    renderFlag,
    setRenderFlag,
    setMessage,
    setSpinFlag,
    setStatusCode,
    setOpen,
  } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const confirm = useConfirm();
  // const [open, setOpen] = useState(null);
  // const [spinFlag, setSpinFlag] = useState(false);
  // const [message, setMessage] = useState();
  const [errorOpen, setErrorOpen] = useState();
  const [bankList, setBankList] = useState([]);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  // const [renderFlag, setRenderFlag] = useState(false);
  // const [statusCode, setStatusCode] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState();

  // const handleMenuItemClick = (event, index) => {
  //   setSelectedIndex(index);
  //   setOpen(false);
  // };
  console.log('banklist.length', bankList.length);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const fetchBankList = async () => {
    try {
      setSpinFlag(true);
      let payload = {
        currentPage: currentPage,
      };
      let { data } = await getBankAccounts(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setBankList(data.bankList);
        setStatusCode(data.statusCode);
        setNumberOfPages(data.noOfPages);
      } else {
        setSpinFlag(false);
        setMessage(data.message);
        setErrorOpen(true);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  useEffect(() => {
    fetchBankList();
  }, [renderFlag, currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const toDeleteBank = async (id) => {
    try {
      confirm({ description: `you want to delete this bank` })
        .then(async () => {
          let payload = { bankId: id };
          const { data } = await deleteBankAccount(payload);
          if (data.statusCode === 200) {
            setRenderFlag(!renderFlag);
            setSpinFlag(false);
            setOpen(true);
            setMessage(data.message);
            setStatusCode(data.statusCode);
          } else {
            setSpinFlag(false);
            setOpen(true);
            setMessage(data.message);
            setStatusCode(data.statusCode);
          }
        })
        .catch(() => console.log('Deletion cancelled.'));
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const setBankAsPrimary = async (id) => {
    try {
      let payload = { bankId: id };
      const { data } = await setBankPrimary(payload);

      if (data.statusCode === 200) {
        setRenderFlag(!renderFlag);
        setStatusCode(data.statusCode);
        // setSpinFlag(false);
        // setOpen(true);
        // setMessage(data.message);
      } else {
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  return (
    <Box>
      {bankList.length > 0 && (
        <>
          {/* <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={spinFlag}
          >
            <CircularProgress color="inherit" />
          </Backdrop> */}
          {/* <DialogPopup
            open={errorOpen}
            setOpen={setErrorOpen}
            message={message}
            size="xs"
            statusCode={statusCode}
          /> */}
          <CardContent sx={{ p: 0 }}>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 1100 }}>
                <Table>
                  <TableBody>
                    {bankList.map((row) => {
                      const {
                        _id,
                        bank,
                        accountName,
                        accountNumber,
                        accountType,
                        accountId,
                      } = row;
                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                          {/* <TableCell sx={{ width: '150px' }}>
                            <Box sx={{ whiteSpace: 'normal' }}>
                              <Avatar
                                sx={{
                                  mr: 2,
                                  width: '100px',
                                  height: '67px',
                                  borderRadius: '14px',
                                }}
                                src={''}
                                alt="photoURL"
                              />
                            </Box>
                          </TableCell> */}

                          <TableCell align="left">
                            <Box sx={{ whiteSpace: 'normal' }}>
                              <Typography variant="body2">Bank name</Typography>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '16px' }}
                              >
                                {bank ? bank : '-'}
                              </Typography>
                            </Box>
                          </TableCell>

                          <TableCell align="left">
                            <Box sx={{ whiteSpace: 'normal' }}>
                              <Typography variant="body2">
                                Account number
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '16px' }}
                              >
                                {accountNumber ? accountNumber : accountId}
                              </Typography>
                            </Box>
                          </TableCell>

                          <TableCell align="left">
                            <Box sx={{ whiteSpace: 'normal' }}>
                              <Typography variant="body2">
                                Account holder's name
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '16px' }}
                              >
                                {accountName ? accountName : '-'}
                              </Typography>
                            </Box>
                          </TableCell>

                          <TableCell align="left">
                            <Box sx={{ whiteSpace: 'normal' }}>
                              <Typography variant="body2">
                                Account type
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '16px' }}
                              >
                                {accountType ? accountType : '-'}
                              </Typography>
                            </Box>
                          </TableCell>
                          {/* <TableCell align="left">
                            <Box sx={{ whiteSpace: 'normal' }}>
                              <Typography variant="body2">
                                3 Digits CVV
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '16px' }}
                              >
                                ***
                              </Typography>
                            </Box>
                          </TableCell> */}

                          <TableCell align="right">
                            <ActionField
                              data={row}
                              toDeleteBank={toDeleteBank}
                              setBankAsPrimary={setBankAsPrimary}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </CardContent>
        </>
      )}
      {bankList.length === 0 && (
        <CardContent sx={{ bgcolor: '#F9F9F9' }}>
          <DataNotPayment title="You Don’t have bank list" />
        </CardContent>
      )}
      {bankList.length !== 0 && (
        <Pagination
          shape="rounded"
          count={numberOfPages}
          siblingCount={0}
          boundaryCount={2}
          onChange={handlePageChange}
          sx={{
            py: 2,
            '& .MuiPagination-ul': { justifyContent: 'center' },
          }}
        />
      )}
    </Box>
  );
};
