import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import {
  Card,
  Typography,
  Button,
  ListItemText,
  ListItemIcon,
  CardContent,
  Box,
} from '@mui/material';
import SvgColor from '../../../components/svg-color';
import listingIcon from '../../../assets/icons/listingIcon.svg';
import { Link } from 'react-router-dom';

const StyledIcon = styled(Box)(({ theme }) => ({}));
export const AppWidgetSummaryMain = ({ title, totalProperty }) => {
  return (
    <>
      <Card
        sx={{
          minHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <CardContent
          sx={{
            textAlign: 'center',
          }}
        >
          <Typography
            component="h2"
            sx={{ fontSize: '96.3846px', fontWeight: '600' }}
          >
            {totalProperty}
          </Typography>

          <Typography variant="subtitle2" sx={{ mt: -2 }}>
            {title}
          </Typography>
          <Button
            variant="contained"
            component={Link}
            to="/dashboard/add-property"
            size="large"
            sx={{ py: 2, px: 4, mt: { lg: 4, xs: 1 } }}
          >
            Add new listing
            <SvgColor
              sx={{ width: '24px', height: '24px', ml: 1.75 }}
              src={listingIcon}
              alt={listingIcon}
            />
          </Button>
        </CardContent>
      </Card>
    </>
  );
};
