import React, { useState } from 'react';
import { alpha, styled } from '@mui/material/styles';
import {
  Card,
  Box,
  Tab,
  CardContent,
  ButtonBase,
  Backdrop,
  CircularProgress,
  Typography,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Scrollbar from '../../../components/scrollbar';
import SvgColor from '../../../components/svg-color';
import { AccountDetail } from '../account-details/AccountDetail';
import { useNavigate, Link } from 'react-router-dom';
import { useAuthStore } from '../../../redux/features/authSlice';
import EditProfile from '../../../assets/icons/EditProfile.svg';
import Listing from '../../../assets/icons/Listing.svg';
import CreateListing from '../../../assets/icons/CreateListing.svg';
import Tenant from '../../../assets/icons/Tenant.svg';
import PaymentMethod from '../../../assets/icons/PaymentMethod.svg';
import Faq from '../../../assets/icons/Faq.svg';
import Notification from '../../../assets/icons/Notification.svg';
import Messages from '../../../assets/icons/Messages.svg';
import Help from '../../../assets/icons/Help.svg';
import Language from '../../../assets/icons/Language.svg';
import Logout from '../../../assets/icons/Logout.svg';
import { HeaderBreadcrumbs } from '../../../components/breadcrumbs';

const StyledIcon = styled(Box)(({ theme }) => ({}));
const StyledTabList = styled(TabList)(({ theme }) => ({
  '& .MuiTabs-flexContainer': {
    gap: '5px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xl')]: {
      flexWrap: 'wrap',
    },
    '& .MuiButtonBase-root': {
      minWidth: 'inherit',
      textAlign: 'center',
      [theme.breakpoints.down('xl')]: {
        fontSize: '0.75rem',
        padding: '25px 5px',
      },
      [theme.breakpoints.down('lg')]: {
        width: 'calc(20% - 5px)',
        padding: '10px 5px',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(33% - 5px)',
        padding: '10px 5px',
        whiteSpace: 'normal',
      },
    },
  },
  '& .MuiTabs-indicator': {
    background: '#323232',
    borderRadius: '30px',
    height: '4px',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  '& .MuiButtonBase-root': {},
}));
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    padding: '25px 10px',
    textTransform: 'none',
    fontWeight: '500',
    color: '#808191',
    [theme.breakpoints.down('xl')]: {},
  })
);
const StyledTabLink = styled(ButtonBase)(({ theme }) => ({
  padding: '25px 10px',
  textTransform: 'none',
  fontWeight: '500',
  color: '#808191',
  flexDirection: 'column',
}));

const TabLinks = [
  {
    label: 'Listing',
    path: '/dashboard',
    icon: Listing,
  },
  // {
  //   label: 'Payment Method',
  //   path: '/payment',
  //   icon: PaymentMethod,
  // },
  {
    label: 'FAQ',
    path: '/faq',
    icon: Faq,
  },
  // {
  //   label: 'Notification',
  //   path: '/notification',
  //   icon: Notification,
  // },
  // {
  //   label: 'Messages',
  //   path: '/message',
  //   icon: Messages,
  // },
];

export const Settings = () => {
  let { loginInfo, removeLoginInfo } = useAuthStore();
  const [value, setValue] = useState('1');
  const [spinFlag, setSpinFlag] = useState(false);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleLogout = () => {
    removeLoginInfo();
    navigate('/');
  };
  return (
    <>
      <HeaderBreadcrumbs heading="Settings" />
      <TabContext value={value}>
        <Box sx={{ mb: 4 }}>
          <Card sx={{ padding: { xl: '0 20px', xs: '0 10px' } }}>
            <StyledTabList
              onChange={handleChange}
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
            >
              <StyledTab
                icon={<SvgColor src={EditProfile} />}
                label="Edit Profile"
                value="1"
              />
              {TabLinks.map((value, index) => (
                <StyledTabLink
                  disableRipple
                  component={Link}
                  to={value.path}
                  key={index}
                >
                  <SvgColor src={value.icon} sx={{ mb: 1 }} />
                  {value.label}
                </StyledTabLink>
              ))}
              <StyledTab
                icon={<SvgColor src={Help} />}
                label="Help"
                value="9"
              />
              <StyledTab
                icon={<SvgColor src={Language} />}
                label="Language"
                value="10"
              />
              <StyledTabLink onClick={handleLogout}>
                <SvgColor src={Logout} sx={{ mb: 1 }} />
                Logout
              </StyledTabLink>
            </StyledTabList>
          </Card>
        </Box>
        <Box>
          <Card>
            <CardContent
              sx={{ p: { lg: 5, md: 3, xs: 2 }, minHeight: '250px' }}
            >
              <TabPanel value="1" sx={{ p: 0 }}>
                <Scrollbar style={{ minHeight: '250px' }}>
                  <AccountDetail setSpinFlag={setSpinFlag} />
                </Scrollbar>
              </TabPanel>
              <TabPanel value="9" sx={{ p: 0 }}>
                <Scrollbar style={{ minHeight: '250px' }}>
                  Data Not Found
                </Scrollbar>
              </TabPanel>
              <TabPanel value="10" sx={{ p: 0 }}>
                <Scrollbar style={{ minHeight: '250px' }}>
                  Data Not Found
                </Scrollbar>
              </TabPanel>
            </CardContent>
          </Card>
        </Box>
      </TabContext>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
