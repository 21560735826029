import React, { useState, useEffect } from 'react';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../../redux/features/authSlice';
import { IMG_URL } from '../../../utils/constant';
import { Avatar } from '@mui/material';
import { createUserLogged } from '../../../utils/common-function';

const ProfileDropdown = () => {
  const { removeAdminLoginInfo, loginAdminInfo } = useAuthStore();
  const navigate = useNavigate();
  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  const handleLogout = () => {
    createUserLogged('logout', 'user logout', new Date(), loginAdminInfo._id);
    removeAdminLoginInfo();
    navigate('/admin');
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            {loginAdminInfo?.profile ? (
              <img
                className="rounded-circle img-thumbnail header-profile-user"
                src={`${IMG_URL}${loginAdminInfo.profile}`}
                alt="Header Avatar"
              />
            ) : (
              <Avatar
                sx={{ bgcolor: 'deepskyblue' }}
                className="rounded-circle img-thumbnail header-profile-user"
              >
                {loginAdminInfo?.firstname?.charAt(0)}
              </Avatar>
            )}
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text text-capitalize">
                {loginAdminInfo.firstname}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text text-capitalize">
                {loginAdminInfo.role}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">
            Welcome {loginAdminInfo.firstname}
          </h6>
          <Link
            className="dropdown-item"
            onClick={() => {
              setIsProfileDropdown(!isProfileDropdown);
            }}
            to={'/admin/profile'}
          >
            <i className="ri-account-circle-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Profile</span>
          </Link>
          <Link
            className="dropdown-item"
            onClick={() => {
              setIsProfileDropdown(!isProfileDropdown);
            }}
            to={'/admin/settings'}
          >
            <i className="ri-settings-3-line text-muted fs-16 align-middle me-1"></i>{' '}
            <span className="align-middle">Settings</span>
          </Link>
          <DropdownItem divider />
          <Button className="dropdown-item" onClick={handleLogout}>
            <i className="ri-logout-box-r-line text-muted fs-16 align-middle me-1"></i>{' '}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </Button>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
