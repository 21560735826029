import * as Yup from 'yup';
import { PAYMENT_MSG } from '../messages/error';
import { REGEX } from '../regex';

export const addPaymentCardSchema = Yup.object().shape({
  cardHolderName: Yup.string()
    .trim()
    .matches(REGEX.FULL_NAME, PAYMENT_MSG.VALID_HOLDER_NAME)
    .max(35, PAYMENT_MSG.MAX_NAME)
    .required(PAYMENT_MSG.CANNOT_EMPTY),
  expirationDate: Yup.string().required(PAYMENT_MSG.CANNOT_EMPTY),
  cvv: Yup.string().trim().matches(REGEX.CVV, PAYMENT_MSG.VALID_CVV),
  cardNumber: Yup.string()
    .matches(REGEX.CARD_NUMBER, PAYMENT_MSG.VALID_CARD_NUMBER)
    .required(PAYMENT_MSG.CANNOT_EMPTY),
});

export const addBankAccountSchema = Yup.object().shape({
  accountName: Yup.string()
    .trim()
    .matches(REGEX.FULL_NAME, PAYMENT_MSG.VALID_ACCOUNT_NAME)
    .max(35, PAYMENT_MSG.MAX_NAME)
    .required(PAYMENT_MSG.ACCOUNT_NAME_EMPTY),
  bankName: Yup.object().required(PAYMENT_MSG.BANK_NAME_EMPTY),
  accountType: Yup.string().trim().required(PAYMENT_MSG.ACCOUNT_TYPE_EMPTY),
  accountNumber: Yup.string()
    .matches(REGEX.ALLOWED_ONLY_NUMBER, PAYMENT_MSG.VALID_CARD_NUMBER)
    .required(PAYMENT_MSG.ACCOUNT_NUMBER_EMPTY),
  // documentNumber: Yup.string()
  //   .max(15, PAYMENT_MSG.MAX_MOBILE)
  //   .min(7, PAYMENT_MSG.MIN_MOBILE)
  //   // .matches(REGEX.MOBILE, AUTH_MSG.VALID_PHONE)
  //   .required(PAYMENT_MSG.CANNOT_EMPTY),
  // documentType: Yup.string().trim().required(PAYMENT_MSG.CANNOT_EMPTY),
});
