import React from 'react';
import { Container } from 'reactstrap';
import PropertyRentalInquiry from './PropertyRentalInquiry';
import BookingConfirmation from './BookingConfirmation';
import BookingRequestRejected from './BookingRequestRejected';
import WalletToBankAccount from './WalletToBankAccount';
import EmailVerifyAction from './EmailVerifyAction';

const Email = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="pb-5">
            <PropertyRentalInquiry />
            {/* <BookingConfirmation />
            <BookingRequestRejected />
            <WalletToBankAccount />
            <EmailVerifyAction /> */}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Email;
