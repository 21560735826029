import {
  Grid,
  Box,
  Container,
  Typography,
  Button,
  Card,
  CardContent,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Slider from 'react-slick';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import SvgColor from '../../components/svg-color';
import { useResponsive } from '../../hooks/useResponsive';
import { getTopRatedCities } from '../../services/apiServices';
import ArrowIconLeft from '../../assets/icons/ArrowIconLeft.svg';
import ArrowIconRight from '../../assets/icons/ArrowIconRight.svg';
import rightArrow from '../../assets/icons/rightArrow.svg';
import bannerImg from '../../assets/images/bannerImg.png';
import { IMG_URL } from '../../utils/constant';
import { DialogPopup } from '../../components/dialog-popup/DialogPopup';
import { latLng } from 'leaflet';

const StylePopular = styled(Box)(({ theme }) => ({
  background: '#FAFAFA',
  padding: '50px 0',
  [theme.breakpoints.down('lg')]: {
    padding: '56px 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: '40px 0',
  },
}));

const ProductImgBox = styled(Box)(({ theme }) => ({
  background: '#FAFAFA',
  position: 'relative',
  marginBottom: '24px',
  aspectRatio: '1.4',
  borderRadius: '14px',
  overflow: 'hidden',
  transition: '300ms',
}));

const StyledProductImg = styled('img')(({ theme }) => ({
  transition: '300ms',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}));

const BannerLeftMain = styled(Box)(({ theme }) => ({}));
const BannerLeft = styled(Box)(({ theme }) => ({
  maxWidth: '520px',
  textAlign: 'center',
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
  '& .MuiTypography-h2': {
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
      marginBottom: '20px',
    },
  },
  '& .MuiTypography-h6': {
    [theme.breakpoints.down('xl')]: {
      fontSize: '1.0625rem',
    },
  },
}));
const BannerLeftText = styled(Box)(({ theme }) => ({
  marginBottom: '20px',
  [theme.breakpoints.down('md')]: {
    marginBottom: '20px',
  },
}));
const CarouselDiv = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: '0 24px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 0',
  },
  '& .slick-slider': {
    '& .slick-dots': {
      bottom: '0',
    },
    '& .slick-list': {
      width: '100%',
    },
  },
  '& .slick-arrow': {
    fontSize: '0',
    width: '40px',
    height: '40px',
    zIndex: '1',
    transition: '0.45s ease-in-out',
    borderRadius: '50%',
    marginTop: '-30px',
    '&:before': {
      fontSize: '0',
    },
    '&.slick-prev': {
      background: `rgba(255,255,255,0.8) url(${ArrowIconLeft}) no-repeat center center / cover`,
      left: '0',
      [theme.breakpoints.up('xl')]: {
        left: '-30px',
      },
    },
    '&.slick-next': {
      background: `rgba(255,255,255,0.8) url(${ArrowIconRight}) no-repeat center center / cover`,
      right: '0',
      [theme.breakpoints.up('xl')]: {
        right: '-30px',
      },
    },
    '&.slick-disabled': {
      opacity: '0.5',
    },
  },
}));
const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  dots: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        arrows: false,
        dots: true,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
        arrows: false,
        dots: true,
      },
    },
  ],
};

export const CitiesSection = (props) => {
  let { homeContents, featuredCities } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [spinFlag, setSpinFlag] = useState();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [statusCode, setStatusCode] = useState();
  const [topCities, setTopCities] = useState();
  const isMobileMode = useResponsive('down', 'md');
  const topRatedCities = async () => {
    try {
      setSpinFlag(true);
      const { data } = await getTopRatedCities();
      if (data.statusCode === 200) {
        setTopCities(data.topRatedCities);
        setSpinFlag(false);
      } else {
        setOpen(true);
        setMessage(data.message);
        setSpinFlag(false);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const redirectToAvailableSpaces = (latLng) => {
    if (latLng.length > 0) {
      navigate(`/available-spaces?lat=${latLng[0]}&&lng=${latLng[1]}`);
    }
  };

  useEffect(() => {
    topRatedCities();
  }, []);

  return (
    <>
      <DialogPopup
        open={open}
        setOpen={setOpen}
        message={message}
        size="xs"
        statusCode={statusCode}
      />
      <StylePopular>
        <Container fixed={true} sx={{ maxWidth: { xl: '1326px' } }}>
          <BannerLeftMain>
            <BannerLeft>
              <BannerLeftText>
                {featuredCities && featuredCities?.length > 0 && (
                  <>
                    <Typography
                      variant="button"
                      component="p"
                      sx={{ mb: 2, color: 'secondary.main' }}
                    >
                      {featuredCities[0]?.title}
                    </Typography>
                    <Typography
                      variant="h2"
                      sx={{ mb: 2, color: 'primary.main' }}
                    >
                      {featuredCities[0]?.subTitle}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 'normal', color: 'text.secondary' }}
                    >
                      {featuredCities[0]?.description}
                    </Typography>
                  </>
                )}
              </BannerLeftText>
            </BannerLeft>
          </BannerLeftMain>
          <Grid container>
            <Grid item xs={12}>
              <CarouselDiv>
                {topCities && (
                  <Slider {...settings}>
                    {topCities.map((value, index) => (
                      <Box
                        key={index}
                        sx={{
                          pt: { lg: 3, xs: 2 },
                          px: 2,
                          pb: { lg: 4, xs: 4 },
                        }}
                      >
                        <Card
                          sx={{
                            boxShadow: 'none',
                            width: '409px',
                            maxWidth: '100%',
                            margin: '0 auto',
                            '&:hover': {
                              borderColor: 'transparent',
                              boxShadow: {
                                lg: '0px 14px 28px rgba(0, 0, 0, 0.05)',
                                xs: '0px 14px 28px rgba(0, 0, 0, 0.05)',
                              },
                              '& .MuiButton-root': {
                                background: '#FEAE01',
                                color: '#fff !important',
                              },
                            },
                          }}
                        >
                          <CardContent>
                            <ProductImgBox className="productImg">
                              <StyledProductImg
                                alt={value.city}
                                src={
                                  value.cityImage
                                    ? `${IMG_URL}${value.cityImage}`
                                    : bannerImg
                                }
                              />
                            </ProductImgBox>
                            <Box sx={{ display: 'flex' }}>
                              <Box sx={{ flexGrow: '1', minWidth: '200px' }}>
                                {/* <Link
                                  color="inherit"
                                  underline="hover"
                                  style={{ textDecoration: 'none' }}
                                > */}
                                <Typography
                                  variant="h5"
                                  noWrap
                                  sx={{ color: 'primary.main' }}
                                >
                                  {value.city}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  noWrap
                                  sx={{ color: 'text.secondary' }}
                                >
                                  {value.totalSpaces} Spaces
                                </Typography>
                                {/* </Link> */}
                              </Box>
                              <Button
                                variant="outlined"
                                color="secondary"
                                sx={{
                                  width: '44px',
                                  height: '44px',
                                  borderRadius: '6px',
                                  p: 1,
                                  minWidth: '44px',
                                }}
                                onClick={() =>
                                  redirectToAvailableSpaces(value.latLng)
                                }
                              >
                                <SvgColor
                                  sx={{ width: '15px', height: '15px' }}
                                  src={rightArrow}
                                />
                              </Button>
                            </Box>
                          </CardContent>
                        </Card>
                      </Box>
                    ))}
                  </Slider>
                )}
              </CarouselDiv>
            </Grid>
          </Grid>
        </Container>
      </StylePopular>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
