import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import { VerifyEmails } from '../auth/vrerify-email';
import { BannerSection } from './BannerSection';
import { PopularSpacesSection } from './PopularSpacesSection';
import { BecomeHostSection } from './BecomeHostSection';
import { CitiesSection } from './CitiesSection';
import { TestimonialSection } from './TestimonialSection';
import { FaqSection } from './FaqSection';
import { ContactUsSection } from './ContactUsSection';
import {
  getCmsContent,
  getHomeContent,
  fetchAllCities,
} from '../../services/apiServices';
import { useAuthStore } from '../../redux/features/authSlice';
import { DialogPopup } from '../../components/dialog-popup/DialogPopup';
import { PropertyFilter } from '../available-spaces/PropertyFilter';

export const Home = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [homePageData, setHomePageData] = useState();
  const [homeContents, setHomeContents] = useState();
  const [testimonialsList, setTestimonialsList] = useState([]);
  const [faqsList, setFaqsList] = useState([]);
  const [contactUsList, setContactUsList] = useState([]);
  const [spinFlag, setSpinFlag] = useState(false);
  const [bannerSection, setBannerSection] = useState([]);
  const [exploreRentals, setExploreRentals] = useState([]);
  const [becomeLandlord, setBecomeLandlord] = useState([]);
  const [featuredCities, setFeaturedCities] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [contactUs, setContactUs] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [statusCode, setStatusCode] = useState();

  const [availRenderFlag, setAvailRenderFlag] = useState();
  const [priceRangeFilter, setPriceRangeFilter] = useState([]);
  const [priceDuration, setPriceDuration] = useState(null);
  const [cities, setCities] = useState([]);
  const [bedroomFilter, setBedroomFilter] = useState('any');
  const [propertyTypeFilter, setPropertyTypeFilter] = useState('');
  const [amenitiesFilter, setAmenitiesFilter] = useState([]);
  const [openFilterPop, setFilterPop] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    checkIn: '',
    checkOut: '',
  });
  const [moveInDate, setMoveInDate] = useState(null);
  const [leaseDuration, setLeaseDuration] = useState([]);
  console.log('leaseDuration', leaseDuration);
  // const [visibleProperties, setVisibleProperties] = useState(9);
  const [cityCheckbox, setCityCheckbox] = useState([]);
  // const [propertiesData, setPropertiesData] = useState([]);
  // const [totalProperty, setTotalProperty] = useState();

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setFilterPop(true);
  };
  const handleCloseFilter = () => {
    setFilterPop(false);
  };

  let { loginInfo } = useAuthStore();
  const resultRef = useRef(null);
  const homePageContent = async () => {
    try {
      const { data } = await getHomeContent();
      if (data.statusCode === 200) {
        setHomePageData(data.homeData);
        setTestimonialsList(data.homeData.testimonialsList);
        setFaqsList(data.homeData.faqsList);
        setContactUsList(data.homeData.contactUsList);
        setHomeContents(data.homeData.homeContent);
      } else {
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };
  const fetchListContent = async () => {
    try {
      setSpinFlag(true);
      const payload = { type: pathname };
      const { data } = await getCmsContent(payload);
      if (data.statusCode === 200) {
        setBannerSection(
          data?.cmsContent?.filter((item) => item?.section === 'bannerSection')
        );
        setExploreRentals(
          data?.cmsContent?.filter((item) => item?.section === 'exploreRentals')
        );
        setBecomeLandlord(
          data?.cmsContent?.filter((item) => item?.section === 'becomeLandlord')
        );
        setFeaturedCities(
          data?.cmsContent?.filter((item) => item?.section === 'featuredCities')
        );
        setReviews(
          data?.cmsContent?.filter((item) => item?.section === 'reviews')
        );
        setFaqs(data?.cmsContent?.filter((item) => item?.section === 'faqs'));
        setContactUs(
          data?.cmsContent?.filter((item) => item?.section === 'contactUs')
        );
        setSpinFlag(false);
        // setMessage(data.message);
        // setOpen(true);
        // setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getCities = async () => {
    try {
      setSpinFlag(true);
      const { data } = await fetchAllCities();
      if (data.statusCode === 200) {
        setCityCheckbox(data?.cities);
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  useEffect(() => {
    homePageContent();
    fetchListContent();
    getCities();
  }, []);

  return (
    <>
      <VerifyEmails setSpinFlag={setSpinFlag} />
      <BannerSection
        bannerSection={bannerSection}
        // homePageData={homePageData}
        homeContents={homeContents}
        loginInfo={loginInfo}
        resultRef={resultRef}
        handleClickOpen={handleClickOpen}
      />

      {loginInfo.role !== 'landlord' && (
        <>
          <PopularSpacesSection
            // homePageData={homePageData}
            exploreRentals={exploreRentals}
            homeContents={homeContents}
            setSpinFlag={setSpinFlag}
          />
          <BecomeHostSection
            // homePageData={homePageData}
            becomeLandlord={becomeLandlord}
            homeContents={homeContents}
          />
          <CitiesSection
            featuredCities={featuredCities}
            // homePageData={homePageData}
            homeContents={homeContents}
          />
        </>
      )}
      <TestimonialSection
        testimonialsList={testimonialsList}
        // homePageData={homePageData}
        reviews={reviews}
        homeContents={homeContents}
      />
      <FaqSection
        faqsList={faqsList}
        faqs={faqs}
        // homePageData={homePageData}
        homeContents={homeContents}
      />
      <ContactUsSection
        contactUsList={contactUsList}
        // homePageData={homePageData}
        contactUs={contactUs}
        homeContents={homeContents}
        setSpinFlag={setSpinFlag}
      />
      <DialogPopup
        handleClose={handleClose}
        open={open}
        message={message}
        setOpen={setOpen}
        statusCode={statusCode}
        size="xs"
      />

      <PropertyFilter
        setRenderFlag={setAvailRenderFlag}
        renderFlag={availRenderFlag}
        setPriceRangeFilter={setPriceRangeFilter}
        setPriceDuration={setPriceDuration}
        setCities={setCities}
        setBedroomFilter={setBedroomFilter}
        setPropertyTypeFilter={setPropertyTypeFilter}
        setAmenitiesFilter={setAmenitiesFilter}
        handleClose={handleCloseFilter}
        setDateFilter={setDateFilter}
        open={openFilterPop}
        city={cityCheckbox}
        setMoveInDate={setMoveInDate}
        moveInDate={moveInDate}
        setLeaseDuration={setLeaseDuration}
        leaseDuration={leaseDuration}
        pathname={pathname}
        //
        priceRangeFilter={priceRangeFilter}
      />

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
