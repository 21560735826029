import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Avatar,
} from '@mui/material';
import Rating from 'react-rating';
import Slider from 'react-slick';
import { styled } from '@mui/material/styles';
import Intersect from '../../assets/images/Intersect.png';
import ArrowIconLeft from '../../assets/icons/ArrowIconLeft.svg';
import ArrowIconRight from '../../assets/icons/ArrowIconRight.svg';
import { useResponsive } from '../../hooks/useResponsive';
import { IMG_URL } from '../../utils/constant';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconEmpty': {
    color: '#D3D3DC',
  },
  '& .MuiRating-iconFilled': {
    color: '#FFBD3E',
  },
  '& .MuiRating-iconHover': {
    color: '#FFBD3E',
  },
});
const StylePopular = styled(Box)(({ theme }) => ({
  background: `url(${Intersect}) no-repeat top center / 100% 48%`,
  padding: '50px 0',
  [theme.breakpoints.down('lg')]: {
    padding: '56px 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: '40px 0',
  },
}));
const BannerLeftMain = styled(Box)(({ theme }) => ({}));
const BannerLeftText = styled(Box)(({ theme }) => ({
  marginBottom: '20px',
  [theme.breakpoints.down('md')]: {
    marginBottom: '20px',
  },
}));
const BannerLeft = styled(Box)(({ theme }) => ({
  maxWidth: '520px',
  textAlign: 'center',
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
  '& .MuiTypography-h2': {
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
      marginBottom: '20px',
    },
  },
  '& .MuiTypography-h6': {
    [theme.breakpoints.down('xl')]: {
      fontSize: '1.0625rem',
    },
  },
}));
const CarouselDiv = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: '0 24px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 0',
  },
  '& .slick-slider': {
    '& .slick-dots': {
      bottom: '0',
    },
    '& .slick-list': {
      width: '100%',
    },
  },
  '& .slick-arrow': {
    fontSize: '0',
    width: '40px',
    height: '40px',
    zIndex: '1',
    transition: '0.45s ease-in-out',
    borderRadius: '50%',
    marginTop: '-30px',
    '&:before': {
      fontSize: '0',
    },
    '&.slick-prev': {
      background: `rgba(255,255,255,0.8) url(${ArrowIconLeft}) no-repeat center center / cover`,
      left: '0',
      [theme.breakpoints.up('xl')]: {
        left: '-30px',
      },
    },
    '&.slick-next': {
      background: `rgba(255,255,255,0.8) url(${ArrowIconRight}) no-repeat center center / cover`,
      right: '0',
      [theme.breakpoints.up('xl')]: {
        right: '-30px',
      },
    },
    '&.slick-disabled': {
      opacity: '0.5',
    },
  },
}));
const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  dots: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        arrows: false,
        dots: true,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
        arrows: false,
        dots: true,
      },
    },
  ],
};

export const TestimonialSection = (props) => {
  let { testimonialsList, homeContents, reviews } = props;
  const isMobileMode = useResponsive('down', 'md');
  return (
    <>
      <StylePopular>
        <Container fixed={true} sx={{ maxWidth: { xl: '1326px' } }}>
          <BannerLeftMain>
            <BannerLeft>
              <BannerLeftText>
                {reviews && reviews?.length > 0 && (
                  <>
                    <Typography
                      variant="button"
                      component="p"
                      sx={{ mb: 2, color: 'secondary.main' }}
                    >
                      {reviews[0]?.subTitle}
                    </Typography>
                    <Typography
                      variant="h2"
                      sx={{ mb: 2, color: 'primary.main' }}
                    >
                      {reviews[0]?.title}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 'normal', color: 'text.secondary' }}
                    >
                      {reviews[0]?.description}
                    </Typography>
                  </>
                )}
              </BannerLeftText>
            </BannerLeft>
          </BannerLeftMain>
          <Grid container>
            <Grid item xs={12}>
              <CarouselDiv>
                <Slider {...settings}>
                  {testimonialsList.map((value, index) => (
                    <Box
                      key={index}
                      sx={{
                        pt: { lg: 3, xs: 2 },
                        px: 2,
                        pb: { lg: 4, xs: 4 },
                      }}
                    >
                      <Card
                        sx={{
                          boxShadow: 'none',
                          border: '1px solid #EBEBEB',
                          borderRadius: '10px',
                          '&:hover': {
                            boxShadow: {
                              lg: '0px 14px 28px rgba(0, 0, 0, 0.05)',
                              xs: '0px 14px 28px rgba(0, 0, 0, 0.05)',
                            },
                            borderColor: 'transparent',
                          },
                        }}
                      >
                        <CardContent sx={{ pt: 4, px: 3 }}>
                          <Box>
                            <Rating
                              emptySymbol="mdi mdi-star text-muted"
                              fullSymbol={'mdi mdi-star text-warning'}
                              readonly
                              initialRating={value.rating}
                            />
                            {/* <StyledRating
                              readOnly
                              initialRating={3}
                              icon={<Icon icon="ph:star-fill" />}
                              emptyIcon={<Icon icon="ph:star-fill" />}
                              sx={{
                                fontSize: {
                                  xs: '1rem',
                                  md: '1rem',
                                  lg: '1.125rem',
                                  xl: '1.25rem',
                                },
                              }}
                            /> */}
                            <Typography
                              variant="body2"
                              sx={{ mt: 4, lineHeight: 2 }}
                            >
                              {value.review}
                            </Typography>
                          </Box>
                        </CardContent>
                        <CardHeader
                          sx={{ pb: 5 }}
                          avatar={
                            <Avatar
                              aria-label="recipe"
                              src={`${IMG_URL}${value.senderProfile}`}
                            ></Avatar>
                          }
                          title={
                            <Typography variant="subtitle1">
                              {value.senderName}
                            </Typography>
                          }
                          subheader={
                            <Typography
                              variant="body2"
                              sx={{ color: 'text.secondary' }}
                            >
                              {value.senderPost}
                            </Typography>
                          }
                        />
                      </Card>
                    </Box>
                  ))}
                </Slider>
              </CarouselDiv>
            </Grid>
          </Grid>
        </Container>
      </StylePopular>
    </>
  );
};
