import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from 'reactstrap';
import { useNavigate, useLocation } from 'react-router-dom';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';
import logoLight from '../../assets/images/logo-light.png';
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';

import withRouter from '../../Components/Common/withRouter';
import { REGEX } from '../../../utils/regex';
import { AUTH_MSG } from '../../../utils/messages/error';
import { resetPassword } from '../../../services/apiServices';
import ShowSpinner from '../../Components/Common/spinner';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const AdminResetPasswordPage = (props) => {
  let query = useQuery();
  let token = query.get('token');
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const dispatch = useDispatch();
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordShowConfirm, setPasswordShowConfirm] = useState(false);

  const [state, setState] = useState({
    showPassword: false,
    showPasswordco: false,
    message: '',
    spinFlag: false,
    showAlert: false,
    showAlertError: false,
  });
  const initialValues = {
    password: '',
    confirmpassword: '',
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .matches(REGEX.PASSWORD, AUTH_MSG.VALID_PASSWORD)
        .required(AUTH_MSG.CANNOT_EMPTY),
      confirmpassword: Yup.string()
        .when('password', {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref('password')],
            AUTH_MSG.SAME_PASSWORD
          ),
        })
        .required(AUTH_MSG.CANNOT_EMPTY),
    }),
    onSubmit: async (values) => {
      let payload = {
        password: values.password,
        token: token,
      };
      try {
        setState({ ...state, spinFlag: true });
        const { data } = await resetPassword(payload);
        if (data.statusCode == 200) {
          setState({
            ...state,
            message: data.message,
            spinFlag: false,
            showAlert: true,
            showAlertError: false,
          });
          resetForm();
          setTimeout(() => {
            navigate('/admin');
          }, 2000);
        } else {
          setState({
            ...state,
            message: data.message,
            spinFlag: false,
            showAlert: false,
            showAlertError: true,
          });
          resetForm();
        }
      } catch (err) {
        navigate(`/error?msg=${err}&&path=${pathname}`);
      }
    },
  });
  useEffect(() => {
    if (token === null) {
      navigate('/admin/reset-password');
    }
  }, []);
  const { resetForm } = validation;
  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img
                      src={logoLight}
                      alt=""
                      height="80"
                      width="220"
                      style={{ objectFit: 'contain' }}
                    />
                  </Link>
                </div>
                <h4 className="mt-3">FLATTTS</h4>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4 border">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Reset password</h5>
                    <p className="text-muted">Reset password with velzon</p>
                    <lord-icon
                      src="https://cdn.lordicon.com/rhvddzym.json"
                      trigger="loop"
                      colors="primary:#0ab39c"
                      className="avatar-xl"
                      style={{ width: '120px', height: '120px' }}
                    ></lord-icon>
                  </div>
                  <div>
                    {state.showAlert ? (
                      <Alert sx={{ mb: 2 }} severity="success">
                        {state.message}
                      </Alert>
                    ) : (
                      ''
                    )}
                    {state.showAlertError ? (
                      <Alert sx={{ mb: 2 }} severity="error">
                        {state.message}
                      </Alert>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="p-2">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-4">
                        <Label className="form-label">Password</Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            name="password"
                            className="form-control"
                            placeholder="Enter password"
                            type={passwordShow ? 'text' : 'password'}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ''}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.password}</div>
                            </FormFeedback>
                          ) : null}
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                            type="button"
                            id="password-addon"
                            onClick={() => setPasswordShow(!passwordShow)}
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>

                      <div>
                        <Label className="form-label">Confirm Password</Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            name="confirmpassword"
                            className="form-control"
                            placeholder="Confirm Password"
                            type={passwordShowConfirm ? 'text' : 'password'}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirmpassword || ''}
                            invalid={
                              validation.touched.confirmpassword &&
                              validation.errors.confirmpassword
                                ? true
                                : false
                            }
                          />
                          {validation.touched.confirmpassword &&
                          validation.errors.confirmpassword ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.confirmpassword}</div>
                            </FormFeedback>
                          ) : null}
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                            type="button"
                            id="password-addon"
                            onClick={() =>
                              setPasswordShowConfirm(!passwordShowConfirm)
                            }
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>

                      <div className="text-center mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          Submit{' '}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-4 text-center">
                <p className="mb-0">
                  Wait, I remember my password...{' '}
                  <Link
                    to="/admin"
                    className="fw-semibold text-primary text-decoration-underline"
                  >
                    {' '}
                    Click here{' '}
                  </Link>{' '}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <ShowSpinner spinFlag={state.spinFlag} />
      </div>
    </ParticlesAuth>
  );
};

AdminResetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(AdminResetPasswordPage);
