import React from 'react';
import { Typography, Stack, Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import bannerImg from '../../assets/images/bannerImg.png';
import SvgColor from '../../components/svg-color';
import Like from '../../assets/icons/Like.svg';
import NoDataImg from '../../assets/images/ic_content.svg';
import MessageSign from '../../assets/icons/MessageSign.svg';
import noCredit from '../../assets/icons/undraw_credit_card_re_blml.svg';
import noNotifications from '../../assets/icons/undraw_push_notifications.svg';

export const DataNotAvailable = ({ sx, title }) => {
  //let { title } = props;
  return (
    <>
      <Stack
        sx={{
          py: 5,
          px: 2,
          bgcolor: 'rgba(145, 158, 171, 0.04)',
          border: '1px dashed rgba(145, 158, 171, 0.08)',
          borderRadius: '14px',
          width: '100%',
          minHeight: '300px',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          ...sx,
        }}
      >
        <img
          style={{ maxWidth: '160px' }}
          src={NoDataImg}
          alt="empty content"
        />
        <Typography variant="h6" color="text.secondary" sx={{ mt: 2 }}>
          {title}
        </Typography>
      </Stack>
    </>
  );
};

export const NoMessage = ({ sx, handleClickOpen }) => {
  return (
    <>
      <Stack
        sx={{
          py: 5,
          px: 2,
          // bgcolor: 'rgba(145, 158, 171, 0.04)',
          // border: '1px dashed rgba(145, 158, 171, 0.08)',
          borderRadius: '0',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          ...sx,
        }}
      >
        <Box sx={{ maxWidth: '350px' }}>
          <img
            style={{ maxWidth: '160px', margin: '0 auto' }}
            src={MessageSign}
            alt="empty content"
          />
          <Typography variant="h3" sx={{ mt: 2 }}>
            No message yet
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
            looks like you haven't initiated a conversation with any people
          </Typography>
          <Button
            variant="contained"
            size="large"
            sx={{ mt: 3 }}
            onClick={handleClickOpen}
          >
            Start Conversation Now
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export const DataNotPayment = ({ sx, title }) => {
  // let { title } = props;
  return (
    <>
      <Stack
        sx={{
          py: 5,
          px: 2,
          // bgcolor: 'rgba(145, 158, 171, 0.04)',
          // border: '1px dashed rgba(145, 158, 171, 0.08)',
          // borderRadius: '14px',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          ...sx,
        }}
      >
        <img style={{ maxWidth: '160px' }} src={noCredit} alt="empty content" />
        <Typography variant="h6" color="text.secondary" sx={{ mt: 2 }}>
          {title}
        </Typography>
      </Stack>
    </>
  );
};

export const NoNotifications = ({ sx, title }) => {
  console.log('title', title);
  // let { title } = props;
  return (
    <>
      <Stack
        sx={{
          py: 5,
          px: 2,
          // bgcolor: 'rgba(145, 158, 171, 0.04)',
          // border: '1px dashed rgba(145, 158, 171, 0.08)',
          // borderRadius: '14px',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          ...sx,
        }}
      >
        <img src={noNotifications} alt="empty content" />
        <Typography variant="h6" color="text.secondary" sx={{ mt: 8 }}>
          {title}
        </Typography>
      </Stack>
    </>
  );
};
