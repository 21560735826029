import React, { useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { IMG_URL } from '../../utils/constant';
import { currencySeprator } from '../../utils/common-function';
import Scrollbar from '../../components/scrollbar';
import { nairaSymbol } from '../../utils/constant';
import { useAuthStore } from '../../redux/features/authSlice';

const Row = (props) => {
  const { unitsItem } = props;
  let {
    unitName,
    price,
    bedrooms,
    bathrooms,
    rules,
    amenities,
    durationName,
    isActive,
    availableFrom,
  } = unitsItem;
  let { loginInfo } = useAuthStore();
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow>
        <TableCell
          sx={{
            p: 0,
            border: '1px solid rgba(145, 158, 171, 0.16)',
            borderRadius: '12px',
          }}
          colSpan={6}
        >
          <Table>
            <TableRow sx={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  border: 'none',
                  width: 200,
                  maxWidth: 200,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {unitName}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  border: 'none',
                  width: 100,
                  maxWidth: 100,
                }}
              >
                {durationName === 'year' ? (
                  <>
                    {nairaSymbol}
                    {currencySeprator(Math.trunc(price / 12))}
                  </>
                ) : durationName === 'sixMonth' ? (
                  <>
                    {nairaSymbol}
                    {currencySeprator(Math.trunc(price / 6))}
                  </>
                ) : durationName === 'threeMonth' ? (
                  <>
                    {nairaSymbol}
                    {currencySeprator(Math.trunc(price / 3))}
                  </>
                ) : durationName === 'month' ? (
                  <>
                    {nairaSymbol}
                    {currencySeprator(price)}
                  </>
                ) : null}{' '}
                /month
              </TableCell>
              <TableCell
                sx={{
                  border: 'none',
                  width: 80,
                  maxWidth: 80,
                }}
              >
                {bedrooms}
              </TableCell>
              <TableCell
                sx={{
                  border: 'none',
                  width: 80,
                  maxWidth: 80,
                }}
              >
                {bathrooms}
              </TableCell>
              <TableCell
                sx={{
                  border: 'none',
                  width: 140,
                  maxWidth: 140,
                }}
              >
                {availableFrom.slice(0, 10)}
              </TableCell>
              {loginInfo.role === 'landlord' ? (
                <TableCell
                  sx={{
                    border: 'none',
                    width: 100,
                    maxWidth: 100,
                  }}
                >
                  <Typography variant="body2" color={isActive ? '' : 'error'}>
                    {isActive ? 'Active' : 'Inactive'}
                  </Typography>
                </TableCell>
              ) : (
                ''
              )}
              <TableCell
                sx={{
                  border: 'none',
                  width: 50,
                  maxWidth: 50,
                }}
              >
                <IconButton aria-label="expand row" size="small">
                  {open ? (
                    <Icon icon="iconoir:nav-arrow-up" />
                  ) : (
                    <Icon icon="iconoir:nav-arrow-down" />
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  border: 'none',
                  p: 0,
                }}
                colSpan={6}
              >
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box
                    sx={{
                      p: 2,
                      borderTop: '1px dashed rgba(145, 158, 171, 0.16)',
                    }}
                  >
                    {/* <Box>
                      <Typography variant="subtitle2" gutterBottom>
                        Available From: {availableFrom.slice(0, 10)}
                      </Typography>
                    </Box> */}
                    <Box sx={{ mt: 3 }}>
                      <Typography variant="subtitle2" gutterBottom>
                        Offers
                      </Typography>
                      <List
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: { sm: 'auto auto', xs: 'auto' },
                          gap: { sm: 1, xs: 0.5 },
                          p: 0,
                        }}
                      >
                        {amenities.map((item, index) => {
                          return (
                            <ListItem key={index} sx={{ p: 0 }}>
                              <ListItemIcon>
                                <img
                                  style={{
                                    width: '18px',
                                    height: '18px',
                                    objectFit: 'contain',
                                  }}
                                  src={`${IMG_URL}${item.path}`}
                                />
                              </ListItemIcon>
                              <ListItemText>
                                <Typography variant="body2">
                                  {item.amenities}
                                </Typography>
                              </ListItemText>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                      <Typography variant="subtitle2" gutterBottom>
                        Rules
                      </Typography>
                      <List sx={{ display: 'grid', gap: 0.5, p: 0 }}>
                        {rules.map((item, index) => {
                          return (
                            <ListItem key={index} sx={{ p: 0 }}>
                              <ListItemText>
                                <Typography variant="body2">{item}</Typography>
                              </ListItemText>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Box>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </Table>
        </TableCell>
      </TableRow>
    </>
  );
};

export const PropertyUnitList = (props) => {
  let { propertyUnits } = props;
  let { loginInfo } = useAuthStore();
  return (
    <Scrollbar>
      <TableContainer component={Paper} sx={{ minWidth: 550 }}>
        <Table
          aria-label="collapsible table"
          sx={{ borderSpacing: '0px 16px', borderCollapse: 'separate' }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  bgcolor: 'rgb(244, 246, 248)',
                  border: 'none',
                  borderTopLeftRadius: '12px',
                  borderBottomLeftRadius: '12px',
                  width: 200,
                  maxWidth: 200,
                }}
              >
                Units name/no.
              </TableCell>
              <TableCell
                sx={{
                  bgcolor: 'rgb(244, 246, 248)',
                  border: 'none',
                  width: 100,
                  maxWidth: 100,
                }}
              >
                Price
              </TableCell>
              <TableCell
                sx={{
                  bgcolor: 'rgb(244, 246, 248)',
                  border: 'none',
                  width: 80,
                  maxWidth: 80,
                }}
              >
                Bed.
              </TableCell>
              <TableCell
                sx={{
                  bgcolor: 'rgb(244, 246, 248)',
                  border: 'none',
                  width: 80,
                  maxWidth: 80,
                }}
              >
                Bath.
              </TableCell>
              <TableCell
                sx={{
                  bgcolor: 'rgb(244, 246, 248)',
                  border: 'none',
                  width: 140,
                  maxWidth: 140,
                }}
              >
                Available From
              </TableCell>
              {loginInfo.role === 'landlord' ? (
                <TableCell
                  sx={{
                    bgcolor: 'rgb(244, 246, 248)',
                    border: 'none',
                    width: 100,
                    maxWidth: 100,
                  }}
                >
                  Status
                </TableCell>
              ) : (
                ''
              )}

              <TableCell
                sx={{
                  bgcolor: 'rgb(244, 246, 248)',
                  border: 'none',
                  width: 50,
                  maxWidth: 50,
                  borderTopRightRadius: '12px',
                  borderBottomRightRadius: '12px',
                }}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {propertyUnits?.map((item, index) => (
              <Row key={index} unitsItem={item} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
  );
};

export default PropertyUnitList;
