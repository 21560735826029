import React from 'react';
import {
  Grid,
  Box,
  Container,
  Typography,
  Stack,
  Divider,
  Avatar,
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
  Button,
  Card,
  CardContent,
  Rating,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../redux/features/authSlice';
import { IMG_URL } from '../../utils/constant';
import profileDummy from '../../assets/images/profileDam.png';
import SvgColor from '../../components/svg-color';
import Identity from '../../assets/icons/Identity.svg';
import Superhost from '../../assets/icons/Superhost.svg';
import { Icon } from '@iconify/react';
import { createRoom } from '../../services/apiServices';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconEmpty': {
    color: '#D3D3DC',
  },
  '& .MuiRating-iconFilled': {
    color: '#FFBD3E',
  },
  '& .MuiRating-iconHover': {
    color: '#FFBD3E',
  },
});
export const PropertyRules = (props) => {
  let { rules, propertyOwner, propertyReview } = props;
  const navigate = useNavigate();
  let { isLoggedin } = useAuthStore();
  let { firstname, lastname } = propertyOwner;

  const handleMessageOpen = async () => {
    if (isLoggedin) {
      const payload = {
        userId: propertyOwner._id,
      };
      const { data } = await createRoom(payload);
      // navigation
      if (data.id) {
        let messageData = {
          userId: propertyOwner._id,
          firstname: propertyOwner.firstname,
          lastname: propertyOwner.lastname,
          profile: propertyOwner.profile,
        };
        navigate(`/message?roomId=${data.id}`, { state: messageData });
      }
    } else {
      navigate('/login');
    }
  };

  return (
    <>
      <Box>
        <Container fixed={true} sx={{ maxWidth: { xl: '1326px' } }}>
          <Divider />
          <Box sx={{ py: 4 }}>
            <Grid container spacing={4}>
              <Grid item md={7} xs={12}>
                <Stack direction="row">
                  <Avatar
                    alt="Remy Sharp"
                    src={
                      propertyOwner.profile
                        ? `${IMG_URL}${propertyOwner.profile}`
                        : profileDummy
                    }
                    sx={{
                      width: { md: 80, xs: 74 },
                      height: { md: 80, xs: 74 },
                    }}
                  />
                  <Box sx={{ ml: 2 }}>
                    <Typography variant="h5" sx={{ fontWeight: '500', mb: 2 }}>
                      Managed by {firstname} {lastname}
                    </Typography>
                    <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: 'grey.500',
                          marginRight: '16px',
                        }}
                      >
                        <Box
                          sx={{
                            background: '#E9EAF0',
                            width: '32px',
                            height: '32px',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '8px',
                          }}
                        >
                          <SvgColor
                            sx={{
                              color: '#82838C',
                              display: 'block',
                              width: '15px',
                              height: '15px',
                            }}
                            src={Identity}
                          />
                        </Box>
                        <Typography variant="body2">
                          Identity verified
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: 'grey.500',
                          mr: 2,
                        }}
                      >
                        <Box
                          sx={{
                            background: '#E9EAF0',
                            width: '32px',
                            height: '32px',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 2,
                          }}
                        >
                          <SvgColor
                            sx={{
                              color: '#82838C',
                              display: 'block',
                              width: '15px',
                              height: '15px',
                            }}
                            src={Superhost}
                          />
                        </Box>
                        <Typography variant="body2">Superhost</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: 'grey.500',
                          gap: '10px',
                        }}
                      >
                        <Typography variant="body2">
                          {propertyReview.length} Reviews
                        </Typography>{' '}
                        <StyledRating
                          readOnly
                          icon={<Icon icon="ph:star-fill" />}
                          emptyIcon={<Icon icon="ph:star-fill" />}
                          sx={{
                            fontSize: {
                              xs: '1rem',
                              md: '1rem',
                              lg: '1.125rem',
                              xl: '1.25rem',
                            },
                          }}
                        />
                      </Box>
                    </Stack>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ pb: 4 }}>
            <Grid container spacing={4}>
              <Grid item md={7} xs={12}>
                <Divider />
                <Box sx={{ pt: 4 }}>
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle1">
                      During your stay
                    </Typography>
                    <Typography variant="body2">
                      This was THE perfect place to enjoy a quiet retreat away
                      from everything - it was magical. The cabin is stunning
                      and beautifully appointed - the furnishings are all
                      natural, wool
                    </Typography>
                    <Typography
                      variant="button"
                      sx={{ mb: 2, textTransform: 'none' }}
                    >
                      <Link to="/" style={{ color: '#323232' }}>
                        Read more
                      </Link>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1">
                      {firstname} {lastname} is a Superhost
                    </Typography>
                    <Typography variant="body2">
                      Superhosts are experienced, highly rated hosts who are
                      committed to providing great stays for guests.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={5} xs={12}>
                <Box sx={{ pt: { md: 4 } }}>
                  <Typography variant="body2" sx={{ mb: 3 }}>
                    Response rate: 100%
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 3 }}>
                    Response time: within an hour
                  </Typography>
                  <Button
                    variant="outlined"
                    size="large"
                    sx={{ minWidth: '290px' }}
                    onClick={handleMessageOpen}
                  >
                    Contact Host
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box sx={{ py: 4 }}>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Things to know
            </Typography>
            <Grid container>
              <Grid item lg={4} sm={6} xs={12}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  House rules
                </Typography>
                <List
                  sx={{
                    display: 'grid',
                    gap: 2,
                  }}
                >
                  {rules.map((item, index) => {
                    return (
                      <ListItem
                        key={index}
                        sx={{ p: 0, alignItems: 'baseline' }}
                      >
                        <ListItemIcon sx={{ top: '2px', position: 'relative' }}>
                          <Icon icon="fa-regular:dot-circle" />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant="body1">{item.rules}</Typography>
                        </ListItemText>
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
              {/* <Grid item lg={3} md={6} xs={12}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  Health & safety
                </Typography>
                <List
                  sx={{
                    display: 'grid',
                    gap: 3,
                  }}
                >
                  <ListItem sx={{ p: 0 }}>
                    <ListItemIcon>
                      <SvgColor src={tree} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="body1">
                        No carbon monoxide alarm
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem sx={{ p: 0 }}>
                    <ListItemIcon>
                      <SvgColor src={car} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="body1">No smoke alarm</Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem sx={{ p: 0 }}>
                    <ListItemText>
                      <Typography
                        variant="button"
                        sx={{ mb: 2, textTransform: 'none' }}
                      >
                        <Link to="/" style={{ color: '#323232' }}>
                          Show more
                        </Link>
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid> */}
              {/* <Grid item xs={4}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  Cancellation policy
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Free cancellation for 48 hours.
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Review the Host’s full cancellation policy which applies even
                  if you cancel for illness or disruptions caused by COVID-19
                </Typography>
                <Typography
                  variant="button"
                  sx={{ mb: 2, textTransform: 'none' }}
                >
                  <Link to="/" style={{ color: '#323232' }}>
                    Show more
                  </Link>
                </Typography>
              </Grid> */}
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};
