import React, { useState, useMemo, useEffect } from 'react';
import MonoConnect from '@mono.co/connect.js';
import {
  Card,
  CardHeader,
  Button,
  Box,
  Stack,
  Grid,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { ConfirmProvider } from 'material-ui-confirm';
import SvgColor from '../../../components/svg-color';
import AddPayment from '../../../assets/icons/AddPayment.svg';
import PaystackLogo from '../../../assets/images/paystack-2.svg';
import MonopaymentsLogo from '../../../assets/images/monopayments-1.svg';
import { CardListTable } from './CardListTable';
import { BankListTable } from './BankListTable';
import { CardWallet } from './CardWallet';
import { usePaystackPayment } from 'react-paystack';
import { TransactionHistory } from './TransactionHistory';
import { tokenizedCard, tokenizedBank } from '../../../services/apiServices';
import { useLocation } from 'react-router-dom';
import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';
import { HeaderBreadcrumbs } from '../../../components/breadcrumbs';

export const Payment = (props) => {
  let { loginInfo } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(null);
  const [spinFlag, setSpinFlag] = useState(false);
  const [message, setMessage] = useState();
  const [renderFlag, setRenderFlag] = useState(false);
  const [statusCode, setStatusCode] = useState();
  const [popupOpen, setPopupOpen] = useState(false);

  const makeTransaction = async (result) => {
    try {
      let { transaction, reference } = result;
      let payload = {
        transactionId: transaction,
        referenceId: reference,
      };
      let { data } = await tokenizedCard(payload);

      if (data.statusCode === 200) {
        setSpinFlag(false);
        setRenderFlag(!renderFlag);
        setMessage(data.message);
        setOpen(true);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  // function of successfull transaction
  const onSuccess = (result) => {
    setSpinFlag(true);
    // Implementation for whatever you want to do with reference and after success call.
    let { status } = result;
    if (status === 'success') {
      makeTransaction(result);
    } else {
      setSpinFlag(false);
      setMessage('Payment Failed');
      setOpen(true);
    }
  };

  // to close paystack popup
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
  };

  //payment code start
  const config = {
    reference: new Date().getTime().toString(),
    email: loginInfo.email,
    currency: 'NGN',
    channels: ['card'],
    amount: 50 * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: 'pk_test_e814770e6d990745f6d9cea879748d30053903be',
  };

  const initializePayment = usePaystackPayment(config);
  const handlePaystackPayment = async () => {
    try {
      initializePayment(onSuccess, onClose);
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const handlePopupOpen = () => {
    setPopupOpen(true);
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  const addBankAccountOnMono = async (code) => {
    try {
      handlePopupClose();
      setSpinFlag(true);
      let payload = { code: code };
      let { data } = await tokenizedBank(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setRenderFlag(!renderFlag);
        setMessage(data.message);
        setOpen(true);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  //link bank account
  const monoConnect = useMemo(() => {
    const monoInstance = new MonoConnect({
      onClose: () => console.log('Widget closed'),
      onLoad: () => console.log('Widget loaded successfully'),
      onSuccess: ({ code }) => {
        console.log('code', code);
        console.log(`Linked successfully: ${code}`);
        if (code) {
          addBankAccountOnMono(code);
        } else {
          setMessage('Failed to add bank account on mono');
          setOpen(true);
        }
      },
      key: 'test_pk_u0se4tef81hlis6gpsv9',
    });

    monoInstance.setup();
    return monoInstance;
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogPopup
        open={open}
        setOpen={setOpen}
        message={message}
        size="xs"
        statusCode={statusCode}
      />
      <HeaderBreadcrumbs heading="Payment" />
      {loginInfo.role === 'renter' ? (
        ''
      ) : (
        <Box sx={{ mb: 4 }}>
          <CardWallet />
        </Box>
      )}
      <Card>
        <CardHeader
          title={loginInfo.role === 'renter' ? 'Card list' : 'Bank list'}
          action={
            <>
              <Box>
                {loginInfo.role === 'renter' ? (
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={() => handlePaystackPayment()}
                    // component={Link}
                    // to="/payment/add-payment-method"
                    sx={{ py: 2, px: 4 }}
                  >
                    <SvgColor
                      sx={{ width: '24px', height: '24px', mr: 1.75 }}
                      src={AddPayment}
                      alt={AddPayment}
                    />{' '}
                    Add new card
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    size="large"
                    component={Link}
                    // onClick={handlePopupOpen}
                    to="/payment/add-bank-accounts"
                    sx={{ py: 2, px: 4 }}
                  >
                    <SvgColor
                      sx={{ width: '24px', height: '24px', mr: 1.75 }}
                      src={AddPayment}
                      alt={AddPayment}
                    />{' '}
                    Add bank account
                  </Button>
                )}
              </Box>
            </>
          }
          sx={{ pb: 3, borderBottom: '1px solid #F1F1F1' }}
        />
        <Box>
          {loginInfo.role === 'renter' ? (
            <ConfirmProvider>
              <CardListTable
                renderFlag={renderFlag}
                setRenderFlag={setRenderFlag}
                setOpen={setOpen}
                setMessage={setMessage}
                setStatusCode={setStatusCode}
                setSpinFlag={setSpinFlag}
                title="Card list"
              />
            </ConfirmProvider>
          ) : (
            <ConfirmProvider>
              <BankListTable
                renderFlag={renderFlag}
                setRenderFlag={setRenderFlag}
                setOpen={setOpen}
                setMessage={setMessage}
                setStatusCode={setStatusCode}
                setSpinFlag={setSpinFlag}
              />
            </ConfirmProvider>
          )}
        </Box>
      </Card>
      <Card sx={{ mt: 4 }}>
        <TransactionHistory title="Transaction history" />
      </Card>

      {/* dialog popup start */}
      <Dialog
        onClose={handlePopupClose}
        aria-labelledby="customized-dialog-title"
        open={popupOpen}
        fullWidth
        maxWidth={'sm'}
      >
        <DialogTitle
          sx={{ textAlign: 'center' }}
          id="customized-dialog-title"
          onClose={handlePopupClose}
        >
          Select Payment Method
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item md={12} xs={12}>
              <Box sx={{ textAlign: 'center' }}>
                <Stack
                  sx={{
                    mb: 3,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    gap: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    size="large"
                    component={Link}
                    to="/payment/add-bank-accounts"
                    sx={{
                      borderRadius: '10px',
                      minWidth: '200px',
                      bgcolor: 'trasparent !important',
                      // color: '#fff !important',
                      borderColor: '#374151 !important',
                    }}
                  >
                    <img src={PaystackLogo} alt="Paystack Logo" />
                  </Button>
                  <Button
                    variant="outlined"
                    size="large"
                    // component={Link}
                    // to="/payment"
                    onClick={() => monoConnect.open()}
                    sx={{
                      borderRadius: '10px',
                      minWidth: '200px',
                      bgcolor: 'trasparent !important',
                      // color: '#fff !important',
                      borderColor: '#374151 !important',
                    }}
                  >
                    <img src={MonopaymentsLogo} alt="Monopayments Logo" />
                  </Button>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {/* dialog popup end */}
    </>

    //diogue popup
  );
};
