import { Grid, Box, Container, Typography, Button, Stack } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import bannerImg from '../../assets/images/bannerImg.png';
import unsplash from '../../assets/images/unsplash.png';
import { Link } from 'react-router-dom';
import { IMG_URL } from '../../utils/constant';
import { useAuthStore } from '../../redux/features/authSlice';

const StylePopular = styled(Box)(({ theme }) => ({
  padding: '50px 0 65px',
  [theme.breakpoints.down('md')]: {
    padding: '50px 0',
  },
}));
const BannerLeftText = styled(Box)(({ theme }) => ({
  padding: '24px',
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
  '& .MuiTypography-h2': {
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
      marginBottom: '20px',
    },
  },
  '& .MuiTypography-h6': {
    [theme.breakpoints.down('xl')]: {
      fontSize: '1.0625rem',
    },
  },
}));
const BannerLeft = styled(Box)(({ theme }) => ({}));
const HostImages = styled(Box)(({ theme }) => ({}));

export const BestDealSection = (props) => {
  let { loginInfo } = useAuthStore();
  let { becomeHostContents, listConfidently } = props;
  return (
    <>
      <StylePopular>
        <Container fixed={true} sx={{ maxWidth: { xl: '1326px' } }}>
          <Box sx={{ background: '#EFEFEF', borderRadius: 2 }}>
            <Grid
              container
              spacing={3}
              sx={{
                alignItems: 'center',
                flexDirection: { md: 'row', xs: 'column-reverse' },
                alignItems: 'end',
              }}
            >
              <Grid item md={6} xs={12}>
                <HostImages>
                  <Box
                    sx={{
                      borderRadius: 2,
                      overflow: 'hidden',
                      width: { md: '50%', xs: '30%' },
                      aspectRatio: '1.08',
                    }}
                  >
                    {listConfidently && listConfidently?.length > 0 && (
                      <>
                        {listConfidently[0]?.bannerImage ? (
                          <img
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              display: 'block',
                            }}
                            src={`${IMG_URL}${listConfidently[0]?.bannerImage}`}
                          />
                        ) : null}
                      </>
                    )}
                  </Box>
                </HostImages>
              </Grid>
              <Grid item md={6} xs={12}>
                <BannerLeft>
                  <BannerLeftText>
                    {listConfidently && listConfidently?.length > 0 && (
                      <>
                        <Typography variant="h2" sx={{ mb: 4 }}>
                          {listConfidently[0]?.title}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: 'normal', color: 'text.secondary' }}
                        >
                          {listConfidently[0]?.description}
                        </Typography>
                      </>
                    )}
                    <Stack
                      direction="row"
                      marginTop="32px"
                      gap="24px"
                      sx={{
                        justifyContent: { md: 'start', xs: 'center' },
                      }}
                    >
                      {loginInfo ? null : (
                        <Button
                          variant="contained"
                          size="large"
                          sx={{ minWidth: '195px' }}
                          component={Link}
                          to="/sign-up"
                        >
                          Sign Up Now
                        </Button>
                      )}
                    </Stack>
                  </BannerLeftText>
                </BannerLeft>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </StylePopular>
    </>
  );
};
