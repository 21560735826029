// ----------------------------------------------------------------------

export default function Checkbox(theme) {
  return {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#FEAE01',
          padding: '0',
          borderRadius: Number(theme.shape.borderRadius) * 1.5,
          border: '1px solid #D0D5DD',
          width: '20px',
          height: '20px',
          '& .MuiSvgIcon-root': {
            display: 'none',
          },
          '&.Mui-checked': {
            color: '#FEAE01',
            border: '1px solid #FEAE01',
            '& .MuiSvgIcon-root': {
              display: 'block',
            },
          },
        },
      },
    },
  };
}
