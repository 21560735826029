import React, { useState, useEffect } from 'react';
import { FaqSection } from '../../pages/home/FaqSection';
import { getHomeContent } from '../../services/apiServices';
import { useLocation, useNavigate } from 'react-router-dom';

export const Faq = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [homeContents, setHomeContents] = useState();
  const [homePageData, setHomePageData] = useState();
  const [faqsList, setFaqsList] = useState([]);
  const homePageContent = async () => {
    try {
      const { data } = await getHomeContent();
      if (data.statusCode === 200) {
        setHomePageData(data.homeData);
        setFaqsList(data.homeData.faqsList);
        setHomeContents(data.homeData.homeContent);
      } else {
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };
  useEffect(() => {
    homePageContent();
  }, []);
  return (
    <>
      <FaqSection
        faqsList={faqsList}
        homePageData={homePageData}
        homeContents={homeContents}
      />
    </>
  );
};
