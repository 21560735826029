import {
  Grid,
  Box,
  Container,
  Typography,
  Button,
  Stack,
  Card,
  CardContent,
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import Intersect from '../../assets/images/Intersect.png';
import { ContactUsForm } from '../../components/contact-us';
import SvgColor from '../../components/svg-color';
import infoIcon1 from '../../assets/icons/mail-01.svg';
import infoIcon2 from '../../assets/icons/message-chat-circle.svg';
import infoIcon3 from '../../assets/icons/phone.svg';
import infoIcon4 from '../../assets/icons/marker-pin-02.svg';
import { IMG_URL } from '../../utils/constant';

const StylePopular = styled(Box)(({ theme }) => ({
  background: `url(${Intersect}) no-repeat top center / 100% 48%`,
  padding: '50px 0',
  [theme.breakpoints.down('lg')]: {
    padding: '56px 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: '40px 0',
  },
}));

const BannerLeftMain = styled(Box)(({ theme }) => ({}));
const BannerLeftText = styled(Box)(({ theme }) => ({
  marginBottom: '20px',
  [theme.breakpoints.down('md')]: {
    marginBottom: '20px',
  },
}));
const BannerLeft = styled(Box)(({ theme }) => ({
  maxWidth: '750px',
  textAlign: 'center',
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
  '& .MuiTypography-h2': {
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
      marginBottom: '20px',
    },
  },
  '& .MuiTypography-h6': {
    [theme.breakpoints.down('xl')]: {
      fontSize: '1.0625rem',
    },
  },
}));
const HostImages = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
}));

const ContactInfo = [
  {
    icon: infoIcon1,
    title: 'Email',
    disc: 'Our friendly team is here to help.',
    info: 'hi@flatt.com',
  },
  {
    icon: infoIcon2,
    title: 'Live chat',
    disc: 'Our friendly team is here to help.',
    info: 'Start new chat',
  },
  {
    icon: infoIcon3,
    title: 'Phone',
    disc: 'Mon-Fri from 8am to 5pm.',
    info: '+1 (555) 000-0000',
  },
  {
    icon: infoIcon4,
    title: 'Office',
    disc: 'Come say hello at our office HQ.',
    info: '100 Smith Street Collingwood VIC 3066 AU',
  },
];

export const ContactUsSection = (props) => {
  let { contactUsList, homeContents, setSpinFlag, contactUs } = props;
  return (
    <>
      <StylePopular>
        <Container fixed={true} sx={{ maxWidth: { xl: '1326px' } }}>
          <BannerLeftMain>
            <BannerLeft>
              <BannerLeftText>
                {contactUs && contactUs?.length > 0 && (
                  <>
                    <Typography
                      variant="button"
                      component="p"
                      sx={{ mb: 2, color: 'secondary.main' }}
                    >
                      {contactUs[0]?.subTitle}
                    </Typography>
                    <Typography variant="h2" sx={{ mb: 4 }}>
                      {contactUs[0]?.title}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 'normal', color: 'text.secondary' }}
                    >
                      {contactUs[0]?.description}
                    </Typography>
                  </>
                )}
              </BannerLeftText>
            </BannerLeft>
          </BannerLeftMain>
          <Grid
            container
            spacing={4}
            sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}
          >
            <Grid item md={6} xs={12}>
              <Grid container spacing={3}>
                {contactUsList.map((value, index) => (
                  <Grid item sm={6} xs={12} key={index}>
                    <Card
                      sx={{
                        boxShadow: 'none',
                        border: '1px solid #EBEBEB',
                        borderRadius: '10px',
                        minHeight: '100%',
                        '&:hover': {
                          boxShadow: '3px 44px 54px rgba(0, 0, 0, 0.08)',
                          borderColor: 'transparent',
                        },
                      }}
                    >
                      <CardContent>
                        <Box>
                          <img
                            src={`${IMG_URL}${value.icon}`}
                            style={{
                              color: 'secondary.main',
                              width: '25px',
                              height: '25px',
                              objectFit: 'contain',
                            }}
                          />
                          <Typography variant="h5" sx={{ mt: 2 }}>
                            {value.heading}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ mt: 1, color: 'text.secondary' }}
                          >
                            {value.subHeading}
                          </Typography>
                          <Typography variant="subtitle1" sx={{ mt: 2 }}>
                            {value.info}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <ContactUsForm setSpinFlag={setSpinFlag} />
            </Grid>
          </Grid>
        </Container>
      </StylePopular>
    </>
  );
};
