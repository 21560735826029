import { React, useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useAuthStore } from '../../redux/features/authSlice';
import { setToken } from '../../utils/axiosClient';
import '../../admin/assets/scss/themes.scss';

import Header from '../../admin/Layouts/Header';
import Footer from '../../admin/Layouts/Footer';
import Sidebar from '../../admin/Layouts/Sidebar';

const Main = styled('section')(({ theme }) => ({
  minHeight: 'calc(100svh - 100px)',
  [theme.breakpoints.down('md')]: {
    minHeight: 'auto',
  },
}));

export const AdminLayout = () => {
  let { loginInfo, loginAdminInfo } = useAuthStore();
  setToken(loginAdminInfo ? loginAdminInfo.token : null);
  return (
    <>
      <Outlet />
    </>
  );
};

export const AdminDashboardLayout = () => {
  const [headerClass, setHeaderClass] = useState('');
  useEffect(() => {
    document.documentElement.setAttribute('data-layout-style', 'default');
    document.documentElement.setAttribute('data-sidebar', 'light');
    document.documentElement.setAttribute('data-layout-mode', 'light');
    document.documentElement.setAttribute('data-layout-width', 'fluid');
    document.documentElement.setAttribute('data-layout-position', 'fixed');
    document.documentElement.setAttribute('data-layout', 'vertical');
    document.documentElement.setAttribute('data-sidebar-image', 'none');
    document.documentElement.setAttribute('data-sidebar-size', 'lg');
    window.addEventListener('scroll', scrollNavigation, true);
  }, []);
  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setHeaderClass('topbar-shadow');
    } else {
      setHeaderClass('');
    }
  }
  return (
    <>
      <div id="layout-wrapper">
        <Header headerClass={headerClass} />
        <Sidebar />
        <div className="main-content">
          <Outlet />
          <Footer />
        </div>
      </div>
    </>
  );
};
