// ERR MSG FOR PROPERTY MODULE
export const PROPERTY_MSG = {
  WRONG: 'Something went wrong',
  TITLE_EMPTY: 'Title cannot be empty',
  PROPERTY_TYPE_EMPTY: 'Property type cannot be empty',
  DESCRIPTION_EMPTY: 'Description cannot be empty',
  DESCRIPTION_TYPE_EMPTY: 'Property type cannot be empty',
  ADDRESS_EMPTY: 'Address cannot be empty',
  CITY_EMPTY: 'City cannot be empty',
  COUNTRY_EMPTY: 'Country cannot be empty',
  ZIP_EMPTY: 'Zip code cannot be empty',
  AMENITIES_EMPTY: 'Amenities cannot be empty',
  RULES_EMPTY: 'Rules cannot be empty',
  UNIT_NAME_EMPTY: 'Unit name cannot be empty',
  PRICE_EMPTY: 'Price cannot be empty',
  DURATION_NAME_EMPTY: 'Duration cannot be empty',
  BEDROOM_EMPTY: 'Bedrooms cannot be empty',
  BATHROOM_EMPTY: 'Bathrooms cannot be empty',
  AVAILABLE_FROM_EMPTY: 'Available date cannot be empty',
  // CANNOT_EMPTY: 'REQUIRED',
  FROM_DATE_EMPTY: 'From date cannot be empty',
  TO_DATE_EMPTY: 'To date cannot be empty',
  GUEST_EMPTY: 'Guest cannot be empty',
  UNIT_EMPTY: 'Unit cannot be empty',
  NAME_EMPTY: 'Name cannot be empty',
  JOBTITLE_EMPTY: 'Job title cannot be empty',
  COMPANY_NAME_EMPTY: 'Company name cannot be empty',
  YEARLY_INCOME_EMPTY: 'Yearly income cannot be empty',
  RENT_CALCULATION_EMPTY: 'Rent calculation cannot be empty',
  RENTABILITY_SCORE_EMPTY: 'Rentability score cannot be empty',
  VISIT_DATE_EMPTY: 'Visit date cannot be empty',
  MESSAGE_EMPTY: 'Message cannot be empty',

  VALID_TITLE: 'Please enter valid title',
  VALID_ZIP: 'Please Enter valid zip code',
  VALID_BEDROOM: 'Please enter valid bedrooms',
  VALID_BATHROOM: 'Please enter valid bathrooms',
  VALID_PROPERTY_IMG: 'Select at least 1 property image',
  VALID_GUEST: 'Please enter valid no. of guest',
  MAX_TITLE: 'Must be 30 character or less',
  MIN_TITLE: 'Must contain 2 character alteast',
  VALID_PRICE: 'Please enter valid price',
  VALID_DESCRIPTION: 'Please enter valid description',
  VALID_MESSAGE: 'Please enter valid message',
  DESCRIPTION_SHORT: 'Description is too short',
  DESCRIPTION_LONG: 'Description is too long',
  MESSAGE_LONG: 'Message is too long',
  MESSAGE_SHORT: 'Message is too short',
  TITLE_TOO_LONG: 'Title is too long',
  TITLE_TOO_SHORT: 'Title is too short',
  MAX_NAME: 'Must be 20 characters or less',
  TOO_SHORT: 'Too short',
  TOO_LONG: 'Too long',
  // SELECT_PRICE:""
};

// ERR MSG FOR AUTH MODULE
export const AUTH_MSG = {
  VALID_PASSWORD:
    'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
  FIRST_NAME_EMPTY: 'First name cannot be empty',
  LAST_NAME_EMPTY: 'Last name cannot be empty',
  UNIT_EMPTY: 'Unit cannot be empty',
  DOB_EMPTY: 'Date of birth cannot be empty',
  MOBILE_EMPTY: 'Mobile cannot be empty',
  EMAIL_EMPTY: 'Email cannot be empty',
  PASSWORD_EMPTY: 'Password cannot be empty',
  CONFIRM_PASSWORD_EMPTY: 'Confirm password cannot be empty',
  MESSAGE_EMPTY: 'Message cannot be empty',
  VALID_NAME: 'Enter valid name',
  MAX_NAME: 'Must be 20 characters or less',
  VALID_EMAIL: 'Enter valid email',
  VALID_PHONE: 'Enter valid phone number',
  VALID_GUEST: 'Enter valid value in guest field',
  TOO_SHORT: 'Too short',
  TOO_LONG: 'Too long',
  VALID_DESCRIPTION: 'Enter valid property description',
  SAME_PASSWORD: 'Password must be same',
  VALID_UNIT: 'Please enter valid unit',
  CHECK_PRIVACY_POLICY: 'You need to accept privacy policy',
  CHECH_DATE_FUTURE: 'Date of birth cannot be in the future',
  FIFTEEN_YEARS_AGO: 'Date of birth must be at least 15 years ago',
  DOB: 'Date of birth is required',
  FIRST_NAME: 'First name is required.',
  LAST_NAME: 'Last name is required.',
  FIRST_NAME: 'First name is required.',
  PHONE_NUMBER: 'Phone number is required.',
  CONFIRM_PASWORD: 'Confirm password is required',
  PASWORD: 'Password is required',
  EMAIL: 'Email is required',
};

export const PAYMENT_MSG = {
  VALID_HOLDER_NAME: 'Please enter valid card holder name',
  VALID_ACCOUNT_NAME: 'Please enter valid account name',
  VALID_CVV: 'Please enter valid cvv',
  VALID_EXPIRATION_DATE: 'Please enter valid expiration date',
  VALID_CARD_NUMBER: 'Please enter valid card number',
  // CANNOT_EMPTY: 'This field cannot be empty.',
  ACCOUNT_NAME_EMPTY: 'Account name cannot be empty.',
  BANK_NAME_EMPTY: 'Bank name cannot be empty.',
  ACCOUNT_TYPE_EMPTY: 'Account type cannot be empty.',
  ACCOUNT_NUMBER_EMPTY: 'Account number cannot be empty.',
  MAX_NAME: 'Must be 20 characters or less',
  VALID_ACCOUNT_NUMBER: 'Please enter valid account number',
  MAX_MOBILE: 'Must less then 15',
  MIN_MOBILE: 'Must greater then 7',
};
