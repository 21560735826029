import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
//import logo
import logoSm from '../assets/images/logo-sm.png';
import logoDark from '../assets/images/logo-dark.png';
import logoLight from '../assets/images/logo-light.png';
import { LogoSection } from '../../components/logo';

//Import Components
import { Container } from 'reactstrap';
import VerticalLayout from './VerticalLayouts';

const Sidebar = ({}) => {
  useEffect(() => {
    var verticalOverlay = document.getElementsByClassName('vertical-overlay');
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener('click', function () {
        document.body.classList.remove('vertical-sidebar-enable');
      });
    }

    // var linksClose = document.querySelectorAll(
    //   "a:not([data-bs-toggle='collapse'])"
    // );
    // linksClose.forEach(function (link) {
    //   console.log(link.getAttribute('href'));
    //   link.addEventListener('click', function (e) {
    //     e.preventDefault();
    //     document.body.classList.remove('vertical-sidebar-enable');
    //   });
    // });
  });

  const addEventListenerOnSmHoverMenu = () => {
    // add listener Sidebar Hover icon on change layout from setting
    if (
      document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover'
    ) {
      document.documentElement.setAttribute(
        'data-sidebar-size',
        'sm-hover-active'
      );
    } else if (
      document.documentElement.getAttribute('data-sidebar-size') ===
      'sm-hover-active'
    ) {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
    } else {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
    }
  };

  return (
    <React.Fragment>
      <div className="app-menu navbar-menu">
        <div className="navbar-brand-box">
          <span className="logo logo-dark">
            {/* <span className="logo-sm">
              <img src={logoSm} alt="" height="22" />
            </span>*/}
            <span className="logo-lg p-4">
              {/* <img src={logoDark} alt="" height="70" /> */}
              <LogoSection linkUrl={'/admin/dashboard'} />
            </span>
          </span>
          <button
            onClick={addEventListenerOnSmHoverMenu}
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </button>
        </div>
        <SimpleBar id="scrollbar" className="h-100">
          <Container fluid>
            <div id="two-column-menu"></div>
            <ul className="navbar-nav" id="navbar-nav">
              <VerticalLayout layoutType={'vertical'} />
            </ul>
          </Container>
        </SimpleBar>
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  );
};

export default Sidebar;
