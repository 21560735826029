import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Stack,
  Grid,
  Box,
  Container,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  FormControl,
} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik, Form, FormikProvider, ErrorMessage } from 'formik';
import { Icon } from '@iconify/react';
import { useAuthStore } from '../../../redux/features/authSlice';
import { requestTourSchema } from '../../../utils/validation/Property';
import { createRoom } from '../../../services/apiServices';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

const RequestTour = (props) => {
  let { popupTour, setPopupTour, propertyOwner } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onDataSubmit = async (values) => {
    try {
      const payload = {
        userId: propertyOwner._id,
      };
      const { data } = await createRoom(payload);

      if (data.id) {
        let messageData = {
          userId: propertyOwner._id,
          firstname: propertyOwner.firstname,
          lastname: propertyOwner.lastname,
          profile: propertyOwner.profile,
          visitDate: values.visitDate,
          visitMessage: values.message,
        };

        navigate(`/message?roomId=${data.id}`, { state: messageData });
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      visitDate: '',
      message: 'Hello, I want to visit your property.',
    },
    validationSchema: requestTourSchema,
    onSubmit: async (values) => {
      onDataSubmit(values);
    },
  });
  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange,
    handleBlur,
    values,
  } = formik;

  const handleavailableDate = (e) => {
    let date = new Date(e.$d),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    let nDate = [date.getFullYear(), mnth, day].join('-');
    return nDate;
  };
  return (
    <>
      <Dialog
        open={popupTour}
        onClose={() => setPopupTour(false)}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="sm"
        sx={{
          '& .MuiPaper-root': {
            width: '100%',
          },
        }}
      >
        <DialogTitle id="scroll-dialog-title">
          Request Tour
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setPopupTour(false)}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Icon icon="iconamoon:close-light" />
          </IconButton>
        </DialogTitle>
        <DialogContent id="scroll-dialog-description">
          <Box sx={{ pt: 2 }}>
            <>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          sx={{
                            p: 0,
                            '& .MuiFormControl-root': { width: '100%' },
                          }}
                          components={['DatePicker']}
                        >
                          <DatePicker
                            label="Enter visit date"
                            format="YYYY-MM-DD"
                            name="visitDate"
                            disablePast={true}
                            value={
                              values.visitDate ? dayjs(values.visitDate) : null
                            }
                            onChange={(e) => {
                              const nDate = handleavailableDate(e);
                              setFieldValue('visitDate', nDate);
                            }}
                            onBlur={handleBlur}
                            // defaultValue={dayjs(new Date())}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      <ErrorMessage
                        style={{ color: 'red' }}
                        component="span"
                        name="visitDate"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        rows="3"
                        type="Text"
                        label="Enter a message"
                        placeholder="Enter a message"
                        value={values.message}
                        name="message"
                        id="message"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        style={{ color: 'red' }}
                        component="span"
                        name="message"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" gap={2} justifyContent="end">
                        <Button
                          type="submit"
                          variant="contained"
                          size="large"
                          sx={{ px: 5 }}
                        >
                          Send Request
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default RequestTour;
