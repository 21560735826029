import { createSlice } from '@reduxjs/toolkit';

export const notifySlice = createSlice({
  name: 'notify',
  initialState: false,
  reducers: {
    updateNotification: (state) => !state,
  },
});

export const { updateNotification } = notifySlice.actions;

export default notifySlice.reducer;
