import React, { useState, useRef } from 'react';
import { Grid, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PropertyCard } from '../../components/property-card';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import Scrollbar from '../../components/scrollbar';
import mapMark from '../../assets/icons/mapMark.svg';
import mapMarkFill from '../../assets/icons/mapMarkFill.svg';
import { PropertyCardMap } from '../../components/property-cardMap';
import { GOOGLE_MAP_KEY } from '../../utils/constant';
import { useResponsive } from '../../hooks/useResponsive';
import Slider from 'react-slick';
import { DataNotAvailable } from '../../components/data-not-found';

const PropertyCardBox = styled(Box)({
  maxWidth: '380px',
});
const CarouselDiv = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.down('sm')]: {},
  '& .slick-slider': {
    '& .slick-list': {
      width: '100%',
    },
  },
}));
const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
const PropertyMap = (props) => {
  const isMobileMode = useResponsive('down', 'lg');
  // propertyUnits
  let { propertiesData, lat, lng, totalProperty } = props;
  const [isHovered, setIsHovered] = useState();
  const [propertyItem, setPropertyItem] = useState();
  const [activeMarker, setActiveMarker] = useState();
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);
  const [hoveredCardId, setHoveredCardId] = useState('');
  const mapRef = useRef(null);

  const handleMarkerClick = (item, marker) => {
    setActiveMarker(marker);
    setIsInfoWindowOpen(true);
    setPropertyItem(item);
    mapRef.current.map.panTo(marker.getPosition());
    mapRef.current.map.setZoom(4);
  };

  const handleInfoWindowClose = () => {
    setActiveMarker();
    setIsInfoWindowOpen(false);
  };

  const defaultMarkerIcon = {
    url: mapMark,
    scaledSize: new google.maps.Size(50, 50),
  };

  const hoverMarkerIcon = {
    url: mapMarkFill,
    scaledSize: new google.maps.Size(50, 50),
  };
  const handleMarkerMouseOver = (index) => {
    setIsHovered(index);
  };

  const handleMarkerMouseOut = () => {
    setIsHovered(false);
  };

  const setCardMarkerHover = (location, index) => {
    setHoveredCardId(location);
    setIsHovered(location);
  };

  const resetCardMarkerHover = () => {
    setHoveredCardId('');
    setIsHovered(false);
  };
  return (
    <>
      <Grid container spacing={3}>
        {propertiesData.length === 0 ? (
          <Grid item xs={4}>
            <DataNotAvailable title="You don't have Property" />
          </Grid>
        ) : (
          <>
            {lat && lng ? (
              <Grid item lg={4} xs={12}>
                {isMobileMode ? (
                  <CarouselDiv>
                    <Slider {...settings}>
                      {propertiesData.map((val, index) => (
                        <Box
                          key={index}
                          sx={{ mb: 3 }}
                          // onMouseEnter={(e) => setCardMarkerHover(location)}
                          onMouseLeave={(e) => resetCardMarkerHover()}
                        >
                          <PropertyCard
                            propertyValues={val}
                            ratingValue={3}
                            sx={{ border: '1px solid #EBEBEB' }}
                          />
                        </Box>
                      ))}
                    </Slider>
                  </CarouselDiv>
                ) : (
                  <Box
                    sx={{
                      height: { lg: '900px', md: '800px', xs: 'auto' },
                    }}
                  >
                    <Scrollbar>
                      {propertiesData.map((val, index) => (
                        <Box
                          key={index}
                          sx={{ mb: 3 }}
                          // onMouseEnter={(e) => setCardMarkerHover(location)}
                          onMouseLeave={(e) => resetCardMarkerHover()}
                        >
                          <PropertyCard
                            propertyValues={val}
                            ratingValue={3}
                            sx={{ border: '1px solid #EBEBEB' }}
                          />
                        </Box>
                      ))}
                    </Scrollbar>
                  </Box>
                )}
              </Grid>
            ) : (
              ''
            )}
          </>
        )}

        <Grid
          item
          lg={propertiesData.length === 0 ? 8 : lat && lng ? 8 : 12}
          xs={12}
        >
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: { lg: '900px', md: '800px', xs: '600px' },
              '& .gm-style .gm-style-iw-c': {
                borderRadius: '14px',
              },
              '& .gm-style-iw .gm-ui-hover-effect': {
                left: '28px !important',
                right: 'auto !important',
                top: '28px !important',
                background: '#fff !important',
                borderRadius: '50%',
              },
            }}
          >
            <Map
              google={props.google}
              zoom={2}
              ref={mapRef}
              onClick={handleInfoWindowClose}
              center={{
                lat: lat || 20.5937,
                lng: lng || 78.9629,
              }}
            >
              {propertiesData.map((item, index) => {
                return (
                  <Marker
                    name={`marker${index}`}
                    key={index}
                    onClick={(m, marker) => handleMarkerClick(item, marker)}
                    position={{
                      lat: item.latitude,
                      lng: item.longitude,
                    }}
                    icon={
                      isHovered === index ? hoverMarkerIcon : defaultMarkerIcon
                    }
                    onMouseover={() => handleMarkerMouseOver(index)}
                    onMouseout={handleMarkerMouseOut}
                    hoveredCardId={hoveredCardId}
                  ></Marker>
                );
              })}
              <InfoWindow
                marker={activeMarker}
                visible={isInfoWindowOpen}
                onClose={handleInfoWindowClose}
                options={{ maxWidth: 380 }}
              >
                <PropertyCardBox>
                  {propertyItem ? (
                    <PropertyCardMap
                      propertyValues={propertyItem}
                      ratingValue={3}
                      sx={{ border: '1px solid #EBEBEB' }}
                    />
                  ) : (
                    ''
                  )}
                </PropertyCardBox>
              </InfoWindow>
            </Map>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_KEY,
})(PropertyMap);
