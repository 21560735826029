import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Button,
  Stack,
  Grid,
  Box,
  Container,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  List,
  ListItem,
  Divider,
} from '@mui/material';
import { Icon } from '@iconify/react';
import SvgColor from '../../../components/svg-color';
import avatar_11 from '../../../assets/images/avatars/avatar_11.jpg';
import message_square from '../../../assets/icons/message-square-02.svg';
import { currencySeprator } from '../../../utils/common-function';
import { BASEURL, IMG_URL, nairaSymbol } from '../../../utils/constant';
import { io } from 'socket.io-client';
import { createRoom } from '../../../services/apiServices';
import { useNavigate } from 'react-router-dom';

export const BookingRequestPopup = (props) => {
  const navigate = useNavigate();
  let { viewDetails, handleRequest, popupOpen, setPopupOpen, loginInfo } =
    props;

  let {
    companyName,
    duration,
    createdAt,
    fromDate,
    toDate,
    guest,
    jobTitle,
    name,
    rentCalculation,
    rentablityScore,
    yearlyIncome,
    status,
    renterId,
    additionalDetails,
    renterData,
    unitData,
  } = viewDetails;

  const handleMessageOpen = async (renter) => {
    console.log('handleMessageOpen called');
    const payload = {
      userId: renter[0]._id,
    };
    const { data } = await createRoom(payload);
    // navigation
    if (data.id) {
      let messageData = {
        userId: renter[0]._id,
        firstname: renter[0].firstname,
        lastname: renter[0].lastname,
        profile: renter[0].profile,
      };

      navigate(`/message?roomId=${data.id}`, { state: messageData });
    }
  };

  return (
    <>
      <Dialog
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="sm"
        sx={{
          '& .MuiPaper-root': {
            width: '100%',
          },
        }}
      >
        <DialogTitle id="scroll-dialog-title">
          Booking request details
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setPopupOpen(false)}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Icon icon="iconamoon:close-light" />
          </IconButton>
        </DialogTitle>
        <DialogContent id="scroll-dialog-description">
          <Box sx={{ pt: 2 }}>
            <Box>
              <Stack flexDirection="row" alignItems="flex-start" sx={{ mb: 4 }}>
                <Avatar
                  src={`${IMG_URL}${renterData[0].profile}`}
                  sx={{
                    width: '48px',
                    height: '48px',
                    textTransform: 'capitalize',
                  }}
                  alt={renterData[0].firstname}
                />
                <Box sx={{ ml: 2, flexGrow: 1, textTransform: 'capitalize' }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: 'text.primary' }}
                  >
                    {renterData[0].firstname} {renterData[0].lastname}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {jobTitle}
                  </Typography>
                </Box>
                {loginInfo.role !== 'renter' && (
                  <Button
                    onClick={() => handleMessageOpen(renterData)}
                    color="success"
                    variant="outlined"
                    size="small"
                    sx={{
                      px: 1,
                      py: 1,
                      width: { md: '48px', xs: '35px' },
                      height: { md: '48px', xs: '35px' },
                      minWidth: 'auto',
                    }}
                    // disabled={status === 'paid' ? false : true}
                  >
                    <SvgColor
                      sx={{ width: 18, height: 18 }}
                      src={message_square}
                    />
                  </Button>
                )}
              </Stack>
            </Box>
            <Box>
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <Box>
                    <Typography
                      variant="caption"
                      sx={{ color: 'text.secondary' }}
                      component="p"
                    >
                      Unit name/no.
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: 'text.primary' }}
                      component="p"
                    >
                      {unitData[0].unitName}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box>
                    <Typography
                      variant="caption"
                      sx={{ color: 'text.secondary' }}
                      component="p"
                    >
                      Company name
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: 'text.primary' }}
                      component="p"
                    >
                      {companyName}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box>
                    <Typography
                      variant="caption"
                      sx={{ color: 'text.secondary' }}
                      component="p"
                    >
                      Yearly income
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: 'text.primary' }}
                      component="p"
                    >
                      {nairaSymbol}
                      {currencySeprator(yearlyIncome)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box>
                    <Typography
                      variant="caption"
                      sx={{ color: 'text.secondary' }}
                      component="p"
                    >
                      Income to rent calculation
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: 'text.primary' }}
                      component="p"
                    >
                      {nairaSymbol}
                      {currencySeprator(rentCalculation)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box>
                    <Typography
                      variant="caption"
                      sx={{ color: 'text.secondary' }}
                      component="p"
                    >
                      Number of people occupying the space
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: 'text.primary' }}
                      component="p"
                    >
                      {guest}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box>
                    <Typography
                      variant="caption"
                      sx={{ color: 'text.secondary' }}
                      component="p"
                    >
                      Rentablity score
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: 'text.primary' }}
                      component="p"
                    >
                      {rentablityScore}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box>
                    <Typography
                      variant="caption"
                      sx={{ color: 'text.secondary' }}
                      component="p"
                    >
                      Move-in date
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: 'text.primary' }}
                      component="p"
                    >
                      {fromDate.slice(0, 10)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box>
                    <Typography
                      variant="caption"
                      sx={{ color: 'text.secondary' }}
                      component="p"
                    >
                      Move-out date
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: 'text.primary' }}
                      component="p"
                    >
                      {toDate.slice(0, 10)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box>
                    <Typography
                      variant="caption"
                      sx={{ color: 'text.secondary' }}
                      component="p"
                    >
                      Request date
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: 'text.primary' }}
                      component="p"
                    >
                      {createdAt.slice(0, 10)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              {additionalDetails && (
                <Grid container spacing={3}>
                  <Grid item sm={12} xs={12}>
                    <Divider sx={{ borderStyle: 'dashed', my: 3 }} />
                    <Box>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: 'text.primary', mb: 1 }}
                        component="p"
                      >
                        Additional details
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: 'text.primary' }}
                        component="p"
                      >
                        {additionalDetails}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ py: 2, px: 3 }}>
          {handleRequest ? (
            <>
              {status === 'pending' ? (
                <>
                  <Button
                    variant="contained"
                    onClick={() => handleRequest(viewDetails._id, 'accept')}
                  >
                    Accept
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ ml: 1 }}
                    onClick={() => handleRequest(viewDetails._id, 'reject')}
                  >
                    Reject
                  </Button>
                </>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
