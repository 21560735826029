import { Box, Container, Typography, Button, Stack } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import PropertySearchForm from '../../components/property-search';
// import { PropertyFilter } from './PropertyFilter';
import Filter from '../../assets/icons/Filter.svg';
import SvgColor from '../../components/svg-color';

const BannerMain = styled(Box)(({ theme }) => ({
  padding: '75px 0 120px',
  [theme.breakpoints.down('lg')]: {
    padding: '56px 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: '40px 0',
  },
}));
const BannerTop = styled(Box)(({ theme }) => ({
  maxWidth: '860px',
  margin: '0 auto',
  padding: '40px 0 20px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: '100%',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    padding: '0',
  },
}));
const BannerLeftText = styled(Box)(({ theme }) => ({
  paddingBottom: '24px',
  [theme.breakpoints.down('lg')]: {},
  '& .MuiTypography-h2': {
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
      marginBottom: '20px',
    },
  },
  '& .MuiTypography-h6': {
    [theme.breakpoints.down('xl')]: {
      fontSize: '1.0625rem',
    },
  },
}));
const BannerRight = styled(Box)(({ theme }) => ({
  '& img': {
    display: 'block',
    width: '100%',
    aspectRatio: '1/0.95',
  },
}));

export const BannerSection = (props) => {
  let { resultRef, bannerSection, handleClickOpen } = props;
  return (
    <>
      <BannerMain>
        <Container fixed={true} sx={{ maxWidth: { xl: '1326px' } }}>
          <BannerTop>
            <BannerLeftText>
              {bannerSection && bannerSection.length > 0 && (
                <>
                  <Typography
                    variant="button"
                    component="p"
                    sx={{ mb: 2, color: 'secondary.main' }}
                  >
                    {bannerSection[0]?.subTitle}
                  </Typography>
                  <Typography variant="h2" sx={{ mb: 4 }}>
                    {/* Find{' '}
                    <Typography component="span" variant="h2">
                      available
                    </Typography>{' '}
                    spaces for living in favourite places */}
                    {bannerSection[0]?.title}
                  </Typography>
                </>
              )}
            </BannerLeftText>
            <Stack
              flexDirection="row"
              gap="14px"
              sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
            >
              <Box
                sx={{
                  width: {
                    md: 'calc(100% - 0px)',
                    sm: 'calc(100% - 0px)',
                    xs: '100%',
                  },
                }}
              >
                <PropertySearchForm
                  resultRef={resultRef}
                  handleClickOpen={handleClickOpen}
                />
              </Box>
              {/* <Button
                variant="outlined"
                color="primary"
                sx={{
                  width: { md: '160px', sm: '65px', xs: '100%' },
                  height: '54px',
                }}
                onClick={handleClickOpen}
              >
                <SvgColor
                  sx={{
                    mr: { xs: 1.5, sm: 0, md: 1.5 },
                    width: '18px',
                    height: '18px',
                  }}
                  src={Filter}
                />
                <Typography
                  component="span"
                  sx={{
                    display: {
                      xs: 'inline-block',
                      sm: 'none',
                      md: 'inline-block',
                    },
                  }}
                >
                  Filter spaces
                </Typography>
              </Button> */}
            </Stack>
          </BannerTop>
        </Container>
      </BannerMain>
    </>
  );
};
