import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Scrollbar from '../../../components/scrollbar';
import {
  Card,
  Button,
  Box,
  CardHeader,
  CardContent,
  Grid,
} from '@mui/material';
import { UnitCard } from '../../../components/unit-card';
import { favoriteList } from '../../../services/apiServices';
import { DataNotAvailable } from '../../../components/data-not-found';
import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';
import Slider from 'react-slick';
import { useResponsive } from '../../../hooks/useResponsive';

export const FavouritePlace = (props) => {
  let { setMyListRender, myListRender, title, setSpinFlag } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // const [spinFlag, setSpinFlag] = useState();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [favList, setFavList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusCode, setStatusCode] = useState();
  const isMobileMode = useResponsive('down', 'sm');

  useEffect(() => {
    getFavList();
  }, [myListRender]);

  const getFavList = async () => {
    try {
      setSpinFlag(true);
      let payload = { currentPage: currentPage };
      let { data } = await favoriteList(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setFavList(data.favList);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
  };
  return (
    <>
      <DialogPopup
        open={open}
        setOpen={setOpen}
        message={message}
        size="xs"
        statusCode={statusCode}
      />
      <Card>
        <CardHeader
          title={title}
          action={
            <Button
              size="small"
              component={Link}
              to="/dashboard/favourite-place"
              color="primary"
            >
              See all
            </Button>
          }
          sx={{ pb: 2 }}
        />

        {!isMobileMode ? (
          <Scrollbar style={{ height: '295px' }}>
            <CardContent sx={{ py: 0 }}>
              <Grid container spacing={2}>
                {favList.length !== 0 ? (
                  favList.map((item, index) => {
                    return (
                      <Grid item md={12} xs={12} key={index}>
                        <UnitCard
                          key={index}
                          favHistoryData={item}
                          setRenderFlag={setMyListRender}
                          renderFlag={myListRender}
                        />
                      </Grid>
                    );
                  })
                ) : (
                  <Grid item md={12} xs={12}>
                    <DataNotAvailable
                      sx={{ p: 2, minHeight: 270 }}
                      title={"You don't have favorite property"}
                    />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Scrollbar>
        ) : (
          <CardContent sx={{ py: 0 }}>
            <Box sx={{ pb: 2 }}>
              {favList.length !== 0 ? (
                <Slider {...settings}>
                  {favList.map((item, index) => {
                    return (
                      <Box
                        sx={{
                          display: 'block !important',
                          m: '0 auto',
                        }}
                      >
                        <UnitCard
                          key={index}
                          favHistoryData={item}
                          setRenderFlag={setMyListRender}
                          renderFlag={myListRender}
                        />
                      </Box>
                    );
                  })}
                </Slider>
              ) : (
                <Box>
                  <DataNotAvailable
                    sx={{ p: 2, minHeight: 270 }}
                    title={"You don't have favorite property"}
                  />
                </Box>
              )}
            </Box>
          </CardContent>
        )}
      </Card>
    </>
  );
};
