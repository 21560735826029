import React, { useState, useRef } from 'react';
import {
  Button,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { styled, alpha } from '@mui/material/styles';
const options = ['Sort by: A-Z', 'Sort by: Z-A'];
export const StyledItemButton = styled((props) => <MenuItem {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    position: 'relative',
    textTransform: 'capitalize',
    borderRadius: '6px',
  })
);
export const PropertiesListSort = ({ handleSort }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    index === 1 ? handleSort('desc') : handleSort('asc');
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <Button
        sx={{
          width: { lg: '38%', sm: '45%', xs: '100%' },
          border: '1px solid #EDEDED',
          minHeight: '48px',
        }}
        ref={anchorRef}
        aria-label="split button"
        onClick={handleToggle}
      >
        <Icon style={{ marginRight: '10px' }} icon="ion:filter" />
        {options[selectedIndex]}
        <Icon style={{ marginLeft: 'auto' }} icon="akar-icons:chevron-down" />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        sx={{
          width: '250px',
          background: '#fff',
          p: 0.5,
          boxShadow: (theme) => theme.customShadows.dropdown,
          borderRadius: 2.5,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <StyledItemButton
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </StyledItemButton>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
