import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Stack,
  Grid,
  Box,
  Container,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  FormHelperText,
  InputAdornment,
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import { useAuthStore } from '../../../redux/features/authSlice';
import { applyListingSchema } from '../../../utils/validation/Property';
import { useLocation, useNavigate } from 'react-router-dom';
import { bookProperty } from '../../../services/apiServices';
import { REGEX } from '../../../utils/regex';
import {
  currencySeprator,
  getNumberWithoutComma,
} from '../../../utils/common-function';
import CurrencyFormat from 'react-currency-format';

const ApplyListing = (props) => {
  let {
    applyValues,
    popupOpen,
    setSpinFlag,
    setMessage,
    setOpen,
    setPopupOpen,
    rentCalculation,
    handlePopupClose,
    setStatusCode,
  } = props;

  let { loginInfo, updateLoginInfo } = useAuthStore();
  const pathname = useLocation().pathname;
  const navigate = useNavigate();

  let {
    fromDate,
    toDate,
    guest,
    landlordId,
    propertyId,
    landlordEmail,
    duration,
    unit,
  } = applyValues;

  const [data, setData] = useState({
    name: loginInfo.firstname,
    jobTitle: loginInfo.jobTitle,
    companyName: loginInfo.companyName,
    yearlyIncome: loginInfo?.yearlyIncome,
    rentCalculation: currencySeprator(rentCalculation),
    rentablityScore: '',
    additionalDetails: '',
  });
  const onDataSubmit = async (values) => {
    values.yearlyIncome = getNumberWithoutComma(values.yearlyIncome);
    values.rentCalculation = getNumberWithoutComma(rentCalculation);

    let payload = {
      ...values,
      fromDate: fromDate,
      guest: guest,
      toDate: toDate,
      unitId: unit,
      landlordId: landlordId,
      propertyId: propertyId,
      landlordEmail: landlordEmail,
      duration: duration,
    };

    try {
      setSpinFlag(true);
      const { data } = await bookProperty(payload);

      if (data.statusCode === 200) {
        handlePopupClose(1);
        let result = data.userData;
        result.token = data.token;
        updateLoginInfo(result);
        setSpinFlag(false);
        setMessage(data.message);
        setStatusCode(data.statusCode);
        setOpen(true);

        // setPopupOpen(false);
      } else {
        setStatusCode(data.statusCode);
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: applyListingSchema,
    onSubmit: async (values) => {
      onDataSubmit(values);
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
    handleChange,
    handleBlur,
  } = formik;

  return (
    <>
      <Dialog
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          Apply for listing
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setPopupOpen(false)}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Icon icon="iconamoon:close-light" />
          </IconButton>
        </DialogTitle>
        <DialogContent id="scroll-dialog-description">
          <Box sx={{ pt: 2 }}>
            <Container fixed={true} maxWidth="lg">
              <Box>
                <>
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            type="text"
                            label="Full Name"
                            name="name"
                            {...getFieldProps('name')}
                            // error={Boolean(touched.name && errors.name)}
                            // helperText={touched.name && errors.name}
                          />
                          {touched.name && errors.name && (
                            <FormHelperText error>{errors.name}</FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            type="text"
                            label="Job Title"
                            name="jobTitle"
                            {...getFieldProps('jobTitle')}
                            // error={Boolean(touched.jobTitle && errors.jobTitle)}
                            // helperText={touched.jobTitle && errors.jobTitle}
                          />
                          {touched.jobTitle && errors.jobTitle && (
                            <FormHelperText error>
                              {errors.jobTitle}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            type="text"
                            label="Company Name"
                            name="companyName"
                            {...getFieldProps('companyName')}
                            // error={Boolean(
                            //   touched.companyName && errors.companyName
                            // )}
                            // helperText={
                            //   touched.companyName && errors.companyName
                            // }
                          />
                          {touched.companyName && errors.companyName && (
                            <FormHelperText error>
                              {errors.companyName}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <CurrencyFormat
                            customInput={TextField}
                            thousandSeparator={true}
                            fullWidth
                            type="text"
                            label="Yearly Income"
                            name="yearlyIncome"
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .replace(REGEX.RESTRICT_CHAR, '')
                                .slice(0, 15);
                            }}
                            // InputProps={{
                            //   endAdornment: (
                            //     <InputAdornment position="end">
                            //       <Icon icon="fa6-solid:naira-sign" />
                            //     </InputAdornment>
                            //   ),
                            // }}
                            {...getFieldProps('yearlyIncome')}
                            // error={Boolean(
                            //   touched.yearlyIncome && errors.yearlyIncome
                            // )}
                            // helperText={
                            //   touched.yearlyIncome && errors.yearlyIncome
                            // }
                          />
                          {touched.yearlyIncome && errors.yearlyIncome && (
                            <FormHelperText error>
                              {errors.yearlyIncome}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <CurrencyFormat
                            customInput={TextField}
                            thousandSeparator={true}
                            fullWidth
                            type="text"
                            label="Income to rent calculation"
                            name="rentCalculation"
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .replace(REGEX.RESTRICT_CHAR, '')
                                .slice(0, 15);
                            }}
                            // InputProps={{
                            //   readOnly: true,
                            //   endAdornment: (
                            //     <InputAdornment position="end">
                            //       <Icon icon="fa6-solid:naira-sign" />
                            //     </InputAdornment>
                            //   ),
                            // }}
                            {...getFieldProps('rentCalculation')}
                            // error={Boolean(
                            //   touched.rentCalculation && errors.rentCalculation
                            // )}
                            // helperText={
                            //   touched.rentCalculation && errors.rentCalculation
                            // }
                          />
                          {touched.rentCalculation &&
                            errors.rentCalculation && (
                              <FormHelperText error>
                                {errors.rentCalculation}
                              </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            type="text"
                            label="Rentablity score"
                            name="rentablityScore"
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .replace(REGEX.RESTRICT_CHAR, '')
                                .slice(0, 15);
                            }}
                            {...getFieldProps('rentablityScore')}
                            // error={Boolean(
                            //   touched.rentablityScore && errors.rentablityScore
                            // )}
                            // helperText={
                            //   touched.rentablityScore && errors.rentablityScore
                            // }
                          />
                          {touched.rentablityScore &&
                            errors.rentablityScore && (
                              <FormHelperText error>
                                {errors.rentablityScore}
                              </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            multiline
                            rows="5"
                            type="text"
                            label="Additional Details"
                            name="additionalDetails"
                            {...getFieldProps('additionalDetails')}
                            // error={Boolean(
                            //   touched.additionalDetails &&
                            //     errors.additionalDetails
                            // )}
                            // helperText={
                            //   touched.additionalDetails &&
                            //   errors.additionalDetails
                            // }
                          />
                          {touched.additionalDetails &&
                            errors.additionalDetails && (
                              <FormHelperText error>
                                {errors.additionalDetails}
                              </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                          <Stack direction="row" gap={2} justifyContent="end">
                            <Button
                              type="submit"
                              variant="contained"
                              size="large"
                              sx={{ px: 5 }}
                            >
                              Request to Book
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Form>
                  </FormikProvider>
                </>
              </Box>
            </Container>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ApplyListing;
