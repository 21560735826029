export const REGEX = {
  TITLE: /^(?![0-9.])(?!.'*[-]$)[a-zA-Z0-9-'\s]+$/,
  ZIP: /^[0-9]{4,6}$/,
  ALLOWED_NUMBER: /^\d{1,2}$/,
  GUEST: /^[1-9]$|^0[1-9]$|^1[0-9]$|^20$/,
  DESCRIPTION: /^(.|\s)*[a-zA-Z]+(.|\s)*$/,
  MOBILE: /^[6789][0-9]{9}$/,
  NAME: /^[A-Za-z]+$/,
  FULL_NAME: /^[A-Za-z\s']{1,}$/,
  // FULLNAME: /^[A-Za-z\s]+$/,
  PASSWORD:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&])[a-zA-Z0-9!@#$%^&]{8,20}$/,
  PRICE: /^(?!0)(?=.*[1-9])\d*(?:[1-9]\d*)(?:\.\d{1,2})?$/,
  // /^(?=.*[1-9])\d+(?:\.\d{1,2})?$/,
  // /^\d{1,9}(?:\.\d{1,4})?$/,
  // ^\d+(,\d{1,2})?$
  RESTRICT_CHAR: /[^0-9]/g,
  RESTRICT_GUEST: /[^0-9]/g,
  CVV: /^\d{3,4}$/,
  CARD_NUMBER:
    /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
  ALLOWED_ONLY_NUMBER: /^[0-9]+$/,
  ALLOWED_NUM_EXCEPT_ZERO: /^[1-9]+$/,
};

export const ADMIN_REGEX = {
  EMAIL: /^([a-z][a-z0-9@._]*$)/,
};
