import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Col, Form, Input, Label, Row } from 'reactstrap';
import 'quill/dist/quill.snow.css';
import { addOrEditRules } from '../../../../services/apiServices';

const AddEditRules = (props) => {
  let {
    isEdit,
    handleClose,
    rulesData,
    setRulesListRendering,
    rulesListRendering,
    setResponse,
    setSpinFlag,
  } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [formData, setFormData] = useState({
    rules: '',
  });
  const [errors, setErrors] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors('');
    setFormData({
      [name]: value,
    });
  };

  const toAddOrEditRules = async () => {
    try {
      setSpinFlag(true);
      formData.isEdit = isEdit;
      let { data } = await addOrEditRules(formData);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
        handleClose();
        setRulesListRendering(!rulesListRendering);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  //for managing validation
  const handleSubmit = (e) => {
    e.preventDefault();
    // Validation logic
    const newErrors = {};

    if (!formData.rules.trim()) {
      newErrors.rules = 'Rules is required';
    }
    // Check for errors
    if (Object.keys(newErrors).length > 0) {
      setErrors('Rules is required');
    } else {
      toAddOrEditRules();
    }
  };

  useEffect(() => {
    if (isEdit) {
      setFormData({ rules: rulesData.rules });
    }
  }, []);

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col lg={12}>
            <div className="mb-3">
              <Label htmlFor="teammembersName" className="form-label">
                Title
              </Label>
              <Input
                type="text"
                className="form-control"
                value={formData.rules}
                onChange={handleChange}
                id="teammembersName"
                placeholder="Title"
                name="rules"
              />
              {errors && <div className="text-danger">{errors}</div>}
            </div>
          </Col>
          <Col lg={12}>
            <div className="hstack gap-2 justify-content-end">
              <Button type="button" className="btn-light" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" className="btn-success">
                {isEdit ? 'Save' : 'Add Rules'}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default AddEditRules;
