import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { MultipleRadialbar, SimpleDonut } from '../../Components/AllCharts';
import CountUp from 'react-countup';
import { useSelector, useDispatch } from 'react-redux';

const PropertyChart = ({
  allUnitCount,
  allBookedUnitCount,
  allAvailableUnitCount,
}) => {
  const [chartData, setchartData] = useState([]);

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Tours Available</h4>
        </CardHeader>

        <CardBody className="p-0 pb-2">
          <div className="w-100">
            {allUnitCount > 0 ? (
              <SimpleDonut
                dataColors='["--vz-success", "--vz-primary"]'
                allUnitCount={allUnitCount}
                allBookedUnitCount={allBookedUnitCount}
                allAvailableUnitCount={allAvailableUnitCount}
              />
            ) : (
              <div style={{ height: '312px' }} className="py-2 text-center">
                There are no records to display
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default PropertyChart;
