import React, { useState } from 'react';
import {
  Stack,
  Box,
  CardContent,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Creditcardmockup from '../../../assets/images/CreditCardMockup.svg';
import ArrowIconLeft from '../../../assets/icons/ArrowIconLeft.svg';
import ArrowIconRight from '../../../assets/icons/ArrowIconRight.svg';
import { CardListTable } from './CardListTable';
import { DataNotPayment } from '../../../components/data-not-found';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';

const CarouselDiv = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  '& .slick-slider': {
    height: '100%',
    width: '100%',
    '& .slick-list': {
      height: '100%',
      width: '100%',
      '& .slick-track': {
        height: '100%',
        '& .slick-slide': {
          '&>div': {
            height: '100%',
            width: '100%',
          },
        },
      },
    },
    '& .slick-arrow': {
      fontSize: '0',
      width: '20px',
      height: '20px',
      zIndex: '1',
      transition: '0.45s ease-in-out',
      borderRadius: '50%',
      opacity: '1',
      '&:before': {
        fontSize: '0',
      },
      '&.slick-prev': {
        background: `rgba(255,255,255,0.8) url(${ArrowIconLeft}) no-repeat center center / cover`,
        left: '5px',
      },
      '&.slick-next': {
        background: `rgba(255,255,255,0.8) url(${ArrowIconRight}) no-repeat center center / cover`,
        right: '5px',
      },
      '&.slick-disabled': {
        display: 'none !important',
      },
    },
    '&:hover': {
      '& .slick-arrow': {
        opacity: '1',
      },
    },
    '& .slick-dots': {
      bottom: '-20px',
      '& li': {
        margin: '0',
        '& button:before': {
          fontSize: '22px',
          color: '#fff',
        },
      },
    },
  },
}));

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};
export const CardList = (props) => {
  let { cardList } = props;

  return (
    <>
      <Box
        sx={{
          p: 2,
          bgcolor: '#F9F9F9',
          '& .owl-dots .owl-dot': {
            '& span': {
              background: '#EFEFEF !important',
            },
            '&.active': {
              '& span': {
                background: '#FEAE01 !important',
              },
            },
          },
        }}
      >
        <CarouselDiv>
          <Slider {...settings}>
            {cardList.map((row, index) => {
              const {
                bank,
                last4,
                cardHolderName,
                // nameCard,
                exp_year,
                exp_month,
                // cvv,
                brand,
              } = row.cardDetails;
              return (
                <Box key={index} sx={{ p: 1 }}>
                  <Box
                    sx={{
                      position: 'relative',
                      borderRadius: '20px',
                      overflow: 'hidden',
                      bgcolor: `${
                        brand === 'visa'
                          ? '#0163F6'
                          : brand === 'Mastercard'
                          ? '#1FAB71'
                          : '#FFBA07'
                      }`,
                    }}
                  >
                    <img
                      style={{ width: '100%' }}
                      src={Creditcardmockup}
                      alt={bank}
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '10px',
                        left: '10px',
                        padding: '5px 10px',
                        color: '#12B76A',
                        backgroundColor: '#ECFDF3',
                        border: '1px solid #12B76A',
                        borderRadius: '10px',
                        fontSize: '12px',
                        fontWeight: '400',
                        overflow: 'hidden',
                        display: row.isPrimary !== 0 ? 'flex' : 'none',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      <Icon width={'16px'} icon="ph:seal-check-duotone" />
                      Primary
                    </Box>
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: '0',
                        left: '0',
                        width: '100%',
                        p: 1.5,
                        color: '#fff',
                      }}
                    >
                      <Grid container>
                        <Grid item xs={9}>
                          <Stack
                            sx={{
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              textTransform: 'uppercase',
                            }}
                          >
                            <Typography variant="caption" noWrap sx={{ pr: 1 }}>
                              {cardHolderName ? cardHolderName : '-'}
                            </Typography>
                            <Typography variant="caption">
                              {exp_month} / {exp_year}
                            </Typography>
                          </Stack>
                          <Typography variant="button">
                            **** **** **** {last4}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                            <Box
                              sx={{
                                bgcolor: 'rgba(255,255,255,0.1)',
                                borderRadius: '4px',
                                height: '32px',
                                width: '45px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '5px',
                              }}
                            >
                              {brand === 'visa' ? (
                                <Icon height="100%" icon={'logos:visa'} />
                              ) : brand === 'Mastercard' ? (
                                <Icon height="100%" icon={'logos:mastercard'} />
                              ) : brand === 'Amex' ? (
                                <Icon height="100%" icon={'logos:amex'} />
                              ) : brand === 'Discover' ? (
                                <Icon height="100%" icon={'logos:discover'} />
                              ) : (
                                ''
                              )}
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Slider>
        </CarouselDiv>
      </Box>
    </>
  );
};
