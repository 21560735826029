import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Rating from 'react-rating';
import Flatpickr from 'react-flatpickr';
import { Button, Col, Form, Input, Label, Row } from 'reactstrap';
import 'quill/dist/quill.snow.css';
import { addOrEditReviews } from '../../../../services/apiServices';
import { IMG_URL } from '../../../../utils/constant';
import dummyImg from '../../../assets/images/users/user-dummy-img.jpg';

const AddEditReviews = (props) => {
  let {
    isEdit,
    reviewData,
    reviewRenderFlag,
    setReviewRenderFlag,
    setSpinFlag,
    setResponse,
    handleClose,
  } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const uploadedImage = useRef();
  const [formData, setFormData] = useState({
    senderName: '',
    senderPost: '',
    review: '',
    senderProfile: '',
    rating: 0,
    postDate: '',
  });

  const [errors, setErrors] = useState({});
  const [reset, setreset] = useState('');
  const currentDate = new Date();
  //for image validation
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    // setImageError('');
    if (
      file?.type === 'image/jpg' ||
      file?.type === 'image/jpeg' ||
      file?.type === 'image/png' ||
      file?.type === 'image/svg+xml' ||
      file?.type === 'image/gif'
    ) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);

      setFormData({ ...formData, senderProfile: file });
      setErrors({ ...errors, senderProfile: '' });
    } else {
      setFormData({ ...formData, senderProfile: '' });
      setErrors({ ...errors, senderProfile: 'Image must be in valid format' });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: '' });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //calling api for add and edit reviews
  const toAddOrEditReviews = async () => {
    try {
      setSpinFlag(true);
      formData.isEdit = isEdit;
      let reviewData = new FormData();
      reviewData.append('data', JSON.stringify(formData));
      reviewData.append('senderProfile', formData.senderProfile);
      let { data } = await addOrEditReviews(reviewData);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
        setReviewRenderFlag(!reviewRenderFlag);
        handleClose();
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validation logic
    const newErrors = {};

    if (!formData.senderProfile) {
      newErrors.senderProfile = 'Profile is required';
    }

    if (!formData.senderName.trim()) {
      newErrors.senderName = 'Name is required';
    }

    if (!formData.senderPost.trim()) {
      newErrors.senderPost = 'Designation is required';
    }

    if (formData.rating === 0) {
      newErrors.rating = 'Rating is required';
    }

    if (!formData.review.trim()) {
      newErrors.review = 'Review is required';
    }

    if (!formData.postDate.trim()) {
      newErrors.postDate = 'Post date is required';
    }

    // Check for errors
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      toAddOrEditReviews();
    }
  };

  const handleavailableDate = (e) => {
    if (e[0]) {
      let date = new Date(e[0]),
        mnth = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2);
      let nDate = [date.getFullYear(), mnth, day].join('-');
      return nDate;
    } else {
      return null;
    }
  };

  const handleDateChange = (e) => {
    const nDate = handleavailableDate(e);
    if (nDate) {
      setErrors({ ...errors, postDate: '' });
    } else {
      setErrors({
        ...errors,
        postDate: 'Post date is required',
      });
    }
    setFormData({
      ...formData,
      postDate: nDate,
    });
  };

  useEffect(() => {
    if (isEdit) {
      setFormData({
        senderName: reviewData.senderName,
        senderPost: reviewData.senderPost,
        review: reviewData.review,
        senderProfile: reviewData.senderProfile,
        rating: reviewData.rating,
        postDate: reviewData.postDate.slice(0, 10),
      });
    }
  }, []);

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col lg={12}>
            <div className="text-center mb-4">
              <div className="position-relative d-inline-block">
                <div className="position-absolute bottom-0 end-0">
                  <label
                    htmlFor="member-image-input"
                    className="mb-0"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="Select Member Image"
                  >
                    <div className="avatar-xs">
                      <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                        <i className="ri-image-fill"></i>
                      </div>
                    </div>
                  </label>
                  <input
                    className="form-control d-none"
                    defaultValue=""
                    id="member-image-input"
                    type="file"
                    name="senderProfile"
                    onChange={handleImageUpload}
                    accept="image/png, image/gif, image/jpeg"
                  />
                </div>
                <div className="avatar-lg">
                  <div className="avatar-title bg-light rounded-circle">
                    <img
                      src={
                        isEdit
                          ? `${IMG_URL}${reviewData.senderProfile}`
                          : dummyImg
                      }
                      alt=" "
                      id="member-img"
                      className="avatar-md rounded-circle object-fit-contain"
                      ref={uploadedImage}
                    />
                  </div>
                  {errors.senderProfile && (
                    <div className="text-danger">{errors.senderProfile}</div>
                  )}
                </div>
              </div>
              <h5 className="fs-13 mt-3">User Profile</h5>
            </div>

            <div className="mb-3">
              <Label htmlFor="" className="form-label">
                Name
              </Label>
              <Input
                type="text"
                className="form-control"
                id=""
                value={formData.senderName}
                placeholder="Name"
                onChange={handleChange}
                name="senderName"
              />
              {errors.senderName && (
                <div className="text-danger">{errors.senderName}</div>
              )}
            </div>
            <div className="mb-3">
              <Label htmlFor="" className="form-label">
                Designation
              </Label>
              <Input
                type="text"
                className="form-control"
                id=""
                value={formData.senderPost}
                onChange={handleChange}
                placeholder="Designation"
                name="senderPost"
              />
              {errors.senderPost && (
                <div className="text-danger">{errors.senderPost}</div>
              )}
            </div>
            <div className="mb-3">
              <Label htmlFor="" className="form-label">
                Rating
              </Label>
              <div dir="ltr">
                <div
                  id="raterreset"
                  className="align-middle d-inline rater-reset"
                >
                  <Rating
                    initialRating={formData.rating}
                    value={formData.rating}
                    name="rating"
                    emptySymbol="mdi mdi-star text-muted"
                    fullSymbol={reset}
                    onClick={(e) => {
                      setErrors({ ...errors, rating: '' });
                      setFormData({
                        ...formData,
                        rating: e,
                      });
                    }}
                    onHover={() => {
                      setreset('mdi mdi-star text-warning');
                    }}
                  />
                </div>

                <span className="clear-rating"></span>
                <Button
                  id="raterreset-button"
                  color="light"
                  size="sm"
                  className="ms-2"
                  onClick={() => {
                    setreset('mdi mdi-star text-muted');
                    setErrors({ ...errors, rating: 'Rating is required' });
                    setFormData({
                      ...formData,
                      rating: 0,
                    });
                  }}
                >
                  Reset
                </Button>
                {errors.rating && (
                  <div className="text-danger">{errors.rating}</div>
                )}
              </div>
            </div>
            <div className="mb-3">
              <Label htmlFor="" className="form-label">
                Date
              </Label>
              <div>
                <div className="input-group">
                  <Flatpickr
                    className="form-control"
                    id="datepicker-publish-input"
                    name="postDate"
                    value={new Date(formData.postDate)}
                    placeholder="Select a date"
                    onChange={(e) => {
                      handleDateChange(e);
                    }}
                    options={{
                      dateFormat: 'Y-m-d',
                      mode: 'single',
                      utc: false,
                      maxDate: currentDate,
                    }}
                  />
                  {errors.postDate && (
                    <div className="text-danger">{errors.postDate}</div>
                  )}
                  <div className="input-group-text bg-primary border-primary text-white">
                    <i className="ri-calendar-2-line"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <Label htmlFor="" className="form-label">
                Reviews
              </Label>
              <textarea
                className="form-control"
                id=""
                value={formData.review}
                onChange={handleChange}
                placeholder="Reviews"
                name="review"
                rows="5"
                style={{ resize: 'none' }}
              ></textarea>
              {errors.review && (
                <div className="text-danger">{errors.review}</div>
              )}
            </div>
          </Col>
          <Col lg={12}>
            <div className="hstack gap-2 justify-content-end">
              <Button type="button" className="btn-light" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" className="btn-success">
                {isEdit ? 'Save' : 'Add Reviews'}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default AddEditReviews;
