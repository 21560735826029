import React from 'react';
import { Grid, Typography, Stack, Box, Button } from '@mui/material';
import { PropertyCard } from '../../components/property-card';
import NoDataImg from '../../assets/images/ic_content.svg';
import { DataNotAvailable } from '../../components/data-not-found';

export const PropertyGrid = (props) => {
  let {
    propertiesData,
    setRenderFlag,
    renderFlag,
    loadMore,
    totalProperty,
    visibleProperties,
    bedroomFilter,
    // searchCount,
  } = props;

  return (
    <>
      <Grid container spacing={3}>
        {propertiesData.length === 0 ? (
          <>
            <Grid item xs={12}>
              <DataNotAvailable title="You don't have Property" />
            </Grid>
          </>
        ) : (
          <>
            {propertiesData.map((val, index) => {
              // return val.propertyPrice.length !== 0 ? (
              return (
                <Grid item lg={4} md={6} xs={12} key={index}>
                  <PropertyCard
                    bedroomFilter={bedroomFilter}
                    renderFlag={renderFlag}
                    setRenderFlag={setRenderFlag}
                    propertyValues={val}
                    ratingValue={3}
                    sx={{ border: '1px solid #EBEBEB' }}
                  />
                </Grid>
              );
              // ) : null;
            })}
            <Grid item xs={12}>
              <Box
                sx={{
                  textAlign: 'center',
                  mt: 4,
                }}
              >
                {totalProperty > visibleProperties ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={loadMore}
                  >
                    Load More
                  </Button>
                ) : null}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
