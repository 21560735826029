import {
  Grid,
  Box,
  Container,
  Typography,
  Button,
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import bannerImg from '../../assets/images/bannerImg.png';
import { Link } from 'react-router-dom';
import SvgColor from '../../components/svg-color';
import Deposite from '../../assets/icons/Deposite.svg';
import Payemnt from '../../assets/icons/Payemnt.svg';
import Price from '../../assets/icons/Price.svg';
import Secure from '../../assets/icons/Secure.svg';
import { IMG_URL } from '../../utils/constant';

const StylePopular = styled(Box)(({ theme }) => ({
  background: '#fff',
  padding: '50px 0',
  [theme.breakpoints.down('lg')]: {
    padding: '56px 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: '40px 0',
  },
}));
const BannerLeftText = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    '& .MuiStack-root': {
      gap: '10px',
      '& .MuiButtonBase-root': {
        padding: '8px 15px',
        minWidth: 'calc(50% - 10px)',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiStack-root': {
      flexDirection: 'column',
      '& .MuiButtonBase-root': {
        minWidth: '100%',
      },
    },
  },
}));
const BannerLeft = styled(Box)(({ theme }) => ({}));
const HostImages = styled(Box)(({ theme }) => ({}));

export const BestValueSection = (props) => {
  let { aboutInfoList, aboutsection } = props;
  return (
    <>
      <StylePopular>
        <Container fixed={true} sx={{ maxWidth: { xl: '1326px' } }}>
          <Grid container spacing={4} sx={{ alignItems: 'center' }}>
            <Grid item md={6} xs={12}>
              <BannerLeft>
                <BannerLeftText>
                  {aboutsection && aboutsection?.length > 0 && (
                    <>
                      <Typography variant="h2" sx={{ mb: 4 }}>
                        {aboutsection[0]?.title}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 'normal', color: 'text.secondary' }}
                      >
                        {aboutsection[0]?.subTitle}
                        <br />
                        <br />
                        {aboutsection[0]?.description}
                      </Typography>
                    </>
                  )}
                </BannerLeftText>
              </BannerLeft>
            </Grid>
            <Grid item md={6} xs={12}>
              <HostImages>
                <List
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                      lg: 'auto auto',
                      md: 'auto',
                      sm: 'auto auto',
                      xs: 'auto',
                    },
                    gap: { lg: 8, xs: 2 },
                  }}
                >
                  {aboutInfoList.map((value, index) => (
                    <ListItem
                      sx={{ paddingLeft: '90px', alignItems: 'start' }}
                      key={index}
                    >
                      <ListItemIcon
                        sx={{ position: 'absolute', left: '-15px', top: '0' }}
                      >
                        <img
                          width="42px"
                          height="42px"
                          style={{
                            borderRadius: '50%',
                            objectFit: 'cover',
                            marginTop: '15px',
                            marginLeft: '15px',
                          }}
                          src={`${IMG_URL}${value.icon}`}
                        />
                      </ListItemIcon>
                      <ListItemText sx={{ position: 'relative', zIndex: 1 }}>
                        <Box>
                          <Typography variant="h6" sx={{ mb: 2 }}>
                            {value.heading}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ color: 'text.secondary' }}
                          >
                            {value.description}
                          </Typography>
                        </Box>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </HostImages>
            </Grid>
          </Grid>
        </Container>
      </StylePopular>
    </>
  );
};
