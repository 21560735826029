import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import {
  Card,
  Typography,
  Button,
  ListItemText,
  ListItemButton,
  Box,
  Stack,
  CardContent,
} from '@mui/material';
import SvgColor from '../../../components/svg-color';

const StyledIcon = styled(Box)(({ theme }) => ({}));
export const AppWidgetSummary = ({ title, total, icon }) => {
  return (
    <>
      <Card sx={{ display: 'grid', alignItems: 'center' }}>
        <CardContent sx={{}}>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="h3">{total}</Typography>
              <Typography variant="body2" sx={{ color: '#808191' }}>
                {title}
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  width: '72px',
                  height: '72px',
                  py: 2,
                  px: 2,
                  background: '#FAFAFA',
                  color: '#FEAE01',
                  borderRadius: '14px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  ml: 'auto',
                }}
              >
                <SvgColor src={icon} alt={icon} />
              </Box>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};
