import React, {
  useRef,
  useState,
  useEffect,
  createRef,
  useCallback,
  useMemo,
} from 'react';
import SimpleBar from 'simplebar-react';
import { Container, Button, UncontrolledTooltip, Input } from 'reactstrap';
import { styled, alpha } from '@mui/material/styles';
import io from 'socket.io-client';
import Dropzone, { useDropzone } from 'react-dropzone';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Avatar,
  InputAdornment,
  OutlinedInput,
  ListItemButton,
  Typography,
  Badge,
  IconButton,
  ListItemAvatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextareaAutosize,
  Tooltip,
  CardMedia,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { Icon } from '@iconify/react';
import Scrollbar from '../../../components/scrollbar';
import SvgColor from '../../../components/svg-color';
import attachment from '../../../assets/icons/attachment-01.svg';
import send from '../../../assets/icons/send-01.svg';
import {
  NoMessage,
  DataNotAvailable,
} from '../../../components/data-not-found';
import { BASEURL, IMG_URL } from '../../../utils/constant';
import moment from 'moment';
import {
  createRoom,
  getMessageList,
  readMessages,
  sendMessages,
  getContactListForChat,
} from '../../../services/apiServices';
import { useAuthStore } from '../../../redux/features/authSlice';

import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  height: 44,
  borderRadius: 14,
  backgroundColor: '#fff',
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
  '& input': {
    pending: '0',
  },
}));
const FileAttachBox = styled(Box)(({ theme }) => ({
  padding: '0',
  '& .MuiList-root': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    padding: '0 0 10px',
    minWidth: 'max-content',
    '& .MuiListItem-root': {
      width: '120px',
      height: '100px',
      padding: '0',
      borderRadius: '5px',
      overflow: 'hidden',
      background: '#fff',
      border: '1px solid rgba(145, 158, 171, 0.3)',
      position: 'relative',
      wordBreak: 'break-all',
      whiteSpace: 'pre-line',
      fontSize: '10px',
      '&>div': {
        width: '100%',
        height: '100%',
        '&>*': {
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        },
      },
    },
  },
}));
const DownloadButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '5px',
  right: '5px',
  width: '30px !important',
  height: '30px !important',
  background: '#fff !important',
  border: '1px solid rgb(241, 241, 244)',
}));
const MessageInput = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  resize: 'none',
  boxShadow: 'none',
  outline: 'none',
  minHeight: '35px',
  fontSize: 14,
  paddingTop: '5px',
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));
const StyledBadge = styled(Badge)(({ theme }) => ({
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const ChatInput = (props) => {
  let {
    handleKeyPress,
    typingHandler,
    sendMessage,
    inputRef,
    sendMessageHandler,
    send,
  } = props;
  return (
    <>
      <div className="px-1 d-flex" style={{ width: 'calc(100% - 80px)' }}>
        <MessageInput
          maxRows={2}
          autoFocus={true}
          id="personalMsg"
          name="personalMsg"
          value={sendMessage}
          onKeyDown={handleKeyPress}
          onChange={typingHandler}
          autoComplete="off"
          placeholder="Enter Message...."
          ref={inputRef}
          className="bg-light border-light chat-input rounded-3 px-3"
        />
      </div>
      <button
        className="btn btn-link btn-soft-success text-decoration-none border-0 chat-send"
        type="submit"
        onClick={() => sendMessageHandler()}
      >
        <i className="ri-send-plane-2-fill"></i>
      </button>
    </>
  );
};
const Chat = () => {
  let { loginAdminInfo } = useAuthStore();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const chatContainerRef = useRef(null);
  const inputRef = useRef(null);
  const location = useLocation();
  const scrollbarRef = useRef(null);
  const msgScrollRef = useRef(null);
  const totalMessageRef = useRef(null);
  const totalContactRef = useRef(null);
  const allContactScrollRef = useRef(null);
  const totalAllContactRef = useRef(null);
  const prevScrollHeightRef = useRef(0);
  const [open, setOpen] = useState(false);
  const [spinFlag, setSpinFlag] = useState(false);
  const [message, setMessage] = useState('');
  const [openContact, setOpenContact] = useState(false);
  const [membersList, setMemeberList] = useState([]);
  const [messageList, setMessageList] = useState([]);
  const [sendMessage, setSendMessage] = useState('');
  const [selectedChatUserId, setSelectedChatUserId] = useState();
  const [typing, setTyping] = useState(false);
  const [istyping, setIsTyping] = useState(false);
  const [headerName, setHeaderName] = useState();
  const [roomId, setRoomId] = useState();
  const [currentSocket, setCurrentSocket] = useState(null);
  const [searchByName, setSearchByName] = useState('');
  const [searchByContact, setSearchByContact] = useState('');
  const [socketConnected, setSocketConnected] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [showBottomArrow, setShowBottomArrow] = useState();
  const [contactListPage, setContactListPage] = useState(6);
  const [allContactListPage, setAllContactListPage] = useState(6);
  const [messageListPage, setMessageListPage] = useState(25);
  const [isDragActive, setIsDragActive] = useState(false);
  const [files, setFiles] = useState([]);
  const [maxFilesPopup, setMaxFilesPopup] = useState(false);
  const [reply, setreply] = useState('');

  const getScrollPosition = () => {
    const showBottomArrow =
      msgScrollRef.current.scrollHeight - 1000 > msgScrollRef.current.scrollTop;
    setShowBottomArrow(showBottomArrow);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragActive(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    // Process droppedFiles here
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragActive(true);
  };

  const handleDragLeave = (e) => {
    setIsDragActive(false);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles) => {
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    },
    maxSize: 75 * 1024 * 1024,
    maxFiles: 10,
  });

  const renderPreview = (file) => {
    if (file.type.startsWith('video/')) {
      return (
        <ReactPlayer
          url={URL.createObjectURL(file)}
          width="100%"
          height="100%"
        />
      );
    }
    // else if (file.type === 'application/pdf') {
    //   return (
    //     <Document file={file}>
    //       <Page pageNumber={1} width="100%" height="100%" />
    //     </Document>
    //   );
    // }
    else if (file.type.startsWith('image/')) {
      return (
        <CardMedia
          component="img"
          alt={file.preview}
          image={URL.createObjectURL(file)}
        />
      );
    } else {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Typography
              variant="caption"
              noWrap
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              {file.name.split('.').pop().toLowerCase() === 'pdf' ? (
                <Icon
                  width="20px"
                  // icon="bxs:file-pdf"
                  icon="vscode-icons:file-type-pdf2"
                />
              ) : file.name.split('.').pop().toLowerCase() === 'docx' ||
                file.name.split('.').pop().toLowerCase() === 'doc' ? (
                <Icon width="20px" icon="fxemoji:documenttextpicture" />
              ) : file.name.split('.').pop().toLowerCase() === 'txt' ? (
                <Icon width="20px" icon="fxemoji:documentwithtext" />
              ) : file.name.split('.').pop().toLowerCase() === 'zip' ? (
                <Icon width="20px" icon="vscode-icons:file-type-zip" />
              ) : (
                <Icon width="20px" icon="simple-line-icons:doc" />
              )}
              {file.name.split('.').pop().toLowerCase()}
            </Typography>
            <Typography variant="subtitle2" noWrap>
              {file.name}
            </Typography>
          </Box>
        </Box>
      );
    }
  };
  const scrollToTop = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollIntoView({
        block: 'end',
      });
    }
  };

  const scrollDown = () => {
    scrollToTop();
  };

  const allContactList = async () => {
    try {
      setSpinFlag(true);
      const payload = {
        role: loginAdminInfo.role,
        searchByContact: searchByContact,
        pageNumber: allContactListPage,
      };
      let { data } = await getContactListForChat(payload);
      if (data.statusCode === 200) {
        setContactList(data.contactList);
        totalAllContactRef.current = data.totalContact;
        setSpinFlag(false);
        //new code
        const container = allContactScrollRef.current;

        if (container) {
          container.addEventListener('scroll', handleAllContactListScroll);
          return () => {
            container.removeEventListener('scroll', handleAllContactListScroll);
          };
        }
        //
      } else {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const handleRequestTourAndContactLandlord = async () => {
    try {
      const searchParams = new URLSearchParams(location.search);
      const paramRoomId = searchParams.get('roomId');
      const _id = state ? state.userId : null;
      const profile = state ? state.profile : null;
      const firstname = state ? state.firstname : null;
      const lastname = state ? state.lastname : null;
      const visitDate = state ? state.visitDate : null;
      const visitMessage = state ? state.visitMessage : null;

      const data = {
        _id: paramRoomId,
        participants: [
          {
            _id: _id,
            firstname: firstname,
            lastname: lastname,
            profile: profile,
          },
        ],
      };

      if (_id && firstname && lastname) {
        await selectedChatHandler(data);
        // if (_id && firstname && lastname && visitDate && visitMessage) {
        //   setSendMessage(`${visitDate}${' '}${visitMessage}`);
        //   let val = {
        //     userId: _id,
        //     visitDate: visitDate,
        //     visitMessage: visitMessage,
        //     room_id: paramRoomId,
        //   };
        //   await sendMessageHandler(val);
        // }
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      allContactList();
    }, 700);

    handleRequestTourAndContactLandlord();
    const getMemberList = setTimeout(() => {
      if (currentSocket) {
        let memberListObj = {
          searchByName: searchByName,
          user_id: loginAdminInfo._id,
          pageNumber: contactListPage,
        };
        currentSocket.emit('member-list', memberListObj);
      }
    }, 700);
    return () => {
      clearTimeout(getMemberList);
      clearTimeout(getData);
      // localStorage.removeItem('userId');
      // localStorage.removeItem('profile');
      // localStorage.removeItem('firstname');
      // localStorage.removeItem('lastname');
      // localStorage.removeItem('visitDate');
      // localStorage.removeItem('visitMessage');
    };
  }, [searchByContact, searchByName, location]);

  useEffect(() => {
    // inputRef.current.focus();
    // Connect to the chat namespace
    const chatSocket = io(`${BASEURL}`);
    const notificationSocket = io(`${BASEURL}`);

    setCurrentSocket(chatSocket);

    let memberListObj = {
      searchByName: searchByName,
      user_id: loginAdminInfo._id,
      pageNumber: contactListPage,
    };
    chatSocket.emit('member-list', memberListObj);
    chatSocket.emit('join chat', roomId);

    // Event handlers for the chat namespace
    chatSocket.on('connect', (socket) => {
      // Perform actions specific to the chat namespace
      setSocketConnected(true);
    });
    chatSocket.on('online', (userId) => {
      setOnlineUsers(userId);
    });

    chatSocket.on('offline', (userId) => {});

    // chatSocket.on('fetch-member-list', handleMemberList )

    chatSocket.on('fetch-member-list', (result) => {
      setMemeberList(result.memberList);
      totalContactRef.current = result.totalMembers;
    });

    chatSocket.on('receiver-member-list', (result) => {
      setMemeberList(result.memberList);
    });

    chatSocket.on('sender-member-list', (result) => {
      setMemeberList(result.memberList);
    });

    // chatSocket.on('message-notify', (data) => {
    //   if (data.roomId !== roomId) {
    //     notificationSocket.emit('notification', data);
    //   } else {
    //     console.log('Not notification.');
    //   }
    // });

    const handleMessages = async (data) => {
      // Handle the received message in the chat namespace
      if (data.roomId === roomId) {
        const read = {
          roomId: roomId,
          receiverId: loginAdminInfo._id,
          senderId: data.senderId._id,
        };
        chatSocket.emit('read-messages', read);
        // chatSocket.emit('delete-notification', read);
        const res = await readMessages(read);
        setMessageList((messageList) => [...messageList, data]);
      }
    };
    chatSocket.on('message', handleMessages);

    chatSocket.on('typing', ({ senderId, room }) => {
      if (roomId === room && senderId === selectedChatUserId) {
        // const isSenderTyping = senderId === selectedChatUserId;
        setIsTyping(true);
        setTyping(true);
      } else {
        setIsTyping(false);
        setTyping(false);
      }
    });

    chatSocket.on('stop typing', ({ senderId }) => {
      setIsTyping(false);
      setTyping(false);
    });

    return () => {
      chatSocket.off('message', handleMessages);
      chatSocket.off('typing');
      chatSocket.off('online');
      // chatSocket.off('stop typing');
      // chatSocket.off('sender-member-list');
      // chatSocket.off('receiver-member-list');
      // chatSocket.off('fetch-member-list');
      // chatSocket.off('message-notify');
      // chatSocket.removeAllListeners()
      // chatSocket.close()
    };
  }, [roomId, selectedChatUserId, location]);

  const contactListHandler = async (contactUser) => {
    const payload = {
      userId: contactUser._id,
    };
    const { data } = await createRoom(payload);
    const hearderData = {
      participants: [contactUser],
      _id: data.id,
    };
    // navigation
    if (data) {
      selectedChatHandler(hearderData);
      setOpenContact(false);
    }
    inputRef.current.focus();
  };

  // const handleRequestTourAndContactLandlord = async () => {
  //   try {
  //     const searchParams = new URLSearchParams(location.search);
  //     const paramRoomId = searchParams.get('roomId');
  //     const _id = localStorage.getItem('userId');
  //     const profile = localStorage.getItem('profile');
  //     const firstname = localStorage.getItem('firstname');
  //     const lastname = localStorage.getItem('lastname');
  //     const visitDate = localStorage.getItem('visitDate');
  //     const visitMessage = localStorage.getItem('visitMessage');

  //     const data = {
  //       _id: paramRoomId,
  //       participants: [
  //         {
  //           _id: _id,
  //           firstname: firstname,
  //           lastname: lastname,
  //           profile: profile,
  //         },
  //       ],
  //     };

  //     if (_id && profile && firstname && lastname) {
  //       await selectedChatHandler(data);
  //       if (
  //         _id &&
  //         profile &&
  //         firstname &&
  //         lastname &&
  //         visitDate &&
  //         visitMessage
  //       ) {
  //         setSendMessage(`${visitDate}${' '}${visitMessage}`);
  //         let val = {
  //           userId: _id,
  //           visitDate: visitDate,
  //           visitMessage: visitMessage,
  //           room_id: paramRoomId,
  //         };
  //         await sendMessageHandler(val);
  //       }
  //     }
  //   } catch (err) {
  //     navigate(`/error?msg=${err}&&path=${pathname}`);
  //   }
  // };

  const selectedChatHandler = async (room) => {
    currentSocket?.emit('join chat', room._id);
    inputRef.current.focus();
    setHeaderName(room);
    setSendMessage('');
    setRoomId(room._id);
    setSelectedChatUserId(room.participants[0]._id);
    setIsTyping(false);
    setTyping(false);
    const payload = {
      roomId: room._id,
      pageNumber: messageListPage,
    };

    const read = {
      roomId: room._id,
      receiverId: loginAdminInfo._id,
    };
    try {
      // setSpinFlag(true);
      const prevScrollHeight = msgScrollRef.current.scrollHeight;
      const { data } = await getMessageList(payload);
      setMessageList(data.messages);
      totalMessageRef.current = data.totalMessage;
      const res = await readMessages(read);
      // if (msgScrollRef.current) {
      //   msgScrollRef.current.scrollTop =
      //     msgScrollRef.current.scrollHeight - prevScrollHeight;
      // }
      if (room.flag) {
        if (msgScrollRef.current) {
          msgScrollRef.current.scrollTop =
            msgScrollRef.current.scrollHeight - prevScrollHeight;
        }
      } else {
        scrollToTop();
      }
      if (res.data === 'read') {
        currentSocket.emit('member-list', loginAdminInfo._id);
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const sendMessageHandler = async (obj) => {
    // let { userId, visitMessage, visitDate, room_id } = obj;

    currentSocket?.emit('stop typing', {
      roomId: roomId,
      senderId: loginAdminInfo._id,
    });

    const payload = {
      senderId: loginAdminInfo._id,
      roomId: roomId ? roomId : obj.room_id,
      receiverId: selectedChatUserId ? selectedChatUserId : obj.userId,
      content: sendMessage
        ? sendMessage
        : obj
        ? `${obj.visitMessage}${' '}${obj.visitDate}`
        : '',
    };
    let messageData = new FormData();
    files.map((file) => {
      messageData.append(`attachments`, file);
    });
    messageData.append('message', JSON.stringify(payload));

    try {
      setSendMessage('');
      setFiles([]);
      if (sendMessage.trim() !== '' || files.length !== 0 || obj) {
        const { data } = await sendMessages(messageData);
        currentSocket?.emit('message', data.messageData);
        setMessageList([...messageList, data.messageData]);

        if (data.messageData) {
          setTimeout(() => {
            scrollToTop();
          }, 100);
        }
        // localStorage.removeItem('visitDate');
        // localStorage.removeItem('visitMessage');
      }
      // else if (files.length !== 0) {
      //   const { data } = await sendMessages(messageData);
      //   currentSocket.emit('message', data.messageData);
      //   setMessageList([...messageList, data.messageData]);
      //   if (data.messageData) {
      //     setTimeout(() => {
      //       scrollToTop();
      //     }, 100);
      //   }
      // }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const typingHandler = (e) => {
    // e.preventDefault();
    setSendMessage(e.target.value);
    if (!socketConnected) return;

    if (!typing) {
      setTyping(true);
      currentSocket.emit('typing', {
        room: roomId,
        senderId: loginAdminInfo._id,
      });
    }
    let lastTypingTime = new Date().getTime();
    var timerLength = 1500;
    setTimeout(() => {
      var timeNow = new Date().getTime();
      var timeDiff = timeNow - lastTypingTime;
      if (timeDiff >= timerLength && typing) {
        currentSocket.emit('stop typing', {
          room: roomId,
          senderId: loginAdminInfo._id,
        });
        setTyping(!typing);
      }
    }, timerLength);
  };

  // Function to handle Enter key press
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault(); // Prevent the default behavior of the Enter key
      setSendMessage((prevMessage) => prevMessage + '\n'); // Append a newline
    } else if (event.key === 'Enter') {
      event.preventDefault();
      sendMessageHandler();
    }
  };

  const handleFilterByContact = (event) => {
    setSearchByContact(event.target.value);
  };

  const handleFilterName = (event) => {
    setSearchByName(event.target.value);
  };

  const handleClickOpen = async () => {
    setOpenContact(true);
    // setTimeout(() => {
    //   const container = allContactScrollRef.current;
    //   if (container) {
    //     container.addEventListener('scroll', handleAllContactListScroll);
    //     return () => {
    //       container.removeEventListener('scroll', handleAllContactListScroll);
    //     };
    //   }
    // }, 100);
  };

  const handleClose = () => {
    setOpenContact(false);
    setMaxFilesPopup(false);
  };

  // to grouped message
  const groupedMessages = {};
  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'day').startOf('day');

  messageList?.forEach((message) => {
    const createdAt = moment(message?.createdAt);
    let dateGroup;

    if (createdAt.isSame(today, 'd')) {
      dateGroup = 'Today';
    } else if (createdAt.isSame(yesterday, 'd')) {
      dateGroup = 'Yesterday';
    } else {
      dateGroup = createdAt.format('DD MMMM YYYY');
    }

    if (!groupedMessages[dateGroup]) {
      groupedMessages[dateGroup] = [];
    }

    groupedMessages[dateGroup].push({
      ...message,
      // agoTime: createdAt.fromNow(),
    });
  });

  // testing code start
  // Fetch more data as the user scrolls down

  const fetchMoreContacts = (currentPage) => {
    //  new code start
    let memberListObj = {
      searchByName: searchByName,
      user_id: loginAdminInfo._id,
      pageNumber: currentPage,
    };

    currentSocket.emit('member-list', memberListObj);
    // new code end
  };
  // Function to check if the user has scrolled to the bottom
  const handleContactListScroll = () => {
    const container = scrollbarRef.current;
    // const target = event.target;

    if (
      container.scrollHeight - container.scrollTop === container.clientHeight &&
      contactListPage < totalContactRef.current
    ) {
      setContactListPage((prevPage) => prevPage + 6);
    }
  };

  // Attach the onScroll event listener to the desired element
  useEffect(() => {
    const container = scrollbarRef.current;
    container.addEventListener('scroll', handleContactListScroll);

    if (currentSocket) {
      fetchMoreContacts(contactListPage);
    }
    return () => {
      container.removeEventListener('scroll', handleContactListScroll);
    };
  }, [contactListPage]);

  useEffect(() => {
    const msgContainer = msgScrollRef.current;
    if (msgContainer) {
      // Store the initial scroll height
      prevScrollHeightRef.current = msgContainer.scrollHeight;
      msgContainer.addEventListener('scroll', handleMessageListScroll);
    }
    if (headerName) {
      headerName.flag = true;
      selectedChatHandler(headerName);
    }
    return () => {
      if (msgContainer) {
        msgContainer.removeEventListener('scroll', handleMessageListScroll);
      }
    };
  }, [messageListPage]);

  const handleMessageListScroll = () => {
    const container = msgScrollRef.current;
    if (
      container &&
      container.scrollTop === 0 &&
      messageListPage < totalMessageRef.current
    ) {
      setMessageListPage((prevPage) => prevPage + 25);
    }
  };

  // for whole contact list pagination start
  // Function to check if the user has scrolled to the bottom
  const handleAllContactListScroll = (event) => {
    const target = event.target;
    if (
      target.scrollHeight - target.scrollTop === target.clientHeight &&
      allContactListPage < totalAllContactRef.current
    ) {
      setAllContactListPage((prevPage) => prevPage + 6);
      allContactList();
    }
  };

  //memoize chat input component
  const AisolutionChatInputcomponent = useMemo(() => {
    return (
      <ChatInput
        handleKeyPress={handleKeyPress}
        typingHandler={typingHandler}
        sendMessage={sendMessage}
        inputRef={inputRef}
        sendMessageHandler={sendMessageHandler}
        send={send}
      />
    );
  }, [typingHandler]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
            <Backdrop
              sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={spinFlag}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <DialogPopup open={open} setOpen={setOpen} message={message} />
            <div className="chat-leftsidebar">
              <div className="px-4 pt-4 mb-3">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h5 className="mb-4">Chats</h5>
                  </div>
                  <div className="flex-shrink-0">
                    <UncontrolledTooltip placement="bottom" target="addcontact">
                      Chats
                    </UncontrolledTooltip>

                    <Button
                      color=""
                      id="addcontact"
                      className="btn btn-soft-success btn-sm shadow-none"
                      onClick={handleClickOpen}
                    >
                      Contact list
                    </Button>
                  </div>
                </div>
                <div className="search-box">
                  <Input
                    value={searchByName}
                    onChange={handleFilterName}
                    placeholder="Search user"
                    className="form-control bg-light border-light"
                  />
                  <i className="ri-search-2-line search-icon"></i>
                </div>
              </div>
              <SimpleBar className="chat-room-list">
                <div className="chat-message-list">
                  <ul
                    className="list-unstyled chat-list chat-user-list users-list"
                    ref={scrollbarRef}
                    id="scrollable-container"
                  >
                    {membersList?.map((val, index) => {
                      return val.participants?.map((user, index) => (
                        <li
                          key={index}
                          className={val._id === roomId ? 'active' : ''}
                        >
                          <Link
                            className={`border-bottom d-flex flex-wrap align-items-start ${
                              val._id === roomId ? 'active' : ''
                            }`}
                            onClick={() => selectedChatHandler(val)}
                          >
                            <div
                              className="d-flex align-items-center"
                              style={{ width: 'calc(100% - 60px)' }}
                            >
                              <div className="flex-shrink-0 chat-user-img align-self-center me-2 ms-0">
                                <div className="avatar-xxs">
                                  {user.profile ? (
                                    <img
                                      alt={user.firstname?.toUpperCase()}
                                      src={
                                        user.profile &&
                                        `${IMG_URL}${user.profile}`
                                      }
                                      className="rounded-circle img-fluid userprofile"
                                    />
                                  ) : (
                                    <div className="avatar-title rounded-circle bg-info userprofile">
                                      {user.firstname.charAt(0)}
                                    </div>
                                  )}
                                </div>
                                <span
                                  className={
                                    onlineUsers.includes(
                                      val?.participants[0]?._id
                                    )
                                      ? 'user-status bg-success'
                                      : 'user-status'
                                  }
                                ></span>
                              </div>
                              <div className="flex-grow-1 overflow-hidden">
                                <h6 className="mb-0 text-truncate">
                                  {user?.firstname} {user?.lastname}
                                </h6>
                                <p className="text-truncate mb-0 d-flex align-items-center">
                                  {val?.lastMessage?.metaData ? (
                                    <>
                                      {val?.lastMessage?.metaData.type ===
                                      'image' ? (
                                        <Icon
                                          width="13px"
                                          icon="vscode-icons:file-type-image"
                                        />
                                      ) : val?.lastMessage?.metaData.type ===
                                        'video' ? (
                                        <Icon
                                          width="13px"
                                          icon="vscode-icons:file-type-video"
                                        />
                                      ) : val?.lastMessage?.metaData.type ===
                                        'doc' ? (
                                        <>
                                          {val?.lastMessage?.metaData.name
                                            .split('.')
                                            .pop()
                                            .toLowerCase() === 'pdf' ? (
                                            <Icon
                                              width="13px"
                                              icon="vscode-icons:file-type-pdf2"
                                            />
                                          ) : val?.lastMessage?.metaData.name
                                              .split('.')
                                              .pop()
                                              .toLowerCase() === 'docx' ||
                                            val?.lastMessage?.metaData.name
                                              .split('.')
                                              .pop()
                                              .toLowerCase() === 'doc' ? (
                                            <Icon
                                              width="13px"
                                              icon="fxemoji:documenttextpicture"
                                            />
                                          ) : val?.lastMessage?.metaData.name
                                              .split('.')
                                              .pop()
                                              .toLowerCase() === 'txt' ? (
                                            <Icon
                                              width="13px"
                                              icon="fxemoji:documentwithtext"
                                            />
                                          ) : val?.lastMessage?.metaData.name
                                              .split('.')
                                              .pop()
                                              .toLowerCase() === 'zip' ? (
                                            <Icon
                                              width="13px"
                                              icon="vscode-icons:file-type-zip"
                                            />
                                          ) : (
                                            <Icon
                                              width="13px"
                                              icon="simple-line-icons:doc"
                                            />
                                          )}
                                        </>
                                      ) : null}
                                      <span className="ps-1">
                                        {val?.lastMessage?.metaData.name}
                                      </span>
                                    </>
                                  ) : (
                                    val?.lastMessage?.content
                                  )}
                                </p>
                              </div>
                            </div>
                            <div
                              className="d-flex justify-content-end align-items-end flex-column"
                              style={{ width: '60px' }}
                            >
                              <p className="text-truncate text-muted m-0">
                                <small>
                                  {val?.lastMessage?.updatedAt &&
                                  moment(val?.lastMessage?.updatedAt).isSame(
                                    moment().startOf('day'),
                                    'd'
                                  )
                                    ? val?.lastMessage?.updatedAt &&
                                      moment(
                                        val?.lastMessage?.updatedAt
                                      ).format('hh:mm a')
                                    : val?.lastMessage?.updatedAt &&
                                      moment(
                                        val?.lastMessage?.updatedAt
                                      ).format('MM/DD/YY')}
                                  {/* {moment(val?.lastMessage?.updatedAt).fromNow()} */}
                                </small>
                              </p>
                              {val?.unread_count === 0 ? (
                                ''
                              ) : (
                                <>
                                  {val?.lastMessage?.senderId !==
                                    loginAdminInfo._id &&
                                  selectedChatUserId !==
                                    val?.lastMessage?.senderId ? (
                                    <span className="badge bg-dark-subtle text-body rounded p-1">
                                      {val?.unread_count > 100 ? (
                                        '99+'
                                      ) : (
                                        <>{val?.unread_count}</>
                                      )}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </>
                              )}
                            </div>
                          </Link>
                        </li>
                      ));
                    })}
                    {membersList?.length === 0 ? (
                      <Box sx={{ px: 2, pb: 2, height: '100%' }}>
                        <DataNotAvailable
                          sx={{ height: '100%', p: 2, minHeight: 'auto' }}
                          title="You don't have Chat List"
                        />
                      </Box>
                    ) : null}
                  </ul>
                </div>
              </SimpleBar>
            </div>

            <div
              className={`user-chat w-100 overflow-hidden ${
                headerName ? 'user-chat-show' : ''
              }`}
            >
              <div className="chat-content h-100 d-lg-flex">
                <div className="w-100 h-100 overflow-hidden position-relative">
                  {headerName ? null : (
                    <div
                      className="bg-white w-100 h-100 align-items-center justify-content-center"
                      style={{
                        display: `${headerName ? 'none' : 'flex'}`,
                      }}
                    >
                      {membersList.length === 0 ? (
                        <NoMessage handleClickOpen={handleClickOpen} />
                      ) : (
                        <>
                          <div className="text-center">
                            <div className="chat-user-img avatar-lg user-own-img mb-3 mx-auto text-center">
                              <div className="avatar-title rounded-circle bg-info userprofile">
                                <img
                                  alt={loginAdminInfo.firstname?.toUpperCase()}
                                  src={`${IMG_URL}${loginAdminInfo.profile}`}
                                  className="rounded-circle"
                                />
                                <span
                                  className={`position-absolute bottom-0 end-0 translate-middle p-2 bg-success border border-light rounded-circle me-n2`}
                                ></span>
                              </div>
                            </div>
                            <p>Welcome !</p>
                            <h3>
                              {loginAdminInfo?.firstname +
                                '  ' +
                                loginAdminInfo.lastname}
                            </h3>
                            <button
                              className="mt-3 btn btn-primary"
                              onClick={handleClickOpen}
                            >
                              Contact list
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  <div
                    className="flex-column overflow-hidden w-100 h-100 position-relative"
                    style={{
                      display: `${headerName ? 'flex' : 'none'}`,
                    }}
                    onDrop={handleDrop}
                    onDragEnter={handleDragEnter}
                    onDragOver={(event) => event.preventDefault()}
                  >
                    {isDragActive && (
                      <div
                        className="position-absolute w-100 h-100 top-0 start-0"
                        style={{
                          zIndex: 9,
                          border: '10px solid #fff',
                          background: 'rgba(255, 255, 255, 0.9)',
                        }}
                        onDragLeave={handleDragLeave}
                      >
                        <div
                          className="w-100 h-100 d-flex align-items-center justify-content-center"
                          {...getRootProps()}
                          style={{
                            border: '2px dashed #999',
                            background: 'rgba(255,255,255,0.5)',
                            borderRadius: '10px',
                          }}
                        >
                          <input name="msgImg" {...getInputProps()} />
                          <h6
                            className="m-0 text-center"
                            style={{ maxWidth: '60%' }}
                          >
                            <Icon
                              icon="fluent-mdl2:doc-library"
                              style={{ marginRight: '5px' }}
                            />{' '}
                            Drag and drop files here, or click to select files
                            (max 75MB)
                          </h6>
                        </div>
                      </div>
                    )}
                    <div className="d-flex flex-column h-100 position-relative overflow-hidden">
                      <div className="p-3 user-chat-topbar border-bottom">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 d-block d-lg-none me-3">
                            <Link
                              to="javascript: void(0);"
                              className="user-chat-remove fs-18 p-1"
                              onClick={() => setHeaderName()}
                            >
                              <i className="ri-arrow-left-s-line align-bottom"></i>
                            </Link>
                          </div>
                          <div className="flex-grow-1 overflow-hidden">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0 chat-user-img avatar-xs user-own-img align-self-center me-3 ms-0">
                                {headerName?.participants[0]?.profile ===
                                undefined ? (
                                  <div className="avatar-title rounded-circle bg-info userprofile">
                                    {headerName?.participants[0]?.firstname.charAt(
                                      0
                                    )}
                                  </div>
                                ) : (
                                  <img
                                    alt={
                                      headerName
                                        ? headerName?.participants[0]?.firstname?.toUpperCase()
                                        : null
                                    }
                                    src={
                                      headerName?.participants[0]?.profile &&
                                      `${IMG_URL}${headerName?.participants[0]?.profile}`
                                    }
                                    className="rounded-circle"
                                  />
                                )}
                                <span
                                  className={
                                    onlineUsers.includes(
                                      headerName?.participants[0]?._id
                                    )
                                      ? 'user-status bg-success'
                                      : headerName && 'user-status'
                                  }
                                ></span>
                              </div>
                              <div className="flex-grow-1 overflow-hidden">
                                <h5 className="text-truncate mb-0 fs-16">
                                  <a
                                    className="text-reset username"
                                    data-bs-toggle="offcanvas"
                                    href="#userProfileCanvasExample"
                                    aria-controls="userProfileCanvasExample"
                                  >
                                    {headerName
                                      ? headerName?.participants[0]?.firstname +
                                        ' ' +
                                        headerName?.participants[0]?.lastname
                                      : null}
                                  </a>
                                </h5>
                                <p className="text-truncate text-muted fs-14 mb-0 userStatus">
                                  {onlineUsers.includes(
                                    headerName?.participants[0]?._id
                                  ) ? (
                                    istyping ? (
                                      <small>Typing...</small>
                                    ) : (
                                      <small>Online</small>
                                    )
                                  ) : (
                                    headerName && <small>Offline</small>
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="chat-conversation p-3 pt-0 flex-grow-1"
                        id="chat-conversation"
                        ref={msgScrollRef}
                        onScroll={getScrollPosition}
                        style={{ overflowY: 'auto' }}
                      >
                        {Object.keys(groupedMessages).map((day, index) => (
                          <div key={index}>
                            <p
                              className="position-sticky text-center top-0 py-1"
                              style={{ zIndex: '1' }}
                            >
                              <span className="bg-light py-1 px-3 d-inline-block rounded-pill">
                                {day}
                              </span>
                            </p>
                            <ul
                              className="list-unstyled chat-conversation-list"
                              id="users-conversation"
                            >
                              {groupedMessages[day].map((msg, index) => (
                                <li
                                  className={
                                    msg.senderId._id == loginAdminInfo._id
                                      ? 'chat-list right'
                                      : 'chat-list left'
                                  }
                                  key={index}
                                >
                                  {msg.senderId._id == loginAdminInfo._id ? (
                                    ''
                                  ) : (
                                    <Avatar
                                      sx={{
                                        mr: `${
                                          msg.senderId._id == loginAdminInfo._id
                                            ? '0'
                                            : '10px'
                                        }`,
                                        ml: `${
                                          msg.senderId._id == loginAdminInfo._id
                                            ? '10px'
                                            : '0'
                                        }`,
                                      }}
                                      alt={msg.senderId?.firstname?.toUpperCase()}
                                      src={
                                        msg.receiverId._id == loginAdminInfo._id
                                          ? msg?.senderId?.profile &&
                                            `${IMG_URL}${msg.senderId.profile}`
                                          : msg?.senderId?.profile &&
                                            `${IMG_URL}${msg.senderId.profile}`
                                      }
                                    />
                                  )}
                                  <div className="conversation-list">
                                    <div className="user-chat-content">
                                      {msg.content !== '' && (
                                        <div className="ctext-wrap">
                                          <div className="ctext-wrap-content">
                                            <p className="mb-0 ctext-content">
                                              {msg.content}
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                      {msg.attachments.length !== 0 && (
                                        <div className="ctext-wrap">
                                          <div className="message-img mb-0">
                                            {msg.attachments.map(
                                              (val, index) => (
                                                <div
                                                  className="message-img-list"
                                                  style={{
                                                    width:
                                                      val.type === 'image' ||
                                                      val.type === 'video'
                                                        ? 'auto'
                                                        : '100%',
                                                  }}
                                                  key={index}
                                                >
                                                  {val.type === 'image' ? (
                                                    <>
                                                      <CardMedia
                                                        component="img"
                                                        alt={val.name}
                                                        image={`${IMG_URL}${val.path}`}
                                                        title={val.name}
                                                        className="rounded border"
                                                        style={{
                                                          background:
                                                            'rgb(241, 241, 244)',
                                                        }}
                                                      />
                                                      <DownloadButton
                                                        component={Link}
                                                        target="_blank"
                                                        size="small"
                                                        to={`${IMG_URL}${val.path}`}
                                                        download
                                                      >
                                                        <Icon icon="ci:download" />
                                                      </DownloadButton>
                                                    </>
                                                  ) : val.type === 'video' ? (
                                                    <>
                                                      <div
                                                        className="rounded border overflow-hidden position-relative"
                                                        style={{
                                                          objectFit: 'cover',
                                                          background:
                                                            'rgb(241, 241, 244)',
                                                          aspectRatio: '1.2',
                                                          width: '200px',
                                                          '&>div': {
                                                            width:
                                                              '100% !important',
                                                            height:
                                                              '100% !important',
                                                          },
                                                        }}
                                                      >
                                                        <ReactPlayer
                                                          url={`${IMG_URL}${val.path}`}
                                                          title={val.name}
                                                          //controls
                                                        />
                                                        <div
                                                          className="position-absolute top-0 right-0 w-100 h-100 d-flex align-items-center justify-content-center text-white"
                                                          style={{
                                                            cursor: 'pointer',
                                                          }}
                                                          component={Link}
                                                          target="_blank"
                                                          to={`${IMG_URL}${val.path}`}
                                                          download
                                                        >
                                                          <Icon
                                                            width="30%"
                                                            icon="ph:play-circle-thin"
                                                          />
                                                        </div>
                                                        <DownloadButton
                                                          component={Link}
                                                          target="_blank"
                                                          size="small"
                                                          to={`${IMG_URL}${val.path}`}
                                                          download
                                                        >
                                                          <Icon icon="ci:download" />
                                                        </DownloadButton>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="d-flex justify-content-end">
                                                        <div
                                                          className="rounded-3 overflow-hidden position-relative p-2 top-0 right-0 d-flex align-items-center justify-content-center  
                                                      "
                                                          style={{
                                                            objectFit: 'cover',
                                                            background:
                                                              'rgb(241, 241, 244)',
                                                            aspectRatio: '2',
                                                            width: '200px',
                                                          }}
                                                          title={val.name}
                                                        >
                                                          <div className="w-100 text-left">
                                                            <span className="d-flex gap-2 align-items-center">
                                                              {val.name
                                                                .split('.')
                                                                .pop()
                                                                .toLowerCase() ===
                                                              'pdf' ? (
                                                                <Icon
                                                                  width="20px"
                                                                  // icon="bxs:file-pdf"
                                                                  icon="vscode-icons:file-type-pdf2"
                                                                />
                                                              ) : val.name
                                                                  .split('.')
                                                                  .pop()
                                                                  .toLowerCase() ===
                                                                  'docx' ||
                                                                val.name
                                                                  .split('.')
                                                                  .pop()
                                                                  .toLowerCase() ===
                                                                  'doc' ? (
                                                                <Icon
                                                                  width="20px"
                                                                  icon="fxemoji:documenttextpicture"
                                                                />
                                                              ) : val.name
                                                                  .split('.')
                                                                  .pop()
                                                                  .toLowerCase() ===
                                                                'txt' ? (
                                                                <Icon
                                                                  width="20px"
                                                                  icon="fxemoji:documentwithtext"
                                                                />
                                                              ) : val.name
                                                                  .split('.')
                                                                  .pop()
                                                                  .toLowerCase() ===
                                                                'zip' ? (
                                                                <Icon
                                                                  width="20px"
                                                                  icon="vscode-icons:file-type-zip"
                                                                />
                                                              ) : (
                                                                <Icon
                                                                  width="20px"
                                                                  icon="simple-line-icons:doc"
                                                                />
                                                              )}
                                                              {val.name
                                                                .split('.')
                                                                .pop()
                                                                .toLowerCase()}
                                                            </span>
                                                            <span className="mt-1">
                                                              {val.name}
                                                            </span>
                                                          </div>
                                                          <DownloadButton
                                                            component={Link}
                                                            target="_blank"
                                                            size="small"
                                                            to={`${IMG_URL}${val.path}`}
                                                            download
                                                          >
                                                            <Icon icon="ci:download" />
                                                          </DownloadButton>
                                                        </div>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      )}
                                      <div className="conversation-name">
                                        <small className="text-muted time">
                                          {moment(msg.createdAt).format(
                                            'hh:mm a'
                                          )}
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                        <div ref={chatContainerRef}></div>
                      </div>
                      {showBottomArrow && (
                        <IconButton
                          size="small"
                          sx={{
                            position: 'absolute',
                            right: '20px',
                            bottom: 0,
                            background: 'rgb(241, 241, 244) !important',
                            border: '1px solid rgb(241, 241, 244)',
                          }}
                          onClick={scrollDown}
                        >
                          <Icon icon="ep:arrow-down" />
                        </IconButton>
                      )}

                      <div className="chat-input-section p-3">
                        <div className="rounded-3 overflow-hidden">
                          {files.length !== 0 && (
                            <FileAttachBox>
                              <Scrollbar>
                                <List>
                                  {files.map((file, index) => (
                                    <ListItem key={index}>
                                      <div>{renderPreview(file)}</div>
                                      {/* <div>{file.preview}</div> */}
                                      <Tooltip
                                        title="Delete"
                                        placement="top"
                                        arrow
                                      >
                                        <IconButton
                                          edge="end"
                                          size="small"
                                          aria-label="delete"
                                          color="error"
                                          sx={{
                                            padding: '2px',
                                            position: 'absolute',
                                            top: '5px',
                                            right: '5px',
                                            bgcolor: '#eee',
                                            border: '1px solid #eee',
                                            fontSize: '0.875rem',
                                            margin: '0',
                                          }}
                                          onClick={() =>
                                            handleRemoveFile(index)
                                          }
                                        >
                                          <Icon icon="iconamoon:close-light" />
                                        </IconButton>
                                      </Tooltip>
                                    </ListItem>
                                  ))}
                                </List>
                              </Scrollbar>
                            </FileAttachBox>
                          )}
                          <div className="d-flex align-items-end">
                            <button
                              className="btn btn-link fs-4 py-0 ps-0 text-decoration-none border-0 chat-send file-btn"
                              {...getRootProps()}
                            >
                              <input name="msgImg" {...getInputProps()} />
                              <i className="ri-attachment-2"></i>
                            </button>
                            {AisolutionChatInputcomponent}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Dialog
              open={maxFilesPopup}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="xs"
              sx={{
                '& .MuiDialog-paper': {
                  width: '100%',
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">
                Too many files selected
              </DialogTitle>
              <DialogContent>
                <Typography variant="body2" component="p" noWrap={true}>
                  You have picked too many files. Limit is 10
                </Typography>
              </DialogContent>
              <Divider />
              <DialogActions sx={{ justifyContent: 'center' }}>
                <Button onClose={handleClose}>OK</Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openContact}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="xs"
              sx={{
                '& .MuiDialog-paper': {
                  width: '100%',
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">
                Contacts{' '}
                <Typography variant="body1" component="span">
                  ({contactList.length})
                </Typography>
              </DialogTitle>
              <Box sx={{ px: 3 }}>
                <StyledSearch
                  value={searchByContact}
                  onChange={handleFilterByContact}
                  placeholder="Search contacts"
                  startAdornment={
                    <InputAdornment position="start">
                      <Icon
                        icon="eva:search-fill"
                        sx={{ color: 'text.disabled', width: 20, height: 20 }}
                      />
                    </InputAdornment>
                  }
                />
              </Box>
              <DialogContent sx={{ height: '385px' }}>
                <List
                  id="scrollable-allcontact"
                  ref={allContactScrollRef}
                  style={{ maxHeight: 'calc(100% - 5px)', overflowY: 'auto' }}
                  sx={{ p: 0 }}
                >
                  {contactList.map((val, index) => (
                    <ListItem
                      sx={{
                        p: 0,
                      }}
                      key={index}
                    >
                      <ListItemButton
                        onClick={() => contactListHandler(val)}
                        sx={{ py: 2, px: 2, borderRadius: '14px' }}
                      >
                        <ListItemAvatar>
                          <StyledBadge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            variant="dot"
                          >
                            <Avatar
                              alt={val.firstname.toUpperCase()}
                              src={val.profile && `${IMG_URL}${val.profile}`}
                            />
                          </StyledBadge>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="subtitle2"
                              color="primary"
                              noWrap={true}
                            >
                              {val.firstname} {val.lastname}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              variant="caption"
                              component="p"
                              noWrap={true}
                            >
                              {val?.role}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
                {contactList?.length === 0 ? (
                  <DataNotAvailable title="You don't have Contacts" />
                ) : null}
              </DialogContent>
            </Dialog>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Chat;
