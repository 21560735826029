import { axiosClient } from '../utils/axiosClient';
import {
  PROPERTY_ENDPOINT,
  AUTH_ENDPOINT,
  BOOKING_ENDPOINT,
  CONTENT_ENDPOINT,
  REVIEW_ENDPOINT,
  NOTIFICATION_ENDPOINT,
  ROOM_ENDPOINT,
  MESSAGE_ENDPOINT,
  PAYMENT_ENDPOINT,
  PROPERTY_SUPERADMIN_ENDPOINT,
  ADMIN_PAYMENT_ENDPOINT,
  ADMIN_SETTING_ENDPOINT,
  ADMIN_EMAIL_TEMPLATE_ENDPOINT,
  ADMIN_USERLOGGED_ENDPOINT,
} from '../utils/constant';

export const userlogin = (payload) => {
  return axiosClient(AUTH_ENDPOINT.LOGIN, payload, 'POST');
};

export const userSignup = (payload) => {
  return axiosClient(AUTH_ENDPOINT.SINGUP, payload, 'POST');
};

export const forgotPassword = (payload) => {
  return axiosClient(AUTH_ENDPOINT.FORGOT_PASSWORD, payload, 'POST');
};

export const resetPassword = (payload) => {
  return axiosClient(AUTH_ENDPOINT.RESET_PASSWORD, payload, 'POST');
};

export const emailVerify = (payload) => {
  return axiosClient(AUTH_ENDPOINT.EMAIL_VERIFY, payload, 'POST');
};

export const userUpdateProfile = (payload) => {
  return axiosClient(AUTH_ENDPOINT.UPDATE_PROFILE, payload, 'POST');
};

export const userChangePassword = (payload) => {
  return axiosClient(AUTH_ENDPOINT.CHANGE_PASSWORD, payload, 'POST');
};

export const contactUs = (payload) => {
  return axiosClient(AUTH_ENDPOINT.CONTACT_US, payload, 'POST');
};

export const addToFavorite = (payload) => {
  return axiosClient(AUTH_ENDPOINT.ADD_FAVORITE, payload, 'POST');
};

export const favoriteList = (payload) => {
  return axiosClient(
    `${AUTH_ENDPOINT.FAVORITE_LIST}/${payload.currentPage}`,
    payload,
    'GET'
  );
};

export const addProperties = (payload) => {
  return axiosClient(PROPERTY_ENDPOINT.ADD_PROPERTY, payload, 'POST');
};

export const myProperties = (payload) => {
  return axiosClient(PROPERTY_ENDPOINT.MY_PROPERTY, payload, 'POST');
};

export const getProperty = (payload) => {
  return axiosClient(PROPERTY_ENDPOINT.GET_PROPERTY, payload, 'POST');
};
export const getPropertyForEdit = (payload) => {
  return axiosClient(PROPERTY_ENDPOINT.GET_PROPERTY_FOR_EDIT, payload, 'POST');
};

export const updateProperty = (payload) => {
  return axiosClient(PROPERTY_ENDPOINT.EDIT_PROPERTY, payload, 'POST');
};

export const getAllProperty = (payload) => {
  return axiosClient(PROPERTY_ENDPOINT.ALL_PROPERTY, payload, 'POST');
};

export const fetchAllCities = (payload) => {
  return axiosClient(PROPERTY_ENDPOINT.FETCH_CITIES, '', 'GET');
};
export const deleteProperty = (payload) => {
  return axiosClient(PROPERTY_ENDPOINT.DELETE_PROPERTY, payload, 'POST');
};

export const getPopularSpaces = (payload) => {
  return axiosClient(PROPERTY_ENDPOINT.POPULAR_SPACES, payload, 'POST');
};

export const fetchRulesAndAmenities = () => {
  return axiosClient(PROPERTY_ENDPOINT.GET_RULES_AMENITIES, '', 'GET');
};

export const bookProperty = (payload) => {
  return axiosClient(BOOKING_ENDPOINT.BOOK_PROPERTY, payload, 'POST');
};

export const applyListingData = (payload) => {
  return axiosClient(BOOKING_ENDPOINT.MY_APPLY_LISTING, payload, 'POST');
};

export const bookingDetails = (payload) => {
  return axiosClient(BOOKING_ENDPOINT.BOOKING_DETAILS, payload, 'POST');
};

export const bookingRequest = (payload) => {
  return axiosClient(BOOKING_ENDPOINT.BOOKING_REQUEST, payload, 'POST');
};

export const manageRequest = (payload) => {
  return axiosClient(BOOKING_ENDPOINT.MANAGE_REQUEST, payload, 'POST');
};

export const getUnitToPay = (payload) => {
  return axiosClient(BOOKING_ENDPOINT.UNIT_TO_PAY, payload, 'POST');
};

export const unitHistoryList = (payload) => {
  return axiosClient(
    `${BOOKING_ENDPOINT.UNIT_HISTORY_LIST}/${payload.currentPage}`,
    {},
    'GET'
  );
};

export const getHomeContent = () => {
  return axiosClient(CONTENT_ENDPOINT.HOME_CONTENT, '', 'GET');
};

export const getAboutContent = () => {
  return axiosClient(CONTENT_ENDPOINT.ABOUT_CONTENT, '', 'GET');
};

export const getBecomeHostContent = () => {
  return axiosClient(CONTENT_ENDPOINT.BECOME_HOST_CONTENT, '', 'GET');
};

export const getAvailableSpacesContent = () => {
  return axiosClient(CONTENT_ENDPOINT.AVAILABLE_SPACES_CONTENT, '', 'GET');
};

export const getTopRatedCities = (payload) => {
  return axiosClient(PROPERTY_ENDPOINT.TOP_RATED_CITIES, payload, 'GET');
};

export const addReview = (payload) => {
  return axiosClient(REVIEW_ENDPOINT.ADD_REVIEW, payload, 'POST');
};
export const notificationList = (payload) => {
  return axiosClient(
    `${NOTIFICATION_ENDPOINT.NOTIFICATION_LIST}/${payload.userId}`,
    {},
    'GET'
  );
};
export const notificationRead = (payload) => {
  return axiosClient(
    `${NOTIFICATION_ENDPOINT.NOTIFICATION_READ}/${payload.notificationId}`,
    {},
    'GET'
  );
};
export const notificationDelete = (notificationId) => {
  return axiosClient(
    `${NOTIFICATION_ENDPOINT.NOTIFICATION_DELETE}/${notificationId}`,
    {},
    'DELETE'
  );
};
export const notificationDeleteAll = () => {
  return axiosClient(
    `${NOTIFICATION_ENDPOINT.NOTIFICATION_DELETE_ALL}`,
    {},
    'DELETE'
  );
};
export const getMemberList = () => {
  return axiosClient(`${ROOM_ENDPOINT.MEMEBER_LIST}`, {}, 'GET');
};
export const getContactListForChat = (payload) => {
  return axiosClient(`${ROOM_ENDPOINT.GET_CONTACT_LIST}`, payload, 'POST');
};

export const getMessageList = (payload) => {
  return axiosClient(
    `${MESSAGE_ENDPOINT.MESSAGE_LIST}/${payload.roomId}?page=${payload.pageNumber}`,
    {},
    'GET'
  );
};
export const readMessages = (read) => {
  return axiosClient(`${MESSAGE_ENDPOINT.MESSAGE_READ}`, read, 'POST');
};
export const sendMessages = (payload) => {
  return axiosClient(`${MESSAGE_ENDPOINT.MESSAGE_SEND}`, payload, 'POST');
};
export const createRoom = (payload) => {
  return axiosClient(`${ROOM_ENDPOINT.ROOM_CREATE}`, payload, 'POST');
};

export const addPaymentCard = (payload) => {
  return axiosClient(`${PAYMENT_ENDPOINT.ADD_PAYMENT_CARD}`, payload, 'POST');
};

export const getPaymentCard = (payload) => {
  return axiosClient(
    `${PAYMENT_ENDPOINT.GET_PAYMENT_CARD}?currentPage=${payload.currentPage}`,
    {},
    'GET'
  );
};

export const getCardForEdit = (payload) => {
  return axiosClient(
    `${PAYMENT_ENDPOINT.GET_CARD_FOR_UPDATE}`,
    payload,
    'POST'
  );
};

export const deletePaymentCard = (payload) => {
  return axiosClient(
    `${PAYMENT_ENDPOINT.DELETE_PAYMENT_CARD}`,
    payload,
    'DELETE'
  );
};

export const setPaymentCardAsPrimary = (payload) => {
  return axiosClient(`${PAYMENT_ENDPOINT.SET_CARD_AS_PRIMARY}`, payload, 'PUT');
};

//mono
export const setBankPrimary = (payload) => {
  return axiosClient(`${PAYMENT_ENDPOINT.SET_BANK_AS_PRIMARY}`, payload, 'PUT');
};

//mono

export const getBankList = () => {
  return axiosClient(`${PAYMENT_ENDPOINT.GET_BANK_LIST}`, {}, 'GET');
};

export const getBankAccounts = (payload) => {
  `${PAYMENT_ENDPOINT.GET_BANK_ACCOUNT}?search=${payload.search}&&currentPage=${payload.currentPage}`;
  return axiosClient(
    `${PAYMENT_ENDPOINT.GET_BANK_ACCOUNT}?currentPage=${payload.currentPage}`,
    {},
    'GET'
  );
};

export const deleteBankAccount = (payload) => {
  return axiosClient(
    `${PAYMENT_ENDPOINT.DELETE_BANK_ACCOUNT}`,
    payload,
    'DELETE'
  );
};

export const addBankAccount = (payload) => {
  return axiosClient(`${PAYMENT_ENDPOINT.ADD_BANK_ACCOUNT}`, payload, 'POST');
};

export const createTransaction = (payload) => {
  return axiosClient(`${PAYMENT_ENDPOINT.CREATE_TRANSACTION}`, payload, 'POST');
};

export const tokenizedCard = (payload) => {
  return axiosClient(`${PAYMENT_ENDPOINT.TOKENIZED_CARD}`, payload, 'POST');
};

// link user's bank account on mono
export const tokenizedBank = (payload) => {
  return axiosClient(`${PAYMENT_ENDPOINT.TOKENIZED_BANK}`, payload, 'POST');
};
//--------------------mono start----------------
export const verifyTransactionMonoPayment = (payload) => {
  return axiosClient(
    `${PAYMENT_ENDPOINT.CREATE_TRANSACTION_MONO_PAYMENT}`,
    payload,
    'POST'
  );
};
//--------------------mono end
//admin panel
export const adminLogin = (payload) => {
  return axiosClient(`${CONTENT_ENDPOINT.ADMIN_LOGIN}`, payload, 'POST');
};

export const allCountBasedOnRole = () => {
  return axiosClient(`${CONTENT_ENDPOINT.ALL_COUNT_ROLEBASED}`, '', 'GET');
};

export const allCountUser = () => {
  return axiosClient(`${CONTENT_ENDPOINT.ALL_USERS_COUNT}`, '', 'GET');
};

export const getAllUsers = (payload) => {
  return axiosClient(`${CONTENT_ENDPOINT.ALL_USERS}`, payload, 'POST');
};

export const createAdminUser = (payload) => {
  return axiosClient(`${CONTENT_ENDPOINT.CREATE_ADMIN_USER}`, payload, 'POST');
};

export const deleteParticularUser = (payload) => {
  return axiosClient(
    `${CONTENT_ENDPOINT.DELETE_PARTICULAR_USER}`,
    payload,
    'DELETE'
  );
};

export const deletePropertyFromAdmin = (payload) => {
  return axiosClient(
    `${PROPERTY_SUPERADMIN_ENDPOINT.DELETE_PROPERTY}`,
    payload,
    'DELETE'
  );
};

export const activeInactiveParticularUser = (payload) => {
  return axiosClient(
    `${CONTENT_ENDPOINT.ACTIVE_INACTIVE_PARTICULAR_USER}`,
    payload,
    'PUT'
  );
};

export const fetchParticularUser = (payload) => {
  return axiosClient(
    `${CONTENT_ENDPOINT.FETCH_PARTICULAR_USER}`,
    payload,
    'POST'
  );
};

export const updateUser = (payload) => {
  return axiosClient(`${CONTENT_ENDPOINT.UPDATE_USER}`, payload, 'PUT');
};

export const getAllPropertyUser = (payload) => {
  return axiosClient(
    `${PROPERTY_SUPERADMIN_ENDPOINT.FETCH_ALL_PROPERTY}`,
    payload,
    'POST'
  );
};

export const getAllUnitHistory = (payload) => {
  return axiosClient(
    `${PROPERTY_SUPERADMIN_ENDPOINT.FETCH_ALL_UNIT_HISTORY}`,
    payload,
    'POST'
  );
};

export const getAllPropertyList = (payload) => {
  return axiosClient(
    `${PROPERTY_SUPERADMIN_ENDPOINT.GET_ALL_PROPERTIES_LIST}`,
    payload,
    'POST'
  );
};
export const getAllBookedProperties = (payload) => {
  return axiosClient(
    `${PROPERTY_SUPERADMIN_ENDPOINT.GET_BOOKED_PROPERTIES_LIST}`,
    payload,
    'POST'
  );
};
export const getAllAvailableProperties = (payload) => {
  return axiosClient(
    `${PROPERTY_SUPERADMIN_ENDPOINT.GET_AVAILABLE_PROPERTIES_LIST}`,
    payload,
    'POST'
  );
};

export const userUpdateProfileSuperAdmin = (payload) => {
  return axiosClient(
    `${CONTENT_ENDPOINT.UPDATE_PROFILE_SUPERADMIN}`,
    payload,
    'POST'
  );
};

export const changePasswordSuperAdmin = (payload) => {
  return axiosClient(
    `${CONTENT_ENDPOINT.UPDATE_PASSWORD_SUPERADMIN}`,
    payload,
    'POST'
  );
};

export const getLogo = () => {
  return axiosClient(`${CONTENT_ENDPOINT.GET_LOGO}`, '', 'GET');
};

export const updateLogo = (payload) => {
  return axiosClient(`${CONTENT_ENDPOINT.UPDATE_LOGO}`, payload, 'POST');
};

export const getFirstFieldContactUS = () => {
  return axiosClient(`${CONTENT_ENDPOINT.GET_FIRST_CONTACTUS}`, '', 'GET');
};

export const updateFirstFieldContactUS = (payload) => {
  return axiosClient(
    `${CONTENT_ENDPOINT.UPDATE_FIRST_CONTACTUS}`,
    payload,
    'POST'
  );
};

export const getSecondFieldContactUS = () => {
  return axiosClient(`${CONTENT_ENDPOINT.GET_SECOND_CONTACTUS}`, '', 'GET');
};

export const updateSecondFieldContactUS = (payload) => {
  return axiosClient(
    `${CONTENT_ENDPOINT.UPDATE_SECOND_CONTACTUS}`,
    payload,
    'POST'
  );
};

export const getThirdFieldContactUS = () => {
  return axiosClient(`${CONTENT_ENDPOINT.GET_THIRD_CONTACTUS}`, '', 'GET');
};

export const updateThirdFieldContactUS = (payload) => {
  return axiosClient(
    `${CONTENT_ENDPOINT.UPDATE_THIRD_CONTACTUS}`,
    payload,
    'POST'
  );
};

export const getFourthFieldContactUS = () => {
  return axiosClient(`${CONTENT_ENDPOINT.GET_FOURTH_CONTACTUS}`, '', 'GET');
};

export const updateFourthFieldContactUS = (payload) => {
  return axiosClient(
    `${CONTENT_ENDPOINT.UPDATE_FOURTH_CONTACTUS}`,
    payload,
    'POST'
  );
};

export const getEmailConfigure = () => {
  return axiosClient(`${CONTENT_ENDPOINT.GET_EMAIL_CONFIGURE}`, '', 'GET');
};

export const updateEmailConfigure = (payload) => {
  return axiosClient(
    `${CONTENT_ENDPOINT.UPDATE_EMAIL_CONFIGURE}`,
    payload,
    'POST'
  );
};

export const getSocialMediaLink = () => {
  return axiosClient(`${CONTENT_ENDPOINT.GET_SOCIAL_MEDIA_LINK}`, '', 'GET');
};

export const updateSocialMediaLink = (payload) => {
  return axiosClient(
    `${CONTENT_ENDPOINT.UPDATE_SOCIAL_MEDIA_LINK}`,
    payload,
    'POST'
  );
};

export const getLogoCommon = () => {
  return axiosClient(`${CONTENT_ENDPOINT.GET_LOGO_COMMON}`, '', 'GET');
};

export const getModules = () => {
  return axiosClient(`${CONTENT_ENDPOINT.GET_MODULES}`, '', 'GET');
};
export const getRoles = () => {
  return axiosClient(`${CONTENT_ENDPOINT.GET_ROLES}`, '', 'GET');
};
export const createRoles = (payload) => {
  return axiosClient(`${CONTENT_ENDPOINT.CREATE_ROLES}`, payload, 'POST');
};

export const deleteRoles = (payload) => {
  return axiosClient(`${CONTENT_ENDPOINT.DELETE_ROLES}`, payload, 'POST');
};

export const allCountProperties = () => {
  return axiosClient(
    `${PROPERTY_SUPERADMIN_ENDPOINT.PROPERTIES_COUNT}`,
    '',
    'GET'
  );
};

export const allCountBasedOnProperty = () => {
  return axiosClient(
    `${PROPERTY_SUPERADMIN_ENDPOINT.ALL_COUNT_PROPERTYBASED}`,
    '',
    'GET'
  );
};
export const getAllRecentBookedProperty = (payload) => {
  return axiosClient(
    `${PROPERTY_SUPERADMIN_ENDPOINT.ALL_RECENT_BOOKED_PROPERTY}`,
    payload,
    'POST'
  );
};
export const getSocialMediaLinkCommon = () => {
  return axiosClient(
    `${CONTENT_ENDPOINT.GET_SOCIALMEDIA_LINK_COMMON}`,
    '',
    'GET'
  );
};

export const emailVerifyAdminUser = (payload) => {
  return axiosClient(
    `${CONTENT_ENDPOINT.EMAIL_VERIFY_ADMIN_USER}`,
    payload,
    'POST'
  );
};

export const fetchParticularUserWithModules = (payload) => {
  return axiosClient(
    `${CONTENT_ENDPOINT.FETCH_PARTICULAR_USER_MODULES}`,
    payload,
    'POST'
  );
};

//property acitve inactive
export const activeInactiveParticularProperty = (payload) => {
  return axiosClient(
    `${PROPERTY_SUPERADMIN_ENDPOINT.ACTIVE_INACTIVE_PARTICULAR_PROPERTY}`,
    payload,
    'POST'
  );
};
//----admin panel end----
export const getTransactionHistory = (payload) => {
  return axiosClient(
    `${PAYMENT_ENDPOINT.GET_TRANSACTION_HISTORY}`,
    payload,
    'POST'
  );
};

export const sendWithdrawRequest = (payload) => {
  return axiosClient(
    `${PAYMENT_ENDPOINT.SEND_WITHDRAW_REQUEST}`,
    payload,
    'POST'
  );
};

export const getAllWithdrawRequest = (payload) => {
  return axiosClient(
    `${ADMIN_PAYMENT_ENDPOINT.FETCH_WITHDRAW_REQUEST_LIST}`,
    payload,
    'POST'
  );
};

// faq's API"s
export const getFaqsList = (payload) => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.FETCH_FAQS_LIST}?search=${payload.search}&&currentPage=${payload.currentPage}`,
    {},
    'GET'
  );
};

export const addOrEditFaqs = (payload) => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.ADD_OR_EDIT_FAQS}`,
    payload,
    'POST'
  );
};
export const getFaqForEdit = (payload) => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.GET_FAQ_FOR_EDIT}`,
    payload,
    'POST'
  );
};

export const deleteFaqs = (payload) => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.DELETE_FAQS}`,
    payload,
    'DELETE'
  );
};

//amenities crud API"S
export const getAmenitiesList = (payload) => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.FETCH_AMENITIES_LIST}?search=${payload.search}&&currentPage=${payload.currentPage}`,
    {},
    'GET'
  );
};

export const addOrEditAmenities = (payload) => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.ADD_OR_EDIT_AMENITIES}`,
    payload,
    'POST'
  );
};

export const deleteAmenities = (payload) => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.DELETE_AMENITIES}`,
    payload,
    'DELETE'
  );
};

//contact us newly start----

export const getContactUsList = () => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.FETCH_CONTACTUS_LIST}`,
    {},
    'GET'
  );
};

export const addOrEditContactUs = (payload) => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.ADD_OR_EDIT_CONTACTUS}`,
    payload,
    'POST'
  );
};

export const deleteContactUs = (payload) => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.DELETE_CONTACTUS}`,
    payload,
    'DELETE'
  );
};

//contact us end newly

// manage rules from admin side
export const getRulesList = (payload) => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.FETCH_RULES_LIST}?search=${payload.search}&&currentPage=${payload.currentPage}`,
    {},
    'GET'
  );
};

export const addOrEditRules = (payload) => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.ADD_OR_EDIT_RULES}`,
    payload,
    'POST'
  );
};

export const deleteRules = (payload) => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.DELETE_RULES}`,
    payload,
    'DELETE'
  );
};

export const getFeaturedCities = (payload) => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.GET_FEATURED_CITIES}?currentPage=${payload.currentPage}&&search=${payload.search}`,
    {},
    'GET'
  );
};

export const addOrRemoveFromFeaturedCities = (payload) => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.ADD_OR_REMOVE_FEATURED_CITIES}`,
    payload,
    'POST'
  );
};

export const changeCityImage = (payload) => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.CHANGE_CITY_IMAGE}`,
    payload,
    'POST'
  );
};

//review section api's
export const getReviewsList = (payload) => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.GET_REVIEW_LIST}?search=${payload.search}&&currentPage=${payload.currentPage}`,
    {},
    'GET'
  );
};

export const addOrEditReviews = (payload) => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.ADD_OR_REMOVE_REVIEWS}`,
    payload,
    'POST'
  );
};

export const deleteReviews = (payload) => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.DELETE_REVIEWS}`,
    payload,
    'DELETE'
  );
};

export const getAllTransactionList = (payload) => {
  return axiosClient(
    `${ADMIN_PAYMENT_ENDPOINT.FETCH_ALL_TRANSACTION_LIST}`,
    payload,
    'POST'
  );
};

export const savedAsPopularPlaces = (payload) => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.SAVED_AS_POPULAR}`,
    payload,
    'PUT'
  );
};

export const removeFromPopularPlaces = (payload) => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.REMOVE_FROM_POPULAR}`,
    payload,
    'PUT'
  );
};

export const getAllCities = () => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.GET_ALL_CITIES_FOR_MAP}`,
    {},
    'GET'
  );
};

export const getPopularSpacesList = (payload) => {
  return axiosClient(
    `${ADMIN_SETTING_ENDPOINT.POPULAR_SPACES_LIST}?currentPage=${payload.currentPage}&&search=${payload.search}`,
    {},
    'GET'
  );
};

export const getAllEmailTemplatesList = () => {
  return axiosClient(
    `${ADMIN_EMAIL_TEMPLATE_ENDPOINT.FETCH_EMAIL_TEMPLATE_LIST}`,
    {},
    'GET'
  );
};

export const editEmailTemplates = (payload) => {
  return axiosClient(
    `${ADMIN_EMAIL_TEMPLATE_ENDPOINT.UPDATE_EMAIL_TEMPLATE}`,
    payload,
    'PUT'
  );
};

//cms crud
export const getCmsHomeContent = (payload) => {
  return axiosClient(
    `${CONTENT_ENDPOINT.CMS_HOME_CONTENT_GET_DATA}`,
    payload,
    'POST'
  );
};

export const addOrEditHomeContent = (payload) => {
  return axiosClient(
    `${CONTENT_ENDPOINT.CMS_HOME_CONTENT_ADD_UPDATE}`,
    payload,
    'POST'
  );
};
export const getCmsContent = (payload) => {
  return axiosClient(CONTENT_ENDPOINT.GET_CMS_CONTENT, payload, 'POST');
};

//about us info
export const getAboutUsList = () => {
  return axiosClient(CONTENT_ENDPOINT.GET_ABOUTUS_INFO_CONTENT, '', 'GET');
};

export const addOrEditAboutUs = (payload) => {
  return axiosClient(
    `${CONTENT_ENDPOINT.UPDATE_ABOUTUS_INFO_CONTENT}`,
    payload,
    'POST'
  );
};

//how it works
export const getHowItWorksList = () => {
  return axiosClient(CONTENT_ENDPOINT.GET_HOWITWORKS_CONTENT, '', 'GET');
};

export const addOrEditHowItWorks = (payload) => {
  return axiosClient(
    `${CONTENT_ENDPOINT.UPDATE_HOWITWORKS_CONTENT}`,
    payload,
    'POST'
  );
};
//user logged
export const addUserLogged = (payload) => {
  return axiosClient(
    `${ADMIN_USERLOGGED_ENDPOINT.ADD_USERLOGGED}`,
    payload,
    'POST'
  );
};

export const getAllUsersLogged = (payload) => {
  return axiosClient(
    `${ADMIN_USERLOGGED_ENDPOINT.GET_USERLOGGED}`,
    payload,
    'POST'
  );
};
