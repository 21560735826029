import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// ----------------------------------------------------------------------

const StyledContent = styled(Box)(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export const Error = () => {
  let query = useQuery();
  return (
    <>
      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Something went wrong
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            {/* Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
            mistyped the URL? Be sure to check your spelling. */}
            {query.get('msg')}
          </Typography>

          <Box sx={{ height: 50, mx: 'auto', my: { xs: 5, sm: 10 } }} />

          <Button
            to={query.get('path')}
            size="large"
            variant="contained"
            component={RouterLink}
          >
            Retry
          </Button>
        </StyledContent>
      </Container>
    </>
  );
};
