import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          // textTransform: 'capitalize',
          textTransform: 'inherit',
          boxShadow: 'none',
          borderRadius: Number(theme.shape.borderRadius) * 3.5,
          fontWeight: '600',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeSmall: {},
        sizeMedium: {},
        sizeLarge: {
          height: 54,
          padding: '8px 40px',
        },
        text: {
          color: `${alpha(theme.palette.primary.main, 1)} !important`,
        },
        textInherit: {
          color: `${alpha(theme.palette.primary.main, 1)} !important`,
        },
        textPrimary: {
          color: `${alpha(theme.palette.primary.main, 1)} !important`,
        },
        textSecondary: {
          color: `${alpha(theme.palette.secondary.main, 1)} !important`,
        },
        textSuccess: {
          color: `${alpha(theme.palette.success.main, 1)} !important`,
        },
        textError: {
          color: `${alpha(theme.palette.error.main, 1)} !important`,
        },
        textInfo: {
          color: `${alpha(theme.palette.info.main, 1)} !important`,
        },
        textWarning: {
          color: `${alpha(theme.palette.warning.main, 1)} !important`,
        },
        outlined: {
          color: `${alpha(theme.palette.primary.main, 1)} !important`,
        },
        outlinedInherit: {
          color: `${alpha(theme.palette.primary.main, 1)} !important`,
        },
        outlinedSecondary: {
          color: `${alpha(theme.palette.secondary.main, 1)} !important`,
        },
        outlinedSuccess: {
          color: `${alpha(theme.palette.success.main, 1)} !important`,
        },
        outlinedError: {
          color: `${alpha(theme.palette.error.main, 1)} !important`,
        },
        outlinedInfo: {
          color: `${alpha(theme.palette.info.main, 1)} !important`,
        },
        outlinedWarning: {
          color: `${alpha(theme.palette.warning.main, 1)} !important`,
        },
        contained: {
          color: `${alpha(theme.palette.grey[0], 1)} !important`,
        },
        containedInherit: {
          color: `${alpha(theme.palette.grey[0], 1)} !important`,
        },
        containedPrimary: {
          color: `${alpha(theme.palette.grey[0], 1)} !important`,
        },
        containedSecondary: {
          color: `${alpha(theme.palette.grey[0], 1)} !important`,
        },
        containedSuccess: {
          color: `${alpha(theme.palette.grey[0], 1)} !important`,
        },
        containedInfo: {
          color: `${alpha(theme.palette.grey[0], 1)} !important`,
        },
        containedError: {
          color: `${alpha(theme.palette.grey[0], 1)} !important`,
        },
        containedWarning: {
          color: `${alpha(theme.palette.grey[0], 1)} !important`,
        },
        soft: {
          color: `${alpha(theme.palette.primary.main, 1)} !important`,
          backgroundColor: alpha(theme.palette.primary.main, 0.08),
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.24),
          },
        },
        softInherit: {
          color: `${alpha(theme.palette.primary.main, 1)} !important`,
          backgroundColor: alpha(theme.palette.primary.main, 0.08),
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.24),
          },
        },
        softPrimary: {
          color: `${alpha(theme.palette.primary.main, 1)} !important`,
          backgroundColor: alpha(theme.palette.primary.main, 0.16),
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.32),
          },
        },
        softSecondary: {
          color: `${alpha(theme.palette.secondary.main, 1)} !important`,
          backgroundColor: alpha(theme.palette.secondary.main, 0.16),
          '&:hover': {
            backgroundColor: alpha(theme.palette.secondary.main, 0.32),
          },
        },
        softSuccess: {
          color: `${alpha(theme.palette.success.main, 1)} !important`,
          backgroundColor: alpha(theme.palette.success.main, 0.16),
          '&:hover': {
            backgroundColor: alpha(theme.palette.success.main, 0.32),
          },
        },
        softInfo: {
          color: `${alpha(theme.palette.info.main, 1)} !important`,
          backgroundColor: alpha(theme.palette.info.main, 0.16),
          '&:hover': {
            backgroundColor: alpha(theme.palette.info.main, 0.32),
          },
        },
        softError: {
          color: `${alpha(theme.palette.error.main, 1)} !important`,
          backgroundColor: alpha(theme.palette.error.main, 0.16),
          '&:hover': {
            backgroundColor: alpha(theme.palette.error.main, 0.32),
          },
        },
        softWarning: {
          color: `${alpha(theme.palette.warning.main, 1)} !important`,
          backgroundColor: alpha(theme.palette.warning.main, 0.16),
          '&:hover': {
            backgroundColor: alpha(theme.palette.warning.main, 0.32),
          },
        },
        softOutlined: {
          color: `${alpha(theme.palette.primary.main, 1)} !important`,
          backgroundColor: alpha(theme.palette.primary.main, 0.04),
          border: `1px solid ${alpha(theme.palette.primary.main, 1)}`,
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.24),
          },
        },
        softInheritOutlined: {
          color: `${alpha(theme.palette.primary.main, 1)} !important`,
          backgroundColor: alpha(theme.palette.primary.main, 0.04),
          border: `1px solid ${alpha(theme.palette.primary.main, 1)}`,
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.24),
          },
        },
        softPrimaryOutlined: {
          color: `${alpha(theme.palette.primary.main, 1)} !important`,
          backgroundColor: alpha(theme.palette.primary.main, 0.04),
          border: `1px solid ${alpha(theme.palette.primary.main, 1)}`,
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.32),
          },
        },
        softSecondaryOutlined: {
          color: `${alpha(theme.palette.secondary.main, 1)} !important`,
          backgroundColor: alpha(theme.palette.secondary.main, 0.04),
          border: `1px solid ${alpha(theme.palette.secondary.main, 1)}`,
          '&:hover': {
            backgroundColor: alpha(theme.palette.secondary.main, 0.32),
          },
        },
        softSuccessOutlined: {
          color: `${alpha(theme.palette.success.main, 1)} !important`,
          backgroundColor: alpha(theme.palette.success.main, 0.04),
          border: `1px solid ${alpha(theme.palette.success.main, 1)}`,
          '&:hover': {
            backgroundColor: alpha(theme.palette.success.main, 0.32),
          },
        },
        softInfoOutlined: {
          color: `${alpha(theme.palette.info.main, 1)} !important`,
          backgroundColor: alpha(theme.palette.info.main, 0.04),
          border: `1px solid ${alpha(theme.palette.info.main, 1)}`,
          '&:hover': {
            backgroundColor: alpha(theme.palette.info.main, 0.32),
          },
        },
        softErrorOutlined: {
          color: `${alpha(theme.palette.error.main, 1)} !important`,
          backgroundColor: alpha(theme.palette.error.main, 0.04),
          border: `1px solid ${alpha(theme.palette.error.main, 1)}`,
          '&:hover': {
            backgroundColor: alpha(theme.palette.error.main, 0.32),
          },
        },
        softWarningOutlined: {
          color: `${alpha(theme.palette.warning.main, 1)} !important`,
          backgroundColor: alpha(theme.palette.warning.main, 0.04),
          border: `1px solid ${alpha(theme.palette.warning.main, 1)}`,
          '&:hover': {
            backgroundColor: alpha(theme.palette.warning.main, 0.32),
          },
        },
      },
    },
  };
}
