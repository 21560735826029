import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { PropertyCard } from '../../components/property-card';
import Intersect from '../../assets/images/Intersect.png';
import ArrowIconLeft from '../../assets/icons/ArrowIconLeft.svg';
import ArrowIconRight from '../../assets/icons/ArrowIconRight.svg';
import Slider from 'react-slick';
import { getPopularSpaces } from '../../services/apiServices';
import { DialogPopup } from '../../components/dialog-popup/DialogPopup';
import { useAuthStore } from '../../redux/features/authSlice';

const StylePopular = styled(Box)(({ theme }) => ({
  background: `#FAFAFA url(${Intersect}) no-repeat center center / 100% 60%`,
  padding: '50px 0',
  [theme.breakpoints.down('lg')]: {
    padding: '56px 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: '40px 0',
  },
}));

const BannerLeft = styled(Box)(({ theme }) => ({
  // maxWidth: '520px',
}));

const CarouselDiv = styled(Box)(({ theme }) => ({
  '& .slick-slider': {
    '& .slick-dots': {
      bottom: '0',
    },
    '& .slick-arrow': {
      fontSize: '0',
      width: '40px',
      height: '40px',
      zIndex: '1',
      transition: '0.45s ease-in-out',
      borderRadius: '50%',
      marginTop: '-30px',
      '&:before': {
        fontSize: '0',
      },
      '&.slick-prev': {
        background: `rgba(255,255,255,0.8) url(${ArrowIconLeft}) no-repeat center center / cover`,
        left: '0',
        [theme.breakpoints.up('xl')]: {
          left: '-30px',
        },
      },
      '&.slick-next': {
        background: `rgba(255,255,255,0.8) url(${ArrowIconRight}) no-repeat center center / cover`,
        right: '0',
        [theme.breakpoints.up('xl')]: {
          right: '-30px',
        },
      },
      '&.slick-disabled': {
        opacity: '0.5',
      },
    },
  },
}));

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        arrows: false,
        dots: true,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
        arrows: false,
        dots: true,
      },
    },
  ],
};
export const PopularSpacesSection = (props) => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  let { loginInfo } = useAuthStore();
  let {
    availableSpacesContents,
    availRenderFlag,
    setAvailRenderFlag,
    setSpinFlag,
    exploreRentals,
  } = props;
  const [popularSpaces, setPopularSpaces] = useState();
  // const [renderFlag, setRenderFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [statusCode, setStatusCode] = useState();
  const fetchPopularSpaces = async () => {
    try {
      setSpinFlag(true);
      let user_id = loginInfo ? loginInfo._id : null;
      let payload = { user_id: user_id };
      const { data } = await getPopularSpaces(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setPopularSpaces(data.popularSpaces);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };
  useEffect(() => {
    fetchPopularSpaces();
  }, [availRenderFlag]);

  return (
    <>
      <DialogPopup
        open={open}
        setOpen={setOpen}
        message={message}
        size="xs"
        statusCode={statusCode}
      />
      <StylePopular>
        <Container fixed={true} sx={{ maxWidth: { xl: '1326px' } }}>
          <Box>
            <BannerLeft>
              <Box sx={{ mb: 5 }}>
                {exploreRentals && exploreRentals.length > 0 && (
                  <>
                    <Typography variant="button" component="p" sx={{ mb: 2 }}>
                      {exploreRentals[0].subTitle}
                    </Typography>
                    <Typography variant="h2" sx={{ mb: 4 }}>
                      {exploreRentals[0].title}
                    </Typography>
                  </>
                )}
              </Box>
            </BannerLeft>
          </Box>
          <Box
            sx={{
              '& .owl-dots .owl-dot': {
                '& span': {
                  background: '#EFEFEF !important',
                },
                '&.active': {
                  '& span': {
                    background: '#FEAE01 !important',
                  },
                },
              },
            }}
          >
            <CarouselDiv>
              {popularSpaces && (
                <Slider {...settings}>
                  {popularSpaces.map((value, index) => (
                    <Box
                      key={index}
                      sx={{ px: 2, pb: { lg: 0, xs: 4 } }}
                      className="sliderItem"
                    >
                      <PropertyCard
                        renderFlag={availRenderFlag}
                        setRenderFlag={setAvailRenderFlag}
                        propertyValues={value}
                      />
                    </Box>
                  ))}
                </Slider>
              )}
            </CarouselDiv>
          </Box>
        </Container>
      </StylePopular>
    </>
  );
};
