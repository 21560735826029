import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Input } from 'reactstrap';
import DatatablesCom from '../../Components/Tables/DataTables/DatatablesCom';

import DeleteModal from '../../Components/Common/DeleteModal';
import { Link } from 'react-router-dom';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Components/Common/Loader';

const customStyles = {
  rows: {
    style: {
      // minHeight: '72px',
    },
  },
  headCells: {
    style: {
      padding: '8px',
      background: '#f3f6f9',
      fontWeight: 'bold',
      color: '#878a99',
    },
  },
  cells: {
    style: {
      padding: '8px',
    },
  },
};

const TransactionList = (props) => {
  let { data } = props;
  const [activeTab, setActiveTab] = useState('1');
  const [isEdit, setIsEdit] = useState(false);
  const [customer, setCustomer] = useState([]);

  // Delete customer
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCustomer(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  // Delete Data
  const onClickDelete = (customer) => {
    setCustomer(customer);
    setDeleteModal(true);
  };

  // Delete Data
  const handleDeleteCustomer = () => {
    if (customer) {
      setDeleteModal(false);
    }
  };

  // Add Data
  const handleCustomerClick = () => {
    setCustomer('');
    setIsEdit(false);
    toggle();
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById('checkBoxAll');
    const ele = document.querySelectorAll('.customerCheckBox');

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
  const deleteMultiple = () => {
    const checkall = document.getElementById('checkBoxAll');
    selectedCheckBoxDelete.forEach((element) => {
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll('.customerCheckBox:checked');
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const columns = [
    {
      name: (
        <input
          type="checkbox"
          id="checkBoxAll"
          className="form-check-input"
          onClick={() => checkedAll()}
        />
      ),
      width: '50px',
      cell: (cellProps) => (
        <input
          type="checkbox"
          className="customerCheckBox form-check-input"
          onChange={() => deleteCheckbox()}
        />
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">ID</span>,
      sortable: true,
      cell: (row) => {
        return <div className="font-weight-bold">{row.invoiceId}</div>;
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Customer</span>,
      sortable: true,
      cell: (row) => {
        return (
          <div className="d-flex align-items-center">
            {row.img ? (
              <img
                src={process.env.REACT_APP_API_URL + '/images/users/' + row.img}
                alt=""
                className="avatar-xs rounded-circle me-2"
              />
            ) : (
              <div className="flex-shrink-0 avatar-xs me-2">
                <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                  {row.name.charAt(0) +
                    row.name.split(' ').slice(-1).toString().charAt(0)}
                </div>
              </div>
            )}
            {row.name}
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      sortable: true,
      selector: (row) => row.email,
    },
    {
      name: <span className="font-weight-bold fs-13">Country</span>,
      sortable: true,
      selector: (row) => row.country,
    },
    {
      name: <span className="font-weight-bold fs-13">Date</span>,
      sortable: true,
      selector: (row) => row.date,
    },
    {
      name: <span className="font-weight-bold fs-13">Amount</span>,
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Payment status</span>,
      sortable: true,
      selector: (cell) => {
        switch (cell.status) {
          case 'Paid':
            return (
              <span className="badge text-uppercase badge-soft-success">
                {cell.status}
              </span>
            );
          case 'Unpaid':
            return (
              <span className="badge text-uppercase badge-soft-warning">
                {cell.status}
              </span>
            );
          case 'Cancel':
            return (
              <span className="badge text-uppercase badge-soft-danger">
                {cell.status}
              </span>
            );
          default:
            return (
              <span className="badge text-uppercase badge-soft-info">
                {cell.status}
              </span>
            );
        }
      },
    },
  ];

  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCustomer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Col lg={12}>
        <Card id="transactionList">
          <CardHeader className="border-0">
            <Row>
              <div className="col-sm">
                <div>
                  <h5 className="card-title mb-0">All Transactions</h5>
                </div>
              </div>
              <div className="col-sm-auto"></div>
            </Row>
          </CardHeader>
          <CardBody className="p-0">
            <div>
              {data && data.length > 0 ? (
                <DatatablesCom
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                />
              ) : (
                // <Loader error="{error}" />
                <span>Data Not Found</span>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default TransactionList;
