import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import { useFormik } from 'formik';
import { Backdrop, CircularProgress } from '@mui/material';
import { setToken } from '../../../utils/axiosClient';
import { useAuthStore } from '../../../redux/features/authSlice';
import {
  changePasswordSuperAdmin,
  fetchParticularUser,
  userUpdateProfileSuperAdmin,
} from '../../../services/apiServices';

//Import Breadcrumb
import BreadCrumb from '../../Components/Common/BreadCrumb';
import userNoImage from '../../assets/images/users/user-dummy-img.jpg';
import { IMG_URL } from '../../../utils/constant';
import { profileUpadte, passwordUpdate } from '../../../utils/validation/Admin';
import ShowSpinner from '../../Components/Common/spinner';
import ResponseModal from '../../Components/Common/SuccessModal';

const AdminProfile = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState('1');
  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const currentDate = new Date();
  const { updateAdminLoginInfo, loginAdminInfo } = useAuthStore();
  const [userData, setUserData] = useState();

  const [oldPasswordShow, setOldPasswordShow] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  const uploadedImage = useRef();
  const imageUploader = useRef();
  const [imageError, setImageError] = useState();
  const [render, setRender] = useState(true);
  const [dates, setDates] = useState();
  const [spinFlag, setSpinFlag] = useState(false);
  const [response, setResponse] = useState({
    message: '',
    open: false,
    statusCode: null,
  });

  useEffect(() => {
    getSuperAdminData();
  }, [render]);

  const getSuperAdminData = async () => {
    try {
      setSpinFlag(true);
      let payload = { user_id: loginAdminInfo ? loginAdminInfo._id : null };
      setToken(loginAdminInfo ? loginAdminInfo.token : null);
      const { data } = await fetchParticularUser(payload);
      if (data.statusCode === 200) {
        setUserData(data.requestData);
        const isoDate = new Date(data.requestData.dob);
        const addLeadingZero = (number) =>
          number < 10 ? `0${number}` : number;
        const formattedDate = `${isoDate.getFullYear()}-${addLeadingZero(
          isoDate.getMonth() + 1
        )}-${addLeadingZero(isoDate.getDate())}`;
        setDates(formattedDate);
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const updateProfile = async (values) => {
    try {
      setSpinFlag(true);
      setToken(loginAdminInfo ? loginAdminInfo.token : null);
      let userData = new FormData();
      userData.append('data', JSON.stringify(values));
      userData.append('profile', values.profile);
      const { data } = await userUpdateProfileSuperAdmin(userData);
      if (data.statusCode === 200) {
        let result = await data.userData;
        result.token = await data.token;
        updateAdminLoginInfo(result);
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
        setRender(!render);
        // resetForm();
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
        // resetForm();
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: userData?.firstname || '',
      lastname: userData?.lastname || '',
      dob: dates || '',
      profile: '',
      email: userData?.email || '',
    },
    validationSchema: profileUpadte,
    onSubmit: async (values) => {
      updateProfile(values);
    },
  });

  const validationPasswordChange = useFormik({
    enableReinitialize: true,
    initialValues: {
      currentPassword: '',
      password: '',
      confirmpassword: '',
    },
    validationSchema: passwordUpdate,
    onSubmit: async (values) => {
      const payload = {
        currentpassword: values.currentPassword,
        newpassword: values.password,
        role: 'superadmin',
      };
      try {
        setSpinFlag(true);
        setToken(loginAdminInfo ? loginAdminInfo.token : null);
        const { data } = await changePasswordSuperAdmin(payload);
        if (data.statusCode == 200) {
          resetForm();
          setSpinFlag(false);
          setResponse({
            message: data.message,
            statusCode: data.statusCode,
            open: true,
          });
          setRender(!render);
        } else {
          setSpinFlag(false);
          setResponse({
            message: data.message,
            statusCode: data.statusCode,
            open: true,
          });
          resetForm();
        }
      } catch (err) {
        navigate(`/admin/error?msg=${err}&&path=${pathname}`);
      }
    },
  });

  const {
    errors,
    touched,
    handleBlur,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setFieldError,
    resetForm1,
    values,
  } = validation;
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setImageError('');
    if (
      file?.type === 'image/jpg' ||
      file?.type === 'image/jpeg' ||
      file?.type === 'image/png' ||
      file?.type === 'image/svg+xml' ||
      file?.type === 'image/gif'
    ) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      setFieldValue('profile', file);
    } else {
      setFieldValue('profile', '');
      setImageError('Invalid image formate');
    }
  };
  const {
    errors1,
    touched1,
    handleBlur1,
    handleSubmit1,
    getFieldProps1,
    setFieldValue1,
    setFieldError1,
    resetForm,
    values1,
  } = validationPasswordChange;
  return (
    <React.Fragment>
      <ResponseModal response={response} setResponse={setResponse} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Profile" pageTitle="" />
          <Row>
            <Col lg={3}>
              <div style={{ position: 'sticky', top: '80px' }}>
                <Card className="">
                  <CardBody className="p-4">
                    <div className="text-center">
                      <div className="profile-user position-relative d-inline-block mx-auto mb-4">
                        <img
                          className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                          src={
                            loginAdminInfo.profile
                              ? `${IMG_URL}${loginAdminInfo.profile}`
                              : userNoImage
                          }
                          alt="Profile Photo"
                          ref={uploadedImage}
                        />
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageUpload}
                          ref={imageUploader}
                          style={{
                            display: 'none',
                          }}
                        />
                        {imageError ||
                        validation.values.profile == 'undefined' ? (
                          <div
                            className="invalid"
                            style={{
                              color: '#EF7157',
                              fontSize: '0.875em',
                            }}
                          >
                            {imageError}
                          </div>
                        ) : null}
                        <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                          <Button
                            id="profile-img-file-input"
                            type="file"
                            className="profile-img-file-input"
                            onClick={() => imageUploader.current.click()}
                          />
                          <Label
                            htmlFor="profile-img-file-input"
                            className="profile-photo-edit avatar-xs"
                          >
                            <span className="avatar-title rounded-circle bg-light text-body">
                              <i className="ri-camera-fill"></i>
                            </span>
                          </Label>
                        </div>
                      </div>
                      {/* <p className="text-muted mb-4">
                        <small>
                          {' '}
                          Only .jpg, .png, .jpeg, .gif and .svg formats are
                          allowed
                        </small>
                      </p> */}
                      <h5 className="fs-16 mb-1">
                        {userData?.firstname} {userData?.lastname}
                      </h5>
                      <p className="text-muted mb-0 text-capitalize">
                        {userData?.role}
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>

            <Col lg={9}>
              <Card>
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => {
                          tabChange('1');
                        }}
                        type="button"
                      >
                        <i className="fas fa-home"></i>
                        Personal Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => {
                          tabChange('2');
                        }}
                        type="button"
                      >
                        <i className="far fa-user"></i>
                        Change Password
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="firstnameInput"
                                className="form-label"
                              >
                                First name
                              </Label>
                              <Input
                                name="firstname"
                                className="form-control"
                                placeholder="Enter first name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.firstname || ''}
                                invalid={
                                  validation.touched.firstname &&
                                  validation.errors.firstname
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.firstname &&
                              validation.errors.firstname ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.firstname}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="lastnameInput"
                                className="form-label"
                              >
                                Last name
                              </Label>
                              <Input
                                name="lastname"
                                className="form-control"
                                placeholder="Enter last name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.lastname || ''}
                                invalid={
                                  validation.touched.lastname &&
                                  validation.errors.lastname
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.lastname &&
                              validation.errors.lastname ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.lastname}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div className="mb-3">
                              <Label
                                htmlFor="JoiningdatInput"
                                className="form-label"
                              >
                                Date of birth
                              </Label>
                              <Flatpickr
                                className="form-control"
                                id="datepicker-publish-input"
                                placeholder="Select a date"
                                value={validation.values.dob || ''}
                                name="dob"
                                options={{
                                  dateFormat: 'Y-m-d',
                                  mode: 'single',
                                  utc: false,
                                  maxDate: currentDate,
                                }}
                                onChange={(selectedDates) => {
                                  validation.setFieldValue(
                                    'dob',
                                    selectedDates[0]
                                  );
                                }}
                              />
                              {validation.errors.dob &&
                                validation.touched.dob && (
                                  <div
                                    className="invalid"
                                    style={{
                                      color: '#EF7157',
                                      fontSize: '0.875em',
                                    }}
                                  >
                                    {validation.errors.dob}
                                  </div>
                                )}
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="email" className="form-label">
                                Email
                              </Label>
                              <Input
                                name="email"
                                className="form-control"
                                placeholder="Enter Email"
                                type="email"
                                value={validation.values.email || ''}
                                disabled={true}
                              />
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                              <Button type="submit" className="btn btn-primary">
                                Updates
                              </Button>
                              <Button
                                type="button"
                                className="btn btn-soft-success"
                              >
                                Cancel
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                      {/* <Backdrop
                        sx={{ color: '#fff' }}
                        open={fetchState.spinFlag}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop> */}
                    </TabPane>

                    <TabPane tabId="2">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validationPasswordChange.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        <Row className="g-2">
                          {/* {state.showAlert ? (
                            <Alert sx={{ mb: 2 }} severity="error">
                              {state.message}
                            </Alert>
                          ) : (
                            ''
                          )} */}
                          <Col lg={12}>
                            <div>
                              <Label
                                htmlFor="oldpasswordInput"
                                className="form-label"
                              >
                                Old password*
                              </Label>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <Input
                                  name="currentPassword"
                                  className="form-control"
                                  placeholder="Enter old password"
                                  type={oldPasswordShow ? 'text' : 'password'}
                                  onChange={
                                    validationPasswordChange.handleChange
                                  }
                                  onBlur={validationPasswordChange.handleBlur}
                                  value={
                                    validationPasswordChange.values
                                      .currentPassword || ''
                                  }
                                  invalid={
                                    validationPasswordChange.touched
                                      .currentPassword &&
                                    validationPasswordChange.errors
                                      .currentPassword
                                      ? true
                                      : false
                                  }
                                />
                                {validationPasswordChange.touched
                                  .currentPassword &&
                                validationPasswordChange.errors
                                  .currentPassword ? (
                                  <FormFeedback type="invalid">
                                    {
                                      validationPasswordChange.errors
                                        .currentPassword
                                    }
                                  </FormFeedback>
                                ) : null}
                                <button
                                  className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                  type="button"
                                  id="password-addon"
                                  onClick={() =>
                                    setOldPasswordShow(!oldPasswordShow)
                                  }
                                >
                                  <i
                                    className={`align-middle ${
                                      oldPasswordShow
                                        ? 'ri-eye-off-fill'
                                        : 'ri-eye-fill'
                                    }`}
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div>
                              <Label
                                htmlFor="newpasswordInput"
                                className="form-label"
                              >
                                New password*
                              </Label>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <Input
                                  name="password"
                                  className="form-control"
                                  placeholder="Enter new password"
                                  type={newPasswordShow ? 'text' : 'password'}
                                  onChange={
                                    validationPasswordChange.handleChange
                                  }
                                  onBlur={validationPasswordChange.handleBlur}
                                  value={
                                    validationPasswordChange.values.password ||
                                    ''
                                  }
                                  invalid={
                                    validationPasswordChange.touched.password &&
                                    validationPasswordChange.errors.password
                                      ? true
                                      : false
                                  }
                                />
                                {validationPasswordChange.touched.password &&
                                validationPasswordChange.errors.password ? (
                                  <FormFeedback type="invalid">
                                    {validationPasswordChange.errors.password}
                                  </FormFeedback>
                                ) : null}
                                <button
                                  className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                  type="button"
                                  id="password-addon"
                                  onClick={() =>
                                    setNewPasswordShow(!newPasswordShow)
                                  }
                                >
                                  <i
                                    className={`align-middle ${
                                      newPasswordShow
                                        ? 'ri-eye-off-fill'
                                        : 'ri-eye-fill'
                                    }`}
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div>
                              <Label
                                htmlFor="confirmpasswordInput"
                                className="form-label"
                              >
                                Confirm password*
                              </Label>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <Input
                                  name="confirmpassword"
                                  className="form-control"
                                  placeholder="Enter confirm password"
                                  type={
                                    confirmPasswordShow ? 'text' : 'password'
                                  }
                                  onChange={
                                    validationPasswordChange.handleChange
                                  }
                                  onBlur={validationPasswordChange.handleBlur}
                                  value={
                                    validationPasswordChange.values
                                      .confirmpassword || ''
                                  }
                                  invalid={
                                    validationPasswordChange.touched
                                      .confirmpassword &&
                                    validationPasswordChange.errors
                                      .confirmpassword
                                      ? true
                                      : false
                                  }
                                />
                                {validationPasswordChange.touched
                                  .confirmpassword &&
                                validationPasswordChange.errors
                                  .confirmpassword ? (
                                  <FormFeedback type="invalid">
                                    {
                                      validationPasswordChange.errors
                                        .confirmpassword
                                    }
                                  </FormFeedback>
                                ) : null}
                                <button
                                  className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                  type="button"
                                  id="password-addon"
                                  onClick={() =>
                                    setConfirmPasswordShow(!confirmPasswordShow)
                                  }
                                >
                                  <i
                                    className={`align-middle ${
                                      confirmPasswordShow
                                        ? 'ri-eye-off-fill'
                                        : 'ri-eye-fill'
                                    }`}
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="text-end">
                              <Button type="submit" className="btn btn-success">
                                Change Password
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                      {/* <Backdrop sx={{ color: '#fff' }} open={state.spinFlag}>
                        <CircularProgress color="inherit" />
                      </Backdrop> */}
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ShowSpinner spinFlag={spinFlag} />
    </React.Fragment>
  );
};

export default AdminProfile;
