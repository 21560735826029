import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {
  Container,
  Grid,
  Box,
  Typography,
  ListItem,
  IconButton,
  List,
  alpha,
} from '@mui/material';
import {
  ManFooter,
  FooterTopMain,
  FooterLogo,
  FooterMenuList,
  FooterBottomMain,
  FooterSocial,
  FooterBottomRight,
  FooterLeft,
} from './styled';
import { LogoSection } from '../../../components/logo';
import Master from '../../../assets/images/Master.svg';
import Visa from '../../../assets/images/Visa.svg';
import Paypal from '../../../assets/images/Paypal.svg';
import facebook from '../../../assets/icons/facebook.svg';
import instagram from '../../../assets/icons/instagram.svg';
import twitter from '../../../assets/icons/twitter.svg';
import youtube from '../../../assets/icons/youtube.svg';
import {
  getSocialMediaLink,
  getSocialMediaLinkCommon,
} from '../../../services/apiServices';
import { IMG_URL } from '../../../utils/constant';
import dummyImgIcon from '../../../admin/assets/images/logo-sm.png';
const CardList = [{ name: Master }, { name: Visa }, { name: Paypal }];

export const Footer = (props) => {
  let { loginInfo } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [fetchSingleUserState, setFetchSingleUserState] = useState({
    message: '',
    spinFlag: false,
    showAlert: false,
    showAlertError: false,
  });
  const [linkData, setLinkData] = useState([]);
  const FooterSocialBtn = [
    {
      icon: facebook,
      path: linkData ? `${linkData[0]?.link}` : '/',
    },
    {
      icon: instagram,
      path: linkData ? `${linkData[1]?.link}` : '/',
    },
    {
      icon: twitter,
      path: linkData ? `${linkData[2]?.link}` : '/',
    },
    {
      icon: youtube,
      path: linkData ? `${linkData[3]?.link}` : '/',
    },
  ];
  const footerMenu = [
    {
      menuTitle: 'Contact us',
      menuList: [
        // { title: 'Instagram', path: 'https://www.instagram.com' },
        // { title: 'Facebook', path: 'https://www.facebook.com' },
        { title: 'Twitter', path: 'https://twitter.com/?lang=en' },
        { title: 'Blog', path: '/' },
      ],
    },
    {
      menuTitle: 'List a property',
      menuList: [
        { title: 'Apply as an Landlord', path: '/sign-up' },
        // { title: 'Submit Free Room', path: '/' },
        // { title: 'Partnership', path: '/' },
      ],
    },
  ];
  useEffect(() => {
    getSocialMediaLinkFooter();
  }, []);
  const getSocialMediaLinkFooter = async () => {
    try {
      setFetchSingleUserState({
        ...fetchSingleUserState,
        spinFlag: true,
        showAlert: false,
      });
      const { data } = await getSocialMediaLinkCommon();
      if (data.statusCode === 200) {
        setLinkData(data?.links?.socailMediaData);
        setFetchSingleUserState({
          ...fetchSingleUserState,
          message: data.message,
          spinFlag: false,
          showAlert: false,
        });
      } else {
        setFetchSingleUserState({
          ...fetchSingleUserState,
          message: data.message,
          spinFlag: false,
          showAlert: true,
        });
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };
  return (
    <ManFooter>
      <FooterTopMain>
        <Container fixed={true} sx={{ maxWidth: { xl: '1326px' } }}>
          <Grid container>
            <Grid item md={5} xs={12}>
              <FooterLeft>
                <FooterLogo>
                  <LogoSection color={'#FEAE01'} />
                </FooterLogo>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ color: 'text.secondary' }}
                >
                  Find your place with Flattts
                </Typography>
                <Box sx={{ display: 'flex', gap: '20px', mt: 3 }}>
                  {CardList.map((val, i) => (
                    <img key={i} src={val.name} alt="Master" />
                  ))}
                </Box>
              </FooterLeft>
            </Grid>
            <Grid item container md={7} xs={12}>
              <Grid item sm={4} xs={12}>
                <Box sx={{ mt: { md: 0, xs: 2 } }}>
                  <Typography variant="h6" component="p">
                    Company
                  </Typography>
                  <FooterMenuList>
                    {loginInfo.role !== 'landlord' && (
                      <>
                        <ListItem>
                          <Link to="/">Home</Link>
                        </ListItem>
                        <ListItem>
                          <Link to="/available-spaces">Rent</Link>
                        </ListItem>
                      </>
                    )}
                    <ListItem>
                      <Link to="/about-us">About us</Link>
                    </ListItem>
                    <ListItem>
                      <Link to="/faq">FAQ</Link>
                    </ListItem>
                  </FooterMenuList>
                </Box>
              </Grid>

              {footerMenu.map((val, index) => (
                <Grid item sm={4} xs={12} key={index}>
                  <Box sx={{ mt: { md: 0, xs: 2 } }}>
                    <Typography variant="h6" component="p">
                      {val.menuTitle}
                    </Typography>
                    <FooterMenuList>
                      {val.menuList.map((menu, i) => (
                        <ListItem key={i}>
                          <Link to={menu.path}>{menu.title}</Link>
                        </ListItem>
                      ))}
                    </FooterMenuList>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
      </FooterTopMain>
      <FooterBottomMain>
        <Container fixed={true} sx={{ maxWidth: { xl: '1326px' } }}>
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <Grid item md={4} xs={12} sx={{ order: { md: '1', xs: '2' } }}>
              <Typography variant="body2">
                ©{new Date().getFullYear()} Flattts. All rights reserved.
              </Typography>
            </Grid>
            <Grid item md={4} xs={12} sx={{ order: { md: '2', xs: '1' } }}>
              <Box sx={{ textAlign: { md: 'center' } }}>
                <FooterSocial>
                  {/* {FooterSocialBtn?.map((val, index) => (
                    <IconButton key={index}>
                      <Link to={`${val?.path}`}>40px
                        <img src={val?.icon} />
                      </Link>
                    </IconButton>
                  ))} */}
                  {linkData?.map((val, index) => {
                    return val?.icon && val?.link ? (
                      <IconButton
                        target="_blank"
                        component={Link}
                        to={val?.link}
                        key={index}
                      >
                        <img
                          style={{
                            width: '24px',
                            height: '24px',
                            objectFit: 'contain',
                          }}
                          src={
                            val?.icon ? `${IMG_URL}${val?.icon}` : dummyImgIcon
                          }
                        />
                      </IconButton>
                    ) : null;
                  })}
                </FooterSocial>
              </Box>
            </Grid>
            <Grid item md={4} xs={12} sx={{ order: { md: '3', xs: '3' } }}>
              <FooterBottomRight>
                <List sx={{ gap: 3 }}>
                  <ListItem sx={{ fontSize: '14px' }} disablePadding>
                    <Link to="/">Terms</Link>
                  </ListItem>
                  <ListItem sx={{ fontSize: '14px' }} disablePadding>
                    <Link to="/">Privacy</Link>
                  </ListItem>
                </List>
              </FooterBottomRight>
            </Grid>
          </Grid>
        </Container>
      </FooterBottomMain>
    </ManFooter>
  );
};
