import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import {
  Container,
  Card,
  CardHeader,
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  CardContent,
  ListItemButton,
  Typography,
  List,
  ListItem,
  Grid,
  Pagination,
  styled,
} from '@mui/material';
import { Icon } from '@iconify/react';
import SvgColor from '../../../components/svg-color';
import deleteIcon from '../../../assets/icons/delete.svg';
import notiCloseButton from '../../../assets/icons/notiCloseButton.svg';
import Scrollbar from '../../../components/scrollbar';
import { UnitCard } from '../../../components/unit-card';
import { favoriteList } from '../../../services/apiServices';
import { DataNotAvailable } from '../../../components/data-not-found';
import { unitHistoryList } from '../../../services/apiServices';
import { HeaderBreadcrumbs } from '../../../components/breadcrumbs';

export const UnitHistoryPage = () => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const [spinFlag, setSpinFlag] = useState();
  const [message, setMessage] = useState(false);
  const [favList, setFavList] = useState([]);
  const [renderFlag, setRenderFlag] = useState(false);
  const [unitHistory, setUnitHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState();
  const [statusCode, setStatusCode] = useState();
  useEffect(() => {
    getUnitHistory();
  }, [renderFlag, currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const getUnitHistory = async () => {
    try {
      const payload = {
        currentPage: currentPage,
      };
      setSpinFlag(true);
      let { data } = await unitHistoryList(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setUnitHistory(data.unitHistoryList);
        setNumberOfPages(data.noOfPages);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };
  return (
    <>
      <HeaderBreadcrumbs
        heading="Unit history"
        links={[
          { name: 'Dashboard', href: '/dashboard' },
          { name: 'Unit history' },
        ]}
      />
      <Card>
        <CardHeader
          title="Unit History"
          sx={{
            pb: { md: 2, xs: 3 },
            flexWrap: 'wrap',
            borderBottom: { md: 'none', xs: '1px solid #F1F1F1' },
            '& .MuiCardHeader-content': {
              width: { md: '35%', xs: '100%' },
              paddingBottom: { md: 0, xs: 1 },
            },
            '& .MuiCardHeader-action': {
              m: 0,
              width: { md: '65%', xs: '100%' },
              paddingTop: { md: 0, xs: 0 },
            },
          }}
        />
        <CardContent>
          <Grid container spacing={2}>
            {unitHistory.length !== 0 ? (
              unitHistory.map((item, index) => {
                return (
                  <Grid item md={6} xs={12}>
                    <UnitCard
                      key={index}
                      favHistoryData={item}
                      setRenderFlag={setRenderFlag}
                      renderFlag={renderFlag}
                    />
                  </Grid>
                );
              })
            ) : (
              <Grid item md={12} xs={12}>
                <DataNotAvailable title={"You don't have unit history"} />
              </Grid>
            )}
          </Grid>
          {unitHistory.length !== 0 && (
            <Pagination
              shape="rounded"
              count={numberOfPages}
              siblingCount={0}
              boundaryCount={2}
              onChange={handlePageChange}
              sx={{
                py: 2,
                '& .MuiPagination-ul': { justifyContent: 'center' },
              }}
            />
          )}
        </CardContent>
      </Card>
    </>
  );
};
