import React, { useState, useRef } from 'react';
import {
  TextField,
  Button,
  Stack,
  Grid,
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
} from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useFormik, Form, FormikProvider } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import profileDam from '../../../assets/images/profileDam.png';
import uploadProfile from '../../../assets/icons/uploadProfile.svg';
import SvgColor from '../../../components/svg-color';
import { updateProfileSchema } from '../../../utils/validation/Auth';
import { useAuthStore } from '../../../redux/features/authSlice';
import { userUpdateProfile } from '../../../services/apiServices';
import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';
import { IMG_URL } from '../../../utils/constant';
import { REGEX } from '../../../utils/regex';
import { onlyCountries } from '../../../utils/constant';
import { Icon } from '@iconify/react';

export const AccountDetail = (props) => {
  let { setSpinFlag } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const { loginInfo, updateLoginInfo } = useAuthStore();
  const [statusCode, setStatusCode] = useState();
  const uploadedImage = useRef();
  const imageUploader = useRef();
  const defaultCountry = 'us';

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (
      file?.type === 'image/jpg' ||
      file?.type === 'image/jpeg' ||
      file?.type === 'image/png' ||
      file?.type === 'image/svg+xml' ||
      file?.type === 'image/gif'
    ) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      setFieldValue('profile', file);
    } else {
      setFieldValue('profile', '');
    }
  };

  const updateProfile = async (values) => {
    try {
      setSpinFlag(true);
      let userData = new FormData();
      userData.append('data', JSON.stringify(values));
      userData.append('profile', values.profile);
      const { data } = await userUpdateProfile(userData);

      if (data.statusCode === 200) {
        let result = data.userData;
        result.token = data.token;
        updateLoginInfo(result);
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const formik = useFormik({
    initialValues: {
      role: loginInfo.role,
      firstname: loginInfo.firstname,
      lastname: loginInfo.lastname,
      email: loginInfo.email,
      // password: '',
      // confirmpassword: '',
      mobile: loginInfo.mobile,
      countryCode: loginInfo.countryCode,
      unit: loginInfo.unit,
      dob: loginInfo.dob,
      profile: '',
    },
    validationSchema: updateProfileSchema,
    onSubmit: async (values) => {
      updateProfile(values);
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setFieldError,
    values,
    handleBlur,
  } = formik;

  const handleDobInput = (e) => {
    if (e) {
      const selectedDate = dayjs(e.$d);
      const currentDate = dayjs();
      const minDate = currentDate.subtract(15, 'year');

      if (selectedDate.isAfter(minDate)) {
        setFieldError(
          'dob',
          'Please select a date from the last 15 years or older'
        );
      } else {
        const formattedDate = selectedDate.format('YYYY-MM-DD');
        setFieldValue('dob', formattedDate);
        setFieldError('dob', undefined);
      }
    } else {
      setFieldValue('dob', '');
    }
  };

  return (
    <>
      <DialogPopup
        open={open}
        setOpen={setOpen}
        message={message}
        size="xs"
        statusCode={statusCode}
      />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Grid container spacing={2}>
              <Grid item md={5} xs={12}>
                <Card
                  sx={{
                    boxShadow: 'none',
                    border: '1px solid #F1F1F1',
                    borderRadius: '12px',
                  }}
                >
                  <CardContent sx={{ p: { md: 4, sm: 3, xs: 2 } }}>
                    <Box>
                      <Typography variant="h5">Photo</Typography>
                      <Typography
                        sx={{ mb: 3, color: '#808191' }}
                        variant="body1"
                      >
                        Upload your profile photo
                      </Typography>
                      <Box sx={{ my: 3 }}>
                        <Box
                          sx={{
                            borderRadius: '14px',
                            overflow: 'hidden',
                          }}
                        >
                          <img
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              aspectRatio: '1/0.8',
                              display: 'block',
                            }}
                            src={
                              loginInfo.profile
                                ? `${IMG_URL}${loginInfo.profile}`
                                : profileDam
                            }
                            alt="Profile Photo"
                            ref={uploadedImage}
                          />
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            ref={imageUploader}
                            style={{
                              display: 'none',
                            }}
                          />
                        </Box>
                        <Button
                          variant="contained"
                          fullWidth
                          size="large"
                          sx={{ py: 2, px: 4, mt: 2 }}
                          onClick={() => imageUploader.current.click()}
                        >
                          <SvgColor
                            sx={{ width: '24px', height: '24px', mr: 1.75 }}
                            src={uploadProfile}
                            alt={uploadProfile}
                          />
                          Upload profile image
                        </Button>
                      </Box>
                      <Typography
                        sx={{
                          color: '#808191',
                          textAlign: 'center',
                        }}
                        variant="body1"
                        component="p"
                      >
                        *minimun size 160 * 1024 <br />
                        <small>
                          only .jpg, .png, .jpeg, .gif and .svg formats are
                          allowed
                        </small>
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
                {/* {touched.profile && errors.profile && (
                  <FormHelperText style={{ color: '#FF4842' }}>
                    {errors.profile}
                  </FormHelperText>
                )} */}
              </Grid>
              <Grid item container md={7} xs={12}>
                <Card
                  sx={{
                    boxShadow: 'none',
                    border: '1px solid #F1F1F1',
                    borderRadius: '12px',
                  }}
                >
                  <CardContent sx={{ p: { md: 4, sm: 3, xs: 2 } }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h5">User detail</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          type="Text"
                          label="First name"
                          {...getFieldProps('firstname')}
                          // error={Boolean(touched.firstname && errors.firstname)}
                          // helperText={touched.firstname && errors.firstname}
                        />
                        {touched.firstname && errors.firstname && (
                          <FormHelperText error>
                            {errors.firstname}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          type="Text"
                          label="Last name"
                          {...getFieldProps('lastname')}
                          // error={Boolean(touched.lastname && errors.lastname)}
                          // helperText={touched.lastname && errors.lastname}
                        />
                        {touched.lastname && errors.lastname && (
                          <FormHelperText error>
                            {errors.lastname}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          type="email"
                          label="Email"
                          {...getFieldProps('email')}
                          // error={Boolean(touched.email && errors.email)}
                          // helperText={touched.email && errors.email}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        {touched.email && errors.email && (
                          <FormHelperText error>{errors.email}</FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex' }}>
                          <MuiPhoneNumber
                            value={values.countryCode}
                            defaultCountry={defaultCountry}
                            onlyCountries={onlyCountries}
                            name="countryCode"
                            label=" "
                            onChange={(e) => {
                              setFieldValue('countryCode', e);
                            }}
                            variant="outlined"
                            countryCodeEditable="false"
                            InputProps={{
                              readOnly: true,
                            }}
                            sx={{
                              width: '200px',
                              '& .MuiInputBase-root': {
                                borderRadius: '14px 0 0 14px',
                                borderRight: 'none',
                                '& legend span': {
                                  display: 'none',
                                },
                                '& .MuiMenu-paper': {
                                  maxHeight: '500px',
                                },
                              },
                            }}
                          />
                          <TextField
                            fullWidth
                            type="tel"
                            label="Enter your phone number"
                            sx={{
                              '& .MuiInputBase-root': {
                                borderRadius: '0 14px 14px 0',
                              },
                            }}
                            {...getFieldProps('mobile')}
                            // error={Boolean(touched.mobile && errors.mobile)}
                            // helperText={touched.mobile && errors.mobile}
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .replace(REGEX.RESTRICT_CHAR, '')
                                .slice(0, 15);
                            }}
                          />
                        </Box>
                        {touched.mobile && errors.mobile && (
                          <FormHelperText error>{errors.mobile}</FormHelperText>
                        )}
                      </Grid>
                      {loginInfo.role === 'landlord' ? (
                        <Grid item xs={12}>
                          <TextField
                            type="text"
                            fullWidth
                            name="unit"
                            label="How many units do you have to rent"
                            {...getFieldProps('unit')}
                            // error={Boolean(touched.unit && errors.unit)}
                            // helperText={touched.unit && errors.unit}
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .replace(REGEX.RESTRICT_CHAR, '')
                                .slice(0, 2);
                            }}
                          />
                          {touched.unit && errors.unit && (
                            <FormHelperText error>{errors.unit}</FormHelperText>
                          )}
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              sx={{ p: 0 }}
                              components={['DatePicker']}
                            >
                              <FormControl fullWidth>
                                <DatePicker
                                  label="Date of birth"
                                  format="YYYY-MM-DD"
                                  value={dayjs(values.dob)}
                                  onChange={handleDobInput}
                                  onBlur={handleBlur}
                                  disableFuture={true}
                                  name="dob"
                                  // error={Boolean(touched.dob && errors.dob)}
                                  // helperText={touched.dob && errors.dob}
                                />
                                {touched.dob && errors.dob && (
                                  <FormHelperText error>
                                    {errors.dob}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Stack gap="24px" flexDirection="row" justifyContent="end">
                  {/* <Button
                    // type="submit"
                    // variant="outlined"
                    // color="error"
                    size="large"
                    sx={{ px: 5 }}
                  >
                    Delete Profile
                  </Button> */}
                  <Button
                    disabled={Object.keys(formik.errors).length > 0}
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ px: 5, width: { sm: 'auto', xs: '100%' } }}
                  >
                    Update profile
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
};
