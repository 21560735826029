import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import { useAuthStore } from '../../../../redux/features/authSlice';

// ----------------------------------------------------------------------

export const NavSection = ({ data = [], ...other }) => {
  let { loginInfo } = useAuthStore();
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 2 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
      {loginInfo.role === 'landlord' && (
        <List
          disablePadding
          sx={{ p: 2, borderTop: 'thin dashed rgba(0, 0, 0, 0.12)' }}
        >
          <StyledNavItem
            component={RouterLink}
            to="/about-us"
            sx={{
              '&.active': {
                color: '#fff',
                bgcolor: 'primary.main',
                fontWeight: 'fontWeightBold',
              },
            }}
          >
            <StyledNavItemIcon> </StyledNavItemIcon>
            <ListItemText disableTypography primary="About Us" />
          </StyledNavItem>
          <StyledNavItem
            component={RouterLink}
            to="/faq"
            sx={{
              '&.active': {
                color: '#fff',
                bgcolor: 'primary.main',
                fontWeight: 'fontWeightBold',
              },
            }}
          >
            <StyledNavItemIcon> </StyledNavItemIcon>
            <ListItemText disableTypography primary="FAQ" />
          </StyledNavItem>
        </List>
      )}
    </Box>
  );
};
// ----------------------------------------------------------------------

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: '#fff',
          bgcolor: 'primary.main',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </StyledNavItem>
  );
}
