import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from 'reactstrap';
import { Pagination } from '@mui/material';
import 'quill/dist/quill.snow.css';
import DeleteModal from '../../../Components/Common/DeleteModal';
import backgroundImg from '../../../assets/images/bannerImg.png';
import {
  getFeaturedCities,
  changeCityImage,
} from '../../../../services/apiServices';
import { addOrRemoveFromFeaturedCities } from '../../../../services/apiServices';
import { IMG_URL } from '../../../../utils/constant';
import ShowSpinner from '../../../Components/Common/spinner';
import ResponseModal from '../../../Components/Common/SuccessModal';

const FeaturedCitiesPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const uploadedImage = useRef();
  const [deleteModal, setDeleteModal] = useState(false);
  const [featuredCities, setFeaturedCities] = useState([]);
  const [cityImage, setCityImage] = useState('');
  const [reviewRendering, setReviewRendering] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState();
  const [search, setSearch] = useState('');
  const [spinFlag, setSpinFlag] = useState(false);
  const [response, setResponse] = useState({
    message: '',
    open: false,
    statusCode: null,
  });
  const [deleteState, setDeleteState] = useState({
    message: '',
    spinFlag: false,
    showAlert: false,
    showAlertError: false,
  });

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  //for remove perticular property from top rated cities
  const onClickDelete = (id) => {
    setDeleteModal(true);
  };

  //update city image in backend
  const saveCityImage = async (file, city_id) => {
    try {
      setSpinFlag(true);
      let cityData = new FormData();
      cityData.append('cityData', JSON.stringify({ city_id }));
      cityData.append('cityImage', file);
      let { data } = await changeCityImage(cityData);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        // setResponse({
        //   message: data.message,
        //   statusCode: data.statusCode,
        //   open: true,
        // });
        setReviewRendering(!reviewRendering);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  //for image validation
  const handleImageUpload = (e, id) => {
    const file = e.target.files[0];
    // setImageError('');
    if (
      file?.type === 'image/jpg' ||
      file?.type === 'image/jpeg' ||
      file?.type === 'image/png' ||
      file?.type === 'image/svg+xml' ||
      file?.type === 'image/gif'
    ) {
      // const reader = new FileReader();
      // const { current } = uploadedImage;
      // current.file = file;
      // reader.onload = (e) => {
      //   current.src = e.target.result;
      // };
      // reader.readAsDataURL(file);

      // setCityImage(file);
      saveCityImage(file, id);
    } else {
      setCityImage('');
    }
  };
  // const toggle = useCallback(() => {
  //   if (modal) {
  //     setModal(false);
  //     setCustomer(null);
  //   } else {
  //     setModal(true);
  //   }
  // }, [modal]);
  // const favouriteBtn = (ele) => {
  //   if (ele.closest('button').classList.contains('active')) {
  //     ele.closest('button').classList.remove('active');
  //   } else {
  //     ele.closest('button').classList.add('active');
  //   }
  // };

  const fetchFeaturedCities = async () => {
    try {
      setSpinFlag(true);
      let payload = { currentPage: currentPage, search: search };
      let { data } = await getFeaturedCities(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setFeaturedCities(data.topRatedCities);
        setNumberOfPages(data.noOfPages);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const addTopRatedCities = async (flag, id) => {
    try {
      setSpinFlag(true);
      let payload = {
        city_id: id,
        flag: flag,
      };
      let { data } = await addOrRemoveFromFeaturedCities(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        // setResponse({
        //   message: data.message,
        //   statusCode: data.statusCode,
        //   open: true,
        // });
        setReviewRendering(!reviewRendering);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  useEffect(() => {
    if (search !== '') {
      const getData = setTimeout(() => {
        fetchFeaturedCities();
      }, 1000);
      return () => clearTimeout(getData);
    } else {
      fetchFeaturedCities();
    }
  }, [reviewRendering, search, currentPage]);

  return (
    <React.Fragment>
      <ResponseModal response={response} setResponse={setResponse} />
      <DeleteModal
        deleteState={deleteState}
        show={deleteModal}
        // onDeleteClick={handleDeleteFaq}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="g-2">
                    <Col xs={12} className="col-lg-auto">
                      <div>
                        <h5 className="card-title mb-0">Featured Cities</h5>
                      </div>
                    </Col>
                    <Col xs={12} className="col-lg-auto ms-auto">
                      <div className="list-grid-nav hstack gap-1">
                        <div className="search-box">
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Search featured cities..."
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <div id="teamlist">
                <Row className="team-list">
                  {featuredCities.map((item, index) => {
                    return (
                      <Col key={index} xxl={3} lg={4} md={6}>
                        <Card className="team-box position-relative">
                          <CardHeader className="border-0 position-absolute w-100 start-0 top-0 bg-transparent">
                            <Row className="align-items-center team-row">
                              <Col className="team-settings">
                                <Row>
                                  <Col>
                                    <div className="flex-shrink-0 me-2"></div>
                                  </Col>
                                  <Col>
                                    <div className="hstack gap-2 justify-content-end">
                                      <div>
                                        <label
                                          htmlFor={`cover-image-input${index}`}
                                          className="mb-0"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="Select Cover Image"
                                        >
                                          <div className="btn btn-light btn-icon border rounded-circle btn-sm bg-white">
                                            <i className="ri-image-fill fs-14"></i>
                                          </div>
                                        </label>
                                        <input
                                          className="form-control d-none"
                                          defaultValue=""
                                          id={`cover-image-input${index}`}
                                          type="file"
                                          name="cityImage"
                                          accept="image/png, image/gif, image/jpeg"
                                          onChange={(event) =>
                                            handleImageUpload(event, item._id)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </CardHeader>
                          <img
                            className="card-img-top img-fluid bg-light"
                            style={{ aspectRatio: '1.3', objectFit: 'cover' }}
                            src={
                              item.cityImage
                                ? `${IMG_URL}${item.cityImage}`
                                : backgroundImg
                            }
                            alt="Card image cap"
                            // ref={uploadedImage}
                          />
                          <CardBody className="p-4 text-center">
                            <div className="mb-4">
                              <h5 className="fs-16 mb-1">{item.city}</h5>
                              <p className="text-muted mb-0">
                                {item.totalSpaces} Spaces
                              </p>
                            </div>
                            <div className="mb-4">
                              <Row className="text-muted text-center">
                                <Col
                                  xs={6}
                                  className="border-end border-end-dashed"
                                >
                                  <h5 className="mb-1">
                                    {item.averageRating
                                      ? item.averageRating
                                      : 0}
                                  </h5>
                                  <p className="text-muted mb-0">Rating</p>
                                </Col>
                                <Col xs={6}>
                                  <h5 className="mb-1">1000+</h5>
                                  <p className="text-muted mb-0">Reviews</p>
                                </Col>
                              </Row>
                            </div>
                            <div>
                              {item.isFeatured === 0 ? (
                                <Button
                                  className={'btn-success w-100'}
                                  onClick={() =>
                                    addTopRatedCities('add', item._id)
                                  }
                                >
                                  Add
                                </Button>
                              ) : (
                                <Button
                                  className={'btn-danger w-100'}
                                  onClick={() =>
                                    addTopRatedCities('remove', item._id)
                                  }
                                >
                                  Remove
                                </Button>
                              )}
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Col>
          </Row>
          {featuredCities.length === 0 && (
            <Card>
              <CardBody className="text-center">Data not found</CardBody>
            </Card>
          )}
          {featuredCities.length !== 0 && (
            <Pagination
              shape="rounded"
              count={numberOfPages}
              page={currentPage}
              siblingCount={0}
              boundaryCount={2}
              onChange={handlePageChange}
              sx={{
                py: 2,
                '& .MuiPagination-ul': { justifyContent: 'center' },
              }}
            />
          )}
        </Container>
      </div>
      <ShowSpinner spinFlag={spinFlag} />
    </React.Fragment>
  );
};

export default FeaturedCitiesPage;
