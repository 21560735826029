import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';
import { emailVerify } from '../../../services/apiServices';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const VerifyEmails = (props) => {
  let { setSpinFlag } = props;
  let query = useQuery();
  let token = query.get('token');
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [statusCode, setStatusCode] = useState();

  const verifyData = async () => {
    try {
      setSpinFlag(true);
      let payload = { token: token };
      const { data } = await emailVerify(payload);

      if (data.statusCode == 200) {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  useEffect(() => {
    if (token !== null) {
      verifyData();
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    navigate('/login');
  };

  return (
    <>
      <DialogPopup
        handleClose={handleClose}
        open={open}
        message={message}
        setOpen={setOpen}
        statusCode={statusCode}
        size="xs"
      />
    </>
  );
};
