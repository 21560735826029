import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  CardContent,
  ListItemButton,
  Typography,
  List,
  ListItem,
  Tooltip,
  styled,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import SvgColor from '../../../components/svg-color';
import deleteIcon from '../../../assets/icons/delete.svg';
import notiCloseButton from '../../../assets/icons/notiCloseButton.svg';
import Scrollbar from '../../../components/scrollbar';
import { NoNotifications } from '../../../components/data-not-found';
import { useAuthStore } from '../../../redux/features/authSlice';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import {
  createRoom,
  notificationDelete,
  notificationDeleteAll,
  notificationList,
  notificationRead,
} from '../../../services/apiServices';
import { updateNotification } from '../../../redux/features/notifySlice';
import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';
// import { useNotifyStore } from '../../../redux/features/notifySlice';

const PageTitle = styled(Box)(({ theme }) => ({
  margin: '0 0 32px',
  position: 'absolute',
  left: '41px',
  top: '60px',
}));

import { HeaderBreadcrumbs } from '../../../components/breadcrumbs';
export const Notification = () => {
  const notificationFlag = useSelector((state) => state.notify);
  const dispatch = useDispatch();
  let { loginInfo } = useAuthStore();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [spinFlag, setSpinFlag] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    fetchNotificationList();
  }, [notificationFlag]);

  const fetchNotificationList = async () => {
    setSpinFlag(true);
    const payload = {
      userId: loginInfo._id,
    };
    try {
      let { data } = await notificationList(payload);
      if (data.statusCode === 200) {
        setNotifications(data.notificationList);
        setSpinFlag(false);
      } else {
        setNotifications([]);
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const handleMessageOpen = async (user) => {
    const payload = {
      userId: JSON.parse(user).senderId._id,
    };
    const { data } = await createRoom(payload);
    // navigation
    if (data.id) {
      let messageData = {
        userId: JSON.parse(user).senderId._id,
        firstname: JSON.parse(user).senderId.firstname,
        lastname: JSON.parse(user).senderId.lastname,
        profile: JSON.parse(user).senderId.profile,
      };

      navigate(`/message?roomId=${data.id}`, { state: messageData });
    }
  };

  const notificationRouteHanler = async (id) => {
    const payload = {
      notificationId: id,
    };
    try {
      const { data } = await notificationRead(payload);

      if (data.statusCode === 200 && data.type === 'Booking') {
        navigate('/dashboard');
      }
      if (data.statusCode === 200 && data.type === 'Payment') {
        navigate('/payment');
      }
      if (data.statusCode === 200 && data.type === 'Message') {
        handleMessageOpen(data.message);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const notificationDeleteHandler = async (id) => {
    try {
      const { data } = await notificationDelete(id);

      dispatch(updateNotification());
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const clearAllNotification = async () => {
    try {
      const { data } = await notificationDeleteAll();

      dispatch(updateNotification());
    } catch (error) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const groupedNotifications = {};
  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'day').startOf('day');

  notifications?.forEach((notification) => {
    const createdAt = moment(notification.createdAt);
    let dateGroup;

    if (createdAt.isSame(today, 'd')) {
      dateGroup = 'Today';
    } else if (createdAt.isSame(yesterday, 'd')) {
      dateGroup = 'Yesterday';
    } else {
      dateGroup = createdAt.format('ddd DD MMMM YYYY');
    }

    if (!groupedNotifications[dateGroup]) {
      groupedNotifications[dateGroup] = [];
    }

    groupedNotifications[dateGroup].push({
      ...notification,
      agoTime: createdAt.fromNow(),
    });
  });

  return (
    <>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogPopup open={open} setOpen={setOpen} message={message} />
      <PageTitle>
        <Typography
          component="h3"
          variant="h3"
          sx={{
            color: 'text.primary',
          }}
        >
          Notification
        </Typography>
      </PageTitle>
      <Card>
        <CardHeader
          title="Recent notification"
          action={
            <>
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  justifyContent: { md: 'end', xs: 'start' },
                }}
              >
                {Object.keys(groupedNotifications).length > 0 && (
                  <Tooltip title="Clear all notification" placement="top" arrow>
                    <Button
                      onClick={() => clearAllNotification()}
                      variant="contained"
                      color="primary"
                      size="large"
                      sx={{
                        py: 2,
                        px: 4,
                        // color: '#808191',
                        width: { md: 'auto', xs: '100%' },
                      }}
                    >
                      <SvgColor
                        sx={{ width: '18px', height: '18px', mr: 1.75 }}
                        src={deleteIcon}
                        alt={deleteIcon}
                      />{' '}
                      Clear all
                    </Button>
                  </Tooltip>
                )}
              </Box>
            </>
          }
          sx={{
            pb: { md: 2, xs: 3 },
            flexWrap: 'wrap',
            borderBottom: { md: 'none', xs: '1px solid #F1F1F1' },
            '& .MuiCardHeader-content': {
              width: { md: '35%', xs: '100%' },
              paddingBottom: { md: 0, xs: 1 },
            },
            '& .MuiCardHeader-action': {
              m: 0,
              width: { md: '65%', xs: '100%' },
              paddingTop: { md: 0, xs: 0 },
            },
          }}
        />
        <CardContent>
          <Scrollbar>
            <Box>
              {Object.keys(groupedNotifications).map((day) => (
                <Box sx={{ mb: 2 }} key={day}>
                  <Typography variant="h6">{day}</Typography>
                  <List>
                    {groupedNotifications[day].map((notification) => (
                      <ListItem sx={{ px: 0 }} key={notification.createdAt}>
                        <ListItemButton
                          sx={{
                            bgcolor: '#F9F9F9',
                            borderRadius: '14px',
                            p: 2,
                            alignItems: 'flex-start',
                          }}
                        >
                          <Tooltip
                            title="Delete Notification"
                            placement="top"
                            arrow
                          >
                            <ListItemIcon
                              onClick={() =>
                                notificationDeleteHandler(notification._id)
                              }
                            >
                              <SvgColor color="#B7B7B7" src={notiCloseButton} />
                            </ListItemIcon>
                          </Tooltip>
                          <ListItemText
                            onClick={() =>
                              notificationRouteHanler(notification._id)
                            }
                          >
                            <Box
                              sx={{
                                display: { md: 'flex', xs: 'block' },
                                alignItems: 'center',
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  width: {
                                    md: 'calc(100% - 120px)',
                                    xs: '100%',
                                  },
                                  fontSize: { md: '1rem', xs: '0.875rem' },
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: notification.content,
                                }}
                              ></Typography>
                              <Typography
                                sx={{
                                  width: { md: '120px', xs: '100%' },
                                  textAlign: 'right',
                                }}
                                variant="caption"
                              >
                                {moment().diff(
                                  moment(notification.createdAt),
                                  'hours'
                                ) < 12
                                  ? notification.agoTime
                                  : moment(notification.createdAt).format(
                                      'hh : mm A'
                                    )}
                              </Typography>
                            </Box>
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              ))}
              {Object.keys(groupedNotifications).length === 0 && (
                <NoNotifications title="You Don’t have notification" />
              )}
            </Box>
          </Scrollbar>
        </CardContent>
      </Card>
    </>
  );
};
