import { Backdrop, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import React from 'react';
import { Alert, Modal, ModalBody } from 'reactstrap';
import checked from '../../assets/lordicon-icons/wired-lineal-37-approve-checked-simple.gif';
import errorImg from '../../assets/lordicon-icons/wired-outline-1140-error.gif';

const ResponseModal = (props) => {
  let { setResponse, response } = props;
  const onCloseClick = () => {
    setResponse({ message: '', open: false, statusCode: null });
  };
  return (
    <>
      <Modal
        fade={true}
        isOpen={response?.open}
        toggle={onCloseClick}
        centered={true}
      >
        <ModalBody className="py-3 px-3">
          <div
            className="mb-3"
            style={{ textAlign: 'right' }}
            onClick={onCloseClick}
          >
            <Icon icon="ic:round-close" style={{ cursor: 'pointer' }} />
          </div>
          <div className="mt-2 text-center">
            {response.statusCode === 200 ? (
              <img width={'100px'} src={checked} alt="Success" />
            ) : response.statusCode ? (
              <img width={'100px'} src={errorImg} alt="Error" />
            ) : (
              ''
            )}

            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>{response?.message}</h4>
              {/* <p className="text-muted mx-4 mb-0">You have Successfully</p> */}
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className={`btn w-sm ${
                response.statusCode === 200 ? 'btn-success' : 'btn-primary'
              }`}
              id="delete-record"
              onClick={onCloseClick}
            >
              Okay
            </button>
          </div>
        </ModalBody>
        {/* <Backdrop sx={{ color: '#fff' }} open={successState?.spinFlag}>
          <CircularProgress color="inherit" />
        </Backdrop> */}
      </Modal>
    </>
  );
};
export default ResponseModal;
