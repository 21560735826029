import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Scrollbar from '../../../components/scrollbar';
import {
  Card,
  Button,
  CardHeader,
  CardContent,
  Avatar,
  Badge,
  Grid,
  Box,
  CircularProgress,
  Backdrop,
} from '@mui/material';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { UnitCard } from '../../../components/unit-card';
import { DataNotAvailable } from '../../../components/data-not-found/index';
import { toBePartiallyChecked } from '@testing-library/jest-dom/matchers';
import { unitHistoryList } from '../../../services/apiServices';
import { useResponsive } from '../../../hooks/useResponsive';
import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';

export const UnitHistory = (props) => {
  let { setMyListRender, myListRender, title } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [value, setValue] = useState('1');
  const [spinFlag, setSpinFlag] = useState();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [unitHistory, setUnitHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusCode, setStatusCode] = useState();
  const isMobileMode = useResponsive('down', 'sm');

  useEffect(() => {
    getUnitHistory();
  }, [myListRender]);

  const getUnitHistory = async () => {
    try {
      setSpinFlag(true);
      let payload = { currentPage: currentPage };
      let { data } = await unitHistoryList(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        console.log('data.unitHistoryList', data.unitHistoryList);
        setUnitHistory(data.unitHistoryList);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  return (
    <>
      {/* <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <DialogPopup
        open={open}
        setOpen={setOpen}
        message={message}
        size="xs"
        statusCode={statusCode}
      />
      <Card>
        <CardHeader
          title={title}
          action={
            <Button
              size="small"
              color="primary"
              component={Link}
              to="/dashboard/unit-history"
            >
              See all
            </Button>
          }
          sx={{ pb: 2 }}
        />
        {!isMobileMode ? (
          <Scrollbar style={{ height: '295px' }}>
            <CardContent sx={{ py: 0 }}>
              <Grid container spacing={2}>
                {unitHistory.length !== 0 ? (
                  unitHistory.map((item, index) => {
                    return (
                      <Grid item md={12} xs={12}>
                        <UnitCard
                          key={index}
                          favHistoryData={item}
                          setRenderFlag={setMyListRender}
                          renderFlag={myListRender}
                        />
                      </Grid>
                    );
                  })
                ) : (
                  <Grid item md={12} xs={12}>
                    <DataNotAvailable
                      sx={{ p: 2, minHeight: 270 }}
                      title={"You don't have unit history"}
                    />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Scrollbar>
        ) : (
          <CardContent sx={{ py: 0 }}>
            <Box sx={{ pb: 2 }}>
              {unitHistory.length !== 0 ? (
                <Slider {...settings}>
                  {unitHistory.map((item, index) => {
                    return (
                      <Box
                        sx={{
                          display: 'block !important',
                          m: '0 auto',
                        }}
                      >
                        <UnitCard
                          key={index}
                          favHistoryData={item}
                          setRenderFlag={setMyListRender}
                          renderFlag={myListRender}
                        />
                      </Box>
                    );
                  })}
                </Slider>
              ) : (
                <Box>
                  <DataNotAvailable
                    sx={{ p: 2, minHeight: 270 }}
                    title={"You don't have unit history"}
                  />
                </Box>
              )}
            </Box>
          </CardContent>
        )}
      </Card>
    </>
  );
};
