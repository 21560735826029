import React, { useState, useEffect } from 'react';
import {
  Card,
  TextField,
  Button,
  Stack,
  Grid,
  Backdrop,
  CircularProgress,
  CardContent,
  FormHelperText,
} from '@mui/material';
import { Formik, useFormik, Form, FormikProvider } from 'formik';
import { addPaymentCardSchema } from '../../../utils/validation/payment';
import { REGEX } from '../../../utils/regex';
import { useNavigate, useLocation } from 'react-router-dom';
import { addPaymentCard, getCardForEdit } from '../../../services/apiServices';
import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';

export const AddPaymentMethod = () => {
  const { state } = useLocation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [isValid, setIsValid] = useState(false);
  const [spinFlag, setSpinFlag] = useState(false);
  const [open, setOpen] = useState();
  const [message, setMessage] = useState();
  const [statusCode, setStatusCode] = useState();

  const initialValues = {
    cardHolderName: '',
    expirationDate: '',
    cardNumber: '',
    cvv: '',
  };

  const onSavePaymentCard = async (values) => {
    try {
      setSpinFlag(true);
      values.cardId = state ? state.id : null;
      let { data } = await addPaymentCard(values);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        navigate(`/payment`);
        // setOpen(true);
        // setMessage(data.message);
        setStatusCode(data.statusCode);
      } else {
        setOpen(true);
        setSpinFlag(false);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const fetchCardForEdit = async () => {
    try {
      setSpinFlag(true);
      let payload = { cardId: state.id };
      let { data } = await getCardForEdit(payload);
      if (data.statusCode === 200) {
        let { cardHolderName, expirationDate, cardNumber, cvv } = data.card;
        setSpinFlag(false);
        setFieldValue('cardHolderName', cardHolderName);
        setFieldValue('expirationDate', expirationDate);
        setFieldValue('cardNumber', cardNumber);
        setFieldValue('cvv', cvv);
        setIsValid(true);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  useEffect(() => {
    if (state) {
      fetchCardForEdit();
    }
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: addPaymentCardSchema,
    onSubmit: async (values) => {
      if (isValid) {
        onSavePaymentCard(values);
      }
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    handleChange,
    handleBlur,
    setFieldValue,
    resetForm,
    values,
  } = formik;

  const handleExpiryDateChange = (e) => {
    let inputExpiryDate = e.target.value;

    // Remove any non-digit characters except the first slash
    const sanitizedExpiryDate = inputExpiryDate.replace(/\D/g, '');

    // Format with a slash after two digits
    const formattedExpiryDate = sanitizedExpiryDate.replace(
      /^(\d{2})(\d{0,2})/,
      (_, mm, yy) => `${mm}${yy ? '/' : ''}${yy}`
    );
    setFieldValue('expirationDate', formattedExpiryDate);
    const isValidExpiryDate = validateExpiryDate(formattedExpiryDate);
    setIsValid(isValidExpiryDate);
  };

  const validateExpiryDate = (expiryDate) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are 0-based

    const [inputMonth, inputYear] = expiryDate
      .split('/')
      .map((part) => parseInt(part, 10));

    // Convert 2-digit year to full year (assuming a 20-year window)
    const fullInputYear = currentYear - (currentYear % 100) + inputYear;

    if (
      fullInputYear < currentYear ||
      (fullInputYear === currentYear && inputMonth < currentMonth)
    ) {
      return false; // Card is already expired
    }

    if (inputMonth < 1 || inputMonth > 12) {
      return false; // Invalid month
    }

    return true;
  };

  return (
    <Card
      sx={{
        mb: 5,
      }}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogPopup
        open={open}
        setOpen={setOpen}
        message={message}
        size="xs"
        statusCode={statusCode}
      />
      <CardContent
        sx={{
          p: 5,
        }}
      >
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="Text"
                    label="Card Holder Name"
                    name="cardHolderName"
                    {...getFieldProps('cardHolderName')}
                    error={Boolean(
                      touched.cardHolderName && errors.cardHolderName
                    )}
                    helperText={touched.cardHolderName && errors.cardHolderName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="Text"
                    label="Card Number"
                    name="cardNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cardNumber.replace(/(\d{4})(?=\d)/g, '$1 ')}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(REGEX.RESTRICT_CHAR, '')
                        .slice(0, 19);
                    }}
                    // {...getFieldProps('cardNumber')}
                    error={Boolean(touched.cardNumber && errors.cardNumber)}
                    helperText={touched.cardNumber && errors.cardNumber}
                  ></TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="Text"
                    label="Expired on"
                    name="expirationDate"
                    placeholder="MM/YY"
                    onChange={handleExpiryDateChange}
                    onBlur={handleBlur}
                    value={values.expirationDate}
                    // onInput={(e) => {
                    //   e.target.value = e.target.value
                    //     .replace(/[^0-9/]/g, '')
                    //     .slice(0, 5);
                    // }}
                    // value={values.expirationDate}
                    error={Boolean(
                      touched.expirationDate && errors.expirationDate
                    )}
                    helperText={touched.expirationDate && errors.expirationDate}
                  ></TextField>
                  {!errors.expirationDate &&
                    !isValid &&
                    touched.expirationDate && (
                      <FormHelperText error>
                        Please enter valid date
                      </FormHelperText>
                    )}
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer sx={{ p: 0 }} components={['DatePicker']}>
                      <DatePicker
                        label="Expired on"
                        format="DD-MM-YYYY"
                        onChange={handleDobInput}
                        name="expirationDate"
                        {...getFieldProps('date')}
                        error={Boolean(
                          touched.expirationDate && errors.expirationDate
                        )}
                        helperText={
                          touched.expirationDate && errors.expirationDate
                        }
                      />
                    </DemoContainer>
                  </LocalizationProvider> */}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="Text"
                    label="3 Digits CVV"
                    name="cvv"
                    {...getFieldProps('cvv')}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(REGEX.RESTRICT_CHAR, '')
                        .slice(0, 3);
                    }}
                    error={Boolean(touched.cvv && errors.cvv)}
                    helperText={touched.cvv && errors.cvv}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ px: 5 }}
                  >
                    Save New Card
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </Form>
        </FormikProvider>
      </CardContent>
    </Card>
  );
};
