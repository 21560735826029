import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Container,
  Typography,
  Stack,
  Divider,
  Rating,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import SvgColor from '../../components/svg-color';
import Bed from '../../assets/icons/Bed.svg';
import Like from '../../assets/icons/Like.svg';
import Bathroom from '../../assets/icons/Bathroom.svg';
import bannerImg from '../../assets/images/bannerImg.png';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import mapMarkFill from '../../assets/icons/mapMarkFill.svg';
import { Icon } from '@iconify/react';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconEmpty': {
    color: '#D3D3DC',
  },
  '& .MuiRating-iconFilled': {
    color: '#FFBD3E',
  },
  '& .MuiRating-iconHover': {
    color: '#FFBD3E',
  },
});
const TopMap = styled(Box)(({ theme }) => ({
  height: '275px',
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

const BannerRight = styled(Box)(({ theme }) => ({
  '& img': {
    display: 'block',
    width: '100%',
    aspectRatio: '1/0.95',
  },
}));

const PropertyTitle = (props) => {
  let { propertyData, propertyUnits } = props;
  let { title, address, latitude, longitude, rating } = propertyData;

  const [center, setCenter] = useState({
    lat: 37.0902,
    lng: 95.7129,
  });

  useEffect(() => {
    setCenter({ lat: latitude, lng: longitude });
  }, [latitude, longitude]);

  return (
    <>
      <Box>
        <TopMap>
          <Map google={props.google} zoom={3} center={center}>
            <Marker name="marker" position={center} icon={mapMarkFill}></Marker>
          </Map>
        </TopMap>
        <Container fixed={true} sx={{ maxWidth: { xl: '1326px' } }}>
          <Box sx={{ py: { md: 5, xs: 3 }, textTransform: 'capitalize' }}>
            <Grid container>
              <Grid item md={7} xs={12}>
                <Box sx={{ mb: { md: 0, xs: 2 } }}>
                  <Typography
                    variant="h3"
                    noWrap
                    sx={{
                      color: '#323232',
                      lineHeight: 'normal',
                      fontWeight: '500',
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="body1"
                    noWrap
                    sx={{ color: '#6D6D6D', lineHeight: 'normal', mt: 1 }}
                  >
                    {address}
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={5} xs={12}>
                <Stack
                  sx={{
                    alignItems: { md: 'end', xs: 'start' },
                  }}
                >
                  <StyledRating
                    defaultValue={rating ? rating : 0}
                    value={rating ? rating : 0}
                    // defaultValue={1}
                    readOnly
                    icon={<Icon icon="ph:star-fill" />}
                    emptyIcon={<Icon icon="ph:star-fill" />}
                    sx={{
                      fontSize: {
                        xs: '1rem',
                        md: '1rem',
                        lg: '1.125rem',
                        xl: '1.25rem',
                      },
                    }}
                  />
                  {propertyUnits.length === 1 ? (
                    <Stack direction="row" sx={{ marginTop: 2 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: 'grey.500',
                          ml: { md: 2 },
                          mr: { md: 0, xs: 2 },
                        }}
                      >
                        <Box
                          sx={{
                            background: '#E9EAF0',
                            width: '32px',
                            height: '32px',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 1,
                          }}
                        >
                          <SvgColor
                            sx={{
                              color: '#82838C',
                              display: 'block',
                              width: '15px',
                              height: '15px',
                            }}
                            src={Bed}
                          />
                        </Box>
                        <Typography variant="body2">
                          {propertyUnits[0].bedrooms} Bedroom
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: 'grey.500',
                          ml: { md: 2 },
                          mr: { md: 0, xs: 2 },
                        }}
                      >
                        <Box
                          sx={{
                            background: '#E9EAF0',
                            width: '32px',
                            height: '32px',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 1,
                          }}
                        >
                          <SvgColor
                            sx={{
                              color: '#82838C',
                              display: 'block',
                              width: '15px',
                              height: '15px',
                            }}
                            src={Bathroom}
                          />
                        </Box>
                        <Typography variant="body2">
                          {propertyUnits[0].bathrooms} Bathroom
                        </Typography>
                      </Box>
                    </Stack>
                  ) : null}
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Divider />
        </Container>
      </Box>
    </>
  );
};
//
export default GoogleApiWrapper({
  apiKey: 'AIzaSyAgcJ-JZYcJwVIFeJyaAOI4b5aap7T2I3w',
})(PropertyTitle);
