import { Grid, Box, Container, Typography, Button, Stack } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import bannerImg from '../../assets/images/bannerImg.png';
import { Link } from 'react-router-dom';
import { IMG_URL } from '../../utils/constant';

const StylePopular = styled(Box)(({ theme }) => ({
  padding: '50px 0',
  [theme.breakpoints.down('lg')]: {
    padding: '56px 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: '40px 0',
  },
}));
const BannerLeftText = styled(Box)(({ theme }) => ({
  paddingBottom: '40px',
  [theme.breakpoints.down('md')]: {
    paddingBottom: '20px',
  },
  '& .MuiTypography-h2': {
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
      marginBottom: '20px',
    },
  },
  '& .MuiTypography-h6': {
    [theme.breakpoints.down('xl')]: {
      fontSize: '1.0625rem',
    },
  },
}));
const BannerLeft = styled(Box)(({ theme }) => ({}));
const HostImages = styled(Box)(({ theme }) => ({}));

export const UnlimitedListingSection = (props) => {
  let { becomeHostContents, convenience } = props;
  return (
    <>
      <StylePopular>
        <Container fixed={true} sx={{ maxWidth: { xl: '1326px' } }}>
          <Grid
            container
            spacing={3}
            sx={{
              alignItems: 'center',
              flexDirection: { md: 'row', xs: 'column-reverse' },
            }}
          >
            <Grid item md={6} xs={12}>
              <BannerLeft>
                <BannerLeftText>
                  {convenience && convenience?.length > 0 && (
                    <>
                      <Typography variant="h2" sx={{ mb: 4 }}>
                        {convenience[0]?.title}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 'normal', color: 'text.secondary' }}
                      >
                        {convenience[0]?.description}
                      </Typography>
                    </>
                  )}
                  <Stack direction="row" marginTop="32px" gap="24px">
                    {/* <Button
                      variant="contained"
                      size="large"
                      sx={{ minWidth: '195px' }}
                      component={Link}
                      to="/"
                    >
                      Learn more
                    </Button> */}
                  </Stack>
                </BannerLeftText>
              </BannerLeft>
            </Grid>
            <Grid item md={6} xs={12}>
              <HostImages>
                <Box
                  sx={{
                    borderRadius: '22px',
                    overflow: 'hidden',
                    width: '100%',
                    aspectRatio: '1.18',
                  }}
                >
                  {convenience && convenience?.length > 0 && (
                    <>
                      {convenience[0].bannerImage ? (
                        <img
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            display: 'block',
                          }}
                          src={`${IMG_URL}${convenience[0].bannerImage}`}
                        />
                      ) : null}
                    </>
                  )}
                </Box>
              </HostImages>
            </Grid>
          </Grid>
        </Container>
      </StylePopular>
    </>
  );
};
