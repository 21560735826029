import React, { useState } from 'react';
import { Collapse, IconButton, TableContainer, Paper } from '@mui/material';
import { Input } from 'reactstrap';
import { Icon } from '@iconify/react';
import { IMG_URL } from '../../../../utils/constant';
import { currencySeprator } from '../../../../utils/common-function';
import Scrollbar from '../../../../components/scrollbar';
import { nairaSymbol } from '../../../../utils/constant';

const Row = (props) => {
  const { unitsItem, onClickActiveInactiveUser } = props;
  let {
    _id,
    unitName,
    price,
    bedrooms,
    bathrooms,
    rules,
    amenities,
    durationName,
    isActive,
  } = unitsItem;
  // console.log('-id---', _id);
  const [open, setOpen] = useState(false);
  return (
    <>
      <tr>
        <td
          style={{
            padding: 0,
            border: '1px solid rgba(145, 158, 171, 0.16)',
          }}
          colSpan={6}
        >
          <table className="w-100 m-0">
            <tr>
              <td
                style={{
                  border: 'none',
                  padding: '0.75rem 0.6rem',
                  width: 200,
                  maxWidth: 200,
                }}
              >
                <h6 className="m-0">{unitName}</h6>
              </td>
              <td
                style={{
                  border: 'none',
                  padding: '0.75rem 0.6rem',
                  width: 100,
                  maxWidth: 100,
                }}
              >
                {durationName === 'year' ? (
                  <>
                    {nairaSymbol}
                    {currencySeprator(Math.trunc(price / 12))}
                  </>
                ) : durationName === 'sixMonth' ? (
                  <>
                    {nairaSymbol}
                    {currencySeprator(Math.trunc(price / 6))}
                  </>
                ) : durationName === 'threeMonth' ? (
                  <>
                    {nairaSymbol}
                    {currencySeprator(Math.trunc(price / 3))}
                  </>
                ) : durationName === 'month' ? (
                  <>
                    {nairaSymbol}
                    {currencySeprator(price)}
                  </>
                ) : null}{' '}
                /month
              </td>
              <td
                style={{
                  border: 'none',
                  padding: '0.75rem 0.6rem',
                  width: 100,
                  maxWidth: 100,
                }}
              >
                {bedrooms}
              </td>
              <td
                style={{
                  border: 'none',
                  padding: '0.75rem 0.6rem',
                  width: 100,
                  maxWidth: 100,
                }}
              >
                {bathrooms}
              </td>
              <td
                style={{
                  border: 'none',
                  padding: '0.75rem 0.6rem',
                  width: 100,
                  maxWidth: 100,
                }}
              >
                <div className="form-check form-switch">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    //checked={true}
                    checked={isActive || false}
                    onClick={() =>
                      onClickActiveInactiveUser(_id, isActive, unitName)
                    }
                  />
                </div>
              </td>
              <td
                style={{
                  border: 'none',
                  padding: '0.75rem 0.6rem',
                  width: 50,
                  maxWidth: 50,
                }}
              >
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? (
                    <Icon icon="iconoir:nav-arrow-up" />
                  ) : (
                    <Icon icon="iconoir:nav-arrow-down" />
                  )}
                </IconButton>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: 'none',
                }}
                colSpan={6}
              >
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <div
                    className="p-3"
                    style={{
                      borderTop: '1px dashed rgba(145, 158, 171, 0.16)',
                    }}
                  >
                    <div>
                      <h5>Offers</h5>
                      <ul className="list-unstyled">
                        {amenities.map((item, index) => {
                          return (
                            <li className="d-flex mb-1" key={index}>
                              <div className="flex-shrink-0 me-1">
                                <div className="avatar-xs">
                                  <img
                                    style={{
                                      width: '18px',
                                      height: '18px',
                                      objectFit: 'contain',
                                    }}
                                    src={`${IMG_URL}${item.path}`}
                                  />
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <p className="fs-14 m-0">{item.amenities}</p>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="mt-3">
                      <h5>Rules</h5>
                      <ul className="list-unstyled">
                        {rules.map((item, index) => {
                          return (
                            <li key={index} sx={{ p: 0 }}>
                              {item}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </Collapse>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </>
  );
};

export const PropertyUnitList = (props) => {
  let { propertyUnits, onClickActiveInactiveUser } = props;
  return (
    <Scrollbar>
      <TableContainer component={Paper} sx={{ minWidth: 550 }}>
        <table className="table mb-0" aria-label="collapsible table">
          <thead>
            <tr>
              <th
                style={{
                  background: 'rgb(244, 246, 248)',
                  width: 200,
                  maxWidth: 200,
                }}
              >
                Units name/no.
              </th>
              <th
                style={{
                  background: 'rgb(244, 246, 248)',
                  width: 100,
                  maxWidth: 100,
                }}
              >
                Price
              </th>
              <th
                style={{
                  background: 'rgb(244, 246, 248)',
                  width: 100,
                  maxWidth: 100,
                }}
              >
                Bedrooms
              </th>
              <th
                style={{
                  background: 'rgb(244, 246, 248)',
                  width: 100,
                  maxWidth: 100,
                }}
              >
                Bathrooms
              </th>
              <th
                style={{
                  background: 'rgb(244, 246, 248)',
                  width: 100,
                  maxWidth: 100,
                }}
              >
                Status
              </th>
              <th
                style={{
                  background: 'rgb(244, 246, 248)',
                  width: 50,
                  maxWidth: 50,
                }}
              />
            </tr>
          </thead>
          <tbody>
            {propertyUnits?.map((item, index) => (
              <Row
                key={index}
                unitsItem={item}
                onClickActiveInactiveUser={onClickActiveInactiveUser}
              />
            ))}
          </tbody>
        </table>
      </TableContainer>
    </Scrollbar>
  );
};

export default PropertyUnitList;
