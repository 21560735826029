import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';

const Widgets = (props) => {
  let {
    separator,
    end,
    decimals,
    label,
    bgcolor,
    icon,
    role,
    tab,
    link,
    prefix,
  } = props;
  return (
    <React.Fragment>
      {/* <Link to={link ? link : null}> */}
      <Card className="card-animate">
        <CardBody>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1 overflow-hidden">
              <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                <small>{label}</small>
              </p>
            </div>
            <div className="flex-shrink-0">
              {/* <h5 className="text-success fs-14 mb-0">
                  <i className="ri-arrow-right-up-line fs-13 align-middle"></i>
                  +16.24 %
                </h5> */}
              {link && (
                <Link className="btn btn-sm" to={link ? link : null}>
                  See all{' '}
                  <i className="ri-arrow-right-line fs-13 align-middle"></i>
                </Link>
              )}
            </div>
          </div>
          <div className="d-flex align-items-end justify-content-between mt-4">
            <div>
              <h4 className="fs-22 fw-semibold ff-secondary mb-3">
                <span className="counter-value">
                  <CountUp start={0} prefix={prefix} end={end} duration={3} />
                </span>
              </h4>
            </div>
            <div className="avatar-sm flex-shrink-0">
              <span className={'avatar-title rounded fs-3 bg-soft-' + bgcolor}>
                <i className={`text-${bgcolor} ${icon}`}></i>
              </span>
            </div>
          </div>
        </CardBody>
      </Card>
      {/* </Link> */}
    </React.Fragment>
  );
};

export default Widgets;
