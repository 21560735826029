import React, { useState, useEffect } from 'react';
import {
  Card,
  TextField,
  Button,
  MenuItem,
  Grid,
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  List,
  ListItemButton,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  OutlinedInput,
  Checkbox,
  Tooltip,
  FormHelperText,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useFormik, Form, FormikProvider, ErrorMessage } from 'formik';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import Scrollbar from '../../../components/scrollbar';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import SvgColor from '../../../components/svg-color';
import { GoogleApiWrapper } from 'google-maps-react';
import CurrencyFormat from 'react-currency-format';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getPropertyForEdit,
  updateProperty,
  fetchRulesAndAmenities,
} from '../../../services/apiServices';
import Dropzone from 'react-dropzone';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import { REGEX } from '../../../utils/regex';
import imgUpload from '../../../assets/icons/imgUpload.svg';
import { editPropertySchema } from '../../../utils/validation/Property';
import { IMG_URL } from '../../../utils/constant';
import { PROPERTY_MSG } from '../../../utils/messages/error';
import ArrowIconLeft from '../../../assets/icons/ArrowIconLeft.svg';
import ArrowIconRight from '../../../assets/icons/ArrowIconRight.svg';
import { priceOptions, propertyOptions } from '../../../utils/optionsForSelect';
import { GOOGLE_MAP_KEY } from '../../../utils/constant';
import { getNumberWithoutComma } from '../../../utils/common-function';
import { HeaderBreadcrumbs } from '../../../components/breadcrumbs';

const MultipleFormControl = styled(FormControl)(({ theme }) => ({
  flexGrow: '1',
  '& .MuiInputLabel-root': {
    fontWeight: '600',
    fontSize: '14px',
    color: '#9CA3AF',
    '&.MuiFormLabel-filled, &.Mui-focused': {
      transform: 'translate(14px, 2px) scale(1)',
      fontWeight: '400',
      fontSize: '12px',
    },
  },
  '& .MuiOutlinedInput-notchedOutline span': {
    display: 'none',
  },
}));

const StyledSelect = styled(OutlinedInput)(({ theme }) => ({
  flexGrow: '1',
  borderRadius: 14,
  height: 56,
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const CarouselDiv = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  '& .slick-slider': {
    height: '100%',
    width: '100%',
    '& .slick-list': {
      height: '100%',
      width: '100%',
      '& .slick-track': {
        height: '100%',
        '& .slick-slide': {
          '&>div': {
            height: '100%',
            width: '100%',
          },
        },
      },
    },
    '& .slick-arrow': {
      fontSize: '0',
      width: '20px',
      height: '20px',
      zIndex: '1',
      transition: '0.45s ease-in-out',
      borderRadius: '50%',
      opacity: '0.2',
      '&:before': {
        fontSize: '0',
      },
      '&.slick-prev': {
        background: `rgba(255,255,255,0.8) url(${ArrowIconLeft}) no-repeat center center / cover`,
        left: '5px',
      },
      '&.slick-next': {
        background: `rgba(255,255,255,0.8) url(${ArrowIconRight}) no-repeat center center / cover`,
        right: '5px',
      },
      '&.slick-disabled': {
        display: 'none !important',
      },
    },
    '&:hover': {
      '& .slick-arrow': {
        opacity: '1',
      },
    },
    '& .slick-dots': {
      bottom: '0',
      '& li': {
        margin: '0',
        '& button:before': {
          fontSize: '22px',
          color: '#fff',
        },
      },
    },
  },
}));

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  dots: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

const EditProperty = () => {
  const { state } = useLocation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [spinFlag, setSpinFlag] = useState();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [statusCode, setStatusCode] = useState();
  const [deletedImageArr, setDeletedImageArr] = useState([]);
  const [deletedPropertyUnits, setDeletedPropertyUnits] = useState([]);
  const [rules, setRules] = useState([]);
  const [amenities, setAmenities] = useState([]);

  const rulesOptions = rules.map((item) => ({
    label: item.rules,
    path: item.path,
  }));

  const amenitiesOptions = amenities.map((item) => ({
    label: item.amenities,
    path: item.path,
  }));

  const [data, setData] = useState({
    title: '',
    propertyType: '',
    description: '',
    address: '',
    city: '',
    country: '',
    zip: '',
    amenities: [],
    unitArray: [
      {
        unitName: '',
        amenities: [],
        rules: [],
        price: '',
        durationName: '',
        bedrooms: '',
        bathrooms: '',
        availableFrom: '',
      },
    ],
    // priceArr: [
    //   {
    //     price: '',
    //     durationName: '',
    //   },
    // ],
    // bedrooms: '',
    // bathrooms: '',
    rules: [],
    // availableFrom: '',
    latitude: '',
    longitude: '',
    propertyImg: [],
    // checkInStartTime: '',
    // checkInEndTime: '',
    // checkOutTime: '',
  });

  const updateData = async (values) => {
    try {
      setSpinFlag(true);
      let propertyData = new FormData();

      values.unitArray.map((item) => {
        item.price = getNumberWithoutComma(item.price);
      });

      propertyData.append('editableObj', JSON.stringify(values));
      propertyData.append('deletedImgArr', JSON.stringify(deletedImageArr));
      propertyData.append(
        'deletedUnitsArr',
        JSON.stringify(deletedPropertyUnits)
      );
      propertyData.append('propertyId', state.id);
      values.propertyImg.map((file) => {
        file.path ? propertyData.append('propertyImages', file) : null;
      });

      const { data } = await updateProperty(propertyData);
      if (data.statusCode === 200) {
        let {
          title,
          propertyType,
          description,
          address,
          city,
          country,
          zip,
          amenities,
          bedrooms,
          bathrooms,
          rules,
          availableFrom,
          priceCategory,
          checkInStartTime,
          checkInEndTime,
          checkOutTime,
          longitude,
          latitude,
        } = data.updatedProperty;

        setData({
          title: title,
          propertyType: propertyType,
          description: description,
          address: address,
          city: city,
          longitude: longitude,
          latitude: latitude,
          country: country,
          zip: zip,
          amenities: amenities,
          unitArray: data?.propertyUnits,
          // bedrooms: bedrooms,
          // bathrooms: bathrooms,
          rules: rules,
          // availableFrom: dayjs(availableFrom),
          propertyImg: data?.updatedPropertyImages,
          // priceCategory: priceCategory,
          // checkInStartTime: checkInStartTime ? checkInStartTime : '',
          // checkInEndTime: checkInEndTime ? checkInEndTime : '',
          // checkOutTime: checkOutTime ? checkOutTime : '',
        });
        // setOpen(true);
        // setMessage(data.message);
        setSpinFlag(false);

        navigate('/dashboard');
        setStatusCode(data.statusCode);
      } else {
        setOpen(true);
        setMessage(data.message);
        setSpinFlag(false);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: editPropertySchema,
    onSubmit: async (values) => {
      updateData(values);
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    handleChange,
    handleBlur,
  } = formik;

  // function to fetch porperty when user comes first in this page
  const fetchProperty = async () => {
    try {
      setSpinFlag(true);
      let payload = { propertyId: state.id };
      const { data } = await getPropertyForEdit(payload);

      if (data.statusCode === 200) {
        let {
          title,
          propertyType,
          description,
          address,
          city,
          country,
          zip,
          amenities,
          // bedrooms,
          // bathrooms,
          rules,
          // availableFrom,
          // priceCategory,
          // checkInStartTime,
          // checkInEndTime,
          // checkOutTime,
          latitude,
          longitude,
        } = data.propertyData;

        setData({
          title: title,
          propertyType: propertyType,
          description: description,
          address: address,
          city: city,
          country: country,
          longitude: longitude,
          latitude: latitude,
          zip: zip,
          amenities: amenities,
          unitArray: data?.propertyUnits,
          // bedrooms: bedrooms,
          // bathrooms: bathrooms,
          rules: rules,
          // availableFrom: dayjs(availableFrom),
          propertyImg: data?.propertyImage,
          // checkInStartTime: checkInStartTime ? checkInStartTime : '',
          // checkInEndTime: checkInEndTime ? checkInEndTime : '',
          // checkOutTime: checkOutTime ? checkOutTime : '',
        });
        setSpinFlag(false);
      } else {
        setOpen(true);
        setMessage(data.message);
        setSpinFlag(false);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const rulesAndAmenities = async () => {
    try {
      const { data } = await fetchRulesAndAmenities();

      if (data.statusCode === 200) {
        setRules(data.rules);
        setAmenities(data.amenities);
      } else {
        setOpen(true);
        setMessage(data.message);
        setSpinFlag(false);
      }
    } catch (err) {
      navigate(
        `/error?title=${PROPERTY_MSG.WRONG}&&msg=${err}&&path=${pathname}`
      );
    }
  };

  const handleavailableDate = (e) => {
    let date = new Date(e.$d),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    let nDate = [date.getFullYear(), mnth, day].join('-');
    return nDate;
  };

  const handleAddress = async (value) => {
    let result = await geocodeByAddress(value);
    result = result[0];
    const latLng = await getLatLng(result);
    const { long_name: country = '' } =
      result.address_components.find((c) => c.types.includes('country')) || {};
    const { long_name: city = '' } =
      result.address_components.find((c) => c.types.includes('locality')) || {};
    const { long_name: zip = '' } =
      result.address_components.find((c) => c.types.includes('postal_code')) ||
      {};
    setFieldValue('address', value);
    setFieldValue('country', country);
    setFieldValue('city', city);
    setFieldValue('zip', zip);
    setFieldValue('latitude', latLng.lat);
    setFieldValue('longitude', latLng.lng);
  };

  useEffect(() => {
    fetchProperty();
    rulesAndAmenities();
  }, []);

  return (
    <>
      <HeaderBreadcrumbs
        heading="Edit listing"
        links={[
          { name: 'Dashboard', href: '/dashboard' },
          { name: 'Edit listing' },
        ]}
      />
      <Box>
        <DialogPopup
          open={open}
          setOpen={setOpen}
          message={message}
          size="xs"
          statusCode={statusCode}
        />
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Card
              sx={{
                p: { md: 4, sm: 3, xs: 2 },
                mb: 5,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography sx={{ mb: 3 }} variant="h5">
                      Upload photo of your property
                    </Typography>
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        if (acceptedFiles.length === 0) {
                          return;
                        }
                        setFieldValue(
                          'propertyImg',
                          values.propertyImg.concat(
                            acceptedFiles.map((file) =>
                              Object.assign(file, {
                                preview: URL.createObjectURL(file),
                              })
                            )
                          )
                        );
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <Box
                          sx={{
                            color: '#9CA3AF',
                            border: '1px dashed #D1D5DB',
                            borderRadius: '14px',
                            padding: {
                              md: '42px 16px',
                              xs: '20px 16px',
                            },
                            maxWidth: '472px',
                            m: '0 auto',
                          }}
                        >
                          <Box {...getRootProps()}>
                            <input {...getInputProps()} />
                            <SvgColor
                              src={imgUpload}
                              alt={imgUpload}
                              sx={{
                                width: '44px',
                                height: '44px',
                                mb: 3,
                              }}
                            />
                            <Typography variant="h5">
                              Drag and drop image or
                            </Typography>
                            <Button
                              variant="contained"
                              size="large"
                              sx={{ px: 5, my: 2 }}
                            >
                              Browser file
                            </Button>
                            <Typography component="p" variant="caption">
                              Photo must be JPEG or PNG format and least
                              1024x768
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Dropzone>
                    <ErrorMessage
                      error
                      component={FormHelperText}
                      name={'propertyImg'}
                      sx={{ textAlign: 'center' }}
                    />
                    {values.propertyImg.length === 0 ? (
                      ''
                    ) : (
                      <CarouselDiv>
                        <Slider {...settings}>
                          {values.propertyImg.map((file, index) => (
                            <Box
                              key={index}
                              sx={{
                                px: 1,
                                py: 1,
                                position: 'relative',
                                '&:hover .MuiButtonBase-root': {
                                  opacity: '1',
                                },
                              }}
                            >
                              <img
                                src={
                                  file?.propertyImage
                                    ? `${IMG_URL}${file?.propertyImage}`
                                    : file.preview
                                }
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover',
                                  borderRadius: '14px',
                                  aspectRatio: '1.5',
                                }}
                                onLoad={() => {
                                  !file?.propertyImage
                                    ? URL.revokeObjectURL(file.preview)
                                    : '';
                                }}
                              />
                              {/* {values.propertyImg.length === 1 ? null : ( */}
                              <Tooltip title="Delete" placement="top" arrow>
                                <IconButton
                                  aria-label="delete"
                                  size="small"
                                  color="error"
                                  sx={{
                                    padding: '2px',
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                    bgcolor: '#fff !important',
                                  }}
                                  onClick={() => {
                                    values.propertyImg.splice(index, 1);
                                    file._id &&
                                      setDeletedImageArr((list) => [
                                        ...list,
                                        file,
                                      ]);

                                    values.propertyImg.map((imgFile) => {
                                      imgFile._id
                                        ? file
                                        : Object.assign(imgFile, {
                                            preview:
                                              URL.createObjectURL(imgFile),
                                          });
                                    });
                                    setFieldValue(
                                      `propertyImg`,
                                      values.propertyImg
                                    );
                                  }}
                                >
                                  <Icon icon="iconamoon:close-light" />
                                </IconButton>
                              </Tooltip>
                              {/* )} */}
                            </Box>
                          ))}
                        </Slider>
                      </CarouselDiv>
                    )}
                  </Box>
                </Grid>
                <Grid item md={6} xs={12} container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h5">Property description</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="Text"
                      label="Title*"
                      name="title"
                      {...getFieldProps('title')}
                      // error={Boolean(touched.title && errors.title)}
                      // helperText={touched.title && errors.title}
                    />
                    <ErrorMessage
                      error
                      component={FormHelperText}
                      name={'title'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      select
                      fullWidth
                      label="Type property"
                      name="propertyType"
                      {...getFieldProps('propertyType')}
                      // error={Boolean(
                      //   touched.propertyType && errors.propertyType
                      // )}
                      // helperText={touched.propertyType && errors.propertyType}
                    >
                      {propertyOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <ErrorMessage
                      error
                      component={FormHelperText}
                      name={'propertyType'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      rows="4"
                      fullWidth
                      label="Description"
                      name="description"
                      {...getFieldProps('description')}
                      // error={Boolean(touched.description && errors.description)}
                      // helperText={touched.description && errors.description}
                    />
                    <ErrorMessage
                      error
                      component={FormHelperText}
                      name={'description'}
                    />
                  </Grid>
                </Grid>

                <Grid item md={6} xs={12} container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h5">Listing location</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {/* map code start */}
                    <PlacesAutocomplete
                      fullWidth
                      value={values.address}
                      onChange={(value) => {
                        setFieldValue('address', value);
                        setFieldValue('country', '');
                        setFieldValue('city', '');
                        setFieldValue('zip', '');
                      }}
                      onSelect={(value) => {
                        handleAddress(value);
                      }}
                      searchOptions={{
                        componentRestrictions: { country: [] },
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <Box
                          sx={{
                            width: '100%',
                            position: 'relative',
                          }}
                        >
                          <TextField
                            fullWidth
                            name="address"
                            {...getInputProps({
                              label: 'Search spaces',
                            })}
                          />
                          <Card
                            sx={{
                              position: 'absolute',
                              left: '0',
                              top: '100%',
                              width: '100%',
                              zIndex: '3',
                            }}
                          >
                            <Scrollbar style={{ maxHeight: '295px' }}>
                              <List sx={{ p: 0 }}>
                                {suggestions.map((suggestion, index) => {
                                  const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: '#fafafa',
                                        cursor: 'pointer',
                                      }
                                    : {
                                        backgroundColor: '#ffffff',
                                        cursor: 'pointer',
                                      };
                                  return (
                                    <ListItemButton
                                      key={index}
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </ListItemButton>
                                  );
                                })}
                              </List>
                            </Scrollbar>
                          </Card>
                        </Box>
                      )}
                    </PlacesAutocomplete>
                    {/* map code end */}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Country"
                      name="country"
                      {...getFieldProps('country')}
                      // error={Boolean(touched.country && errors.country)}
                      // helperText={touched.country && errors.country}
                    />
                    <ErrorMessage
                      error
                      component={FormHelperText}
                      name={'country'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="Text"
                      label="City"
                      name="city"
                      {...getFieldProps('city')}
                      // error={Boolean(touched.city && errors.city)}
                      // helperText={touched.city && errors.city}
                    />
                    <ErrorMessage
                      error
                      component={FormHelperText}
                      name={'city'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="ZIP"
                      name="zip"
                      {...getFieldProps('zip')}
                      // error={Boolean(touched.zip && errors.zip)}
                      // helperText={touched.zip && errors.zip}
                      onInput={(e) => {
                        e.target.value = e.target.value
                          .replace(REGEX.RESTRICT_CHAR, '')
                          .slice(0, 6);
                      }}
                    />
                    <ErrorMessage
                      error
                      component={FormHelperText}
                      name={'zip'}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h5">Property detail</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <MultipleFormControl fullWidth>
                      <InputLabel id="demo-multiple-chip-label">
                        Property's amenities
                      </InputLabel>
                      <Select
                        fullWidth
                        multiple
                        name="amenities"
                        {...getFieldProps('amenities')}
                        value={values.amenities}
                        input={
                          <StyledSelect
                            id="select-multiple-chip"
                            label="Property's amenities"
                          />
                        }
                        renderValue={(selected) => selected.join(', ')}
                      >
                        {amenitiesOptions.map((option, index) => (
                          <MenuItem key={index} value={option.label}>
                            <img
                              style={{
                                marginRight: '16px',
                                width: '24px',
                                height: '24px',
                                objectFit: 'contain',
                              }}
                              src={`${IMG_URL}${option.path}`}
                            />
                            <span
                              style={{
                                width: '100%',
                                display: 'block',
                              }}
                            >
                              {option.label}
                            </span>
                            <Checkbox
                              checked={
                                values.amenities.indexOf(option.label) > -1
                              }
                              sx={{
                                minWidth: '20px',
                                border: 'none',
                                transform: 'scale(.8)',
                              }}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </MultipleFormControl>
                    <ErrorMessage
                      error
                      component={FormHelperText}
                      name={'amenities'}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <MultipleFormControl fullWidth>
                      <InputLabel id="demo-multiple-chip-label">
                        Property rules
                      </InputLabel>
                      <Select
                        fullWidth
                        multiple
                        name="rules"
                        {...getFieldProps('rules')}
                        value={values.rules}
                        input={
                          <StyledSelect
                            id="select-multiple-chip"
                            label="Property's rules"
                          />
                        }
                        renderValue={(selected) => selected.join(', ')}
                      >
                        {rulesOptions.map((option, index) => (
                          <MenuItem key={index} value={option.label}>
                            <span
                              style={{
                                width: '100%',
                                display: 'block',
                              }}
                            >
                              {option.label}
                            </span>
                            <Checkbox
                              checked={values.rules.indexOf(option.label) > -1}
                              sx={{
                                minWidth: '20px',
                                border: 'none',
                                transform: 'scale(.8)',
                              }}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </MultipleFormControl>
                    <ErrorMessage
                      error
                      component={FormHelperText}
                      name={'rules'}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5">Listing detail</Typography>
                    </Grid>
                    {values.unitArray.map((unitItem, unitIndex) => (
                      <Grid item container xs={12} key={unitIndex} spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            type="Text"
                            label="Unit name*"
                            value={unitItem.unitName}
                            name={`unitArray[${unitIndex}].unitName`}
                            id={`unitArray[${unitIndex}].unitName`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            error
                            component={FormHelperText}
                            name={`unitArray[${unitIndex}].unitName`}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <MultipleFormControl fullWidth>
                            <InputLabel id="demo-multiple-chip-label">
                              Property's amenities
                            </InputLabel>
                            <Select
                              fullWidth
                              multiple
                              name={`unitArray[${unitIndex}].amenities`}
                              id={`unitArray[${unitIndex}].amenities`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={unitItem.amenities}
                              input={
                                <StyledSelect
                                  id="select-multiple-chip"
                                  label="Property's amenities"
                                />
                              }
                              renderValue={(selected) => selected.join(', ')}
                            >
                              {amenitiesOptions.map((option, index) => (
                                <MenuItem key={index} value={option.label}>
                                  <img
                                    style={{
                                      marginRight: '16px',
                                      width: '24px',
                                      height: '24px',
                                      objectFit: 'contain',
                                    }}
                                    src={`${IMG_URL}${option.path}`}
                                    alt="err"
                                  />
                                  <span
                                    style={{
                                      width: '100%',
                                      display: 'block',
                                    }}
                                  >
                                    {option.label}
                                  </span>
                                  <Checkbox
                                    checked={
                                      unitItem.amenities.indexOf(option.label) >
                                      -1
                                    }
                                    sx={{
                                      minWidth: '20px',
                                      border: 'none',
                                      transform: 'scale(.8)',
                                    }}
                                  />
                                </MenuItem>
                              ))}
                            </Select>
                          </MultipleFormControl>
                          <ErrorMessage
                            error
                            component={FormHelperText}
                            name={`unitArray[${unitIndex}].amenities`}
                          />
                        </Grid>
                        <Grid item container md={6} xs={12}>
                          <Grid item container xs={12} spacing={1}>
                            <Grid item container spacing={1} sm={12} xs={12}>
                              <Grid item sm={6} xs={6}>
                                <CurrencyFormat
                                  customInput={TextField}
                                  fullWidth
                                  type="text"
                                  value={unitItem.price}
                                  name={`unitArray[${unitIndex}].price`}
                                  label="Price"
                                  thousandSeparator={true}
                                  onChange={(e) => {
                                    let value = getNumberWithoutComma(
                                      e.target.value
                                    );

                                    setFieldValue(
                                      `unitArray[${unitIndex}].price`,
                                      value
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  onInput={(e) => {
                                    e.target.value = e.target.value
                                      .replace(REGEX.RESTRICT_CHAR, '')
                                      .slice(0, 9);
                                  }}
                                />
                                <ErrorMessage
                                  error
                                  component={FormHelperText}
                                  name={`unitArray[${unitIndex}].price`}
                                />
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <TextField
                                  fullWidth
                                  select
                                  name={`unitArray[${unitIndex}].durationName`}
                                  value={unitItem.durationName}
                                  label="Duration"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  {priceOptions.map((option, index) => (
                                    <MenuItem
                                      key={index}
                                      value={option.value}
                                      // disabled={
                                      //   priceIndex === 0 ||
                                      //   unitItem.priceArr
                                      //     .map((item) => {
                                      //       return item.durationName;
                                      //     })
                                      //     .includes(
                                      //       option.value
                                      //     )
                                      // }
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                                <ErrorMessage
                                  error
                                  component={FormHelperText}
                                  name={`unitArray[${unitIndex}].durationName`}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            type="text"
                            label="Number bedrooms"
                            value={unitItem.bedrooms}
                            name={`unitArray[${unitIndex}].bedrooms`}
                            id={`unitArray[${unitIndex}].bedrooms`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .replace(REGEX.RESTRICT_CHAR, '')
                                .slice(0, 2);
                            }}
                          />
                          <ErrorMessage
                            error
                            component={FormHelperText}
                            name={`unitArray[${unitIndex}].bedrooms`}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            type="text"
                            label="Number bathrooms"
                            value={unitItem.bathrooms}
                            name={`unitArray[${unitIndex}].bathrooms`}
                            id={`unitArray[${unitIndex}].bathrooms`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .replace(REGEX.RESTRICT_CHAR, '')
                                .slice(0, 2);
                            }}
                            // error={Boolean(
                            //   `${touched}.properties.${index}.bathrooms` &&
                            //     `${errors}.properties.${index}.bathrooms`
                            // )}
                            // helperText={
                            //   `${touched}.properties.${index}.bathrooms` &&
                            //   `${errors}.properties.${index}.bathrooms`
                            // }
                          />
                          <ErrorMessage
                            error
                            component={FormHelperText}
                            name={`unitArray[${unitIndex}].bathrooms`}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              sx={{ p: 0 }}
                              components={['DatePicker']}
                            >
                              <DatePicker
                                sx={{ width: '100%' }}
                                label="Availbale from (YYYY-MM-DD)"
                                format="YYYY-MM-DD"
                                value={dayjs(unitItem.availableFrom)}
                                // disablePast={true}
                                name={`unitArray[${unitIndex}].availableFrom`}
                                id={`unitArray[${unitIndex}].availableFrom`}
                                onChange={(e) => {
                                  const nDate = handleavailableDate(e);
                                  setFieldValue(
                                    `unitArray[${unitIndex}].availableFrom`,
                                    nDate
                                  );
                                }}
                                onBlur={handleBlur}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                          <ErrorMessage
                            error
                            component={FormHelperText}
                            name={`unitArray[${unitIndex}].availableFrom`}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <MultipleFormControl fullWidth>
                            <InputLabel id="demo-multiple-chip-label">
                              Property rules
                            </InputLabel>
                            <Select
                              fullWidth
                              multiple
                              name={`unitArray[${unitIndex}].rules`}
                              id={`unitArray[${unitIndex}].rules`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={unitItem.rules}
                              input={
                                <StyledSelect
                                  id="select-multiple-chip"
                                  label="Property's rules"
                                />
                              }
                              renderValue={(selected) => selected.join(', ')}
                            >
                              {rulesOptions.map((option, index) => (
                                <MenuItem key={index} value={option.label}>
                                  <span
                                    style={{
                                      width: '100%',
                                      display: 'block',
                                    }}
                                  >
                                    {option.label}
                                  </span>
                                  <Checkbox
                                    checked={
                                      unitItem.rules.indexOf(option.label) > -1
                                    }
                                    sx={{
                                      minWidth: '20px',
                                      border: 'none',
                                      transform: 'scale(.8)',
                                    }}
                                  />
                                </MenuItem>
                              ))}
                            </Select>
                          </MultipleFormControl>
                          <ErrorMessage
                            error
                            component={FormHelperText}
                            name={`unitArray[${unitIndex}].rules`}
                          />
                        </Grid>
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          sx={{
                            display: 'inline-flex',
                            gap: 2,
                            justifyContent: 'end',
                          }}
                        >
                          {unitIndex === 0 ? null : (
                            <Button
                              variant="softErrorOutlined"
                              onClick={() => {
                                values.unitArray.splice(unitIndex, 1);
                                setFieldValue('unitArray', values.unitArray);

                                setDeletedPropertyUnits((list) => [
                                  ...list,
                                  unitItem,
                                ]);
                              }}
                              startIcon={<Icon icon="uil:minus" />}
                            >
                              Remove units
                            </Button>
                          )}
                          {values.unitArray.length === unitIndex + 1 && (
                            <Button
                              variant="outlined"
                              onClick={() => {
                                values.unitArray.push({
                                  unitName: unitItem.unitName,
                                  amenities: unitItem.amenities,
                                  rules: unitItem.rules,
                                  price: unitItem.price,
                                  durationName: unitItem.durationName,
                                  bedrooms: unitItem.bedrooms,
                                  bathrooms: unitItem.bathrooms,
                                  availableFrom: unitItem.availableFrom,
                                });
                                setFieldValue('unitArray', values.unitArray);
                              }}
                              startIcon={<Icon icon="uil:plus" />}
                            >
                              Add units
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    ))}

                    {/* <Grid item md={6} xs={12}>
                      <MultipleFormControl fullWidth>
                        <InputLabel id="demo-multiple-chip-label">
                          Property's Amenities
                        </InputLabel>
                        <Select
                          fullWidth
                          multiple
                          name="amenities"
                          {...getFieldProps('amenities')}
                          value={values.amenities}
                          input={
                            <StyledSelect
                              id="select-multiple-chip"
                              label="Property's Amenities"
                            />
                          }
                          renderValue={(selected) => selected.join(', ')}
                        >
                          {amenitiesOptions.map((option, index) => (
                            <MenuItem key={index} value={option.label}>
                              <img
                                style={{
                                  marginRight: '16px',
                                  width: '24px',
                                  height: '24px',
                                  objectFit: 'contain',
                                }}
                                src={`${IMG_URL}${option.path}`}
                              />
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </MultipleFormControl>
                    </Grid>
                    <Grid item container md={6} xs={12}>
                      <Grid item container xs={12} spacing={1}>
                        {values.priceArr.map((item, priceIndex) => (
                          <Grid
                            item
                            container
                            spacing={1}
                            sm={11}
                            xs={10}
                            key={priceIndex}
                          >
                            <Grid item sm={6} xs={5}>
                              <CurrencyFormat
                                customInput={TextField}
                                fullWidth
                                type="text"
                                name={`priceArr[${priceIndex}].price`}
                                value={item.price}
                                thousandSeparator={true}
                                label="Price"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onInput={(e) => {
                                  e.target.value = e.target.value
                                    .replace(REGEX.RESTRICT_CHAR, '')
                                    .slice(0, 9);
                                }}
                              />

                              <ErrorMessage
                                error
                                component={FormHelperText}
                                name={`priceArr[${priceIndex}].price`}
                              />
                            </Grid>
                            <Grid item sm={5} xs={5}>
                              <TextField
                                fullWidth
                                select
                                name={`priceArr[${priceIndex}].durationName`}
                                label="Duration"
                                value={item.durationName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                {priceOptions.map((option, index) => (
                                  <MenuItem
                                    key={index}
                                    value={option.value}
                                    disabled={
                                      priceIndex === 0 ||
                                      values.priceArr
                                        .map((item) => {
                                          return item.durationName;
                                        })
                                        .includes(option.value)
                                    }
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                              <ErrorMessage
                                error
                                component={FormHelperText}
                                name={`priceArr[${priceIndex}].durationName`}
                              />
                            </Grid>
                            <Grid item sm={1} xs={2}>
                              {priceIndex === 0 ? null : (
                                <IconButton
                                  sx={{ bgcolor: '#F4F4F4', mt: 2 }}
                                  color="error"
                                  size="small"
                                  onClick={() => {
                                    values.priceArr.splice(priceIndex, 1);
                                    setFieldValue(`priceArr`, values.priceArr);
                                  }}
                                >
                                  <Icon icon="uil:minus" />
                                </IconButton>
                              )}
                            </Grid>
                          </Grid>
                        ))}
                        <Grid item sm={1} xs={2}>
                          {values.priceArr.length <= 1 ? (
                            <IconButton
                              sx={{ bgcolor: '#F4F4F4', mt: 2 }}
                              size="small"
                              onClick={() => {
                                values.priceArr.push({
                                  price: '',
                                  durationName: '',
                                });
                                setFieldValue(`priceArr`, values.priceArr);
                              }}
                            >
                              <Icon icon="uil:plus" />
                            </IconButton>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        type="text"
                        label="Number Bedrooms"
                        name="bedrooms"
                        {...getFieldProps('bedrooms')}
                        error={Boolean(touched.bedrooms && errors.bedrooms)}
                        helperText={touched.bedrooms && errors.bedrooms}
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .replace(REGEX.RESTRICT_CHAR, '')
                            .slice(0, 2);
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        type="text"
                        label="Number Bathrooms"
                        name="bathrooms"
                        {...getFieldProps('bathrooms')}
                        error={Boolean(touched.bathrooms && errors.bathrooms)}
                        helperText={touched.bathrooms && errors.bathrooms}
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .replace(REGEX.RESTRICT_CHAR, '')
                            .slice(0, 2);
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          sx={{ p: 0 }}
                          components={['DatePicker']}
                        >
                          <DatePicker
                            sx={{ width: '100%' }}
                            label="Availbale from (YYYY-MM-DD)"
                            format="YYYY-MM-DD"
                            value={dayjs(values.availableFrom)}
                            name="availableFrom"
                            onChange={(e) => {
                              handleavailableDate(e);
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <MultipleFormControl fullWidth>
                        <InputLabel id="demo-multiple-chip-label">
                          Property Rules
                        </InputLabel>
                        <Select
                          fullWidth
                          multiple
                          name="rules"
                          {...getFieldProps('rules')}
                          value={values.rules}
                          input={
                            <StyledSelect
                              id="select-multiple-chip"
                              label="Property's rules"
                            />
                          }
                          renderValue={(selected) => selected.join(', ')}
                        >
                          {rulesOptions.map((option, index) => (
                            <MenuItem key={index} value={option.label}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </MultipleFormControl>
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
            <Box sx={{ textAlign: 'right' }}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{ px: 5, width: { md: 'auto', xs: '100%' } }}
              >
                Save listing
              </Button>
            </Box>
          </Form>
        </FormikProvider>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_KEY,
  libraries: ['places'],
})(EditProperty);
