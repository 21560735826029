import React, { useState } from 'react';
import { Grid, Box, Backdrop, CircularProgress } from '@mui/material';
import { RightImg, LeftSection, BrandListBox, BannerLeftMain } from './styled';
import { BrandList } from '../../../components/brand-list';
import Rectangle5196 from '../../../assets/images/Rectangle5196.png';
import { RegisterLandlord } from './RegisterLandlord';
import { RegisterRenter } from './RegisterRenter';
import { RegisterRole } from './RegisterRole';
import authBack6 from '../../../assets/images/auth_back6.svg';
import authBack7 from '../../../assets/images/auth_back7.svg';

export const SignUp = () => {
  const [role, setRole] = useState();
  const [show, setShow] = useState();
  const [spinFlag, setSpinFlag] = useState(false);
  const handleShow = (role) => {
    setRole(role);
  };
  const signUpLandlord = (e) => {
    handleShow('landlord');
  };
  const signUpRenter = (e) => {
    handleShow('renter');
  };
  const signUpRoles = () => {
    handleShow('roles');
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: { md: 'end', xs: 'center' },
            background: `url(${authBack6}) no-repeat top right, url(${authBack7}) no-repeat bottom left`,
            order: { md: '1', xs: '2' },
          }}
        >
          <BannerLeftMain>
            <LeftSection>
              {role === 'landlord' ? (
                <RegisterLandlord
                  role={role}
                  signUpRoles={signUpRoles}
                  setSpinFlag={setSpinFlag}
                />
              ) : role === 'renter' ? (
                <RegisterRenter
                  role={role}
                  signUpRoles={signUpRoles}
                  setSpinFlag={setSpinFlag}
                />
              ) : (
                <RegisterRole
                  signUpLandlord={signUpLandlord}
                  signUpRenter={signUpRenter}
                />
              )}
            </LeftSection>

            <BrandListBox>
              <BrandList />
            </BrandListBox>
          </BannerLeftMain>
        </Grid>
        <Grid item md={6} xs={12} sx={{ order: { md: '2', xs: '1' } }}>
          <RightImg>
            <img src={Rectangle5196} alt="Rectangle5196" />
          </RightImg>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
