import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button, Col, Form, Input, Label, Row } from 'reactstrap';
import { addOrEditFaqs, getFaqForEdit } from '../../../../services/apiServices';
import 'quill/dist/quill.snow.css';

const AddEditFaqs = (props) => {
  let {
    isEdit,
    setFaqListRendering,
    faqListRendering,
    handleClose,
    setResponse,
    setSpinFlag,
  } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [question, setQuestion] = useState();
  const [answere, setAnswere] = useState();
  const [formData, setFormData] = useState({
    question: '',
    answere: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: '' });
    setQuestion(value);
  };

  const handleAnswereChange = (e, editor) => {
    const data = editor.getData();
    setErrors({ ...errors, answere: '' });
    setAnswere(data);
  };

  const toAddFaqs = async () => {
    try {
      setSpinFlag(true);

      let payload = {
        isEdit: isEdit,
        question: question,
        answere: answere,
      };
      let { data } = await addOrEditFaqs(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
        setFaqListRendering(!faqListRendering);
        handleClose();
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation logic
    const newErrors = {};

    if (!question.trim()) {
      newErrors.question = 'Question is required';
    }

    if (!answere.trim()) {
      newErrors.answere = 'Answere is required';
    }
    // Check for errors
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      toAddFaqs();
    }
  };

  const fetchFaqsForEdit = async () => {
    try {
      setSpinFlag(true);
      let payload = { faq_id: isEdit };
      let { data } = await getFaqForEdit(payload);
      if (data.statusCode === 200) {
        let { answere, question } = data.faqData;
        setSpinFlag(false);
        setQuestion(question);
        setAnswere(answere);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  useEffect(() => {
    if (isEdit) {
      fetchFaqsForEdit();
    }
  }, []);

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col sm={12}>
            <div className="mb-3">
              <Label htmlFor="" className="form-label">
                Title
              </Label>
              <Input
                name="question"
                className="form-control"
                placeholder="Title"
                type="text"
                value={question}
                onChange={handleChange}
                defaultValue={question}
              />
              {errors.question && (
                <div className="text-danger">{errors.question}</div>
              )}
            </div>
          </Col>
          <Col sm={12}>
            <div className="mb-3">
              <Label htmlFor="" className="form-label">
                Designation
              </Label>
              <CKEditor
                editor={ClassicEditor}
                data={answere}
                onChange={(e, editor) => {
                  handleAnswereChange(e, editor);
                }}
                // onReady={(editor) => {
                // }}
                // onChange={(editor) => {}}
              />
              {errors.answere && (
                <div className="text-danger">{errors.answere}</div>
              )}
            </div>
          </Col>
          <Col sm={12}>
            <div className="hstack gap-2 justify-content-end">
              <Button type="button" className="btn-light" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" className="btn-success">
                {isEdit ? 'Save' : 'Add Faq'}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default AddEditFaqs;
