import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import PropertyTitle from './PropertyTitle';
import { ImagesGallery } from './ImagesGallery';
import { PropertyDescription } from './PropertyDescription';
import { PropertyReviews } from './PropertyReviews';
import { PropertyRules } from './PropertyRules';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { getProperty } from '../../services/apiServices';
import { DialogPopup } from '../../components/dialog-popup/DialogPopup';
import { useAuthStore } from '../../redux/features/authSlice';
import { Page404 } from '../../../src/pages/not-found/Page404.js';

export const PropertyDetails = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let { id } = useParams();
  let { loginInfo } = useAuthStore();
  const query = new URLSearchParams(useLocation().search);
  const bedroomFilter = query.get('bedroom');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [spinFlag, setSpinFlag] = useState(false);
  const [propertyData, setPropertyData] = useState({});
  const [propertyImages, setPropertyImages] = useState([]);
  const [propertyOwner, setPropertyOwner] = useState({});
  const [amenities, setAmenities] = useState([]);
  const [rules, setRules] = useState([]);
  // const [propertyPrice, setPropertyPrice] = useState([]);
  const [renderFlag, setRenderFlag] = useState(false);
  const [propertyReview, setPropertyReview] = useState([]);
  const [reviewData, setReviewData] = useState();
  const [propertyUnits, setPropertyUnits] = useState([]);
  const [statusCode, setStatusCode] = useState();

  const getPropertyData = async () => {
    try {
      setSpinFlag(true);
      let user_id = loginInfo ? loginInfo._id : null;
      let payload = {
        propertyId: id,
        user_id: user_id,
        role: loginInfo ? loginInfo.role : null,
        bedroomFilter:
          bedroomFilter !== 'undefined' ? bedroomFilter : undefined,
      };
      const { data } = await getProperty(payload);

      if (data.statusCode === 200) {
        let propertyData = data.propertyData;
        // if (propertyData) {
        propertyData.requestData = data.requestData;
        propertyData.bookingDetails = data.bookingDetails;
        propertyData.bookingDatesForEachUnits = data.bookingDatesForEachUnits;
        propertyData.requestDataForReview = data.requestDataForReview;
        setPropertyImages(data.propertyImage);
        setPropertyOwner(data.propertyOwner);
        setRules(data.rules);

        setAmenities(data.amenities);
        // setPropertyPrice(data.propertyUnits);
        setPropertyReview(data.propertyReview);
        setReviewData(data.singleReviewData);
        setPropertyUnits(data.propertyUnits);
        // }
        setPropertyData(propertyData);
        setSpinFlag(false);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        if (data.statusCode === 404) {
          navigate('/404');
        }
        // setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };
  useEffect(() => {
    getPropertyData();
  }, [renderFlag]);

  return (
    <>
      <DialogPopup
        open={open}
        setOpen={setOpen}
        message={message}
        size="xs"
        statusCode={statusCode}
      />

      {/* {propertyData && propertyUnits.length > 0 ? (
        <> */}
      <PropertyTitle
        propertyUnits={propertyUnits}
        propertyData={propertyData}
      />
      <ImagesGallery propertyImages={propertyImages} />
      <PropertyDescription
        setRenderFlag={setRenderFlag}
        renderFlag={renderFlag}
        amenities={amenities}
        propertyOwner={propertyOwner}
        propertyData={propertyData}
        // propertyPrice={propertyPrice}
        propertyUnits={propertyUnits}
        setSpinFlag={setSpinFlag}
      />
      <PropertyReviews
        setRenderFlag={setRenderFlag}
        renderFlag={renderFlag}
        propertyOwner={propertyOwner}
        propertyData={propertyData}
        propertyReview={propertyReview}
        reviewData={reviewData}
        setSpinFlag={setSpinFlag}
        propertyUnits={propertyUnits}
      />
      <PropertyRules
        rules={rules}
        propertyOwner={propertyOwner}
        propertyReview={propertyReview}
      />
      {/* </>
      ) : (
        <Page404 />
      )} */}

      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
