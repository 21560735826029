import React, { useState, useEffect } from 'react';
import {
  DialogTitle,
  DialogActions,
  Dialog,
  Typography,
  Button,
  DialogContent,
  Divider,
  Box,
  Slider,
  SliderThumb,
  Grid,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Stack,
  FormLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormGroup,
  OutlinedInput,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import SvgColor from '../../components/svg-color';
import dollarIcon from '../../assets/icons/dollarIcon.svg';
import { fetchRulesAndAmenities } from '../../services/apiServices';
import { REGEX } from '../../utils/regex';
import CurrencyFormat from 'react-currency-format';
import { currencySeprator } from '../../utils/common-function';
import { priceOptions } from '../../utils/optionsForSelect';
import { Icon } from '@iconify/react';
import { nairaSymbol } from '../../utils/constant';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const DateRangePickerBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 3,
  '& .DateRangePickerInput': {
    height: '53px',
    borderRadius: '14px',
    borderColor: '#D1D5DB',
    fontWeight: '400',
    fontSize: '14px',
    color: '#323232',
    display: 'flex',
    // flexWrap: 'wrap',
    alignItems: 'center',
    padding: '0',
    fontFamily: 'Inter,sans-serif',
    '& ::-webkit-input-placeholder': {
      fontWeight: '600',
      fontSize: '14px',
      color: '#9CA3AF',
      fontFamily: 'Inter,sans-serif',
    },
    '& :-ms-input-placeholder': {
      fontWeight: '600',
      fontSize: '14px',
      color: '#9CA3AF',
      fontFamily: 'Inter,sans-serif',
    },
    '& ::placeholder': {
      fontWeight: '600',
      fontSize: '14px',
      color: '#9CA3AF',
      fontFamily: 'Inter,sans-serif',
    },
    '& .DateRangePickerInput_calendarIcon': {
      width: '35px',
      position: 'static',
      transform: 'inherit',
      background: 'none',
      borderRadius: '0',
    },
    '& .DateRangePickerInput_clearDates': {
      width: '35px',
      position: 'static',
      transform: 'inherit',
      background: 'none',
      borderRadius: '0',
    },
    '& .DateInput': {
      width: 'calc(50% - 51px)',
      background: 'none',
      height: '100%',
      '& .DateInput_input': {
        height: '100%',
        fontWeight: '400',
        fontSize: '14px',
        color: '#323232',
        fontFamily: 'Inter,sans-serif',
        background: 'none',
      },
      '& .DateInput_input__focused': {
        borderColor: '#323232',
      },
    },
    '& .DayPicker': {
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow:
        '0px 0px 2px 0px rgba(145, 158, 171, 0.24), 0px 20px 40px -4px rgba(145, 158, 171, 0.24)',
    },
    '& .CalendarMonth_caption strong': {
      fontWeight: '600',
      fontSize: '16px',
      color: '#323232',
    },
    '& .DayPickerNavigation_button': {
      border: 'none',
    },
    '& .CalendarDay__default': {
      border: 'none !important',
      '&.CalendarDay__selected_start': {
        borderRadius: '20px 0 0 20px',
      },
      '&.CalendarDay__selected_end': {
        borderRadius: '0 20px 20px 0',
      },
      '&.CalendarDay__selected_span': {
        background: 'rgb(247, 247, 247)',
        color: '#323232',
      },
      '&.CalendarDay__selected': {
        background: '#323232',
        color: '#fff',
        '&:active': {
          background: '#323232',
        },
        '&:hover': {
          background: '#323232',
        },
      },
      '&.CalendarDay__hovered_span': {
        background: 'rgb(247, 247, 247)',
        color: '#484848',
        '&:hover': {
          background: 'rgb(247, 247, 247)',
          color: '#484848',
        },
      },
      '&.CalendarDay__blocked_out_of_range': {
        textDecoration: 'line-through',
      },
    },
  },
}));

const cityName = [
  {
    name: 'Jakarta',
  },
  {
    name: 'Mumbai',
  },
  {
    name: 'Samarinda',
  },
  {
    name: 'Los Angles',
  },
  {
    name: 'Kuala Lumpur',
  },
  {
    name: 'Bali',
  },
  { name: 'Cape Town' },
  { name: 'New York' },
  { name: 'indore' },
];
const propertyTypeName = [{ propertyType: 'Home' }, { propertyType: 'Flat' }];
const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];
const StyledSelect = styled(OutlinedInput)(({ theme }) => ({
  flexGrow: '1',
  borderRadius: 14,
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));
const SelectFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiFormGroup-root': {
    margin: '15px 0 0',
    gap: '5px',
    '& .MuiFormControlLabel-root': {
      margin: '0',
      '& .MuiFormControlLabel-label': {
        border: '1px solid #D1D5DB',
        padding: '8px 30px',
        borderRadius: '50px',
        textAlign: 'center',
        transition: '0.15s',
        '&:hover': {
          borderColor: '#24191B',
        },
      },
      '& .Mui-checked + .MuiFormControlLabel-label': {
        background: '#24191B',
        color: '#fff',
        borderColor: '#24191B',
      },
    },
  },
}));
const SelectFormGroup = styled(FormGroup)(({ theme }) => ({
  '&.MuiFormGroup-root': {
    gap: '5px',
    flexDirection: 'row',
    '& .MuiFormControlLabel-root': {
      margin: '0',
      '& .MuiFormControlLabel-label': {
        border: '1px solid #D1D5DB',
        padding: '8px 30px',
        borderRadius: '50px',
        textAlign: 'center',
        transition: '0.15s',
        '&:hover': {
          borderColor: '#24191B',
        },
      },
      '& .Mui-checked + .MuiFormControlLabel-label': {
        background: '#24191B',
        color: '#fff',
        borderColor: '#24191B',
      },
    },
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '100%',
    maxWidth: '780px',
  },
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(3),
  },
}));

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: '#FFF9EB',
  height: 6,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '8px solid #FEAE01',
    '&:hover': {
      boxShadow: '0 0 0 8px #FFF9EB',
    },
    '& .airbnb-bar': {},
  },
  '& .MuiSlider-track': {
    background: '#FEAE01',
    height: 6,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#FFF9EB' : '#FFF9EB',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: 6,
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    minWidth: 65,
    height: 32,
    borderRadius: '60px',
    backgroundColor: '#323232',
    // transformOrigin: 'bottom left',
    top: '-20px',
    // '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {},
    '& > *': {
      //   transform: 'rotate(45deg)',
    },
  },
}));

function AirbnbThumbComponent(props) {
  const { children, ...other } = props;
  return <SliderThumb {...other}>{children}</SliderThumb>;
}

const valuetext = (priceRange) => {
  return `${priceRange}$`;
};

export const PropertyFilter = (props) => {
  const navigate = useNavigate();
  const {
    handleClose,
    open,
    setPriceRangeFilter,
    setCities,
    setPriceDuration,
    renderFlag,
    setRenderFlag,
    setPropertyTypeFilter,
    setBedroomFilter,
    setAmenitiesFilter,
    setDateFilter,
    city,
    setMoveInDate,
    setLeaseDuration,
    leaseDuration,
    moveInDate,
    resultRef,
    pathname,
    priceRangeFilter,
    ...other
  } = props;
  console.log(
    'pathname----------------------------',
    pathname === '/' ? 'true' : 'false'
  );
  const minValue = 10;
  const maxValue = 1000000;
  const [priceRange, setPriceRange] = useState([minValue, maxValue]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [duration, setDuration] = useState('year');
  const [bedroom, setBedroom] = useState('any');
  const [propertyType, setPropertyType] = useState([]);
  // const [personName, setPersonName] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [amenitiesForShow, setAmenitiesForShow] = useState([]);
  const amenitiesOptions = amenitiesForShow.map((item) => ({
    label: item.amenities,
    path: item.path,
  }));

  useEffect(() => {
    getAmenities();
  }, []);

  const getAmenities = async () => {
    try {
      const { data } = await fetchRulesAndAmenities();
      if (data.statusCode === 200) {
        // setRules(data.rules);
        setAmenitiesForShow(data.amenities);
      } else {
        // setOpen(true);
        // setSpinFlag(false);
        // setMessage(data.message);
      }
    } catch (err) {
      // navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };
  const handleChangeLease = (e) => {
    console.log('eeee', e);
    setLeaseDuration(e.target.value);
  };
  const handleChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  const handleMinPriceChange = (event) => {
    setPriceRange([Number(event.target.value), priceRange[1]]);
  };

  const handleMaxPriceChange = (event) => {
    setPriceRange([priceRange[0], Number(event.target.value)]);
  };

  const handlePropertyTypeChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setPropertyType((prevSelectedItems) => [...prevSelectedItems, value]);
    } else {
      setPropertyType((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item !== value)
      );
    }
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, value]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item !== value)
      );
    }
  };

  const clearFilter = () => {
    setPriceRange([minValue, maxValue]);
    setDuration('year');
    setSelectedItems([]);
    setBedroom('any');
    setPropertyType([]);
    setAmenities([]);
    setStartDate('');
    setEndDate('');
    setMoveInDate(null);
    setLeaseDuration([]);
    // setMoveInDate('');
  };

  const handleFilter = async () => {
    // if (pathname === "/") {
    let filterObject = {
      leaseDuration: leaseDuration,
      moveInDate: moveInDate,
      priceRangeFilter: priceRange,
      bedroom: bedroom,
      propertyType: propertyType,
      amenities: amenities,
      cities: selectedItems,
    };
    navigate(`/available-spaces`, { state: filterObject });
    // } else {
    setRenderFlag(!renderFlag);
    setPriceRangeFilter(priceRange);
    setPriceDuration(duration);
    setCities(selectedItems);
    setBedroomFilter(bedroom);
    setPropertyTypeFilter(propertyType);
    setAmenitiesFilter(amenities);
    // clearFilter();
    // setSelectedItems([]);
    // }
  };

  const handleChangeAm = (event) => {
    const {
      target: { value },
    } = event;
    setAmenities(value);
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);

  const handleDate = (e) => {
    console.log('ee', e);
    if (e) {
      let date = new Date(e._d),
        mnth = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2);
      let nDate = [date.getFullYear(), mnth, day].join('-');
      return nDate;
    }
  };
  const handleavailableDate = (e) => {
    let date = new Date(e.$d),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    let nDate = [date.getFullYear(), mnth, day].join('-');
    return nDate;
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setStartDate(startDate);
    setEndDate(endDate);
    let sDate = handleDate(startDate);
    let eDate = handleDate(endDate);
    console.log('start date:', sDate);
    console.log('end date:', eDate);
    setDateFilter({ checkIn: startDate, checkOut: endDate });
  };

  const handleFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon icon="ic:round-close" />
        </IconButton>
        <DialogTitle
          sx={{ textAlign: 'center' }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Filter
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="h5">Move-In Date</Typography>
          <Box
            sx={{
              pt: 2,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer sx={{ p: 0 }} components={['DatePicker']}>
                <DatePicker
                  sx={{ width: '100%' }}
                  label="Move-In Date (YYYY-MM-DD)"
                  format="YYYY-MM-DD"
                  disablePast={true}
                  onChange={(e) => {
                    const nDate = handleavailableDate(e);
                    setMoveInDate(nDate);
                    console.log('mdate', nDate);
                  }}
                  value={moveInDate ? dayjs(moveInDate) : null}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Typography variant="h5">Lease Duration</Typography>
          {/* <Box
            sx={{
              pt: 3,
            }}
          >
            <TextField
              fullWidth
              select
              value={leaseDuration}
              label="Duration"
              onChange={handleChangeLease}
            >
              {priceOptions.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box> */}
          {/* multiselect field start */}
          <Box
            sx={{
              pt: 2,
            }}
          >
            <FormControl fullWidth>
              <Select
                multiple
                fullWidth
                displayEmpty
                value={leaseDuration}
                onChange={handleChangeLease}
                placeholder="Amenities"
                input={<StyledSelect id="select-multiple-chip" />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Select Lease duration</em>;
                  }

                  return selected.join(', ');
                }}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem disabled value="">
                  <em>Select Lease duration</em>
                </MenuItem>
                {priceOptions.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    <span
                      style={{
                        width: '100%',
                        display: 'block',
                      }}
                    >
                      {option.label}
                    </span>
                    <Checkbox
                      checked={leaseDuration.indexOf(option.value) > -1}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {/* multiselect field end */}

          <Divider sx={{ my: 3 }} />

          <Typography variant="h5">Date range</Typography>

          <Box
            sx={{
              pt: 2,
            }}
          >
            <DateRangePickerBox>
              <FormControl fullWidth>
                <DateRangePicker
                  startDate={startDate}
                  startDateId="your_unique_start_date_id"
                  endDate={endDate}
                  endDateId="your_unique_end_date_id"
                  onDatesChange={handleDatesChange}
                  focusedInput={focusedInput}
                  onFocusChange={handleFocusChange}
                  hideKeyboardShortcutsPanel
                  showClearDates
                  block
                  customInputIcon={
                    <Icon width="18" height="18" icon="solar:calendar-linear" />
                  }
                  startDatePlaceholderText="Start date"
                  endDatePlaceholderText="End date"
                />
              </FormControl>
            </DateRangePickerBox>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Typography variant="h5">Price range</Typography>

          <Typography
            variant="h6"
            sx={{ fontWeight: 'normal', color: 'text.secondary' }}
          >
            The average{' '}
            {duration === 'day'
              ? 'nightly'
              : duration === 'month'
              ? 'monthly'
              : 'yearly'}{' '}
            price range is {nairaSymbol}
            {currencySeprator(priceRange[0])} - {nairaSymbol}
            {currencySeprator(priceRange[1])}
          </Typography>

          <Box sx={{ pt: 6 }}>
            <Box sx={{ px: 2 }}>
              <AirbnbSlider
                valueLabelDisplay="auto"
                slots={{ thumb: AirbnbThumbComponent }}
                getAriaLabel={(index) =>
                  index === 0 ? 'Minimum price' : 'Maximum price'
                }
                value={priceRange ? priceRange : [minValue, maxValue]}
                onChange={handleChange}
                getAriaValueText={valuetext}
                min={minValue}
                max={maxValue}
              />
            </Box>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon icon="fa6-solid:naira-sign" />
                      </InputAdornment>
                    ),
                  }}
                  name="minPrice"
                  type="text"
                  label="Min price"
                  value={priceRange ? priceRange[0] : minValue}
                  onChange={handleMinPriceChange}
                  min={minValue}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(REGEX.RESTRICT_CHAR, '')
                      .slice(0, 7);
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon icon="fa6-solid:naira-sign" />
                      </InputAdornment>
                    ),
                  }}
                  name="maxPrice"
                  type="text"
                  label="Max price"
                  value={priceRange ? priceRange[1] : maxValue}
                  onChange={handleMaxPriceChange}
                  max={maxValue}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(REGEX.RESTRICT_CHAR, '')
                      .slice(0, 7);
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Typography variant="h5">Bedrooms and Bathrooms</Typography>

          <Box
            sx={{
              pt: 2,
            }}
          >
            <Stack sx={{ mb: 3 }}>
              <SelectFormControl>
                <Typography variant="subtitle1">Bedrooms</Typography>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="bedroom-groups"
                  defaultValue="any"
                  value={bedroom}
                  onChange={(e) => {
                    setBedroom(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="any"
                    control={<Radio sx={{ display: 'none' }} />}
                    label="Any"
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio sx={{ display: 'none' }} />}
                    label="1"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio sx={{ display: 'none' }} />}
                    label="2"
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio sx={{ display: 'none' }} />}
                    label="3"
                  />
                  <FormControlLabel
                    value="4+"
                    control={<Radio sx={{ display: 'none' }} />}
                    label="4+"
                  />
                </RadioGroup>
              </SelectFormControl>
            </Stack>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Typography variant="h5">Property type</Typography>

          <Box
            sx={{
              pt: 2,
            }}
          >
            <SelectFormGroup>
              {propertyTypeName.map((item, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      sx={{ display: 'none' }}
                      checked={propertyType.includes(item.propertyType)}
                    />
                  }
                  onChange={handlePropertyTypeChange}
                  name="propertyType"
                  label={item.propertyType}
                  value={item.propertyType}
                />
              ))}
              {/* <FormControlLabel
                control={<Checkbox sx={{ display: 'none' }} />}
                label="Home"
              /> */}
            </SelectFormGroup>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Typography variant="h5">Amenities</Typography>

          <Box
            sx={{
              pt: 2,
            }}
          >
            <FormControl fullWidth>
              <Select
                multiple
                fullWidth
                displayEmpty
                value={amenities}
                onChange={handleChangeAm}
                placeholder="Amenities"
                input={<StyledSelect id="select-multiple-chip" />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Select Amenities</em>;
                  }

                  return selected.join(', ');
                }}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem disabled value="">
                  <em>Select amenities</em>
                </MenuItem>
                {amenitiesOptions.map((amenity, index) => (
                  <MenuItem key={index} value={amenity.label}>
                    <span
                      style={{
                        width: '100%',
                        display: 'block',
                      }}
                    >
                      {amenity.label}
                    </span>
                    <Checkbox checked={amenities.indexOf(amenity.label) > -1} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Typography variant="h5">City</Typography>

          <Box
            sx={{
              pt: 2,
              display: 'grid',
              gridTemplateColumns: { sm: 'auto auto', xs: 'auto' },
              gap: '15px',
            }}
          >
            {city?.map((value, index) => (
              <FormControlLabel
                key={index}
                sx={{ gap: '10px', margin: '0' }}
                value={value?.city}
                onChange={handleCheckboxChange}
                control={
                  <Checkbox checked={selectedItems.includes(value?.city)} />
                }
                label={value?.city}
                name="city"
              />
            ))}
            {/* <Typography
              variant="body1"
              color="primary"
              sx={{
                textTransform: 'none',
              }}
            >
              <span
                style={{
                  cursor: 'pointer',
                }}
              >
                +100 more
              </span>
            </Typography> */}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            flexDirection: { sm: 'row', xs: 'column-reverse' },
            py: '15px !important',
          }}
        >
          <Button
            variant="text"
            sx={{ width: { sm: 'auto', xs: '100%' } }}
            size="large"
            onClick={clearFilter}
          >
            Clear all
          </Button>
          <Button
            disabled={priceRange[0] > priceRange[1]}
            variant="contained"
            sx={{ width: { sm: 'auto', xs: '100%' } }}
            size="large"
            onClick={handleFilter}
          >
            Apply filter
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};
