import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Rating from 'react-rating';
import Flatpickr from 'react-flatpickr';
import {
  Breadcrumb,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import 'quill/dist/quill.snow.css';
import smallImage9 from '../../../../assets/images/users/avatar-1.jpg';
import icondummyimg from '../../../../assets/images/logo-sm.png';
import { IMG_URL } from '../../../../../utils/constant';
import { addOrEditAboutUs } from '../../../../../services/apiServices';
const AddEditList = (props) => {
  let {
    isEdit,
    setModal,
    setSpinFlag,
    setResponse,
    aboutUsData,
    render,
    setRender,
    handleClose,
  } = props;
  const uploadedImage = useRef();
  const [formData, setFormData] = useState({
    icon: '',
    description: '',
    heading: '',
  });
  const [errors, setErrors] = useState({});
  const [reset, setreset] = useState('');
  const navigate = useNavigate();
  const currentDate = new Date();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: '' });
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  //for image validation
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    // setImageError('');
    if (
      file?.type === 'image/jpg' ||
      file?.type === 'image/jpeg' ||
      file?.type === 'image/png' ||
      file?.type === 'image/svg+xml' ||
      file?.type === 'image/gif'
    ) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      setFormData({ ...formData, icon: file });
      setErrors({ ...errors, icon: '' });
    } else {
      setFormData({ ...formData, icon: '' });
      setErrors({ ...errors, icon: 'Image must be in valid format' });
    }
  };
  useEffect(() => {
    if (isEdit) {
      setFormData({
        icon: aboutUsData?.icon,
        heading: aboutUsData?.heading,
        description: aboutUsData?.description,
      });
    }
  }, []);

  const toAddOrEditContactUs = async () => {
    try {
      setSpinFlag(true);
      formData.isEdit = isEdit;
      console.log('isisisisDatatat...', formData);

      let abouttUsData = new FormData();
      abouttUsData.append('data', JSON.stringify(formData));
      abouttUsData.append('icon', formData?.icon);
      console.log('abouttUsData....', abouttUsData);
      let { data } = await addOrEditAboutUs(abouttUsData);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
        setRender(!render);
        handleClose();
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validation logic
    const newErrors = {};

    if (!formData.icon) {
      newErrors.icon = 'icon is required';
    }

    if (!formData.heading.trim()) {
      newErrors.heading = 'Heading is required';
    }

    if (!formData.description.trim()) {
      newErrors.info = 'Info is required';
    }
    // Check for errors
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      toAddOrEditContactUs();
    }
  };
  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col lg={12}>
            <div className="text-center mb-4">
              <div className="position-relative d-inline-block">
                <div className="position-absolute bottom-0 end-0">
                  <label
                    htmlFor="member-image-input"
                    className="mb-0"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="Select Member Image"
                  >
                    <div className="avatar-xs">
                      <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                        <i className="ri-image-fill"></i>
                      </div>
                    </div>
                  </label>
                  <input
                    className="form-control d-none"
                    defaultValue=""
                    id="member-image-input"
                    name="path"
                    type="file"
                    onChange={handleImageUpload}
                    accept="image/png, image/gif, image/jpeg"
                  />
                </div>
                <div className="avatar-lg">
                  <div className="avatar-title bg-light rounded-circle">
                    <img
                      src={
                        isEdit ? `${IMG_URL}${aboutUsData?.icon}` : icondummyimg
                      }
                      alt=" "
                      id="member-img"
                      className="avatar-md rounded-circle object-fit-contain"
                      ref={uploadedImage}
                    />
                  </div>
                  {errors.icon && (
                    <div className="text-danger">{errors.icon}</div>
                  )}
                </div>
              </div>
              <h5 className="fs-13 mt-3">Icon</h5>
            </div>

            <div className="mb-3">
              <Label htmlFor="" className="form-label">
                Title
              </Label>
              <Input
                type="text"
                className="form-control"
                id="title"
                placeholder="Title"
                name="heading"
                onChange={handleChange}
                value={formData?.heading}
              />
              {errors.heading && (
                <div className="text-danger">{errors?.heading}</div>
              )}
            </div>

            <div className="mb-3">
              <Label htmlFor="" className="form-label">
                Description
              </Label>
              <textarea
                className="form-control"
                id="description"
                placeholder="Description"
                onChange={handleChange}
                name="description"
                style={{ resize: 'none' }}
                value={formData?.description}
              />
              {errors?.description && (
                <div className="text-danger">{errors?.description}</div>
              )}
            </div>
          </Col>
          <Col lg={12}>
            <div className="hstack gap-2 justify-content-end">
              <Button
                type="button"
                className="btn-light"
                onClick={() => setModal(false)}
              >
                Cancel
              </Button>
              <Button type="submit" className="btn-success">
                {isEdit ? 'Save' : 'Add Reviews'}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default AddEditList;
