import React, { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
// import DataTable from 'your-data-table-library';

import { Row, Col } from 'reactstrap';
import { CustomersGlobalFilter } from '../../Common/GlobalSearchFilter';
import { Backdrop, CircularProgress } from '@mui/material';
import Loader from '../../../Components/Common/Loader';
const customStyles = {
  rows: {
    style: {
      // minHeight: '72px',
    },
    rowsPerPageDropdown: {
      display: 'none', // Hide the Rows per page dropdown
    },
  },
  headCells: {
    style: {
      padding: '8px',
      background: '#f3f6f9',
      fontWeight: 'bold',
      color: '#878a99',
    },
  },
  cells: {
    style: {
      padding: '8px',
    },
  },
};
const DatatablesCom = (props) => {
  let {
    columns,
    data,
    setSearch,
    setUserStatus,
    search,
    userStatus,
    setFetchRender,
    fetchRender,
    optionsFlag,
    searchByDate,
    setSearchByDate,
    // paginationTotalRows,
    loader,
    setRowsPerPage,
    rowPerPage,
  } = props;

  const handlePageChange = (page) => {
    props.setCurrentPage(page);
    setFetchRender(!fetchRender);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setRowsPerPage(newPerPage);
  };
  return (
    <>
      <div className="card-body border-bottom-dashed border-bottom border-top-dashed border-top">
        <Row>
          <Col xl={5}>
            <div className="search-box mb-xl-0 mb-3">
              <input
                id="search-bar-0"
                type="text"
                className="form-control search"
                placeholder={props.placeholder}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <i className="bx bx-search-alt search-icon"></i>
            </div>
          </Col>
          <CustomersGlobalFilter
            setUserStatus={setUserStatus}
            userStatus={userStatus}
            fetchRender={fetchRender}
            setFetchRender={setFetchRender}
            optionsFlag={optionsFlag}
            searchByDate={searchByDate}
            setSearchByDate={setSearchByDate}
          />
        </Row>
      </div>

      <div className="position-relative" style={{ minHeight: '200px' }}>
        {loader ? (
          <div
            className="p-2 position-absolute d-flex align-items-center justify-content-center w-100"
            style={{ minHeight: '200px' }}
          >
            <Loader className="text-secondary" />
          </div>
        ) : (
          <>
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationServer
              onChangePage={handlePageChange}
              customStyles={customStyles}
              paginationTotalRows={props.paginationTotalRows}
              paginationRowsPerPageOptions={[10, 25, 50, 70, 100]}
              paginationRowsPerPage={rowPerPage}
              paginationDefaultPage={1}
              onChangeRowsPerPage={handlePerRowsChange}
            />
          </>
        )}
      </div>
    </>
  );
};

export default DatatablesCom;
