import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Widgets from '../../Components/Common/Widgets';
import { Container, Row, Col } from 'reactstrap';
import PropertyList from './PropertiesList';
import { allCountProperties } from '../../../services/apiServices';
import ShowSpinner from '../../Components/Common/spinner';
import ResponseModal from '../../Components/Common/SuccessModal';

const PropertyListPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [spinFlag, setSpinFlag] = useState(false);
  const [response, setResponse] = useState({
    message: '',
    open: false,
    statusCode: null,
  });
  const [allProperties, setAllProperties] = useState(0);
  const [allAvailableProperties, setAllAvailableProperties] = useState(0);
  const [allBookedProperties, setAllBookedProperties] = useState(0);
  const [renderCount, setRender] = useState(true);

  useEffect(() => {
    getAllCountProperties();
  }, [renderCount]);

  const getAllCountProperties = async () => {
    try {
      setSpinFlag(true);
      const { data } = await allCountProperties();
      if (data.statusCode == 200) {
        setSpinFlag(false);
        setAllProperties(data.allPropertyCount);
        setAllAvailableProperties(data.allAvailableCount);
        setAllBookedProperties(data.allBookedCount);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };
  return (
    <React.Fragment>
      <ResponseModal response={response} setResponse={setResponse} />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xxl={3} lg={6}>
              <Widgets
                label={'Total Properties'}
                prefix={''}
                suffix={''}
                separator={''}
                end={allProperties}
                decimals={''}
                bgcolor={'success'}
                icon={'bx bx-building-house'}
                role={''}
                tab={''}
              />
            </Col>
            <Col xxl={3} lg={6}>
              <Widgets
                label={'AVAILABLE PROPERTIES'}
                prefix={''}
                suffix={''}
                separator={''}
                end={allAvailableProperties}
                decimals={''}
                bgcolor={'warning'}
                icon={'bx bxs-building'}
                role={''}
                tab={''}
              />
            </Col>
            <Col xxl={3} lg={6}>
              <Widgets
                label={'BOOKED PROPERTIES'}
                prefix={''}
                suffix={''}
                separator={''}
                end={allBookedProperties}
                decimals={''}
                bgcolor={'danger'}
                icon={'bx bx-building'}
                role={''}
                tab={''}
              />
            </Col>
          </Row>
          <Row>
            <PropertyList
              renderCount={renderCount}
              setRender={setRender}
              setSpinFlag={setSpinFlag}
              setResponse={setResponse}
            />
          </Row>
        </Container>
      </div>
      <ShowSpinner spinFlag={spinFlag} />
    </React.Fragment>
  );
};

export default PropertyListPage;
