import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Container,
  Typography,
  Stack,
  Divider,
  Avatar,
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAuthStore } from '../../redux/features/authSlice';
import { PropertyCheckout } from './PropertyCheckout';
import { IMG_URL, nairaSymbol } from '../../utils/constant';
import SvgColor from '../../components/svg-color';
import { Icon } from '@iconify/react';
import avatar_11 from '../../assets/images/avatars/avatar_11.jpg';
import Bed from '../../assets/icons/Bed.svg';
import Bathroom from '../../assets/icons/Bathroom.svg';
import award from '../../assets/icons/award.svg';
import location from '../../assets/icons/location.svg';
import calendaradd from '../../assets/icons/calendaradd.svg';
import ReserveForm from './ReserveForm';
import PropertyUnitList from './PropertyUnitList';
import profileDummy from '../../assets/images/profileDam.png';
import { currencySeprator } from '../../utils/common-function';

const TopMap = styled(Box)(({ theme }) => ({}));

export const PropertyDescription = (props) => {
  let { loginInfo, isLoggedin } = useAuthStore();
  let {
    propertyOwner,
    propertyData,
    amenities,
    propertyUnits,
    setRenderFlag,
    renderFlag,
    setSpinFlag,
  } = props;
  let { firstname, lastname, _id } = propertyOwner;
  let { description, bedrooms, bathrooms, requestData, bookingStatus } =
    propertyData;

  const [showMore, setShowMore] = useState(false);
  const [open, setOpen] = useState(false);
  const [minimumDurationStay, setMinimumDurationStay] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    let arr = [];
    propertyUnits?.map((item) => {
      if (item.durationName === 'year') {
        arr.push(10);
      } else if (item.durationName === 'sixMonth') {
        arr.push(6);
      } else if (item.durationName === 'threeMonth') {
        arr.push(3);
      } else {
        arr.push(1);
      }
    });

    if (arr.length !== 0) {
      const minValue = Math.min(...arr);
      setMinimumDurationStay(minValue);
    }
  }, [propertyUnits]);
  return (
    <>
      <Box>
        <Container fixed={true} sx={{ maxWidth: { xl: '1326px' } }}>
          <Grid
            container
            spacing={4}
            sx={{ flexFlow: { xs: 'column-reverse', lg: 'row' } }}
          >
            <Grid item lg={7} xs={12}>
              <Box sx={{ pb: { md: 4, xs: 3 } }}>
                <Grid container>
                  <Grid item sm={8} xs={12}>
                    <Stack direction="row">
                      <Avatar
                        alt="Remy Sharp"
                        src={
                          propertyOwner.profile
                            ? `${IMG_URL}${propertyOwner.profile}`
                            : profileDummy
                        }
                        sx={{
                          width: { md: 80, xs: 74 },
                          height: { md: 80, xs: 74 },
                        }}
                      />
                      <Box sx={{ ml: 2 }}>
                        <Typography
                          variant="h5"
                          sx={{ fontWeight: '500', mb: { sm: 2, xs: 1 } }}
                        >
                          Managed by {firstname} {lastname}
                        </Typography>
                        {propertyUnits.length === 1 ? (
                          <Stack direction="row">
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: 'grey.500',
                                mr: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  background: '#E9EAF0',
                                  width: '32px',
                                  height: '32px',
                                  borderRadius: '50%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  mr: 1,
                                }}
                              >
                                <SvgColor
                                  sx={{
                                    color: '#82838C',
                                    display: 'block',
                                    width: '15px',
                                    height: '15px',
                                  }}
                                  src={Bed}
                                />
                              </Box>
                              <Typography variant="body2">
                                {propertyUnits[0].bedrooms} Bed
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: 'grey.500',
                                mr: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  background: '#E9EAF0',
                                  width: '32px',
                                  height: '32px',
                                  borderRadius: '50%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  mr: 1,
                                }}
                              >
                                <SvgColor
                                  sx={{
                                    color: '#82838C',
                                    display: 'block',
                                    width: '15px',
                                    height: '15px',
                                  }}
                                  src={Bathroom}
                                />
                              </Box>
                              <Typography variant="body2">
                                {propertyUnits[0].bathrooms} Bathroom
                              </Typography>
                            </Box>
                          </Stack>
                        ) : null}
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    sm={4}
                    xs={12}
                    sx={{ display: { sm: 'block', xs: 'none' } }}
                  >
                    <Stack alignItems="end">
                      <Typography variant="h5" sx={{ fontWeight: 'normal' }}>
                        {propertyUnits
                          ? propertyUnits.length === 1
                            ? propertyUnits.map((item) => {
                                return item.durationName === 'year' ? (
                                  <>
                                    <b>
                                      {nairaSymbol}
                                      {currencySeprator(
                                        Math.trunc(item.price / 12)
                                      )}
                                    </b>{' '}
                                    /month
                                  </>
                                ) : item.durationName === 'sixMonth' ? (
                                  <>
                                    <b>
                                      {nairaSymbol}
                                      {currencySeprator(
                                        Math.trunc(item.price / 6)
                                      )}
                                    </b>{' '}
                                    /month
                                  </>
                                ) : item.durationName === 'threeMonth' ? (
                                  <>
                                    <b>
                                      {nairaSymbol}
                                      {currencySeprator(
                                        Math.trunc(item.price / 3)
                                      )}
                                    </b>{' '}
                                    /month
                                  </>
                                ) : item.durationName === 'month' ? (
                                  <>
                                    <b>
                                      {nairaSymbol}
                                      {currencySeprator(item.price)}
                                    </b>{' '}
                                    /month
                                  </>
                                ) : null;
                              })
                            : null
                          : null}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Divider />
              <Box sx={{ py: { md: 4, xs: 3 } }}>
                <List>
                  <ListItem sx={{ alignItems: 'start', p: 0, mb: 2 }}>
                    <ListItemIcon>
                      <SvgColor src={award} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="subtitle1">
                        {firstname} {lastname} is a Superhost
                      </Typography>
                      <Typography variant="body2">
                        Superhosts are experienced, highly rated hosts who are
                        committed to providing great stays for guests.
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem sx={{ alignItems: 'start', p: 0, mb: 2 }}>
                    <ListItemIcon>
                      <SvgColor src={location} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="subtitle1">
                        Great location
                      </Typography>
                      <Typography variant="body2">
                        100% of recent guests gave the location a 5-star rating.
                      </Typography>
                    </ListItemText>
                  </ListItem>

                  <ListItem sx={{ alignItems: 'start', p: 0, mb: 2 }}>
                    <ListItemIcon>
                      <SvgColor src={location} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="subtitle1">
                        Minimum duration stay
                      </Typography>
                      <Typography variant="body2">
                        {minimumDurationStay === 10
                          ? '1 Year'
                          : `${minimumDurationStay} Month`}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  {/* <ListItem sx={{ alignItems: 'start', p: 0, mb: 2 }}>
                    <ListItemIcon>
                      <SvgColor src={calendaradd} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: '500' }}
                      >
                        Free cancellation for 48 hours.
                      </Typography>
                      <Typography variant="body2"></Typography>
                    </ListItemText>
                  </ListItem> */}
                </List>
              </Box>
              <Divider />
              <Box sx={{ py: { md: 4, xs: 3 } }}>
                <Typography variant="body1" component="p" sx={{ mb: 2 }}>
                  {description ? (
                    <>{showMore ? description : description.slice(0, 250)}</>
                  ) : null}
                </Typography>
                {description ? (
                  <>
                    {description.length < 250 ? (
                      ''
                    ) : (
                      <Typography
                        variant="button"
                        sx={{
                          mb: 2,
                          textTransform: 'none',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                        onClick={() => setShowMore(!showMore)}
                      >
                        {showMore ? <>Show less</> : <>Show more</>}
                      </Typography>
                    )}
                  </>
                ) : null}
              </Box>
              <Divider />
              <Box sx={{ py: { md: 4, xs: 3 } }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  What this place offers
                </Typography>
                <List
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { sm: 'auto auto', xs: 'auto' },
                    gap: { sm: 3, xs: 1 },
                  }}
                >
                  {amenities.map((item, index) => {
                    return (
                      <ListItem key={index} sx={{ p: 0 }}>
                        <ListItemIcon>
                          <img
                            style={{
                              width: '26px',
                              height: '26px',
                              objectFit: 'contain',
                            }}
                            src={`${IMG_URL}${item.path}`}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant="body1">
                            {item.amenities}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
              {propertyUnits.length > 0 ? (
                <>
                  <Divider />
                  <Box sx={{ py: { md: 4, xs: 3 } }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Unit list
                    </Typography>
                    <PropertyUnitList propertyUnits={propertyUnits} />
                  </Box>
                </>
              ) : null}
            </Grid>
            <Grid item lg={5} xs={12}>
              {loginInfo._id === _id && loginInfo.role === 'landlord' ? null : (
                <ReserveForm
                  setRenderFlag={setRenderFlag}
                  renderFlag={renderFlag}
                  propertyUnits={propertyUnits}
                  propertyOwner={propertyOwner}
                  propertyData={propertyData}
                  handleClickOpen={handleClickOpen}
                  setSpinFlag={setSpinFlag}
                  requestData={requestData ? true : false}
                />
              )}
              {/* {isLoggedin ? (
                loginInfo._id === _id ||
                loginInfo.role === 'landlord' ? null : (
                  <ReserveForm
                    setRenderFlag={setRenderFlag}
                    renderFlag={renderFlag}
                   
                    propertyUnits={propertyUnits}
                    propertyOwner={propertyOwner}
                    propertyData={propertyData}
                    handleClickOpen={handleClickOpen}
                    setSpinFlag={setSpinFlag}
                    requestData={requestData ? true : false}
                  />
                )
              ) : (
                <ReserveForm
                  setRenderFlag={setRenderFlag}
                  renderFlag={renderFlag}
                  propertyUnits={propertyUnits}
                 
                  propertyOwner={propertyOwner}
                  propertyData={propertyData}
                  handleClickOpen={handleClickOpen}
                  setSpinFlag={setSpinFlag}
                />
              )} */}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <PropertyCheckout handleClose={handleClose} open={open} />
    </>
  );
};
