import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ButtonBase, Typography, Box } from '@mui/material';
import { useAuthStore } from '../../redux/features/authSlice';
import { useEffect, useState } from 'react';
import { getLogo, getLogoCommon } from '../../services/apiServices';
import { IMG_URL } from '../../utils/constant';
import { useRedirectStore } from '../../redux/features/userRedirectSlice';
export const LogoSection = (props) => {
  const { color, to, linkUrl } = props;
  const { userRedirectFlag, updateUserRedirectState } = useRedirectStore();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let { loginInfo } = useAuthStore();
  const [logoData, setLogoData] = useState();
  const [fetchSingleUserState, setFetchSingleUserState] = useState({
    message: '',
    spinFlag: false,
    showAlert: false,
    showAlertError: false,
  });
  useEffect(() => {
    getLogoHeader();
  }, [userRedirectFlag]);

  const getLogoHeader = async () => {
    try {
      setFetchSingleUserState({
        ...fetchSingleUserState,
        spinFlag: true,
        showAlert: false,
      });
      const { data } = await getLogoCommon();
      if (data.statusCode === 200) {
        setLogoData(data.logo);
        setFetchSingleUserState({
          ...fetchSingleUserState,
          message: data.message,
          spinFlag: false,
          showAlert: false,
        });
      } else {
        setFetchSingleUserState({
          ...fetchSingleUserState,
          message: data.message,
          spinFlag: false,
          showAlert: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };
  return (
    <>
      {linkUrl ? (
        <ButtonBase
          disableRipple
          component={Link}
          color={color}
          to={linkUrl}
          sx={{ maxWidth: '155px' }}
        >
          {logoData?.logo ? (
            <img src={`${IMG_URL}${logoData?.logo}`} alt="Logo" />
          ) : (
            'Logo'
          )}
        </ButtonBase>
      ) : (
        <Typography component={Box} sx={{ maxWidth: '155px' }}>
          {logoData?.logo ? (
            <img src={`${IMG_URL}${logoData?.logo}`} alt="Logo" />
          ) : (
            'Logo'
          )}
        </Typography>
      )}
    </>
  );
};
