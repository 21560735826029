import React, {
  useRef,
  useState,
  useEffect,
  createRef,
  useCallback,
  useMemo,
} from 'react';
import { styled, alpha } from '@mui/material/styles';
import io from 'socket.io-client';
import Dropzone, { useDropzone } from 'react-dropzone';
import ReactPlayer from 'react-player';
import {
  Paper,
  Card,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Avatar,
  InputAdornment,
  OutlinedInput,
  ListItemButton,
  Typography,
  Badge,
  Stack,
  Button,
  IconButton,
  ListItemAvatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextareaAutosize,
  Tooltip,
  CardMedia,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { Icon } from '@iconify/react';
import Scrollbar from '../../../components/scrollbar';
import SvgColor from '../../../components/svg-color';
import attachment from '../../../assets/icons/attachment-01.svg';
import send from '../../../assets/icons/send-01.svg';
import {
  NoMessage,
  DataNotAvailable,
} from '../../../components/data-not-found';
import { BASEURL, IMG_URL } from '../../../utils/constant';
import moment from 'moment';
import {
  createRoom,
  getMessageList,
  readMessages,
  sendMessages,
  getContactListForChat,
} from '../../../services/apiServices';
import { useAuthStore } from '../../../redux/features/authSlice';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';

const PageTitle = styled(Box)(({ theme }) => ({
  margin: '0 0 32px',
  position: 'absolute',
  left: '41px',
  top: '60px',
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  height: 44,
  borderRadius: 14,
  backgroundColor: '#fff',
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));
const FileAttachBox = styled(Box)(({ theme }) => ({
  padding: '10px 10px 0',
  '& .MuiList-root': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    padding: '0 0 10px',
    minWidth: 'max-content',
    '& .MuiListItem-root': {
      width: '120px',
      height: '100px',
      padding: '0',
      borderRadius: '10px',
      overflow: 'hidden',
      background: '#fff',
      border: '1px solid rgba(145, 158, 171, 0.3)',
      position: 'relative',
      wordBreak: 'break-all',
      whiteSpace: 'pre-line',
      fontSize: '10px',
      '&>div': {
        width: '100%',
        height: '100%',
        '&>*': {
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        },
      },
    },
  },
}));
const downloadButtona = {
  position: 'absolute',
  top: '5px',
  right: '5px',
  width: '30px !important',
  height: '30px !important',
  background: '#fff !important',
  border: '1px solid rgb(241, 241, 244)',
};
const DownloadButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '5px',
  right: '5px',
  width: '30px !important',
  height: '30px !important',
  background: '#fff !important',
  border: '1px solid rgb(241, 241, 244)',
}));
const MessageSendBoxMain = styled(Box)(({ theme }) => ({
  padding: '16px',
  position: 'relative',
  zIndex: '1',
}));
const MessageSendBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${alpha(theme.palette.grey[500], 0.32)}`,
  borderRadius: '32px',
  backgroundColor: '#fff',
  overflow: 'hidden',
}));
const MessageInputBox = styled(Box)(({ theme }) => ({
  height: { md: '67px', xs: '50px' },
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
}));
const MessageInput = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  resize: 'none',
  border: 'none',
  boxShadow: 'none',
  outline: 'none',
  fontFamily: `Inter, sans-serif`,
  fontSize: 14,
  // padding: '0 15px',
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));
const StyledBadge = styled(Badge)(({ theme }) => ({
  // '& .MuiBadge-badge': {
  //   backgroundColor: '#D1D5DB',
  //   color: '#D1D5DB',
  //   boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  // },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const ChatInput = (props) => {
  let {
    handleKeyPress,
    typingHandler,
    sendMessage,
    inputRef,
    sendMessageHandler,
    send,
  } = props;
  return (
    <>
      <Box sx={{ px: 1, width: 'calc(100% - 80px)' }}>
        <MessageInput
          maxRows={4}
          autoFocus={true}
          id="personalMsg"
          name="personalMsg"
          value={sendMessage}
          onKeyDown={handleKeyPress}
          onChange={typingHandler}
          autoComplete="off"
          placeholder="Enter Message...."
          ref={inputRef}
        />
      </Box>
      <IconButton
        type="submit"
        onClick={() => sendMessageHandler()}
        sx={{
          bgcolor: '#323232 !important',
          color: '#fff !important',
        }}
      >
        <SvgColor src={send} />
      </IconButton>
    </>
  );
};

export const Message = () => {
  let { loginInfo } = useAuthStore();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const chatContainerRef = useRef(null);
  const inputRef = useRef(null);
  const location = useLocation();
  const scrollbarRef = useRef(null);
  const msgScrollRef = useRef(null);
  const totalMessageRef = useRef(null);
  const totalContactRef = useRef(null);
  const allContactScrollRef = useRef(null);
  const totalAllContactRef = useRef(null);
  const prevScrollHeightRef = useRef(0);
  const [open, setOpen] = useState(false);
  const [spinFlag, setSpinFlag] = useState(false);
  const [message, setMessage] = useState('');
  const [openContact, setOpenContact] = useState(false);
  const [membersList, setMemeberList] = useState([]);
  const [messageList, setMessageList] = useState([]);
  const [sendMessage, setSendMessage] = useState('');
  const [selectedChatUserId, setSelectedChatUserId] = useState();
  const [typing, setTyping] = useState(false);
  const [istyping, setIsTyping] = useState(false);
  const [headerName, setHeaderName] = useState();
  const [roomId, setRoomId] = useState();
  const [currentSocket, setCurrentSocket] = useState(null);
  const [searchByName, setSearchByName] = useState('');
  const [searchByContact, setSearchByContact] = useState('');
  const [socketConnected, setSocketConnected] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [showBottomArrow, setShowBottomArrow] = useState();
  const [contactListPage, setContactListPage] = useState(6);
  const [allContactListPage, setAllContactListPage] = useState(6);
  const [messageListPage, setMessageListPage] = useState(25);
  const [isDragActive, setIsDragActive] = useState(false);
  const [files, setFiles] = useState([]);
  const [maxFilesPopup, setMaxFilesPopup] = useState(false);

  const getScrollPosition = () => {
    const showBottomArrow =
      msgScrollRef.current.scrollHeight - 1000 > msgScrollRef.current.scrollTop;
    setShowBottomArrow(showBottomArrow);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragActive(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    // Process droppedFiles here
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragActive(true);
  };

  const handleDragLeave = (e) => {
    setIsDragActive(false);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles) => {
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    },
    maxSize: 75 * 1024 * 1024,
    maxFiles: 10,
  });

  const renderPreview = (file) => {
    if (file.type.startsWith('video/')) {
      return (
        <ReactPlayer
          url={URL.createObjectURL(file)}
          width="100%"
          height="100%"
        />
      );
    }
    // else if (file.type === 'application/pdf') {
    //   return (
    //     <Document file={file}>
    //       <Page pageNumber={1} width="100%" height="100%" />
    //     </Document>
    //   );
    // }
    else if (file.type.startsWith('image/')) {
      return (
        <CardMedia
          component="img"
          alt={file.preview}
          image={URL.createObjectURL(file)}
        />
      );
    } else {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Typography
              variant="caption"
              noWrap
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              {file.name.split('.').pop().toLowerCase() === 'pdf' ? (
                <Icon
                  width="20px"
                  // icon="bxs:file-pdf"
                  icon="vscode-icons:file-type-pdf2"
                />
              ) : file.name.split('.').pop().toLowerCase() === 'docx' ||
                file.name.split('.').pop().toLowerCase() === 'doc' ? (
                <Icon width="20px" icon="fxemoji:documenttextpicture" />
              ) : file.name.split('.').pop().toLowerCase() === 'txt' ? (
                <Icon width="20px" icon="fxemoji:documentwithtext" />
              ) : file.name.split('.').pop().toLowerCase() === 'zip' ? (
                <Icon width="20px" icon="vscode-icons:file-type-zip" />
              ) : (
                <Icon width="20px" icon="simple-line-icons:doc" />
              )}
              {file.name.split('.').pop().toLowerCase()}
            </Typography>
            <Typography variant="subtitle2" noWrap>
              {file.name}
            </Typography>
          </Box>
        </Box>
      );
    }
  };
  const scrollToTop = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollIntoView({
        block: 'end',
      });
    }
  };

  const scrollDown = () => {
    scrollToTop();
  };

  const allContactList = async () => {
    try {
      setSpinFlag(true);
      const payload = {
        role: loginInfo.role,
        searchByContact: searchByContact,
        pageNumber: allContactListPage,
      };
      let { data } = await getContactListForChat(payload);
      if (data.statusCode === 200) {
        setContactList(data.contactList);
        totalAllContactRef.current = data.totalContact;
        setSpinFlag(false);
        //new code
        const container = allContactScrollRef.current;

        if (container) {
          container.addEventListener('scroll', handleAllContactListScroll);
          return () => {
            container.removeEventListener('scroll', handleAllContactListScroll);
          };
        }
        //
      } else {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      allContactList();
    }, 700);

    handleRequestTourAndContactLandlord();
    const getMemberList = setTimeout(() => {
      if (currentSocket) {
        let memberListObj = {
          searchByName: searchByName,
          user_id: loginInfo._id,
          pageNumber: contactListPage,
        };
        currentSocket.emit('member-list', memberListObj);
      }
    }, 700);
    return () => {
      clearTimeout(getMemberList);
      clearTimeout(getData);
      window.history.replaceState({}, document.title);
    };
  }, [searchByContact, searchByName, location]);

  // const [hasMore, setHasMore] = useState(true);
  // const [page, setPage] = useState(0);
  // const handleMemberList = (newContacts) => {
  //   // Append the new contacts to your existing list
  //   const nextPage = page + 1;
  //   if (newContacts?.memberList?.length === 0) {
  //         setHasMore(false); // No more items to load
  //       } else {
  //         setPage(nextPage);
  //         setMemeberList((prevContacts) => [prevContacts, ...newContacts?.memberList]);
  //         totalContactRef.current = newContacts.totalMembers;
  //         // setMemeberList((prevList) => [...prevList, ...newItems]);
  //       }
  // };

  //   const loadMore = () => {
  //     //  new code start
  //     let memberListObj = {
  //       searchByName: searchByName,
  //       user_id: loginInfo._id,
  //       pageNumber: page,
  //     };
  //   if(currentSocket) {
  //   currentSocket.emit('member-list', memberListObj);
  //   }
  // };

  useEffect(() => {
    // inputRef.current.focus();
    // Connect to the chat namespace
    const chatSocket = io(`${BASEURL}`);
    const notificationSocket = io(`${BASEURL}`);

    setCurrentSocket(chatSocket);

    let memberListObj = {
      searchByName: searchByName,
      user_id: loginInfo._id,
      pageNumber: contactListPage,
    };
    chatSocket.emit('member-list', memberListObj);
    chatSocket.emit('join chat', roomId);

    // Event handlers for the chat namespace
    chatSocket.on('connect', (socket) => {
      // Perform actions specific to the chat namespace
      setSocketConnected(true);
    });
    chatSocket.on('online', (userId) => {
      setOnlineUsers(userId);
    });

    chatSocket.on('offline', (userId) => {});

    // chatSocket.on('fetch-member-list', handleMemberList )

    chatSocket.on('fetch-member-list', (result) => {
      setMemeberList(result.memberList);
      totalContactRef.current = result.totalMembers;
    });

    chatSocket.on('receiver-member-list', (result) => {
      setMemeberList(result.memberList);
    });

    chatSocket.on('sender-member-list', (result) => {
      setMemeberList(result.memberList);
    });

    // chatSocket.on('message-notify', (data) => {
    //   if (data.roomId !== roomId) {
    //     notificationSocket.emit('notification', data);
    //   } else {
    //     console.log('Not notification.');
    //   }
    // });

    const handleMessages = async (data) => {
      // Handle the received message in the chat namespace
      if (data.roomId === roomId) {
        const read = {
          roomId: roomId,
          receiverId: loginInfo._id,
          senderId: data.senderId._id,
        };
        chatSocket.emit('read-messages', read);
        // chatSocket.emit('delete-notification', read);
        const res = await readMessages(read);
        setMessageList((messageList) => [...messageList, data]);
      }
    };
    chatSocket.on('message', handleMessages);

    chatSocket.on('typing', ({ senderId, room }) => {
      if (roomId === room && senderId === selectedChatUserId) {
        // const isSenderTyping = senderId === selectedChatUserId;
        setIsTyping(true);
        setTyping(true);
      } else {
        setIsTyping(false);
        setTyping(false);
      }
    });

    chatSocket.on('stop typing', ({ senderId }) => {
      setIsTyping(false);
      setTyping(false);
    });

    return () => {
      chatSocket.off('message', handleMessages);
      chatSocket.off('typing');
      chatSocket.off('online');
      // chatSocket.off('stop typing');
      // chatSocket.off('sender-member-list');
      // chatSocket.off('receiver-member-list');
      // chatSocket.off('fetch-member-list');
      // chatSocket.off('message-notify');
      // chatSocket.removeAllListeners()
      // chatSocket.close()
    };
  }, [roomId, selectedChatUserId, location]);

  const contactListHandler = async (contactUser) => {
    const payload = {
      userId: contactUser._id,
    };
    const { data } = await createRoom(payload);
    const hearderData = {
      participants: [contactUser],
      _id: data.id,
    };
    // navigation
    if (data) {
      selectedChatHandler(hearderData);
      setOpenContact(false);
    }
    inputRef.current.focus();
  };

  const handleRequestTourAndContactLandlord = async () => {
    try {
      const searchParams = new URLSearchParams(location.search);
      const paramRoomId = searchParams.get('roomId');
      const _id = state ? state.userId : null;
      const profile = state ? state.profile : null;
      const firstname = state ? state.firstname : null;
      const lastname = state ? state.lastname : null;
      const visitDate = state ? state.visitDate : null;
      const visitMessage = state ? state.visitMessage : null;

      const data = {
        _id: paramRoomId,
        participants: [
          {
            _id: _id,
            firstname: firstname,
            lastname: lastname,
            profile: profile,
          },
        ],
      };
      console.log('dataaaaaaaaa', data);
      if (_id && firstname && lastname) {
        await selectedChatHandler(data);
        if (_id && firstname && lastname && visitDate && visitMessage) {
          setSendMessage(`${visitDate}${' '}${visitMessage}`);
          let val = {
            userId: _id,
            visitDate: visitDate,
            visitMessage: visitMessage,
            room_id: paramRoomId,
          };
          await sendMessageHandler(val);
        }
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const selectedChatHandler = async (room) => {
    currentSocket?.emit('join chat', room._id);
    inputRef.current.focus();
    setHeaderName(room);
    setSendMessage('');
    setRoomId(room._id);
    setSelectedChatUserId(room.participants[0]._id);
    setIsTyping(false);
    setTyping(false);
    const payload = {
      roomId: room._id,
      pageNumber: messageListPage,
    };

    const read = {
      roomId: room._id,
      receiverId: loginInfo._id,
    };

    try {
      // setSpinFlag(true);
      const prevScrollHeight = msgScrollRef.current.scrollHeight;
      const { data } = await getMessageList(payload);
      setMessageList(data.messages);
      totalMessageRef.current = data.totalMessage;
      const res = await readMessages(read);
      // if (msgScrollRef.current) {
      //   msgScrollRef.current.scrollTop =
      //     msgScrollRef.current.scrollHeight - prevScrollHeight;
      // }
      if (room.flag) {
        if (msgScrollRef.current) {
          msgScrollRef.current.scrollTop =
            msgScrollRef.current.scrollHeight - prevScrollHeight;
        }
      } else {
        scrollToTop();
      }
      if (res.data === 'read') {
        currentSocket.emit('member-list', loginInfo._id);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const sendMessageHandler = async (obj) => {
    try {
      setSpinFlag(true);
      currentSocket?.emit('stop typing', {
        roomId: roomId,
        senderId: loginInfo._id,
      });

      const payload = {
        senderId: loginInfo._id,
        roomId: roomId ? roomId : obj.room_id,
        receiverId: selectedChatUserId ? selectedChatUserId : obj.userId,
        content: sendMessage
          ? sendMessage
          : obj
          ? `${obj.visitMessage}${' '}${obj.visitDate}`
          : '',
      };
      let messageData = new FormData();
      files.map((file) => {
        messageData.append(`attachments`, file);
      });
      messageData.append('message', JSON.stringify(payload));

      setSendMessage('');
      setFiles([]);
      if (sendMessage.trim() !== '' || files.length !== 0 || obj) {
        const { data } = await sendMessages(messageData);
        setSpinFlag(false);
        currentSocket?.emit('message', data.messageData);
        setMessageList([...messageList, data.messageData]);

        if (data.messageData) {
          setTimeout(() => {
            scrollToTop();
          }, 100);
        }

        window.history.replaceState({}, document.title);
      }
      // else if (files.length !== 0) {
      //   const { data } = await sendMessages(messageData);
      //   currentSocket.emit('message', data.messageData);
      //   setMessageList([...messageList, data.messageData]);
      //   if (data.messageData) {
      //     setTimeout(() => {
      //       scrollToTop();
      //     }, 100);
      //   }
      // }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const typingHandler = (e) => {
    // e.preventDefault();
    setSendMessage(e.target.value);
    if (!socketConnected) return;

    if (!typing) {
      setTyping(true);
      currentSocket.emit('typing', { room: roomId, senderId: loginInfo._id });
    }
    let lastTypingTime = new Date().getTime();
    var timerLength = 1500;
    setTimeout(() => {
      var timeNow = new Date().getTime();
      var timeDiff = timeNow - lastTypingTime;
      if (timeDiff >= timerLength && typing) {
        currentSocket.emit('stop typing', {
          room: roomId,
          senderId: loginInfo._id,
        });
        setTyping(!typing);
      }
    }, timerLength);
  };

  // Function to handle Enter key press
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault(); // Prevent the default behavior of the Enter key
      setSendMessage((prevMessage) => prevMessage + '\n'); // Append a newline
    } else if (event.key === 'Enter') {
      event.preventDefault();
      sendMessageHandler();
    }
  };

  const handleFilterByContact = (event) => {
    setSearchByContact(event.target.value);
  };

  const handleFilterName = (event) => {
    setSearchByName(event.target.value);
  };

  const handleClickOpen = async () => {
    setOpenContact(true);
    // setTimeout(() => {
    //   const container = allContactScrollRef.current;
    //   if (container) {
    //     container.addEventListener('scroll', handleAllContactListScroll);
    //     return () => {
    //       container.removeEventListener('scroll', handleAllContactListScroll);
    //     };
    //   }
    // }, 100);
  };

  const handleClose = () => {
    setOpenContact(false);
    setMaxFilesPopup(false);
  };

  // to grouped message
  const groupedMessages = {};
  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'day').startOf('day');

  messageList?.forEach((message) => {
    const createdAt = moment(message?.createdAt);
    let dateGroup;

    if (createdAt.isSame(today, 'd')) {
      dateGroup = 'Today';
    } else if (createdAt.isSame(yesterday, 'd')) {
      dateGroup = 'Yesterday';
    } else {
      dateGroup = createdAt.format('DD MMMM YYYY');
    }

    if (!groupedMessages[dateGroup]) {
      groupedMessages[dateGroup] = [];
    }

    groupedMessages[dateGroup].push({
      ...message,
      // agoTime: createdAt.fromNow(),
    });
  });

  // testing code start
  // Fetch more data as the user scrolls down

  const fetchMoreContacts = (currentPage) => {
    //  new code start
    let memberListObj = {
      searchByName: searchByName,
      user_id: loginInfo._id,
      pageNumber: currentPage,
    };

    currentSocket.emit('member-list', memberListObj);
    // new code end
  };
  // Function to check if the user has scrolled to the bottom
  const handleContactListScroll = () => {
    const container = scrollbarRef.current;
    // const target = event.target;

    if (
      container.scrollHeight - container.scrollTop === container.clientHeight &&
      contactListPage < totalContactRef.current
    ) {
      setContactListPage((prevPage) => prevPage + 6);
    }
  };

  // Attach the onScroll event listener to the desired element
  useEffect(() => {
    const container = scrollbarRef.current;
    container.addEventListener('scroll', handleContactListScroll);

    if (currentSocket) {
      fetchMoreContacts(contactListPage);
    }
    return () => {
      container.removeEventListener('scroll', handleContactListScroll);
    };
  }, [contactListPage]);

  useEffect(() => {
    const msgContainer = msgScrollRef.current;
    if (msgContainer) {
      // Store the initial scroll height
      prevScrollHeightRef.current = msgContainer.scrollHeight;
      msgContainer.addEventListener('scroll', handleMessageListScroll);
    }
    if (headerName) {
      headerName.flag = true;
      selectedChatHandler(headerName);
    }
    return () => {
      if (msgContainer) {
        msgContainer.removeEventListener('scroll', handleMessageListScroll);
      }
    };
  }, [messageListPage]);

  const handleMessageListScroll = () => {
    const container = msgScrollRef.current;
    if (
      container &&
      container.scrollTop === 0 &&
      messageListPage < totalMessageRef.current
    ) {
      setMessageListPage((prevPage) => prevPage + 25);
    }
  };

  // for whole contact list pagination start
  // Function to check if the user has scrolled to the bottom
  const handleAllContactListScroll = (event) => {
    const target = event.target;
    if (
      target.scrollHeight - target.scrollTop === target.clientHeight &&
      allContactListPage < totalAllContactRef.current
    ) {
      setAllContactListPage((prevPage) => prevPage + 6);
      allContactList();
    }
  };

  //memoize chat input component
  const AisolutionChatInputcomponent = useMemo(() => {
    return (
      <ChatInput
        handleKeyPress={handleKeyPress}
        typingHandler={typingHandler}
        sendMessage={sendMessage}
        inputRef={inputRef}
        sendMessageHandler={sendMessageHandler}
        send={send}
      />
    );
  }, [typingHandler]);

  return (
    <>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogPopup open={open} setOpen={setOpen} message={message} />
      <PageTitle>
        <Typography
          component="h3"
          variant="h3"
          sx={{
            color: 'text.primary',
          }}
        >
          Message
        </Typography>
      </PageTitle>
      <Card
        sx={{
          borderRadius: {
            md: '14px',
            xs: '0',
          },
          boxShadow: {
            md: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
            xs: 'none',
          },
          background: { md: '#fff', xs: 'none' },
          overflow: { md: 'hidden', xs: 'inherit' },
        }}
      >
        <Box
          component={Paper}
          sx={{
            background: { md: '#fff', xs: 'none' },
            height: { md: '72vh', xs: 'auto' },
            minHeight: '400px',
            display: 'flex',
            flexDirection: { md: 'column', xs: 'row' },
            flexWrap: 'wrap',
            gap: { md: '0', xs: '32px' },
          }}
        >
          <Box
            sx={{
              flex: '0 0 auto',
              width: { md: '320px', xs: '100%' },
              transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              display: 'flex',
              flexDirection: 'column',
              borderRight: { md: '1px solid rgba(145, 158, 171, 0.24)' },
              height: { md: '100%', xs: '500px' },
              maxWidth: '100%',
              borderRadius: {
                md: '0',
                xs: '14px',
              },
              boxShadow: {
                md: 'none',
                xs: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
              },
              background: { md: 'none', xs: '#fff' },
              overflow: { md: 'inherit', xs: 'hidden' },
            }}
          >
            <Box
              sx={{
                p: 2,
                height: '75px',
                borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
              }}
            >
              <StyledSearch
                value={searchByName}
                onChange={handleFilterName}
                placeholder="Search user"
                startAdornment={
                  <InputAdornment position="start">
                    <Icon
                      icon="eva:search-fill"
                      sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                  </InputAdornment>
                }
              />
            </Box>
            <Stack
              flexDirection="row"
              sx={{
                p: 2,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h6">Chats</Typography>
              <Typography
                variant="caption"
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={handleClickOpen}
              >
                Contact list
              </Typography>
            </Stack>
            <Box
              sx={{
                flexGrow: '1',
                height: '100%',
                // maxHeight: 'calc(100% - 5px)',
                overflow: 'auto',
              }}
            >
              <List
                ref={scrollbarRef}
                id="scrollable-container"
                style={{ maxHeight: 'calc(100% - 5px)', overflowY: 'auto' }}
                sx={{ p: 0 }}
              >
                {membersList?.map((val, index) => {
                  return val.participants?.map((user, index) => (
                    <ListItem
                      sx={{
                        p: 0,
                        borderBottom: '1px solid #F1F1F1',
                      }}
                      key={index}
                    >
                      <ListItemButton
                        onClick={() => selectedChatHandler(val)}
                        sx={{
                          p: { md: 3, xs: 2 },
                          borderRadius: '0 !important',
                        }}
                        selected={val._id === roomId ? true : false}
                      >
                        <ListItemAvatar>
                          <StyledBadge
                            color={
                              onlineUsers.includes(val?.participants[0]?._id)
                                ? 'success'
                                : 'secondary'
                            }
                            overlap="circular"
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            variant="dot"
                          >
                            <Avatar
                              alt={user.firstname?.toUpperCase()}
                              src={user.profile && `${IMG_URL}${user.profile}`}
                            />
                          </StyledBadge>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="subtitle1"
                              component="h6"
                              noWrap={true}
                              sx={{ textTransform: 'capitalize' }}
                            >
                              {user?.firstname} {user?.lastname}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              variant="caption"
                              component="p"
                              noWrap={true}
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              {val?.lastMessage?.metaData ? (
                                <>
                                  {val?.lastMessage?.metaData.type ===
                                  'image' ? (
                                    <Icon
                                      width="13px"
                                      icon="vscode-icons:file-type-image"
                                    />
                                  ) : val?.lastMessage?.metaData.type ===
                                    'video' ? (
                                    <Icon
                                      width="13px"
                                      icon="vscode-icons:file-type-video"
                                    />
                                  ) : val?.lastMessage?.metaData.type ===
                                    'doc' ? (
                                    <>
                                      {val?.lastMessage?.metaData.name
                                        .split('.')
                                        .pop()
                                        .toLowerCase() === 'pdf' ? (
                                        <Icon
                                          width="13px"
                                          icon="vscode-icons:file-type-pdf2"
                                        />
                                      ) : val?.lastMessage?.metaData.name
                                          .split('.')
                                          .pop()
                                          .toLowerCase() === 'docx' ||
                                        val?.lastMessage?.metaData.name
                                          .split('.')
                                          .pop()
                                          .toLowerCase() === 'doc' ? (
                                        <Icon
                                          width="13px"
                                          icon="fxemoji:documenttextpicture"
                                        />
                                      ) : val?.lastMessage?.metaData.name
                                          .split('.')
                                          .pop()
                                          .toLowerCase() === 'txt' ? (
                                        <Icon
                                          width="13px"
                                          icon="fxemoji:documentwithtext"
                                        />
                                      ) : val?.lastMessage?.metaData.name
                                          .split('.')
                                          .pop()
                                          .toLowerCase() === 'zip' ? (
                                        <Icon
                                          width="13px"
                                          icon="vscode-icons:file-type-zip"
                                        />
                                      ) : (
                                        <Icon
                                          width="13px"
                                          icon="simple-line-icons:doc"
                                        />
                                      )}
                                    </>
                                  ) : null}
                                  <Typography
                                    variant="caption"
                                    component="p"
                                    noWrap={true}
                                    sx={{
                                      width: 'calc(100% - 13px)',
                                      pl: 0.5,
                                    }}
                                  >
                                    {val?.lastMessage?.metaData.name}
                                  </Typography>
                                </>
                              ) : (
                                val?.lastMessage?.content
                              )}
                            </Typography>
                          }
                        />
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            ml: 2,
                          }}
                        >
                          <Typography
                            variant="caption"
                            sx={{ color: 'text.secondary' }}
                            noWrap={true}
                          >
                            {val?.lastMessage?.updatedAt &&
                            moment(val?.lastMessage?.updatedAt).isSame(
                              moment().startOf('day'),
                              'd'
                            )
                              ? val?.lastMessage?.updatedAt &&
                                moment(val?.lastMessage?.updatedAt).format(
                                  'hh:mm a'
                                )
                              : val?.lastMessage?.updatedAt &&
                                moment(val?.lastMessage?.updatedAt).format(
                                  'MM/DD/YY'
                                )}
                            {/* {moment(val?.lastMessage?.updatedAt).fromNow()} */}
                          </Typography>
                          {val?.unread_count === 0 ? (
                            ''
                          ) : (
                            <>
                              <Box
                                sx={{
                                  minWidth: '20px',
                                  height: '20px',
                                  borderRadius: '20px',
                                  bgcolor: '#FEAE01',
                                  display: `${
                                    val?.lastMessage?.senderId !==
                                      loginInfo._id &&
                                    selectedChatUserId !==
                                      val?.lastMessage?.senderId
                                      ? 'flex'
                                      : 'none'
                                  }`,
                                  flexWrap: 'wrap',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  fontSize: '11px',
                                  color: '#fff',
                                  p: '0 2px',
                                }}
                              >
                                {val?.unread_count > 100 ? (
                                  '99+'
                                ) : (
                                  <>{val?.unread_count}</>
                                )}
                              </Box>
                            </>
                          )}
                        </Box>
                      </ListItemButton>
                    </ListItem>
                  ));
                })}
              </List>

              {membersList?.length === 0 ? (
                <Box sx={{ px: 2, pb: 2, height: '100%' }}>
                  <DataNotAvailable
                    sx={{ height: '100%', p: 2, minHeight: 'auto' }}
                    title="You don't have Chat List"
                  />
                </Box>
              ) : null}
            </Box>
          </Box>
          <Box
            // ref={chatCont}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: '1',
              width: { md: 'calc(100% - 320px)', xs: '100%' },
              maxWidth: '100%',
              height: { md: '100%', xs: 'calc(100svh - 80px)' },
              maxHeight: { md: '100%', xs: '600px' },
              borderRadius: {
                md: '0',
                xs: '14px',
              },
              boxShadow: {
                md: 'none',
                xs: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
              },
              background: { md: 'none', xs: '#fff' },
              overflow: { md: 'inherit', xs: 'hidden' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                p: 2,
                height: '75px',
                borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  visibility: `${!headerName ? 'hidden' : 'flex'}`,
                  flexGrow: '1',
                  alignItems: 'center',
                  minWidth: '200px',
                }}
              >
                <StyledBadge
                  color={
                    onlineUsers.includes(headerName?.participants[0]?._id)
                      ? 'success'
                      : 'secondary'
                  }
                  overlap="circular"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  variant="dot"
                >
                  <Avatar
                    alt={
                      headerName
                        ? headerName?.participants[0]?.firstname?.toUpperCase()
                        : null
                    }
                    src={
                      headerName?.participants[0]?.profile &&
                      `${IMG_URL}${headerName?.participants[0]?.profile}`
                    }
                  />
                </StyledBadge>
                <Box sx={{ ml: 2, flexGrow: '1', minWidth: '200px' }}>
                  <Typography
                    variant="subtitle1"
                    component="h6"
                    sx={{ textTransform: 'capitalize' }}
                    noWrap={true}
                  >
                    {headerName
                      ? headerName?.participants[0]?.firstname +
                        ' ' +
                        headerName?.participants[0]?.lastname
                      : null}
                  </Typography>
                  <Typography variant="caption" component="p" noWrap={true}>
                    {onlineUsers.includes(headerName?.participants[0]?._id) ? (
                      <span>Online</span>
                    ) : (
                      headerName && <span>Offline</span>
                    )}
                  </Typography>
                </Box>
              </Box>
              {/* <IconButton
              sx={{
                bgcolor: '#FFF9EB',
                color: '#FEAE01',
                ml: 2,
              }}
            >
              <SvgColor src={search_sm} />
            </IconButton> */}
              {/* <IconButton
              sx={{
                bgcolor: '#FFF9EB',
                color: '#FEAE01',
                ml: 2,
              }}
            >
              <SvgColor src={phone_call} />
            </IconButton> */}
              {/* <IconButton
              sx={{
                bgcolor: '#FFF9EB',
                color: '#FEAE01',
                ml: 2,
              }}
            >
              <SvgColor src={video_recorder} />
            </IconButton> */}
              {/* <ActionField /> */}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                overflow: 'hidden',
                width: '100%',
                height: 'calc(100% - 75px)',
              }}
            >
              {headerName ? null : (
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: `${headerName ? 'none' : 'flex'}`,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {membersList.length === 0 ? (
                    <NoMessage handleClickOpen={handleClickOpen} />
                  ) : (
                    <>
                      <Stack
                        sx={{
                          p: '10% 0',
                          alignItems: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <StyledBadge
                          color={'success'}
                          overlap="circular"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          variant="dot"
                          sx={{
                            '& .MuiBadge-badge': {
                              width: 12,
                              height: 12,
                              borderRadius: '50%',
                            },
                          }}
                        >
                          <Avatar
                            sx={{ width: 100, height: 100 }}
                            alt={loginInfo.firstname?.toUpperCase()}
                            src={`${IMG_URL}${loginInfo.profile}`}
                          />
                        </StyledBadge>
                        <Box sx={{ mt: 2 }}>
                          <Typography
                            variant="body1"
                            component="h6"
                            sx={{ textTransform: 'capitalize' }}
                            color="secondary"
                            noWrap={true}
                          >
                            Welcome !
                          </Typography>
                          <Typography
                            variant="h3"
                            sx={{ textTransform: 'capitalize' }}
                            noWrap={true}
                          >
                            {loginInfo?.firstname + '  ' + loginInfo.lastname}
                          </Typography>
                          <Button
                            variant="outlined"
                            onClick={handleClickOpen}
                            sx={{ mt: 3 }}
                          >
                            Contact list
                          </Button>
                        </Box>
                      </Stack>
                    </>
                  )}
                </Box>
              )}
              <Box
                sx={{
                  display: `${headerName ? 'flex' : 'none'}`,
                  flexDirection: 'column',
                  overflow: 'hidden',
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                }}
                onDrop={handleDrop}
                onDragEnter={handleDragEnter}
                onDragOver={(event) => event.preventDefault()}
              >
                {isDragActive && (
                  <Box
                    sx={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      top: 0,
                      left: 0,
                      zIndex: 9,
                      border: '10px solid #fff',
                      background: 'rgba(255, 255, 255, 0.9)',
                    }}
                    onDragLeave={handleDragLeave}
                  >
                    <Box
                      {...getRootProps()}
                      sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '2px dashed #999',
                        background: 'rgba(255,255,255,0.5)',
                        borderRadius: '10px',
                      }}
                    >
                      <input name="msgImg" {...getInputProps()} />
                      <Typography
                        variant="subtitle1"
                        sx={{ maxWidth: '60%', textAlign: 'center' }}
                      >
                        <Icon
                          icon="fluent-mdl2:doc-library"
                          style={{ marginRight: '5px' }}
                        />{' '}
                        Drag and drop files here, or click to select files (max
                        75MB)
                      </Typography>
                    </Box>
                  </Box>
                )}
                <Box
                  sx={{
                    height: '100%',
                    position: 'relative',
                    flexGrow: '1',
                    overflow: 'hidden',
                  }}
                >
                  <Box
                    sx={{
                      maxHeight: '100%',
                      // overflow: 'auto',
                      overflowY: 'auto',
                      padding: '0 24px 0',
                    }}
                    ref={msgScrollRef}
                    onScroll={getScrollPosition}
                  >
                    {Object.keys(groupedMessages).map((day, index) => (
                      <Box key={index}>
                        <Typography
                          sx={{
                            textAlign: 'center',
                            position: 'sticky',
                            top: '0',
                            zIndex: 1,
                            padding: '10px 0',
                          }}
                          component="p"
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{
                              bgcolor: '#323232',
                              color: '#fff',
                              p: '5px 10px',
                              borderRadius: '20px',
                              display: 'inline-block',
                            }}
                          >
                            {day}
                          </Typography>
                        </Typography>
                        {groupedMessages[day].map((msg, index) => (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: `${
                                msg.senderId._id == loginInfo._id
                                  ? 'flex-start'
                                  : 'unset'
                              }`,
                              flexDirection: `${
                                msg.senderId._id == loginInfo._id
                                  ? 'row-reverse;'
                                  : 'row'
                              }`,
                              marginBottom: '24px',
                            }}
                            key={index}
                          >
                            <Avatar
                              sx={{
                                mr: `${
                                  msg.senderId._id == loginInfo._id
                                    ? '0'
                                    : '10px'
                                }`,
                                ml: `${
                                  msg.senderId._id == loginInfo._id
                                    ? '10px'
                                    : '0'
                                }`,
                              }}
                              alt={msg.senderId?.firstname?.toUpperCase()}
                              src={
                                msg.receiverId._id == loginInfo._id
                                  ? msg?.senderId?.profile &&
                                    `${IMG_URL}${msg.senderId.profile}`
                                  : msg?.senderId?.profile &&
                                    `${IMG_URL}${msg.senderId.profile}`
                              }
                            />
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: {
                                  md: 'calc(60% - 60px)',
                                  xs: 'calc(80% - 60px)',
                                },
                                alignItems: `${
                                  msg.senderId._id == loginInfo._id
                                    ? 'flex-end'
                                    : 'flex-start'
                                }`,
                              }}
                            >
                              <Box
                                id="chat-messages"
                                sx={{
                                  textAlign: `${
                                    msg.senderId._id !== loginInfo._id
                                      ? 'left'
                                      : 'right'
                                  }`,
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: `${
                                    msg.senderId._id !== loginInfo._id
                                      ? 'flex-start'
                                      : 'flex-end'
                                  }`,
                                }}
                              >
                                {msg.content !== '' && (
                                  <Box sx={{ width: '100%' }}>
                                    <Box
                                      sx={{
                                        textAlign: 'left',
                                        display: 'inline-block',
                                        bgcolor: `${
                                          msg.senderId._id !== loginInfo._id
                                            ? '#323232'
                                            : 'rgb(244, 246, 248)'
                                        }`,
                                        color: `${
                                          msg.senderId._id == loginInfo._id
                                            ? '#2B2730'
                                            : '#fff'
                                        }`,
                                        p: 1.5,
                                        borderRadius: `${
                                          msg.senderId._id == loginInfo._id
                                            ? '14px 14px 0px 14px'
                                            : '14px 14px 14px 0px'
                                        }`,
                                        mb: 1,
                                        fontSize: { md: '14px', xs: '13px' },
                                        maxWidth: '100%',
                                        wordWrap: 'break-word',
                                        whiteSpace: 'pre-wrap',
                                        wordBreak: 'break-word',
                                      }}
                                    >
                                      {msg.content}
                                    </Box>
                                  </Box>
                                )}
                                {msg.attachments.length !== 0 && (
                                  <Box
                                    sx={{
                                      mb: 1,
                                      textAlign: 'left',
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      gap: '5px',
                                      justifyContent: `${
                                        msg.senderId._id !== loginInfo._id
                                          ? 'flex-start'
                                          : 'flex-end'
                                      }`,
                                      width: '100%',
                                    }}
                                  >
                                    {msg.attachments.map((val, index) => (
                                      <Box
                                        key={index}
                                        sx={{
                                          display: 'flex',
                                          flexWrap: 'wrap',
                                          justifyContent: `${
                                            msg.senderId._id !== loginInfo._id
                                              ? 'flex-start'
                                              : 'flex-end'
                                          }`,
                                          width: `${
                                            val.type === 'image'
                                              ? 'calc(50% - 10px)'
                                              : '100%'
                                          }`,
                                          position: 'relative',
                                        }}
                                      >
                                        {val.type === 'image' ? (
                                          <>
                                            <CardMedia
                                              component="img"
                                              alt={val.name}
                                              image={`${IMG_URL}${val.path}`}
                                              title={val.name}
                                              sx={{
                                                // width: '100%',
                                                height: '100%',
                                                // objectFit: 'cover',
                                                background:
                                                  'rgb(241, 241, 244)',
                                                overflow: 'hidden',
                                                borderRadius: '14px',
                                                aspectRatio: '1.2',
                                              }}
                                            />
                                            <DownloadButton
                                              component={Link}
                                              target="_blank"
                                              size="small"
                                              to={`${IMG_URL}${val.path}`}
                                              download
                                              style={downloadButtona}
                                              // onClick={() => {
                                              //   var element =
                                              //     document.createElement('a');
                                              //   var file = new Blob([
                                              //     `${IMG_URL}${val.path}`,
                                              //   ]);
                                              //   element.href =
                                              //     URL.createObjectURL(file);
                                              //   element.download = val.name;
                                              //   element.click();
                                              // }}
                                            >
                                              <Icon icon="ci:download" />
                                            </DownloadButton>
                                          </>
                                        ) : val.type === 'video' ? (
                                          <>
                                            <Box
                                              sx={{
                                                objectFit: 'cover',
                                                background:
                                                  'rgb(241, 241, 244)',
                                                overflow: 'hidden',
                                                borderRadius: '14px',
                                                aspectRatio: '1.2',
                                                width: '200px',
                                                position: 'relative',
                                                '&>div': {
                                                  width: '100% !important',
                                                  height: '100% !important',
                                                },
                                              }}
                                            >
                                              <ReactPlayer
                                                url={`${IMG_URL}${val.path}`}
                                                title={val.name}
                                                //controls
                                              />
                                              <Box
                                                sx={{
                                                  position: 'absolute',
                                                  top: '0',
                                                  right: '0',
                                                  width: '100%',
                                                  height: '100%',
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent: 'center',
                                                  cursor: 'pointer',
                                                  color: '#fff',
                                                }}
                                                component={Link}
                                                target="_blank"
                                                to={`${IMG_URL}${val.path}`}
                                                download
                                              >
                                                <Icon
                                                  width="30%"
                                                  icon="ph:play-circle-thin"
                                                />
                                              </Box>
                                              <DownloadButton
                                                component={Link}
                                                target="_blank"
                                                size="small"
                                                to={`${IMG_URL}${val.path}`}
                                                download
                                                //style={downloadButtona}
                                                // onClick={() => {
                                                //   var element =
                                                //     document.createElement('a');
                                                //   var file = new Blob([
                                                //     `${IMG_URL}${val.path}`,
                                                //   ]);
                                                //   element.href =
                                                //     URL.createObjectURL(file);
                                                //   element.download = val.name;
                                                //   element.click();
                                                // }}
                                              >
                                                <Icon icon="ci:download" />
                                              </DownloadButton>
                                            </Box>
                                          </>
                                        ) : (
                                          <>
                                            <Box
                                              sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '200px',
                                                aspectRatio: '2',
                                                padding: '10px',
                                                background:
                                                  'rgb(241, 241, 244)',
                                                overflow: 'hidden',
                                                borderRadius: '14px',
                                                position: 'relative',
                                              }}
                                              title={val.name}
                                            >
                                              <Box sx={{ width: '100%' }}>
                                                <Typography
                                                  variant="caption"
                                                  noWrap
                                                  sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '5px',
                                                  }}
                                                >
                                                  {val.name
                                                    .split('.')
                                                    .pop()
                                                    .toLowerCase() === 'pdf' ? (
                                                    <Icon
                                                      width="20px"
                                                      // icon="bxs:file-pdf"
                                                      icon="vscode-icons:file-type-pdf2"
                                                    />
                                                  ) : val.name
                                                      .split('.')
                                                      .pop()
                                                      .toLowerCase() ===
                                                      'docx' ||
                                                    val.name
                                                      .split('.')
                                                      .pop()
                                                      .toLowerCase() ===
                                                      'doc' ? (
                                                    <Icon
                                                      width="20px"
                                                      icon="fxemoji:documenttextpicture"
                                                    />
                                                  ) : val.name
                                                      .split('.')
                                                      .pop()
                                                      .toLowerCase() ===
                                                    'txt' ? (
                                                    <Icon
                                                      width="20px"
                                                      icon="fxemoji:documentwithtext"
                                                    />
                                                  ) : val.name
                                                      .split('.')
                                                      .pop()
                                                      .toLowerCase() ===
                                                    'zip' ? (
                                                    <Icon
                                                      width="20px"
                                                      icon="vscode-icons:file-type-zip"
                                                    />
                                                  ) : (
                                                    <Icon
                                                      width="20px"
                                                      icon="simple-line-icons:doc"
                                                    />
                                                  )}
                                                  {val.name
                                                    .split('.')
                                                    .pop()
                                                    .toLowerCase()}
                                                </Typography>
                                                <Typography
                                                  variant="subtitle2"
                                                  noWrap
                                                  sx={{ mt: 1.5 }}
                                                >
                                                  {val.name}
                                                </Typography>
                                              </Box>
                                              <DownloadButton
                                                component={Link}
                                                target="_blank"
                                                size="small"
                                                to={`${IMG_URL}${val.path}`}
                                                download
                                                //style={downloadButtona}
                                                // onClick={() => {
                                                //   var element =
                                                //     document.createElement('a');
                                                //   var file = new Blob([
                                                //     `${IMG_URL}${val.path}`,
                                                //   ]);
                                                //   element.href =
                                                //     URL.createObjectURL(file);
                                                //   element.download = val.name;
                                                //   element.click();
                                                // }}
                                              >
                                                <Icon icon="ci:download" />
                                              </DownloadButton>
                                            </Box>
                                          </>
                                        )}
                                      </Box>
                                    ))}
                                  </Box>
                                )}
                              </Box>
                              <Typography variant="caption" noWrap={true}>
                                {moment(msg.createdAt).format('hh:mm a')}
                                {/* {moment(msg.createdAt).fromNow()} */}
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    ))}
                    <Box ref={chatContainerRef}></Box>
                  </Box>
                  {showBottomArrow && (
                    <IconButton
                      size="small"
                      sx={{
                        position: 'absolute',
                        right: '20px',
                        bottom: 0,
                        background: 'rgb(241, 241, 244) !important',
                        border: '1px solid rgb(241, 241, 244)',
                      }}
                      onClick={scrollDown}
                    >
                      <Icon icon="ep:arrow-down" />
                    </IconButton>
                  )}
                  {istyping ? (
                    <Typography
                      variant="caption"
                      sx={{
                        position: 'absolute',
                        bottom: '0',
                        left: '0',
                        padding: '0 24px',
                        background: '#fff',
                        width: '100%',
                      }}
                    >
                      Typing...
                    </Typography>
                  ) : null}
                </Box>
                <MessageSendBoxMain>
                  <MessageSendBox>
                    {files.length !== 0 && (
                      <FileAttachBox>
                        <Scrollbar>
                          <List>
                            {files.map((file, index) => (
                              <ListItem key={index}>
                                <div>{renderPreview(file)}</div>
                                {/* <div>{file.preview}</div> */}
                                <Tooltip title="Delete" placement="top" arrow>
                                  <IconButton
                                    edge="end"
                                    size="small"
                                    aria-label="delete"
                                    color="error"
                                    sx={{
                                      padding: '2px',
                                      position: 'absolute',
                                      top: '5px',
                                      right: '5px',
                                      bgcolor: '#eee',
                                      border: '1px solid #eee',
                                      fontSize: '0.875rem',
                                      margin: '0',
                                    }}
                                    onClick={() => handleRemoveFile(index)}
                                  >
                                    <Icon icon="iconamoon:close-light" />
                                  </IconButton>
                                </Tooltip>
                              </ListItem>
                            ))}
                          </List>
                        </Scrollbar>
                      </FileAttachBox>
                    )}
                    <MessageInputBox>
                      <IconButton color="primary" {...getRootProps()}>
                        <input name="msgImg" {...getInputProps()} />
                        <SvgColor src={attachment} />
                      </IconButton>
                      {AisolutionChatInputcomponent}
                      {/* <ChatInput
                        handleKeyPress={handleKeyPress}
                        typingHandler={typingHandler}
                        sendMessage={sendMessage}
                        inputRef={inputRef}
                        sendMessageHandler={sendMessageHandler}
                        send={send}
                      /> */}
                      {/* <MessageInput
                        maxRows={3}
                        autoFocus={true}
                        id="personalMsg"
                        name="personalMsg"
                        value={sendMessage}
                        onKeyDown={handleKeyPress}
                        onChange={typingHandler}
                        autoComplete="off"
                        placeholder="Enter Message...."
                        ref={inputRef}
                      /> */}
                      {/* <IconButton
                        type="submit"
                        onClick={() => sendMessageHandler()}
                        sx={{
                          bgcolor: '#323232 !important',
                          color: '#fff !important',
                        }}
                      >
                        <SvgColor src={send} />
                      </IconButton> */}
                    </MessageInputBox>
                  </MessageSendBox>
                </MessageSendBoxMain>
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
      <Dialog
        open={maxFilesPopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        sx={{
          '& .MuiDialog-paper': {
            width: '100%',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Too many files selected
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" component="p" noWrap={true}>
            You have picked too many files. Limit is 10
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button onClose={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openContact}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        sx={{
          '& .MuiDialog-paper': {
            width: '100%',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Contacts{' '}
          <Typography variant="body1" component="span">
            ({contactList.length})
          </Typography>
        </DialogTitle>
        <Box sx={{ px: 3 }}>
          <StyledSearch
            value={searchByContact}
            onChange={handleFilterByContact}
            placeholder="Search contacts"
            startAdornment={
              <InputAdornment position="start">
                <Icon
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            }
          />
        </Box>
        <DialogContent sx={{ height: '385px' }}>
          <List
            id="scrollable-allcontact"
            ref={allContactScrollRef}
            style={{ maxHeight: 'calc(100% - 5px)', overflowY: 'auto' }}
            sx={{ p: 0 }}
          >
            {contactList.map((val, index) => (
              <ListItem
                sx={{
                  p: 0,
                }}
                key={index}
              >
                <ListItemButton
                  onClick={() => contactListHandler(val)}
                  sx={{
                    py: 2,
                    px: 2,
                    borderRadius: '14px',
                    textTransform: 'capitalize',
                  }}
                >
                  <ListItemAvatar>
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      variant="dot"
                    >
                      <Avatar
                        alt={val.firstname.toUpperCase()}
                        src={val.profile && `${IMG_URL}${val.profile}`}
                      />
                    </StyledBadge>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle2"
                        color="primary"
                        noWrap={true}
                      >
                        {val.firstname} {val.lastname}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="caption" component="p" noWrap={true}>
                        {val?.role}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          {contactList?.length === 0 ? (
            <DataNotAvailable title="You don't have Contacts" />
          ) : null}
        </DialogContent>
      </Dialog>
    </>
  );
};
