import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BannerSection } from './BannerSection';
import { BestValueSection } from './BestValueSection';
import { HiringSection } from './HiringSection';
import { getAboutContent, getCmsContent } from '../../services/apiServices';
import { Backdrop, CircularProgress } from '@mui/material';
import { DialogPopup } from '../../components/dialog-popup/DialogPopup';

export const AboutUs = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [aboutContents, setAboutContents] = useState();
  const [aboutInfoList, setAboutInfoList] = useState([]);
  const [aboutBannerSection, setAboutBannerSection] = useState([]);
  const [aboutHiringSection, setAboutHiringSection] = useState([]);
  const [aboutsection, setAboutSection] = useState([]);
  const [spinFlag, setSpinFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [statusCode, setStatusCode] = useState();
  console.log('checkedc path', pathname);
  useEffect(() => {
    fetchCmsAboutUsContent();
    aboutPageContent();
  }, [pathname]);
  const aboutPageContent = async () => {
    try {
      const { data } = await getAboutContent();
      if (data.statusCode === 200) {
        // setAboutPageData(data.aboutUsdata);
        setAboutInfoList(data.aboutUsdata.aboutInfoList);
        // setAboutContents(data.aboutUsdata.aboutUsContent);
      } else {
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };
  const fetchCmsAboutUsContent = async () => {
    try {
      setSpinFlag(true);
      console.log('pathname', pathname);
      const payload = { type: pathname };
      const { data } = await getCmsContent(payload);
      if (data.statusCode === 200) {
        // ('bannerSection');
        // setAboutInfoList(data?.aboutUsdata.aboutInfoList);
        // setAboutContents(data?.aboutUsdata.aboutUsContent);
        setAboutBannerSection(
          data?.cmsContent?.filter((item) => item?.section === 'bannerSection')
        );
        setAboutHiringSection(
          data?.cmsContent?.filter((item) => item?.section === 'hiring')
        );
        setAboutSection(
          data?.cmsContent?.filter((item) => item?.section === 'aboutUs')
        );
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <BannerSection
        // aboutPageData={aboutPageData}
        aboutBannerSection={aboutBannerSection}
        // aboutContents={aboutContents}
      />
      <BestValueSection
        aboutInfoList={aboutInfoList}
        // aboutPageData={aboutPageData}
        // aboutContents={aboutContents}
        aboutsection={aboutsection}
      />
      <HiringSection
        // aboutPageData={aboutPageData}
        aboutHiringSection={aboutHiringSection}
        // aboutContents={aboutContents}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogPopup
        handleClose={handleClose}
        open={open}
        message={message}
        setOpen={setOpen}
        statusCode={statusCode}
        size="xs"
      />
    </>
  );
};
