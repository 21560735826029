export const propertyOptions = [
  {
    label: 'Flat',
    value: 'Flat',
  },
  {
    label: 'Home',
    value: 'Home',
  },
];

export const accountTypeOptions = [
  {
    label: 'Personal',
    value: 'personal',
  },
  {
    label: 'Business',
    value: 'business',
  },
];

export const documentTypeOptions = [
  {
    label: 'Identity Number',
    value: 'identityNumber',
  },
  {
    label: 'Passport Number',
    value: 'passportNumber',
  },
  {
    label: 'Business Registration Number',
    value: 'businessRegistrationNumber',
  },
];

export const priceOptions = [
  {
    label: '1 Month',
    value: 'month',
  },
  {
    label: '3 Month',
    value: 'threeMonth',
  },
  {
    label: '6 Month',
    value: 'sixMonth',
  },
  {
    label: '1 Year',
    value: 'year',
  },
];
