import { styled, List, Box } from '@mui/material';
export const ManFooter = styled('footer')(({ theme }) => ({
  padding: '55px 10px',
  borderTop: '1px solid #EBEBEB',
  //color: `${alpha(theme.palette.secondary.main)}`,
  [theme.breakpoints.down('md')]: {
    padding: '55px 0 30px',
  },
}));
export const FooterTopMain = styled(Box)(({ theme }) => ({
  // [theme.breakpoints.up('md')]: {
  //     display: 'flex',
  // }
}));
export const FooterLogo = styled(Box)(({ theme }) => ({
  marginBottom: '21px',
}));
export const FooterMenuList = styled(List)(({ theme }) => ({
  '& .MuiListItem-root': {
    paddingLeft: '0',
    paddingRight: '0',
    '& a': {
      color: '#101130',
      textDecoration: 'none',
    },
  },
}));

export const FooterBottomMain = styled(Box)(({ theme }) => ({
  paddingTop: '45px',
  [theme.breakpoints.down('md')]: {
    paddingTop: '30px',
  },
}));
export const FooterLeft = styled(Box)(({ theme }) => ({
  maxWidth: '250px',
  [theme.breakpoints.down('md')]: {
    marginBottom: '20px',
  },
}));
export const FooterSocial = styled(Box)(({ theme }) => ({}));
export const FooterBottomRight = styled(Box)(({ theme }) => ({
  '& .MuiList-root': {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'start',
    },
    '& .MuiListItem-root': {
      width: 'auto',
      '& a': {
        color: '#101130',
        textDecoration: 'none',
      },
    },
  },
}));
