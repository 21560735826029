import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Container, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
//
import { Header } from './header';
import { Nav } from './sidebar';
import Intersect from '../../assets/images/Intersect.svg';
import { useAuthStore } from '../../redux/features/authSlice';
import { setToken } from '../../utils/axiosClient';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 70;
const APP_BAR_DESKTOP = 120;

const StyledRoot = styled(Box)({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(8),
  position: 'relative',
  minHeight: '100svh',
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 48,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  '&:before': {
    content: "''",
    background: `#FAFAFA url(${Intersect}) no-repeat center center / cover`,
    minHeight: '254px',
    width: '100%',
    position: 'fixed',
    top: '0',
    left: '0',
    zIndex: '-1',
  },
}));

// ----------------------------------------------------------------------

export const DashboardLayout = () => {
  const [open, setOpen] = useState(false);
  const { loginInfo } = useAuthStore();
  setToken(loginInfo.token);

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />
      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      <Main>
        <Container fixed={true} maxWidth="lg">
          <Outlet />
        </Container>
      </Main>
    </StyledRoot>
  );
};
