import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
} from '@mui/material';
// mock
// components
import Scrollbar from '../../../components/scrollbar';
import { NavSection } from './nav-section';
//
import navConfig from './config';
import { useAuthStore } from '../../../redux/features/authSlice';
import { LogoSection } from '../../../components/logo';
import { useResponsive } from '../../../hooks/useResponsive';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export const Nav = ({ openNav, onCloseNav }) => {
  const { pathname } = useLocation();
  const { loginInfo } = useAuthStore();
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box
        sx={{ px: { lg: 3, xs: 2 }, py: { lg: 5, xs: 3 }, textAlign: 'center' }}
      >
        <LogoSection
          linkUrl={
            loginInfo.role === 'landlord'
              ? '/dashboard'
              : loginInfo.role === 'renter'
              ? '/'
              : '/'
          }
        />
      </Box>
      <NavSection data={navConfig} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: '#fff',
              border: 'none',
              boxShadow: (theme) => theme.customShadows.dropdown,
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
};
