import React, { useState } from 'react';
import {
  Container,
  Card,
  CardContent,
  Backdrop,
  CircularProgress,
  Box,
  styled,
  Typography,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import { AccountDetail } from './AccountDetail';
import { HeaderBreadcrumbs } from '../../../components/breadcrumbs';
export const AccountDetails = (props) => {
  const [spinFlag, setSpinFlag] = useState(false);
  return (
    <>
      <HeaderBreadcrumbs heading="Account details" />
      <Card>
        <CardContent
          sx={{
            p: { lg: 5, md: 3, xs: 2 },
          }}
        >
          <AccountDetail setSpinFlag={setSpinFlag} />
        </CardContent>
      </Card>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
