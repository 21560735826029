import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Pagination } from '@mui/material';
import Rating from 'react-rating';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  UncontrolledDropdown,
  ModalHeader,
} from 'reactstrap';
import 'quill/dist/quill.snow.css';
import AddEditReviews from './AddEditReviews';
import DeleteModal from '../../../Components/Common/DeleteModal';
import {
  getReviewsList,
  deleteReviews,
} from '../../../../services/apiServices';
import { IMG_URL } from '../../../../utils/constant';
import ShowSpinner from '../../../Components/Common/spinner';
import ResponseModal from '../../../Components/Common/SuccessModal';

const ReviewsPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [deleteModal, setDeleteModal] = useState(false);
  const [reviews, setReviewsList] = useState([]);
  const [isReviewsDelete, setIsReviewsDelete] = useState(null);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const [spinFlag, setSpinFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState();
  const [search, setSearch] = useState('');
  const [response, setResponse] = useState({
    message: '',
    open: false,
    statusCode: null,
  });

  const [reviewData, setReviewData] = useState(null);
  const [reviewRenderFlag, setReviewRenderFlag] = useState(false);
  //need to be deleted
  const [deleteState, setDeleteState] = useState({
    message: '',
    spinFlag: false,
    showAlert: false,
    showAlertError: false,
  });

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const onClickDelete = (id) => {
    setIsReviewsDelete(id);
    setDeleteModal(true);
  };

  const handleClose = () => {
    setReviewData(null);
    setIsEdit(null);
    setModal(false);
  };

  const fetchReviews = async () => {
    try {
      setSpinFlag(true);
      let payload = {
        currentPage: currentPage,
        search: search,
      };
      let { data } = await getReviewsList(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setNumberOfPages(data.noOfPages);
        setReviewsList(data.testimonialsList);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  useEffect(() => {
    if (search !== '') {
      const getData = setTimeout(() => {
        fetchReviews();
      }, 1000);
      return () => clearTimeout(getData);
    } else {
      fetchReviews();
    }
  }, [reviewRenderFlag, search, currentPage]);

  //to delete reviews
  const handleDeleteReviews = async () => {
    try {
      setSpinFlag(true);
      let payload = { delete_id: isReviewsDelete };
      let { data } = await deleteReviews(payload);

      if (data.statusCode === 200) {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
        setDeleteModal(false);
        setReviewRenderFlag(!reviewRenderFlag);
      } else {
        setDeleteModal(false);
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  return (
    <React.Fragment>
      <ResponseModal response={response} setResponse={setResponse} />
      <DeleteModal
        deleteState={deleteState}
        show={deleteModal}
        onDeleteClick={handleDeleteReviews}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="g-2">
                    <Col xs={12} className="col-lg-auto">
                      <div>
                        <h5 className="card-title mb-0">Reviews</h5>
                      </div>
                    </Col>
                    <Col xs={12} className="col-lg-auto ms-auto">
                      <div className="list-grid-nav hstack gap-1">
                        <div className="search-box">
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Search reviews..."
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                        <div className="list-grid-nav hstack gap-1">
                          <Button
                            color="success"
                            onClick={() => {
                              setIsEdit(null);
                              setModal(true);
                              // toggle();
                            }}
                            className="text-nowrap"
                          >
                            <i className="ri-add-fill me-1 align-bottom"></i>{' '}
                            Add Reviews
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <div id="teamlist">
                <div className="team-list">
                  {/* loop start */}
                  {reviews.map((item, index) => {
                    console.log('reviews', reviews);
                    return (
                      <Card className="team-box mb-2">
                        <CardBody className="p-3">
                          <Row className="align-items-start team-row">
                            <Col xs={9}>
                              <div className="team-profile-img d-flex gap-3 align-items-start">
                                <div className="avatar-sm img-thumbnail rounded-circle flex-shrink-0">
                                  <img
                                    src={`${IMG_URL}${item.senderProfile}`}
                                    alt=""
                                    className="member-img img-fluid d-block rounded-circle"
                                  />
                                </div>
                                <div className="team-content">
                                  <h5 className="fs-16 mb-1">
                                    {item.senderName}
                                  </h5>
                                  <p className="text-muted mb-1">
                                    {item.senderPost}
                                  </p>
                                </div>
                              </div>
                            </Col>
                            <Col xs={3}>
                              <Row>
                                <UncontrolledDropdown
                                  direction="start"
                                  className="col text-end"
                                >
                                  <DropdownToggle
                                    tag="a"
                                    id="dropdownMenuLink2"
                                    role="button"
                                  >
                                    <i className="ri-more-fill fs-17"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      className="  edit-list"
                                      onClick={() => {
                                        setModal(true);
                                        setReviewData(item);
                                        setIsEdit(item._id);
                                      }}
                                    >
                                      <i className="ri-pencil-line me-2 align-bottom text-muted"></i>
                                      Edit
                                    </DropdownItem>
                                    <DropdownItem
                                      className="  remove-list"
                                      onClick={() => {
                                        onClickDelete(item._id);
                                      }}
                                    >
                                      <i className="ri-delete-bin-5-line me-2 align-bottom text-muted"></i>
                                      Remove
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </Row>
                            </Col>
                          </Row>
                          <div className="mt-3">
                            <div className="d-flex gap-4 mb-2 align-items-center">
                              <div>
                                <div dir="ltr" className="">
                                  <div
                                    id="raterreset"
                                    className="align-middle d-inline rater-reset"
                                  >
                                    <Rating
                                      emptySymbol="mdi mdi-star text-muted"
                                      fullSymbol={'mdi mdi-star text-warning'}
                                      readonly
                                      initialRating={item.rating}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div>
                                <i className="ri-time-line text-primary me-1 align-bottom"></i>
                                {item.postDate.slice(0, 10)}
                              </div>
                            </div>
                            <p className="text-muted">{item.review}</p>
                          </div>
                        </CardBody>
                      </Card>
                    );
                  })}
                </div>
              </div>
            </Col>
          </Row>
          {reviews.length === 0 && (
            <Card>
              <CardBody className="text-center">Data not found</CardBody>
            </Card>
          )}
          {reviews.length > 0 && (
            <Pagination
              shape="rounded"
              count={numberOfPages}
              page={currentPage}
              siblingCount={0}
              boundaryCount={2}
              onChange={handlePageChange}
              sx={{
                py: 2,
                '& .MuiPagination-ul': { justifyContent: 'center' },
              }}
            />
          )}
        </Container>
      </div>

      <Modal
        id="showModal"
        isOpen={modal}
        handleClose={handleClose}
        centered
        size="md"
        toggle={handleClose}
      >
        <ModalHeader modal={modal} toggle={handleClose}>
          {isEdit ? 'Edit Reviews' : 'Add Reviews'}
        </ModalHeader>

        <ModalBody>
          <AddEditReviews
            setSpinFlag={setSpinFlag}
            setResponse={setResponse}
            handleClose={handleClose}
            isEdit={isEdit}
            reviewData={reviewData}
            reviewRenderFlag={reviewRenderFlag}
            setReviewRenderFlag={setReviewRenderFlag}
          />
        </ModalBody>
      </Modal>
      <ShowSpinner spinFlag={spinFlag} />
    </React.Fragment>
  );
};

export default ReviewsPage;
