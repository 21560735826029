import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
} from 'reactstrap';
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';
import { login } from '../../../utils/validation/Admin';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import withRouter from '../../Components/Common/withRouter';
import { adminLogin } from '../../../services/apiServices';
import { useAuthStore } from '../../../redux/features/authSlice';
import ShowSpinner from '../../Components/Common/spinner';
import ResponseModal from '../../Components/Common/SuccessModal';
import { LogoSection } from '../../../components/logo';
import { createUserLogged } from '../../../utils/common-function';

const AdminLogin = (props) => {
  const [userLogin, setUserLogin] = useState([]);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [passwordShow, setPasswordShow] = useState(false);
  const { updateAdminLoginInfo, loginAdminInfo } = useAuthStore();
  const [spinFlag, setSpinFlag] = useState(false);
  let today = new Date();
  const [state, setState] = useState({
    showPassword: false,
    message: '',
    showAlert: false,
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: userLogin.email || '',
      password: userLogin.password || '',
    },
    validationSchema: login,
    onSubmit: async (values) => {
      try {
        setSpinFlag(true);
        const { data } = await adminLogin(values);
        if (data.statusCode == 200) {
          let result = data.userData;
          result.token = data.token;
          createUserLogged('login', data?.message, today, data?.userData?._id);
          setState({
            ...state,
            message: data.message,
            showAlert: false,
          });
          setSpinFlag(false);

          updateAdminLoginInfo(result);
          if (result.role !== 'renter' && result.role !== 'landlord') {
            navigate('/admin/dashboard');
          } else navigate('/admin');
        } else {
          setSpinFlag(false);
          setState({
            ...state,
            message: data.message,
            showAlert: true,
          });
        }
      } catch (err) {
        navigate(`/admin/error?msg=${err}&&path=${pathname}`);
      }
    },
  });

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <LogoSection linkUrl={'/'} />
                  </div>
                  <h4 className="mt-3">FLATTTS</h4>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4 border">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">
                        Sign in to continue to FLATTTS.
                      </p>
                    </div>
                    <div>
                      {state.showAlert ? (
                        <Alert color="danger">{state.message}</Alert>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            Email
                          </Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ''}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <div className="float-end">
                            <Link
                              to="/admin/forgot-password"
                              className="text-muted"
                            >
                              Forgot password?
                            </Link>
                          </div>
                          <Label
                            className="form-label"
                            htmlFor="password-input"
                          >
                            Password
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              name="password"
                              value={validation.values.password || ''}
                              type={passwordShow ? 'text' : 'password'}
                              className="form-control pe-5"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon"
                              onClick={() => setPasswordShow(!passwordShow)}
                            >
                              <i
                                className={`align-middle ${
                                  passwordShow
                                    ? 'ri-eye-off-fill'
                                    : 'ri-eye-fill'
                                }`}
                              ></i>
                            </button>
                          </div>
                        </div>

                        {/* <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="auth-remember-check"
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                          >
                            Remember me
                          </Label>
                        </div> */}

                        <div className="mt-4">
                          <Button
                            color="success"
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            Sign In
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
      <ShowSpinner spinFlag={spinFlag} />
    </React.Fragment>
  );
};

export default withRouter(AdminLogin);
