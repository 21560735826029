import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthStore } from '../redux/features/authSlice';

export const PermissionModules = ({ permissionType, children }) => {
  const { loginAdminInfo } = useAuthStore();
  const permission = loginAdminInfo?.data;
  let auth = false;
  permission.map((item) => {
    if (item?.users?.length > 0) {
      item?.users?.map((usersitem) => {
        if (usersitem.module_name == permissionType) {
          auth = true;
        }
      });
    }

    if (item?.properties?.length > 0) {
      item?.properties?.map((propertiesitem) => {
        if (propertiesitem.module_name == permissionType) {
          auth = true;
        }
      });
    }

    if (item?.chat?.length > 0) {
      item?.chat?.map((chatitem) => {
        if (chatitem.module_name == permissionType) {
          auth = true;
        }
      });
    }

    if (item?.payment?.length > 0) {
      item?.payment?.map((paymentitem) => {
        if (paymentitem.module_name == permissionType) {
          auth = true;
        }
      });
    }

    if (item?.setting?.length > 0) {
      item?.setting?.map((settingitem) => {
        if (settingitem.module_name == permissionType) {
          auth = true;
        }
      });
    }

    if (item?.masterCrud?.length > 0) {
      item?.masterCrud?.map((masterCruditem) => {
        if (masterCruditem.module_name == permissionType) {
          auth = true;
        }
      });
    }

    if (item?.emailModule?.length > 0) {
      item?.emailModule?.map((emailModuleitem) => {
        if (emailModuleitem.module_name == permissionType) {
          auth = true;
        }
      });
    }
  });
  if (loginAdminInfo?.role === 'superadmin') auth = true;
  return auth ? children : <Navigate to="/admin/dashboard" />;
};
