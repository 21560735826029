import { styled, AppBar, Box } from '@mui/material';

export const RootStyle = styled(AppBar)(({ theme }) => ({
  padding: '20px 0',
  boxShadow: 'none',
  [theme.breakpoints.down('md')]: {
    padding: '16px 0',
  },
}));
export const HeadLogo = styled(Box)(({ theme }) => ({
  marginRight: '30px',
  '& .MuiButtonBase-root': {
    width: '100%',
    maxWidth: '100px',
  },
  [theme.breakpoints.down('lg')]: {
    marginRight: '15px',
  },
  [theme.breakpoints.down('md')]: {
    marginRight: '0',
  },
}));
export const HeaderLeft = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));
export const NavBar = styled('nav')(({ theme }) => ({
  '& .MuiList-root': {
    '& .MuiListItem-root': {
      '& .nav-link': {
        color: '#6F737C',
        textDecoration: 'none',
        '&.active': {
          color: '#323232',
          fontWeight: '600',
        },
        [theme.breakpoints.down('xl')]: {
          fontSize: '14px',
        },
        [theme.breakpoints.down('lg')]: {
          fontSize: '13px',
        },
      },
    },
  },
}));
export const HeaderRight = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));
export const HeaderSign = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '5px',
  paddingLeft: '5px',
  '& .MuiButton-root': {
    [theme.breakpoints.down('xl')]: {
      padding: '8px 30px',
      fontSize: '13px',
      height: '45px',
    },
  },
}));
export const NavBarDesktop = styled(Box)(({ theme }) => ({
  '& .MuiList-root': {
    display: 'flex',
    padding: '0',
    '& .MuiListItem-root': {
      padding: '0 25px',
      width: 'auto',
      [theme.breakpoints.down('xl')]: {
        padding: '0 15px',
      },
      [theme.breakpoints.down('lg')]: {
        padding: '0 10px',
      },
    },
  },
}));

export const NavBarMobile = styled(Box)(({ theme }) => ({
  maxWidth: '450px',
  width: '100%',
  margin: '0 auto',
  padding: '0 0 20px',
  '& .MuiList-root': {
    '& .MuiListItem-root': {
      display: 'block',
      '& .nav-link': {
        fontSize: '16px',
        padding: '5px 12px',
        display: 'block',
      },
    },
  },
}));
