import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Rating from 'react-rating';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Offcanvas,
  OffcanvasBody,
  UncontrolledDropdown,
  ModalHeader,
} from 'reactstrap';
import 'quill/dist/quill.snow.css';
import icondummyimg from '../../../../assets/images/logo-sm.png';
import AddEditList from './AddEditList';
import DeleteModal from '../../../../Components/Common/DeleteModal';
import ResponseModal from '../../../../Components/Common/SuccessModal';
import ShowSpinner from '../../../../Components/Common/spinner';
import { getAboutUsList } from '../../../../../services/apiServices';
import { IMG_URL } from '../../../../../utils/constant';
const AboutUsList = () => {
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [customize, setcustomize] = useState('');
  const [reset, setreset] = useState('');
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [customer, setCustomer] = useState([]);
  const [modalEdit, setModalEdit] = useState(false);
  const [render, setRender] = useState(true);
  const [spinFlag, setSpinFlag] = useState(false);
  const [aboutUsList, setAboutUsList] = useState([]);
  const [aboutUsData, setAboutUsData] = useState(null);
  const { pathname } = useLocation();
  const [response, setResponse] = useState({
    message: '',
    open: false,
    statusCode: null,
  });
  const [deleteState, setDeleteState] = useState({
    message: '',
    spinFlag: false,
    showAlert: false,
    showAlertError: false,
  });
  useEffect(() => {
    fetchAboutUsDetails();
  }, [render]);

  const fetchAboutUsDetails = async () => {
    try {
      setSpinFlag(true);
      let { data } = await getAboutUsList();
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setAboutUsList(data?.aboutUsdata);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };
  const onClickDelete = (id) => {
    setDeleteModal(true);
  };
  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCustomer(null);
    } else {
      setModal(true);
    }
  }, [modal]);
  // const handleCustomerClick = useCallback(
  //   (arg) => {
  //     const customer = arg;
  //     setCustomer();
  //     setIsEdit(true);
  //     toggle();
  //   },
  //   [toggle]
  // );
  const handleClose = () => {
    setModal(false);
    setAboutUsData(null);
    setIsEdit(null);
  };
  return (
    <React.Fragment>
      <ResponseModal response={response} setResponse={setResponse} />
      <DeleteModal
        deleteState={deleteState}
        show={deleteModal}
        // onDeleteClick={handleDeleteFaq}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="g-2">
                    <Col>
                      <div>
                        <h5 className="card-title mb-0">About Us List</h5>
                      </div>
                    </Col>
                    <Col className="col-sm-auto ms-auto"></Col>
                  </Row>
                </CardBody>
              </Card>
              <div id="teamlist">
                <div className="team-list">
                  <Row>
                    {aboutUsList?.map((item, index) => {
                      return (
                        <Col lg={4}>
                          <Card className="team-box position-relative">
                            <CardHeader className="border-0 position-absolute w-100 start-0 top-0 bg-transparent">
                              <Row className="align-items-center team-row">
                                <Col className="team-settings">
                                  <Row>
                                    <Col>
                                      <div className="flex-shrink-0 me-2"></div>
                                    </Col>
                                    <Col>
                                      <div className="hstack gap-2 justify-content-end">
                                        <UncontrolledDropdown
                                          direction="start"
                                          className="col text-end"
                                        >
                                          <DropdownToggle
                                            tag="a"
                                            id="dropdownMenuLink2"
                                            role="button"
                                          >
                                            <i className="ri-more-fill fs-17"></i>
                                          </DropdownToggle>
                                          <DropdownMenu>
                                            <DropdownItem
                                              className=" edit-list"
                                              // onClick={() => {
                                              //   handleCustomerClick();
                                              // }}
                                              onClick={() => {
                                                setModal(true);
                                                setAboutUsData(item);
                                                setIsEdit(item._id);
                                              }}
                                            >
                                              <i className="ri-pencil-line me-2 align-bottom text-muted"></i>
                                              Edit
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody className="p-4 text-center border-top">
                              <div className="text-center mb-4">
                                <div className="position-relative d-inline-block">
                                  <div className="avatar-lg">
                                    <div className="avatar-title bg-light rounded-circle">
                                      <img
                                        src={
                                          item?.icon
                                            ? `${IMG_URL}${item?.icon}`
                                            : icondummyimg
                                        }
                                        alt=" "
                                        id="member-img"
                                        className="avatar-md rounded-circle object-fit-contain"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="team-content mb-4">
                                <h5 className="fs-16 mb-1">{item?.heading}</h5>
                                <p className="text-muted mb-1">
                                  {item?.description}
                                </p>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })}
                    {aboutUsList?.length === 0 && (
                      <Card>
                        <CardBody className="text-center">
                          Data not found
                        </CardBody>
                      </Card>
                    )}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal id="showModal" isOpen={modal} toggle={toggle} centered size="md">
        <ModalHeader toggle={toggle}>{isEdit ? 'Edit' : 'Add'}</ModalHeader>

        <ModalBody>
          <AddEditList
            handleClose={handleClose}
            setSpinFlag={setSpinFlag}
            setResponse={setResponse}
            aboutUsData={aboutUsData}
            isEdit={isEdit}
            render={render}
            setRender={setRender}
            setModal={setModal}
          />
        </ModalBody>
      </Modal>
      <ShowSpinner spinFlag={spinFlag} />
    </React.Fragment>
  );
};

export default AboutUsList;
