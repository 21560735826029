import React from 'react';
import {
  Box,
  Card,
  Typography,
  Stack,
  IconButton,
  CardContent,
  Checkbox,
  Button,
  Rating,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import SvgColor from '../svg-color';
import Bed from '../../assets/icons/Bed.svg';
import Like from '../../assets/icons/Like.svg';
import nophoto from '../../assets/images/nophoto.jpg';
import Bathroom from '../../assets/icons/Bathroom.svg';
import ArrowIconLeft from '../../assets/icons/ArrowIconLeft.svg';
import ArrowIconRight from '../../assets/icons/ArrowIconRight.svg';
import { currencySeprator } from '../../utils/common-function';

import { IMG_URL, nairaSymbol } from '../../utils/constant';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconEmpty': {
    color: '#D3D3DC',
  },
  '& .MuiRating-iconFilled': {
    color: '#FFBD3E',
  },
  '& .MuiRating-iconHover': {
    color: '#FFBD3E',
  },
});
const StyledProductImg = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});
const FavoriteIconButton = styled(Box)(({ theme }) => ({
  zIndex: 9,
  top: 16,
  right: 16,
  position: 'absolute',
  '& .MuiCheckbox-root': {
    border: 'none !important',
    width: 'auto',
    height: 'auto',
    padding: 8,
    borderRadius: '50%',
    color: '#fff',
    '& > *': {
      fontSize: '24px',
    },
    '&.Mui-checked': {
      color: '#FF6645',
    },
  },
}));
const CardIcon = styled(Box)(({ theme }) => ({
  background: '#E9EAF0',
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '8px',
}));

export const PropertyCardMap = (props) => {
  let { propertyValues, sx } = props;
  let {
    title,
    address,
    rating,
    bathrooms,
    bedrooms,
    propertyImages,
    _id,
    propertyUnits,
  } = propertyValues;
  return (
    <Card sx={{ boxShadow: 'none', ...sx }}>
      <CardContent style={{ fontFamily: 'Inter, sans-serif' }}>
        <Box
          className="sliderItemImg"
          style={{
            position: 'relative',
            marginBottom: '24px',
            aspectRatio: '1.10',
            borderRadius: '14px',
            overflow: 'hidden',
            border: '1px solid #EBEBEB',
            maxHeight: '200px',
            width: '100%',
          }}
        >
          <FavoriteIconButton>
            <Checkbox
              icon={<Icon icon="ion:heart-outline" />}
              checkedIcon={<Icon icon="ion:heart-sharp" />}
            />
          </FavoriteIconButton>
          {propertyImages ? (
            <Box style={{ height: '100%' }}>
              {/* <a
                style={{
                  textDecoration: 'none',
                  display: 'block',
                  width: '100%',
                  height: '100%',
                }}
                href={'/property-details/' + _id}
              > */}
              <StyledProductImg
                alt={title}
                src={`${IMG_URL}${propertyImages[0].propertyImage}`}
              />
              {/* </a> */}
            </Box>
          ) : (
            <Box style={{ height: '100%' }}>
              <a
                style={{
                  textDecoration: 'none',
                  display: 'block',
                  width: '100%',
                  height: '100%',
                }}
                href={'/property-details/' + _id}
              >
                <StyledProductImg alt={title} src={nophoto} />
              </a>
            </Box>
          )}
        </Box>
        <Box style={{ display: 'flex' }}>
          <Box style={{ flexGrow: '1', minWidth: '200px' }}>
            <a
              style={{ textDecoration: 'none' }}
              href={'/property-details/' + _id}
              target="_blank"
            >
              <Typography
                variant="h5"
                noWrap
                style={{
                  fontSize: '18px',
                  color: '#323232',
                  margin: '0 0 5px',
                  textTransform: 'capitalize',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {title}
              </Typography>
              <Typography
                variant="body2"
                noWrap
                style={{
                  fontSize: '13px',
                  color: '#6D6D6D',
                  margin: '0',
                  textTransform: 'capitalize',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {address}
              </Typography>
            </a>
          </Box>
          <StyledRating
            defaultValue={rating ? rating : 0}
            value={rating ? rating : 0}
            readOnly
            icon={<Icon icon="ph:star-fill" />}
            emptyIcon={<Icon icon="ph:star-fill" />}
            sx={{
              fontSize: {
                xs: '1rem',
                md: '1rem',
                lg: '1.125rem',
                xl: '1.25rem',
              },
            }}
          />
        </Box>
        <Stack
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '24px',
            flexDirection: { sm: 'row', xs: 'column' },
            alignItems: { sm: 'center', xs: 'self-start' },
          }}
        >
          <Typography
            variant="body2"
            style={{
              color: '#323232',
              textTransform: 'capitalize',
              margin: '0',
            }}
          >
            {propertyUnits ? (
              propertyUnits.length > 1 ? (
                <span>
                  <b>
                    {nairaSymbol}
                    {currencySeprator(
                      Math.min(...propertyUnits.map((item) => item.price))
                    )}
                  </b>{' '}
                  -
                  <b>
                    {nairaSymbol}
                    {currencySeprator(
                      Math.max(...propertyUnits.map((item) => item.price))
                    )}
                  </b>
                </span>
              ) : (
                <span>
                  <b>
                    {nairaSymbol}
                    {currencySeprator(
                      Math.min(...propertyUnits.map((item) => item.price))
                    )}
                  </b>
                </span>
              )
            ) : null}
            {/* {propertyPrice ? (
              <span>
                <b> ${currencySeprator(propertyPrice.price)}</b> /{' '}
                {propertyPrice.durationName}
              </span>
            ) : null} */}
          </Typography>
          <Stack direction="row" style={{ gap: '15px', display: 'flex' }}>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#6D6D6D',
              }}
            >
              <CardIcon>
                <SvgColor
                  sx={{
                    width: '10px',
                    height: '10px',
                  }}
                  src={Bed}
                />
              </CardIcon>
              <Typography
                variant="caption"
                style={{
                  margin: '0',
                  textTransform: 'capitalize',
                  fontSize: '0.75rem',
                }}
              >
                {Math.min(...propertyUnits.map((item) => item.bedrooms))} Bed
              </Typography>
            </Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#6D6D6D',
              }}
            >
              <CardIcon>
                <SvgColor
                  sx={{
                    width: '10px',
                    height: '10px',
                  }}
                  src={Bathroom}
                />
              </CardIcon>
              <Typography
                variant="caption"
                style={{
                  margin: '0',
                  textTransform: 'capitalize',
                  fontSize: '0.75rem',
                }}
              >
                {Math.min(...propertyUnits.map((item) => item.bathrooms))}{' '}
                Bathroom
              </Typography>
            </Box>
          </Stack>
        </Stack>
        <Box style={{ marginTop: '10px' }}>
          <a
            href={'/property-details/' + _id}
            target="_blank"
            style={{
              backgroundColor: 'rgb(50, 50, 50)',
              textTransform: 'capitalize',
              borderRadius: '14px',
              fontWeight: '600',
              boxShadow: 'none',
              height: '45px',
              padding: '13px 40px',
              width: '100%',
              color: '#fff',
              border: 'none',
              display: 'block',
              textAlign: 'center',
              textDecoration: 'none',
            }}
          >
            Book Now
          </a>
        </Box>
      </CardContent>
    </Card>
  );
};
