import { useEffect, useState } from 'react';
import moment from 'moment';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// utils
// components
import Scrollbar from '../../../components/scrollbar';
import { Icon } from '@iconify/react';
import { io } from 'socket.io-client';
import { BASEURL } from '../../../utils/constant';
import { useAuthStore } from '../../../redux/features/authSlice';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  createRoom,
  notificationList,
  notificationRead,
} from '../../../services/apiServices';
import { NoNotifications } from '../../../components/data-not-found';
import { useSelector } from 'react-redux';
// ----------------------------------------------------------------------

export const NotificationsPopover = () => {
  const flag = useSelector((state) => state.notify);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  let { loginInfo } = useAuthStore();
  const [notifications, setNotifications] = useState([]);
  const [realTimeNotification, setRealTimeNotification] = useState([]);
  const [open, setOpen] = useState(null);
  const notificationSocket = io(`${BASEURL}`);
  const location = useLocation();
  // console.log('notifications____________________', notifications);
  useEffect(() => {
    fetchNotificationList();
  }, [realTimeNotification, open, flag]);

  const fetchNotificationList = async () => {
    const payload = {
      userId: loginInfo._id,
    };
    try {
      let { data } = await notificationList(payload);
      if (data.statusCode === 200) {
        setNotifications(data);
      } else {
        setNotifications([]);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  useEffect(() => {
    const notificationSocket = io(`${BASEURL}`);

    if (location.pathname == '/message') {
      notificationSocket.on('sendnotification', (data) => {});
      return;
    }
    notificationSocket.emit('join', loginInfo._id);
    // Socket.io event: booking
    const handleNotification = (booking) => {
      console.log('booking in notificationpopover', booking);
      // Handle the booking notification
      setRealTimeNotification((prev) => [...prev, booking]);
    };
    notificationSocket.on('sendnotification', handleNotification);
    return () => {
      notificationSocket.off('sendnotification', handleNotification);
    };
  }, [notificationSocket]);

  const totalUnRead = notifications?.notificationList?.filter(
    (item) => item.isRead === 0
  ).length;

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        sx={{
          background: '#F4F4F4',
          borderRadius: { xs: '6px', lg: '14px' },
          width: { xs: '42px', lg: '56px' },
          height: { xs: '42px', lg: '56px' },
          minWidth: 'auto',
        }}
      >
        <Badge badgeContent={totalUnRead} color="primary">
          <Icon width="25" height="25" icon="heroicons:bell" />
        </Badge>
      </Button>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
            maxHeight: 'inherit !important',
            p: '0 !important',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            {totalUnRead === 0 && (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {/* {totalUnRead} unread messages */}
              </Typography>
            )}
          </Box>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: 340 }}>
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: 'overline' }}
              >
                {/* <Typography
                  display={todayNotifications.length === 0 ? 'none' : 'flex'}
                  variant="caption"
                >
                  Today
                </Typography> */}
              </ListSubheader>
            }
          >
            {notifications.notificationList
              ?.slice(0, 25)
              .map((notification, index) => {
                return (
                  <div key={index}>
                    <NotificationItem
                      notifications={notification}
                      setOpen={setOpen}
                      key={notification._id}
                      notification={notification}
                    />
                  </div>
                );
              })}
          </List>
          {notifications?.notificationList?.length === 0 && (
            <NoNotifications title="You Don’t have notification" />
          )}
        </Scrollbar>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button
            component={Link}
            to={'/notification'}
            onClick={() => setOpen(null)}
            fullWidth
            disableRipple
            sx={{
              '&:hover': {
                color: '#323232',
              },
            }}
          >
            View All
          </Button>
        </Box>
      </Popover>
    </>
  );
};

// ----------------------------------------------------------------------

function NotificationItem(props) {
  const { notification, setIsRead, setOpen } = props;
  const navigate = useNavigate();

  const handleMessageOpen = async (user) => {
    const payload = {
      userId: JSON.parse(user).senderId._id,
    };
    const { data } = await createRoom(payload);
    // navigation
    if (data.id) {
      let messageData = {
        userId: JSON.parse(user).senderId._id,
        firstname: JSON.parse(user).senderId.firstname,
        lastname: JSON.parse(user).senderId.lastname,
        profile: JSON.parse(user).senderId.profile,
      };

      navigate(`/message?roomId=${data.id}`, { state: messageData });
    }
  };

  const notificationRouteHanler = async (id) => {
    const payload = {
      notificationId: id,
    };
    try {
      const { data } = await notificationRead(payload);

      if (data.statusCode === 200 && data.type === 'Booking') {
        navigate('/dashboard');
        setOpen(null);
      }
      if (data.statusCode === 200 && data.type === 'Payment') {
        navigate('/payment');
        setOpen(null);
      }
      if (data.statusCode === 200 && data.type === 'Message') {
        handleMessageOpen(data.message);
        setOpen(null);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const { avatar, title, content, createdAt, _id } = notification;

  const currentDate = moment();

  let displayTime;
  if (moment(createdAt).isSame(currentDate, 'day')) {
    displayTime = 'today,';
  } else if (
    moment(createdAt).isSame(currentDate.clone().subtract(1, 'days'), 'day')
  ) {
    displayTime = 'yesterday,';
  } else {
    displayTime = moment(createdAt).format('MMM D,');
  }
  return (
    <>
      <ListItemButton
        sx={{
          py: 1.5,
          px: 2.5,
          mt: '1px',
          borderRadius: '0 !important',
          ...(notification?.isRead == 0 && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        {/* <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }} src="" />
      </ListItemAvatar> */}
        <ListItemText
          onClick={() => notificationRouteHanler(_id)}
          primary={
            <Typography
              variant="body2"
              sx={{
                color: 'primary',
              }}
              dangerouslySetInnerHTML={{ __html: content }}
            >
              {/* <div dangerouslySetInnerHTML={{ __html: content }} />  */}
              {/* {content} */}
            </Typography>
          }
          secondary={
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: 'flex',
                alignItems: 'center',
                color: 'text.disabled',
              }}
            >
              {displayTime} {moment(createdAt).format('hh:mm A')}
            </Typography>
          }
        />
      </ListItemButton>
    </>
  );
}

// ----------------------------------------------------------------------
// not used
function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.content}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: 'text.secondary' }}
      >
        {notification.content}
        {/* &nbsp; {noCase(notification.description)} */}
      </Typography>
    </Typography>
  );

  if (notification?.type === 'Booking request') {
    return {
      avatar: (
        <img
          alt={notification?.content}
          src="/assets/icons/ic_notification_package.svg"
        />
      ),
      title,
    };
  }
  if (notification?.type === 'order_shipped') {
    return {
      avatar: (
        <img
          alt={notification?.title}
          src="/assets/icons/ic_notification_shipping.svg"
        />
      ),
      title,
    };
  }
  if (notification?.type === 'mail') {
    return {
      avatar: (
        <img
          alt={notification?.title}
          src="/assets/icons/ic_notification_mail.svg"
        />
      ),
      title,
    };
  }
  if (notification?.type === 'chat_message') {
    return {
      avatar: (
        <img
          alt={notification?.content}
          src="/assets/icons/ic_notification_chat.svg"
        />
      ),
      title,
    };
  }
  return {
    avatar: notification?.avatar ? (
      <img alt={notification?.content} src={notification?.avatar} />
    ) : null,
    title,
  };
}
