import * as Yup from 'yup';
import { AUTH_MSG, PROPERTY_MSG } from '../messages/error';
import { REGEX } from '../regex';
// file supported format
const FILE_SIZE = 160 * 1024;
const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
  'image/svg',
];
export const updateProfileSchema = Yup.object().shape({
  role: Yup.string().required(),
  firstname: Yup.string()
    .trim()
    .matches(REGEX.FULL_NAME, AUTH_MSG.VALID_NAME)
    .max(20, AUTH_MSG.MAX_NAME)
    .required(AUTH_MSG.FIRST_NAME_EMPTY),
  lastname: Yup.string()
    .trim()
    .matches(REGEX.FULL_NAME, AUTH_MSG.VALID_NAME)
    .max(20, AUTH_MSG.MAX_NAME)
    .required(AUTH_MSG.LAST_NAME_EMPTY),

  unit: Yup.string()
    .trim()
    .when('role', {
      is: (val) => val === 'landlord',
      then: Yup.string()
        .matches(REGEX.ALLOWED_NUMBER, AUTH_MSG.VALID_UNIT)
        .required(AUTH_MSG.UNIT_EMPTY),
      otherwise: Yup.string().notRequired(),
    }),
  dob: Yup.string()
    .trim()
    .when('role', {
      is: (val) => val === 'renter',
      then: Yup.string().required(AUTH_MSG.CANNOT_EMPTY),
      otherwise: Yup.string().notRequired(),
    }),

  email: Yup.string()
    .trim()
    .email(AUTH_MSG.VALID_EMAIL)
    .required(AUTH_MSG.DOB_EMPTY),

  mobile: Yup.string()
    .trim()
    .max(15, AUTH_MSG.VALID_PHONE)
    .min(7, AUTH_MSG.VALID_PHONE)
    // .matches(REGEX.MOBILE, AUTH_MSG.VALID_PHONE)
    .required(AUTH_MSG.MOBILE_EMPTY),
  countryCode: Yup.string().trim().required(),
  // profile: Yup.mixed().test(
  //   'fileFormat',
  //   'Unsupported Format',
  //   (value) => value && SUPPORTED_FORMATS.includes(value.type)
  // ),
  // .test(
  //   'fileSize',
  //   'File too large',
  //   (value) => value && value.size <= FILE_SIZE
  // ),
});

// LOGIN VALIDATION
export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email(AUTH_MSG.VALID_EMAIL)
    .required(AUTH_MSG.EMAIL_EMPTY),
  password: Yup.string()
    .trim()
    .matches(REGEX.PASSWORD, AUTH_MSG.VALID_PASSWORD)
    .required(AUTH_MSG.PASSWORD_EMPTY),
});

// SIGN-UP VALIDATION FOR LANDLORD
export const registerSchema = Yup.object().shape({
  firstname: Yup.string()
    .trim()
    .matches(REGEX.FULL_NAME, AUTH_MSG.VALID_NAME)
    .max(20, AUTH_MSG.MAX_NAME)
    .required(AUTH_MSG.FIRST_NAME_EMPTY),
  lastname: Yup.string()
    .trim()
    .matches(REGEX.FULL_NAME, AUTH_MSG.VALID_NAME)
    .max(20, AUTH_MSG.MAX_NAME)
    .required(AUTH_MSG.LAST_NAME_EMPTY),
  unit: Yup.string()
    .trim()
    .matches(REGEX.ALLOWED_NUM_EXCEPT_ZERO, AUTH_MSG.VALID_UNIT)
    .required(AUTH_MSG.UNIT_EMPTY),
  email: Yup.string()
    .trim()
    .email(AUTH_MSG.VALID_EMAIL)
    .required(AUTH_MSG.EMAIL_EMPTY),
  password: Yup.string()
    .trim()
    .matches(REGEX.PASSWORD, AUTH_MSG.VALID_PASSWORD)
    .required(AUTH_MSG.PASSWORD_EMPTY),
  confirmpassword: Yup.string()
    .trim()
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], AUTH_MSG.SAME_PASSWORD),
    })
    .required(AUTH_MSG.CONFIRM_PASSWORD_EMPTY),
  mobile: Yup.string()
    .trim()
    .max(15, AUTH_MSG.VALID_PHONE)
    .min(7, AUTH_MSG.VALID_PHONE)
    // .matches(REGEX.MOBILE, AUTH_MSG.VALID_PHONE) //LENGTH SHOULD BE 7 OR 15
    .required(AUTH_MSG.MOBILE_EMPTY),
});

// SIGN UP VALIDATION FOR RENTER
export const RegisterSchemaForRenter = Yup.object().shape({
  firstname: Yup.string()
    .trim()
    .matches(REGEX.FULL_NAME, AUTH_MSG.VALID_NAME)
    .max(20, AUTH_MSG.MAX_NAME)
    .required(AUTH_MSG.FIRST_NAME_EMPTY),
  lastname: Yup.string()
    .trim()
    .matches(REGEX.FULL_NAME, AUTH_MSG.VALID_NAME)
    .max(20, AUTH_MSG.MAX_NAME)
    .required(AUTH_MSG.LAST_NAME_EMPTY),
  // dob: Yup.string().trim().required(AUTH_MSG.DOB_EMPTY),
  dob: Yup.date()
    .nullable()
    .max(new Date(), 'DOB cannot be in the future')
    .test(
      'is-at-least-15',
      'DOB must be at least 15 years old',
      function (value) {
        // Calculate age in years
        const today = new Date();
        const birthDate = new Date(value);
        const age = today.getFullYear() - birthDate.getFullYear();

        // Check if the person is at least 15 years old
        return age >= 15;
      }
    )
    .required(AUTH_MSG.DOB_EMPTY),
  email: Yup.string()
    .trim()
    .email(AUTH_MSG.VALID_EMAIL)
    .required(AUTH_MSG.EMAIL_EMPTY),
  password: Yup.string()
    .trim()
    .matches(REGEX.PASSWORD, AUTH_MSG.VALID_PASSWORD)
    .required(AUTH_MSG.PASSWORD_EMPTY),
  confirmpassword: Yup.string()
    .trim()
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], AUTH_MSG.SAME_PASSWORD),
    })
    .required(AUTH_MSG.CONFIRM_PASSWORD_EMPTY),
  mobile: Yup.string()
    .trim()
    .max(15, AUTH_MSG.VALID_PHONE)
    .min(7, AUTH_MSG.VALID_PHONE)
    // .matches(REGEX.MOBILE, AUTH_MSG.VALID_PHONE)
    .required(AUTH_MSG.MOBILE_EMPTY),
});

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email(AUTH_MSG.VALID_EMAIL)
    .required(AUTH_MSG.EMAIL_EMPTY),
});

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .matches(REGEX.PASSWORD, AUTH_MSG.VALID_PASSWORD)
    .required(AUTH_MSG.PASSWORD_EMPTY),
  confirmpassword: Yup.string()
    .trim()
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], AUTH_MSG.SAME_PASSWORD),
    })
    .required(AUTH_MSG.CONFIRM_PASSWORD_EMPTY),
});

export const contactUsSchema = Yup.object().shape({
  firstname: Yup.string()
    .trim()
    .matches(REGEX.FULL_NAME, AUTH_MSG.VALID_NAME)
    .max(20, AUTH_MSG.MAX_NAME)
    .required(AUTH_MSG.FIRST_NAME_EMPTY),
  lastname: Yup.string()
    .trim()
    .matches(REGEX.FULL_NAME, AUTH_MSG.VALID_NAME)
    .max(20, AUTH_MSG.MAX_NAME)
    .required(AUTH_MSG.LAST_NAME_EMPTY),
  email: Yup.string()
    .trim()
    .email(AUTH_MSG.VALID_EMAIL)
    .required(AUTH_MSG.EMAIL_EMPTY),
  message: Yup.string()
    .trim()
    .matches(REGEX.DESCRIPTION, PROPERTY_MSG.VALID_DESCRIPTION)
    .max(1000, PROPERTY_MSG.DESCRIPTION_LONG)
    .min(15, PROPERTY_MSG.DESCRIPTION_SHORT)
    .required(AUTH_MSG.MESSAGE_EMPTY),
  privacyCheck: Yup.bool().oneOf([true], AUTH_MSG.CHECK_PRIVACY_POLICY),
});
