import { useState, useRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  ListItemIcon,
  ListItemText,
  Button,
  Popover,
  ListItemButton,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { styled, alpha } from '@mui/material/styles';
import { useNavigate, Link } from 'react-router-dom';
import SvgColor from '../../../components/svg-color';
import avatar_default from '../../../assets/images/avatars/avatar_default.jpg';
import Dashboard from '../../../assets/icons/dashboard.svg';
import AccountDetails from '../../../assets/icons/account-details.svg';
import Settings from '../../../assets/icons/settings.svg';
import Logout from '../../../assets/icons/Logout.svg';
import { useAuthStore } from '../../../redux/features/authSlice';
import { IMG_URL } from '../../../utils/constant';
import { useResponsive } from '../../../hooks/useResponsive';
import { createUserLogged } from '../../../utils/common-function';

const icon = (name) => (
  <SvgColor src={`${name}`} sx={{ width: 18, height: 18 }} />
);

export const StyledNavItem = styled((props) => <MenuItem {...props} />)(
  ({ theme }) => ({
    color: theme.palette.text.secondary,
  })
);

export const StyledNavItemIcon = styled(ListItemIcon)({
  width: 18,
  height: 18,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 5,
});

const MENU_OPTIONS = [
  {
    label: 'Dashboard',
    icon: icon(Dashboard),
    path: './dashboard',
  },
  {
    label: 'Account Details',
    icon: icon(AccountDetails),
    path: 'account-details',
  },
  {
    label: 'Settings',
    icon: icon(Settings),
    path: 'settings',
  },
];

export const AccountPopover = () => {
  let { loginInfo, removeLoginInfo } = useAuthStore();
  let { profile, firstname, lastname, role, _id } = loginInfo;
  const isMobileMode = useResponsive('down', 'lg');
  const [open, setOpen] = useState(null);
  const anchorRef = useRef(null);
  const navigate = useNavigate();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    createUserLogged('logout', 'user logout', new Date(), _id);
    removeLoginInfo();
    navigate('/');
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          background: { xs: 'none !important', lg: '#F4F4F4 !important' },
          minHeight: 'auto',
          padding: { xs: '0', lg: '8px 22px' },
          borderRadius: { xs: '6px', lg: '14px' },
          height: { lg: '56px' },
          width: { lg: '220px' },
          minWidth: 'auto',
        }}
      >
        <ListItemIcon>
          <Avatar
            sx={{
              width: { lg: '32px' },
              height: { lg: '32px' },
              border: '1px solid #FEAE01',
              bgcolor: profile ? '' : '#FEAE01',
            }}
            src={`${IMG_URL}${profile}`}
            alt={firstname}
          />
        </ListItemIcon>
        {!isMobileMode && (
          <>
            <ListItemText>
              <Box
                sx={{
                  textAlign: 'left',
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: 'text.primary',
                    lineHeight: 1,
                    fontSize: { xs: '12px', lg: '16px' },
                  }}
                  noWrap
                >
                  {firstname} {lastname}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: 'text.primary',
                    fontSize: { xs: '10px', lg: '12px' },
                  }}
                  noWrap
                >
                  {role}
                </Typography>
              </Box>
            </ListItemText>
            <Box>
              <Icon
                style={{
                  marginLeft: '5px',
                  fontSize: '22px',
                  color: '#323232',
                }}
                icon="material-symbols:keyboard-arrow-down-rounded"
              />
            </Box>
          </>
        )}
      </Button>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 220,
            borderRadius: 2.5,
            textTransform: 'capitalize',
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {firstname} {lastname}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {role}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <StyledNavItem
              component={RouterLink}
              to={option.path}
              key={option.label}
              onClick={handleClose}
            >
              <StyledNavItemIcon>{option.icon}</StyledNavItemIcon>
              {option.label}
            </StyledNavItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed', my: 1 }} />

        <StyledNavItem
          onClick={handleLogout}
          sx={{ color: 'error.main', minHeight: 'auto', m: 1 }}
        >
          <StyledNavItemIcon>
            <SvgColor src={Logout} sx={{ width: 18, height: 18 }} />
          </StyledNavItemIcon>
          Logout
        </StyledNavItem>
      </Popover>
    </>
  );
};
