import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, Input } from 'reactstrap';
import DatatablesCom from '../../Components/Tables/DataTables/DatatablesCom';
import userNoImage from '../../assets/images/users/user-dummy-img.jpg';
import 'react-toastify/dist/ReactToastify.css';
import { IMG_URL } from '../../../utils/constant';
import { currencySeprator } from '../../../utils/common-function';
import { getAllRecentBookedProperty } from '../../../services/apiServices';

const customStyles = {
  rows: {
    style: {
      // minHeight: '72px',
    },
  },
  headCells: {
    style: {
      padding: '8px',
      background: '#f3f6f9',
      fontWeight: 'bold',
      color: '#878a99',
    },
  },
  cells: {
    style: {
      padding: '8px',
    },
  },
};

const ResentBooking = (props) => {
  let { setResponse, setSpinFlag } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [allRecentBookedList, setAllRecentBookedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationTotalRows, setPaginationTotalRows] = useState(1);
  const [fetchRender, setFetchRender] = useState(true);
  const [search, setSearch] = useState();
  const [searchByDate, setSearchByDate] = useState();
  const [optionsFlag, setOptionsFlag] = useState(false);
  const [placeholder, setPlaceholder] = useState('Search by title');
  const [rowPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    fetchRecentBookedList();
  }, [fetchRender, currentPage, rowPerPage]);

  const fetchRecentBookedList = async () => {
    try {
      setSpinFlag(true);
      let payload = { currentPage, rowPerPage };
      if (search) payload['search'] = search;
      if (searchByDate) payload['searchByDate'] = searchByDate;
      const { data } = await getAllRecentBookedProperty(payload);
      if (data.statusCode == 200) {
        setPaginationTotalRows(data.allRecentBookedCount);
        setAllRecentBookedList(data.allRecentBookedProperty);
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Properties</span>,
      sortable: true,
      width: '350px',
      cell: (row) => {
        return (
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 me-3">
              <Link to={`/admin/property-details/${row?.property[0]?._id}`}>
                <div className="avatar-sm bg-light rounded p-1">
                  {row?.propertyImages[0]?.propertyImage ? (
                    <img
                      className="img-fluid d-block"
                      src={`${IMG_URL}${row?.propertyImages[0]?.propertyImage}`}
                      alt="Header Avatar"
                    />
                  ) : (
                    <img
                      className="img-fluid d-block"
                      src={userNoImage}
                      alt="Header Avatar"
                    />
                  )}
                </div>
              </Link>
            </div>
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                <Link
                  to={`/admin/property-details/${row?.property[0]?._id}`}
                  className="text-dark"
                >
                  {row?.property[0]?.title}
                </Link>
              </h5>
              <p className="text-muted mb-0">
                <span className="fw-medium"> {row?.property[0]?.address}</span>
              </p>
            </div>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Landlord name</span>,
      selector: (row) => (
        <Link
          to={`/admin/user-details?id=${row?.landlordId}`}
          className="text-dark text-capitalize"
        >
          {row?.users[0]?.firstname + ' ' + row?.users[0]?.lastname}
        </Link>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Renter name</span>,

      selector: (row) => (
        <Link
          to={`/admin/user-details?id=${row?.renterId}`}
          className="text-dark text-capitalize"
        >
          {row?.name}
        </Link>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Units name</span>,
      selector: (row) => row?.propertyUnit[0]?.unitName,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Price</span>,
      sortable: true,
      cell: (row) => {
        return (
          <>
            <span>{'₦' + currencySeprator(row?.propertyUnit[0]?.price)}</span>{' '}
          </>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Move-in date</span>,
      selector: (row) => row?.fromDate.substring(0, 10),
      sortable: true,
    },
  ];
  return (
    <>
      <Col lg={12}>
        <Card id="propertiesList">
          <CardHeader className="border-0">
            <Row>
              <Col>
                <div>
                  <h5 className="card-title mb-0">Recent Rentals</h5>
                </div>
              </Col>
              <Col></Col>
            </Row>
          </CardHeader>
          <CardBody className="p-0">
            <div>
              {allRecentBookedList && allRecentBookedList?.length ? (
                <DatatablesCom
                  columns={columns}
                  data={allRecentBookedList}
                  customStyles={customStyles}
                  setCurrentPage={setCurrentPage}
                  paginationTotalRows={paginationTotalRows}
                  setSearch={setSearch}
                  search={search}
                  fetchRender={fetchRender}
                  setFetchRender={setFetchRender}
                  optionsFlag={optionsFlag}
                  setSearchByDate={setSearchByDate}
                  placeholder={placeholder}
                  setRowsPerPage={setRowsPerPage}
                  rowPerPage={rowPerPage}
                />
              ) : (
                <>
                  <DatatablesCom
                    setCurrentPage={setCurrentPage}
                    customStyles={customStyles}
                    setSearch={setSearch}
                    search={search}
                    fetchRender={fetchRender}
                    setFetchRender={setFetchRender}
                    optionsFlag={optionsFlag}
                    setSearchByDate={setSearchByDate}
                    placeholder={placeholder}
                    setRowsPerPage={setRowsPerPage}
                    rowPerPage={rowPerPage}
                  />
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ResentBooking;
