import React from 'react';
import { Grid, Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import bannerImg from '../../assets/images/bannerImg.png';
import HeroBanner from '../../assets/images/HeroBanner.png';
import PropertySearchForm from '../../components/property-search';
import { BrandList } from '../../components/brand-list';
import { IMG_URL } from '../../utils/constant';
const BannerMain = styled(Box)(({ theme }) => ({
  background: `url(${HeroBanner}) no-repeat center center`,
  position: 'relative',
  zIndex: '1',
  padding: '32px 0',
  [theme.breakpoints.down('sm')]: {
    // padding: '24px 0',
  },
}));
const BannerLeft = styled(Box)(({ theme }) => ({
  padding: '40px 0 20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  minHeight: '100%',
  [theme.breakpoints.down('xl')]: {
    padding: '10px 0 20px',
  },
  [theme.breakpoints.down('lg')]: {
    padding: '5px 0 0',
  },
  '& .MuiTypography-h2': {
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
      marginBottom: '20px',
    },
  },
  '& .MuiTypography-h6': {
    [theme.breakpoints.down('xl')]: {
      fontSize: '1.0625rem',
    },
  },
}));
const BannerRight = styled(Box)(({ theme }) => ({
  '& img': {
    display: 'block',
    width: '100%',
    aspectRatio: '1/0.95',
  },
}));
const BannerLeftText = styled(Box)(({ theme }) => ({
  paddingBottom: '40px',
  [theme.breakpoints.down('lg')]: {
    paddingBottom: '20px',
  },
}));
const BrandListBox = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingBottom: '40px',
  '& .MuiListItem-root': {
    justifyContent: 'center',
  },
  [theme.breakpoints.down('md')]: {
    paddingBottom: '0',
  },
}));

export const BannerSection = (props) => {
  let { homeContents, loginInfo, resultRef, bannerSection, handleClickOpen } =
    props;
  return (
    <>
      <BannerMain>
        <Container
          fixed={true}
          sx={{
            maxWidth: {
              xl: '1326px',
            },
          }}
        >
          <Grid
            container
            spacing={3}
            sx={{ flexFlow: { xs: 'column-reverse', md: 'row' } }}
          >
            <Grid item md={6} xs={12}>
              <BannerLeft>
                <BannerLeftText>
                  {bannerSection && bannerSection.length > 0 && (
                    <>
                      <Typography
                        variant="button"
                        component="p"
                        sx={{ mb: 2, color: 'secondary.main' }}
                      >
                        {bannerSection[0]?.subTitle}
                      </Typography>
                      <Typography
                        variant="h2"
                        sx={{ mb: 4, color: 'primary.main' }}
                      >
                        {/* <Typography
                          component="span"
                          variant="h2"
                          sx={{
                            position: 'relative',
                            '&:after': {
                              content: '""',
                              background: '#FEAE01',
                              height: '10px',
                              width: '100%',
                              position: 'absolute',
                              bottom: '-5px',
                              left: '0',
                            },
                          }}
                        >
                          available
                        </Typography> */}
                        {bannerSection[0]?.title}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 'normal', color: 'text.secondary' }}
                      >
                        {bannerSection[0]?.description}
                      </Typography>
                    </>
                  )}
                  {loginInfo.role !== 'landlord' && (
                    <Box sx={{ mt: { lg: 5, xs: 3 } }}>
                      <PropertySearchForm
                        resultRef={resultRef}
                        handleClickOpen={handleClickOpen}
                      />
                    </Box>
                  )}
                </BannerLeftText>
                {/* brand logo */}
                {/* <BrandListBox>
                  <BrandList />
                </BrandListBox> */}
              </BannerLeft>
            </Grid>
            <Grid item md={6} xs={12}>
              <BannerRight>
                {bannerSection && bannerSection.length > 0 && (
                  <>
                    {bannerSection[0]?.bannerImage ? (
                      <img
                        src={`${IMG_URL}${bannerSection[0]?.bannerImage}`}
                        alt="Banner image"
                      />
                    ) : null}
                  </>
                )}
              </BannerRight>
            </Grid>
          </Grid>
        </Container>
      </BannerMain>
    </>
  );
};
