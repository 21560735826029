import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import Widgets from '../../Components/Common/Widgets';
import UsersList from './UsersList';
import { allCountBasedOnRole } from '../../../services/apiServices';
import ShowSpinner from '../../Components/Common/spinner';
import ResponseModal from '../../Components/Common/SuccessModal';

const UserlistPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [allUsersCount, setAllUsersCount] = useState(0);
  const [allRenterCount, setAllRenterCount] = useState(0);
  const [allLandlordCount, setAllLandlordCount] = useState(0);
  const [allPropertyCount, setAllPropertyCount] = useState(0);
  const [allActiveUserCount, setActiveUserCount] = useState(0);
  const [allInActiveUserCount, setInActiveUserCount] = useState(0);
  const [allAdminCount, setAllAdminCount] = useState(0);
  const [countData, setCountData] = useState(0);
  const [renderCount, setRender] = useState(true);
  const [spinFlag, setSpinFlag] = useState(false);
  const [response, setResponse] = useState({
    message: '',
    open: false,
    statusCode: null,
  });
  useEffect(() => {
    getAllCount();
  }, [renderCount]);

  const getAllCount = async () => {
    try {
      setSpinFlag(true);
      const { data } = await allCountBasedOnRole();
      if (data.statusCode == 200) {
        setAllUsersCount(data.allUserCount);
        setAllLandlordCount(data.allLandlordCount);
        setAllRenterCount(data.allRenterCount);
        setAllPropertyCount(data.allPropertiesCount);
        setAllAdminCount(data.allAdminCount);
        setActiveUserCount(data.allActiveUserCount);
        setInActiveUserCount(data.allInActiveUserCount);
        setCountData(data);
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };
  const ecomWidgets = [
    {
      id: 1,
      cardColor: 'success',
      label: 'Total Users',
      badgeClass: 'success',
      counter: allUsersCount,
      bgcolor: 'success',
      icon: 'bx bx-user-circle',
      decimals: 0,
      role: 'all',
      tab: '',
    },
    {
      id: 2,
      cardColor: 'warning',
      label: 'Active Users',
      badgeClass: 'warning',
      counter: allActiveUserCount,
      bgcolor: 'warning',
      icon: 'bx bx-user-plus',
      decimals: 0,
      role: 'property',
      tab: '',
    },
    {
      id: 3,
      cardColor: 'danger',
      label: 'Inactive Users',
      badgeClass: 'danger',
      counter: allInActiveUserCount,
      bgcolor: 'danger',
      icon: 'bx bx-user-minus',
      decimals: 0,
      role: 'landlord',
      tab: '',
    },
    {
      id: 4,
      cardColor: 'info',
      label: 'Landlords',
      badgeClass: 'info',
      counter: allLandlordCount,
      bgcolor: 'info',
      icon: 'bx bxs-user-badge',
      decimals: 0,
      role: 'landlord',
      tab: '',
    },
    {
      id: 5,
      cardColor: 'info',
      label: 'Renter',
      badgeClass: 'info',
      counter: allRenterCount,
      bgcolor: 'info',
      icon: 'bx bxs-user-rectangle',
      decimals: 0,
      role: 'landlord',
      tab: '',
    },
    {
      id: 6,
      cardColor: 'info',
      label: 'Admin Users',
      badgeClass: 'info',
      counter: allAdminCount,
      bgcolor: 'info',
      icon: 'bx bxs-user-account',
      decimals: 0,
      role: 'landlord',
      tab: '',
    },
  ];

  return (
    <React.Fragment>
      <ResponseModal response={response} setResponse={setResponse} />
      <div className="page-content">
        <Container fluid>
          <Row>
            {ecomWidgets.map((item, index) => (
              <Col xxl={4} lg={6} key={index}>
                <Widgets
                  label={item.label}
                  prefix={item.prefix}
                  suffix={item.suffix}
                  separator={item.separator}
                  end={item.counter}
                  decimals={item.decimals}
                  bgcolor={item.bgcolor}
                  icon={item.icon}
                  role={item.role}
                  tab={item.tab}
                />
              </Col>
            ))}
          </Row>
          <Row>
            <UsersList
              setSpinFlag={setSpinFlag}
              renderCount={renderCount}
              setRender={setRender}
              allUsersCount={allUsersCount}
              allLandlordCount={allLandlordCount}
              allRenterCount={allRenterCount}
              allAdminCount={allAdminCount}
              response={response}
              setResponse={setResponse}
            />
          </Row>
        </Container>
        <ShowSpinner spinFlag={spinFlag} />
      </div>
    </React.Fragment>
  );
};

export default UserlistPage;
