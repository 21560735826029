import React from 'react';
import ReactApexChart from 'react-apexcharts';

// Import Images
// import comingSoon from '../../../../assets/images/comingsoon.png';
// import Img4 from '../../../../assets/images/small/img-4.jpg';

import getChartColorsArray from '../Common/ChartsDynamicColor';

const RevenueCharts = ({ monthName, series, dataColors }) => {
  var linechartcustomerColors = getChartColorsArray(dataColors);
  var options = {
    chart: {
      height: 379,
      type: 'line',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'straight',
      dashArray: [0, 0, 8],
      width: [3, 2, 2.2],
    },
    fill: {
      opacity: [1, 1, 1],
    },
    markers: {
      size: [0, 0, 0],
      strokeWidth: 2,
      hover: {
        size: 4,
      },
    },
    xaxis: {
      categories: monthName,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 5,
        right: 5,
        bottom: 5,
        left: 20,
      },
    },
    legend: {
      show: true,
      horizontalAlign: 'center',
      offsetX: 0,
      offsetY: 0,
      markers: {
        width: 9,
        height: 9,
        radius: 6,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '30%',
        barHeight: '70%',
      },
    },
    colors: linechartcustomerColors,
    tooltip: {
      shared: true,
      y: [
        {
          formatter: function (y) {
            if (typeof y !== 'undefined') {
              return y.toFixed(0);
            }
            return y;
          },
        },
        {
          formatter: function (y) {
            if (typeof y !== 'undefined') {
              return '$' + y.toFixed(2) + 'k';
            }
            return y;
          },
        },
        {
          formatter: function (y) {
            if (typeof y !== 'undefined') {
              return y.toFixed(0) + ' Sales';
            }
            return y;
          },
        },
      ],
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={379}
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const StoreVisitsCharts = ({ dataColors }) => {
  var chartDonutBasicColors = getChartColorsArray(dataColors);
  const series = [44, 55, 41, 17, 15];
  var options = {
    labels: ['Direct', 'Social', 'Email', 'Other', 'Referrals'],
    chart: {
      height: 333,
      type: 'donut',
    },
    legend: {
      position: 'bottom',
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
    },
    colors: chartDonutBasicColors,
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height="333"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const SimpleRadialbar = ({ dataColors }) => {
  var chartRadialbarBasicColors = getChartColorsArray(dataColors);
  const series = [70];
  var options = {
    chart: {
      height: 350,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '70%',
        },
      },
    },
    labels: ['Cricket'],
    colors: chartRadialbarBasicColors,
  };
  return (
    <ReactApexChart
      className="apex-charts"
      series={series}
      options={options}
      type="radialBar"
      height={328.7}
    />
  );
};

const MultipleRadialbar = (props) => {
  const {
    dataColors,
    allUsersCount,
    allAdminUserPercentage,
    allLandlordUserPercentage,
    allRenterUserPercentage,
  } = props;

  var chartRadialbarMultipleColors = getChartColorsArray(dataColors);
  const series = [
    allAdminUserPercentage,
    allLandlordUserPercentage,
    allRenterUserPercentage,
  ];
  var options = {
    chart: {
      height: 310,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: '22px',
          },
          value: {
            fontSize: '16px',
          },
          total: {
            show: true,
            label: 'Total',
            formatter: () => {
              return allUsersCount;
            },
          },
        },
      },
    },
    labels: ['Admin Users', 'Landlords', 'Renters'],
    legend: {
      position: 'left',
      show: true,
    },
    colors: chartRadialbarMultipleColors,
  };

  return (
    <>
      <ReactApexChart
        className="apex-charts"
        series={series}
        options={options}
        type="radialBar"
        height={310}
      />
    </>
  );
};

const CircleRadialbar = ({ dataColors }) => {
  var chartRadialbarCircleColors = getChartColorsArray(dataColors);
  const series = [76, 67, 61, 55];
  var options = {
    chart: {
      height: 350,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: '30%',
          background: 'transparent',
          image: undefined,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
    colors: chartRadialbarCircleColors,
    labels: ['Vimeo', 'Messenger', 'Facebook', 'LinkedIn'],
    legend: {
      show: true,
      floating: true,
      fontSize: '16px',
      position: 'left',
      offsetX: 160,
      offsetY: 15,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0,
      },
      formatter: function (seriesName, opts) {
        return seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex];
      },
      itemMargin: {
        vertical: 3,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
  };
  return (
    <ReactApexChart
      className="apex-charts"
      series={series}
      options={options}
      type="radialBar"
      height={328.7}
    />
  );
};

const GradientCircleRadialbar = ({ dataColors }) => {
  var chartRadialbarGradientColors = getChartColorsArray(dataColors);
  const series = [75];
  var options = {
    chart: {
      height: 350,
      type: 'radialBar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          margin: 0,
          size: '70%',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
        },
        track: {
          strokeWidth: '67%',
          margin: 0, // margin is in pixels
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: '#888',
            fontSize: '17px',
          },
          value: {
            formatter: function (val) {
              return parseInt(val);
            },
            color: '#111',
            fontSize: '36px',
            show: true,
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: chartRadialbarGradientColors,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: 'round',
    },
    labels: ['Percent'],
  };
  return (
    <ReactApexChart
      className="apex-charts"
      series={series}
      options={options}
      type="radialBar"
      height={328.7}
    />
  );
};

const ImageRadialbar = () => {
  const series = [67];
  var options = {
    chart: {
      height: 315,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 15,
          size: '65%',
          image: 'comingSoon',
          imageWidth: 56,
          imageHeight: 56,
          imageClipped: false,
        },
        dataLabels: {
          name: {
            show: false,
            color: '#fff',
          },
          value: {
            show: true,
            color: '#333',
            offsetY: 65,
            fontSize: '22px',
          },
        },
      },
    },
    fill: {
      type: 'image',
      image: {
        src: ['Img4'],
      },
    },
    stroke: {
      lineCap: 'round',
    },
    labels: ['Volatility'],
  };
  return (
    <ReactApexChart
      className="apex-charts"
      series={series}
      options={options}
      type="radialBar"
      height={328.7}
    />
  );
};

const StrokedCircleRadial = ({ dataColors }) => {
  var chartStorkeRadialbarColors = getChartColorsArray(dataColors);
  const series = [67];
  var options = {
    chart: {
      height: 326,
      type: 'radialBar',
      offsetY: -10,
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            fontSize: '16px',
            color: undefined,
            offsetY: 120,
          },
          value: {
            offsetY: 76,
            fontSize: '22px',
            color: undefined,
            formatter: function (val) {
              return val + '%';
            },
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        shadeIntensity: 0.15,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 65, 91],
      },
    },
    stroke: {
      dashArray: 4,
    },
    labels: ['Median Ratio'],
    colors: chartStorkeRadialbarColors,
  };
  return (
    <ReactApexChart
      className="apex-charts"
      series={series}
      options={options}
      type="radialBar"
      height={328.7}
    />
  );
};

const SemiCircularRadial = ({ dataColors }) => {
  var chartStorkeRadialbarColors = getChartColorsArray(dataColors);
  const series = [76];
  var options = {
    chart: {
      type: 'radialBar',
      height: 350,
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#e7e7e7',
          strokeWidth: '97%',
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: '#999',
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: '22px',
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    labels: ['Average Results'],
    colors: chartStorkeRadialbarColors,
  };
  return (
    <ReactApexChart
      className="apex-charts"
      series={series}
      options={options}
      type="radialBar"
      height={328.7}
    />
  );
};

const SimpleDonut = ({
  dataColors,
  allUnitCount,
  allBookedUnitCount,
  allAvailableUnitCount,
}) => {
  var chartDonutBasicColors = getChartColorsArray(dataColors);
  const series = [allAvailableUnitCount, allBookedUnitCount];
  var options = {
    chart: {
      height: 310,
      type: 'donut',
    },
    labels: ['Available Units', 'Booked Units'],
    legend: {
      position: 'left',
    },
    plotOptions: {
      pie: {
        size: 100,
        offsetX: 0,
        offsetY: 0,
        donut: {
          size: '70%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '18px',
              offsetY: -5,
            },
            value: {
              show: true,
              fontSize: '20px',
              color: '#343a40',
              fontWeight: 500,
              offsetY: 5,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              fontSize: '13px',
              label: 'Total Units',
              color: '#9599ad',
              fontWeight: 500,
              formatter: function (w) {
                return w.globals.seriesTotals.reduce(function (a, b) {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: chartDonutBasicColors,
  };
  return (
    <ReactApexChart
      className="apex-charts"
      series={series}
      options={options}
      type="donut"
      height={310}
    />
  );
};

export {
  SimpleRadialbar,
  MultipleRadialbar,
  CircleRadialbar,
  GradientCircleRadialbar,
  StrokedCircleRadial,
  SemiCircularRadial,
  ImageRadialbar,
  StoreVisitsCharts,
  RevenueCharts,
  SimpleDonut,
};
