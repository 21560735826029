import React, { useState, useEffect } from 'react';
import {
  Card,
  TextField,
  Button,
  Stack,
  Grid,
  Backdrop,
  CircularProgress,
  CardContent,
  FormHelperText,
  MenuItem,
  Box,
  Typography,
  styled,
} from '@mui/material';
import { Formik, useFormik, Form, FormikProvider } from 'formik';
import { addBankAccountSchema } from '../../../utils/validation/payment';
import { REGEX } from '../../../utils/regex';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  addBankAccount,
  getCardForEdit,
  getBankList,
} from '../../../services/apiServices';
import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';
import {
  accountTypeOptions,
  documentTypeOptions,
} from '../../../utils/optionsForSelect';
import { HeaderBreadcrumbs } from '../../../components/breadcrumbs';

export const AddBankAccounts = () => {
  const { state } = useLocation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [spinFlag, setSpinFlag] = useState(false);
  const [open, setOpen] = useState();
  const [message, setMessage] = useState();
  const [bankList, setBankList] = useState([]);
  const [statusCode, setStatusCode] = useState();

  const initialValues = {
    bankName: '',
    // countryCode: '',
    // bankCode: '',
    accountName: '',
    accountType: '',
    accountNumber: '',
    // documentType: '',
    // documentNumber: '',
  };

  const onSaveBankAccount = async (values) => {
    try {
      setSpinFlag(true);
      // values.cardId = state ? state.id : null;
      let { data } = await addBankAccount(values);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setStatusCode(data.statusCode);
        navigate(`/payment`);
      } else {
        setOpen(true);
        setSpinFlag(false);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const fetchCardForEdit = async () => {
    try {
      setSpinFlag(true);
      let payload = { cardId: state.id };
      let { data } = await getCardForEdit(payload);
      if (data.statusCode === 200) {
        let { cardHolderName, expirationDate, cardNumber, cvv } = data.card;
        setSpinFlag(false);
        setFieldValue('cardHolderName', cardHolderName);
        setFieldValue('expirationDate', expirationDate);
        setFieldValue('cardNumber', cardNumber);
        setFieldValue('cvv', cvv);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const fetchBankList = async () => {
    try {
      setSpinFlag(true);
      let { data } = await getBankList();

      if (data.statusCode === 200) {
        setSpinFlag(false);

        setBankList(data.bankList);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  useEffect(() => {
    fetchBankList();
    if (state) {
      fetchCardForEdit();
    }
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: addBankAccountSchema,
    onSubmit: async (values) => {
      onSaveBankAccount(values);
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    handleChange,
    handleBlur,
    setFieldValue,
    resetForm,
    values,
  } = formik;

  return (
    <>
      <HeaderBreadcrumbs
        heading="Add bank accounts"
        links={[
          { name: 'Payment', href: '/payment' },
          { name: 'Add bank accounts' },
        ]}
      />
      <Card
        sx={{
          mb: 5,
        }}
      >
        <DialogPopup
          open={open}
          setOpen={setOpen}
          message={message}
          size="xs"
          statusCode={statusCode}
        />
        <CardContent
          sx={{
            p: 5,
          }}
        >
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      select
                      fullWidth
                      label="Select bank"
                      value={values.bankName}
                      name="bankName"
                      id="bankName"
                      {...getFieldProps('bankName')}
                      // onChange={handleChange}
                      // onBlur={handleBlur}
                      // error={Boolean(touched.bankName && errors.bankName)}
                      // helperText={touched.bankName && errors.bankName}
                    >
                      {bankList.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    {touched.bankName && errors.bankName && (
                      <FormHelperText error>{errors.bankName}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      type="Text"
                      label="Account name"
                      name="accountName"
                      {...getFieldProps('accountName')}
                      // error={Boolean(touched.accountName && errors.accountName)}
                      // helperText={touched.accountName && errors.accountName}
                    />
                    {touched.accountName && errors.accountName && (
                      <FormHelperText error>
                        {errors.accountName}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      type="Text"
                      label="Account number"
                      name="accountNumber"
                      // onChange={handleChange}
                      // onBlur={handleBlur}
                      value={values.accountNumber}
                      onInput={(e) => {
                        e.target.value = e.target.value
                          .replace(REGEX.RESTRICT_CHAR, '')
                          .slice(0, 19);
                      }}
                      {...getFieldProps('accountNumber')}
                      // error={Boolean(
                      //   touched.accountNumber && errors.accountNumber
                      // )}
                      // helperText={touched.accountNumber && errors.accountNumber}
                    />
                    {touched.accountNumber && errors.accountNumber && (
                      <FormHelperText error>
                        {errors.accountNumber}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      fullWidth
                      label="Account type"
                      value={values.accountType}
                      name="accountType"
                      id="accountType"
                      {...getFieldProps('accountType')}
                      // onChange={handleChange}
                      // onBlur={handleBlur}
                      // error={Boolean(touched.accountType && errors.accountType)}
                      // helperText={touched.accountType && errors.accountType}
                    >
                      {accountTypeOptions.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    {touched.accountType && errors.accountType && (
                      <FormHelperText error>
                        {errors.accountType}
                      </FormHelperText>
                    )}
                  </Grid>
                  {/* <Grid item xs={6}>
                  <TextField
                    select
                    fullWidth
                    label="Document Type"
                    value={values.documentType}
                    name="documentType"
                    id="documentType"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.documentType && errors.documentType)}
                    helperText={touched.documentType && errors.documentType}
                  >
                    {documentTypeOptions.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid> */}
                  {/* <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="Text"
                    label="Enter Your Document Number"
                    name="documentNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.documentNumber}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(REGEX.RESTRICT_CHAR, '')
                        .slice(0, 20);
                    }}
                    {...getFieldProps('documentNumber')}
                    error={Boolean(
                      touched.documentNumber && errors.documentNumber
                    )}
                    helperText={touched.documentNumber && errors.documentNumber}
                  ></TextField>
                </Grid> */}
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ px: 5 }}
                    >
                      Save new bank
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </Form>
          </FormikProvider>
        </CardContent>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={spinFlag}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Card>
    </>
  );
};
