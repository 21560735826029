import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Input,
  Slide,
  Button,
  IconButton,
  Box,
  InputAdornment,
  ClickAwayListener,
} from '@mui/material';
// utils
import { Icon } from '@iconify/react';
import SearchIcon from '../../../assets/icons/search.svg';
import SvgColor from '../../../components/svg-color';
// ----------------------------------------------------------------------

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 120;

const StyledSearchbar = styled(Box)(({ theme }) => ({
  background: '#fff',
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  margin: '0 !important',
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: (theme) => theme.customShadows.dropdown,
  [theme.breakpoints.up('md')]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(6, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export const Searchbar = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box>
        <Button
          onClick={handleOpen}
          sx={{
            background: '#F4F4F4',
            borderRadius: { xs: '6px', lg: '14px' },
            width: { xs: '42px', lg: '56px' },
            height: { xs: '42px', lg: '56px' },
            minWidth: 'auto',
          }}
        >
          <Icon width="25" height="25" icon="fluent:search-28-filled" />
        </Button>

        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <StyledSearchbar>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Search…"
              startAdornment={
                <InputAdornment position="start">
                  <Icon width="18" height="18" icon="fluent:search-28-filled" />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            <Button variant="contained" onClick={handleClose}>
              Search
            </Button>
          </StyledSearchbar>
        </Slide>
      </Box>
    </ClickAwayListener>
  );
};
