import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { CardBody, Row, Col, Card, Container } from 'reactstrap';
import * as moment from 'moment';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import DeleteModal from '../../Components/Common/DeleteModal';
import Widgets from '../../Components/Common/Widgets';

import Loader from '../../Components/Common/Loader';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { nairaSymbol } from '../../../utils/constant';
import { getAllTransactionList } from '../../../services/apiServices';
import TransactionList from './TransactionList';
import PayRequest from './PayRequest';

const invoiceTable = [
  {
    id: 1,
    invoiceId: '#VL25000351',
    img: 'avatar2',
    name: 'Diana Kohler',
    email: 'dianakohler@velzon.com',
    country: 'Brazil',
    date: '06 Apr, 2021',
    time: '09:58PM',
    amount: '$875',
    status: 'Paid',
    statusClass: 'success',
  },
  {
    id: 2,
    invoiceId: '#VL25000352',
    img: 'avatar2',
    name: 'James Morris',
    email: 'jamesmorris@velzon.com',
    country: 'Germany',
    date: '17 Dec, 2021',
    time: '1:24AM',
    amount: '$451.00',
    status: 'Unpaid',
    statusClass: 'warning',
  },
  {
    id: 3,
    invoiceId: '#VL25000353',
    img: 'avatar2',
    name: 'Dawn Koh',
    email: 'dawnkoh@velzon.com',
    country: 'United Kingdom',
    date: '29 Nov, 2021',
    time: '7:20PM',
    amount: '$984.98',
    status: 'Paid',
    statusClass: 'success',
  },
  {
    id: 4,
    invoiceId: '#VL25000354',
    img: 'avatar2',
    name: 'Tonya Noble',
    email: 'tonynoble@velzon.com',
    country: 'Spain',
    date: '22 Nov, 2021',
    time: '10:20PM',
    amount: '$742.12',
    status: 'Cancel',
    statusClass: 'danger',
  },
  {
    id: 5,
    invoiceId: '#VL25000355',
    img: 'avatar2',
    name: 'David Nichols',
    email: 'davidnochols@velzon.com',
    country: 'United States of America',
    date: '11 Nov, 2021',
    time: '12:37AM',
    amount: '$2415.00',
    status: 'Unpaid',
    statusClass: 'warning',
  },
  {
    id: 6,
    invoiceId: '#VL25000356',
    img: 'avatar2',
    name: 'Joseph Payten',
    email: 'josephpayten@velzon.com',
    country: 'France',
    date: '03 Nov, 2021',
    time: '12:29AM',
    amount: '$7451.02',
    status: 'Paid',
    statusClass: 'success',
  },
  {
    id: 7,
    invoiceId: '#VL25000357',
    name: 'Mary Rucker',
    email: 'maryrucker@velzon.com',
    country: 'United Kingdom',
    date: '27 Oct, 2021',
    time: '01:46PM',
    amount: '$327.36',
    status: 'Cancel',
    statusClass: 'danger',
  },
  {
    id: 8,
    invoiceId: '#VL25000358',
    name: 'Alexis Clarke',
    email: 'alexisclarke@velzon.com',
    country: 'Spain',
    date: '18 Oct, 2021',
    time: '04:55PM',
    amount: '$879.78',
    status: 'Unpaid',
    statusClass: 'warning',
  },
  {
    id: 9,
    invoiceId: '#VL25000359',
    img: 'avatar2',
    name: 'Ryan Cowie',
    email: 'rayancowie@velzon.com',
    country: 'France',
    date: '07 Oct, 2021',
    time: '06:33AM',
    amount: '$879.00',
    status: 'Refund',
    statusClass: 'primary',
  },
  {
    id: 10,
    invoiceId: '#VL25000360',
    img: 'avatar2',
    name: 'Christina Maier',
    email: 'christinamaier@velzon.com',
    country: 'United States of America',
    date: '13 Sep, 2021',
    time: '11:59AM',
    amount: '$1624.18',
    status: 'Unpaid',
    statusClass: 'warning',
  },
  {
    id: 11,
    invoiceId: '#VL25000361',
    img: 'avatar2',
    name: 'Jennifer Winkel',
    email: 'jenniferwinkal@velzon.com',
    country: 'Brazil',
    date: '15 Aug, 2021',
    time: '01:05PM',
    amount: '$214.67',
    status: 'Cancel',
    statusClass: 'danger',
  },
  {
    id: 12,
    invoiceId: '#VL25000362',
    name: 'Erik Peters',
    email: 'erikpeters@velzon.com',
    country: 'Mexico',
    date: '07 Aug, 2021',
    time: '07:46PM',
    amount: '$1798.71',
    status: 'Active',
    statusClass: 'success',
  },
];

const PaymentPage = () => {
  //delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [transactionList, setTrasactionList] = useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const [monthName, setMonthName] = useState([]);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [spinFlag, setSpinFlag] = useState(false);

  const fetchAllTransactions = async () => {
    try {
      // setSpinFlag(true);
      let payload = {};
      let { data } = await getAllTransactionList(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setRevenueData(data.totalAmountsArray);
        setMonthName(data.monthNames);
        setTotalEarnings(data.total_volume);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  useEffect(() => {
    fetchAllTransactions();
  }, []);

  useEffect(() => {
    setInvoice();
  }, [invoiceTable]);

  // Delete Data
  const onClickDelete = (invoiceTable) => {
    setInvoice(invoiceTable);
    setDeleteModal(true);
  };
  const handleDeleteInvoice = () => {
    if (invoiceTable) {
      setDeleteModal(false);
    }
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  };

  const handleValidTime = (time) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = '';
    if (getHour >= 12) {
      meridiem = 'PM';
    } else {
      meridiem = 'AM';
    }
    const updateTime =
      moment(getTime, 'hh:mm').format('hh:mm') + ' ' + meridiem;
    return updateTime;
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById('checkBoxAll');
    const ele = document.querySelectorAll('.invoiceCheckBox');

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById('checkBoxAll');
    selectedCheckBoxDelete.forEach((element) => {
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll('.invoiceCheckBox:checked');
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xxl={3} lg={6}>
              <Widgets
                label={'TOTAL EARNINGS'}
                prefix={nairaSymbol}
                suffix={''}
                separator={''}
                end={totalEarnings}
                decimals={''}
                bgcolor={'success'}
                icon={'bx bxs-wallet'}
                role={''}
                tab={''}
              />
            </Col>
            <Col xxl={3} lg={6}>
              <Widgets
                label={'Available Balance'}
                prefix={nairaSymbol}
                suffix={''}
                separator={''}
                end={totalEarnings}
                decimals={''}
                bgcolor={'warning'}
                icon={'bx bxs-wallet-alt'}
                role={''}
                tab={''}
              />
            </Col>
            <Col xxl={3} lg={6}>
              <Widgets
                label={'TOTAL PayOut'}
                prefix={nairaSymbol}
                suffix={''}
                separator={''}
                end={0}
                decimals={''}
                bgcolor={'danger'}
                icon={'bx bx-wallet-alt'}
                role={''}
                tab={''}
              />
            </Col>
          </Row>
          <Row>
            {invoiceTable.length ? (
              <PayRequest data={invoiceTable} />
            ) : (
              <Loader error="{error}" />
            )}
          </Row>
          <Row>
            {invoiceTable.length ? (
              <TransactionList data={[]} />
            ) : (
              <Loader error="{error}" />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PaymentPage;
