import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  CircularProgress,
  Backdrop,
  Pagination,
  styled,
  Box,
  Typography,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { UnitCard } from '../../../components/unit-card';
import { favoriteList } from '../../../services/apiServices';
import { DataNotAvailable } from '../../../components/data-not-found';
import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';
import { HeaderBreadcrumbs } from '../../../components/breadcrumbs';

export const FavouritePlacePage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [spinFlag, setSpinFlag] = useState();
  const [message, setMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const [favList, setFavList] = useState([]);
  const [renderFlag, setRenderFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState();
  const [statusCode, setStatusCode] = useState();
  useEffect(() => {
    getFavList();
  }, [renderFlag, currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const getFavList = async () => {
    try {
      const payload = {
        currentPage: currentPage,
      };
      setSpinFlag(true);
      let { data } = await favoriteList(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setFavList(data.favList);
        setNumberOfPages(data.noOfPages);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };
  return (
    <>
      <HeaderBreadcrumbs
        heading="Favourite place"
        links={[
          { name: 'Dashboard', href: '/dashboard' },
          { name: 'Favourite place' },
        ]}
      />
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogPopup
        open={open}
        setOpen={setOpen}
        message={message}
        size="xs"
        statusCode={statusCode}
      />
      <Card>
        <CardHeader
          title="Favourite Place"
          sx={{
            pb: { md: 2, xs: 3 },
            flexWrap: 'wrap',
            borderBottom: { md: 'none', xs: '1px solid #F1F1F1' },
            '& .MuiCardHeader-content': {
              width: { md: '35%', xs: '100%' },
              paddingBottom: { md: 0, xs: 1 },
            },
            '& .MuiCardHeader-action': {
              m: 0,
              width: { md: '65%', xs: '100%' },
              paddingTop: { md: 0, xs: 0 },
            },
          }}
        />
        <CardContent>
          <Grid container spacing={2}>
            {favList.length !== 0 ? (
              favList.map((item, index) => {
                return (
                  <>
                    <Grid item md={6} xs={12}>
                      <UnitCard
                        key={index}
                        favHistoryData={item}
                        setRenderFlag={setRenderFlag}
                        renderFlag={renderFlag}
                      />
                    </Grid>
                  </>
                );
              })
            ) : (
              <Grid item md={12} xs={12}>
                <DataNotAvailable title={"You don't have favorite property"} />
              </Grid>
            )}
          </Grid>
          {favList.length !== 0 && (
            <Pagination
              shape="rounded"
              count={numberOfPages}
              siblingCount={0}
              boundaryCount={2}
              onChange={handlePageChange}
              sx={{
                py: 2,
                '& .MuiPagination-ul': { justifyContent: 'center' },
              }}
            />
          )}
        </CardContent>
      </Card>
    </>
  );
};
