import React, { useState } from 'react';
import {
  TextField,
  IconButton,
  InputAdornment,
  Button,
  Stack,
  Grid,
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  FormHelperText,
  FormControl,
} from '@mui/material';
import { useFormik, Form, FormikProvider, Field } from 'formik';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Icon } from '@iconify/react';
import BackArrow from '../../../assets/icons/back-arrow.svg';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { REGEX } from '../../../utils/regex';
import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';
import { RegisterSchemaForRenter } from '../../../utils/validation/Auth';
import { userSignup } from '../../../services/apiServices';
import { onlyCountries } from '../../../utils/constant';

export const RegisterRenter = (props) => {
  let { role, signUpRoles, setSpinFlag } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordco, setShowPasswordco] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [statusCode, setStatusCode] = useState();
  const defaultCountry = 'us';

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      dob: null,
      email: '',
      password: '',
      confirmpassword: '',
      mobile: '',
      countryCode: '+1',
      role: role,
    },
    validationSchema: RegisterSchemaForRenter,
    onSubmit: async (values) => {
      let payload = {
        firstname: values.firstname,
        lastname: values.lastname,
        dob: values.dob,
        email: values.email,
        password: values.password,
        mobile: values.mobile,
        countryCode: values.countryCode,
        role: values.role,
      };

      try {
        const { data } = await userSignup(payload);
        if (data.statusCode == 200) {
          setSpinFlag(false);
          setOpen(true);
          setMessage(data.message);
          setStatusCode(data.statusCode);
          resetForm();
        } else {
          setSpinFlag(false);
          setOpen(true);
          setStatusCode(data.statusCode);
          setMessage(data.message);
        }
      } catch (err) {
        navigate(`/error?msg=${err}&&path=${pathname}`);
      }
    },
  });

  const {
    errors,
    touched,
    handleBlur,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setFieldError,
    resetForm,
    values,
  } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleShowPasswordCo = () => {
    setShowPasswordco((show) => !show);
  };

  const handleDobInput = (e) => {
    if (e) {
      // Check if the date is 'Invalid Date'
      let validDate = checkValidDate(e.$d);
      if (!validDate) {
        return setFieldValue('dob', null);
      }
      const selectedDate = dayjs(e.$d);
      const formattedDate = selectedDate.format('YYYY-MM-DD');
      setFieldValue('dob', formattedDate);
    } else {
      setFieldValue('dob', null);
    }
  };

  function checkValidDate(dateString) {
    const date = new Date(dateString);
    // Check if the date is 'Invalid Date'
    if (isNaN(date.getTime())) {
      // If it's 'Invalid Date', return a default Date or null
      return null; // or return new Date(); for a default date
    }
    // If the date is valid, return it
    return date;
  }

  const handleCountryCodeInput = (e) => {
    setFieldValue('countryCode', e);
  };
  return (
    <>
      <DialogPopup
        open={open}
        setOpen={setOpen}
        message={message}
        size="xs"
        statusCode={statusCode}
      />
      <Box sx={{ mb: 6, position: 'relative' }}>
        <Button
          onClick={signUpRoles}
          sx={{
            position: 'absolute',
            left: '0',
            top: '-12px',
            background: '#F2F2F2',
            borderRadius: '6px',
            width: '44px',
            height: '44px',
            minWidth: 'auto',
          }}
        >
          <img src={BackArrow} alt="back" />
        </Button>
        <Typography
          variant="subtitle1"
          align="center"
          sx={{
            color: 'secondary.main',
            textTransform: 'uppercase',
            letterSpacing: { sm: '0.1em' },
          }}
        >
          Sign up as Renter
        </Typography>
      </Box>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  type="Text"
                  label="First name"
                  {...getFieldProps('firstname')}
                  // error={Boolean(touched.firstname && errors.firstname)}
                  // helperText={touched.firstname && errors.firstname}
                />
                {touched.firstname && errors.firstname && (
                  <FormHelperText error>{errors.firstname}</FormHelperText>
                )}
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  type="Text"
                  label="Last name"
                  {...getFieldProps('lastname')}
                  // error={Boolean(touched.lastname && errors.lastname)}
                  // helperText={touched.lastname && errors.lastname}
                />
                {touched.lastname && errors.lastname && (
                  <FormHelperText error>{errors.lastname}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer sx={{ p: 0 }} components={['DatePicker']}>
                    <FormControl onBlur={handleBlur} fullWidth>
                      <DatePicker
                        name="dob"
                        label="Enter Your Birthdate"
                        format="YYYY-MM-DD"
                        // defaultValue={dayjs(new Date())}
                        value={values.dob ? dayjs(values.dob) : null}
                        // {...getFieldProps('dob')}
                        onChange={handleDobInput}
                        onBlur={handleBlur}
                        disableFuture={true}

                        // renderInput={(params) => <input {...params} />}
                        // error={Boolean(touched.dob && errors.dob)}
                        // helperText={touched.dob && errors.dob}
                      />
                      {touched.dob && errors.dob && (
                        <FormHelperText error>{errors.dob}</FormHelperText>
                      )}
                    </FormControl>
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="email"
                  label="Email"
                  {...getFieldProps('email')}
                  // error={Boolean(touched.email && errors.email)}
                  // helperText={touched.email && errors.email}
                />
                {touched.email && errors.email && (
                  <FormHelperText error>{errors.email}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  {...getFieldProps('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Icon
                            icon={
                              showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  // error={Boolean(touched.password && errors.password)}
                  // helperText={touched.password && errors.password}
                />
                {touched.password && errors.password && (
                  <FormHelperText error>{errors.password}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type={showPasswordco ? 'text' : 'password'}
                  label="Confirm password"
                  {...getFieldProps('confirmpassword')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPasswordCo} edge="end">
                          <Icon
                            icon={
                              showPasswordco
                                ? 'eva:eye-fill'
                                : 'eva:eye-off-fill'
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  // error={Boolean(
                  //   touched.confirmpassword && errors.confirmpassword
                  // )}
                  // helperText={touched.confirmpassword && errors.confirmpassword}
                />
                {touched.confirmpassword && errors.confirmpassword && (
                  <FormHelperText error>
                    {errors.confirmpassword}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex' }}>
                  <MuiPhoneNumber
                    value={values.countryCode}
                    defaultCountry={defaultCountry}
                    onlyCountries={onlyCountries}
                    name="countryCode"
                    label=" "
                    onChange={handleCountryCodeInput}
                    variant="filled"
                    countryCodeEditable="false"
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      width: '200px',
                      '& .MuiInputBase-root': {
                        borderRadius: '14px 0 0 14px',
                        borderRight: 'none',
                        padding: '16.5px 12px',
                        '& .MuiInputAdornment-root': { margin: '0 !important' },
                        '& .MuiInputBase-input': { padding: '0 0 0 5px' },
                        '& .MuiMenu-paper': {
                          maxHeight: '500px',
                        },
                      },
                    }}
                    // error={Boolean(touched.countryCode && errors.countryCode)}
                    // helperText={touched.countryCode && errors.countryCode}
                  />
                  <TextField
                    fullWidth
                    type="tel"
                    label="Enter your phone number"
                    sx={{
                      '& .MuiInputBase-root': {
                        borderRadius: '0 14px 14px 0',
                      },
                    }}
                    {...getFieldProps('mobile')}
                    // error={Boolean(touched.mobile && errors.mobile)}
                    // helperText={touched.mobile && errors.mobile}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(REGEX.RESTRICT_CHAR, '')
                        .slice(0, 15);
                    }}
                  />
                </Box>
                {touched.mobile && errors.mobile && (
                  <FormHelperText error>{errors.mobile}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                >
                  Create an account
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Form>
      </FormikProvider>
      <Box>
        <Typography
          component="p"
          align="center"
          sx={{
            mt: 2,
            fontSize: '16px',
            color: '#6B7280',
            fontWeight: '500',
          }}
        >
          Have an account,{' '}
          <Link
            to="/login"
            style={{ color: '#FEAE01', textDecoration: 'none' }}
          >
            Login
          </Link>
        </Typography>
      </Box>
    </>
  );
};
