import React from 'react';
import { List, ListItem } from '@mui/material';
import Brand01 from '../../assets/brandlist/brand01.svg';
import Brand02 from '../../assets/brandlist/brand02.svg';
import Brand03 from '../../assets/brandlist/brand03.svg';
import Brand04 from '../../assets/brandlist/brand04.svg';

const icon = (name) => <img src={`${name}`} alt={`${name}`} />;

const brandList = [
  {
    imgPath: icon(Brand01),
  },
  {
    imgPath: icon(Brand02),
  },
  {
    imgPath: icon(Brand03),
  },
  {
    imgPath: icon(Brand04),
  },
];

export const BrandList = () => {
  return (
    <>
      <List
        sx={{
          px: 0,
          gap: 3,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {brandList.map((item, index) => (
          <ListItem key={index} disablePadding>
            {item.imgPath}
          </ListItem>
        ))}
      </List>
    </>
  );
};
