import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { VectorMap } from 'react-jvectormap';
import './jquery-jvectormap.scss';
// import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
// import { GOOGLE_MAP_KEY } from '../../../utils/constant';
import { getAllCities } from '../../../services/apiServices';
import ResponseModal from '../../Components/Common/SuccessModal';
import ShowSpinner from '../../Components/Common/spinner';

const map = React.createRef(null);
const Vectormap = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [allCities, setAllCities] = useState([]);
  const [spinFlag, setSpinFlag] = useState(false);
  const [response, setResponse] = useState({
    message: '',
    open: false,
    statusCode: null,
  });

  const optionsForMarkers = allCities.map((item) => ({
    latLng: item.latLng,
    name: item.city,
  }));

  const fetchAllcities = async () => {
    try {
      setSpinFlag(true);
      let { data } = await getAllCities();
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setAllCities(data.topRatedCities);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  useEffect((item) => {
    fetchAllcities();
  }, []);

  return (
    <>
      <ResponseModal response={response} setResponse={setResponse} />
      <div style={{ width: props.width, height: 432 }}>
        <VectorMap
          map={props.value}
          zoomOnScroll={false}
          zoomButtons={false}
          markersSelectable={true}
          markerStyle={{
            scaleColors: ['#C8EEFF', '#0071A4'],
            initial: {
              fill: '#0ab39c',
            },
            selected: {
              fill: '#0ab39c',
            },
          }}
          markers={
            optionsForMarkers
            //   [
            //   {
            //     latLng: [1.3521, 103.8198],
            //     name: 'Singapore',
            //   },
            // ]
          }
          labels={{
            markers: {
              render: function (marker) {
                return marker.name;
              },
            },
          }}
          backgroundColor="transparent"
          ref={map}
          containerStyle={{
            width: '100%',
            height: '100%',
          }}
          regionStyle={{
            initial: {
              stroke: '#9599ad',
              'stroke-width': 0.25,
              fill: '#f3f3f9',
              fillOpacity: 1,
            },
            hover: {
              'fill-opacity': 0.8,
              fill: '',
              stroke: '#2b2b2b',
            },
            selected: {
              fill: '#FFFB00',
            },
          }}
        />
      </div>
      <ShowSpinner spinFlag={spinFlag} />
    </>
  );
};

export default Vectormap;
// export default GoogleApiWrapper({
//   apiKey: GOOGLE_MAP_KEY,
// })(Vectormap);
