import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import DatatablesCom from '../../Components/Tables/DataTables/DatatablesCom';

import DeleteModal from '../../Components/Common/DeleteModal';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Components/Common/Loader';
import { getAllWithdrawRequest } from '../../../services/apiServices';
import { useAuthStore } from '../../../redux/features/authSlice';
import { setToken } from '../../../utils/axiosClient';
import { IMG_URL, nairaSymbol } from '../../../utils/constant';
import userNoImage from '../../assets/images/users/user-dummy-img.jpg';

const customStyles = {
  rows: {
    style: {
      // minHeight: '72px',
    },
  },
  headCells: {
    style: {
      padding: '8px',
      background: '#f3f6f9',
      fontWeight: 'bold',
      color: '#878a99',
    },
  },
  cells: {
    style: {
      padding: '8px',
    },
  },
};

const PayRequest = (props) => {
  let { data } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState('1');
  const [customer, setCustomer] = useState([]);
  const [WithdrawRequest, setWithdrawRequest] = useState([]);

  // Delete customer
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  // Delete Data
  const handleDeleteCustomer = () => {
    if (customer) {
      setDeleteModal(false);
    }
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById('checkBoxAll');
    const ele = document.querySelectorAll('.customerCheckBox');

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
  const deleteMultiple = () => {
    const checkall = document.getElementById('checkBoxAll');
    selectedCheckBoxDelete.forEach((element) => {
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll('.customerCheckBox:checked');
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const columns = [
    {
      name: (
        <input
          type="checkbox"
          id="checkBoxAll"
          className="form-check-input"
          onClick={() => checkedAll()}
        />
      ),
      width: '50px',
      cell: (cellProps) => (
        <input
          type="checkbox"
          className="customerCheckBox form-check-input"
          onChange={() => deleteCheckbox()}
        />
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Customer</span>,
      sortable: true,
      cell: (row) => {
        return (
          <div className="d-flex align-items-center">
            {row?.userData[0].profile ? (
              <img
                src={`${IMG_URL}${row.userData[0].profile}`}
                alt=""
                className="avatar-xs rounded-circle me-2"
              />
            ) : (
              <img
                className="avatar-xs rounded-circle me-2"
                src={userNoImage}
                alt="Header Avatar"
              />
            )}
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      sortable: true,
      selector: (row) => row.userData[0].email,
    },
    // {
    //   name: <span className="font-weight-bold fs-13">Payment Status</span>,
    //   sortable: true,
    //   selector: (row) => row.status,
    // },
    {
      name: <span className="font-weight-bold fs-13">Contact</span>,
      sortable: true,
      selector: (row) => row.userData[0].mobile,
    },
    {
      name: <span className="font-weight-bold fs-13">Date</span>,
      sortable: true,
      selector: (row) => row.createdAt.slice(0, 10),
    },
    {
      name: <span className="font-weight-bold fs-13">Amount</span>,
      selector: (row) => {
        return (
          <>
            {nairaSymbol}
            {row.amount}
          </>
        );
      },
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Payment status</span>,
      sortable: true,
      cell: (row) => {
        return (
          <button type="button" className="btn btn-sm btn-soft-secondary">
            Pay Now
          </button>
        );
      },
    },
  ];

  useEffect(() => {
    fetchWithDrawRequestList();
  }, []);

  const fetchWithDrawRequestList = async () => {
    try {
      const payload = {};
      const { data } = await getAllWithdrawRequest(payload);

      if (data.statusCode == 200) {
        setWithdrawRequest(data.withdrawRequestList);
      } else {
      }
    } catch (error) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCustomer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Col lg={12}>
        <Card id="transactionList">
          <CardHeader className="border-0">
            <Row>
              <div className="col-sm">
                <div>
                  <h5 className="card-title mb-0">Withdraw request</h5>
                </div>
              </div>
              <div className="col-sm-auto"></div>
            </Row>
          </CardHeader>
          <CardBody className="p-0">
            <div>
              {data && data.length ? (
                <DatatablesCom
                  flag={1}
                  columns={columns}
                  data={WithdrawRequest}
                  customStyles={customStyles}
                />
              ) : (
                <Loader error="{error}" />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default PayRequest;
