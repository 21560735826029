import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import error500 from '../../assets/images/error500.png';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
// ----------------------------------------------------------------------

const ErrorAdmin = () => {
  let query = useQuery();
  return (
    <>
      <React.Fragment>
        <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
          <div className="auth-page-content overflow-hidden p-0">
            <Container fluid={true}>
              <Row className="justify-content-center">
                <Col xl={4} className="text-center">
                  <div className="error-500 position-relative">
                    <img
                      src={error500}
                      alt=""
                      className="img-fluid error-500-img error-img"
                    />
                    <h1 className="title text-muted">500</h1>
                  </div>
                  <div>
                    <h4>Something went wrong</h4>
                    <p className="text-muted w-75 mx-auto">
                      {query.get('msg')}
                    </p>
                    <Link to={query.get('path')} className="btn btn-success">
                      <i className="mdi mdi-home me-1"></i>Retry
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default ErrorAdmin;
