import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Card,
  Typography,
  Button,
  CardContent,
  Divider,
  Box,
  Stack,
  Grid,
  Avatar,
  CardHeader,
  Rating,
  Radio,
} from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import SvgColor from '../../../components/svg-color';
import AddPayment from '../../../assets/icons/AddPayment.svg';
import Creditcardmockup from '../../../assets/images/Creditcardmockup.png';
import Creditcardmockup1 from '../../../assets/images/Creditcardmockup-1.png';
import Creditcardmockup2 from '../../../assets/images/Creditcardmockup-2.png';
import ArrowIconLeft from '../../../assets/icons/ArrowIconLeft.svg';
import ArrowIconRight from '../../../assets/icons/ArrowIconRight.svg';
import { CardList } from '../payment/CardList';
import Slider from 'react-slick';

const CreditcardLists = [
  {
    cardName: 'OLIVIA RHYE',
    cardPath: Creditcardmockup,
  },
  {
    cardName: 'OLIVIA RHYE 2',
    cardPath: Creditcardmockup1,
  },
  {
    cardName: 'OLIVIA RHYE 3',
    cardPath: Creditcardmockup2,
  },
];
const PriceTable = styled(Box)(({ theme }) => ({
  '& .MuiStack-root': {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 0 12px',
    '&:last-child': {
      padding: '10px 0 0',
    },
  },
}));
const StyledRating = styled(Rating)({
  marginTop: 'auto',
  fontSize: '15px !important',
  '& .MuiRating-iconEmpty': {
    color: '#D3D3DC',
  },
  '& .MuiRating-iconFilled': {
    color: '#FFBD3E',
  },
  '& .MuiRating-iconHover': {
    color: '#FFBD3E',
  },
});
const SelectRadio = styled(Radio)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: '14px',
  '&>span': { display: 'none' },
  '&.Mui-checked': {
    border: '5px solid #FF6645',
  },
}));
const CarouselDiv = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  '& .slick-slider': {
    height: '100%',
    width: '100%',
    '& .slick-list': {
      height: '100%',
      width: '100%',
      '& .slick-track': {
        height: '100%',
        '& .slick-slide': {
          '&>div': {
            height: '100%',
            width: '100%',
          },
        },
      },
    },
    '& .slick-arrow': {
      fontSize: '0',
      width: '20px',
      height: '20px',
      zIndex: '1',
      transition: '0.45s ease-in-out',
      borderRadius: '50%',
      opacity: '1',
      '&:before': {
        fontSize: '0',
      },
      '&.slick-prev': {
        background: `rgba(255,255,255,0.8) url(${ArrowIconLeft}) no-repeat center center / cover`,
        left: '5px',
      },
      '&.slick-next': {
        background: `rgba(255,255,255,0.8) url(${ArrowIconRight}) no-repeat center center / cover`,
        right: '5px',
      },
      '&.slick-disabled': {
        display: 'none !important',
      },
    },
    '&:hover': {
      '& .slick-arrow': {
        opacity: '1',
      },
    },
    '& .slick-dots': {
      bottom: '-20px',
      '& li': {
        margin: '0',
        '& button:before': {
          fontSize: '22px',
          color: '#fff',
        },
      },
    },
  },
}));
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};
export const PaymentMmethod = (props) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [selectedValue, setSelectedValue] = useState();
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <>
      <Card>
        <CardHeader title="Choose Payment Methods" sx={{ pb: 2 }} />
        <Divider />
        <CardContent>
          <Grid container>
            <Grid item md={7} xs={12}>
              <Box>
                <Typography variant="h6" sx={{ p: 3, pb: 0 }}>
                  Choose Payment Methods
                </Typography>
                <Grid container>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
                <Box
                  sx={{
                    '& .owl-dots .owl-dot': {
                      '& span': {
                        background: '#EFEFEF !important',
                      },
                      '&.active': {
                        '& span': {
                          background: '#FEAE01 !important',
                        },
                      },
                    },
                  }}
                >
                  <CarouselDiv>
                    <Slider {...settings}>
                      {CreditcardLists.map((val, index) => (
                        <Box key={index} sx={{ p: 1 }}>
                          <Box
                            sx={{
                              position: 'relative',
                              borderRadius: '14px',
                              overflow: 'hidden',
                            }}
                          >
                            <img
                              style={{ width: '100%' }}
                              src={val.cardPath}
                              alt={val.cardPath}
                            />
                            <SelectRadio
                              disableRipple
                              checked={selectedValue === val.cardName}
                              onChange={handleChange}
                              value={val.cardName}
                              name="radio-buttons"
                            />
                          </Box>
                        </Box>
                      ))}
                    </Slider>
                  </CarouselDiv>
                </Box>
              </Box>
            </Grid>
            <Grid item md={5} xs={12}>
              <Box sx={{ p: 3 }}>
                <Stack flexDirection="row" flexWrap="wrap" sx={{ pb: 3 }}>
                  <Avatar
                    sx={{
                      borderRadius: '14px',
                      width: '100px',
                      height: '100px',
                      aspectRatio: '1',
                    }}
                    src="https://d18fng178ow3z3.cloudfront.net/flatts/1689937067474-residence-9.webp"
                  />
                  <Stack
                    sx={{ pl: 2, width: 'calc(100% - 100px)', pb: '10px' }}
                  >
                    <Typography variant="h6" noWrap>
                      Cliff-top Cottage on Coast Path Panoramic Views
                    </Typography>
                    <Typography variant="body2" noWrap>
                      Los Angeles International Airport (LAX), World Way, Los
                      Angeles, CA, USA
                    </Typography>
                    <StyledRating
                      defaultValue={0}
                      value={0}
                      readOnly
                      icon={<Icon icon="ph:star-fill" />}
                      emptyIcon={<Icon icon="ph:star-fill" />}
                      sx={{
                        fontSize: {
                          xs: '1rem',
                          md: '1rem',
                          lg: '1.125rem',
                          xl: '1.25rem',
                        },
                      }}
                    />
                  </Stack>
                </Stack>
                <Divider />
                <Box>
                  <Typography variant="h6" sx={{ pt: 3 }}>
                    Price details
                  </Typography>
                  <PriceTable sx={{ py: 3 }}>
                    <Stack>
                      <Typography variant="subtitle1">Subtotal</Typography>
                      <Typography variant="body1" align="right">
                        $832
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography variant="subtitle1">
                        Flattts service fee
                      </Typography>
                      <Typography variant="body1" align="right">
                        $4.5
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography variant="subtitle1">Taxes</Typography>
                      <Typography variant="body1" align="right">
                        $4.5
                      </Typography>
                    </Stack>
                    <Divider />
                    <Stack>
                      <Typography variant="h6">Total</Typography>
                      <Typography variant="h6" align="right">
                        $841
                      </Typography>
                    </Stack>
                  </PriceTable>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
