import React, { useState, useEffect } from 'react';
import 'react-nice-dates/build/style.css';
import {
  Grid,
  Typography,
  Stack,
  Divider,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  Box,
  ButtonGroup,
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
} from '@mui/material';
import { enGB } from 'date-fns/locale';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { useResponsive } from '../../hooks/useResponsive';
import { currencySeprator } from '../../utils/common-function';
// import { DateRangePicker, START_DATE, END_DATE } from 'react-nice-dates';
import {
  getMonth,
  getDate,
  isEqual,
  getYear,
  differenceInDays,
  differenceInMonths,
} from 'date-fns';
import { REGEX } from '../../utils/regex';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import SvgColor from '../../components/svg-color';
import ApplyListing from '../../pages/dashboard-pages/apply-listing';
import RequestTour from '../../pages/dashboard-pages/request-tour';
import profile from '../../assets/icons/profile.svg';
import { reserveSchema } from '../../utils/validation/Property';
import { useAuthStore } from '../../redux/features/authSlice';
import { DialogPopup } from '../../components/dialog-popup/DialogPopup';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import { Socket, io } from 'socket.io-client';
import { BASEURL, nairaSymbol } from '../../utils/constant';
import { createRoom } from '../../services/apiServices';
// import { socket } from '../../App';

const DateRangePickerDiv = styled(Box)(({ theme }) => ({
  '& .nice-dates-popover': {
    width: '80%',
    // maxWidth: '320px',
    borderRadius: '0',
    '& .nice-dates-day': {
      fontSize: '0.75rem',
    },
    '& .nice-dates-grid_container': {},
  },
}));
const DateRangePickerBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 3,
  '& .DateRangePickerInput': {
    height: '53px',
    borderRadius: '14px',
    borderColor: '#D1D5DB',
    fontWeight: '400',
    fontSize: '14px',
    color: '#323232',
    display: 'flex',
    // flexWrap: 'wrap',
    alignItems: 'center',
    padding: '0',
    fontFamily: 'Inter,sans-serif',
    '& ::-webkit-input-placeholder': {
      fontWeight: '600',
      fontSize: '14px',
      color: '#9CA3AF',
      fontFamily: 'Inter,sans-serif',
    },
    '& :-ms-input-placeholder': {
      fontWeight: '600',
      fontSize: '14px',
      color: '#9CA3AF',
      fontFamily: 'Inter,sans-serif',
    },
    '& ::placeholder': {
      fontWeight: '600',
      fontSize: '14px',
      color: '#9CA3AF',
      fontFamily: 'Inter,sans-serif',
    },
    '& .DateRangePickerInput_calendarIcon': {
      width: '35px',
      position: 'static',
      transform: 'inherit',
      background: 'none',
      borderRadius: '0',
    },
    '& .DateRangePickerInput_clearDates': {
      width: '35px',
      position: 'static',
      transform: 'inherit',
      background: 'none',
      borderRadius: '0',
    },
    '& .DateInput': {
      width: 'calc(50% - 51px)',
      height: '100%',
      '& .DateInput_input': {
        height: '100%',
        fontWeight: '400',
        fontSize: '14px',
        color: '#323232',
        fontFamily: 'Inter,sans-serif',
      },
      '& .DateInput_input__focused': {
        borderColor: '#323232',
      },
    },
    '& .DayPicker': {
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow:
        '0px 0px 2px 0px rgba(145, 158, 171, 0.24), 0px 20px 40px -4px rgba(145, 158, 171, 0.24)',
    },
    '& .CalendarMonth_caption strong': {
      fontWeight: '600',
      fontSize: '16px',
      color: '#323232',
    },
    '& .DayPickerNavigation_button': {
      border: 'none',
    },
    '& .CalendarDay__default': {
      border: 'none !important',
      '&.CalendarDay__selected_start': {
        borderRadius: '20px 0 0 20px',
      },
      '&.CalendarDay__selected_end': {
        borderRadius: '0 20px 20px 0',
      },
      '&.CalendarDay__selected_span': {
        background: 'rgb(247, 247, 247)',
        color: '#323232',
      },
      '&.CalendarDay__selected': {
        background: '#323232',
        color: '#fff',
        '&:active': {
          background: '#323232',
        },
        '&:hover': {
          background: '#323232',
        },
      },
      '&.CalendarDay__hovered_span': {
        background: 'rgb(247, 247, 247)',
        color: '#484848',
        '&:hover': {
          background: 'rgb(247, 247, 247)',
          color: '#484848',
        },
      },
      '&.CalendarDay__blocked_out_of_range': {
        textDecoration: 'line-through',
      },
    },
  },
}));

const ReserveForm = (props) => {
  let {
    propertyUnits,
    propertyOwner,
    propertyData,
    setRenderFlag,
    renderFlag,
    requestData,
    setSpinFlag,
  } = props;

  let { bookingDetails, bookingDatesForEachUnits } = propertyData;

  const navigate = useNavigate();
  let { isLoggedin } = useAuthStore();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [monthPrice, setMonthPrice] = useState(null);
  const [dayPrice, setDayPrice] = useState(null);
  const [yearPrice, setYearPrice] = useState(null);
  const [sixMonthPrice, setSixMonthPrice] = useState(null);
  const [threeMonthPrice, setThreeMonthPrice] = useState(null);
  const [applyValues, setApplyValues] = useState({});
  const [duration, setDuration] = useState('');
  const [rentCalculation, setRentCalculation] = useState(null);
  const [difference, setDifference] = useState(1);
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupTour, setPopupTour] = useState(false);
  const [openMsg, setOpenMsg] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [highlightedDates, setHighlightedDates] = useState([]);
  const [focusedInput, setFocusedInput] = useState(null);
  const isMobileMode = useResponsive('down', 'sm');
  const [bookingDates, setBookingDates] = useState([]);
  const [availableFrom, setAvailableFrom] = useState('');
  const [statusCode, setStatusCode] = useState();

  const handleClickOpen = () => {
    if (isLoggedin) {
      setPopupTour(true);
    } else {
      navigate('/login');
    }
  };

  const handlePopupClose = (flag) => {
    setPopupOpen(false);
    setRentCalculation(null);
    resetForm();
    setStartDate(null);
    setEndDate(null);
    setTimeout(() => {
      setRenderFlag(!renderFlag);
    }, 1000);
  };

  const initialValues = {
    fromDate: '',
    toDate: '',
    guest: '',
    unit: propertyUnits.length === 1 ? propertyUnits[0]._id : '',
  };

  const notificationSocket = io(`${BASEURL}`);
  const onDataSubmit = async (values) => {
    // Socket.io event: connect
    notificationSocket.on('connect', () => {
      // Join the landloard's room based on their unique landloard ID
      notificationSocket.emit('join', propertyOwner._id);
    });
    if (isLoggedin) {
      let flag;

      if (duration === 'year') {
        flag = isOneYearSelected();
      }
      if (duration === 'sixMonth') {
        flag = isSixMonthSelected();
      }
      if (duration === 'threeMonth') {
        flag = isThreeMonthSelected();
      }
      if (duration === 'month') {
        flag = isOneMonthSelected();
      }
      // if (duration === 'day') {
      //   flag = true;
      // }

      values = {
        ...values,
        landlordId: propertyOwner._id,
        landlordEmail: propertyOwner.email,
        propertyId: propertyData._id,
        duration: duration,
      };
      if (flag) {
        setApplyValues(values);
        setPopupOpen(true);
      }
    } else {
      navigate('/login');
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: reserveSchema,
    onSubmit: async (values) => {
      onDataSubmit(values);
    },
  });
  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    resetForm,
    handleChange,
    values,
    setFieldError,
  } = formik;

  useEffect(() => {
    if (propertyUnits && bookingDatesForEachUnits) {
      if (propertyUnits.length === 1) {
        bookingDatesForEachUnits.map((unitItem, index) => {
          if (unitItem._id === propertyUnits[0]._id) {
            setBookingDates(unitItem.data);
          }
        });

        setDuration(propertyUnits[0].durationName);
        setAvailableFrom(propertyUnits[0].availableFrom);
        if (propertyUnits[0].durationName === 'year')
          setYearPrice(propertyUnits[0].price);
        else if (propertyUnits[0].durationName === 'month')
          setMonthPrice(propertyUnits[0].price);
        else if (propertyUnits[0].durationName === 'sixMonth')
          setSixMonthPrice(propertyUnits[0].price);
        else setThreeMonthPrice(propertyUnits[0].price);
      }
    }
    setHighlightedDates(getHighlightedDates());
  }, [bookingDates, bookingDatesForEachUnits]);

  const handleDate = (e) => {
    if (e) {
      let date = new Date(e._d),
        mnth = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2);
      let nDate = [date.getFullYear(), mnth, day].join('-');
      return nDate;
    }
  };

  const handleDateChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    let sDate = handleDate(startDate);
    let eDate = handleDate(endDate);
    setFieldValue('fromDate', sDate);
    setFieldValue('toDate', eDate);

    if (startDate && endDate && duration === 'month') {
      const start = moment(startDate);
      const end = moment(endDate);
      const diffMonths = end.diff(start, 'months', true);
      if (
        diffMonths === 1
        // ||
        // diffMonths === 2 ||
        // diffMonths === 3 ||
        // diffMonths === 4 ||
        // diffMonths === 5 ||
        // diffMonths === 6
      ) {
        setRentCalculation(monthPrice * diffMonths);
        setDifference(diffMonths);
      } else {
        setRentCalculation(null);
      }
    } else if (startDate && endDate && duration === 'year') {
      const start = moment(startDate);
      const end = moment(endDate);
      const diffYears = end.diff(start, 'years', true);
      if (diffYears === 1) {
        setRentCalculation(yearPrice * diffYears);
        setDifference(diffYears);
      } else {
        setRentCalculation(null);
      }
    } else if (startDate && endDate && duration === 'sixMonth') {
      const start = moment(startDate);
      const end = moment(endDate);
      let diffMonths = end.diff(start, 'months', true);
      // Calculate the number of six-month intervals
      let sixMonthsInterval = Math.floor(diffMonths / 6);

      if (
        diffMonths === 6
        //  ||
        // diffMonths === 12 ||
        // diffMonths === 18 ||
        // diffMonths === 24
      ) {
        setRentCalculation(sixMonthPrice * sixMonthsInterval);
        setDifference(sixMonthsInterval);
      } else {
        setRentCalculation(null);
      }
    } else if (startDate && endDate && duration === 'threeMonth') {
      const start = moment(startDate);
      const end = moment(endDate);
      let diffMonths = end.diff(start, 'months', true);
      // Calculate the number of six-month intervals
      let threeMonthsInterval = Math.floor(diffMonths / 3);

      if (
        diffMonths === 3
        // ||
        // diffMonths === 12 ||
        // diffMonths === 15 ||
        // diffMonths === 6 ||
        // diffMonths === 9
      ) {
        setRentCalculation(threeMonthPrice * threeMonthsInterval);
        setDifference(threeMonthsInterval);
      } else {
        setRentCalculation(null);
      }
    } else {
      const start = moment(startDate);
      const end = moment(endDate);
      const diffInDays = end.diff(start, 'days');
      setRentCalculation(dayPrice * diffInDays);
      setDifference(diffInDays);
    }
  };

  // Define your logic to determine the highlighted dates
  const getHighlightedDates = () => {
    // Example: Highlight dates from the start date to the next 7 days
    const highlighted = [];
    if (bookingDates) {
      bookingDates?.map((item, index) => {
        let currentDate = moment(item.fromDate);
        let stopDate = moment(item.toDate);
        while (currentDate <= stopDate) {
          highlighted.push(moment(currentDate));
          currentDate = moment(currentDate).add(1, 'days');
        }
      });
    }
    return highlighted;
  };

  // Custom function to find the next disable date after the selected start date
  const getNextDisableDate = (start) => {
    const sortedDisableDates = highlightedDates
      .filter((date) => date.isAfter(start))
      .sort((a, b) => a.diff(b));

    return sortedDisableDates.length > 0 ? sortedDisableDates[0] : null;
  };

  // const getPreDisableDate = (end) => {
  //   const sortedDisableDates = highlightedDates
  //     .filter((date) => date.isBefore(end))
  //     .sort((a, b) => b.diff(a));
  //   return sortedDisableDates.length > 0 ? sortedDisableDates[0] : null;
  // };

  const isOutsideRange = (day) => {
    const highlighted = [];
    // const oneYearFromToday = moment().add(1, 'year');
    // date.isAfter(oneYearFromToday, 'day');
    let currentDate = new Date();
    let givenDate = availableFrom;

    if (currentDate > new Date(availableFrom)) {
      givenDate = currentDate;
    }

    if (startDate !== null && endDate === null) {
      // Check if the selected start date is after the disable date
      let nextDisableDate = getNextDisableDate(startDate); // Define your next disable date here
      let preDisableDate = moment(nextDisableDate).subtract(1, 'day').toDate();
      if (startDate.isSame(preDisableDate, 'day')) {
        setStartDate(null);
      }

      if (nextDisableDate) {
        duration !== 'day'
          ? handleDateChange(startDate, moment(preDisableDate))
          : null;

        return (
          day.isBefore(startDate, 'day') || day.isAfter(preDisableDate, 'day')
        );
      } else {
        if (duration === 'year') {
          let nextYearDate = startDate.clone().add(1, 'Year');
          handleDateChange(startDate, nextYearDate);
        }
        if (duration === 'sixMonth') {
          let nextMonthDate = startDate.clone().add(6, 'Month');
          handleDateChange(startDate, nextMonthDate);
        }
        if (duration === 'threeMonth') {
          let nextMonthDate = startDate.clone().add(3, 'Month');
          handleDateChange(startDate, nextMonthDate);
        }
        if (duration === 'month') {
          let nextMonthDate = startDate.clone().add(1, 'Month');
          handleDateChange(startDate, nextMonthDate);
        }
        // if (duration === 'day') {
        //   let nextThreeDaysDate = startDate.clone().add(1, 'days');
        //   // setEndDate(nextThreeDaysDate);
        //   // handleDateChange(startDate, nextThreeDaysDate);
        // }
        return day.isBefore(startDate, 'day');
      }
    } else if (
      endDate !== null &&
      startDate !== null &&
      focusedInput === 'startDate'
    ) {
      if (bookingDates) {
        bookingDates?.map((item) => {
          let currentDate = moment(item.fromDate);
          let stopDate = moment(item.toDate);
          while (currentDate <= stopDate) {
            highlighted.push(moment(currentDate));
            currentDate = moment(currentDate).add(1, 'days');
          }
        });

        return (
          day.isBefore(moment(givenDate), 'day') ||
          highlighted.some((disabledDate) => day.isSame(disabledDate, 'day'))
        );
      }
    } else if (
      endDate !== null &&
      startDate !== null &&
      focusedInput === 'endDate'
    ) {
      let nextDisableDate = getNextDisableDate(startDate); // Define your next disable date here

      if (endDate.isAfter(nextDisableDate)) {
        setEndDate(null);
      }
      nextDisableDate = moment(nextDisableDate).subtract(1, 'day').toDate();
      if (nextDisableDate) {
        return (
          day.isBefore(startDate, 'day') || day.isAfter(nextDisableDate, 'day')
        );
      }
    } else if (focusedInput === 'endDate' && startDate === null) {
      setFocusedInput('startDate');
      if (bookingDates) {
        bookingDates?.map((item, index) => {
          let currentDate = moment(item.fromDate);
          let stopDate = moment(item.toDate);
          while (currentDate <= stopDate) {
            highlighted.push(moment(currentDate));
            currentDate = moment(currentDate).add(1, 'days');
          }
        });
        return (
          day.isBefore(moment(givenDate), 'day') ||
          highlighted.some((disabledDate) => day.isSame(disabledDate, 'day'))
        );
      }
    } else {
      if (bookingDates) {
        bookingDates?.map((item, index) => {
          let currentDate = moment(item.fromDate);
          let stopDate = moment(item.toDate);
          while (currentDate <= stopDate) {
            highlighted.push(moment(currentDate));
            currentDate = moment(currentDate).add(1, 'days');
          }
        });

        return (
          day.isBefore(moment(givenDate), 'day') ||
          highlighted.some((disabledDate) => day.isSame(disabledDate, 'day'))
        );
      }
    }
    setHighlightedDates(highlighted);
  };

  // Custom function to check if selected dates span exactly one month
  const isOneMonthSelected = () => {
    if (startDate && endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      const diffMonths = end.diff(start, 'months', true);

      if (diffMonths === 1) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  // custom function to check if selected dates span exactly three month
  const isThreeMonthSelected = () => {
    if (startDate && endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      const diffMonths = end.diff(start, 'months', true);

      if (diffMonths === 3) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  // custom function to check if selected dates span exactly six month
  const isSixMonthSelected = () => {
    if (startDate && endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      const diffMonths = end.diff(start, 'months', true);

      if (diffMonths === 6) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  // Custom function to check if selected dates span exactly one year
  const isOneYearSelected = () => {
    if (startDate && endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      const diffYears = end.diff(start, 'years', true);

      if (diffYears === 1) {
        return true;
      }
    }
  };

  const handleUnitChange = (unit) => {
    setBookingDates([]);
    setDuration(unit.durationName);
    setRentCalculation(null);
    setStartDate(null);
    setEndDate(null);
    setFieldValue('fromDate', '');
    setFieldValue('toDate', '');
    setAvailableFrom(unit.availableFrom);
    if (unit.durationName === 'year') setYearPrice(unit.price);
    else if (unit.durationName === 'month') setMonthPrice(unit.price);
    else if (unit.durationName === 'sixMonth') setSixMonthPrice(unit.price);
    else setThreeMonthPrice(unit.price);
    bookingDatesForEachUnits.map((unitItem, index) => {
      if (unitItem._id === unit._id) {
        setBookingDates(unitItem.data);
      }
    });
  };

  const handleMessageOpen = async () => {
    if (isLoggedin) {
      const payload = {
        userId: propertyOwner._id,
      };
      const { data } = await createRoom(payload);
      // navigation
      if (data.id) {
        let messageData = {
          userId: propertyOwner._id,
          firstname: propertyOwner.firstname,
          lastname: propertyOwner.lastname,
          profile: propertyOwner.profile,
        };
        navigate(`/message?roomId=${data.id}`, { state: messageData });
      }
    } else {
      navigate('/login');
    }
  };

  return (
    <>
      <DialogPopup
        open={open}
        setOpen={setOpen}
        message={message}
        size="xs"
        statusCode={statusCode}
      />
      <Box
        sx={{
          padding: '10px 0',
          position: { md: 'sticky', xs: 'relative' },
          top: '0',
        }}
      >
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Card
              sx={{ overflow: 'inherit', maxWidth: '550px', margin: '0 auto' }}
            >
              <CardContent sx={{ p: { sm: 3, xs: 2 } }}>
                <Grid container spacing={2}>
                  {/* <Grid item xs={12}>
                  <Stack alignItems="start" sx={{}}>
                    <Box
                      sx={{
                        bgcolor: 'rgb(235, 235, 235)',
                        p: 0.5,
                        borderRadius: 10,
                        display: 'flex',
                        gap: 0.5,
                      }}
                    >
                      {propertyUnits?.map((item, index) => (
                        <Button
                          sx={{
                            bgcolor:
                              duration === item.durationName
                                ? '#323232'
                                : 'white',
                            color:
                              duration === item.durationName
                                ? 'white'
                                : '#323232',
                            borderRadius: 10,
                          }}
                          onClick={() => {
                            handleDuration(item.durationName);
                          }}
                          key={index}
                        >
                          {item.durationName}
                        </Button>
                      ))}
                    </Box>
                  </Stack>
                </Grid> */}
                  {propertyUnits && propertyUnits.length !== 1 ? (
                    <Grid item xs={12}>
                      {/* dropdown  */}
                      <TextField
                        fullWidth
                        select
                        name="unit"
                        value={values.unit}
                        label="Select unit"
                        onChange={handleChange}
                        // {...getFieldProps('unit')}
                        sx={{
                          '& .MuiSelect-select': {
                            display: 'flex',
                            justifyContent: 'space-between',
                          },
                        }}
                      >
                        {propertyUnits &&
                          propertyUnits.map((option, index) => (
                            <MenuItem
                              key={index}
                              // disabled={
                              //   option.bookingStatus === 'year' ? true : false
                              // }
                              onClick={() => {
                                handleUnitChange(option);
                              }}
                              value={option._id}
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Typography
                                variant="body2"
                                noWrap
                                sx={{ maxWidth: 300 }}
                              >
                                {option.unitName}
                              </Typography>

                              <Typography variant="caption">
                                {' '}
                                Available for {''}
                                {option.durationName === 'year'
                                  ? 'Year'
                                  : option.durationName === 'sixMonth'
                                  ? '6 Month'
                                  : option.durationName === 'threeMonth'
                                  ? '3 Month'
                                  : option.durationName === 'month'
                                  ? 'Month'
                                  : null}
                              </Typography>
                            </MenuItem>
                          ))}
                      </TextField>
                      {touched.unit && errors.unit && (
                        <FormHelperText error>{errors.unit}</FormHelperText>
                      )}
                    </Grid>
                  ) : null}

                  <Grid item xs={12}>
                    <DateRangePickerBox>
                      <FormControl fullWidth>
                        <DateRangePicker
                          startDate={startDate}
                          startDateId="start_date"
                          endDate={endDate}
                          disabled={values.unit === '' ? true : false}
                          endDateId="end_date"
                          onDatesChange={({ startDate, endDate }) =>
                            handleDateChange(startDate, endDate)
                          }
                          focusedInput={focusedInput}
                          onFocusChange={(focusedInput) => {
                            setFocusedInput(focusedInput);
                          }}
                          // isDayBlocked={(day) =>
                          //   highlightedDates.some((date) => date.isSame(day, 'day'))
                          // }
                          isOutsideRange={(day) => isOutsideRange(day)}
                          displayFormat="DD/MM/YYYY"
                          hideKeyboardShortcutsPanel
                          showClearDates
                          block
                          customInputIcon={
                            <Icon
                              width="18"
                              height="18"
                              icon="solar:calendar-linear"
                            />
                          }
                          // navPrev={<Icon icon="formkit:left" />}
                          // navNext={<Icon icon="formkit:right" />}
                          anchorDirection="right"
                          numberOfMonths={!isMobileMode ? 2 : 1}
                        />
                        {duration === 'year' &&
                          !isOneYearSelected() &&
                          startDate &&
                          endDate && (
                            <FormHelperText error>
                              You can only select a year.
                            </FormHelperText>
                          )}
                        {duration === 'sixMonth' &&
                          !isSixMonthSelected() &&
                          startDate &&
                          endDate && (
                            <FormHelperText error>
                              You can only select for 6 months.
                            </FormHelperText>
                          )}
                        {duration === 'threeMonth' &&
                          !isThreeMonthSelected() &&
                          startDate &&
                          endDate && (
                            <FormHelperText error>
                              You can only select for 3 months.
                            </FormHelperText>
                          )}
                        {duration === 'month' &&
                          !isOneMonthSelected() &&
                          startDate &&
                          endDate && (
                            <FormHelperText error>
                              You can only select months.
                            </FormHelperText>
                          )}

                        {touched.fromDate &&
                          errors.fromDate &&
                          touched.toDate &&
                          errors.toDate && (
                            <FormHelperText error>
                              {errors.fromDate}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </DateRangePickerBox>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Number of people occupying the space"
                      onInput={(e) => {
                        e.target.value = e.target.value
                          .replace(REGEX.RESTRICT_GUEST, '')
                          .slice(0, 2);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SvgColor src={profile} alr="" />
                          </InputAdornment>
                        ),
                      }}
                      {...getFieldProps('guest')}
                      // error={Boolean(touched.guest && errors.guest)}
                      // helperText={touched.guest && errors.guest}
                    />
                    {touched.guest && errors.guest && (
                      <FormHelperText error>{errors.guest}</FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    {rentCalculation ? (
                      <>
                        <Stack
                          justifyContent="space-between"
                          flexDirection="row"
                          sx={{ py: 1 }}
                        >
                          <Typography variant="body1">
                            {nairaSymbol}
                            {duration === 'year' ? (
                              <>
                                {currencySeprator(yearPrice)} {'('} 1 Year{')'}
                              </>
                            ) : duration === 'month' ? (
                              <>
                                {currencySeprator(monthPrice)} {'('}1 Month{')'}
                              </>
                            ) : duration === 'sixMonth' ? (
                              <>
                                {currencySeprator(sixMonthPrice)} {'('}6 Month
                                {')'}
                              </>
                            ) : duration === 'threeMonth' ? (
                              <>
                                {currencySeprator(threeMonthPrice)} {'('}3 Month
                                {')'}
                              </>
                            ) : null}{' '}
                            {/* x {difference} {duration}$ */}
                            {/* {currencySeprator(monthPrice)} x{' '}
                            {duration === 'year' ? (
                              <>{difference * 1} Year</>
                            ) : duration === 'sixMonth' ? (
                              <>{difference * 6} Month</>
                            ) : duration === 'threeMonth' ? (
                              <>{difference * 3} Month</>
                            ) : duration === 'month' ? (
                              <>{difference * 1} Month</>
                            ) : null}{' '} */}
                            {/* x {difference} {duration} */}
                          </Typography>

                          <Typography variant="body1">
                            {nairaSymbol}
                            {currencySeprator(rentCalculation)}
                          </Typography>
                        </Stack>
                        <Stack
                          justifyContent="space-between"
                          flexDirection="row"
                          sx={{ py: 1 }}
                        >
                          {/* <Typography variant="body1">Service fee</Typography>
                        <Typography variant="body1">$36</Typography> */}
                        </Stack>
                        <Divider sx={{ my: 1 }} />
                        <Stack
                          justifyContent="space-between"
                          flexDirection="row"
                          sx={{ py: 1 }}
                        >
                          <Typography variant="subtitle1">
                            Total (exc. Tax)
                          </Typography>
                          <Typography variant="subtitle1">
                            {nairaSymbol}
                            {currencySeprator(rentCalculation)}
                          </Typography>
                        </Stack>
                      </>
                    ) : null}
                  </Grid>

                  <Grid item xs={12}>
                    {requestData ? (
                      <Button
                        variant="contained"
                        fullWidth
                        size="large"
                        disabled
                      >
                        Already applied
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        fullWidth
                        size="large"
                        type="submit"
                      >
                        Apply for listing
                      </Button>
                    )}
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Button
                      variant="outlined"
                      fullWidth
                      size="large"
                      sx={{ px: 2 }}
                      onClick={handleMessageOpen}
                    >
                      Contact landlord
                    </Button>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Button
                      variant="outlined"
                      fullWidth
                      size="large"
                      sx={{ px: 2 }}
                      onClick={handleClickOpen}
                    >
                      Request tour
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Form>
        </FormikProvider>
      </Box>
      <RequestTour
        popupTour={popupTour}
        propertyOwner={propertyOwner}
        setPopupTour={setPopupTour}
      />
      {popupOpen ? (
        <ApplyListing
          applyValues={applyValues}
          popupOpen={popupOpen}
          handlePopupClose={handlePopupClose}
          setPopupOpen={setPopupOpen}
          rentCalculation={rentCalculation}
          setSpinFlag={setSpinFlag}
          setMessage={setMessage}
          setOpen={setOpen}
          difference={difference}
          setStatusCode={setStatusCode}
        />
      ) : null}
    </>
  );
};

export default ReserveForm;
