import { Grid, Box, Container, Typography, Button, Stack } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import bannerImg from '../../assets/images/bannerImg.png';
import { useResponsive } from '../../hooks/useResponsive';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { IMG_URL } from '../../utils/constant';

const StylePopular = styled(Box)(({ theme }) => ({
  padding: '50px 0',
  [theme.breakpoints.down('lg')]: {
    padding: '56px 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: '40px 0',
  },
}));
const BannerLeftMain = styled(Box)(({ theme }) => ({
  paddingLeft: '100px',
  [theme.breakpoints.down('xl')]: {
    paddingLeft: '80px',
  },
  [theme.breakpoints.down('lg')]: {
    paddingLeft: '30px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '0',
  },
}));
const BannerLeftText = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    '& .MuiStack-root': {
      gap: '10px',
      '& .MuiButtonBase-root': {
        padding: '8px 15px',
        minWidth: 'calc(50% - 10px)',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiStack-root': {
      flexDirection: 'column',
      '& .MuiButtonBase-root': {
        minWidth: '100%',
      },
    },
  },
}));
const BannerLeft = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
  '& .MuiTypography-h2': {
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
      marginBottom: '20px',
    },
  },
  '& .MuiTypography-h6': {
    [theme.breakpoints.down('xl')]: {
      fontSize: '1.0625rem',
    },
  },
}));
const HostImages = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'start',
  overflow: 'hidden',
  gap: '25px',
  paddingTop: '64px',
  marginLeft: '-50%',
  [theme.breakpoints.down('md')]: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '0 24px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 16px',
  },
}));
const CarouselDiv = styled(Box)(({ theme }) => ({
  '& .slick-slider': {
    '& .slick-dots': {
      bottom: '0',
      position: 'static',
    },
    '& .slick-list': {
      width: '100%',
    },
  },
}));
const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
};
export const BecomeHostSection = (props) => {
  let { homeContents, becomeLandlord } = props;
  const isMobileMode = useResponsive('down', 'md');
  return (
    <>
      <StylePopular>
        <Container
          fixed={true}
          sx={{
            maxWidth: {
              xl: '1326px',
            },
          }}
        >
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              {isMobileMode ? (
                <CarouselDiv>
                  {becomeLandlord && becomeLandlord?.length > 0 && (
                    <Slider {...settings}>
                      {becomeLandlord?.map((val, index) => (
                        <Box
                          sx={{
                            borderRadius: '22px',
                            overflow: 'hidden',
                            aspectRatio: '1',
                          }}
                          key={index}
                        >
                          {val?.bannerImage ? (
                            <img
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                display: 'block',
                              }}
                              src={`${IMG_URL}${val?.bannerImage}`}
                            />
                          ) : null}
                        </Box>
                      ))}
                    </Slider>
                  )}
                </CarouselDiv>
              ) : (
                <HostImages>
                  {becomeLandlord && becomeLandlord?.length > 0 && (
                    <>
                      <Box
                        sx={{
                          borderRadius: '22px',
                          overflow: 'hidden',
                          width: '470px',
                          aspectRatio: '1',
                        }}
                      >
                        {becomeLandlord[0]?.bannerImage ? (
                          <img
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              display: 'block',
                            }}
                            src={`${IMG_URL}${becomeLandlord[0]?.bannerImage}`}
                          />
                        ) : null}
                      </Box>
                      <Box
                        sx={{
                          borderRadius: '22px',
                          overflow: 'hidden',
                          width: '470px',
                          marginTop: { md: '-64px', xs: '0' },
                          aspectRatio: '1',
                        }}
                      >
                        {becomeLandlord[0]?.bannerImage2 ? (
                          <img
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              display: 'block',
                            }}
                            src={`${IMG_URL}${becomeLandlord[0]?.bannerImage2}`}
                          />
                        ) : null}
                      </Box>
                    </>
                  )}
                </HostImages>
              )}
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: { md: 'start', xs: 'center' },
              }}
            >
              <BannerLeftMain>
                <BannerLeft>
                  <BannerLeftText>
                    {becomeLandlord && becomeLandlord?.length > 0 && (
                      <>
                        <Typography
                          variant="button"
                          component="p"
                          sx={{ mb: 2, color: 'secondary.main' }}
                        >
                          {becomeLandlord[0]?.subTitle}
                        </Typography>
                        <Typography
                          variant="h2"
                          sx={{ mb: 4, color: 'primary.main' }}
                        >
                          {becomeLandlord[0]?.title}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: 'normal', color: 'text.secondary' }}
                        >
                          {becomeLandlord[0]?.description}
                        </Typography>
                      </>
                    )}
                    <Stack direction="row" marginTop="32px" gap="24px">
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        sx={{ minWidth: '195px' }}
                        component={Link}
                        to="/become-a-host"
                      >
                        Become a landlord
                      </Button>
                      {/* <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        sx={{ minWidth: '195px' }}
                        component={Link}
                        to="/"
                      >
                        Learn More
                      </Button> */}
                    </Stack>
                  </BannerLeftText>
                </BannerLeft>
              </BannerLeftMain>
            </Grid>
          </Grid>
        </Container>
      </StylePopular>
    </>
  );
};
