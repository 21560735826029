import { useState, useRef, useEffect } from 'react';
// @mui
import {
  Popper,
  Table,
  CardContent,
  Paper,
  Avatar,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  Pagination,
  CardHeader,
  Box,
  Grow,
  ClickAwayListener,
  MenuList,
  List,
  ListItemIcon,
  ListItemButton,
  Backdrop,
  CircularProgress,
  Radio,
} from '@mui/material';
import { Icon } from '@iconify/react';
import Creditcardmockup from '../../../assets/images/CreditCardMockup.svg';
import Filter from '../../../assets/icons/Filter.svg';
import SvgColor from '../../../components/svg-color';
import Scrollbar from '../../../components/scrollbar';
import {
  getPaymentCard,
  deletePaymentCard,
  setPaymentCardAsPrimary,
} from '../../../services/apiServices';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';
import { DataNotPayment } from '../../../components/data-not-found';
import { CardList } from './CardList';
// ----------------------------------------------------------------------

const options = ['Sort by: Alphabet', 'Sort by: A-Z', 'Sort by: Z-A'];

// ----------------------------------------------------------------------
const ActionField = (props) => {
  let { data, toDeleteCard, setCardAsPrimary } = props;
  const navigate = useNavigate();
  const anchorRef1 = useRef(null);
  const [openIn, setOpenIn] = useState(false);
  const handleEditPage = (id) => {
    navigate(`/payment/add-payment-method`, {
      state: {
        id,
      },
    });
  };
  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        <IconButton
          onClick={() => {
            setOpenIn(true);
          }}
          ref={anchorRef1}
        >
          <Icon icon="carbon:overflow-menu-vertical" />
        </IconButton>
        <Popover
          open={openIn}
          onClose={() => {
            setOpenIn(false);
          }}
          anchorEl={anchorRef1.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'center', horizontal: 'right' }}
          PaperProps={{
            sx: {
              width: '180px',
            },
          }}
        >
          <List>
            {data.isPrimary !== 1 ? (
              <ListItemButton
                onClick={() => {
                  setOpenIn(false), setCardAsPrimary(data._id);
                }}
                sx={{
                  borderRadius: 1.5,
                }}
              >
                <ListItemIcon>
                  <Icon
                    style={{ height: 20, width: 20 }}
                    icon="ph:seal-check-duotone"
                  />
                </ListItemIcon>
                Set as Primary
              </ListItemButton>
            ) : null}

            {/* <ListItemButton
              onClick={() => {
                handleEditPage(data._id);
              }}
              sx={{
                borderRadius: 1.5,
              }}
            >
              <ListItemIcon>
                <Icon
                  style={{ height: 20, width: 20 }}
                  icon="fluent:edit-20-filled"
                />
              </ListItemIcon>
              Edit
            </ListItemButton> */}
            <ListItemButton
              onClick={() => {
                setOpenIn(false), toDeleteCard(data._id);
              }}
              sx={{ borderRadius: 1.5, color: 'error.main' }}
            >
              <ListItemIcon>
                <Icon
                  style={{ height: 20, width: 20 }}
                  icon="solar:trash-bin-minimalistic-bold"
                />
              </ListItemIcon>
              Delete
            </ListItemButton>
          </List>
        </Popover>
      </Box>
    </>
  );
};

export const CardListTable = (props) => {
  let {
    title,
    renderFlag,
    setRenderFlag,
    setMessage,
    setSpinFlag,
    setStatusCode,
    setOpen,
  } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const confirm = useConfirm();
  // const [open, setOpen] = useState(null);
  // const [spinFlag, setSpinFlag] = useState(false);
  // const [errorOpen, setErrorOpen] = useState();
  // const [message, setMessage] = useState();
  const [cardList, setCardList] = useState([]);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  // const [statusCode, setStatusCode] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState();

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const fetchCardList = async () => {
    try {
      setSpinFlag(true);
      let payload = {
        currentPage: currentPage,
      };
      let { data } = await getPaymentCard(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setCardList(data.cardList);
        // setStatusCode(data.statusCode);
        setNumberOfPages(data.noOfPages);
      } else {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  useEffect(() => {
    fetchCardList();
  }, [renderFlag, currentPage]);

  const toDeleteCard = async (id) => {
    try {
      confirm({ description: `you want to delete this card` })
        .then(async () => {
          let payload = { cardId: id };
          const { data } = await deletePaymentCard(payload);
          if (data.statusCode === 200) {
            setSpinFlag(false);
            setOpen(true);
            setMessage(data.message);
            setStatusCode(data.statusCode);
            setRenderFlag(!renderFlag);
          } else {
            setSpinFlag(false);
            setOpen(true);
            setMessage(data.message);
            setStatusCode(data.statusCode);
          }
        })
        .catch(() => console.log('Deletion cancelled.'));
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const setCardAsPrimary = async (id) => {
    try {
      let payload = { cardId: id };
      const { data } = await setPaymentCardAsPrimary(payload);

      if (data.statusCode === 200) {
        setRenderFlag(!renderFlag);
        setStatusCode(data.statusCode);
        // setSpinFlag(false);
        // setOpen(true);
        // setMessage(data.message);
      } else {
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  return (
    <Box>
      {cardList.length > 0 && (
        <>
          <CardList cardList={cardList} />
          {/* <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={spinFlag}
          >
            <CircularProgress color="inherit" />
          </Backdrop> */}
          {/* <DialogPopup
            open={open}
            setOpen={setOpen}
            message={message}
            size="xs"
            statusCode={statusCode}
          /> */}
          <CardHeader
            title={title}
            // action={
            //   <Box>
            //     <Button
            //       sx={{
            //         width: '250px',
            //         border: '1px solid #EDEDED',
            //         height: '48px',
            //         pl: 2,
            //       }}
            //       ref={anchorRef}
            //       aria-label="split button"
            //       onClick={handleToggle}
            //     >
            //       <SvgColor
            //         sx={{ mr: 1.5, width: '18px', height: '18px' }}
            //         src={Filter}
            //       />
            //       {options[selectedIndex]}
            //       <Icon
            //         style={{ marginLeft: 'auto' }}
            //         icon="akar-icons:chevron-down"
            //       />
            //     </Button>
            //     <Popper
            //       open={open}
            //       anchorEl={anchorRef.current}
            //       transition
            //       sx={{
            //         width: '250px',
            //         background: '#fff',
            //         p: 1,
            //         borderRadius: 2.5,
            //         boxShadow: (theme) => theme.customShadows.z4,
            //       }}
            //     >
            //       {({ TransitionProps, placement }) => (
            //         <Grow
            //           {...TransitionProps}
            //           style={{
            //             transformOrigin:
            //               placement === 'bottom'
            //                 ? 'center top'
            //                 : 'center bottom',
            //           }}
            //         >
            //           <Paper>
            //             <ClickAwayListener onClickAway={handleClose}>
            //               <MenuList id="split-button-menu" autoFocusItem>
            //                 {options.map((option, index) => (
            //                   <MenuItem
            //                     key={option}
            //                     selected={index === selectedIndex}
            //                     onClick={(event) =>
            //                       handleMenuItemClick(event, index)
            //                     }
            //                     sx={{
            //                       borderRadius: 1.5,
            //                     }}
            //                   >
            //                     {option}
            //                   </MenuItem>
            //                 ))}
            //               </MenuList>
            //             </ClickAwayListener>
            //           </Paper>
            //         </Grow>
            //       )}
            //     </Popper>
            //   </Box>
            // }
            sx={{ pb: 3, borderBottom: '1px solid #F1F1F1' }}
          />
          <CardContent sx={{ p: 0 }}>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 1100 }}>
                <Table>
                  <TableBody>
                    {cardList.map((row) => {
                      const {
                        bank,
                        last4,
                        cardHolderName,
                        // nameCard,
                        exp_year,
                        exp_month,
                        // cvv,
                        brand,
                      } = row.cardDetails;
                      return (
                        <TableRow hover tabIndex={-1} role="checkbox">
                          <TableCell sx={{ width: '150px' }}>
                            <Box sx={{ whiteSpace: 'normal' }}>
                              <Box
                                sx={{
                                  width: '100px',
                                  height: '67px',
                                  borderRadius: '10px',
                                  position: 'relative',
                                  bgcolor: `${
                                    brand === 'visa'
                                      ? '#0163F6'
                                      : brand === 'Mastercard'
                                      ? '#1FAB71'
                                      : '#FFBA07'
                                  }`,
                                }}
                              >
                                <img
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                  }}
                                  src={Creditcardmockup}
                                  alt={bank}
                                />
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: '5px',
                                    left: '5px',
                                    padding: '2px',
                                    color: '#12B76A',
                                    backgroundColor: '#ECFDF3',
                                    border: '1px solid #12B76A',
                                    borderRadius: '5px',
                                    fontSize: '8px',
                                    fontWeight: '300',
                                    overflow: 'hidden',
                                    display:
                                      row.isPrimary !== 0 ? 'flex' : 'none',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Icon
                                    width="18px"
                                    icon="ph:seal-check-duotone"
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    bgcolor: 'rgba(255,255,255,0.1)',
                                    borderRadius: '4px',
                                    height: '32px',
                                    width: '45px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '5px',
                                    position: 'absolute',
                                    bottom: '5px',
                                    right: '5px',
                                  }}
                                >
                                  {brand === 'visa' ? (
                                    <Icon height="100%" icon={'logos:visa'} />
                                  ) : brand === 'Mastercard' ? (
                                    <Icon
                                      height="100%"
                                      icon={'logos:mastercard'}
                                    />
                                  ) : brand === 'Amex' ? (
                                    <Icon height="100%" icon={'logos:amex'} />
                                  ) : brand === 'Discover' ? (
                                    <Icon
                                      height="100%"
                                      icon={'logos:discover'}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>

                          <TableCell align="left">
                            <Box sx={{ whiteSpace: 'normal' }}>
                              <Typography variant="body2">Bank name</Typography>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '16px' }}
                              >
                                {bank ? bank : '-'}
                              </Typography>
                            </Box>
                          </TableCell>

                          <TableCell align="left">
                            <Box sx={{ whiteSpace: 'normal' }}>
                              <Typography variant="body2">
                                Card number
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '16px' }}
                              >
                                **** **** **** {last4}
                              </Typography>
                            </Box>
                          </TableCell>

                          <TableCell align="left">
                            <Box sx={{ whiteSpace: 'normal' }}>
                              <Typography variant="body2">
                                Card holder's name
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '16px' }}
                              >
                                {cardHolderName ? cardHolderName : '-'}
                              </Typography>
                            </Box>
                          </TableCell>

                          <TableCell align="left">
                            <Box sx={{ whiteSpace: 'normal' }}>
                              <Typography variant="body2">Expired</Typography>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '16px' }}
                              >
                                {exp_month} / {exp_year}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell align="left">
                            <Box sx={{ whiteSpace: 'normal' }}>
                              <Typography variant="body2">
                                3 digits CVV
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '16px' }}
                              >
                                ***
                              </Typography>
                            </Box>
                          </TableCell>

                          <TableCell align="right">
                            <ActionField
                              data={row}
                              toDeleteCard={toDeleteCard}
                              setCardAsPrimary={setCardAsPrimary}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </CardContent>
        </>
      )}
      {cardList.length === 0 && (
        <CardContent sx={{ bgcolor: '#F9F9F9' }}>
          <DataNotPayment title="You Don’t have card list" />
        </CardContent>
      )}
      {cardList.length !== 0 && (
        <Pagination
          shape="rounded"
          count={numberOfPages}
          siblingCount={0}
          boundaryCount={2}
          onChange={handlePageChange}
          sx={{
            py: 2,
            '& .MuiPagination-ul': { justifyContent: 'center' },
          }}
        />
      )}
    </Box>
  );
};
