import React, { useEffect, useState } from 'react';
import 'flatpickr/dist/flatpickr.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import MuiPhoneNumber from 'material-ui-phone-number';
import Select from 'react-select';
import { FormFeedback, Input, Label, Form, Button, Row, Col } from 'reactstrap';
import { onlyCountries } from '../../../utils/constant';
import Flatpickr from 'react-flatpickr';
import { setToken } from '../../../utils/axiosClient';
import { useAuthStore } from '../../../redux/features/authSlice';
import { addAdminUser, editAdminUser } from '../../../utils/validation/Admin';
import {
  createAdminUser,
  getModules,
  getRoles,
  updateUser,
} from '../../../services/apiServices';
const AddAdminUsers = (props) => {
  let {
    userData,
    allUserPermissionModule,
    setModal,
    setFetchRender,
    fetchRender,
    isEdit,
    setSpinFlag,
    setResponse,
  } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { loginAdminInfo } = useAuthStore();
  const [allModulesData, setAllModulesData, allModulesDataRef] = useState([]);
  const currentDate = new Date();
  const [allModules, setAllModules] = useState([]);
  const [optionsRoles, setOptionsRoles] = useState([]);
  const [renderState, setRenderState] = useState(true);
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const defaultCountry = 'us';
  const customermocalrole = [
    {
      options: optionsRoles,
    },
  ];

  useEffect(() => {
    fetchRoles();
    fetchModules();
    setAllModulesData(allUserPermissionModule);
  }, [allUserPermissionModule]);

  useEffect(() => {
    count();
  }, [allModules]);
  useEffect(() => {
    getCountttt();
  }, [allModules]);
  const count = () => {
    const moduleSubmoduleCount = {};
    if (allModules && allModules.length > 0) {
      allModules.forEach((module) => {
        const moduleName = module.module_name;
        const submoduleCount = module.subModules.length;

        // Store the count with the module name as the key
        moduleSubmoduleCount[module._id] = submoduleCount;
      });
    }
  };
  const getCountttt = () => {};
  const checkedPreviousData = () => {
    allUserPermissionModule.forEach((item, index) => {
      let checkbox = document.getElementById(`${item?.moduleId}`);
      if (checkbox) {
        checkbox.checked = true;
      }
    });
  };

  const fetchRoles = async () => {
    try {
      setSpinFlag(true);
      const { data } = await getRoles();
      if (data.statusCode == 200) {
        const arr = [];
        data?.allRoles.map((item) => {
          arr.push({ label: item.role_name, value: item.role_name });
        });
        setOptionsRoles(arr);
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };
  const fetchModules = async () => {
    setRenderState(!renderState);
    try {
      setSpinFlag(true);
      const { data } = await getModules();
      if (data.statusCode == 200) {
        setAllModules(data.allModules);
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: userData?.firstname || '',
      lastname: userData?.lastname || '',
      email: userData?.email || '',
      password: '',
      confirmpassword: '',
      mobile: userData?.mobile || '',
      countryCode: userData?.countryCode || '+1',
      dob: userData?.dob || '',
      allModules: '',
      role: userData?.role || '',
    },
    validationSchema: isEdit ? editAdminUser : addAdminUser,
    onSubmit: async (values) => {
      isEdit ? editAdminUserModules(values) : addAdminUserModules(values);
    },
  });
  const addAdminUserModules = async (values) => {
    const payload = {
      firstname: values.firstname,
      lastname: values.lastname,
      dob: values.dob,
      email: values.email,
      password: values.password,
      mobile: values.mobile,
      countryCode: values.countryCode,
      role: values.role,
      allModules: allModulesData,
    };
    try {
      setSpinFlag(true);
      setToken(loginAdminInfo ? loginAdminInfo.token : null);
      const { data } = await createAdminUser(payload);
      if (data.statusCode == 200) {
        setFetchRender(!fetchRender);
        setModal(false);
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
        resetForm();
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };
  const editAdminUserModules = async (values) => {
    try {
      setSpinFlag(true);
      const payload = {
        firstname: values.firstname,
        lastname: values.lastname,
        dob: values.dob,
        role: values.role,
        user_id: userData._id,
        allModules: allModulesData,
      };
      const { data } = await updateUser(payload);
      if (data.statusCode === 200) {
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
        setSpinFlag(false);
        setFetchRender(!fetchRender);
        setModal(false);
        resetForm();
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const handleChange = async (event, modeuleData, subModuleData, flag) => {
    if (event.target.checked) {
      if (flag) {
        if (
          modeuleData.hasOwnProperty('subModules') &&
          Array.isArray(modeuleData.subModules) &&
          modeuleData.subModules.length > 0
        ) {
          modeuleData.subModules.forEach((item, index) => {
            let obj = { moduleId: modeuleData._id, subModuleId: item._id };
            addSingleItem(obj);
          });
        }
        let pObj = {
          moduleId: modeuleData._id,
          subModuleId:
            subModuleData._id == undefined ? null : subModuleData._id,
        };

        addSingleItem(pObj);
      } else {
        // for unched handling
        let pObj = {
          moduleId: modeuleData._id,
          subModuleId: subModuleData._id,
        };
        addSingleItem(pObj); // push data in array

        let Newdata = allModulesDataRef?.current?.filter(
          (ob) => ob.moduleId === modeuleData._id
        );
        let filtered = Newdata?.filter(function (item) {
          let obj = modeuleData.subModules.some(
            (element) =>
              element.moduleId === item.parent_id ||
              element.subModuleId === item._id
          );
          return obj;
        });

        if (filtered?.length === modeuleData?.subModules.length) {
          let pObj = {
            moduleId: modeuleData._id,
            subModuleId: null,
          };
          addSingleItem(pObj); // if alla child checked then checked parent as well
        }
      }
    } else {
      if (flag) {
        modeuleData?.subModules.forEach((item, index) => {
          const ele = document.querySelectorAll(`.${modeuleData?.module_name}`);
          ele.forEach((subModuleCheckbox) => {
            subModuleCheckbox.checked = false;
          });
          let obj = { moduleId: modeuleData._id, subModuleId: item._id };
          const objectExists = allModulesData.some(
            (item) =>
              item.moduleId === obj.moduleId ||
              item.subModuleId === obj.subModuleId
          );
          if (objectExists) {
            setAllModulesData((prevArray) =>
              prevArray?.filter(
                (item, index) =>
                  item.moduleId !== obj.moduleId ||
                  item.subModuleId !== obj.subModuleId
              )
            );
          }
        });
        let pObj = {
          moduleId: modeuleData._id,
          subModuleId: null,
        };
        removeSingleItem(pObj);
      } else {
        let pObj = {
          moduleId: modeuleData._id,
          subModuleId: subModuleData._id,
        };
        removeSingleItem(pObj);
        let checkbox = document.getElementById(`${modeuleData?._id}`);
        checkbox.checked = false;
        let NPObj = {
          moduleId: modeuleData._id,
          subModuleId: null,
        };
        removeSingleItem(NPObj);
      }
    }
  };

  const removeSingleItem = (pObj) => {
    const pObjectExists = allModulesData.some(
      (item) =>
        item.moduleId === pObj.moduleId && item.subModuleId === pObj.subModuleId
    );
    if (pObjectExists) {
      setAllModulesData((prevArray) =>
        prevArray?.filter(
          (item, index) =>
            item.moduleId !== pObj.moduleId ||
            item.subModuleId !== pObj.subModuleId
        )
      );
    }
  };

  const addSingleItem = (pObj) => {
    const pObjectExists = allModulesData.some(
      (item) =>
        item.moduleId === pObj.moduleId && item.subModuleId === pObj.subModuleId
    );
    if (!pObjectExists) {
      setAllModulesData((prevArray) => [...prevArray, pObj]);
    }
  };

  const {
    errors,
    touched,
    handleBlur,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setFieldError,
    resetForm,
    values,
  } = validation;
  const handleCountryCodeInput = (e) => {
    setFieldValue('countryCode', e);
  };

  const getCheckedValue = (moduleId, subModuleId) => {
    const pObjectExists = allModulesData.some(
      (item) => item.moduleId === moduleId && item.subModuleId === subModuleId
    );
    if (pObjectExists) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <Row>
          <Col sm={6}>
            <div className="mb-3">
              <Label htmlFor="email" className="form-label">
                First Name
              </Label>
              <Input
                name="firstname"
                className="form-control"
                placeholder="Enter First Name"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.firstname || ''}
                invalid={
                  validation.touched.firstname && validation.errors.firstname
                    ? true
                    : false
                }
              />
              {validation.touched.firstname && validation.errors.firstname ? (
                <FormFeedback type="invalid">
                  {validation.errors.firstname}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col sm={6}>
            <div className="mb-3">
              <Label htmlFor="email" className="form-label">
                Last Name
              </Label>
              <Input
                name="lastname"
                className="form-control"
                placeholder="Enter Last Name"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.lastname || ''}
                invalid={
                  validation.touched.lastname && validation.errors.lastname
                    ? true
                    : false
                }
              />
              {validation.touched.lastname && validation.errors.lastname ? (
                <FormFeedback type="invalid">
                  {validation.errors.lastname}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col sm={6}>
            <div className="mb-3">
              <Label htmlFor="email" className="form-label">
                Email
              </Label>
              <Input
                name="email"
                className="form-control"
                placeholder="Enter Email"
                type="email"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email || ''}
                disabled={isEdit ? true : false}
                invalid={
                  validation.touched.email && validation.errors.email
                    ? true
                    : false
                }
              />
              {validation.touched.email && validation.errors.email ? (
                <FormFeedback type="invalid">
                  {validation.errors.email}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col sm={6}>
            <div className="mb-3">
              <Label htmlFor="mobile" className="form-label">
                Phone
              </Label>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <MuiPhoneNumber
                  value={values.countryCode}
                  defaultCountry={defaultCountry}
                  onlyCountries={onlyCountries}
                  name="countryCode"
                  onChange={handleCountryCodeInput}
                  variant="filled"
                  countryCodeEditable="false"
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    width: '110px',
                    '& .MuiPhoneNumber-flagButton': {
                      height: '20px',
                      padding: '0',
                      minWidth: '20px',
                    },
                    '& .MuiInputBase-root': {
                      borderRadius: '0.25rem 0 0 0.25rem',
                      borderRight: 'none',
                      fontSize: '0.8125rem',
                      padding: '0.5rem 0.9rem',
                      minHeight: '37.5px',
                      background: 'none',
                      borderColor: 'var(--vz-input-border)',
                      '& .MuiInputAdornment-root': { margin: '0 !important' },
                      '& .MuiInputBase-input': {
                        padding: '0 0 0 5px',
                        fontSize: '0.8125rem',
                        fontFamily: 'Inter,sans-serif',
                        fontWeight: 'normal',
                      },
                      '& .MuiMenu-paper': {
                        maxHeight: '500px',
                      },
                    },
                  }}
                  disabled={isEdit ? true : false}
                />
                <Input
                  name="mobile"
                  className="form-control"
                  disabled={isEdit ? true : false}
                  placeholder="Enter Confirm Phone No."
                  style={{
                    borderTopLeftRadius: '0',
                    borderBottomLeftRadius: '0',
                    width: 'calc(100% - 110px)',
                  }}
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.mobile || ''}
                  invalid={
                    validation.touched.mobile && validation.errors.mobile
                      ? true
                      : false
                  }
                />
                {validation.touched.mobile && validation.errors.mobile ? (
                  <FormFeedback type="invalid" style={{ width: '100%' }}>
                    {validation.errors.mobile}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
          </Col>
          {isEdit ? (
            ' '
          ) : (
            <>
              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="password" className="form-label">
                    Password
                  </Label>
                  <div className="position-relative auth-pass-inputgroup mb-3">
                    <Input
                      name="password"
                      className="form-control"
                      placeholder="Enter Password"
                      type={passwordShow ? 'text' : 'password'}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.password || ''}
                      invalid={
                        validation.touched.password &&
                        validation.errors.password
                          ? true
                          : false
                      }
                    />
                    {validation.touched.password &&
                    validation.errors.password ? (
                      <FormFeedback type="invalid">
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}
                    <button
                      className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                      type="button"
                      id="password-addon"
                      onClick={() => setPasswordShow(!passwordShow)}
                    >
                      <i
                        className={`align-middle ${
                          passwordShow ? 'ri-eye-off-fill' : 'ri-eye-fill'
                        }`}
                      ></i>
                    </button>
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="cpasswprd" className="form-label">
                    Confirm Password
                  </Label>
                  <div className="position-relative auth-pass-inputgroup mb-3">
                    <Input
                      name="confirmpassword"
                      className="form-control"
                      placeholder="Enter Confirm Password"
                      type={confirmPasswordShow ? 'text' : 'password'}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.confirmpassword || ''}
                      invalid={
                        validation.touched.confirmpassword &&
                        validation.errors.confirmpassword
                          ? true
                          : false
                      }
                    />
                    {validation.touched.confirmpassword &&
                    validation.errors.confirmpassword ? (
                      <FormFeedback type="invalid">
                        {validation.errors.confirmpassword}
                      </FormFeedback>
                    ) : null}
                    <button
                      className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                      type="button"
                      id="password-addon"
                      onClick={() =>
                        setConfirmPasswordShow(!confirmPasswordShow)
                      }
                    >
                      <i
                        className={`align-middle ${
                          confirmPasswordShow
                            ? 'ri-eye-off-fill'
                            : 'ri-eye-fill'
                        }`}
                      ></i>
                    </button>
                  </div>
                </div>
              </Col>
            </>
          )}

          <Col sm={6}>
            <div className="mb-3">
              <Label htmlFor="datepicker-publish-input" className="form-label">
                Date Of Birth
              </Label>
              <div className="">
                <Flatpickr
                  className="form-control"
                  id="datepicker-publish-input"
                  placeholder="Select a date"
                  value={validation.values.dob || ''}
                  options={{
                    dateFormat: 'Y-m-d',
                    mode: 'single',
                    utc: false,
                    maxDate: currentDate,
                  }}
                  onChange={(selectedDates) => {
                    validation.setFieldValue('dob', selectedDates[0]);
                  }}
                />
              </div>
              {validation.errors.dob && validation.touched.dob && (
                <div
                  className="invalid"
                  style={{ color: '#EF7157', fontSize: '0.875em' }}
                >
                  {validation.errors.dob}
                </div>
              )}
            </div>
          </Col>
          <Col sm={6}>
            <div className="mb-3">
              <Label htmlFor="role-field" className="form-label">
                Select role
              </Label>
              <Select
                onChange={(e) => {
                  validation.setFieldValue('role', e.value);
                }}
                options={customermocalrole}
                name="choices-single-default"
                className="text-capitalize"
                id="idStatus"
                placeholder={isEdit ? validation.values.role : 'Select Role'}
              ></Select>
              {validation.touched.role && validation.errors.role ? (
                <FormFeedback type="invalid">
                  {validation.errors.role}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        <h5 className="modal-title mt-2 mb-3">Permissions</h5>
        <Row>
          {allModules ? (
            <>
              {allModules.map((item, index) => {
                return (
                  <Col sm={6}>
                    <div className="mb-4">
                      <div className="mb-3">
                        <Input
                          name={item.module_name}
                          id={item._id}
                          type="checkbox"
                          checked={getCheckedValue(item._id, null)}
                          onChange={(e) => handleChange(e, item, {}, true)}
                        />
                        <Label
                          htmlFor={item?.module_name + index}
                          className="form-label ms-2 mb-0"
                        >
                          {item?.module_name}
                        </Label>
                      </div>
                      {item?.subModules && item?.subModules.length !== 0 ? (
                        <>
                          {item?.subModules?.map((subModule, index1) => {
                            return (
                              <div className="ps-3 mb-2">
                                <Input
                                  name={item?.subModule}
                                  id={subModule?._id}
                                  type="checkbox"
                                  className={item?.module_name}
                                  key={subModule?._id}
                                  checked={getCheckedValue(
                                    item._id,
                                    subModule._id
                                  )}
                                  onChange={(e) =>
                                    handleChange(e, item, subModule, false)
                                  }
                                />
                                <Label
                                  htmlFor={subModule?._id}
                                  className="form-label ms-2 mb-0"
                                >
                                  {subModule?.module_name}
                                </Label>
                              </div>
                            );
                          })}
                        </>
                      ) : null}
                    </div>
                  </Col>
                );
              })}
            </>
          ) : null}
        </Row>
        <div className="mt-4">
          <div className="hstack gap-2 justify-content-end">
            <Button
              color="light"
              type="button"
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </Button>
            <Button color="success" type="submit">
              {isEdit ? 'Update User' : 'Add User'}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default AddAdminUsers;
