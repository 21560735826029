import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Container,
  Typography,
  Stack,
  Divider,
  Rating,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import SvgColor from '../../../../components/svg-color';
import Bed from '../../../../assets/icons/Bed.svg';
import Like from '../../../../assets/icons/Like.svg';
import Bathroom from '../../../../assets/icons/Bathroom.svg';
import bannerImg from '../../../../assets/images/bannerImg.png';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import mapMarkFill from '../../../../assets/icons/mapMarkFill.svg';
import { Icon } from '@iconify/react';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconEmpty': {
    color: '#D3D3DC',
  },
  '& .MuiRating-iconFilled': {
    color: '#FFBD3E',
  },
  '& .MuiRating-iconHover': {
    color: '#FFBD3E',
  },
});
const TopMap = styled(Box)(({ theme }) => ({
  height: '275px',
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

const BannerRight = styled(Box)(({ theme }) => ({
  '& img': {
    display: 'block',
    width: '100%',
    aspectRatio: '1/0.95',
  },
}));

const PropertyMap = (props) => {
  let { propertyData, propertyUnits } = props;
  let { title, address, latitude, longitude, rating } = propertyData;

  const [center, setCenter] = useState({
    lat: 37.0902,
    lng: 95.7129,
  });

  useEffect(() => {
    setCenter({ lat: latitude, lng: longitude });
  }, [latitude, longitude]);

  return (
    <>
      <Box>
        <TopMap>
          <Map google={props.google} zoom={3} center={center}>
            <Marker name="marker" position={center} icon={mapMarkFill}></Marker>
          </Map>
        </TopMap>
      </Box>
    </>
  );
};
//
export default GoogleApiWrapper({
  apiKey: 'AIzaSyAgcJ-JZYcJwVIFeJyaAOI4b5aap7T2I3w',
})(PropertyMap);
