import { createSlice } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

let initialState = {
  userRedirectFlag: false,
};
export const userRedirect = createSlice({
  name: 'redirect',
  initialState,
  reducers: {
    updateUserRedirectState: (state) => {
      state.userRedirectFlag = !state.userRedirectFlag;
    },
  },
});

export function useRedirectStore() {
  const userRedirectFlag = useSelector(
    (state) => state.redirect.userRedirectFlag
  );
  const dispatch = useDispatch();
  return {
    userRedirectFlag,
    updateUserRedirectState: () => dispatch(updateUserRedirectState()),
  };
}

export const { updateUserRedirectState } = userRedirect.actions;

export default userRedirect.reducer;
