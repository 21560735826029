import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import Widgets from '../../Components/Common/Widgets';
import WidgetsSmall from '../../Components/Common/WidgetsSmall';
import {
  allCountBasedOnProperty,
  allCountBasedOnRole,
} from '../../../services/apiServices';
import { setToken } from '../../../utils/axiosClient';
import { useAuthStore } from '../../../redux/features/authSlice';
import RevenueChart from './RevenueChart';
import PropertyChart from './PropertyChart';
import UsersChart from './UsersChart';
import PropertyMapLocations from './PropertyMapLocations';
import ResentBooking from './ResentBooking';
import RecentActivity from './RecentActivity';
import ShowSpinner from '../../Components/Common/spinner';
import ResponseModal from '../../Components/Common/SuccessModal';
import { nairaSymbol } from '../../../utils/constant';
import { createUserLogged } from '../../../utils/common-function';

const AdminDashboard = () => {
  let today = new Date();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [totalEarning, setTotalEarning] = useState(0);
  const [allUsersCount, setAllUsersCount] = useState(0);
  const [allRenterCount, setAllRenterCount] = useState(0);
  const [allLandlordCount, setAllLandlordCount] = useState(0);
  const [allPropertyCount, setAllPropertyCount] = useState(0);
  const [allAdminCount, setAllAdminCount] = useState(0);
  const [allCityCount, setAllCityCount] = useState(0);
  const [allActiveUserCount, setAllActiveUserCount] = useState(0);
  const [allInActiveUserCount, setAllInActiveUserCount] = useState(0);
  const [allActiveUserPercentage, setAllActiveUserPercentage] = useState(0);
  const [allInActiveUserPercentage, setAllInActiveUserPercentage] = useState(0);
  const [allAdminUserPercentage, setAllAdminUserPercentage] = useState(0);
  const [allLandlordUserPercentage, setAllLandlordUserPercentage] = useState(0);
  const [allRenterUserPercentage, setAllRenterUserPercentage] = useState(0);
  const [allViewedPropertyCount, setAllViewedPropertyCount] = useState(0);
  const [allViewedPropertyPercentage, setAllViewedPropertyPercentage] =
    useState(0);
  const [allUnitCount, setAllUnitCount] = useState(0);
  const [allBookedUnitCount, setAllBookedUnitCount] = useState(0);
  const [allBookedUnitPercentage, setAllBookedUnitPercentage] = useState(0);
  const [allAvailableUnitCount, setAllAvailableUnitCount] = useState(0);
  const [allAvailableUnitPercentage, setAllAvailableUnitPercentage] =
    useState(0);
  const [allCountryCountPercentage, setCountryCountPercentage] = useState([]);
  const [countData, setCountData] = useState(0);
  const { loginAdminInfo } = useAuthStore();
  const [renderCount, setRender] = useState(true);
  const [spinFlag, setSpinFlag] = useState(false);
  const [response, setResponse] = useState({
    message: '',
    open: false,
    statusCode: null,
  });

  useEffect(() => {
    getAllCount();
    getAllCountPropertyBased();
  }, [renderCount, allUsersCount]);

  const getAllCount = async () => {
    try {
      setSpinFlag(true);
      setToken(loginAdminInfo ? loginAdminInfo.token : null);
      console.log('loginAdminInfo', loginAdminInfo);
      const { data } = await allCountBasedOnRole();
      if (data.statusCode == 200) {
        setAllUsersCount(data.allUserCount);
        setAllLandlordCount(data.allLandlordCount);
        setAllRenterCount(data.allRenterCount);
        setAllPropertyCount(data.allPropertiesCount);
        setAllAdminCount(data.allAdminCount);
        setAllCityCount(data.allCityCount);
        setAllActiveUserCount(data.allActiveUserCount);
        setAllInActiveUserCount(data.allInActiveUserCount);
        setAllActiveUserPercentage(data.activeUserPercenatge);
        setAllInActiveUserPercentage(data.InactiveUserPercenatge);
        setAllAdminUserPercentage(data.adminUserPercentage);
        setAllLandlordUserPercentage(data.landlordUserPercentage);
        setAllRenterUserPercentage(data.renterUserPercentage);
        setCountData(data);
        setTotalEarning(data.totalEarning);
        setSpinFlag(false);
        createUserLogged(
          'dashboard',
          'worked and visted on dashboard',
          today,
          loginAdminInfo?._id
        );
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const getAllCountPropertyBased = async () => {
    try {
      setSpinFlag(true);
      const { data } = await allCountBasedOnProperty();
      if (data.statusCode == 200) {
        setAllViewedPropertyPercentage(data.viewedPropertyPercentage);
        setAllViewedPropertyCount(data.allViewedPropertyCount);
        setAllUnitCount(data.allUnitCount);
        setAllBookedUnitCount(data.allBookedUnitCount);
        setAllBookedUnitPercentage(data.bookedUnitPercentage);
        setAllAvailableUnitCount(data.allAvailableUnitCount);
        setAllAvailableUnitPercentage(data.availableUnitPercentage);
        setCountryCountPercentage(data.allCountryCountPercentage);
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const ecomWidgets = [
    {
      id: 1,
      cardColor: 'success',
      label: 'Total Users',
      badgeClass: 'success',
      counter: allUsersCount,
      bgcolor: 'success',
      icon: 'bx bx-user-circle',
      decimals: 0,
      role: 'all',
      tab: 1,
    },
    {
      id: 2,
      cardColor: 'secondary',
      label: 'Total Properties',
      badgeClass: 'danger',
      counter: allPropertyCount,
      bgcolor: 'info',
      icon: 'bx bx-building-house',
      decimals: 0,
      role: 'property',
      tab: 11,
    },
    {
      id: 3,
      cardColor: 'warning',
      label: 'Total Landlord',
      badgeClass: 'warning',
      counter: allLandlordCount,
      bgcolor: 'warning',
      icon: 'bx bx-user-circle',
      decimals: 0,
      role: 'landlord',
      tab: 3,
    },
    {
      id: 4,
      cardColor: 'info',
      label: 'Total Renter',
      badgeClass: 'info',
      counter: allRenterCount,
      bgcolor: 'info',
      icon: 'bx bx-user-circle',
      decimals: 0,
      role: 'renter',
      tab: 4,
    },
    {
      id: 5,
      cardColor: 'danger',
      label: 'Total Admin User',
      badgeClass: 'danger',
      counter: allAdminCount,
      bgcolor: 'danger',
      icon: 'bx bx-user-circle',
      decimals: 0,
      role: 'admin',
      tab: 2,
    },
  ];
  // property-list
  const sidebar = () => {
    const element = document.getElementById('layout-rightside-coll');
    element.classList.toggle('d-none');
  };
  return (
    <React.Fragment>
      <ResponseModal response={response} setResponse={setResponse} />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Row className="mb-3">
                  <Col xs={9}>
                    <h4 className="fs-16 mb-1">
                      Good Morning,{' '}
                      <span className="text-capitalize">
                        {loginAdminInfo.firstname}
                      </span>
                      !
                    </h4>
                    <p className="text-muted mb-0">
                      Here's what's happening with your site today.
                    </p>
                  </Col>
                  <Col xs={3}>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-soft-info btn-icon waves-effect waves-light layout-rightside-btn"
                        onClick={() => sidebar()}
                      >
                        <i className="ri-pulse-line"></i>
                      </button>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xxl={3} lg={6}>
                    <Widgets
                      label={'TOTAL PROPERTIES'}
                      prefix={''}
                      suffix={''}
                      separator={''}
                      end={allPropertyCount}
                      decimals={''}
                      bgcolor={'success'}
                      icon={'bx bx-building-house'}
                      role={''}
                      tab={''}
                      link={'/admin/property-list'}
                    />
                  </Col>
                  <Col xxl={3} lg={6}>
                    <Widgets
                      label={'TOTAL USERS'}
                      prefix={''}
                      suffix={''}
                      separator={''}
                      end={allUsersCount}
                      decimals={''}
                      bgcolor={'info'}
                      icon={'bx bx-user-circle'}
                      role={''}
                      tab={''}
                      link={'/admin/user-list'}
                    />
                  </Col>
                  <Col xxl={3} lg={6}>
                    <Widgets
                      label={'TOTAL EARNINGS'}
                      prefix={nairaSymbol}
                      suffix={''}
                      separator={''}
                      end={totalEarning}
                      decimals={''}
                      bgcolor={'warning'}
                      icon={'bx bxs-wallet'}
                      role={''}
                      tab={''}
                      link={'/admin/payment'}
                    />
                  </Col>
                  <Col xxl={3} lg={6}>
                    <Widgets
                      label={'Total City'}
                      prefix={''}
                      suffix={''}
                      separator={''}
                      end={allCityCount}
                      decimals={''}
                      bgcolor={'primary'}
                      icon={'bx bxs-city'}
                      role={''}
                      tab={''}
                      link={'/admin/featured-cities'}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <Card className="overflow-hidden">
                      <CardBody>
                        <div className="">
                          <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                            <small>TOTAL Viewed Properties</small>{' '}
                            <span className="float-end">
                              {allViewedPropertyCount}
                            </span>
                          </p>
                          <div
                            className="progress mt-2"
                            style={{ height: '6px' }}
                          >
                            <div
                              className="progress-bar progress-bar-striped bg-primary"
                              role="progressbar"
                              style={{
                                width: `${allViewedPropertyPercentage}%`,
                              }}
                              aria-valuenow="75"
                              aria-valuemin="0"
                              aria-valuemax="75"
                            ></div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Row>
                      <Col xxl={6} lg={12}>
                        <WidgetsSmall
                          label={'Available Units'}
                          separator={''}
                          decimals={''}
                          end={allAvailableUnitCount}
                          bgcolor={'rgb(41, 156, 219)'}
                          icon={'bx bx-building-house'}
                          percentageCount={allAvailableUnitPercentage}
                        />
                      </Col>
                      <Col xxl={6} lg={12}>
                        <WidgetsSmall
                          label={'Booked Units'}
                          separator={''}
                          decimals={''}
                          end={allBookedUnitCount}
                          bgcolor={'rgb(240, 101, 72)'}
                          icon={'bx bx-building-house'}
                          percentageCount={allBookedUnitPercentage}
                        />
                      </Col>
                      <Col xs={12}>
                        <PropertyChart
                          allUnitCount={allUnitCount}
                          allBookedUnitCount={allBookedUnitCount}
                          allAvailableUnitCount={allAvailableUnitCount}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row>
                      <Col xxl={6} lg={12}>
                        <WidgetsSmall
                          label={'Active USERS'}
                          separator={''}
                          decimals={''}
                          end={allActiveUserCount}
                          bgcolor={'rgb(41, 156, 219)'}
                          icon={'bx bx-user-circle'}
                          percentageCount={allActiveUserPercentage}
                        />
                      </Col>
                      <Col xxl={6} lg={12}>
                        <WidgetsSmall
                          label={'InActive USERS'}
                          separator={''}
                          decimals={''}
                          end={allInActiveUserCount}
                          bgcolor={'rgb(240, 101, 72)'}
                          icon={'bx bx-user-circle'}
                          percentageCount={allInActiveUserPercentage}
                        />
                      </Col>
                      <Col xs={12}>
                        <UsersChart
                          allUsersCount={allUsersCount}
                          allAdminUserPercentage={allAdminUserPercentage}
                          allLandlordUserPercentage={allLandlordUserPercentage}
                          allRenterUserPercentage={allRenterUserPercentage}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xxl={6}>
                    <Row>
                      <Col>
                        <RevenueChart />
                      </Col>
                    </Row>
                  </Col>
                  <Col xxl={6}>
                    <Row>
                      <Col>
                        <PropertyMapLocations
                          allCountryCountPercentage={allCountryCountPercentage}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ResentBooking
                      setSpinFlag={setSpinFlag}
                      setResponse={setResponse}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            <RecentActivity />
          </Row>
        </Container>
        <ShowSpinner spinFlag={spinFlag} />
      </div>
    </React.Fragment>
  );
};

export default AdminDashboard;
