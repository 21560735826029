import React, { useEffect, useState, useMemo } from 'react';
import MonoConnect from '@mono.co/connect.js';
import { styled } from '@mui/material/styles';
import {
  Card,
  Typography,
  Button,
  CardContent,
  Divider,
  Box,
  Stack,
  Grid,
  Avatar,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  Rating,
  Radio,
  Checkbox,
  DialogActions,
  FormGroup,
  FormControlLabel,
  IconButton,
} from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import SvgColor from '../../../components/svg-color';
import credit_card from '../../../assets/icons/credit-card-02.svg';
import credit_card_refresh from '../../../assets/icons/credit-card-refresh.svg';
import message_square from '../../../assets/icons/message-square-02.svg';
import Creditcardmockup from '../../../assets/images/Creditcardmockup.png';
import Creditcardmockup1 from '../../../assets/images/Creditcardmockup-1.png';
import Creditcardmockup2 from '../../../assets/images/Creditcardmockup-2.png';
import ArrowIconLeft from '../../../assets/icons/ArrowIconLeft.svg';
import ArrowIconRight from '../../../assets/icons/ArrowIconRight.svg';
import { IMG_URL } from '../../../utils/constant';
import AddPayment from '../../../assets/icons/AddPayment.svg';
import { currencySeprator } from '../../../utils/common-function';
import {
  createRoom,
  getUnitToPay,
  manageRequest,
  createTransaction,
  verifyTransactionMonoPayment,
} from '../../../services/apiServices';
import NoDataImg from '../../../assets/images/ic_content.svg';
import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';
import Scrollbar from '../../../components/scrollbar';
import { DataNotAvailable } from '../../../components/data-not-found';
import { useResponsive } from '../../../hooks/useResponsive';
import { usePaystackPayment } from 'react-paystack';
import Slider from 'react-slick';
import { nairaSymbol } from '../../../utils/constant';
import { getPaymentCard } from '../../../services/apiServices';
import { async } from 'q';

const CreditcardLists = [
  {
    cardName: 'OLIVIA RHYE',
    cardPath: Creditcardmockup,
  },
  {
    cardName: 'OLIVIA RHYE 2',
    cardPath: Creditcardmockup1,
  },
  {
    cardName: 'OLIVIA RHYE 3',
    cardPath: Creditcardmockup2,
  },
];

const PriceTable = styled(Box)(({ theme }) => ({
  '& .MuiStack-root': {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 0 12px',
    '&:last-child': {
      padding: '10px 0 0',
    },
  },
}));
const StyledRating = styled(Rating)({
  marginTop: 'auto',
  fontSize: '15px !important',
  '& .MuiRating-iconEmpty': {
    color: '#D3D3DC',
  },
  '& .MuiRating-iconFilled': {
    color: '#FFBD3E',
  },
  '& .MuiRating-iconHover': {
    color: '#FFBD3E',
  },
});
const SelectRadio = styled(Radio)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: '14px',
  '&>span': { display: 'none' },
  '&.Mui-checked': {
    border: '5px solid #FF6645',
  },
}));
const CarouselDiv = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  '& .slick-slider': {
    height: '100%',
    width: '100%',
    '& .slick-list': {
      height: '100%',
      width: '100%',
      '& .slick-track': {
        height: '100%',
        '& .slick-slide': {
          '&>div': {
            height: '100%',
            width: '100%',
          },
        },
      },
    },
    '& .slick-arrow': {
      fontSize: '0',
      width: '20px',
      height: '20px',
      zIndex: '1',
      transition: '0.45s ease-in-out',
      borderRadius: '50%',
      opacity: '1',
      '&:before': {
        fontSize: '0',
      },
      '&.slick-prev': {
        background: `rgba(255,255,255,0.8) url(${ArrowIconLeft}) no-repeat center center / cover`,
        left: '5px',
      },
      '&.slick-next': {
        background: `rgba(255,255,255,0.8) url(${ArrowIconRight}) no-repeat center center / cover`,
        right: '5px',
      },
      '&.slick-disabled': {
        display: 'none !important',
      },
    },
    '&:hover': {
      '& .slick-arrow': {
        opacity: '1',
      },
    },
    '& .slick-dots': {
      bottom: '-20px',
      '& li': {
        margin: '0',
        '& button:before': {
          fontSize: '22px',
          color: '#fff',
        },
      },
    },
  },
}));
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
};
export const CurrentUnit = (props) => {
  let { setMyListRender, myListRender, loginInfo, setSpinFlag } = props;

  //link bank account
  // const monoConnect = useMemo(() => {
  //   const monoInstance = new MonoConnect({
  //     onClose: () => console.log('Widget closed'),
  //     onLoad: () => console.log('Widget loaded successfully'),
  //     onSuccess: (code) => {
  //       console.log('code', code);
  //       console.log(`Linked successfully: ${code}`);
  //     },
  //     key: 'test_pk_u0se4tef81hlis6gpsv9',
  //   });

  //   monoInstance.setup();

  //   return monoInstance;
  // }, []);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState();
  const [unit, setUnit] = useState([]);
  const [unitForPay, setUnitForPay] = useState();
  const [renderFlag, setRenderFlag] = useState(false);
  const isMobileMode = useResponsive('down', 'md');
  const [popupOpen, setPopupOpen] = useState(false);
  const [recurringChecked, setRecurringChecked] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [securityDeposit, setSecurityDeposit] = useState();
  const [vatTax, setVatTax] = useState();
  const [serviceFee, setServiceFee] = useState();
  const [propertyRent, setPropertyRent] = useState();
  const [cardList, setCardList] = useState([]);
  const [statusCode, setStatusCode] = useState();
  const [paymentType, setPaymentType] = useState();
  // console.log('paymentType', paymentType);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handlePopupOpen = async (unitData) => {
    try {
      let rent = unitData.rentCalculation;
      let serviceFeeForPay = (rent * 4.5) / 100;
      let vatForPay = (rent * 3) / 100;
      // let totalAmount = rent + serviceFeeForPay + vatForPay;

      unitData.duration === 'month'
        ? setSecurityDeposit((rent * 12 * 10) / 100)
        : unitData.duration === 'threeMonth'
        ? setSecurityDeposit((rent * 4 * 10) / 100)
        : unitData.duration === 'sixMonth'
        ? setSecurityDeposit((rent * 2 * 10) / 100)
        : setSecurityDeposit((rent * 10) / 100);
      setPropertyRent(rent);
      setVatTax(vatForPay);
      setServiceFee(serviceFeeForPay);
      setUnitForPay(unitData);
      setSpinFlag(true);
      let payload = { currentPage: 1 };
      let { data } = await getPaymentCard(payload);

      if (data.statusCode === 200) {
        setSpinFlag(false);
        setStatusCode(data.statusCode);

        if (data.cardList.length > 0) {
          setPopupOpen(true);
          setStatusCode(data.statusCode);
        } else {
          setMessage('You have to add your card first before paying');
          setOpen(true);
          setStatusCode(201);
        }
      } else {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&reference&path=${pathname}`);
    }
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
    setPaymentType('');
  };

  //pay with mono
  const createMonoConnect = () => {
    handlePopupClose();
    let totalAmount = propertyRent + vatTax + serviceFee + securityDeposit;
    const monoInstance = new MonoConnect({
      key: 'test_pk_u0se4tef81hlis6gpsv9',
      scope: 'payments',
      data: {
        type: 'onetime-debit', // one-time-debit || recurring-debit
        amount: Math.ceil(totalAmount * 100), // amount in kobo
        description: 'Payment for light bill',
      },
      onSuccess: async (chargeObject) => {
        console.log(`charged successfully`, chargeObject);
        handleMonoPayment(chargeObject.data);
      },
      onClose: (chargeObject) => {
        console.log(`close successfully`, chargeObject);
      },
    });

    monoInstance.setup();

    return monoInstance;
  };

  const handleButtonClick = () => {
    const monoConnect = createMonoConnect();
    monoConnect.open();
  };

  // const makeTransaction = async (result) => {
  //   try {
  //     let { transaction, reference } = result;
  //     let { landlordId, propertyId, unitId, rentCalculation, durationName } =
  //       unitForPay;
  //     let payload = {
  //       landlordId: landlordId,
  //       propertyId: propertyId,
  //       unitId: unitId,
  //       transactionId: transaction,
  //       referenceId: reference,
  //       totalAmount: totalAmountForPay,
  //       recurringPayment: recurringChecked,
  //       durationName: durationName,
  //       rentCalculation: rentCalculation,
  //     };
  //     let { data } = await createTransaction(payload);

  //   } catch (err) {
  //     console.log('err', err);
  //   }
  // };

  const handlePaystackPayment = async () => {
    try {
      // setPopupOpen(false);
      handlePopupClose();
      setSpinFlag(true);
      //call api for internal transaction with the help of authorization code
      let {
        landlordId,
        propertyId,
        unitId,
        _id,
        rentCalculation,
        durationName,
      } = unitForPay;

      let payload = {
        landlordId: landlordId,
        propertyId: propertyId,
        unitId: unitId,
        bookingId: _id,
        propertyRent: propertyRent,
        vatTax: vatTax,
        serviceFee: serviceFee,
        securityDeposit: securityDeposit,
        recurringPayment: recurringChecked,
        durationName: durationName,
        rentCalculation: rentCalculation,
      };
      let { data } = await createTransaction(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
        setRecurringChecked(false);
        setRenderFlag(!renderFlag);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
        setStatusCode(data.statusCode);
      }
      // initializePayment(onSuccess, onClose);
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const handleMonoPayment = async (transactionData) => {
    try {
      handlePopupClose(false);
      setSpinFlag(true);
      //call api for internal transaction with the help of authorization code
      let {
        landlordId,
        propertyId,
        unitId,
        _id,
        rentCalculation,
        durationName,
      } = unitForPay;

      let payload = {
        landlordId: landlordId,
        propertyId: propertyId,
        unitId: unitId,
        bookingId: _id,
        propertyRent: propertyRent,
        vatTax: vatTax,
        serviceFee: serviceFee,
        securityDeposit: securityDeposit,
        recurringPayment: recurringChecked,
        durationName: durationName,
        rentCalculation: rentCalculation,
        transactionData: transactionData,
      };
      // payload.transactionData = chargeObject.data;
      let { data } = await verifyTransactionMonoPayment(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
        setRecurringChecked(false);
        setRenderFlag(!renderFlag);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const fetchCardList = async () => {
    try {
      setSpinFlag(true);
      let payload = { currentPage: 1 };
      let { data } = await getPaymentCard(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setCardList(data.cardList);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  useEffect(() => {
    unitToPay();
    // fetchCardList();
  }, [renderFlag, currentPage]);

  const unitToPay = async () => {
    try {
      setSpinFlag(true);
      let payload = { currentPage: currentPage };
      const { data } = await getUnitToPay(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setUnit(data.unitsToPay);
        setNumberOfPages(data.noOfPages);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleMessageOpen = async (user) => {
    const payload = {
      userId: user[0]._id,
    };
    const { data } = await createRoom(payload);

    if (data.id) {
      let messageData = {
        userId: user[0]._id,
        firstname: user[0].firstname,
        lastname: user[0].lastname,
        profile: user[0].profile,
      };
      navigate(`/message?roomId=${data.id}`, { state: messageData });
    }
  };

  return (
    <>
      <Card>
        <CardContent>
          <Typography component="h3" variant="h3" sx={{ pb: 3 }}>
            Welcome {loginInfo.firstname}, This is your current unit
          </Typography>
          <Divider sx={{ mb: 1 }} />
          <Scrollbar style={{ maxHeight: '500px' }}>
            {unit.length !== 0 ? (
              <>
                {unit.map(
                  (
                    unitData,
                    // {
                    //   propertyData,
                    //   propertyImages,
                    //   rentCalculation,
                    //   status,
                    //   _id,
                    //   fromDate,
                    //   toDate,
                    //   landlordData,
                    //   ...unitData
                    // },
                    index
                  ) => {
                    return (
                      <>
                        <Box key={index} sx={{ pt: 3 }}>
                          <Stack flexDirection="row" flexWrap="wrap">
                            <Box sx={{ width: { md: '15%', xs: '100%' } }}>
                              <Link
                                to={
                                  '/property-details/' +
                                  unitData.propertyData[0]._id
                                }
                              >
                                <Avatar
                                  sx={{
                                    // minHeight: '165px',
                                    width: '100%',
                                    height: 'auto',
                                    display: 'block',
                                    borderRadius: '14px',
                                    aspectRatio: 1,
                                  }}
                                  src={`${IMG_URL}${unitData.propertyImages[0].propertyImage}`}
                                />
                              </Link>
                            </Box>
                            <Box
                              sx={{
                                width: { md: '85%', xs: '100%' },
                                pl: { md: 3 },
                                mt: { md: 0, xs: 2 },
                              }}
                            >
                              <Grid container sx={{ mb: 2 }} spacing={2}>
                                <Grid item md={5} sm={12} xs={12}>
                                  <Typography
                                    variant="h4"
                                    sx={{ fontWeight: '500' }}
                                    noWrap={true}
                                  >
                                    {unitData.propertyData[0].title}
                                  </Typography>
                                  <Typography variant="body2" noWrap={true}>
                                    {unitData.propertyData[0].address}
                                  </Typography>
                                </Grid>
                                <Grid item md={2} sm={4} xs={12}>
                                  <Typography
                                    variant="h6"
                                    sx={{ mb: { lg: 1 } }}
                                  >
                                    Move-in
                                  </Typography>
                                  <Typography variant="body2">
                                    {unitData.fromDate.slice(0, 10)}
                                  </Typography>
                                </Grid>
                                <Grid item md={2} sm={4} xs={12}>
                                  <Typography
                                    variant="h6"
                                    sx={{ mb: { lg: 1 } }}
                                  >
                                    Move-out
                                  </Typography>
                                  <Typography variant="body2">
                                    {unitData.toDate.slice(0, 10)}
                                  </Typography>
                                </Grid>
                                <Grid item md={3} sm={4} xs={12}>
                                  <Typography
                                    variant="h6"
                                    sx={{ mb: { lg: 1 } }}
                                  >
                                    Total amount
                                  </Typography>
                                  <Typography variant="body2">
                                    {nairaSymbol}
                                    {currencySeprator(
                                      Math.trunc(unitData.rentCalculation)
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Box sx={{ mt: 4 }}>
                                {/* <Typography
                                  variant="subtitle1"
                                  sx={{ mb: 1.5 }}
                                >
                                  View Transaction history
                                </Typography> */}
                                <Grid container spacing={2}>
                                  <Grid item md={4} sm={6} xs={12}>
                                    <Button
                                      onClick={() =>
                                        handleMessageOpen(unitData.landlordData)
                                      }
                                      variant="text"
                                      color="primary"
                                      size="large"
                                      sx={{
                                        bgcolor: '#FAFAFA',
                                        p: { md: 2, xs: 1.5 },
                                      }}
                                      fullWidth
                                    >
                                      <SvgColor
                                        sx={{ mr: { xl: 2, xs: 1 } }}
                                        src={message_square}
                                      />
                                      Message landlord
                                    </Button>
                                  </Grid>
                                  <Grid item md={4} sm={6} xs={12}>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      size="large"
                                      sx={{ p: { md: 2, xs: 1.5 } }}
                                      fullWidth
                                      component={Link}
                                      to="/payment"
                                    >
                                      <SvgColor
                                        sx={{ mr: { xl: 2, xs: 1 } }}
                                        src={credit_card_refresh}
                                      />
                                      Add New Card
                                    </Button>
                                  </Grid>
                                  <Grid item md={3} sm={6} xs={12}>
                                    {unitData.status === 'paid' ? (
                                      <Button
                                        variant="contained"
                                        color="success"
                                        size="large"
                                        sx={{ p: { md: 2, xs: 1.5 } }}
                                        disabled
                                        fullWidth
                                      >
                                        <SvgColor
                                          sx={{ mr: { xl: 2, xs: 1 } }}
                                          src={credit_card}
                                        />
                                        Paid
                                      </Button>
                                    ) : (
                                      <Button
                                        // component={Link}
                                        // to="/payment"
                                        variant="contained"
                                        color="success"
                                        size="large"
                                        sx={{
                                          bgcolor: '#1FAB71',
                                          color: '#fff',
                                          p: { md: 2, xs: 1.5 },
                                        }}
                                        fullWidth
                                        onClick={() =>
                                          handlePopupOpen(unitData)
                                        }
                                        // onClick={handlePay}
                                        // onClick={() =>
                                        //   handlePayment(_id, 'paid')
                                        // }
                                      >
                                        <SvgColor
                                          sx={{ mr: { xl: 2, xs: 1 } }}
                                          src={credit_card}
                                        />
                                        Pay now
                                      </Button>
                                    )}
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Stack>
                        </Box>
                      </>
                    );
                  }
                )}
              </>
            ) : (
              <DataNotAvailable title="You don't have Current unit" />
            )}
          </Scrollbar>
        </CardContent>
        {unit.length !== 0 && (
          <Pagination
            shape="rounded"
            count={numberOfPages}
            siblingCount={0}
            boundaryCount={2}
            onChange={handlePageChange}
            sx={{
              py: 2,
              '& .MuiPagination-ul': {
                justifyContent: 'center',
              },
            }}
          />
        )}
      </Card>
      <DialogPopup
        open={open}
        setOpen={setOpen}
        message={message}
        url=""
        size="xs"
        statusCode={statusCode}
      />
      <Dialog
        onClose={handlePopupClose}
        aria-labelledby="customized-dialog-title"
        open={popupOpen}
        fullWidth
        maxWidth={'sm'}
      >
        <IconButton
          aria-label="close"
          onClick={handlePopupClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon icon="ic:round-close" />
        </IconButton>
        <DialogTitle
          sx={{ textAlign: 'center' }}
          id="customized-dialog-title"
          onClose={handlePopupClose}
        >
          Choose Payment Methods
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item md={12} xs={12}>
              <Box>
                <Stack
                  sx={{
                    mb: 3,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  {/* <Typography variant="h6">Select Card</Typography>
                  <Typography variant="subtitle2">
                    <Button
                      variant="outlined"
                      size=""
                      component={Link}
                      to="/payment"
                      sx={{ borderRadius: '10px' }}
                    >
                      <SvgColor
                        sx={{ width: '18px', height: '18px', mr: 1 }}
                        src={AddPayment}
                        alt={AddPayment}
                      />{' '}
                      Add new card
                    </Button>
                  </Typography> */}
                  <Typography variant="h6">Select Payment Method</Typography>
                  <Typography variant="subtitle2">
                    <Button
                      variant="outlined"
                      size=""
                      // component={Link}
                      // to="/payment"
                      onClick={() => setPaymentType('paystack')}
                      sx={{ borderRadius: '10px' }}
                    >
                      Paystack
                    </Button>
                    <Button
                      variant="outlined"
                      size=""
                      // component={Link}
                      // to="/payment"
                      onClick={() => setPaymentType('mono')}
                      sx={{ borderRadius: '10px' }}
                    >
                      Mono payment
                    </Button>
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    mb: 2,
                    '& .owl-dots .owl-dot': {
                      '& span': {
                        background: '#EFEFEF !important',
                      },
                      '&.active': {
                        '& span': {
                          background: '#FEAE01 !important',
                        },
                      },
                    },
                  }}
                >
                  {/* done after tomorrow */}
                  {/* <CarouselDiv>
                    <Slider {...settings}>
                      {CreditcardLists.map((val, index) => (
                        <Box key={index} sx={{ p: 1 }}>
                          <Box
                            sx={{
                              position: 'relative',
                              borderRadius: '14px',
                              overflow: 'hidden',
                            }}
                          >
                            <img
                              style={{ width: '100%' }}
                              src={val.cardPath}
                              alt={val.cardPath}
                            />
                            <SelectRadio
                              disableRipple
                              checked={selectedValue === val.cardName}
                              onChange={handleChange}
                              value={val.cardName}
                              name="radio-buttons"
                            />
                          </Box>
                        </Box>
                      ))}
                    </Slider>
                  </CarouselDiv> */}
                </Box>
              </Box>
            </Grid>
            <Grid item md={12} xs={12}>
              <Box>
                <Box
                  sx={{
                    background: 'rgb(244, 246, 248)',
                    padding: '16px',
                    borderRadius: '14px',
                    border: '1px solid rgba(145, 158, 171, 0.16)',
                    mb: 3,
                  }}
                >
                  <Stack flexDirection="row" flexWrap="wrap">
                    <Avatar
                      sx={{
                        borderRadius: '14px',
                        width: '100px',
                        height: '100px',
                        aspectRatio: '1',
                      }}
                      src={
                        unitForPay &&
                        `${IMG_URL}${unitForPay.propertyImages[0].propertyImage}`
                      }
                    />
                    <Stack
                      sx={{ pl: 2, width: 'calc(100% - 100px)', pb: '10px' }}
                    >
                      <Typography variant="h6" noWrap>
                        {unitForPay && unitForPay.propertyData[0].title}
                      </Typography>
                      <Typography variant="body2" noWrap>
                        {unitForPay && unitForPay.propertyData[0].address}
                      </Typography>
                      <Typography variant="body2" noWrap>
                        Unis Name :{' '}
                        <b> {unitForPay && unitForPay.unit[0].unitName}</b>
                      </Typography>
                      <StyledRating
                        defaultValue={0}
                        value={unitForPay && unitForPay.propertyData[0].rating}
                        readOnly
                        icon={<Icon icon="ph:star-fill" />}
                        emptyIcon={<Icon icon="ph:star-fill" />}
                        sx={{
                          fontSize: {
                            xs: '1rem',
                            md: '1rem',
                            lg: '1.125rem',
                            xl: '1.25rem',
                          },
                        }}
                      />
                    </Stack>
                  </Stack>
                </Box>
                <Box>
                  <Stack
                    sx={{
                      mb: 3,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    {paymentType === 'paystack' ? (
                      <>
                        <Typography variant="h6">Price details</Typography>
                        <Typography variant="subtitle2">
                          <FormControlLabel
                            sx={{ m: 0 }}
                            control={
                              <Checkbox
                                checked={recurringChecked}
                                onChange={(e) => {
                                  setRecurringChecked(e.target.checked);
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}
                                sx={{ mr: 1 }}
                              />
                            }
                            label="Recurring payment"
                          />
                        </Typography>
                      </>
                    ) : (
                      <>
                        {paymentType === 'mono' ? (
                          <>
                            <Typography variant="h6">Price details</Typography>
                          </>
                        ) : null}
                      </>
                    )}
                  </Stack>
                  <PriceTable>
                    <Stack>
                      <Typography variant="subtitle2">Rent</Typography>
                      <Typography variant="body1" align="right">
                        {/* service fee 10% */}
                        {nairaSymbol}
                        {currencySeprator(
                          Math.trunc(unitForPay && unitForPay.rentCalculation)
                        )}
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography variant="subtitle2">Service Fee</Typography>
                      <Typography variant="body1" align="right">
                        {/* service fee 4.5% */}
                        {nairaSymbol}
                        {currencySeprator(Math.trunc(serviceFee))}
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography variant="subtitle2">
                        One-time security deposite
                      </Typography>
                      <Typography variant="body1" align="right">
                        {nairaSymbol}
                        {currencySeprator(Math.trunc(securityDeposit))}
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography variant="subtitle2">VAT</Typography>
                      <Typography variant="body1" align="right">
                        {/* service fee 3% */}
                        {nairaSymbol}
                        {currencySeprator(Math.trunc(vatTax))}
                      </Typography>
                    </Stack>
                    <Divider />
                    <Stack>
                      <Typography variant="h6">Total</Typography>
                      <Typography variant="h6" align="right">
                        {nairaSymbol}
                        {currencySeprator(
                          Math.trunc(
                            propertyRent + vatTax + serviceFee + securityDeposit
                          )
                        )}
                        {/* {unitForPay &&
                          unitForPay.rentCalculation +
                            (unitForPay.rentCalculation * 4.5) / 100 +
                            (unitForPay.rentCalculation * 10) / 100 +
                            (unitForPay.rentCalculation * 3) / 100} */}
                      </Typography>
                    </Stack>
                  </PriceTable>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ flexDirection: 'row', px: 3, pt: 1.5, pb: 4 }}>
          {paymentType === 'paystack' ? (
            <Button
              // variant="softSuccess"
              size="large"
              fullWidth
              variant="contained"
              color="success"
              onClick={() => handlePaystackPayment()}
            >
              <SvgColor sx={{ mr: { xl: 2, xs: 1 } }} src={credit_card} />
              pay with paystack
            </Button>
          ) : (
            <>
              {paymentType === 'mono' ? (
                <Button
                  // variant="softSuccess"
                  size="large"
                  fullWidth
                  variant="contained"
                  color="success"
                  // onClick={() => handleMonoPayment()}
                  // onClick={() => monoConnect.open()}
                  onClick={handleButtonClick}
                >
                  <SvgColor sx={{ mr: { xl: 2, xs: 1 } }} src={credit_card} />
                  pay with mono
                </Button>
              ) : (
                <Button
                  // variant="softSuccess"
                  size="large"
                  fullWidth
                  variant="contained"
                  color="success"
                  disabled
                  // onClick={() => handlePaystackPayment()}
                >
                  <SvgColor sx={{ mr: { xl: 2, xs: 1 } }} src={credit_card} />
                  pay
                </Button>
              )}
            </>
          )}

          <Button
            fullWidth
            // variant="softError"
            variant="outlined"
            color="primary"
            sx={{
              bgcolor: '#FAFAFA',
            }}
            size="large"
            onClick={handlePopupClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
