import * as Yup from 'yup';
import { REGEX, ADMIN_REGEX } from '../regex';
import { AUTH_MSG } from '../messages/adminError';

//validation for login
export const login = Yup.object({
  email: Yup.string().email(AUTH_MSG.VALID_EMAIL).required(AUTH_MSG.EMAIL),
  password: Yup.string()
    .matches(REGEX.PASSWORD, AUTH_MSG.VALID_PASSWORD)
    .required(AUTH_MSG.PASWORD),
});
//validation for forgot password
export const forgotPasswordValidation = Yup.object({
  email: Yup.string().email(AUTH_MSG.VALID_EMAIL).required(AUTH_MSG.EMAIL),
});
//validation for add admin
export const addAdminUser = Yup.object().shape({
  firstname: Yup.string()
    .matches(REGEX.NAME, AUTH_MSG.VALID_NAME)
    .max(20, AUTH_MSG.MAX_NAME)
    .required(AUTH_MSG.FIRST_NAME),
  lastname: Yup.string()
    .matches(REGEX.NAME, AUTH_MSG.VALID_NAME)
    .max(20, AUTH_MSG.MAX_NAME)
    .required(AUTH_MSG.LAST_NAME),
  email: Yup.string()
    .matches(ADMIN_REGEX.EMAIL, `${AUTH_MSG.VALID_EMAIL}`)
    .email(AUTH_MSG.VALID_EMAIL)
    .required(AUTH_MSG.EMAIL),
  password: Yup.string()
    .matches(REGEX.PASSWORD, AUTH_MSG.VALID_PASSWORD)
    .required(AUTH_MSG.PASWORD),
  confirmpassword: Yup.string()
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], AUTH_MSG.SAME_PASSWORD),
    })
    .required(AUTH_MSG.CONFIRM_PASWORD),
  dob: Yup.date()
    .required(AUTH_MSG.DOB)
    .max(new Date(), AUTH_MSG.CHECH_DATE_FUTURE)
    .test(AUTH_MSG.CANNOT_EMPTY, AUTH_MSG.FIFTEEN_YEARS_AGO, (value) => {
      const fifteenYearsAgo = new Date();
      fifteenYearsAgo.setFullYear(fifteenYearsAgo.getFullYear() - 15);
      return value <= fifteenYearsAgo;
    }),
  mobile: Yup.string()
    .max(15, AUTH_MSG.VALID_PHONE)
    .min(7, AUTH_MSG.VALID_PHONE)
    .required(AUTH_MSG.PHONE_NUMBER),
  role: Yup.string().required(AUTH_MSG.ROLE),
});

//validation for edit admin
export const editAdminUser = Yup.object().shape({
  firstname: Yup.string()
    .matches(REGEX.NAME, AUTH_MSG.VALID_NAME)
    .max(20, AUTH_MSG.MAX_NAME)
    .required(AUTH_MSG.FIRST_NAME),
  lastname: Yup.string()
    .matches(REGEX.NAME, AUTH_MSG.VALID_NAME)
    .max(20, AUTH_MSG.MAX_NAME)
    .required(AUTH_MSG.LAST_NAME),
  dob: Yup.date()
    .required(AUTH_MSG.DOB)
    .max(new Date(), AUTH_MSG.CHECH_DATE_FUTURE)
    .test(AUTH_MSG.CANNOT_EMPTY, AUTH_MSG.FIFTEEN_YEARS_AGO, (value) => {
      const fifteenYearsAgo = new Date();
      fifteenYearsAgo.setFullYear(fifteenYearsAgo.getFullYear() - 15);
      return value <= fifteenYearsAgo;
    }),
  email: Yup.string()
    .matches(ADMIN_REGEX.EMAIL, `${AUTH_MSG.VALID_EMAIL}`)
    .email(AUTH_MSG.VALID_EMAIL)
    .required(AUTH_MSG.EMAIL),
});

//superadmin profile update
export const profileUpadte = Yup.object().shape({
  firstname: Yup.string()
    .matches(REGEX.NAME, AUTH_MSG.VALID_NAME)
    .max(20, AUTH_MSG.MAX_NAME)
    .required(AUTH_MSG.FIRST_NAME),
  lastname: Yup.string()
    .matches(REGEX.NAME, AUTH_MSG.VALID_NAME)
    .max(20, AUTH_MSG.MAX_NAME)
    .required(AUTH_MSG.LAST_NAME),
  dob: Yup.date()
    .required(AUTH_MSG.DOB)
    .max(new Date(), AUTH_MSG.CHECH_DATE_FUTURE)
    .test(AUTH_MSG.CANNOT_EMPTY, AUTH_MSG.FIFTEEN_YEARS_AGO, (value) => {
      const fifteenYearsAgo = new Date();
      fifteenYearsAgo.setFullYear(fifteenYearsAgo.getFullYear() - 15);
      return value <= fifteenYearsAgo;
    }),
});

//password change for superadmin
export const passwordUpdate = Yup.object({
  currentPassword: Yup.string()
    .matches(REGEX.PASSWORD, AUTH_MSG.VALID_PASSWORD)
    .required(AUTH_MSG.PASWORD),
  password: Yup.string()
    .matches(REGEX.PASSWORD, AUTH_MSG.VALID_PASSWORD)
    .required(AUTH_MSG.PASWORD),
  confirmpassword: Yup.string()
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], AUTH_MSG.SAME_PASSWORD),
    })
    .required(AUTH_MSG.CONFIRM_PASWORD),
});

//contact us
export const contactUsUpadte = Yup.object({
  heading: Yup.string()
    .max(60, AUTH_MSG.MAX_INFO_SIZE)
    .required(AUTH_MSG.HEADING),
  info: Yup.string().max(60, AUTH_MSG.MAX_INFO_SIZE).required(AUTH_MSG.INFO),
  subHeading: Yup.string()
    .max(60, AUTH_MSG.MAX_INFO_SIZE)
    .required(AUTH_MSG.SUB_HEADING),
});

//validate email-configure
export const emailConfigure = Yup.object({
  email: Yup.string().required(AUTH_MSG.EMAIL),
  password: Yup.string().required(AUTH_MSG.PASWORD),
  confirmpassword: Yup.string()
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], AUTH_MSG.SAME_PASSWORD),
    })
    .required(AUTH_MSG.CONFIRM_PASWORD),
});

//validate cms-crud-validation
export const cmsCrudValidation = Yup.object({
  bannerHeading: Yup.string().required(AUTH_MSG.HEADING),
});

//validate social-media link
export const socialMediaLink = Yup.object({
  link1: Yup.string().required(AUTH_MSG.SOCIAL_LINK),
  link2: Yup.string().required(AUTH_MSG.SOCIAL_LINK),
  link3: Yup.string().required(AUTH_MSG.SOCIAL_LINK),
  link4: Yup.string().required(AUTH_MSG.SOCIAL_LINK),
});
