import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Box,
  Container,
  Typography,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { PropertyCard } from '../../components/property-card';
import Intersect from '../../assets/images/Intersect.png';
import ArrowIconLeft from '../../assets/icons/ArrowIconLeft.svg';
import ArrowIconRight from '../../assets/icons/ArrowIconRight.svg';
import Slider from 'react-slick';
import { getPopularSpaces } from '../../services/apiServices';

import { useAuthStore } from '../../redux/features/authSlice';
import { DialogPopup } from '../../components/dialog-popup/DialogPopup';

const StylePopular = styled(Box)(({ theme }) => ({
  background: `#FAFAFA url(${Intersect}) no-repeat center center / 100% 60%`,
  padding: '96px 0 50px',
  marginTop: '-67px',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    padding: '72px 0 56px',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '0',
    padding: '40px 0',
  },
}));
const BannerLeftMain = styled(Box)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
}));
const BannerLeftText = styled(Box)(({ theme }) => ({
  marginBottom: '20px',
  [theme.breakpoints.down('md')]: {
    marginBottom: '20px',
  },
}));
const BannerLeft = styled(Box)(({ theme }) => ({
  paddingRight: '100px',
  [theme.breakpoints.down('xl')]: {
    paddingRight: '80px',
  },
  [theme.breakpoints.down('lg')]: {
    paddingRight: '30px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '0',
  },
  '& .MuiTypography-h2': {
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
      marginBottom: '20px',
    },
  },
  '& .MuiTypography-h6': {
    [theme.breakpoints.down('xl')]: {
      fontSize: '1.0625rem',
    },
  },
}));
const SliderArrows = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 15,
  marginTop: 30,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  '& .slick-arrow': {
    fontSize: '0',
    width: '40px',
    height: '40px',
    transform: 'inherit',
    position: 'static',
    display: 'block',
    '&:before': {
      fontSize: '0',
    },
    '&.slick-prev': {
      background: `url(${ArrowIconLeft}) no-repeat center center / cover`,
    },
    '&.slick-next': {
      background: `url(${ArrowIconRight}) no-repeat center center / cover`,
    },
    '&.slick-disabled': {
      opacity: '0.5',
    },
  },
}));
const CarouselDiv = styled(Box)(({ theme }) => ({
  // width: '1000px',
  [theme.breakpoints.up('md')]: {
    width: '800px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '1000px',
  },
  '&>.slick-slider': {
    '&>.slick-list': {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        overflow: 'inherit',
        maxWidth: '90%',
      },
    },
    '& .slick-dots': {
      bottom: '0',
    },
    '& .slick-slide': {
      transition: '0.45s ease-in-out',
      // padding: '0 0.7% 3%',
      '&.slick-current': {
        // padding: '1.29% 0.7% 1.2%',
        '& .sliderItem': {
          // transform: 'scale(1.151)',
          transform: 'scale(1)',
        },
      },
      '& .sliderItem': {
        transition: '0.45s ease-in-out',
        transform: 'scale(0.85)',
        transformOrigin: '0 0',
        [theme.breakpoints.down('md')]: {
          transform: 'scale(1)',
        },
        '& .MuiCard-root': {
          [theme.breakpoints.up('md')]: {
            width: '468px',
          },
        },
      },
    },
  },
}));
const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: false,
  dots: false,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
        arrows: false,
        dots: true,
        centerMode: false,
      },
    },
  ],
};
export const PopularSpacesSection = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let { loginInfo } = useAuthStore();
  let { homeContents, setSpinFlag, exploreRentals } = props;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [popularSpaces, setPopularSpaces] = useState([]);
  const [renderFlag, setRenderFlag] = useState(false);
  const [statusCode, setStatusCode] = useState();
  const slider = useRef(null);

  const fetchPopularSpaces = async () => {
    try {
      setSpinFlag(true);
      let user_id = loginInfo ? loginInfo._id : null;
      let payload = { user_id: user_id };
      const { data } = await getPopularSpaces(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setPopularSpaces(data.popularSpaces);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };
  useEffect(() => {
    fetchPopularSpaces();
  }, [renderFlag]);
  return (
    <>
      <DialogPopup
        open={open}
        setOpen={setOpen}
        message={message}
        size="xs"
        statusCode={statusCode}
      />
      <StylePopular>
        <Container
          fixed={true}
          sx={{
            maxWidth: {
              xl: '1326px',
            },
          }}
        >
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <BannerLeftMain>
                <BannerLeft>
                  <BannerLeftText>
                    {exploreRentals && exploreRentals.length > 0 && (
                      <>
                        <Typography
                          variant="button"
                          component="p"
                          sx={{ mb: 2, color: 'secondary.main' }}
                        >
                          {exploreRentals[0].subTitle}
                        </Typography>
                        <Typography
                          variant="h2"
                          sx={{ mb: 4, color: 'primary.main' }}
                        >
                          {exploreRentals[0].title}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: 'normal', color: 'text.secondary' }}
                        >
                          {exploreRentals[0].description}
                        </Typography>
                      </>
                    )}
                    {popularSpaces.length > 2 && (
                      <SliderArrows>
                        <button
                          className="slick-arrow slick-prev"
                          style={{ display: 'block' }}
                          onClick={() => slider?.current?.slickPrev()}
                        >
                          Prev
                        </button>
                        <button
                          className="slick-arrow slick-next"
                          style={{ display: 'block' }}
                          onClick={() => slider?.current?.slickNext()}
                        >
                          Next
                        </button>
                      </SliderArrows>
                    )}
                  </BannerLeftText>
                </BannerLeft>
              </BannerLeftMain>
            </Grid>
            <Grid item md={6} xs={12}>
              <CarouselDiv>
                {popularSpaces.length > 1 ? (
                  <Slider {...settings} ref={slider}>
                    {popularSpaces.map((value, index) => (
                      <Box
                        key={index}
                        sx={{
                          pl: { lg: 2, xs: 0 },
                          pr: { lg: 2, xs: 4 },
                          pb: { lg: 0, xs: 4 },
                        }}
                        className="sliderItem"
                      >
                        <PropertyCard
                          renderFlag={renderFlag}
                          setRenderFlag={setRenderFlag}
                          propertyValues={value}
                        />
                      </Box>
                    ))}
                  </Slider>
                ) : (
                  <>
                    {popularSpaces.map((value, index) => (
                      <Box
                        key={index}
                        sx={{
                          pl: { lg: 2, xs: 0 },
                          pr: { lg: 2, xs: 4 },
                          pb: { lg: 0, xs: 4 },
                        }}
                        className="sliderItem"
                      >
                        <PropertyCard
                          renderFlag={renderFlag}
                          setRenderFlag={setRenderFlag}
                          propertyValues={value}
                        />
                      </Box>
                    ))}
                  </>
                )}
              </CarouselDiv>
            </Grid>
          </Grid>
        </Container>
      </StylePopular>
    </>
  );
};
