import { styled, Box } from '@mui/material';

export const RightImg = styled(Box)(({ theme }) => ({
  height: '100%',
  img: {
    width: '100%',
    display: 'block',
    minHeight: '100%',
    height: 'calc(100svh - 94px)',
    objectFit: 'cover',
    borderRadius: '49px 0 0 49px',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      aspectRatio: '1.875',
      borderRadius: '0',
    },
  },
}));
export const BannerLeftMain = styled(Box)(({ theme }) => ({
  maxWidth: '647px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '0 24px',
  justifyContent: 'space-between',
  [theme.breakpoints.down('xl')]: {
    maxWidth: '590px',
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: '450px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 16px',
  },
}));
export const LeftSection = styled(Box)(({ theme }) => ({
  width: '400px',
  margin: '0 auto',
  padding: '50px 0',
  [theme.breakpoints.down('md')]: {
    padding: '40px 0 40px',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '400px',
    width: '100%',
  },
}));
export const BrandListBox = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingBottom: '40px',
  '& .MuiListItem-root': {
    justifyContent: 'center',
  },
}));
