import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BannerSection } from './BannerSection';
import { FavouritePlaceSection } from './FavouritePlaceSection';
import { HowItWorksSection } from './HowItWorksSection';
import { UnlimitedListingSection } from './UnlimitedListingSection';
import { BestDealSection } from './BestDealSection';
import {
  getBecomeHostContent,
  getCmsContent,
} from '../../services/apiServices';
import { Backdrop, CircularProgress } from '@mui/material';
import { DialogPopup } from '../../components/dialog-popup/DialogPopup';

export const BecomeAHost = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [becomeHostContents, setbecomeHostContents] = useState();
  const [becomeHostList, setbecomeHostList] = useState([]);
  const [bannerSection, setBannerSection] = useState([]);
  const [rentFavouritePlace, setRentFavouritePlace] = useState([]);
  const [convenience, setConvenience] = useState([]);
  const [listConfidently, setListConfidently] = useState([]);
  const [howItWorks, setHowItWorks] = useState([]);
  const [spinFlag, setSpinFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [statusCode, setStatusCode] = useState();
  const becomeHostContent = async () => {
    try {
      const { data } = await getBecomeHostContent();
      if (data.statusCode === 200) {
        setbecomeHostList(data.becomeHostdata.howWorksList);
        setbecomeHostContents(data.becomeHostdata.becomeHostContent);
      } else {
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };
  const fetchBecomeHostContent = async () => {
    try {
      setSpinFlag(true);
      console.log('pathname', pathname);
      const payload = { type: pathname };
      const { data } = await getCmsContent(payload);
      if (data.statusCode === 200) {
        setBannerSection(
          data?.cmsContent?.filter((item) => item?.section === 'bannerSection')
        );
        setRentFavouritePlace(
          data?.cmsContent?.filter(
            (item) => item?.section === 'rentFavouritePlace'
          )
        );
        setConvenience(
          data?.cmsContent?.filter((item) => item?.section === 'convenience')
        );
        setListConfidently(
          data?.cmsContent?.filter(
            (item) => item?.section === 'listConfidently'
          )
        );
        setHowItWorks(
          data?.cmsContent?.filter((item) => item?.section === 'howItWorks')
        );
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    becomeHostContent();
    fetchBecomeHostContent();
  }, [pathname]);
  return (
    <>
      <BannerSection
        becomeHostContents={becomeHostContents}
        bannerSection={bannerSection}
      />
      <HowItWorksSection
        becomeHostContents={becomeHostContents}
        becomeHostList={becomeHostList}
        howItWorks={howItWorks}
      />
      <FavouritePlaceSection
        becomeHostContents={becomeHostContents}
        rentFavouritePlace={rentFavouritePlace}
      />
      <UnlimitedListingSection
        becomeHostContents={becomeHostContents}
        convenience={convenience}
      />
      <BestDealSection
        becomeHostContents={becomeHostContents}
        listConfidently={listConfidently}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogPopup
        handleClose={handleClose}
        open={open}
        message={message}
        setOpen={setOpen}
        statusCode={statusCode}
        size="xs"
      />
    </>
  );
};
