import { createTheme, filledInputClasses } from '@mui/material';
// ----------------------------------------------------------------------
const muiTheme = createTheme();
export default function TextField(theme) {
  const { palette } = theme;
  return {
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          borderRadius: Number(theme.shape.borderRadius) * 3.5,
          borderStyle: 'solid',
          borderWidth: 1,
          overflow: 'hidden',
          borderColor: palette.grey[400],
          transition: muiTheme.transitions.create([
            'border-color',
            'box-shadow',
          ]),
          '&:hover': {
            backgroundColor: palette.grey[0],
            borderColor: palette.secondary.main,
          },
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
          [`&.${filledInputClasses.disabled}`]: {
            backgroundColor: 'transparent',
          },
          [`&.${filledInputClasses.focused}`]: {
            backgroundColor: palette.secondary.lighter,
            borderColor: palette.secondary.main,
          },
          [`&.${filledInputClasses.error}`]: {
            borderColor: palette.error.main,
          },
        },
        input: {
          fontSize: 14,
          fontWeight: 500,
          lineHeight: '24px',
          color: palette.primary.main,
          '&.MuiSelect-select': {
            height: '1.4375em !important',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 600,
          color: palette.grey[500],
          // [`&.${inputLabelClasses.filled}`]: {
          //   transform: 'translate(12px, 18px) scale(1)',
          // },
          // [`&.${inputLabelClasses.shrink}`]: {
          //   [`&.${inputLabelClasses.standard}`]: {
          //     transform: 'translate(0, -1.5px) scale(0.85)',
          //   },
          //   [`&.${inputLabelClasses.filled}`]: {
          //     transform: 'translate(12px, 6px) scale(0.85)',
          //   },
          //   [`&.${inputLabelClasses.outlined}`]: {
          //     transform: 'translate(14px, -9px) scale(0.85)',
          //   },
          // },
        },
      },
    },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiInputBase-root': {
    //         borderRadius: Number(theme.shape.borderRadius) * 3.5,
    //         backgroundColor: '#fff',
    //       },
    //       '& .MuiInputBase-input': {
    //         fontWeight: '400',
    //         fontSize: '14px',
    //         color: '#323232',
    //       },
    //       '& .MuiInputLabel-root': {
    //         fontWeight: '600',
    //         fontSize: '14px',
    //         color: '#9CA3AF',
    //         '&.Mui-focused, &.MuiFormLabel-filled': {
    //           transform: 'translate(14px, 2px) scale(1)',
    //           fontWeight: '400',
    //           fontSize: '12px',
    //         },
    //       },
    //       '& .MuiOutlinedInput-root': {
    //         // borderRadius: Number(theme.shape.borderRadius) * 3.5,,
    //         '&.Mui-focused': {
    //           backgroundColor: '#FFF9EB',
    //           borderColor: '#FEAE01', // Custom border color on focus
    //           '& fieldset': {
    //             borderColor: '#FEAE01',
    //           },
    //         },
    //         '& fieldset': {
    //           borderColor: '#D1D5DB', // Custom default border color
    //         },
    //         '&:hover fieldset': {
    //           borderColor: '#D1D5DB', // Custom border color on hover
    //         },
    //         '&.Mui-focused fieldset': {
    //           borderWidth: 1, // Custom border width on focus
    //         },
    //       },
    //       '& .MuiOutlinedInput-root:hover': {
    //         '& fieldset': {
    //           borderColor: '#FEAE01',
    //         },
    //       },
    //       '& .MuiOutlinedInput-notchedOutline': {
    //         '& span': {
    //           display: 'none',
    //         },
    //       },
    //     },
    //   },
    // },
  };
}
