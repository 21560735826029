import React from 'react';
import {
  Box,
  Typography,
  Avatar,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import { addToFavorite } from '../../services/apiServices';
import { IMG_URL, nairaSymbol } from '../../utils/constant';
import { currencySeprator } from '../../utils/common-function';
import { useResponsive } from '../../hooks/useResponsive';
import { useNavigate } from 'react-router-dom';

const FavoriteIconButton = styled(Box)(({ theme }) => ({
  zIndex: 1,
  '& .MuiCheckbox-root': {
    border: 'none !important',
    width: 'auto',
    height: 'auto',
    padding: 8,
    borderRadius: '50%',
    color: '#FF6645',
    '& > *': {
      fontSize: '24px',
    },
    '&.Mui-checked': {
      color: '#FF6645',
    },
  },
}));

export const UnitCard = (props) => {
  console.log('UnitCard runnnnnnnnnnnnnnnnnn');
  const navigate = useNavigate();
  let { favHistoryData, renderFlag, setRenderFlag } = props;
  let { propertyImages, propertyPrice, propertyData, ownerData, isFavorite } =
    favHistoryData;
  let { _id, title, address } = propertyData ? propertyData[0] : null;
  const isMobileMode = useResponsive('down', 'sm');
  const handleFav = async (event) => {
    try {
      let payload = {
        propertyId: _id,
        flag: event.target.checked,
      };
      let { data } = await addToFavorite(payload);
      if (data.statusCode === 200) {
        setRenderFlag(!renderFlag);
      } else {
        setOpen(true);
        setMessage(data.message);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };
  return (
    <>
      <ListItemButton
        to={'/property-details/' + _id}
        sx={{
          p: 2,
          borderRadius: '14px',
          border: '1px solid #F1F1F1',
          flexWrap: 'wrap',
          maxWidth: { sm: '100%', xs: '300px' },
          m: '0 auto',
        }}
      >
        <ListItemIcon
          sx={{
            width: { sm: '94px', xs: '100%' },
            mr: { sm: 2, xs: 0 },
            mb: { sm: 0, xs: 2 },
            aspectRatio: { sm: '1', xs: 1.45 },
          }}
        >
          <Avatar
            sx={{
              aspectRatio: { sm: '1', xs: 1.45 },
              width: '100%',
              height: 'auto',
              display: 'block',
              borderRadius: '14px',
            }}
            src={`${IMG_URL}${propertyImages[0].propertyImage}`}
          />
        </ListItemIcon>

        <ListItemText sx={{ width: { sm: 'calc(100% - 110px)', xs: '100%' } }}>
          <Box>
            <Grid container>
              <Grid item xs={8}>
                <Box>
                  <Typography
                    variant="subtitle1"
                    component="h6"
                    noWrap={true}
                    sx={{ mb: { sm: 1 } }}
                  >
                    {title}
                  </Typography>
                  <Typography variant="body2" component="p" noWrap={true}>
                    {address}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <FavoriteIconButton>
                    {/* <SvgColor sx={{ color: 'primary.contrastText' }} src={Like} /> */}
                    <Checkbox
                      checked={
                        isFavorite
                          ? isFavorite.length !== 0
                            ? true
                            : false
                          : true
                      }
                      onChange={handleFav}
                      icon={<Icon icon="ion:heart-outline" />}
                      checkedIcon={<Icon icon="ion:heart-sharp" />}
                    />
                  </FavoriteIconButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mt: { sm: 2, xs: 1 } }}>
            <Grid container>
              <Grid item xs={7}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    sx={{ width: '24px', height: '24px' }}
                    src={`${IMG_URL}${ownerData[0].profile}`}
                  />
                  <Typography variant="subtitle2" sx={{ ml: 1 }} noWrap={true}>
                    {ownerData[0].firstname} {ownerData[0].lastname}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box sx={{ textAlign: 'right' }}>
                  <Typography variant="caption">
                    {propertyPrice
                      ? propertyPrice.map((item, index) => {
                          return item.durationName === 'year' ? (
                            <span key={index}>
                              <b>
                                {nairaSymbol}
                                {currencySeprator(item.price)}
                              </b>{' '}
                              / year
                            </span>
                          ) : null;
                        })
                      : null}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </ListItemText>
      </ListItemButton>
    </>
  );
};
