import React from 'react';

import { Backdrop, CircularProgress } from '@mui/material';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ShowSpinner = (props) => {
  let { spinFlag } = props;
  return (
    <React.Fragment>
      <Backdrop sx={{ color: '#fff', zIndex: '9999' }} open={spinFlag}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default ShowSpinner;
