import React, { useEffect, useState } from 'react';
import { Pagination } from '@mui/material';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Rating from 'react-rating';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from 'reactstrap';
import 'quill/dist/quill.snow.css';
import DeleteModal from '../../../Components/Common/DeleteModal';
import {
  getPopularSpacesList,
  removeFromPopularPlaces,
} from '../../../../services/apiServices';
import { IMG_URL, nairaSymbol } from '../../../../utils/constant';
import ShowSpinner from '../../../Components/Common/spinner';
import ResponseModal from '../../../Components/Common/SuccessModal';
import SvgColor from '../../../../components/svg-color';
import Bed from '../../../../assets/icons/Bed.svg';
import Bathroom from '../../../../assets/icons/Bathroom.svg';

const ExploreRentalsPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [deleteModal, setDeleteModal] = useState(false);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState();
  const [popularSpaces, setPopularSpaces] = useState([]);
  const [renderingFlag, setRenderingFlag] = useState(false);
  const [isPopularDelete, setIsPopularDelete] = useState(null);
  const [deleteConfirmationMsg, setDeleteConfirmationMsg] = useState('');
  const [spinFlag, setSpinFlag] = useState(false);
  const [response, setResponse] = useState({
    message: '',
    open: false,
    statusCode: null,
  });
  const [deleteState, setDeleteState] = useState({
    message: '',
    spinFlag: false,
    showAlert: false,
    showAlertError: false,
  });

  //for remove perticular property from top rated cities
  const onClickDelete = (id) => {
    setDeleteConfirmationMsg(
      'Are you sure you want to delete this property from explore rentals'
    );
    setIsPopularDelete(id);
    setDeleteModal(true);
  };

  const fetchPopularSpaces = async () => {
    try {
      setSpinFlag(true);
      let payload = {
        currentPage: currentPage,
        search: search,
      };
      let { data } = await getPopularSpacesList(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setNumberOfPages(data.noOfPages);
        setPopularSpaces(data.popularSpaces);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    if (search !== '') {
      const getData = setTimeout(() => {
        fetchPopularSpaces();
      }, 1000);
      return () => clearTimeout(getData);
    } else {
      fetchPopularSpaces();
    }
  }, [renderingFlag, search, currentPage]);

  const removeFromPopular = async () => {
    try {
      setSpinFlag(true);
      let payload = { property_id: isPopularDelete };
      let { data } = await removeFromPopularPlaces(payload);
      if (data.statusCode === 200) {
        setRenderingFlag(!renderingFlag);
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
        setDeleteModal(false);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
        setDeleteModal(false);
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  return (
    <React.Fragment>
      <ResponseModal response={response} setResponse={setResponse} />
      <DeleteModal
        deleteConfirmationMsg={deleteConfirmationMsg}
        deleteState={deleteState}
        show={deleteModal}
        onDeleteClick={removeFromPopular}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="g-2">
                    <Col xs={12} className="col-lg-auto">
                      <div>
                        <h5 className="card-title mb-0">Explore Rentals</h5>
                      </div>
                    </Col>
                    <Col xs={12} className="col-lg-auto ms-auto">
                      <div className="list-grid-nav hstack gap-1">
                        <div className="search-box">
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Search explore rentals..."
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                        <div className="list-grid-nav hstack gap-1">
                          <Link
                            to="/admin/property-list"
                            className="btn btn-success text-nowrap"
                          >
                            <i className="ri-add-fill me-1 align-bottom"></i>{' '}
                            Add explore rentals
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <div id="teamlist">
                <Row className="team-list">
                  {popularSpaces.map((item, index) => {
                    return (
                      <Col key={index} lg={4}>
                        <Card className="team-box position-relative">
                          <CardHeader className="border-0 position-absolute w-100 start-0 top-0 bg-transparent p-4">
                            <Row className="align-items-center team-row">
                              <Col className="team-settings">
                                <Row>
                                  <Col>
                                    <div className="flex-shrink-0 me-2"></div>
                                  </Col>
                                  <Col>
                                    <div className="hstack gap-2 justify-content-end">
                                      <div>
                                        <button
                                          className="btn btn-icon btn-topbar btn-ghost-danger rounded-circle border-danger mt-1 me-1"
                                          onClick={() => {
                                            onClickDelete(item._id);
                                          }}
                                        >
                                          <i className="ri-delete-bin-5-fill fs-16"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody className="p-4 ">
                            <div className="mb-4">
                              <img
                                className="card-img-top img-fluid bg-light"
                                style={{
                                  aspectRatio: '1.3',
                                  objectFit: 'cover',
                                }}
                                src={`${IMG_URL}${item.propertyImages[0].propertyImage}`}
                                alt="Card image cap"
                              />
                            </div>
                            <div className="mb-4">
                              <Row>
                                <Col sm={8}>
                                  <div>
                                    <Link
                                      to={`/admin/property-details/${item?._id}`}
                                      className="text-dark"
                                    >
                                      <h5 className="fs-16 mb-1">
                                        {item.title}
                                      </h5>
                                      <p className="text-muted mb-0 text-truncate">
                                        {item.address}
                                      </p>
                                    </Link>
                                  </div>
                                </Col>
                                <Col sm={4}>
                                  <div dir="ltr" className="text-end">
                                    <div
                                      id="raterreset"
                                      className="align-middle d-inline rater-reset"
                                    >
                                      <Rating
                                        initialRating={item.rating}
                                        emptySymbol="mdi mdi-star-outline text-muted "
                                        fullSymbol="mdi mdi-star text-warning "
                                        style={{ fontSize: '16px' }}
                                        readonly
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <div className="">
                              <Row>
                                <Col sm={6}>
                                  <p className="fs-14 mb-0">
                                    <span>
                                      <b>
                                        {nairaSymbol}
                                        {item.minPrice}
                                      </b>{' '}
                                      -
                                      <b>
                                        {nairaSymbol}
                                        {item.maxPrice}
                                      </b>
                                    </span>
                                  </p>
                                </Col>
                                {/* <Col sm={6}>
                                  <div className="d-flex justify-content-end gap-2">
                                    <div className="d-flex align-items-center">
                                      <span
                                        className="d-flex align-items-center justify-content-center rounded-circle me-2"
                                        style={{
                                          background: '#E9EAF0',
                                          width: '24px',
                                          height: '24px',
                                        }}
                                      >
                                        <SvgColor
                                          sx={{
                                            width: '10px',
                                            height: '10px',
                                          }}
                                          src={Bed}
                                        />
                                      </span>
                                      <span>
                                        
                                        Bed
                                      </span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <span
                                        className="d-flex align-items-center justify-content-center rounded-circle me-2"
                                        style={{
                                          background: '#E9EAF0',
                                          width: '24px',
                                          height: '24px',
                                        }}
                                      >
                                        <SvgColor
                                          sx={{
                                            width: '10px',
                                            height: '10px',
                                          }}
                                          src={Bathroom}
                                        />
                                      </span>

                                      <span>
                                        
                                        Bathroom
                                      </span>
                                    </div>
                                  </div>
                                </Col> */}
                              </Row>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Col>
          </Row>
          {popularSpaces.length === 0 && (
            <Card>
              <CardBody className="text-center">Data not found</CardBody>
            </Card>
          )}
          {popularSpaces.length > 0 && (
            <Pagination
              shape="rounded"
              count={numberOfPages}
              page={currentPage}
              siblingCount={0}
              boundaryCount={2}
              onChange={handlePageChange}
              sx={{
                py: 2,
                '& .MuiPagination-ul': { justifyContent: 'center' },
              }}
            />
          )}
        </Container>
      </div>
      <ShowSpinner spinFlag={spinFlag} />
    </React.Fragment>
  );
};

export default ExploreRentalsPage;
