import React, { useState, useRef, useEffect } from 'react';
import {
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  CardHeader,
  Button,
  styled,
  Input,
  Dialog,
  FormHelperText,
  Backdrop,
  CircularProgress,
  Slider as MuiSlider,
} from '@mui/material';
import { Stack } from '@mui/system';
import { Icon } from '@iconify/react';
import { useAuthStore } from '../../../redux/features/authSlice';
import { nairaSymbol } from '../../../utils/constant';
import { REGEX } from '../../../utils/regex';
import { currencySeprator } from '../../../utils/common-function';
import { useNavigate, useLocation } from 'react-router-dom';
import { sendWithdrawRequest } from '../../../services/apiServices';
import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';

const AmountBox = styled(Box)(({ theme }) => ({
  '& .MuiTypography-root': { paddingTop: '8px' },
  '& .MuiInputBase-root': {
    '&:after, &:before': {
      content: 'none',
    },
    '& .MuiInputBase-input': {
      fontSize: '2.5rem',
      textAlign: 'center',
      fontWeight: '700',
      padding: '0',
    },
  },
}));
const AirbnbSlider = styled(MuiSlider)(({ theme }) => ({
  color: '#FEAE01',
  height: 6,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '8px solid #FEAE01',
    '&:hover': {
      boxShadow: '0 0 0 8px #FFF9EB',
    },
    '& .airbnb-bar': {},
  },
  '& .MuiSlider-track': {
    background: '#FEAE01',
    height: 6,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#FFF9EB' : '#FFF9EB',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: 6,
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    minWidth: 32,
    height: 32,
    borderRadius: '60px',
    backgroundColor: '#323232',
    // transformOrigin: 'bottom left',
    top: '-20px',
    // '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {},
    '& > *': {
      //   transform: 'rotate(45deg)',
    },
  },
}));

const valuetext = (value) => {
  return `${value}$`;
};
export const CardWallet = () => {
  const MIN_AMOUNT = 0;
  const MAX_AMOUNT = 10000000;
  const STEP = 50;
  let { loginInfo } = useAuthStore();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const [open, setOpen] = useState(null);
  const [spinFlag, setSpinFlag] = useState(false);
  const [message, setMessage] = useState();
  const [amount, setAmount] = useState(MIN_AMOUNT);
  const [autoWidth, setAutoWidth] = useState(27);
  const [errorForAmount, setErrorForAmount] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [statusCode, setStatusCode] = useState();
  const handleSliderChange = (event, newValue) => {
    setAmount(newValue);
  };
  const handleInputChange = (event) => {
    setAmount(
      event.target.value.replace(REGEX.RESTRICT_CHAR, '') === ''
        ? ''
        : Number(event.target.value.replace(REGEX.RESTRICT_CHAR, ''))
    );
    setErrorMsg('');
  };
  const handleBlur = () => {
    if (amount < 0) {
      setAmount(MIN_AMOUNT);
    } else if (amount > MAX_AMOUNT) {
      setAmount(MAX_AMOUNT);
    }
  };
  useEffect(() => {
    if (amount) {
      handleAutoWidth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);
  const handleAutoWidth = () => {
    const getNumberLength = amount.toString().length;
    setAutoWidth(getNumberLength * 27);
  };

  const submitWithdrawRequest = async () => {
    try {
      let payload = { amount: amount };
      let { data } = await sendWithdrawRequest(payload);

      if (data.statusCode === 200) {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const handleWithdrawRequest = () => {
    if (amount < 100) {
      setErrorMsg('Amount must be greater then 100 NGN');
    } else if (
      amount > loginInfo.walletBalance ||
      loginInfo.walletBalance === undefined
    ) {
      setErrorMsg('You have insufficient funds to transfer');
    } else {
      setErrorForAmount(true);
      setErrorMsg('');
      submitWithdrawRequest();
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogPopup
        open={open}
        setOpen={setOpen}
        message={message}
        size="xs"
        statusCode={statusCode}
      />
      <Grid container spacing={2}>
        <Grid item md={6} xs={12} sx={{ display: 'grid', gap: '15px' }}>
          <Card
            sx={{
              display: 'grid',
              alignItems: 'center',
            }}
          >
            <CardContent>
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={6}>
                  <Box>
                    <Typography variant="h3" sx={{ display: 'flex' }}>
                      <Typography
                        component="span"
                        variant="h5"
                        sx={{ pt: 0.5, mr: 0.5 }}
                      >
                        {nairaSymbol}
                      </Typography>
                      {loginInfo.totalEarning
                        ? currencySeprator(loginInfo.totalEarning)
                        : 0}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#808191' }}>
                      Total earning
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ textAlign: 'right' }}>
                    <Box
                      sx={{
                        width: '72px',
                        height: '72px',
                        py: 2,
                        px: 2,
                        background: '#FAFAFA',
                        color: '#FEAE01',
                        borderRadius: '14px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        ml: 'auto',
                      }}
                    >
                      <Icon width="80%" icon="solar:hand-money-linear" />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card
            sx={{
              display: 'grid',
              alignItems: 'center',
            }}
          >
            <CardContent>
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={6}>
                  <Box>
                    <Typography variant="h3" sx={{ display: 'flex' }}>
                      <Typography
                        component="span"
                        variant="h5"
                        sx={{ pt: 0.5, mr: 0.5 }}
                      >
                        {nairaSymbol}
                      </Typography>
                      {loginInfo.walletBalance
                        ? currencySeprator(loginInfo.walletBalance)
                        : 0}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#808191' }}>
                      Current balance
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ textAlign: 'right' }}>
                    <Box
                      sx={{
                        width: '72px',
                        height: '72px',
                        py: 2,
                        px: 2,
                        background: '#FAFAFA',
                        color: '#FEAE01',
                        borderRadius: '14px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        ml: 'auto',
                      }}
                    >
                      <Icon width="80%" icon="solar:wallet-linear" />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} xs={12}>
          <Card
            sx={{
              minHeight: '100%',
            }}
          >
            <CardHeader title="Request for withdrawal" />
            <CardContent sx={{}}>
              <Stack sx={{ gap: '15px' }}>
                <AmountBox>
                  <Stack direction="row" justifyContent="center" spacing={1}>
                    <Typography variant="h5">{nairaSymbol}</Typography>
                    <Input
                      disableUnderline
                      size="small"
                      value={amount ? amount : 0}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      inputProps={{
                        step: STEP,
                        min: MIN_AMOUNT,
                        max: MAX_AMOUNT,
                      }}
                      onInput={(e) => {
                        e.target.value = e.target.value
                          .replace(REGEX.RESTRICT_CHAR, '')
                          .slice(0, 9);
                      }}
                      sx={{
                        typography: 'h3',
                        '& input': {
                          p: 0,
                          textAlign: 'center',
                          width: autoWidth,
                        },
                      }}
                    />
                  </Stack>
                </AmountBox>
                {errorMsg && (
                  <FormHelperText error={true} sx={{ textAlign: 'center' }}>
                    {errorMsg}
                  </FormHelperText>
                )}
                <AirbnbSlider
                  getAriaLabel={() => 'Temperature range'}
                  value={amount ? amount : MIN_AMOUNT}
                  onChange={handleSliderChange}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  min={MIN_AMOUNT}
                  max={MAX_AMOUNT}
                />
                <Grid container>
                  <Grid item xs={6}>
                    <Box>
                      <Typography variant="subtitle2">Your balance</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ textAlign: 'right' }}>
                      <Typography variant="subtitle2">
                        <Typography
                          component="span"
                          variant="subtitle2"
                          sx={{ pt: 0.5, mr: 0.5 }}
                        >
                          {nairaSymbol}
                        </Typography>
                        {loginInfo.walletBalance
                          ? currencySeprator(loginInfo.walletBalance)
                          : 0}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  disabled={
                    amount === 0 || amount === '' || amount === undefined
                  }
                  onClick={handleWithdrawRequest}
                  fullWidth
                  size="large"
                >
                  Send request
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
