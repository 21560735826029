import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Pagination } from '@mui/material';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  UncontrolledDropdown,
  ModalHeader,
} from 'reactstrap';
import 'quill/dist/quill.snow.css';
import AddEditAmenities from './AddEditAmenities';
import DeleteModal from '../../../Components/Common/DeleteModal';
import {
  getAmenitiesList,
  deleteAmenities,
} from '../../../../services/apiServices';
import { IMG_URL } from '../../../../utils/constant';
import ResponseModal from '../../../Components/Common/SuccessModal';
import ShowSpinner from '../../../Components/Common/spinner';

const AmenitiesPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState();
  const [search, setSearch] = useState('');
  const [amenitiesListRendering, setAmenitiesListRendering] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const [isAmenitiesDelete, setIsAmenitiesDelete] = useState(null);
  const [spinFlag, setSpinFlag] = useState(false);
  const [response, setResponse] = useState({
    message: '',
    open: false,
    statusCode: null,
  });

  const [amenityData, setAmenityData] = useState(null);
  const [deleteState, setDeleteState] = useState({
    message: '',
    spinFlag: false,
    showAlert: false,
    showAlertError: false,
  });

  const onClickDelete = (id) => {
    setIsAmenitiesDelete(id);
    setDeleteModal(true);
  };
  // const toggle = useCallback(() => {
  //   if (modal) {
  //     setModal(false);
  //     setCustomer(null);
  //   } else {
  //     setModal(true);
  //   }
  // }, [modal]);
  // const handleCustomerClick = useCallback(
  //   (arg) => {
  //     const customer = arg;
  //     setCustomer();
  //     setIsEdit(true);
  //     toggle();
  //   },
  //   [toggle]
  // );

  const fetchAmenities = async () => {
    try {
      setSpinFlag(true);
      let payload = {
        currentPage: currentPage,
        search: search,
      };
      let { data } = await getAmenitiesList(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setNumberOfPages(data.noOfPages);
        setAmenitiesList(data.amenitiesList);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };
  const handleClose = () => {
    setAmenityData(null);
    setIsEdit(null);
    setModal(false);
  };

  //to delete amenities
  const handleDeleteAmenities = async () => {
    try {
      setSpinFlag(true);
      let payload = { delete_id: isAmenitiesDelete };
      let { data } = await deleteAmenities(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
        setDeleteModal(false);
        setAmenitiesListRendering(!amenitiesListRendering);
      } else {
        setDeleteModal(false);
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    if (search !== '') {
      const getData = setTimeout(() => {
        fetchAmenities();
      }, 1000);
      return () => clearTimeout(getData);
    } else {
      fetchAmenities();
    }
  }, [amenitiesListRendering, currentPage, search]);

  return (
    <React.Fragment>
      <ResponseModal response={response} setResponse={setResponse} />
      <DeleteModal
        deleteState={deleteState}
        show={deleteModal}
        onDeleteClick={handleDeleteAmenities}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="g-2">
                    <Col xs={12} className="col-lg-auto">
                      <div>
                        <h5 className="card-title mb-0">Amenities</h5>
                      </div>
                    </Col>
                    <Col xs={12} className="col-lg-auto ms-auto">
                      <div className="list-grid-nav hstack gap-1">
                        <div className="search-box">
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Search amenities..."
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                        <div className="list-grid-nav hstack gap-1">
                          <Button
                            color="success"
                            onClick={() => {
                              setIsEdit(null);
                              setModal(true);
                              // toggle();
                            }}
                            className="text-nowrap"
                          >
                            <i className="ri-add-fill me-1 align-bottom"></i>{' '}
                            Add Amenities
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <div id="teamlist">
                <Row className="team-list list-view-filter">
                  {amenitiesList.map((item, index) => {
                    return (
                      <Col key={index}>
                        <Card className="team-box mb-2">
                          <CardBody className="p-3">
                            <Row className="team-row">
                              <Col xs={2} className="team-settings">
                                <UncontrolledDropdown
                                  direction="start"
                                  className="text-end"
                                >
                                  <DropdownToggle
                                    tag="a"
                                    id="dropdownMenuLink2"
                                    role="button"
                                  >
                                    <i className="ri-more-fill fs-17"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      className="  edit-list"
                                      onClick={() => {
                                        setModal(true);
                                        setAmenityData(item);
                                        setIsEdit(item._id);
                                      }}
                                    >
                                      <i className="ri-pencil-line me-2 align-bottom text-muted"></i>
                                      Edit
                                    </DropdownItem>
                                    <DropdownItem
                                      className="  remove-list"
                                      onClick={() => {
                                        onClickDelete(item._id);
                                      }}
                                    >
                                      <i className="ri-delete-bin-5-line me-2 align-bottom text-muted"></i>
                                      Remove
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </Col>
                              <Col xs={10}>
                                <div className="team-profile-img">
                                  <div className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
                                    <img
                                      src={`${IMG_URL}${item.path}`}
                                      alt=""
                                      className="member-img img-fluid d-block rounded-circle"
                                    />
                                  </div>
                                  <div className="team-content">
                                    <h5 className="fs-16 mb-1">
                                      {item.amenities}
                                    </h5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Col>
          </Row>
          {amenitiesList.length === 0 && (
            <Card>
              <CardBody className="text-center">Data not found</CardBody>
            </Card>
          )}
          {amenitiesList.length > 0 && (
            <Pagination
              shape="rounded"
              count={numberOfPages}
              page={currentPage}
              siblingCount={0}
              boundaryCount={2}
              onChange={handlePageChange}
              sx={{
                py: 2,
                '& .MuiPagination-ul': { justifyContent: 'center' },
              }}
            />
          )}
        </Container>
      </div>

      <Modal
        id="showModal"
        isOpen={modal}
        handleClose={handleClose}
        centered
        size="md"
        toggle={handleClose}
      >
        <ModalHeader modal={modal} toggle={handleClose}>
          {isEdit ? 'Edit Amenities' : 'Add Amenities'}
        </ModalHeader>

        <ModalBody>
          <AddEditAmenities
            setSpinFlag={setSpinFlag}
            setResponse={setResponse}
            amenityData={amenityData}
            handleClose={handleClose}
            isEdit={isEdit}
            amenitiesListRendering={amenitiesListRendering}
            setAmenitiesListRendering={setAmenitiesListRendering}
          />
        </ModalBody>
      </Modal>
      <ShowSpinner spinFlag={spinFlag} />
    </React.Fragment>
  );
};

export default AmenitiesPage;
