import React, { useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
  Box,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Backdrop,
  CircularProgress,
  FormHelperText,
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { contactUs } from '../../services/apiServices';
import { contactUsSchema } from '../../utils/validation/Auth';
import { DialogPopup } from '../dialog-popup/DialogPopup';

export const ContactUsForm = (props) => {
  let { setSpinFlag } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [statusCode, setStatusCode] = useState();
  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      message: '',
      privacyCheck: true,
    },
    validationSchema: contactUsSchema,
    onSubmit: async (values) => {
      try {
        setSpinFlag(true);
        const { data } = await contactUs(values);
        if (data.statusCode == 200) {
          setSpinFlag(false);
          setMessage(data.message);
          setOpen(true);
          setStatusCode(data.statusCode);
          resetForm();
        } else {
          setSpinFlag(false);
          setMessage(data.message);
          setStatusCode(data.statusCode);
          setOpen(true);
        }
      } catch (err) {
        navigate(`/error?msg=${err}&&path=${pathname}`);
      }
    },
  });
  const { errors, touched, handleSubmit, getFieldProps, resetForm, values } =
    formik;
  return (
    <Card
      sx={{
        bgcolor: '#F9FAFB',
        boxShadow: 'none',
      }}
    >
      <DialogPopup
        open={open}
        setOpen={setOpen}
        message={message}
        size="xs"
        statusCode={statusCode}
      />
      <CardContent sx={{ py: { md: 5 }, px: { md: 4 } }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                <FormLabel sx={{ mb: 1, display: 'block' }}>
                  First name
                </FormLabel>
                <TextField
                  fullWidth
                  type="Text"
                  label=" "
                  {...getFieldProps('firstname')}
                  // error={Boolean(touched.firstname && errors.firstname)}
                  // helperText={touched.firstname && errors.firstname}
                  sx={{
                    '& .MuiInputBase-root': { p: '0' },
                    '& .MuiInputBase-input': { p: '16.5px 12px' },
                  }}
                />
                {touched.firstname && errors.firstname && (
                  <FormHelperText error>{errors.firstname}</FormHelperText>
                )}
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormLabel sx={{ mb: 1, display: 'block' }}>
                  Last name
                </FormLabel>
                <TextField
                  fullWidth
                  type="Text"
                  label=" "
                  {...getFieldProps('lastname')}
                  // error={Boolean(touched.lastname && errors.lastname)}
                  // helperText={touched.lastname && errors.lastname}
                  sx={{
                    '& .MuiInputBase-root': { p: '0' },
                    '& .MuiInputBase-input': { p: '16.5px 12px' },
                  }}
                />
                {touched.lastname && errors.lastname && (
                  <FormHelperText error>{errors.lastname}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormLabel sx={{ mb: 1, display: 'block' }}>Email</FormLabel>
                <TextField
                  fullWidth
                  type="email"
                  label=" "
                  {...getFieldProps('email')}
                  // error={Boolean(touched.email && errors.email)}
                  // helperText={touched.email && errors.email}
                  sx={{
                    '& .MuiInputBase-root': { p: '0' },
                    '& .MuiInputBase-input': { p: '16.5px 12px' },
                  }}
                />
                {touched.email && touched.email && (
                  <FormHelperText error>{errors.email}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormLabel sx={{ mb: 1, display: 'block' }}>Message</FormLabel>
                <TextField
                  multiline
                  rows="4"
                  fullWidth
                  type="Text"
                  label=" "
                  {...getFieldProps('message')}
                  // error={Boolean(touched.message && errors.message)}
                  // helperText={touched.message && errors.message}
                  sx={{
                    '& .MuiInputBase-root': { p: '0' },
                    '& .MuiInputBase-input': { p: '16.5px 12px' },
                  }}
                />
                {touched.message && errors.message && (
                  <FormHelperText error>{errors.message}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Box sx={{}}>
                  <FormControlLabel
                    sx={{ gap: '15px', marginLeft: '11px' }}
                    control={
                      <Checkbox
                        checked={values.privacyCheck}
                        name="privacyCheck"
                        {...getFieldProps('privacyCheck')}
                        // error={Boolean(
                        //   touched.privacyCheck && errors.privacyCheck
                        // )}
                        // helperText={touched.privacyCheck && errors.privacyCheck}
                      />
                    }
                    label="You agree to our friendly privacy policy."
                  />
                </Box>
                {touched.privacyCheck && errors.privacyCheck && (
                  <FormHelperText error>{errors.privacyCheck}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                >
                  Send message
                </Button>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </CardContent>
    </Card>
  );
};
