// ----------------------------------------------------------------------

export default function Dialog(theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            borderRadius: Number(theme.shape.borderRadius) * 3.5,
            margin: 16,
          },
        },
      },
      MuiDialogContent: {},
    },
  };
}
