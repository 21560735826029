import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Input } from 'reactstrap';
import DatatablesCom from '../../Components/Tables/DataTables/DatatablesCom';
import userNoImage from '../../assets/images/users/user-dummy-img.jpg';
import 'react-toastify/dist/ReactToastify.css';
import { IMG_URL } from '../../../utils/constant';
import { currencySeprator } from '../../../utils/common-function';
import { Link } from 'react-router-dom';

const customStyles = {
  rows: {
    style: {
      // minHeight: '72px',
    },
  },
  headCells: {
    style: {
      padding: '8px',
      background: '#f3f6f9',
      fontWeight: 'bold',
      color: '#878a99',
    },
  },
  cells: {
    style: {
      padding: '8px',
    },
  },
};

const LandlordDetails = (props) => {
  let {
    data,
    currentPage,
    setCurrentPage,
    paginationTotalRows,
    setSearch,
    search,
    fetchRender,
    setFetchRender,
    optionsFlag,
    setSearchByDate,
    rowPerPage,
    setRowsPerPage,
    onClickDelete,
    setUserStatus,
    userStatus,
  } = props;
  const [placeholder, setPlaceholder] = useState(
    'Search by title, city, country'
  );

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll('.customerCheckBox:checked');
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Properties</span>,
      sortable: true,
      width: '350px',
      cell: (row) => {
        return (
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 me-3">
              <div className="avatar-sm bg-light rounded p-1">
                {row?.propertyImages[0]?.propertyImage ? (
                  <img
                    className="img-fluid d-block"
                    src={`${IMG_URL}${row?.propertyImages[0]?.propertyImage}`}
                    alt="Header Avatar"
                  />
                ) : (
                  <img
                    className="img-fluid d-block"
                    src={userNoImage}
                    alt="Header Avatar"
                  />
                )}
              </div>
            </div>
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                <Link
                  to={`/admin/property-details/${row?._id}`}
                  className="text-dark"
                >
                  {' '}
                  {row.title}
                </Link>
              </h5>
              <p className="text-muted mb-0">
                <span className="fw-medium"> {row?.address}</span>
              </p>
            </div>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Price</span>,
      sortable: true,
      width: '230px',
      cell: (row) => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <span>{'₦' + currencySeprator(row?.minPrice)}</span>{' '}
            <b className="px-1">{' - '}</b>
            <span>{'₦' + currencySeprator(row?.maxPrice)}</span>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Viewed</span>,
      sortable: true,
      cell: (row) => {
        return <span>{row?.views}</span>;
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Units</span>,
      sortable: true,
      cell: (row) => {
        return row?.propertyUnit?.length;
      },
    },

    {
      name: <span className="font-weight-bold fs-13">Rating</span>,
      sortable: true,
      cell: (row) => {
        return (
          <span>
            <span className="badge bg-light text-body fs-12 fw-medium">
              {row?.rating ? (
                <>
                  <i className="mdi mdi-star text-warning me-1"></i>
                  {row?.rating}
                </>
              ) : (
                '-'
              )}
            </span>
          </span>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      sortable: true,
      selector: (row) => (
        <div className="form-check form-switch">
          <Input
            className="form-check-input"
            type="checkbox"
            role="switch"
            checked={!row.isDeleted || false}
            // onClick={() => onClickActiveInactiveUser(cell._id, cell.isActive)}
            onClick={() => {
              onClickDelete(row?._id, row?.user, row?.title, row?.isDeleted);
            }}
          />
        </div>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Created date</span>,
      selector: (row) => row?.createdAt.substring(0, 10),
      sortable: true,
    },
  ];
  return (
    <>
      <Col lg={12}>
        <Card id="propertiesList">
          <CardHeader className="border-0">
            <Row>
              <Col>
                <div>
                  <h5 className="card-title mb-0">Properties List</h5>
                </div>
              </Col>
              <Col></Col>
            </Row>
          </CardHeader>
          <CardBody className="p-0">
            <div>
              {data && data.length ? (
                <DatatablesCom
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  setCurrentPage={setCurrentPage}
                  paginationTotalRows={paginationTotalRows}
                  setSearch={setSearch}
                  search={search}
                  fetchRender={fetchRender}
                  setFetchRender={setFetchRender}
                  optionsFlag={optionsFlag}
                  setSearchByDate={setSearchByDate}
                  placeholder={placeholder}
                  setRowsPerPage={setRowsPerPage}
                  rowPerPage={rowPerPage}
                  setUserStatus={setUserStatus}
                  userStatus={userStatus}
                />
              ) : (
                <>
                  <DatatablesCom
                    setCurrentPage={setCurrentPage}
                    customStyles={customStyles}
                    setSearch={setSearch}
                    search={search}
                    fetchRender={fetchRender}
                    setFetchRender={setFetchRender}
                    optionsFlag={optionsFlag}
                    setSearchByDate={setSearchByDate}
                    placeholder={placeholder}
                    setRowsPerPage={setRowsPerPage}
                    rowPerPage={rowPerPage}
                    setUserStatus={setUserStatus}
                    userStatus={userStatus}
                  />
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default LandlordDetails;
