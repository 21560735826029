import React, { useState, useEffect } from 'react';
import Rating from 'react-rating';
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Tooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';

//Simple bar
import SimpleBar from 'simplebar-react';
import product8 from '../../../assets/images/products/img-8.png';
import userNoImage from '../../../assets/images/users/user-dummy-img.jpg';
import SvgColor from '../../../../components/svg-color';
import Bed from '../../../../assets/icons/Bed.svg';
import Bathroom from '../../../../assets/icons/Bathroom.svg';
import award from '../../../../assets/icons/award.svg';
import location from '../../../../assets/icons/location.svg';
import calendaradd from '../../../../assets/icons/calendaradd.svg';
import Identity from '../../../../assets/icons/Identity.svg';
import Superhost from '../../../../assets/icons/Superhost.svg';

import { Swiper, SwiperSlide } from 'swiper/react';
import classnames from 'classnames';

// import PropertyTitle from './PropertyTitle';
// import { ImagesGallery } from './ImagesGallery';
// import { PropertyDescription } from './PropertyDescription';
// import { PropertyReviews } from './PropertyReviews';
// import { PropertyRules } from './PropertyRules';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  activeInactiveParticularProperty,
  activeInactiveParticularUser,
  getProperty,
} from '../../../../services/apiServices';
import { useAuthStore } from '../../../../redux/features/authSlice';
import { IMG_URL } from '../../../../utils/constant';
import PropertyUnitList from './PropertyUnitList';
import PropertyMap from './PropertyMap';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import SwiperCore, { FreeMode, Navigation, Thumbs } from 'swiper';
import { Link } from 'react-router-dom';
import ActiveInactiveModel from '../../../Components/Common/ActiveInactiveModel';
import ShowSpinner from '../../../Components/Common/spinner';
import ResponseModal from '../../../Components/Common/SuccessModal';
import { color } from '@mui/system';

SwiperCore.use([FreeMode, Navigation, Thumbs]);

function PropertyDetailsAdmin(props) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [ttop, setttop] = useState(false);
  const [ssize, setssize] = useState(false);
  const [msize, setmsize] = useState(false);
  const [lsize, setlsize] = useState(false);
  const [xlsize, setxlsize] = useState(false);
  const [customActiveTab, setcustomActiveTab] = useState('1');
  const [activeInactiveUserId, setActiveInactiveUserId] = useState();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let { id } = useParams();
  let { loginAdminInfo } = useAuthStore();
  const query = new URLSearchParams(useLocation().search);
  const bedroomFilter = query.get('bedroom');

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [spinFlag, setSpinFlag] = useState(false);
  const [propertyData, setPropertyData] = useState({});
  const [propertyImages, setPropertyImages] = useState([]);
  const [propertyOwner, setPropertyOwner] = useState({});
  const [amenities, setAmenities] = useState([]);
  const [rules, setRules] = useState([]);
  const [propertyPrice, setPropertyPrice] = useState([]);
  const [renderFlag, setRenderFlag] = useState(false);
  const [propertyReview, setPropertyReview] = useState([]);
  const [reviewData, setReviewData] = useState();
  const [propertyUnits, setPropertyUnits] = useState([]);
  const [statusCode, setStatusCode] = useState();
  const [minimumDurationStay, setMinimumDurationStay] = useState(null);
  //model acive inactive propert unit
  const [messageActiveInactive, setMessageActiveInactive] = useState();
  const [unitName, setUnitName] = useState('');
  const [userId, setUserId] = useState('');
  const [response, setResponse] = useState({
    message: '',
    open: false,
    statusCode: null,
  });
  const [activeInactivePropertyModel, setActiveInactivePropertyModel] =
    useState(false);
  const getPropertyData = async () => {
    try {
      setSpinFlag(true);
      let user_id = loginAdminInfo ? loginAdminInfo._id : null;
      let payload = {
        propertyId: id,
        user_id: user_id,
        role: loginAdminInfo ? loginAdminInfo.role : null,
        bedroomFilter:
          bedroomFilter !== 'undefined' ? bedroomFilter : undefined,
      };
      const { data } = await getProperty(payload);
      if (data.statusCode === 200) {
        let propertyData = data.propertyData;
        propertyData.requestData = data.requestData;
        propertyData.bookingDetails = data.bookingDetails;
        propertyData.bookingDatesForEachUnits = data.bookingDatesForEachUnits;
        setSpinFlag(false);
        setPropertyData(propertyData);
        setPropertyImages(data.propertyImage);
        setPropertyOwner(data.propertyOwner);
        setRules(data.rules);
        setAmenities(data.amenities);
        // setPropertyPrice(data.propertyUnits);
        setPropertyReview(data.propertyReview);
        setReviewData(data.singleReviewData);
        setPropertyUnits(data.propertyUnits);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };
  useEffect(() => {
    getPropertyData();
  }, [renderFlag]);

  const handleClose = () => {
    setOpen(false);
  };

  //active in active user
  const handleActiveInactiveProperty = async () => {
    try {
      setSpinFlag(true);
      let payload = {
        property_unit_id: activeInactiveUserId,
        propertUnitName: unitName,
        propertyId: id,
      };
      const { data } = await activeInactiveParticularProperty(payload);
      if (data.statusCode === 200) {
        // if (data.bookingStatus) {
        //   setResponse({
        //     message: data.message,
        //     statusCode: data.statusCode,
        //     open: true,
        //   });
        // }
        // console.log('');
        setActiveInactivePropertyModel(false);
        setRenderFlag(!renderFlag);
        setSpinFlag(false);
      } else {
        setActiveInactivePropertyModel(false);
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  //active in active user
  const onClickActiveInactiveUser = (idd, isActive, unitName) => {
    setActiveInactiveUserId(idd);
    setUnitName(unitName);
    setUserId(id);
    setActiveInactivePropertyModel(true);
    if (isActive) {
      setMessageActiveInactive('inactive this property unit');
    } else {
      setMessageActiveInactive('active this property unit');
    }
  };

  useEffect(() => {
    let arr = [];
    propertyUnits?.map((item) => {
      if (item.durationName === 'year') {
        arr.push(10);
      } else if (item.durationName === 'sixMonth') {
        arr.push(6);
      } else if (item.durationName === 'threeMonth') {
        arr.push(3);
      } else {
        arr.push(1);
      }
    });

    if (arr.length !== 0) {
      const minValue = Math.min(...arr);
      setMinimumDurationStay(minValue);
    }
  }, [propertyUnits]);

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <div className="page-content">
      <ResponseModal response={response} setResponse={setResponse} />
      <ActiveInactiveModel
        show={activeInactivePropertyModel}
        onActiveInactiveClick={handleActiveInactiveProperty}
        onCloseClick={() => setActiveInactivePropertyModel(false)}
        messageActiveInactive={messageActiveInactive}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row className="gx-lg-5">
                  <Col xl={4} md={8} className="mx-auto">
                    <h5>
                      Gallery <small>({propertyImages.length} images)</small>
                    </h5>
                    <div className="product-img-slider sticky-side-div p-2">
                      <Swiper
                        navigation={true}
                        thumbs={{ swiper: thumbsSwiper }}
                        className="swiper product-thumbnail-slider rounded bg-light"
                      >
                        <div className="swiper-wrapper">
                          {propertyImages.map((file, index) => (
                            <SwiperSlide className="" key={index}>
                              <img
                                src={`${IMG_URL}${file?.propertyImage}`}
                                alt=""
                                className="img-fluid d-block w-100 h-100"
                                style={{ aspectRatio: '1.5' }}
                              />
                            </SwiperSlide>
                          ))}
                        </div>
                      </Swiper>
                      <div className="mt-3">
                        <PropertyMap propertyData={propertyData} />
                      </div>
                    </div>
                  </Col>

                  <Col xl={8}>
                    <div className="mt-xl-0 mt-5">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <h4>{propertyData.title}</h4>
                          <p>{propertyData.address}</p>
                          <div className="hstack gap-3 flex-wrap">
                            <div className="text-muted">
                              Units :{' '}
                              <span className="text-body fw-medium">
                                {propertyUnits.length}
                              </span>
                            </div>
                            <div className="vr"></div>
                            <div className="text-muted">
                              Property Type :{' '}
                              <span className="text-body fw-medium">
                                {propertyData.propertyType}
                              </span>
                            </div>
                            <div className="vr"></div>
                            <div className="text-muted">
                              Property Status :{' '}
                              <span
                                className={`text-body fw-medium`}
                                style={
                                  propertyData?.isDeleted
                                    ? { color: 'red' }
                                    : { color: 'green' }
                                }
                              >
                                {propertyData?.isDeleted
                                  ? 'Inactive'
                                  : 'Active'}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="flex-shrink-0"></div>
                      </div>

                      <div className="d-flex flex-wrap gap-2 align-items-center mt-3">
                        <div className="text-muted fs-16">
                          <Rating
                            initialRating={
                              propertyData.rating ? propertyData.rating : 0
                            }
                            emptySymbol="mdi mdi-star-outline text-muted "
                            fullSymbol="mdi mdi-star text-warning "
                            style={{ fontSize: '16px' }}
                            readonly
                          />
                        </div>
                        <div className="text-muted">
                          {propertyData.rating ? propertyData.rating : 0}
                        </div>
                      </div>

                      <div className="mt-4">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-sm bg-light rounded p-1">
                              {propertyOwner.profile ? (
                                <img
                                  className="img-fluid d-block"
                                  src={`${IMG_URL}${propertyOwner.profile}`}
                                  alt="Header Avatar"
                                />
                              ) : (
                                <img
                                  className="img-fluid d-block"
                                  src={userNoImage}
                                  alt="Header Avatar"
                                />
                              )}
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="fs-14 mb-1">
                              Managed by {propertyOwner.firstname}{' '}
                              {propertyOwner.lastname}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="mt-4">
                        <ul className="list-unstyled">
                          <li className="d-flex mb-3">
                            <div className="flex-shrink-0 me-1">
                              <div className="avatar-xs">
                                <SvgColor src={award} />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h5 className="fs-14">
                                {propertyOwner.firstname}{' '}
                                {propertyOwner.lastname} is a Superhost
                              </h5>
                              <p className="m-0">
                                Superhosts are experienced, highly rated hosts
                                who are committed to providing great stays for
                                guests.
                              </p>
                            </div>
                          </li>
                          <li className="d-flex mb-3">
                            <div className="flex-shrink-0 me-1">
                              <div className="avatar-xs">
                                <SvgColor src={location} />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h5 className="fs-14">Great location</h5>
                              <p className="m-0">
                                100% of recent guests gave the location a 5-star
                                rating.
                              </p>
                            </div>
                          </li>
                          <li className="d-flex mb-3">
                            <div className="flex-shrink-0 me-1">
                              <div className="avatar-xs">
                                <SvgColor src={location} />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h5 className="fs-14">Minimum duration stay</h5>
                              <p className="m-0">
                                {propertyOwner.minimumDurationStay === 10
                                  ? '1 Year'
                                  : `${propertyOwner.minimumDurationStay} Month`}
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <hr />
                      <div className="mt-4 text-muted">
                        <h5 className="fs-14 mb-3">Description :</h5>
                        <p>{propertyData.description}</p>
                      </div>
                      <hr />
                      <div className="mt-4">
                        <h5 className="fs-14 mb-3">What this place offers</h5>
                        <ul className="list-unstyled d-flex flex-wrap">
                          {amenities.map((item, index) => {
                            return (
                              <li className="d-flex mb-2 w-50" key={index}>
                                <div className="flex-shrink-0 me-1">
                                  <div className="avatar-xs">
                                    <img
                                      style={{
                                        width: '24px',
                                        height: '24px',
                                        objectFit: 'contain',
                                      }}
                                      src={`${IMG_URL}${item.path}`}
                                    />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <h5 className="fs-14">{item.amenities}</h5>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <hr />
                      <div className="mt-4">
                        <h5 className="fs-14 mb-3">Unit list</h5>
                        <PropertyUnitList
                          propertyUnits={propertyUnits}
                          onClickActiveInactiveUser={onClickActiveInactiveUser}
                        />
                      </div>
                      <div className="mt-4">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-sm bg-light rounded p-1">
                              {propertyOwner.profile ? (
                                <img
                                  className="img-fluid d-block"
                                  src={`${IMG_URL}${propertyOwner.profile}`}
                                  alt="Header Avatar"
                                />
                              ) : (
                                <img
                                  className="img-fluid d-block"
                                  src={userNoImage}
                                  alt="Header Avatar"
                                />
                              )}
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="fs-14 mb-1">
                              Managed by {propertyOwner.firstname}{' '}
                              {propertyOwner.lastname}
                            </h5>
                            <p className="m-0 d-flex align-items-center gap-2 flex-wrap">
                              <span className="d-flex align-items-center">
                                <SvgColor
                                  sx={{
                                    color: '#82838C',
                                    display: 'block',
                                    width: '15px',
                                    height: '15px',
                                  }}
                                  src={Identity}
                                />
                                <span className="ms-2">Identity verified</span>
                              </span>
                              <span className="d-flex align-items-center">
                                <SvgColor
                                  sx={{
                                    color: '#82838C',
                                    display: 'block',
                                    width: '15px',
                                    height: '15px',
                                  }}
                                  src={Superhost}
                                />
                                <span className="ms-2">Superhost</span>
                              </span>
                              <span className="d-flex align-items-center">
                                <Rating
                                  initialRating={0}
                                  emptySymbol="mdi mdi-star-outline text-muted "
                                  fullSymbol="mdi mdi-star text-warning "
                                  style={{ fontSize: '16px' }}
                                  readonly
                                />
                                <span className="ms-2">
                                  {propertyReview.length} Reviews
                                </span>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="mt-4 text-muted">
                        <ul className="list-unstyled">
                          <li className="d-flex mb-3">
                            <div className="flex-grow-1">
                              <h5 className="fs-14">During your stay</h5>
                              <p className="m-0">
                                This was THE perfect place to enjoy a quiet
                                retreat away from everything - it was magical.
                                The cabin is stunning and beautifully appointed
                                - the furnishings are all natural, wool
                              </p>
                            </div>
                          </li>
                          <li className="d-flex mb-3">
                            <div className="flex-grow-1">
                              <h5 className="fs-14">
                                {propertyOwner.firstname}{' '}
                                {propertyOwner.lastname} is a Superhost
                              </h5>
                              <p className="m-0">
                                Superhosts are experienced, highly rated hosts
                                who are committed to providing great stays for
                                guests.
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <hr />
                      <div className="mt-4">
                        <h5 className="fs-14 mb-3">House rules</h5>
                        <ul className="list-unstyled d-flex flex-wrap">
                          {rules.map((item, index) => {
                            return (
                              <li className="d-flex w-50" key={index}>
                                <div className="flex-shrink-0">
                                  <div className="avatar-xs">{index + 1}</div>
                                </div>
                                <div className="flex-grow-1">
                                  <h5 className="fs-14">{item.rules}</h5>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <hr />
                      <div className="mt-5">
                        <div>
                          <h5 className="fs-14 mb-3">Ratings & Reviews</h5>
                        </div>
                        <Row className="gy-4 gx-0">
                          <Col lg={8}>
                            <div>
                              <div className="pb-3">
                                <div className="bg-light px-3 py-2 rounded-2 mb-2">
                                  <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                      <div className="fs-16 align-middle text-warning">
                                        <Rating
                                          initialRating={
                                            propertyData.rating
                                              ? propertyData.rating
                                              : 0
                                          }
                                          emptySymbol="mdi mdi-star-outline text-muted "
                                          fullSymbol="mdi mdi-star text-warning "
                                          style={{ fontSize: '16px' }}
                                          readonly
                                        />
                                      </div>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <h6 className="mb-0">
                                        {propertyData.rating
                                          ? propertyData.rating
                                          : 0}{' '}
                                        out of 5
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                                <div className="text-center">
                                  <div className="text-muted">
                                    Total{' '}
                                    <span className="fw-medium">
                                      {propertyReview.length}
                                    </span>{' '}
                                    reviews
                                  </div>
                                </div>
                              </div>

                              <div className="mt-3">
                                <Row className="align-items-center g-2">
                                  <div className="col-3">
                                    <div className="p-2">
                                      <h6 className="mb-0">Cleanliness</h6>
                                    </div>
                                  </div>
                                  <div className="col-5">
                                    <div className="p-2">
                                      <div className="progress animated-progess progress-sm">
                                        <div
                                          className="progress-bar bg-primary"
                                          role="progressbar"
                                          style={{
                                            width: `${
                                              propertyData.cleanlinessRating *
                                              20
                                            }%`,
                                          }}
                                          aria-valuenow={
                                            propertyData.cleanlinessRating
                                          }
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-auto">
                                    <div className="p-2">
                                      <h6 className="mb-0 text-muted">
                                        {propertyData.cleanlinessRating}
                                      </h6>
                                    </div>
                                  </div>
                                </Row>

                                <Row className="align-items-center g-2">
                                  <div className="col-3">
                                    <div className="p-2">
                                      <h6 className="mb-0">Accuracy</h6>
                                    </div>
                                  </div>
                                  <div className="col-5">
                                    <div className="p-2">
                                      <div className="progress animated-progess progress-sm">
                                        <div
                                          className="progress-bar bg-primary"
                                          role="progressbar"
                                          style={{
                                            width: `${
                                              propertyData.accuracyRating * 20
                                            }%`,
                                          }}
                                          aria-valuenow={
                                            propertyData.accuracyRating
                                          }
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-auto">
                                    <div className="p-2">
                                      <h6 className="mb-0 text-muted">
                                        {propertyData.accuracyRating}
                                      </h6>
                                    </div>
                                  </div>
                                </Row>

                                <Row className="align-items-center g-2">
                                  <div className="col-3">
                                    <div className="p-2">
                                      <h6 className="mb-0">Communication</h6>
                                    </div>
                                  </div>
                                  <div className="col-5">
                                    <div className="p-2">
                                      <div className="progress animated-progess progress-sm">
                                        <div
                                          className="progress-bar bg-primary"
                                          role="progressbar"
                                          style={{
                                            width: `${
                                              propertyData.communicationRating *
                                              20
                                            }%`,
                                          }}
                                          aria-valuenow={
                                            propertyData.communicationRating
                                          }
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-auto">
                                    <div className="p-2">
                                      <h6 className="mb-0 text-muted">
                                        {propertyData.communicationRating}
                                      </h6>
                                    </div>
                                  </div>
                                </Row>

                                <Row className="align-items-center g-2">
                                  <div className="col-3">
                                    <div className="p-2">
                                      <h6 className="mb-0">Location</h6>
                                    </div>
                                  </div>
                                  <div className="col-5">
                                    <div className="p-2">
                                      <div className="progress animated-progess progress-sm">
                                        <div
                                          className="progress-bar bg-primary"
                                          role="progressbar"
                                          style={{
                                            width: `${
                                              propertyData.locationRating * 20
                                            }%`,
                                          }}
                                          aria-valuenow={
                                            propertyData.locationRating
                                          }
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        ></div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-auto">
                                    <div className="p-2">
                                      <h6 className="mb-0 text-muted">
                                        {propertyData.locationRating}
                                      </h6>
                                    </div>
                                  </div>
                                </Row>

                                <Row className="align-items-center g-2">
                                  <div className="col-3">
                                    <div className="p-2">
                                      <h6 className="mb-0">Value</h6>
                                    </div>
                                  </div>
                                  <div className="col-5">
                                    <div className="p-2">
                                      <div className="progress animated-progess progress-sm">
                                        <div
                                          className="progress-bar bg-primary"
                                          role="progressbar"
                                          style={{
                                            width: `${
                                              propertyData.valueRating * 20
                                            }%`,
                                          }}
                                          aria-valuenow={
                                            propertyData.valueRating
                                          }
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-auto">
                                    <div className="p-2">
                                      <h6 className="mb-0 text-muted">
                                        {propertyData.valueRating}
                                      </h6>
                                    </div>
                                  </div>
                                </Row>
                              </div>
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div className="">
                              <div className="d-flex flex-wrap align-items-start gap-3">
                                <h5 className="fs-14">Reviews: </h5>
                              </div>

                              <SimpleBar
                                className="me-lg-n3 pe-lg-4"
                                style={{ maxHeight: '500px' }}
                              >
                                <ul className="list-unstyled mb-0">
                                  {propertyReview.length !== 0
                                    ? propertyReview
                                        .slice(0, 1)
                                        .map((item, index) => {
                                          return (
                                            <li className="py-2" key={index}>
                                              <div className="border border-dashed rounded p-3">
                                                <div className="d-flex align-items-center mb-3">
                                                  <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-sm bg-light rounded p-1">
                                                      {item.reviewerData[0]
                                                        .profile ? (
                                                        <img
                                                          className="img-fluid d-block"
                                                          src={`${IMG_URL}${item.reviewerData[0].profile}`}
                                                          alt="Header Avatar"
                                                        />
                                                      ) : (
                                                        <img
                                                          className="img-fluid d-block"
                                                          src={userNoImage}
                                                          alt="Header Avatar"
                                                        />
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="flex-grow-1">
                                                    <h5 className="fs-14 mb-1 text-capitalize">
                                                      {
                                                        item.reviewerData[0]
                                                          .firstname
                                                      }{' '}
                                                      {
                                                        item.reviewerData[0]
                                                          .lastname
                                                      }
                                                    </h5>
                                                    <p className="text-muted fs-13 mb-0">
                                                      {new Date(
                                                        item.createdAt
                                                      ).toLocaleDateString(
                                                        'en-US',
                                                        {
                                                          month: 'long',
                                                          year: 'numeric',
                                                        }
                                                      )}
                                                    </p>
                                                  </div>
                                                  <div className="flex-shrink-0 ms-3 d-flex align-items-center gap-2">
                                                    <Rating
                                                      initialRating={
                                                        item.rating
                                                          ? item.rating
                                                          : 0
                                                      }
                                                      emptySymbol="mdi mdi-star-outline text-muted "
                                                      fullSymbol="mdi mdi-star text-warning "
                                                      style={{
                                                        fontSize: '16px',
                                                      }}
                                                      readonly
                                                    />
                                                    {item.rating}
                                                  </div>
                                                </div>
                                                <div className="d-flex align-items-end">
                                                  <p className="text-muted fs-13 mb-0">
                                                    {item.comment}
                                                  </p>
                                                </div>
                                              </div>
                                            </li>
                                          );
                                        })
                                    : 'No reviews yet'}
                                </ul>
                              </SimpleBar>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ShowSpinner spinFlag={spinFlag} />
    </div>
  );
}

export default PropertyDetailsAdmin;
