import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Col, Form, Input, Label, Row } from 'reactstrap';
import 'quill/dist/quill.snow.css';
import { IMG_URL } from '../../../../utils/constant';
import { addOrEditAmenities } from '../../../../services/apiServices';
import dummyImgIcon from '../../../assets/images/logo-sm.png';

const AddEditAmenities = (props) => {
  let {
    isEdit,
    handleClose,
    amenityData,
    setAmenitiesListRendering,
    amenitiesListRendering,
    setResponse,
    setSpinFlag,
  } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const uploadedImage = useRef();
  const [formData, setFormData] = useState({
    path: '',
    amenities: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: '' });
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  //for image validation
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    // setImageError('');
    if (
      file?.type === 'image/jpg' ||
      file?.type === 'image/jpeg' ||
      file?.type === 'image/png' ||
      file?.type === 'image/svg+xml' ||
      file?.type === 'image/gif'
    ) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);

      setFormData({ ...formData, path: file });
      setErrors({ ...errors, path: '' });
    } else {
      setFormData({ ...formData, path: '' });
      setErrors({ ...errors, path: 'Image must be in valid format' });
    }
  };

  const toAddOrEditAmenities = async () => {
    try {
      setSpinFlag(true);
      formData.isEdit = isEdit;
      let amenitiesData = new FormData();
      amenitiesData.append('data', JSON.stringify(formData));
      amenitiesData.append('amenitiesIcon', formData.path);
      let { data } = await addOrEditAmenities(amenitiesData);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
        setAmenitiesListRendering(!amenitiesListRendering);
        handleClose();
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validation logic
    const newErrors = {};

    if (!formData.path) {
      newErrors.path = 'Amenities icon is required';
    }

    if (!formData.amenities.trim()) {
      newErrors.amenities = 'Amenities is required';
    }
    // Check for errors
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      toAddOrEditAmenities();
    }
  };

  useEffect(() => {
    if (isEdit) {
      setFormData({ path: amenityData.path, amenities: amenityData.amenities });
    }
  }, []);

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col lg={12}>
            <div className="text-center mb-4">
              <div className="position-relative d-inline-block">
                <div className="position-absolute bottom-0 end-0">
                  <label
                    htmlFor="member-image-input"
                    className="mb-0"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="Select Member Image"
                  >
                    <div className="avatar-xs">
                      <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                        <i className="ri-image-fill"></i>
                      </div>
                    </div>
                  </label>
                  <input
                    className="form-control d-none"
                    defaultValue=""
                    id="member-image-input"
                    name="path"
                    type="file"
                    onChange={handleImageUpload}
                    accept="image/png, image/gif, image/jpeg"
                  />
                </div>
                <div className="avatar-lg">
                  <div className="avatar-title bg-light rounded-circle">
                    <img
                      src={
                        isEdit ? `${IMG_URL}${amenityData.path}` : dummyImgIcon
                      }
                      alt=""
                      id="member-img"
                      className="avatar-md rounded-circle object-fit-contain"
                      ref={uploadedImage}
                    />
                  </div>
                  {errors.path && (
                    <div className="text-danger">{errors.path}</div>
                  )}
                </div>
              </div>
              <h5 className="fs-13 mt-3">Amenities Icon</h5>
            </div>

            <div className="mb-3">
              <Label htmlFor="teammembersName" className="form-label">
                Title
              </Label>
              <Input
                type="text"
                className="form-control"
                id="teammembersName"
                placeholder="Title"
                name="amenities"
                onChange={handleChange}
                value={formData.amenities}
              />
              {errors.amenities && (
                <div className="text-danger">{errors.amenities}</div>
              )}
            </div>
          </Col>
          <Col lg={12}>
            <div className="hstack gap-2 justify-content-end">
              <Button type="button" className="btn-light" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" className="btn-success">
                {isEdit ? 'Save' : 'Add Amenities'}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default AddEditAmenities;
