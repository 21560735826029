// ----------------------------------------------------------------------

export default function Select(theme) {
  return {
    MuiSelect: {
      styleOverrides: {
        root: {
          width: '100%',
          '& .MuiInputBase-root': {
            borderRadius: Number(theme.shape.borderRadius) * 3.5,
            backgroundColor: '#fff',
          },
          '& .MuiInputBase-input': {
            fontWeight: '400',
            fontSize: '14px',
            color: '#323232',
          },
          '& .MuiInputLabel-root': {
            fontWeight: '600',
            fontSize: '14px',
            color: '#9CA3AF',
            '&.Mui-focused, &.MuiFormLabel-filled': {
              transform: 'translate(14px, 2px) scale(1)',
              fontWeight: '400',
              fontSize: '12px',
            },
          },
          '& .MuiOutlinedInput-root': {
            // borderRadius: Number(theme.shape.borderRadius) * 3.5,,
            '&.Mui-focused': {
              backgroundColor: '#FFF9EB',
              borderColor: '#FEAE01', // Custom border color on focus
              '& fieldset': {
                borderColor: '#FEAE01',
              },
            },
            '& fieldset': {
              borderColor: '#D1D5DB', // Custom default border color
            },
            '&:hover fieldset': {
              borderColor: '#D1D5DB', // Custom border color on hover
            },
            '&.Mui-focused fieldset': {
              borderWidth: 1, // Custom border width on focus
            },
          },
          '& .MuiOutlinedInput-root:hover': {
            '& fieldset': {
              borderColor: '#FEAE01',
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            '& span': {
              display: 'none',
            },
          },
        },
      },
    },
  };
}
