import { Backdrop, CircularProgress } from '@mui/material';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Modal, ModalBody } from 'reactstrap';
import { Icon } from '@iconify/react';
import ShowSpinner from './spinner';
import ResponseModal from './SuccessModal';

const ActiveInactiveModel = ({
  show,
  onActiveInactiveClick,
  onCloseClick,
  messageActiveInactive,
}) => {
  return (
    <>
      <Modal fade={true} isOpen={show} toggle={onCloseClick} centered={true}>
        <ModalBody className="py-3 px-3">
          <div
            className="mb-3"
            style={{ textAlign: 'right' }}
            onClick={onCloseClick}
          >
            <Icon icon="ic:round-close" style={{ cursor: 'pointer' }} />
          </div>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: '100px', height: '100px' }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you sure you want to {messageActiveInactive} ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
              onClick={onCloseClick}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={onActiveInactiveClick}
            >
              Yes, {messageActiveInactive?.split(' ')[0]} It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

ActiveInactiveModel.propTypes = {
  onCloseClick: PropTypes.func,
  onActiveInactiveClick: PropTypes.func,
  show: PropTypes.any,
};

export default ActiveInactiveModel;
