import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import classnames from 'classnames';
import 'quill/dist/quill.snow.css';
import DeleteModal from '../../../Components/Common/DeleteModal';
import AddEditInfo from './AddEditInfo';
import { FormikProvider, useFormik } from 'formik';
import {
  deleteContactUs,
  getContactUsList,
  getLogo,
  updateLogo,
} from '../../../../services/apiServices';
import { IMG_URL } from '../../../../utils/constant';
import EmailConfigure from './EmailConfigure';
import SocialMediaLink from './SocialMediaLink';
import AddRoles from './AddRoles';
import ShowSpinner from '../../../Components/Common/spinner';
import ResponseModal from '../../../Components/Common/SuccessModal';
import { useRedirectStore } from '../../../../redux/features/userRedirectSlice';

const SettingsPage = () => {
  const navigate = useNavigate();
  const { userRedirectFlag, updateUserRedirectState } = useRedirectStore();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState('1');
  const [logoData, setLogoData] = useState();
  const [imageError, setImageError] = useState();
  const uploadedImage = useRef();
  const imageUploader = useRef();
  const [render, setRender] = useState(true);
  const [modal, setModal] = useState(false);
  //
  const [contactUsList, setContactUsList] = useState([]);
  const [contactUsData, setContactUsData] = useState(null);
  const [amenitiesListRendering, setAmenitiesListRendering] = useState(false);
  const [isAmenitiesDelete, setIsAmenitiesDelete] = useState(null);
  const [isEdit, setIsEdit] = useState(null);
  //contact us
  const [deleteModal, setDeleteModal] = useState(false);

  const [spinFlag, setSpinFlag] = useState(false);
  const [response, setResponse] = useState({
    message: '',
    open: false,
    statusCode: null,
  });
  const [deleteState, setDeleteState] = useState({
    message: '',
    spinFlag: false,
    showAlert: false,
    showAlertError: false,
  });
  async function fetchLogo() {
    try {
      // console.log('userRedirectFlag', userRedirectFlag);
      setSpinFlag(true);
      const { data } = await getLogo();
      if (data.statusCode === 200) {
        setLogoData(data.logo);
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  }
  useEffect(() => {
    fetchLogo();
    fetchContactUsDetails();
  }, [render, userRedirectFlag, amenitiesListRendering]);

  const fetchContactUsDetails = async () => {
    try {
      setSpinFlag(true);
      let { data } = await getContactUsList();
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setContactUsList(data.allContactUsList);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const updateProfile = async () => {
    try {
      setSpinFlag(true);
      let userData = new FormData();
      userData.append('profile', values.profile);
      const { data } = await updateLogo(userData);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        // updateAdminLoginInfo(result);
        updateUserRedirectState(!userRedirectFlag);
        setRender(!render);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };
  const handleClose = () => {
    setModal(false);
    setContactUsData(null);
    setIsEdit(null);
  };
  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setImageError('');
    if (
      file?.type === 'image/jpg' ||
      file?.type === 'image/jpeg' ||
      file?.type === 'image/png' ||
      file?.type === 'image/svg+xml' ||
      file?.type === 'image/gif'
    ) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      setFieldValue('profile', file);
    } else {
      setFieldValue('profile', '');
      setImageError('Invalid image formate');
    }
  };
  const onClickDelete = (id) => {
    console.log('wshcgshxcg', id);
    setIsAmenitiesDelete(id);
    setDeleteModal(true);
  };

  const handleDeleteContactUs = async () => {
    try {
      console.log('delete functikon called');
      console.log('dtdtdtdtdt', isAmenitiesDelete);
      setSpinFlag(true);
      let payload = { delete_id: isAmenitiesDelete };
      let { data } = await deleteContactUs(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
        setDeleteModal(false);
        setAmenitiesListRendering(!amenitiesListRendering);
      } else {
        setDeleteModal(false);
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const validation = useFormik({
    initialValues: {
      profile: '',
    },
    onSubmit: async (values) => {
      updateProfile(values);
    },
  });
  const { handleSubmit, setFieldValue, values } = validation;
  return (
    <React.Fragment>
      <ResponseModal response={response} setResponse={setResponse} />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xxl={3}>
              <Row>
                <Col xxl={12} lg={6}>
                  <Card>
                    <CardBody className="p-4">
                      <div className="d-flex align-items-center mb-4">
                        <div className="flex-grow-1">
                          <h5 className="card-title mb-0">Website Logo</h5>
                        </div>
                      </div>
                      <FormikProvider value={validation}>
                        <Form
                          autoComplete="off"
                          noValidate
                          onSubmit={handleSubmit}
                        >
                          <div className="text-center">
                            <div className="site-logo profile-user position-relative mx-auto mb-4">
                              <div className="img-thumbnail text-center">
                                <img
                                  src={`${IMG_URL}${logoData?.logo}`}
                                  className="site-logo-image d-block mx-auto p-3"
                                  alt="Flattts"
                                  ref={uploadedImage}
                                />
                              </div>
                              <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={handleImageUpload}
                                  ref={imageUploader}
                                  style={{
                                    display: 'none',
                                  }}
                                />
                                <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                  {/* <Button
                                className="profile-img-file-input"
                                type="file"
                                id="profile-img-file-input"
                                onClick={() => imageUploader.current.click()}
                              /> */}
                                  <Label
                                    htmlFor="profile-img-file-input"
                                    className="profile-photo-edit avatar-xs m-0"
                                    type="file"
                                    id="profile-img-file-input"
                                    onClick={() =>
                                      imageUploader.current.click()
                                    }
                                  >
                                    <span className="avatar-title rounded-circle bg-light text-body">
                                      <i className="ri-camera-fill"></i>
                                    </span>
                                  </Label>
                                </div>
                              </div>
                            </div>

                            {imageError ||
                            validation.values.profile == 'undefined' ? (
                              <div
                                className="invalid"
                                style={{
                                  color: '#EF7157',
                                  fontSize: '0.875em',
                                }}
                              >
                                {imageError}
                              </div>
                            ) : null}

                            <div className="mb-3">
                              <h5 className="fs-16 mb-1">Flattts</h5>
                              <p className="text-muted mb-0">Superadmin</p>
                            </div>

                            <Button type="submit" className="btn btn-success">
                              Updates
                            </Button>
                          </div>
                        </Form>
                      </FormikProvider>
                    </CardBody>
                  </Card>
                </Col>
                <Col xxl={12} lg={6}>
                  <Card>
                    <CardBody>
                      <SocialMediaLink
                        setSpinFlag={setSpinFlag}
                        setResponse={setResponse}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xxl={9}>
              <Card>
                <CardHeader className="overflow-auto">
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0 flex-nowrap"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => {
                          tabChange('1');
                        }}
                        type="button"
                      >
                        Contact Us
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => {
                          tabChange('2');
                        }}
                        type="button"
                      >
                        Email Configure
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => {
                          tabChange('3');
                        }}
                        type="button"
                      >
                        Roles
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <DeleteModal
                  deleteState={deleteState}
                  show={deleteModal}
                  onDeleteClick={handleDeleteContactUs}
                  onCloseClick={() => setDeleteModal(false)}
                />
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        {contactUsList?.map((item, index) => {
                          return (
                            <Col xxl={4} sm={6} xs={12} key={index}>
                              <Card className="shadow-none border">
                                <CardBody>
                                  <div className="team-profile-img">
                                    <div className="avatar-sm img-thumbnail rounded-circle flex-shrink-0 mb-3 d-flex align-items-center justify-content-center">
                                      <img
                                        src={`${IMG_URL}${item?.icon}`}
                                        alt=""
                                        className="member-img img-fluid d-block rounded-circle"
                                      />
                                    </div>
                                    <div className="position-absolute top-0 end-0 mt-2 me-3">
                                      <UncontrolledDropdown
                                        direction="start"
                                        className="col text-end"
                                      >
                                        <DropdownToggle
                                          tag="a"
                                          id="dropdownMenuLink2"
                                          role="button"
                                        >
                                          <i className="ri-more-fill fs-17"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem
                                            className="  edit-list"
                                            onClick={() => {
                                              setModal(true);
                                              setContactUsData(item);
                                              setIsEdit(item._id);
                                            }}
                                          >
                                            <i className="ri-pencil-line me-2 align-bottom text-muted"></i>
                                            Edit
                                          </DropdownItem>
                                          <DropdownItem
                                            className="  remove-list"
                                            onClick={() => {
                                              onClickDelete(item._id);
                                            }}
                                          >
                                            <i className="ri-delete-bin-5-line me-2 align-bottom text-muted"></i>
                                            Remove
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </div>
                                    <div className="team-content">
                                      <h4 className="mb-2">{item?.heading}</h4>
                                      <p className="text-muted">
                                        {item?.subHeading}
                                      </p>
                                      <h5 className=" ">{item?.info}</h5>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          );
                        })}
                        {contactUsList?.length < 4 ? (
                          <Col xs={12}>
                            <div className="text-end mb-5">
                              <Button
                                type=""
                                className="btn btn-success"
                                onClick={() => {
                                  setIsEdit(null);
                                  setModal(true);
                                }}
                              >
                                <i className="ri-add-fill me-1 align-bottom"></i>{' '}
                                Add More
                              </Button>
                            </div>
                          </Col>
                        ) : null}
                      </Row>

                      {contactUsList.length === 0 && (
                        <Card>
                          <CardBody className="text-center">
                            Data not found
                          </CardBody>
                        </Card>
                      )}
                    </TabPane>
                    <TabPane tabId="2">
                      <EmailConfigure
                        setSpinFlag={setSpinFlag}
                        setResponse={setResponse}
                      />
                    </TabPane>
                    <TabPane tabId="3">
                      <AddRoles
                        setSpinFlag={setSpinFlag}
                        setResponse={setResponse}
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        id="showModal"
        isOpen={modal}
        handleClose={handleClose}
        centered
        size="md"
        toggle={handleClose}
      >
        <ModalHeader toggle={handleClose} modal={modal}>
          Add Info
        </ModalHeader>

        <ModalBody>
          <AddEditInfo
            handleClose={handleClose}
            setSpinFlag={setSpinFlag}
            setResponse={setResponse}
            contactUsData={contactUsData}
            isEdit={isEdit}
            amenitiesListRendering={amenitiesListRendering}
            setAmenitiesListRendering={setAmenitiesListRendering}
          />
        </ModalBody>
      </Modal>
      <ShowSpinner spinFlag={spinFlag} />
    </React.Fragment>
  );
};

export default SettingsPage;
