import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Pagination } from '@mui/material';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  UncontrolledDropdown,
  ModalHeader,
} from 'reactstrap';
import 'quill/dist/quill.snow.css';
import { getRulesList, deleteRules } from '../../../../services/apiServices';
import AddEditRules from './AddEditRules';
import DeleteModal from '../../../Components/Common/DeleteModal';
import ShowSpinner from '../../../Components/Common/spinner';
import ResponseModal from '../../../Components/Common/SuccessModal';

const RulesPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState();
  const [search, setSearch] = useState('');
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const [isRulesDelete, setIsRulesDelete] = useState(null);
  const [rulesList, setRulesList] = useState([]);
  const [rulesListRendering, setRulesListRendering] = useState(false);
  const [rulesData, setRulesData] = useState(null);
  const [spinFlag, setSpinFlag] = useState(false);
  const [response, setResponse] = useState({
    message: '',
    open: false,
    statusCode: null,
  });

  const [deleteState, setDeleteState] = useState({
    message: '',
    spinFlag: false,
    showAlert: false,
    showAlertError: false,
  });

  const onClickDelete = (id) => {
    setIsRulesDelete(id);
    setDeleteModal(true);
  };

  const handleClose = () => {
    setRulesData(null);
    setIsEdit(null);
    setModal(false);
  };
  // const toggle = useCallback(() => {
  //   if (modal) {
  //     setModal(false);
  //     setCustomer(null);
  //   } else {
  //     setModal(true);
  //   }
  // }, [modal]);
  // const handleCustomerClick = useCallback(
  //   (arg) => {
  //     const customer = arg;
  //     setCustomer();
  //     setIsEdit(true);
  //     toggle();
  //   },
  //   [toggle]
  // );

  const fetchRules = async () => {
    try {
      setSpinFlag(true);
      let payload = {
        currentPage: currentPage,
        search: search,
      };
      let { data } = await getRulesList(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setNumberOfPages(data.noOfPages);
        setRulesList(data.rulesList);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  //to delete rules
  const handleDeleteRules = async () => {
    try {
      setSpinFlag(true);
      let payload = { delete_id: isRulesDelete };
      let { data } = await deleteRules(payload);

      if (data.statusCode === 200) {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
        setDeleteModal(false);
        setRulesListRendering(!rulesListRendering);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    if (search !== '') {
      const getData = setTimeout(() => {
        fetchRules();
      }, 1000);
      return () => clearTimeout(getData);
    } else {
      fetchRules();
    }
  }, [rulesListRendering, currentPage, search]);

  return (
    <React.Fragment>
      <ResponseModal response={response} setResponse={setResponse} />
      <DeleteModal
        deleteState={deleteState}
        show={deleteModal}
        onDeleteClick={handleDeleteRules}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-2">
                    <Col xs={12} className="col-lg-auto">
                      <div>
                        <h5 className="card-title mb-0">Rules</h5>
                      </div>
                    </Col>
                    <Col xs={12} className="col-lg-auto ms-auto">
                      <div className="list-grid-nav hstack gap-1">
                        <div className="search-box">
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Search rules..."
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                        <div className="list-grid-nav hstack gap-1">
                          <Button
                            color="success"
                            onClick={() => {
                              setIsEdit(null);
                              setModal(true);
                              // toggle();
                            }}
                            className="text-nowrap"
                          >
                            <i className="ri-add-fill me-1 align-bottom"></i>{' '}
                            Add Rules
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-3">
                  <div id="teamlist">
                    {rulesList.map((item, index) => {
                      return (
                        <Row className="team-list list-view-filter">
                          <Col>
                            <div className="team-box border-bottom py-2 border-bottom-dashed">
                              <Row className="team-row">
                                <Col xs={2} className="team-settings">
                                  <UncontrolledDropdown
                                    direction="start"
                                    className="text-end"
                                  >
                                    <DropdownToggle
                                      tag="a"
                                      id="dropdownMenuLink2"
                                      role="button"
                                    >
                                      <i className="ri-more-fill fs-17"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        className="  edit-list"
                                        onClick={() => {
                                          // handleCustomerClick();
                                          setIsEdit(item._id);
                                          setModal(true);
                                          setRulesData(item);
                                        }}
                                      >
                                        <i className="ri-pencil-line me-2 align-bottom text-muted"></i>
                                        Edit
                                      </DropdownItem>
                                      <DropdownItem
                                        className="  remove-list"
                                        onClick={() => {
                                          onClickDelete(item._id);
                                        }}
                                      >
                                        <i className="ri-delete-bin-5-line me-2 align-bottom text-muted"></i>
                                        Remove
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </Col>
                                <Col xs={10}>
                                  <div className="team-profile-img">
                                    <div className="avatar-xs d-flex align-items-center justify-content-center flex-shrink-0">
                                      <h5 className="fs-16 mb-1">
                                        {index + 1}
                                      </h5>
                                    </div>
                                    <div className="team-content">
                                      <h5 className="fs-16 mb-1">
                                        {item.rules}
                                      </h5>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {rulesList.length === 0 && (
            <Card>
              <CardBody className="text-center">Data not found</CardBody>
            </Card>
          )}
          {rulesList.length > 0 && (
            <Pagination
              shape="rounded"
              count={numberOfPages}
              page={currentPage}
              siblingCount={0}
              boundaryCount={2}
              onChange={handlePageChange}
              sx={{
                py: 2,
                '& .MuiPagination-ul': { justifyContent: 'center' },
              }}
            />
          )}
        </Container>
      </div>

      <Modal
        id="showModal"
        isOpen={modal}
        handleClose={handleClose}
        centered
        size="md"
        toggle={handleClose}
      >
        <ModalHeader modal={modal} toggle={handleClose}>
          {isEdit ? 'Edit Rules' : 'Add Rules'}
        </ModalHeader>

        <ModalBody>
          <AddEditRules
            isEdit={isEdit}
            setSpinFlag={setSpinFlag}
            setResponse={setResponse}
            handleClose={handleClose}
            rulesData={rulesData}
            setRulesListRendering={setRulesListRendering}
            rulesListRendering={rulesListRendering}
          />
        </ModalBody>
      </Modal>
      <ShowSpinner spinFlag={spinFlag} />
    </React.Fragment>
  );
};

export default RulesPage;
