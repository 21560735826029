import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { forgotPasswordValidation } from '../../../utils/validation/Admin';
import logoLight from '../../assets/images/logo-light.png';
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';
import withRouter from '../../Components/Common/withRouter';
import { forgotPassword } from '../../../services/apiServices';
import { useFormik } from 'formik';
import ShowSpinner from '../../Components/Common/spinner';

const AdminForgetPasswordPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [state, setState] = useState({
    showPassword: false,
    message: '',
    spinFlag: false,
    showAlert: false,
    showAlertError: false,
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: forgotPasswordValidation,
    onSubmit: async (values) => {
      let payload = {
        email: values.email,
      };
      try {
        setState({ ...state, spinFlag: true });
        const { data } = await forgotPassword(payload);
        if (data.statusCode == 200) {
          setState({
            ...state,
            message: data.message,
            spinFlag: false,
            showAlert: true,
            showAlertError: false,
          });
          // resetForm();
        } else if (data.statusCode == 400) {
          setState({
            ...state,
            message: data.message,
            spinFlag: false,
            showAlert: false,
            showAlertError: true,
          });
        }
      } catch (err) {
        navigate(`/admin/error?msg=${err}&&path=${pathname}`);
      }
    },
  });

  const { forgetError, forgetSuccessMsg } = useSelector((state) => ({}));

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img
                      src={logoLight}
                      alt=""
                      height="80"
                      width="220"
                      style={{ objectFit: 'contain' }}
                    />
                  </Link>
                </div>
                <h4 className="mt-3">FLATTTS</h4>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4 border">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Forgot Password?</h5>
                    <p className="text-muted">Reset password with velzon</p>

                    <lord-icon
                      src="https://cdn.lordicon.com/rhvddzym.json"
                      trigger="loop"
                      colors="primary:#0ab39c"
                      className="avatar-xl"
                      style={{ width: '120px', height: '120px' }}
                    ></lord-icon>
                  </div>
                  <div>
                    {state.showAlert ? (
                      <Alert sx={{ mb: 2 }} severity="success">
                        {state.message}
                      </Alert>
                    ) : (
                      ''
                    )}
                    {state.showAlertError ? (
                      <Alert sx={{ mb: 2 }} severity="error">
                        {state.message}
                      </Alert>
                    ) : (
                      ''
                    )}
                  </div>

                  <Alert
                    className="alert-borderless alert-warning text-center mb-2 mx-2"
                    role="alert"
                  >
                    Enter your email and instructions will be sent to you!
                  </Alert>
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: '13px' }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: '13px' }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-4">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.email}</div>
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="text-center mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          Send Reset Link
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-4 text-center">
                <p className="mb-0">
                  Wait, I remember my password...{' '}
                  <Link
                    to="/admin"
                    className="fw-semibold text-primary text-decoration-underline"
                  >
                    {' '}
                    Click here{' '}
                  </Link>{' '}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ShowSpinner spinFlag={state.spinFlag} />
    </ParticlesAuth>
  );
};

AdminForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(AdminForgetPasswordPage);
