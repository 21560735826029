import { Grid, Box, Container, Typography, alpha } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import Intersect2 from '../../assets/images/Intersect2.png';
import Rectangle5201 from '../../assets/images/Rectangle5201.png';
import bannerImg from '../../assets/images/bannerImg.png';
import Research from '../../assets/icons/Research.svg';
import Instant from '../../assets/icons/Instant.svg';
import Track from '../../assets/icons/Track.svg';
import SvgColor from '../../components/svg-color';
import { IMG_URL } from '../../utils/constant';

const BannerMain = styled(Box)(({ theme }) => ({
  padding: '50px 0',
  [theme.breakpoints.down('lg')]: {
    padding: '56px 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: '40px 0',
  },
}));
const BannerLeftMain = styled(Box)(({ theme }) => ({}));
const BannerLeft = styled(Box)(({ theme }) => ({
  maxWidth: '860px',
  textAlign: 'center',
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
  '& .MuiTypography-h2': {
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
      marginBottom: '20px',
    },
  },
  '& .MuiTypography-h6': {
    [theme.breakpoints.down('xl')]: {
      fontSize: '1.0625rem',
    },
  },
}));
const BannerLeftText = styled(Box)(({ theme }) => ({
  marginBottom: '40px',
  [theme.breakpoints.down('md')]: {
    marginBottom: '20px',
  },
}));
const WorksIconBox = styled(Box)(({ theme }) => ({
  width: '90px',
  height: '90px',
  borderRadius: '20px',
  background: `${alpha(theme.palette.secondary.main, 1)}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto 50px',
  [theme.breakpoints.down('md')]: {
    margin: '0 auto 20px',
  },
}));

export const HowItWorksSection = (props) => {
  let { becomeHostContents, becomeHostList, howItWorks } = props;
  return (
    <>
      <BannerMain>
        <Container fixed={true} sx={{ maxWidth: { xl: '1326px' } }}>
          <BannerLeftMain>
            <BannerLeft>
              <BannerLeftText>
                {howItWorks && howItWorks.length > 0 && (
                  <>
                    <Typography variant="h2" sx={{ mb: 4 }}>
                      {howItWorks[0]?.title}
                    </Typography>
                  </>
                )}
              </BannerLeftText>
            </BannerLeft>
          </BannerLeftMain>
          <Grid container spacing={3}>
            {becomeHostList.map((value, index) => (
              <Grid item md={4} xs={12} key={index}>
                <Box
                  sx={{
                    textAlign: 'center',
                    maxWidth: '300px',
                    m: { md: '0 auto', xs: '0 auto 20px' },
                  }}
                >
                  <WorksIconBox>
                    <img
                      width="25px"
                      height="25px"
                      style={{ objectFit: 'contain' }}
                      src={`${IMG_URL}${value.icon}`}
                    />
                  </WorksIconBox>
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    {value.heading}
                  </Typography>
                  <Typography variant="body1">{value.description}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </BannerMain>
    </>
  );
};
