import React, { useState, useEffect } from 'react';
import { Grid, Box, Container, Typography } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import Intersect2 from '../../assets/images/Intersect2.png';
import { Icon } from '@iconify/react';

const StylePopular = styled(Box)(({ theme }) => ({
  background: `#FAFAFA url(${Intersect2}) no-repeat center center / 100% 80%`,
  padding: '50px 0',
  borderRadius: '80px',
  [theme.breakpoints.down('lg')]: {
    padding: '56px 0',
    borderRadius: '56px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '40px 0',
    borderRadius: '40px',
  },
}));
const BannerLeftMain = styled(Box)(({ theme }) => ({}));
const BannerLeftText = styled(Box)(({ theme }) => ({
  marginBottom: '20px',
  [theme.breakpoints.down('md')]: {
    marginBottom: '20px',
  },
}));
const BannerLeft = styled(Box)(({ theme }) => ({
  maxWidth: '850px',
  textAlign: 'center',
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
  '& .MuiTypography-h2': {
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
      marginBottom: '20px',
    },
  },
  '& .MuiTypography-h6': {
    [theme.breakpoints.down('xl')]: {
      fontSize: '1.0625rem',
    },
  },
}));
const HostImages = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
}));
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  background: '#FFFFFF',
  borderRadius: '8px',
  overflow: 'hidden',
  marginBottom: '30px',
  padding: '10px 45px',
  [theme.breakpoints.down('md')]: {
    padding: '10px 25px',
    marginBottom: '20px',
  },
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <Icon style={{ fontSize: '24px', color: '#000000' }} icon="tabler:plus" />
    }
    {...props}
  />
))(({ theme }) => ({
  background: '#FFFFFF',
  border: 'none',
  padding: 0,
  //   flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(45deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '10px 0 35px',
}));
export const FaqSection = (props) => {
  let { faqsList, homeContents, faqs } = props;
  const [expanded, setExpanded] = useState();
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <StylePopular>
        <Container fixed={true} sx={{ maxWidth: { xl: '1326px' } }}>
          <BannerLeftMain>
            <BannerLeft>
              <BannerLeftText>
                {faqs && faqs?.length > 0 && (
                  <>
                    <Typography variant="h2" sx={{ mb: 4 }}>
                      {faqs[0]?.title}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 'normal', color: 'text.secondary' }}
                    >
                      {faqs[0]?.subTitle}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ mb: 4, color: 'text.secondary' }}
                    >
                      {faqs[0]?.description}
                    </Typography>
                  </>
                )}
              </BannerLeftText>
            </BannerLeft>
          </BannerLeftMain>
          <Box sx={{ mt: 6 }}>
            {faqsList.map((value, index) => (
              <Accordion
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
                key={index}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography variant="h6">{value.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: value.answere,
                    }}
                  ></Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Container>
      </StylePopular>
    </>
  );
};
