import { React, useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Header } from '../main-layout/header';
import { useAuthStore } from '../../redux/features/authSlice';
import { setToken } from '../../utils/axiosClient';

const Main = styled('section')(({ theme }) => ({
  minHeight: 'calc(100svh - 100px)',
  [theme.breakpoints.down('md')]: {
    minHeight: 'auto',
  },
}));

export const SignupLayout = () => {
  let { loginInfo } = useAuthStore();
  setToken(loginInfo ? loginInfo.token : null);
  return (
    <>
      <Header />
      <Main>
        <Outlet />
      </Main>
    </>
  );
};
