import React, { useState, useRef } from 'react';
import {
  Card,
  CardHeader,
  Typography,
  Button,
  ListItemText,
  IconButton,
  Box,
  Popper,
  Paper,
  Avatar,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Popover,
  ListItemIcon,
  List,
  ListItem,
  ListItemButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { styled, alpha } from '@mui/material/styles';

export const StyledNavItemIcon = styled(ListItemIcon)({
  width: 18,
  height: 18,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
const options = [
  {
    value: 'EN',
    label: 'English',
    icon: 'emojione:flag-for-united-kingdom',
  },
  {
    value: 'GE',
    label: 'German',
    icon: 'emojione:flag-for-germany',
  },
  {
    value: 'FR',
    label: 'French',
    icon: 'emojione:flag-for-france',
  },
];
export const LanguagePopover = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <Button
        ref={anchorRef}
        sx={{ width: '130px', borderRadius: '6px' }}
        aria-label="split button"
        onClick={handleToggle}
      >
        <StyledNavItemIcon>
          <Icon icon={options[selectedIndex].icon} />
        </StyledNavItemIcon>
        <ListItemText>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span
              style={{ fontSize: '12px', fontWeight: '600', color: '#323232' }}
            >
              {options[selectedIndex].label}
            </span>
            <Icon
              style={{ marginLeft: '5px', fontSize: '22px', color: '#808191' }}
              icon="material-symbols:keyboard-arrow-down-rounded"
            />
          </Box>
        </ListItemText>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        sx={{
          width: '150px',
          background: '#fff',
          p: 0.5,
          borderRadius: 2.5,
          zIndex: 9,
          boxShadow: (theme) => theme.customShadows.dropdown,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <List id="split-button-menu" sx={{ p: 0 }}>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      sx={{
                        borderRadius: 1.5,
                      }}
                    >
                      <StyledNavItemIcon>
                        <Icon icon={option.icon} />
                      </StyledNavItemIcon>
                      {option.label}
                    </MenuItem>
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
